import React from 'react';
import withWindowDimensions from '../../commons/withWindowDimensions';
import { withSuccess } from 'antd/lib/modal/confirm';

const Status = (props) => {
    const styling = props.isSm ? { minWidth: "100px" } : { minWidth: "100px" }
    const colorClass = determineColorClass(props.status);

    return (<div
        className={`dashboard-datatable__button btn text-uppercase rounded-pill 
    text-white ${colorClass}`}
        style={styling}>
        {props.status}
    </div>)
}

function determineColorClass(status) {
    const okayStatus = ["received", "completed", "approved"];
    const warnStatus = ["pending", "required"];

    const statusText = status.toLowerCase();

    let colorClass = "application-status--";

    if (okayStatus.includes(statusText)) {
        colorClass = colorClass.concat("success")
    }
    else if (warnStatus.includes(statusText)) {
        colorClass = colorClass = colorClass.concat("warn")
    }
    else {
        colorClass = colorClass.concat("warn")
    }

    return colorClass;
}

export default withWindowDimensions(Status);