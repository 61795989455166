import React, { Component } from 'react';

class FtaAuthenticationComplete extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="policy-dashboard__prompt-overlay">
                <article className="policy-services-prompt">
                    <article className="policy-services-prompt__content">
                        <article className="policy-services-submodule__user-area d-flex flex-column">
                            <section className="policy-services-submodule__child-container">
                                <div className="policy-services-submodule__auth-message policy-services-submodule__auth-message--fta policy-services-submodule__auth-message--complete">
                                    <span className="policy-services-submodule__auth-check-icon icon-check-circle"></span>
                                    <span>
                                        {
                                            `Link has been successfully sent to ${this.props.authMethod === "sms" ? `+${this.props.baseCountryCode} ${this.props.baseMobileNum}` : this.props.baseEmail}. Please advise your client to fill out the FTA form. The link is valid for 48 hours.`
                                        }
                                    </span>
                                </div>
                                <div className="policy-services-submodule__auth-actions policy-services-submodule__auth-actions--fta">
                                    <button
                                        className="policy-services-submodule__auth-action policy-services-submodule__auth-action--fta"
                                        onClick={() => this.props.history.push("/dashboard/agent-transaction-history")}
                                    >
                                        Go to Transaction List
                                    </button>
                                    <button
                                        className="policy-services-submodule__auth-action policy-services-submodule__auth-action--fta"
                                        onClick={() => this.props.history.push("/dashboard/policy-services/fta")}
                                    >
                                        Close
                                    </button>
                                </div>
                            </section>
                        </article>
                    </article>
                </article>
            </div>
        )
    }
}


export default FtaAuthenticationComplete;
