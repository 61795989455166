import React from 'react';
import withWindowDimensions from '../../commons/withWindowDimensions';
import { withSuccess } from 'antd/lib/modal/confirm';

const Status = (props) => {
    let { status } = props;
    status = status || "---";
    const okayStatus = ["for issuance", "encoded"];
    const warnStatus = ["postponed", "underwriting with pending items", "encoded with pending items", "underwriting"];
    const errorStatus = ["cancelled", "declined"];
    const styling = props.isSm? {  }: { minWidth: "100px" }

    let output = <>---</>;

    if(props.status) {
        if (okayStatus.includes(props.status.toLowerCase())) {
            output = (
                <div className="dashboard-datatable__button btn text-uppercase rounded-pill text-white application-status--success" style={ styling }>
                    {props.status}
                </div>
            )
        }
        else if (warnStatus.includes(props.status.toLowerCase())) {
            output = (
                <div className="dashboard-datatable__button btn text-uppercase rounded-pill text-white application-status--warn" style={ styling }>
                    {props.status}
                </div>
            )
        }
        else if (errorStatus.includes(props.status.toLowerCase())) {
            output = (
                <div className="dashboard-datatable__button btn text-uppercase rounded-pill text-white application-status--danger" style={ styling }>
                    {props.status}
                </div>
            )
        }
        else {
            output = (
                <div className="dashboard-datatable__button btn text-uppercase rounded-pill text-white application-status--unknown" style={ styling }>
                    {props.status}
                </div>
            )
        }
    }

    return output;
}

export default withWindowDimensions(Status);
