import React, { Component } from 'react';
// import '../App.css';
import { appendFileType, writeData, removeNullProfilePic, jsonToURI, readAllData, encrypt, decrypt, adjustUsername } from '../commons/utility.js';
import { styles } from '../styles/styles.js';

import { Modal, Loader } from '../commons/utilityViews.js';
import { myConfig } from '../commons/config.js';
import { BrowserRouter as Router, Route, Link, Redirect, withRouter } from 'react-router-dom';
import { user } from '../apiFactory/user.js';
import routeChange from '../commons/routeChange';
import { apiModule, createQueryStrings } from '../commons/apiCall.js';
import AuthLoader from '../commons/authLoader';
import Announcement from './Announcement/announcement.js';
import LoginModal from './misc/loginModal.js';

class Login extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isModalShown: true,
      isLoading: false,
      payloadParams: {
        username: '',
        password: ''
      },
      fakepermissions: [
        "VIEW_ALERTS",
        "VIEW_UP",
        "VIEW_HIER_UP",
        "UPDATE_UP",
        "VIEW_CL_LIST",
        "VIEW_FA_LIST",
        "VIEW_CS",
        "SEARCH_CL",
        "VIEW_CL",
        "VIEW_POL_LIST",
        "SEARCH_POL",
        "VIEW_POL_DET",
        "VIEW_POL_VAL",
        "VIEW_POL_COV",
        "VIEW_POL_BENE",
        "VIEW_POL_FUND_DET",
        "VIEW_POL_FUND_TRANS",
        "VIEW_POL_LOANS",
        "VIEW_POL_ENDOW",
        "VIEW_POL_PAY",
        "VIEW_POL_CLAIMS",
        "VIEW_POL_ENDORSE",
        "VIEW_POL_REINSTATE",
        "VIEW_REP",
        "VIEW_REP_CL_BD",
        "VIEW_REP_POL_DUE",
        "VIEW_REP_CL_LOC",
        "VIEW_REP_POL_SERV",
        "VIEW_LINKS",
        "VIEW_CONTACT"
      ],
      error: '',
      passwordShow: false,
    };
    this.getUserRole = this.getUserRole.bind(this);
    this.getWebUser = this.getWebUser.bind(this);
    this.getAccountManager = this.getAccountManager.bind(this);
    this.getUser = this.getUser.bind(this);
  }

  async componentDidMount() {
    let access_token = this.props.getSession().access_token;
    // console.log(this.props.getSession());
    if (this.props.getLoginMessage() !== "") {
      this.errorMessage(this.props.getLoginMessage());
    }

  }
  fetchUserDetails = (agentNumber, username, access_token) => {
    let that = this;
    return new Promise(function (resolve, reject) {
      let headerParams = {
        x_auth: access_token,
        webUser: username
      };
      let payloadParams = {};

      apiModule("get_webuser", headerParams, null)
        .then(function (result) {
          let alias = '';
          let image = '';

          if (alias != null) {
            alias = result.alias;
          }

          if (image != null) {
            image = appendFileType(removeNullProfilePic(result.image));
          }

          resolve({
            alias: alias,
            image: image
          });
        })
        .catch(function (err) {
          reject(err)
          //   resolve("Ok");

        });
    });
  }
  doResetPassword = (username) => {
    let that = this;

    return new Promise(function (resolve, reject) {
      let headerParams = {};
      let payloadParams = {
        username: username,

      };
      apiModule("authentication_forgot_password", headerParams, payloadParams)
        .then(function (result) {
          resolve("Ok");
        })
        .catch(function (err) {
          resolve("Ok");
        });
    });


  }
  doLogin = (that) => {

    that.setState({ isLoading: true });

    localStorage.clear();
    let payloadParams = {
      username: adjustUsername(that.state.payloadParams.username),
      password: that.state.payloadParams.password,
      grant_type: "password",
      appId: "AZ-ASSIST"
    };
    let username = `${payloadParams.username}@allianzpnblife.ph`;

    return new Promise(function (resolve, reject) {
      //start loading

      let headerParams = {};
      let timestamp = new Date();


      readAllData('users')
        .then(function (data) {
          const uuidv5 = require('uuid/v5');
          let deviceId = '';
          //let fakeDeviceId = 'dc3f6ffa-f04c-5f97-90ed-8c5e95e9e14d';
          let fakeDeviceId = '';

          if (fakeDeviceId !== '') {
            deviceId = fakeDeviceId;
            writeData('users', { deviceId: deviceId, id: 1 });
          } else {
            if (data.length < 1) {
              deviceId = uuidv5('http://allianzpnblife.salesportal.com/' + timestamp + '/' + payloadParams, uuidv5.URL);
              writeData('users', { deviceId: deviceId, id: 1 });
            } else {
              for (var dt of data) {
                if (dt.deviceId === "" || dt.deviceId === undefined || dt.deviceId === null) {
                  deviceId = uuidv5('http://allianzpnblife.salesportal.com/' + timestamp + '/' + payloadParams, uuidv5.URL);
                  writeData('users', { deviceId: deviceId, id: 1 });
                } else {
                  deviceId = dt.deviceId;
                }
              }
            }
          }

          payloadParams.deviceId = deviceId;

          apiModule("authentication_login", headerParams, payloadParams, '', true)
            .then(async(result) => {
              console.log('result', result);
              if (result.status == 200) {
                if(result.data.access_token) {

                  const { access_token = '', refresh_token = '' } = result.data;
                  let headerParams = {}

                  let session = { 
                    resetPassword: false, 
                    channel: "", 
                    alias: "", 
                    image: "", 
                    view_as: "", 
                    view_as_email: "", 
                    view_as_number: "", 
                    name: "", 
                    agentNumber: "", 
                    role: "", 
                    access_token: access_token, 
                    code: '', 
                    permissions: "", 
                    username: username, 
                    id: 1,
                    user_role: "",
                    refresh_token: refresh_token
                  };
                  writeData('session', session);

                  headerParams = {...headerParams, x_auth: access_token, webUser: username};

                  console.log('header params', headerParams);

                  const user = await that.getUser(headerParams, that);
                  username = user.email ? encrypt(user.email) : encrypt(username);

                  console.log('username', username);
                  
                  headerParams = {...headerParams, x_auth: access_token, webUser: decrypt(username)};

                  const userRole = await that.getUserRole(headerParams, that);
                  if(userRole) {
                      headerParams = {...headerParams, userRole: userRole.userRole};

                      let session = { 
                          resetPassword: false, 
                          channel: "", 
                          alias: "", 
                          image: "", 
                          view_as: "", 
                          view_as_email: "", 
                          view_as_number: "", 
                          name: "", 
                          agentNumber: "", 
                          role: "", 
                          access_token: access_token, 
                          code: '', 
                          permissions: "", 
                          username: username, 
                          id: 1,
                          user_role: userRole.userRole,
                          refresh_token: refresh_token
                      };
                      writeData('session', session);

                      let role = 'HO';
                      let channel = 'HO';

                      const webUser = await that.getWebUser(headerParams, that);
                      if(webUser) {
                          if(webUser.accountManagerId) {
                              headerParams = {...headerParams, accountManagerId: webUser.accountManagerId};
                              const accountManager = await that.getAccountManager(headerParams, that);
                              if(accountManager) {
                                  role = accountManager.role;
                                  channel = accountManager.channel;
                              }
                          }
                          
                          console.log('header param permission', headerParams);

                          const permissions = await that.fetchUserPermissions(headerParams);
                          if(permissions) {

                              let session = { 
                                  resetPassword: false, 
                                  channel: channel, 
                                  alias: webUser.alias, 
                                  image: webUser.image, 
                                  view_as: "", 
                                  view_as_email: "", 
                                  view_as_number: "", 
                                  name: encrypt(user.name), 
                                  agentNumber: encrypt(webUser.accountManagerId), 
                                  role: role, 
                                  access_token: access_token, 
                                  code: '', 
                                  permissions: permissions, 
                                  username: username, 
                                  id: 1,
                                  user_role: userRole.userRole,
                                  refresh_token: refresh_token
                              };
                              writeData('session', session);
                              that.props.setUserSession(session);

                              if (session.resetPassword === true || session.resetPassword === "true") {
                                  routeChange(that.props.history, "/change_password");
                              } else {
                                  let permissionArray = permissions.map(function (e) { return e });
                                  let foundOne = false;

                                  if (permissionArray.indexOf('VIEW_IS') !== -1 && foundOne === false) { // MIMIC HSBC HOS
                                    that.props.setViewAs(2013745, '', 'raechelgrace.yadao@allianzpnblife.ph', that.props.history, "/dashboard/dashboard");
                                    foundOne = true;
                                }
                                if (permissionArray.indexOf('VIEW_HSBC') !== -1 && foundOne === false) { // MIMIC HSBC RBM
                                    that.props.setViewAs(2011547, '', 'margrette.ang@allianzpnblife.ph', that.props.history, "/dashboard/dashboard");
                                    foundOne = true;
                                }
                                  
                                  if (permissionArray.indexOf('VIEW_ASSIST') !== -1 && foundOne === false) { // ASSIST
                                      routeChange(that.props.history, "/dashboard/dashboard");
                                      foundOne = true;
                                  }

                                  if (permissionArray.indexOf('VIEW_CS') !== -1 && foundOne === false) { // CS
                                      routeChange(that.props.history, "/dashboard/list-of-users");
                                      foundOne = true;
                                  }

                                  if (permissionArray.indexOf('VIEW_MIS') !== -1 && foundOne === false) { // MIS
                                      routeChange(that.props.history, "/dashboard/mis");
                                      foundOne = true;
                                  }

                                  if (((permissionArray.indexOf("VIEW_PRODUCTION_REPORT_MASTER") !== -1) || (permissionArray.indexOf("VIEW_PRODUCTION_REPORT") !== -1)) && foundOne === false) { // REPORTS
                                      routeChange(that.props.history, "/dashboard/reports");
                                      foundOne = true;
                                  }

                                  if (foundOne === false) { //
                                      routeChange(that.props.history, "/dashboard/list-of-users");
                                  }
                              }
                          }
                      }
                  }
                }
                else if (result.data.code) {

                  const access_token = result.data.access_token ? result.data.access_token : '';
                  const code = result.data.code ? result.data.code : '';

                  let session = { resetPassword: false, view_as: "", view_as_email: "", view_as_number: "", name: "", agentNumber: "", role: "", code: code, access_token: access_token, permissions: that.state.fakepermissions, username: encrypt(username), id: 1, channel: "", user_role: "", refresh_token: "" };
                  writeData('session', session);
                  that.props.setUserSession(session);
                  routeChange(that.props.history, "/verify");
                }
              }
            })
            .catch(function (err) {
              console.log('err', err)
              const response = JSON.stringify(err);
              const { response: { data: { error } } = { data: {error: ''}} } = JSON.parse(response);

              const errorCodes = {
                700: "Invalid application ID.",
                703: "OTP locked.",
                708: "Invalid user group.",
                709: "User account locked.",
                711: "Password expired or account locked.",
                710: "OTP resend locked. OTP resend limit (5) has been met. OTP resending will be locked for a short duration (15 minutes)",
                712: "Unable to login user.",
                "401 UNAUTHORIZED": "Invalid username or password.",
                "invalid_grant": "Invalid username or password."
              }

              if(errorCodes.hasOwnProperty(error)) {
                that.setState({
                  isLoading: false,
                  error: errorCodes[error]
                });
              } else {
                that.setState({
                  isLoading: false,
                  error: myConfig.GENERIC_ERROR_MESSAGE
                });
              }
              resolve("Ok");
            })
        });
    });
  }

  errorMessage = (message) => {
    if (message) {
      this.setState({ error: message })
    }
  }
  resetPassword = () => {
    routeChange(this.props.history, "/reset_password");
  }
  showError = () => {
    let message = '';
    if (this.state.error !== '') {
      message =
        <div className="form-group mb-4 text-white">
          <p>{this.state.error}</p>
        </div>
    }
    return message;
  }
  handleKeyPress(e) {
    if (e.key === 'Enter') {
      this.doLogin(this);
    } else {

    }
  }
  handleChanges(e) {
    let payloadParams = this.state.payloadParams;
    payloadParams[e.target.name] = e.target.value;
    this.setState({ payloadParams });

  }

  handlePasswordVisibility = () => {
    if (this.state.passwordShow === false) {
      this.setState({ passwordShow: true })
    } else {
      this.setState({ passwordShow: false })
    }
  }

  
  getUserRole =  (headerParams, that) => {
    return new Promise(function (resolve, reject) {
        //TODO role
        apiModule("get_user_role", headerParams, null)
            .then(function (result) {
                console.log('result get user role', result);

                const roleString = r => `"${r}"`;
                const roles = result.data.map(roleString);
                const userRole = `[${roles.join(',')}]`;

                resolve({
                    userRole
                });
            })
            .catch(function (err) {
                console.log(err);
                that.setState({
                    isLoading: false,
                });
                that.errorMessage(myConfig.GENERIC_ERROR_MESSAGE);
                resolve(false);
            });
    });
  }

  getWebUser = (headerParams, that) => {
      return new Promise(function (resolve, reject) {
          //TODO /webuser
          apiModule("get_webuser", headerParams, null, createQueryStrings({ platform: 'react' }))
              .then(function (result) {
                  console.log('result webuser', result);
                  const accountManagerId = result.userId;

                  let image = '';

                  if (image != null) {
                      image = appendFileType(removeNullProfilePic(result.image));
                  }

                  resolve({
                      accountManagerId,
                      alias: result.alias,
                      image: image
                  })
              })
              .catch(function (err) {
                  console.log(err);
                  that.setState({
                      isLoading: false,
                  });
                  that.errorMessage(myConfig.GENERIC_ERROR_MESSAGE);
                  resolve(false);
              });  
      });
  }

  getAccountManager = (headerParams, that) => {

      return new Promise(function (resolve, reject) {

      //TODO /accountManager
      apiModule("get_accountmanager", headerParams, null)
          .then(function (result) {
              console.log('result account manager', result);
              resolve({
                  role: result.roleNameOfAccountManager || 'HO',
                  channel: result.accountManagerCategory || 'HO'
              })                                                              
          })
          .catch(function (err) {
              resolve(false);
          });
      });
  }

  getUser = (headerParams, that) => {
      return new Promise(function (resolve, reject) {
          
          //TODO /webuser
          let user = headerParams.webUser.replace('@allianzpnblife.ph', '');
          const headers = {...headerParams, webUser: user};
          console.log('headers 2', headers);
          apiModule("get_user", headers, null)
              .then(function (result) {
                  console.log('result user', result);
                  resolve({
                      name: `${result.data.firstName} ${result.data.lastName}`,
                      email: result.data.email
                  });
              })
              .catch(function (err) {
                  console.log(err);
                  that.setState({
                      isLoading: false,
                  });
                  that.errorMessage(myConfig.GENERIC_ERROR_MESSAGE);
                  resolve({
                      name: '',
                      email: ''
                  });
              });  
      });
  }

  fetchUserPermissions = (headerParams) => {
      return new Promise(function (resolve, reject) {
          apiModule("get_webuser_permissions", headerParams, null)
              .then(function (permissions_result) {
                  let permissions = [];
                  for (let i = 0; i < permissions_result.data.length; i++) {
                      permissions.push(permissions_result.data[i].name);
                  }

                  resolve(permissions);
              })
              .catch(function (err) {
                  reject(err);
              });

      });
  }

  render() {
    return (
      <div className="auth-section">
        { this.state.isModalShown &&
          <LoginModal
            close={() => this.setState({ isModalShown: false })}
          />
        }
        {/* <Announcement pageToCheck="login_page" /> */}
        <div className="auth__picture">
          <img className="auth__img" src={require('../assets/img/bg-login.jpg')} alt="Authentication background" />
        </div>
        <div className="custom-skewed-block background--gradient-blue">

          <div className="auth-block">
            {/* LOGIN CONTAINER */}
            <div className="auth-container">
              <form>
                <h1 className="auth__header text-white mb-4">Allianz Assist</h1>
                {this.showError()}
                <div className="form-group mb-4">
                  <input className="auth__text-field form-control" type="text" name="username" onChange={(event) => this.handleChanges(event)} placeholder="USERNAME" value={this.state.payloadParams.username} required />
                </div>
                <div className="form-group mb-4">
                  <div className="auth__text-field form-control d-flex">
                    <input className="flex-grow-1 border-0" type={this.state.passwordShow === true ? "text" : "password"} autoComplete={"off"} autocomplete="off" name="password" onKeyPress={(event) => this.handleKeyPress(event)} onChange={(event) => this.handleChanges(event)} placeholder="PASSWORD" value={this.state.payloadParams.password} required />
                    <a className="d-flex align-items-center cs-pointer" onClick={this.handlePasswordVisibility}>
                      <span className={`${this.state.passwordShow === false ? "icon-hide-password-o" : "icon-show-password-o"} font-1p5rem text-lightgray auth__icon`}></span>
                    </a>
                  </div>
                  <div className="d-flex">
                    <a className="auth__small-text text-white text-decoration-none mt-2 ml-auto" href="#" onClick={() => this.resetPassword()}>Reset Password</a>
                  </div>
                </div>
                {/* <a href="/verify" className="auth__button btn btn-navyblue text-uppercase btn-block">Submit</a> */}
                {/* <Link className="auth__button btn btn-navyblue text-uppercase btn-block" to="/verify">Submit</Link> */}
                <a onClick={() => this.doLogin(this)} className={`auth__button btn ${this.state.isLoading ? "btn-loading pes-none" : "btn-navyblue"} text-uppercase btn-block text-white`}>{this.state.isLoading ? <AuthLoader width="60" /> : "Submit"}</a>
              </form>
            </div>
            {/* END LOGIN CONTAINER */}
          </div>
          <div className="auth-logo-section">
            <div className="auth-logo-container">
              <img className="auth-logo" src={require("../assets/img/logo.png")} alt="Allianz Logo" />
            </div>
          </div>
        </div>
      </div >

    );
  }
}

export default withRouter(Login);
