import React, { Component } from 'react';
import ViewSDKClient from '../../commons/ViewSDKClient';
import LoaderGif from '../../commons/loaderGif';

export class AdobePdfModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true
        }
    }

    componentDidMount() {

        document.addEventListener("keydown", this.handleKeyDown, false);

        const viewSDKClient = new ViewSDKClient();
        viewSDKClient.ready().then(() => {
            viewSDKClient.previewFile("pdf-div",
                {
                    url: this.props.activePdfLink,
                    fileName: " ",//this.props.headerText,
                },
                {
                    // embedMode: "SIZED_CONTAINER",
                    defaultViewMode: "FIT_PAGE",
                    showAnnotationTools: false,
                    showDownloadPDF: false,
                    showPrintPDF: false,
                });
            viewSDKClient.eventAdobeLoad().then(() => {
                this.setState({
                    loading: false
                });
            });
        });
    }


    componentWillUnmount() {
        document.removeEventListener("keydown", this.handleKeyDown, false);
    }

    handleKeyDown = (e) => {
        if (e.key === "Escape") {
            this.handleCloseClick();
        }
    }

    handleCloseClick = () => {
        this.props.onCloseClick();
    }

    pdfDocument = () => {
        return (
            <div id="pdf-div" style={{ display: this.state.loading ? 'none' : null }}></div>
        );
    }

    render() {
        return (
            <div
                className="agent-kb-pdf-modal-adobe__overlay"
                onClick={this.handleCloseClick}
            >
                <article className="agent-kb-pdf-modal-adobe" onClick={(e) => e.stopPropagation()}>
                    <header className="agent-kb-pdf-modal-adobe__header d-flex flex-row">
                        <h4 className=" d-inline-flex align-items-center mr-auto mb-0">
                            {this.props.headerText}
                        </h4>
                        <span
                            className={`agent-kb-pdf-modal-adobe__close icon-close mb-0`}
                            onClick={this.handleCloseClick}
                        ></span>
                    </header>
                    <section id="js-pdf-modal-content" className="agent-kb-pdf-modal-adobe__content">
                        <div className="agent-kb-pdf-modal-adobe__loading" style={{ display: !this.state.loading ? 'none' : null }}>
                            <LoaderGif/><p>Loading Adobe PDF Embed API</p>
                        </div>
                        {this.pdfDocument()}
                    </section>
                </article>
            </div>
        );
    }
}
