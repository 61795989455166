import React, { Component, Fragment } from 'react';
//import '../App.css';
import { writeData, clearAllData, prettifyDate, formatDateByNumber, checkCharacters } from '../commons/utility';
import { isManager, checkLogin, checkPermissions, getSessionInfo } from '../commons/security.js';
import { Modal, Loader } from '../commons/utilityViews.js';
import Sidebar from './sidebar';
import { menuConfig } from '../commons/menu.js';
import '../home.scss';
// import DatePicker from 'react-datepicker';
import { Doughnut } from 'react-chartjs-2';
import { myConfig } from '../commons/config';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import { withRouter, Route } from 'react-router-dom';
import 'react-vertical-timeline-component/style.min.css';
import Redirect from 'react-router-dom/Redirect';
import Announcement from './Announcement/announcement';

class SidebarContainer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      toggle: undefined,
      currentSeasonTarget: '',
      openSeason: false,
      hideSeason: 'season-hide',
      seasonArrow: 'fa-angle-down',
      isLoading: false,
      menuIndex: "HOME",
      sidebar: !undefined,
      riaAllowedPages: ['/dashboard/user-info', '/dashboard/change-password', '/dashboard/hierarchy'],
      isRIAOnly: false,
      isLoading: true,
      riaPermissions: ["VIEW_UP", "UPDATE_UP", "ACCESS_REWARDS_AND_INCENTIVES"],
    };
  }

  async componentDidMount() {
    // let jwt = this.props.getSession().jwt;
    // let menu = this.props.match.params.name.toUpperCase().replace('-', ' ');
    // this.setState({jwt : jwt, menuIndex : menu});
    let permissionList = await getSessionInfo();
    let isRIAOnly = await checkPermissions(["ACCESS_REWARDS_AND_INCENTIVES"], permissionList[0].permissions)

    if (permissionList[0].permissions) {
      for (let permission of permissionList[0].permissions) {
        if (!this.state.riaPermissions.includes(permission)) {
          isRIAOnly = false;
        }
      }
    }

    this.setState({
      isRIAOnly: isRIAOnly,
      isLoading: false
    })
  }

  changeIndex = (index) => {
    this.setState({ menuIndex: index });
  }

  toggleDrawer() {
    let drawer = !this.state.sidebar;
    this.setState({ sidebar: drawer });
  }

  render() {
    myConfig.sidebarContext = this;
    const { riaAllowedPages, isLoading } = this.state;
    const isRIAOnly = this.state.isRIAOnly && !riaAllowedPages.includes(this.props.match.url);
    //MIS will not show COVID Announcement
    let isMISDashboard = this.props.match.params.name ? this.props.match.params.name == 'mis' : false;
    return !isLoading ? (
      !isRIAOnly ? (
        <Fragment>
          {isMISDashboard ? '' : <Announcement pageToCheck="dashboard_page" />}
          <Sidebar
            toggle={this.state.sidebar}
            doSearch={(query, pageNumber) => this.props.doSearch(query, pageNumber)}
            getSearchResults={() => this.props.getSearchResults()}
            getSearchLoading={() => this.props.getSearchLoading()}
            getSearchMessage={() => this.props.getSearchMessage()}
            setUserSession={(user) => this.props.setUserSession(user)}
            // signout={this.props.auth.signout}
            toggleSidebar={() => this.toggleDrawer()}
            getSession={() => this.props.getSession()}
            parent={this}
            setViewAs={(view_as_number, view_as, view_as_email, history, path) => this.props.setViewAs(view_as_number, view_as, view_as_email, history, path)}
          >
            {this.props.menuRoute(this)}
          </Sidebar>
        </Fragment>
      ) : <Redirect to='/rewards-and-incentives' />) : ''
  }
}

export default withRouter(SidebarContainer);
