import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import withWindowDimensions from '../commons/withWindowDimensions';
import Search from '../components/Documents/search';
import { Years } from "../components/Datepicker/customHeader";
import Document from "../components/Documents/document";
import { ReportLoader } from '../commons/utilityViews.js';
import ErrorMessage from "../components/errorMessage";
import { apiModule, createQueryStrings } from "../commons/apiCall";
import { decrypt, checkSearch, checkDateRange, fixName } from "../commons/utility";
import { myConfig } from "../commons/config.js";
import LoaderGif from '../commons/loaderGif';
import moment from 'moment';
import Modal from "../components/Reports/Modal.js";
import DownloadSuccessModal from "../components/Reports/DownloadSuccessModal";

const disabledPolicyNumber = ["POLICY_LIST"];
const hasDownloadSuccessModal = ["POLICY_LIST"];
class DocumentsReport extends Component {
    state = {
        // Loaders
        isLoading: true,
        isLoadingDocs: false,


        // Search
        searchFilter: {},

        documentTypeOptions: [],

        yearFrom: { label: new Date().getFullYear(), value: new Date().getFullYear() },
        yearTo: { label: new Date().getFullYear(), value: new Date().getFullYear() },

        startDate: "",
        endDate: "",

        years: [],
        policyNumber: "",

        // Error message
        errorMessage: "",
        successMessage: "",
        success: false,

        documents: [],
        rawDocuments: [],
        request: {},
        showModal: false,
        showDownloadSuccessModal: false,

        // PolicyStatus
        isStatusListLoading: false,
        statusList: [],
        status: null,
    }

    // Lifecycle
    async componentDidMount() {
        const agentNumber = decrypt(this.props.getSession().agentNumber);
        const jwt = this.props.getSession().access_token;
        let documentTypeOptions = [];
        let searchFilter = {};

        // Retrieve document types
        try {
            const documentTypes = await this.fetchDocumentTypes(jwt);
            documentTypeOptions = this.createDocumentTypeOptions(documentTypes);
            searchFilter = documentTypeOptions.length > 0 ? documentTypeOptions[0] : {};

            await this.fetchStatuses();
        }
        catch (error) {
            this.displayError(myConfig.GENERIC_ERROR_MESSAGE);
        }

        const years = Years(1990, new Date().getFullYear() + 1).reverse();

        console.log('search filter', searchFilter);
        console.log('document ooptions', documentTypeOptions);

        this.setState({ isLoading: false, years, documentTypeOptions, searchFilter });
    }

    // Data fetch
    /**
     * Sends an API call to fetch and return the list of document types
     *
     * @param {string} jwt
     * @returns {array} - List of document types
     */
    async fetchDocumentTypes(jwt) {
        const headerParams = { x_auth: jwt };

        return await apiModule("get_document_types", headerParams);
    }

    /**
     * Sends an API call to fetch and return the list of documents
     *
     * @param {string} type - Report type
     * @param {string | number} contractId - Policy/application number
     * @param {string} agentNumber - Agent number of current user
     * @param {string} jwt
     * @param {Date} dateFrom - Start date for search
     * @param {Date} dateTo - End date for search
     */
    async fetchDocuments(type, contractId, agentNumber, jwt, dateFrom = "", dateTo = "", status = "") {
        const headerParams = { x_auth: jwt };
        const queryString = createQueryStrings({ type, agentNumber, contractId, dateFrom, dateTo, status: status || "" });

        let returnValue;
        const response = await apiModule('get_documents', headerParams, null, queryString);
        if (response.hasOwnProperty("error")) {
            returnValue = { ok: false, documents: [], errorMessage: response.message }
        }
        else {
            returnValue = { ok: true, documents: response };
        }

        return returnValue;
    }

    // Handlers
    /**
     * Handles updates of the "searchFilter" state value
     *
     * @param {object} choice - The chosen search filter
     * @param {string} choice.name - The choice's display name
     * @param {string} choice.value - The choice's value
     */
    handleUpdateSearchFilter = (choice) => {
        this.setState({
            searchFilter: choice,

            // Clear previous data
            status: null,
         });
    }

    /**
     * Handles updates of the "policyNumber" state value
     *
     * @param {object} e - DOM event
     */
    handleUpdatePolicyNumber = (e) => {
        this.setState({ policyNumber: e.target.value });
    }

    /**
     * Handles updates of the "yearFrom" state value
     *
     * @param {object} choice
     * @param {number} choice.label
     * @param {number} choice.value
     */
    handleUpdateYearFrom = (choice) => {
        let date = new Date()
        date.setFullYear(choice.value);
        date.setMonth(0);
        date.setDate(1)
        this.setState({ yearFrom: choice })
    }

    /**
     * Handles updates of the "yearTo" state value
     *
     * @param {object} choice
     * @param {number} choice.label
     * @param {number} choice.value
     */
    handleUpdateYearTo = (choice) => {
        this.setState({ yearTo: choice })
    }

    /**
     * Handles updates of the "startDate" state value
     *
     * @param {Date} startDate
     */
    handleUpdateStartDate = (startDate) => {
        this.setState({ startDate })
    }

    /**
     * Handles updates of the "endDate" state value
     *
     * @param {Date} endDate
     */
    handleUpdateEndDate = (endDate) => {
        this.setState({ endDate })
    }

    handleUpdateStatus = (status) => {
        this.setState({ status })
    }

    /**
     * Handles the search request submission
     */
    handleSubmit = async () => {
        await this.doSearch();
    }

    /**
     * Catches the "Enter" key action and initiates a document search
     *
     * @typedef {object} MouseEvent
     *
     * @param {KeyEvent} e
     */
    handlePressEnter = async (e) => {
        if (e.key === "Enter") {
            await this.doSearch();
        }
    }

    /**
     * Sets the following state variables to an empty string:
     *  policyNumber
     *  yearFrom
     *  yearTo
     *  startDate
     *  endDate
     */
    handleClear = () => {
        const currentYear = new Date().getFullYear();
        this.setState({
            policyNumber: "",
            yearFrom: { label: currentYear, value: currentYear },
            yearTo: { label: currentYear, value: currentYear },
            startDate: "",
            endDate: "",
            status: null,
        });
    }

    // Helpers
    /**
     * Displays an error message in the top portion of the screen
     *
     * @param {string} errorMessage - Message contents
     */
    displayError = (errorMessage) => {
        if (errorMessage) {
            this.setState({ errorMessage: errorMessage });
        }
    }

    /**
     * Hides the error message by setting the "errorMessage" state variable to
     * an empty string
     */
    hideError = () => {
        this.setState({ errorMessage: "" });
    }

    /**
     * Receives the response from the document types endpoint
     * and transforms it to a suitable format for React Select
     *
     * @param {Object.<string, string>} object
     *
     * @returns {{label: string, value: string}}
     */
    createDocumentTypeOptions(object) {
        const choices = [];
        Object.keys(object).forEach(key => {
            const choice = { label: "", value: "" };
            choice.label = object[key];
            choice.value = key;
            choices.push(choice);
        });
        return choices;
    }

    /**
     * Receives a year and returns the earliest possible date for that year
     * @param {number} year
     *
     * @returns {Date}
     */
    createMinDateFromYear(year) {
        const date = new Date();
        date.setFullYear(year);
        date.setMonth(0);
        date.setDate(1);
        return date;
    }

    /**
     * Receives a year and returns the earliest possible date for that year
     *
     * @param {number} year
     *
     * @returns {Date}
     */
    createMaxDateFromYear(year) {
        const date = new Date();
        date.setFullYear(year);
        date.setMonth(11);
        date.setDate(31);
        return date;
    }


    /**
     * Returns two dates (from and to) depending on the selected document type
     *
     * TODO: Refactor. This function is not well-designed as it is not fully pure. It accepts
     * the document type argument but pulls startDate, endDate, yearFrom, and yearTo
     * from the state.
     *
     * @param {string} documentType
     * @returns {dateFrom: string, dateTo: string}
     */
    computeDateRanges(documentType) {
        let dateFrom = undefined;
        let dateTo = undefined;

        switch (documentType) {
            case "CERT_TC_OR":
            case "SUMM_OR":
                if (this.state.startDate !== "" && this.state.endDate !== "") {
                    dateFrom = this.state.startDate;
                    dateTo = this.state.endDate;
                }
                break;
        }
        const formattedDateFrom = dateFrom !== undefined ? moment(dateFrom).format("YYYY-MM-DD") : undefined;
        const formattedDateTo = dateTo !== undefined ? moment(dateTo).format("YYYY-MM-DD") : undefined;
        return { dateFrom: formattedDateFrom, dateTo: formattedDateTo };
    }

    /**
     * Generates an error message depending on the selected report type
     *
     * @param {string} documentTypeOption
     * @param {boolean} hasDates - Indicates whether the selected document type has a date range associated with it
     *
     * @returns {string}
     */
    createErrorMessage(documentTypeOption, hasDates) {
        let message;
        const createMessage = (documentType, additionalText = "") => `There is no ${documentType} for the selected policy ${additionalText}`;
        const documentTypeText = documentTypeOption.label;
        switch (documentTypeOption.value) {
            case "ANNIV_STMT":
                message = createMessage(documentTypeText)
                break;
            case "CERT_FULL_PMT":
                message = createMessage(documentTypeText)
                break;
            case "CERT_INSURANCE":
                message = createMessage(documentTypeText)
                break;
            case "CERT_TC_OR":
                if (hasDates) {
                    message = createMessage(documentTypeText, 'on the selected date range')
                }
                else {
                    message = createMessage(documentTypeText)
                }
                break;
            case "SUMM_OR":
                if (hasDates) {
                    message = createMessage(documentTypeText, 'on the selected date range')
                }
                else {
                    message = createMessage(documentTypeText)
                }
                break;
            case "BILLING_NTC":
                message = createMessage(documentTypeText);
                break;
        }
        return message;
    }

    // TODO: Refactor
    /**
     * Initiates a search requst
     */
    async doSearch() {
        const agentNumber = isNaN(parseInt(decrypt(this.props.getSession().agentNumber))) ? "": decrypt(this.props.getSession().agentNumber);
        const jwt = this.props.getSession().access_token;

        // Clear document list
        this.setState({ documents: [], policyNumber: this.state.policyNumber.trim(), success: false, successMessage: "", rawDocuments: [] });

        /* Check if policy number is valid. Note that this function already displays the error message
        when the error conditions are met (see second parameter) */
        if ((checkSearch(this.state.policyNumber, this.displayError, 2) && this.state.policyNumber.length > 0) || disabledPolicyNumber.includes(this.state.searchFilter.value)) {
            this.hideError();
            const { dateFrom, dateTo } = this.computeDateRanges(this.state.searchFilter.value);
            const status = this.state.status ? this.state.status.value : null;

            if (dateFrom !== undefined && dateTo !== undefined) {
                const isDateValidMsg = checkDateRange(dateTo, dateFrom);
                if (isDateValidMsg.length > 0) {
                    this.displayError(isDateValidMsg)
                    /* TODO: Refactor this to not have a "return" in th middle of the function.
                    /* This is bad design */
                    return;
                }
                else {
                    this.hideError();
                }
            }

            try {
                this.setState({ isLoadingDocs: true })
                const documentResponse = await this.fetchDocuments(
                    this.state.searchFilter.value,
                    this.state.policyNumber.trim(),
                    agentNumber,
                    jwt,
                    dateFrom,
                    dateTo,
                    status,
                );
                const documents = documentResponse.documents.map((document, ctr) => {
                    return {
                        link: `data:application/pdf;base64,${document.data}`,
                        name: document.fileName
                    };
                })

                const rawDocuments = documentResponse.documents;

                if (documents.length === 0) {
                    const hasDates = dateFrom !== undefined;
                    this.displayError(this.createErrorMessage(this.state.searchFilter, hasDates))
                }
                else {
                    const showDownloadSuccessModal = hasDownloadSuccessModal.includes(this.state.searchFilter.value);
                    this.setState({ documents, rawDocuments, showDownloadSuccessModal: showDownloadSuccessModal, isLoadingDocs: false }, () => {
                        if (showDownloadSuccessModal) {
                            document.querySelector(".js-download-link").click();
                        }
                    });
                }

                if (documentResponse.errorMessage) {
                    this.displayError(documentResponse.errorMessage);
                }

            }
            catch (e) {
                console.log(e, "CHECK")
                this.displayError(myConfig.GENERIC_ERROR_MESSAGE);
            }
            finally {
                this.setState({ isLoadingDocs: false });
            }
        }
        else if (this.state.policyNumber.length > 9) {
            this.displayError('Invalid input, Maximum number of characters is 9');
        }
        else {
            this.displayError('Invalid input, Policy Number cannot be empty');
        }
    }

    fetchStatuses = async () => {
        this.setState({
            isStatusListLoading: true,
        })
        let headerParams = {
            dataListId: "policyStatus",
            x_auth: this.props.getSession().access_token,
        }

        try {
            const response = await apiModule('get_datalist', headerParams);
            let statusList = [
                { label: "All Statuses", value: null }
            ];
            statusList = [
                ...statusList,
                ...Object.keys(response).map((key) => {
                    return {
                        value: key,
                        label: response[key],
                    }
                })
            ]
            this.setState({
                isStatusListLoading: false,
                statusList,
            })
        } catch (e) {
            this.displayError(myConfig.GENERIC_ERROR_MESSAGE);
            this.setState({
                isStatusListLoading: false
            })
        }
    }

    getEmail = async () => {
        this.setState({
            isLoadingDocs: true,
            errorMessage: ""
        });
        let headerParams = {
            policyId: this.state.policyNumber.trim(),
            x_auth: this.props.getSession().access_token
        }
        let request = {
            from: myConfig.EDELIVERY_EMAIL
        };
        try {
            const response = await apiModule('get_policy', headerParams);
            if (response.contractHolder) {
                const email = response.contractHolder.email ? response.contractHolder.email : '';
                const name = fixName(response.contractHolder);
                const subject = `[WARNING - ENCRYPTED ATTACHMENT NOT VIRUS SCANNED] ${name} - here is your Billing Notice from Allianz PNB Life.`
                request = { ...request, to: [email], customerName: name, subject }
            }
            if (response.contractHolder.email) {
                if (this.state.documents.length > 0) {
                    const document = this.state.rawDocuments[0];
                    console.log('document', document);
                    request = {
                        ...request,
                        attachment: [
                            {
                                fileName: document.fileName,
                                fileFormat: document.fileFormat,
                                data: document.data
                            }
                        ]
                    }
                }
                this.setState({
                    request,
                    showModal: true,
                    isLoadingDocs: false
                });
            } else {
                this.displayError('Sorry, we cannot process your request. Email address was not found on client\'s policy.');
                this.setState({
                    isLoadingDocs: false
                })
            }
        } catch (e) {
            this.displayError(myConfig.GENERIC_ERROR_MESSAGE);
            this.setState({
                isLoadingDocs: false
            })
        }


    }
    closeModal = (status) => {
        this.setState({
            showModal: false,
            showDownloadSuccessModal: false,
        })
        if (status === "success") {
            this.setState({
                success: true,
                successMessage: "Billing Notice has been successfully sent."
            })
        } else if (status === "failed") {
            this.displayError(myConfig.GENERIC_ERROR_MESSAGE);
        }
    }

    downloadMessage = () => {
        this.displayError("Sorry, you do not have permission to open this file");
    }

    render() {
        if (this.state.isLoading) {
            return (
                <ReportLoader show={this.state.isLoading} />
            )
        }
        else {
            return (
                <div className="dashboard-section overflow-hidden">
                    <ErrorMessage message={this.state.errorMessage} />

                    <Modal displayError={this.displayError}
                        showModal={this.state.showModal}
                        closeModal={this.closeModal}
                        token={this.props.getSession().access_token}
                        request={this.state.request}
                        query={createQueryStrings({ workflowId: this.state.searchFilter.value })} />

                    <DownloadSuccessModal
                        showModal={this.state.showDownloadSuccessModal}
                        closeModal={this.closeModal}
                    />

                    {
                        this.state.searchFilter.value === 'BILLING_NTC' && this.state.success &&
                        <div className="bg-success py-1 px-4">
                            <p className="text-white mb-0 font-neo-semibold">{this.state.successMessage}</p>
                        </div>
                    }

                    <div className="dashboard-container container-fluid p-3">
                        <div className="dashboard-header">
                            <h6 className="font-neo-bold">
                                <Link className="text-darkgray text-decoration-none" to="/dashboard/reports">Reports</Link>
                                <span className="dashboard-header__subheader text-headercolor"> | Generate Documents</span>
                            </h6>
                        </div>
                        <div className="dashboard-body">
                            <div className="dashboard-datatable mt-4 documents-report__search">
                                <Search
                                    searchFilter={this.state.searchFilter}
                                    policyNumber={this.state.policyNumber}
                                    startDate={this.state.startDate}
                                    endDate={this.state.endDate}
                                    yearFrom={this.state.yearFrom}
                                    yearTo={this.state.yearTo}
                                    years={this.state.years}
                                    documentTypeOptions={this.state.documentTypeOptions}
                                    handleUpdateSearchFilter={this.handleUpdateSearchFilter}
                                    handleUpdatePolicyNumber={this.handleUpdatePolicyNumber}
                                    handleUpdateYearFrom={this.handleUpdateYearFrom}
                                    handleUpdateYearTo={this.handleUpdateYearTo}
                                    handleUpdateStartDate={this.handleUpdateStartDate}
                                    handleUpdateEndDate={this.handleUpdateEndDate}
                                    handlePressEnter={this.handlePressEnter}
                                    handleClear={this.handleClear}
                                    handleSubmit={this.handleSubmit}
                                    showSpielMessage={true}

                                    //Policy Status
                                    status={this.state.status}
                                    statusListOptions={this.state.statusList}
                                    handleUpdateStatus={this.handleUpdateStatus}
                                    disabledPolicyNumber={disabledPolicyNumber}

                                />
                            </div>
                            <ul className="documents-report__results">
                                {this.state.isLoadingDocs ? (
                                    <div className="mt-4 mb-4">
                                        <LoaderGif />
                                    </div>
                                ) : (
                                    this.state.documents.map(document => <Document downloadMessage={this.downloadMessage} downloadable={this.state.searchFilter.value !== 'BILLING_NTC'} name={document.name} link={document.link} key={document.link} />)
                                )}
                            </ul>
                            {
                                this.state.searchFilter.value === 'BILLING_NTC' && this.state.documents.length > 0 && !this.state.isLoadingDocs &&
                                <a
                                    className={`lh-1 d-flex flex-grow-0 mt-2 justify-content-center align-items-center btn btn-dropdowncolor rounded-pill text-uppercase ${this.props.isSm ? "font-p75rem report-generate-btn-mobile" : "font-p65rem report-generate-btn"} font-neo-semibold text-white datatable-search__btn ml-2 mt-4`}
                                    onClick={this.getEmail}>
                                    Send Billing to Client
                                </a>
                            }
                        </div>
                    </div>
                </div>
            )
        }
    }
}

export default withRouter(withWindowDimensions(DocumentsReport));
