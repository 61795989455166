import React, { Component } from 'react';
import { ReportLoader } from '../commons/utilityViews.js';
import { withRouter, Link } from 'react-router-dom';
import { myConfig } from '../commons/config.js';
import { createOnlineSILink, urlBase64ToUint8Array } from '../commons/utility.js';
import { prettifyDate, calculateAge, numberWithCommas, writeData, readAllData, decrypt, encrypt } from '../commons/utility.js';
import { apiModule, createQueryStrings } from '../commons/apiCall';
import { isManager, checkLogin, checkPermissions, isInternalUser, getSessionInfo } from '../commons/security.js';
import { mockPersistencyData } from '../commons/mockData.js';
import routeChange from '../commons/routeChange';
//components
import PremiumDue from '../components/premiumDue';
import DashboardLapsedReport from '../components/dashboardLapsed';
import AlertsList from '../components/alertsList';
import StatusPortfolio from '../components/statusPortfolio';
import ProductPortfolio from '../components/productPortfolio';
import BirthdayList from '../components/birthdayList';
import FundChart from '../components/fundChart';
import withWindowDimensions from '../commons/withWindowDimensions.js';
import LoaderGif, { LoaderGif2, LoaderGif3 } from '../commons/loaderGif';
// import ViewPort from '../components/viewPort';
import FaPersistency from '../components/faPersistency';
import DOMPurify from 'dompurify';

let permissionsList = [
    {
        "name": "ACCESS_PNB",
        "description": "ACCESS TO PNB",
        "isActive": "Y",
        "id": 1
    },
    {
        "name": "ACCESS_AGENCY",
        "description": "ACCESS TO AGENCY",
        "isActive": "Y",
        "id": 2
    },
    {
        "name": "ACCESS_HSBC",
        "description": "ACCESS TO HSBC",
        "isActive": "Y",
        "id": 3
    },
    {
        "name": "ACCESS_RMS",
        "description": "ACCESS TO RMS",
        "isActive": "Y",
        "id": 8
    },
    {
        "name": "ACCESS_SALES",
        "description": "ACCESS TO SALES",
        "isActive": "Y",
        "id": 4
    },
    {
        "name": "ACCESS_WST_LTG",
        "description": "ACCESS TO WORKSITE LTG",
        "isActive": "Y",
        "id": 5
    },
    {
        "name": "ACCESS_WST_NLTG",
        "description": "ACCESS TO WORKSITE NON-LTG",
        "isActive": "Y",
        "id": 6
    },
    {
        "name": "ACCESS_ALT",
        "description": "ACCESS TO ALTERNATIVE",
        "isActive": "Y",
        "id": 7
    }
]

class Dashboard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            viewFAPersistency: false,
            FAPersistencyPercentage: "--",
            FAPersistencyDate: '',
            FAPersistencyFormulaName: '',
            isLoading: false,
            summary: {},
            PremiumDueList: null,
            LapsedPolicies: null,
            BirthdayList: null,
            PortfolioList: null,
            PortfolioListProd: null,
            AlertsList: null,

            //To show my team dropdown
            authUserType: "sm", //team dropdown will show if "sm" or "rbm"
            selectedTeammateStatus: "", // {label:"",value:""}
            selectedRangeStatus: { label: "All", value: "ALL" }, // {label:"",value:""}
            rangeOptions: {},

            // PORTFOLIO BY PROD STATES
            selectedTeammateProd: "",
            selectedRangeProd: { label: "All", value: "ALL" },

            //for dropdown options
            teammates: [{ value: "2007628", label: "EMGILIJWE, test" }],
            showPersistency: false,

            // LOADERS FOR COMPONENTS
            isPortfolioStatusLoading: true,
            isPortfolioProductLoading: true,
        };

        this.setPersistencyModal = this.setPersistencyModal.bind(this);
        this.fetchFAPersistencyCardPercentage = this.fetchFAPersistencyCardPercentage.bind(this);
        this.onClickPolicyNumber = this.onClickPolicyNumber.bind(this);
    }

    async fetchFAPersistencyCardPercentage() {
        let session = this.props.getSession();
        let that = this;
        let headerParams = {
            accountManagerId: decrypt(session.agentNumber),
            x_auth: session.access_token,
        };

        let result = {};
        // let isPNB = session.channel === 'PNB';
        // let calculationFormulaName = isPNB ? 'LIMRA' : 'RP';
        let calculationFormulaName = 'RP';

        result = await new Promise(function (resolve, reject) {
            let filter = [];
            filter['calculationFormulaName'] = calculationFormulaName;

            apiModule("get_fa_persistency", headerParams, null, createQueryStrings(filter))
                .then(function (response) {
                    let result = response;
                    result.formulaName = calculationFormulaName;
                    resolve(result);
                })
                .catch(function (err) {
                    resolve(null);
                })
        });

        // if(isPNB && result.goalDetails[0].achievedPersistencyPercentage <= 0){
        //     let filter = [];

        //     filter['calculationFormulaName'] = 'RP';

        //     result = await new Promise(function (resolve, reject) {
        //         let filter = [];
        //         filter['calculationFormulaName'] = 'RP';

        //         apiModule("get_fa_persistency", headerParams, null, createQueryStrings(filter))
        //             .then(function (response) {
        //                 let result = response;
        //                 result.formulaName = 'RP';
        //                 if(result && result.goalDetails && result.goalDetails[0].achievedPersistencyPercentage <= 0){
        //                     result.formulaName = 'LIMRA';
        //                 }
        //                 resolve(result);
        //             })
        //             .catch(function (err) {
        //                 resolve(null);
        //             })
        //     });
        // }
        return result;
    }

    setPersistencyModal(boo) {
        this.setState({
            showPersistency: boo ? boo : false,
        });
    }

    async componentDidMount() {
        let that = this;

        let session = this.props.getSession();
        let summary = {};
        let PremiumDueList = [];
        let LapsedPolicies = [];
        let BirthdayList = [];
        let PortfolioList = [];
        let PortfolioListProd = [];
        let AlertsList = [];
        let teammates = [];
        let counter = 0;

        //To view permissions
        let sessionInfo = await getSessionInfo();
        let viewFAPersistency = await checkPermissions(['VIEW_PERSISTENCY'], sessionInfo[0].permissions);

        if (session.access_token === undefined || session.access_token === null || session.access_token === "" ||
            session.username === undefined || session.username === null || session.username === "") {
            let i = 0;

            readAllData('session')
                .then(function (data) {
                    for (var dt of data) {
                        console.log(i + " : retrieve");
                        console.log(dt);
                        if (dt && dt.agentNumber !== "" && dt.agentNumber !== null && dt.agentNumber !== undefined) {
                            i++;
                            dt.agentNumber = decrypt(dt.agentNumber);
                            dt.name = decrypt(dt.name);
                            dt.username = decrypt(dt.username);
                            that.props.setUserSession(dt);

                            if (viewFAPersistency) {
                                that.fetchFAPersistencyCardPercentage().then(function (result) {

                                    // Mock data
                                    if (myConfig.ENABLE_MOCK_DATA) {
                                        result = mockPersistencyData['13'];
                                    }

                                    that.setState({
                                        viewFAPersistency: viewFAPersistency,
                                        FAPersistencyPercentage: result ? result.goalDetails[0].achievedPersistencyPercentage > 0 ? result.goalDetails[0].achievedPersistencyPercentage + '%' : '--' : '--',
                                        FAPersistencyDate: result ? ' For ' + result.goalDetails[0].month.substring(0, 3) + ' ' + result.goalDetails[0].year : 'Persistency',
                                        FAPersistencyFormulaName: result ? result.formulaName + '13' : ''
                                    })
                                }).catch(function (err) {
                                    console.log(err);
                                    that.setState({
                                        viewFAPersistency: viewFAPersistency,
                                    })
                                });
                            }


                            that.fetchSummary().then(function (result) {
                                summary = result;
                                counter++;
                                if (counter >= 4) {
                                    that.setState({
                                        summary,
                                        AlertsList,
                                        PortfolioList,
                                        BirthdayList,
                                        PremiumDueList,
                                        LapsedPolicies,
                                        isLoading: false,
                                        isMgr: isManager(that.props.getSession().role)
                                    })
                                }
                            }).catch(function (err) {
                                counter++;


                                if (counter >= 4) {
                                    that.setState({
                                        summary,
                                        AlertsList,
                                        PortfolioList,
                                        BirthdayList,
                                        PremiumDueList,
                                        LapsedPolicies,
                                        isLoading: false,
                                        isMgr: isManager(that.props.getSession().role)
                                    })
                                }
                            });

                            console.log("fetchAlerts : " + that.props.getSession().username);
                            that.fetchAlerts(dt.username).then(function (result) {
                                AlertsList = result;
                                console.log("AlertsList");
                                console.log(AlertsList);
                                counter++;
                                if (counter >= 4) {
                                    that.setState({
                                        summary,
                                        AlertsList,
                                        PortfolioList,
                                        BirthdayList,
                                        PremiumDueList,
                                        LapsedPolicies,
                                        isLoading: false,
                                        // teammates,
                                        isMgr: isManager(that.props.getSession().role)
                                    })
                                }
                            }).catch(function (err) {
                                counter++;

                                if (counter >= 4) {
                                    that.setState({
                                        summary,
                                        AlertsList,
                                        PortfolioList,
                                        BirthdayList,
                                        PremiumDueList,
                                        LapsedPolicies,
                                        isLoading: false,
                                        //  teammates,
                                        isMgr: isManager(that.props.getSession().role)
                                    })
                                }
                            });

                            that.fetchTeammates().then(function (result) {
                                teammates.push({ value: decrypt(dt.agentNumber), label: `All` });

                                console.log(result);
                                for (let i = 0; i < result.length; i++) {
                                    let faResult = result[i];
                                    teammates.push({ value: faResult.agentNumber, label: `${faResult.name}` })
                                }
                                counter++;

                                that.setState({
                                    teammates,
                                });
                            }).catch(function (err) {
                                counter++;

                                if (counter >= 4) {
                                    that.setState({
                                        summary,
                                        AlertsList,
                                        PortfolioList,
                                        BirthdayList,
                                        PremiumDueList,
                                        LapsedPolicies,
                                        //  teammates,
                                        isLoading: false,
                                        isMgr: isManager(that.props.getSession().role)
                                    })
                                }
                            });

                            that.fetchBirthdays().then(function (result) {
                                BirthdayList = result;
                                counter++;
                                if (counter >= 4) {
                                    that.setState({
                                        summary,
                                        AlertsList,
                                        PortfolioList,
                                        BirthdayList,
                                        PremiumDueList,
                                        LapsedPolicies,
                                        isLoading: false,
                                        // teammates,
                                        isMgr: isManager(that.props.getSession().role)
                                    })
                                }
                            }).catch(function (err) {
                                counter++;

                                if (counter >= 4) {
                                    that.setState({
                                        summary,
                                        AlertsList,
                                        PortfolioList,
                                        BirthdayList,
                                        PremiumDueList,
                                        LapsedPolicies,
                                        isLoading: false,
                                        //  teammates,
                                        isMgr: isManager(that.props.getSession().role)
                                    })
                                }
                            });

                            that.fetchTeammates().then(function (result) {


                                for (let i = 0; i < result.length; i++) {
                                    let faResult = result[i];

                                    teammates.push({ value: faResult.agentNumber, label: `${faResult.name}` })
                                }
                                counter++;
                                if (counter >= 4) {
                                    that.setState({
                                        summary,
                                        AlertsList,
                                        PortfolioList,
                                        BirthdayList,
                                        PremiumDueList,
                                        LapsedPolicies,
                                        isLoading: false,
                                        // teammates,
                                        isMgr: isManager(that.props.getSession().role)
                                    })
                                }
                            }).catch(function (err) {
                                counter++;

                                if (counter >= 4) {
                                    that.setState({
                                        summary,
                                        AlertsList,
                                        PortfolioList,
                                        BirthdayList,
                                        PremiumDueList,
                                        LapsedPolicies,
                                        // teammates,
                                        isLoading: false,
                                        isMgr: isManager(that.props.getSession().role)
                                    })
                                }
                            });
                            that.fetchDuePremiums().then(function (result) {
                                PremiumDueList = result;
                                counter++;
                                if (counter >= 4) {
                                    that.setState({
                                        summary,
                                        AlertsList,
                                        PortfolioList,
                                        BirthdayList,
                                        PremiumDueList,
                                        LapsedPolicies,
                                        //      teammates,
                                        isLoading: false,
                                        isMgr: isManager(that.props.getSession().role)
                                    })
                                }
                            }).catch(function (err) {
                                counter++;

                                if (counter >= 4) {
                                    that.setState({
                                        summary,
                                        AlertsList,
                                        PortfolioList,
                                        BirthdayList,
                                        PremiumDueList,
                                        LapsedPolicies,
                                        //   teammates,
                                        isLoading: false,
                                        isMgr: isManager(that.props.getSession().role)
                                    })
                                }
                            });
                            that.fetchLapsedReport().then(function (result) {
                                LapsedPolicies = result;
                                counter++;
                                if (counter >= 4) {
                                    that.setState({
                                        summary,
                                        AlertsList,
                                        PortfolioList,
                                        BirthdayList,
                                        PremiumDueList,
                                        LapsedPolicies,
                                        //      teammates,
                                        isLoading: false,
                                        isMgr: isManager(that.props.getSession().role)
                                    })
                                }
                            }).catch(function (err) {
                                counter++;

                                if (counter >= 4) {
                                    that.setState({
                                        summary,
                                        AlertsList,
                                        PortfolioList,
                                        BirthdayList,
                                        PremiumDueList,
                                        LapsedPolicies,
                                        //   teammates,
                                        isLoading: false,
                                        isMgr: isManager(that.props.getSession().role)
                                    })
                                }
                            });
                        } else {
                            window.location.href = "/";
                        }
                    }
                });
            console.log(AlertsList.length + " : retrieve");
        } else {

            //To view permissions
            let sessionInfo = await getSessionInfo();
            let viewFAPersistency = await checkPermissions(['VIEW_PERSISTENCY'], sessionInfo[0].permissions);
            if (viewFAPersistency) {
                that.fetchFAPersistencyCardPercentage().then(function (result) {

                    //Mock data
                    if (myConfig.ENABLE_MOCK_DATA) {
                        result = mockPersistencyData['13'];
                    }

                    that.setState({
                        viewFAPersistency: viewFAPersistency,
                        FAPersistencyPercentage: result ? result.goalDetails[0].achievedPersistencyPercentage > 0 ? result.goalDetails[0].achievedPersistencyPercentage + '%' : '--' : '--',
                        FAPersistencyDate: result ? ' For ' + result.goalDetails[0].month.substring(0, 3) + ' ' + result.goalDetails[0].year : 'Persistency',
                        FAPersistencyFormulaName: result ? result.formulaName + '13' : ''
                    })
                }).catch(function (err) {
                    console.log(err);
                    that.setState({
                        viewFAPersistency: viewFAPersistency,
                    })
                });
            }

            that.fetchSummary().then(function (result) {
                summary = result;
                counter++;

                if (counter >= 4) {
                    that.setState({
                        summary,
                        AlertsList,
                        PortfolioList,
                        BirthdayList,
                        PremiumDueList,
                        LapsedPolicies,
                        //  teammates,
                        isLoading: false,
                        isMgr: isManager(that.props.getSession().role)
                    })
                }
            }).catch(function (err) {
                counter++;

                if (counter >= 4) {
                    that.setState({
                        summary,
                        AlertsList,
                        PortfolioList,
                        BirthdayList,
                        PremiumDueList,
                        LapsedPolicies,
                        //  teammates,
                        isLoading: false,
                        isMgr: isManager(that.props.getSession().role)
                    })
                }
            });

            console.log("fetchAlerts : " + that.props.getSession().username);
            that.fetchAlerts(that.props.getSession().username).then(function (result) {
                AlertsList = result;

                counter++;
                if (counter >= 4) {
                    that.setState({
                        summary,
                        AlertsList,
                        PortfolioList,
                        BirthdayList,
                        PremiumDueList,
                        LapsedPolicies,
                        // teammates,
                        isLoading: false,
                        isMgr: isManager(that.props.getSession().role)
                    })
                }
            }, that).catch(function (err) {
                counter++;

                if (counter >= 4) {
                    that.setState({
                        summary,
                        AlertsList,
                        PortfolioList,
                        BirthdayList,
                        PremiumDueList,
                        LapsedPolicies,
                        //    teammates,
                        isLoading: false,
                        isMgr: isManager(that.props.getSession().role)
                    })
                }
            });

            that.fetchRangeOptions().then(function (result) {
                let rangeOptions = [];
                for (let key in result) {
                    let entry = {
                        label: result[key],
                        value: key,
                    };
                    rangeOptions.push(entry);
                }
                that.setState({ rangeOptions });
            });

            that.fetchPortfolio().then(function (result) {
                PortfolioList = result;
                counter++;
                if (counter >= 4) {
                    that.setState({
                        summary,
                        AlertsList,
                        PortfolioList,
                        BirthdayList,
                        PremiumDueList,
                        LapsedPolicies,
                        // teammates,
                        isLoading: false,
                        isPortfolioStatusLoading: false,
                        isPortfolioProductLoading: false,
                        isMgr: isManager(that.props.getSession().role),
                    });
                }
            }).catch(function (err) {
                counter++;

                if (counter >= 4) {
                    that.setState({
                        summary,
                        AlertsList,
                        PortfolioList,
                        BirthdayList,
                        PremiumDueList,
                        LapsedPolicies,
                        //teammates,
                        isLoading: false,
                        isMgr: isManager(that.props.getSession().role)
                    })
                }
            });

            that.fetchPortfolioFilter(decrypt(that.props.getSession().agentNumber), this.state.selectedRangeProd.value, "PRODUCT", "ALL").then(function (result) {
                PortfolioListProd = result;
                counter++;
                if (counter >= 4) {
                    that.setState({
                        summary,
                        AlertsList,
                        PortfolioList,
                        PortfolioListProd,
                        BirthdayList,
                        PremiumDueList,
                        LapsedPolicies,
                        // teammates,
                        isLoading: false,
                        isPortfolioStatusLoading: false,
                        isPortfolioProductLoading: false,
                        isMgr: isManager(that.props.getSession().role),
                    });
                }
            }, that).then(function (result) {
                that.setState({
                    isPortfolioProductLoading: false,
                });
            }).catch(function (err) {
                counter++;

                if (counter >= 4) {
                    that.setState({
                        summary,
                        AlertsList,
                        PortfolioList,
                        BirthdayList,
                        PremiumDueList,
                        LapsedPolicies,
                        //teammates,
                        isLoading: false,
                        isMgr: isManager(that.props.getSession().role)
                    })
                }
            });

            that.fetchTeammates().then(function (result) {

                teammates.push({ value: decrypt(session.agentNumber), label: `All` });

                for (let i = 0; i < result.length; i++) {
                    let faResult = result[i];

                    teammates.push({ value: faResult.agentNumber, label: `${faResult.name}` });
                }

                counter++;

                that.setState({
                    teammates,
                })
            }).catch(function (err) {
                counter++;

                if (counter >= 4) {
                    that.setState({
                        summary,
                        AlertsList,
                        PortfolioList,
                        BirthdayList,
                        PremiumDueList,
                        LapsedPolicies,
                        teammates,
                        isLoading: false,
                        isMgr: isManager(that.props.getSession().role)
                    })
                }
            });

            that.fetchBirthdays().then(function (result) {
                BirthdayList = result;
                counter++;
                if (counter >= 4) {
                    that.setState({
                        summary,
                        AlertsList,
                        PortfolioList,
                        BirthdayList,
                        PremiumDueList,
                        LapsedPolicies,
                        teammates,
                        isLoading: false,
                        isMgr: isManager(that.props.getSession().role)
                    })
                }
            }).catch(function (err) {
                counter++;

                if (counter >= 4) {
                    that.setState({
                        summary,
                        AlertsList,
                        PortfolioList,
                        BirthdayList,
                        teammates,
                        PremiumDueList,
                        LapsedPolicies,
                        isLoading: false,
                        isMgr: isManager(that.props.getSession().role)
                    })
                }
            });

            that.fetchDuePremiums().then(function (result) {
                PremiumDueList = result;
                counter++;
                if (counter >= 4) {
                    that.setState({
                        summary,
                        AlertsList,
                        PortfolioList,
                        BirthdayList,
                        PremiumDueList,
                        LapsedPolicies,
                        teammates,
                        isLoading: false,
                        isMgr: isManager(that.props.getSession().role)
                    })
                }
            }).catch(function (err) {
                counter++;

                if (counter >= 4) {
                    that.setState({
                        summary,
                        AlertsList,
                        PortfolioList,
                        BirthdayList,
                        PremiumDueList,
                        LapsedPolicies,
                        teammates,
                        isLoading: false,
                        isMgr: isManager(that.props.getSession().role)
                    })
                }
            });

            that.fetchLapsedReport().then(function (result) {
                LapsedPolicies = result;
                counter++;
                if (counter >= 4) {
                    that.setState({
                        summary,
                        AlertsList,
                        PortfolioList,
                        BirthdayList,
                        PremiumDueList,
                        LapsedPolicies,
                        teammates,
                        isLoading: false,
                        isMgr: isManager(that.props.getSession().role)
                    })
                }
            }).catch(function (err) {
                counter++;

                if (counter >= 4) {
                    that.setState({
                        summary,
                        AlertsList,
                        PortfolioList,
                        BirthdayList,
                        PremiumDueList,
                        LapsedPolicies,
                        teammates,
                        isLoading: false,
                        isMgr: isManager(that.props.getSession().role)
                    })
                }
            });
            console.log(AlertsList.length + " : ee retrieve");
        }
    }
    errorMessage = (message) => {
        if (message) {
            this.setState({ error: message })
        }
    }


    updateSelectedTeammateStatus = (option) => {
        // TRIGGERS ON SELECTED OPTIONS
        let PortfolioList = [];
        let that = this;

        this.setState({
            isPortfolioStatusLoading: true,
        });

        this.fetchPortfolioFilter(option.value, this.state.selectedRangeStatus.value, "PERIOD")
            .then(function (result) {
                PortfolioList = result;

                that.setState({
                    PortfolioList,
                    selectedTeammateStatus: option,
                    isPortfolioStatusLoading: false,
                });
            });
    }

    updateSelectedRangeStatus = (option) => {
        let PortfolioList = [];
        let session = this.props.getSession();
        let that = this;

        let agentNumber;

        if (this.state.selectedTeammateStatus) {
            agentNumber = this.state.selectedTeammateStatus.value;
        } else {
            agentNumber = decrypt(session.agentNumber);
        }

        this.setState({
            isPortfolioStatusLoading: true,
        });

        this.fetchPortfolioFilter(agentNumber, option.value, "PERIOD")
            .then(function (result) {
                PortfolioList = result;

                that.setState({
                    PortfolioList,
                    selectedRangeStatus: option,
                    isPortfolioStatusLoading: false,
                });
            });
    }

    updateSelectedTeammateProd = (option) => {
        // TRIGGERS ON SELECTED OPTIONS
        let PortfolioListProd = [];
        let that = this;
        let productOfferingType = "ALL";
        let filterType = "PRODUCT";

        this.setState({
            isPortfolioProductLoading: true,
        });

        this.fetchPortfolioFilter(option.value, this.state.selectedRangeProd.value, filterType, productOfferingType)
            .then(function (result) {
                PortfolioListProd = result;

                that.setState({
                    PortfolioListProd,
                    selectedTeammateProd: option,
                    isPortfolioProductLoading: false,
                });
            });
    }

    updateSelectedRangeProd = (option) => {
        let PortfolioListProd = [];
        let session = this.props.getSession();
        let that = this;
        let productOfferingType = "ALL";
        let filterType = "PRODUCT";

        let agentNumber;

        if (this.state.selectedTeammateProd) {
            agentNumber = this.state.selectedTeammateProd.value;
        } else {
            agentNumber = decrypt(session.agentNumber);
        }

        this.setState({
            isPortfolioProductLoading: true,
        });

        this.fetchPortfolioFilter(agentNumber, option.value, filterType, productOfferingType)
            .then(function (result) {
                PortfolioListProd = result;

                that.setState({
                    PortfolioListProd,
                    selectedRangeProd: option,
                    isPortfolioProductLoading: false,
                });
            });
    }

    checkPermissions = (permissionsList, key) => {

        let isAllowed = false;

        permissionsList.forEach(function (permissions, i) {
            if (permissions.name === key) {
                isAllowed = true;
            }
        });

        return isAllowed;
    }

    fetchTeammates = () => {
        let session = this.props.getSession();
        let that = this;
        let headerParams = {
            accountManagerId: decrypt(session.agentNumber),
            x_auth: session.access_token,
        };

        let payloadParams = {};
        return new Promise(function (resolve, reject) {
            let filter = [];

            if (isManager(that.props.getSession().role)) {
                apiModule("get_accountmanager_team", headerParams, null, createQueryStrings(filter))
                    .then(function (response) {
                        let result = response.content;
                        resolve(result);
                    })
                    .catch(function (err) {
                        resolve([]);
                    })
            } else {
                resolve([]);
            }

        });
    }

    fetchRangeOptions = () => {
        let session = this.props.getSession();
        let dataListId = 'DATASETPERIOD';
        let headerParams = {
            x_auth: session.access_token,
            dataListId: dataListId,
        };
        return new Promise(function (resolve, reject) {
            apiModule("get_datalist", headerParams)
                .then(function (result) {
                    resolve(result);
                })
                .catch(function (err) {
                    resolve([]);
                    console.log("Something went wrong...");
                    console.log(err);
                });
        });
    }

    fetchAlerts = (username) => {
        let session = this.props.getSession();
        console.log("channel : " + session.channel);

        let isIU = isInternalUser(session.channel);

        let that = this;

        let headerParams = {
            accountManagerId: decrypt(username),
            x_auth: session.access_token,
        };

        if (isIU) {
            headerParams = {
                accountManagerId: decrypt(session.view_as_email),
                x_auth: session.access_token,
            };
        }

        console.log(session);
        let payloadParams = {};
        let filter = [];

        filter['page_size'] = 10;
        return new Promise(function (resolve, reject) {
            if (username === "" || username === null || username === undefined) {
                resolve([]);
            } else {
                apiModule("get_inbox_dashboard", headerParams, payloadParams, createQueryStrings(filter))
                    .then(function (reply) {
                        let result = reply.message.data;

                        // resolve(result.message.slice(Math.max(result.message.length - 6, 1)));
                        resolve(result);
                    })
                    .catch(function (err) {
                        resolve([]);
                    })
            }
        });
    }
    fetchPortfolioFilter = (agentNumber, period, filterType, productOfferingType) => {
        let session = this.props.getSession();
        let that = this;
        let headerParams = {
            accountManagerId: agentNumber,
            x_auth: session.access_token,
            datasetId: 'portfolio',
        };

        let queryString = createQueryStrings({ filterType, period, productOfferingType });
        let result = null;

        // INPUT MORE PARAMETERS BASED ON TICKET
        let payloadParams = {};

        result = new Promise(function (resolve, reject) {
            apiModule("get_dashboard_dataset", headerParams, payloadParams, queryString)
                .then(function (result) {
                    resolve(result.data.portfolio);
                })
                .catch(function (err) {
                    resolve([]);
                    console.log("Something went wrong..");
                    console.log(err);
                })
        });

        return result;
    }
    fetchPortfolio = () => {
        let session = this.props.getSession();
        let that = this;
        let period = 'ALL';
        let filterType = 'PERIOD';
        let headerParams = {
            accountManagerId: decrypt(session.agentNumber),
            x_auth: session.access_token,
            datasetId: 'portfolio',
        };
        let queryString = createQueryStrings({ filterType, period });

        let payloadParams = {};
        return new Promise(function (resolve, reject) {
            apiModule("get_portfolio_dataset", headerParams, payloadParams, queryString)
                .then(function (result) {

                    resolve(result.data.portfolio);

                })
                .catch(function (err) {
                    resolve([]);
                })
        })
    }
    fetchDuePremiums = () => {
        let session = this.props.getSession();
        let that = this;
        let headerParams = {
            accountManagerId: decrypt(session.agentNumber),
            x_auth: session.access_token,
            datasetId: 'duepremiums',

        };

        let payloadParams = {};
        return new Promise(function (resolve, reject) {
            apiModule("get_dashboard_dataset", headerParams, payloadParams)
                .then(function (result) {

                    resolve(result.data.duepremiums);

                })
                .catch(function (err) {
                    resolve([]);
                })
        });
    }
    fetchLapsedReport = () => {
        let session = this.props.getSession();
        let that = this;
        let headerParams = {
            accountManagerId: decrypt(session.agentNumber),
            x_auth: session.access_token,
            datasetId: 'lapsedpolicies',

        };

        let payloadParams = {};
        return new Promise(function (resolve, reject) {
            apiModule("get_dashboard_dataset", headerParams, payloadParams)
                .then(function (result) {
                    resolve(result.data.lapsedPolicies);

                })
                .catch(function (err) {
                    resolve([]);
                })
        });
    }
    OnlineSI = () => {
        let channel = this.props.getSession().channel;
        channel = channel.toLowerCase();
        window.location = createOnlineSILink(channel);
    }
    fetchBirthdays = () => {
        let session = this.props.getSession();
        let that = this;
        // let period = 'ALL';
        // let productOfferingType = 'PERIOD';
        // let queryString = createQueryStrings({ productOfferingType, period });
        let headerParams = {
            accountManagerId: decrypt(session.agentNumber),
            x_auth: session.access_token,
            datasetId: 'birthdays',
        };

        let payloadParams = {};
        return new Promise(function (resolve, reject) {
            apiModule("get_dashboard_dataset", headerParams, payloadParams)
                .then(function (result) {

                    resolve(result.data.birthdays);

                })
                .catch(function (err) {
                    resolve([]);
                })
        })
    }
    fetchSummary = () => {
        let session = this.props.getSession();
        let that = this;
        let headerParams = {
            accountManagerId: decrypt(session.agentNumber),
            x_auth: session.access_token,
            datasetId: 'summary',
        };

        let payloadParams = {};
        return new Promise(function (resolve, reject) {
            apiModule("get_dashboard_dataset", headerParams, payloadParams)
                .then(function (result) {
                    resolve(result.data.summary);
                })
                .catch(function (err) {
                    resolve([]);
                })
        })
    }
    //Clients, Active Policies, Newly Assigned, Upcoming Birthdays CARDS UI
    dataCards = (css) => {
        // const loaderStyle = { border: "5px solid #f3f3f3", borderTop: "5px solid black", width: "30px", height: "30px" };
        // const loader = <LoaderGif2 css="ml-auto" style={loaderStyle} />;
        const loader = <LoaderGif3 css="ml-auto" style={{ width: this.props.isSm ? "4rem" : "2.5rem" }} />
        const cardData = {
            'Clients': {
                'id': 1,
                'bg': {
                    'color': '#F37449',
                    'img': 'card-client.png'
                },
                'value': this.state.summary.clients,
                'url': '/dashboard/client-information'
            },
            'Active Policies': {
                'id': 2,
                'bg': {
                    'color': '#B4DCBB',
                    'img': 'card-policy.png'
                },
                'value': this.state.summary.activePolicies,
                'url': '/dashboard/list-of-policies'
            },
            'Newly Assigned Policies': {
                'id': 3,
                'bg': {
                    'color': '#43C1C0',
                    'img': 'card-assign.png'
                },
                'value': this.state.summary.newlyAssigned,
                'url': !this.state.summary.newlyAssigned ? '#' : '/dashboard/newly-assigned-policies'
            },
        };
        let cards = [];
        for (let key in cardData) {
            if (cardData.hasOwnProperty(key)) {

                cards.push(
                    // parent div has ROW class
                    <div key={cardData[key].id} className={`dashboard-card-container ${css} align-items-stretch mb-4`}>
                        <a className="text-decoration-none" onClick={() => routeChange(this.props.history, cardData[key].url)}>
                            <div
                                className="card overflow-hidden shadow-sm w-100 h-100 dashboard__card"
                                style=
                                {{
                                    backgroundColor: cardData[key].bg.color,
                                    cursor: cardData[key].value ? 'pointer' : ""
                                }}>

                                <img className="card-bg-img" src={require(`../assets/img/${cardData[key].bg.img}`)} />
                                <div className="dashboard-card__data card-body dashboard__card-body text-right d-flex justify-content-end align-items-center">

                                    <div className="card-body-content h-100 d-flex flex-column">
                                        <h1 className="card-title dashboard__card-title--mobile dashboard__card-title font-neo-bold mb-2">{(cardData[key].value || cardData[key].value === 0) ? cardData[key].value : loader}</h1>
                                        <h5 className="card-subtitle dashboard__card-subtitle--mobile dashboard__card-subtitle font-neo-semibold">{key}</h5>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                );
            }
        }
        let isMgr = isManager(this.props.getSession().role); //isManager
        let user = (this.props.getSession().role).toLowerCase(); //userRole
        let channel = this.props.getSession().channel; // ONLINE SI
        channel = channel.toLowerCase(); // ONLINE SI
        let link = DOMPurify.sanitize("https://salesportal.allianzpnblife.ph/" + channel); // ONLINE SI
        let { FAPersistencyPercentage, FAPersistencyDate, FAPersistencyFormulaName, viewFAPersistency } = this.state;
        if (viewFAPersistency) {
            return (
                <div className="dashboard-cards row">
                    {cards}
                    <div style={{ cursor: 'pointer' }} className={`dashboard-card-container ${css} align-items-stretch mb-4`}>
                        <a className="text-decoration-none" onClick={() => { this.setPersistencyModal(true) }}>
                            <div
                                className="card overflow-hidden shadow-sm w-100 h-100 dashboard__card"
                                style=
                                {{
                                    backgroundColor: "#89679C",
                                }}>
                                <img className="card-bg-img" src={require(`../assets/img/persistency-icon.png`)} />
                                <div className="dashboard-card__data card-body dashboard__card-body text-right d-flex justify-content-end align-items-center">
                                    <div className="card-body-content h-100 d-flex flex-column justify-content-between">
                                        {isMgr ? user === "regional business manager" && FAPersistencyPercentage === '--' ? '' : <h5 className="card-subtitle dashboard__card-subtitle-persistency--mobile dashboard__card-subtitle-persistency font-neo-semibold">Personal Persistency</h5> : '' }
                                        <h1 className="card-title dashboard__card-title-persistency--mobile dashboard__card-title font-neo-bold mb-2">{FAPersistencyPercentage}</h1>
                                        <h5 className="card-subtitle dashboard__card-subtitle-persistency--mobile dashboard__card-subtitle font-neo-semibold">{FAPersistencyFormulaName + ' ' + FAPersistencyDate}</h5>
                                        {isMgr ? user === "regional business manager" && FAPersistencyPercentage === '--' ? '' : <h5 className="card-subtitle dashboard__card-desc--mobile dashboard__card-desc font-neo-semibold">Overall Team Persistency rating is not yet available</h5> : '' }
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            )
        }
        else {
            return (
                <div className="dashboard-cards row">
                    {cards}
                    <div className={`dashboard-card-container ${css} align-items-stretch mb-4`}>
                        <a className="text-decoration-none" href={link} target="_blank">
                            <div
                                className="card overflow-hidden shadow-sm w-100 h-100 dashboard__card"
                                style=
                                {{
                                    backgroundColor: "#89679C",
                                }}>
                                <img className="card-bg-img" src={require(`../assets/img/card-report-status.png`)} />
                                <div className="dashboard-card__data card-body dashboard__card-body text-right d-flex justify-content-end align-items-center">
                                    <div className="card-body-content">
                                        <h5 className="card-subtitle dashboard__card-subtitle--mobile font-neo-semibold" onClick={() => this.OnlineSI()}>Online SI</h5>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            );
        }
    }

    renderStatusPortfolio = () => {
        const usersWithTeam = ["sm", "rbm"];
        if (this.state.teammates.length > 0) {
            return (
                <StatusPortfolio
                    list={this.state.PortfolioList}
                    showDropdown={usersWithTeam.includes(this.state.authUserType) ? true : false}
                    dropdownItems={this.state.teammates}
                    updateSelectedOption={this.updateSelectedTeammateStatus}
                    updateSelectedRange={this.updateSelectedRangeStatus}
                    selectedOption={this.state.selectedTeammateStatus}
                    rangeOptions={this.state.rangeOptions}
                    selectedRange={this.state.selectedRangeStatus}
                    isMgr={isManager(this.props.getSession().role)}
                    css="col-md-6"
                    isPortfolioLoading={this.state.isPortfolioStatusLoading}
                />
            )
        } else {
            return (<div></div>);
        }
    }

    renderProductPortfolio = () => {
        const usersWithTeam = ["sm", "rbm"];

        if (this.state.teammates.length > 0) {
            return (
                <ProductPortfolio
                    list={this.state.PortfolioListProd}
                    showDropdown={usersWithTeam.includes(this.state.authUserType) ? true : false}
                    dropdownItems={this.state.teammates}
                    updateSelectedOption={this.updateSelectedTeammateProd}
                    updateSelectedRange={this.updateSelectedRangeProd}
                    selectedOption={this.state.selectedTeammateProd}
                    rangeOptions={this.state.rangeOptions}
                    selectedRange={this.state.selectedRangeProd}
                    isMgr={isManager(this.props.getSession().role)}
                    css="col-md-6"
                    isPortfolioLoading={this.state.isPortfolioProductLoading}
                />
            )
        } else {
            return (<div></div>);
        }
    }

    onClickPolicyOwner = (item) => {
        this.props.setClientOrPolicy({ clientId: item });
        writeData('policy-client', { clientId: item, id: 1 });
        routeChange(this.props.history, "/dashboard/single-client");
    }

    onClickPolicyNumber = (item) => {
        this.props.setClientOrPolicy({ policyId: item });
        writeData('policy-client', { policyId: item, id: 1 });
        routeChange(this.props.history, "/dashboard/single-policy");
    }

    render() {
        if (this.state.isLoading) {
            return (
                <div className="dashboard-section">
                    <ReportLoader handleClose={this.hideModal} show={this.state.isLoading} />
                </div>
            );
        } else {

            return (
                <div className="dashboard-section">
                    <div className="dashboard-container container-fluid p-3">
                        <div className="dashboard-header">
                            <h6 className="font-neo-bold text-darkgray">Dashboard</h6>
                            {this.props.getViewAs}
                        </div>
                        {this.state.showPersistency === true ? <FaPersistency {...this.props} getSession={this.props.getSession()} hidePersistency={() => { this.setPersistencyModal(false) }} showLink={isManager(this.props.getSession().role)} /> : null}
                        <div className="dashboard-body mt-4">
                            {this.dataCards("col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3")}
                            <div className="dashboard-data-container row align-items-stretch">
                                <PremiumDue onClickPolicyNumber={this.onClickPolicyNumber} onClickPolicyOwner={this.onClickPolicyOwner} list={this.state.PremiumDueList} css="col-md-6" rows="6" isMgr={this.state.isMgr} />
                                <BirthdayList list={this.state.BirthdayList} css="col-md-6" rows="6" />
                                <DashboardLapsedReport onClickPolicyNumber={this.onClickPolicyNumber} onClickPolicyOwner={this.onClickPolicyOwner} list={this.state.LapsedPolicies} isMgr={this.state.isMgr} css="col-md-6" rows="6" />
                                <AlertsList list={this.state.AlertsList} css="col-md-6" rows="4" />
                                {/* <input type="button" onClick={() => this.props.setViewAs("test","test 123")} value="test"/> */}
                                {this.renderStatusPortfolio()}
                                {this.renderProductPortfolio()}
                                <FundChart
                                    css="col"
                                    getSession={() => this.props.getSession()}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
}
export default withRouter(withWindowDimensions(Dashboard));
