import React, { Component } from 'react';
import SectionHeader from '../components/sectionHeader';
import MiniLoading from '../components/miniLoading';
import { getContestList } from '../commons/restEndpoint';
import LeaderboardSectionListItem from '../components/leaderboardSectionListItem';

class LeaderboardSection extends Component {

    constructor(props) {
        super(props)

        this.state = {
            contestList: [],
            doneGettingContestList: false,
        }

        this.fetchContestList = this.fetchContestList.bind(this);
    }

    async fetchContestList() {
        let params = {
            status: 'active'
        }
        let x = await getContestList(params, this.props.session.access_token);
        if (x) {
            if (x.data) {
                this.setState({
                    contestList: x.data,
                    doneGettingContestList: true,
                });
            }
        }
    }

    componentDidMount() {
        this.fetchContestList();
    }

    render() {
        const { doneGettingContestList } = this.state
        return (
            <div>
                <div className="container rni-leaderboard-section">
                    <SectionHeader label="Active contests with rankings" src="/rni/trophy.png" />
                    {doneGettingContestList ? <div className="rni-leaderboard-section-contest-list row">
                        {this.state.contestList.map(function (contest, index) {
                            let alt = false;
                            if (index % 2 !== 0) {
                                alt = true;
                            }
                            return (
                                <div key={index} className="col-md-6 col-xs-12 col-sm-12 col-lg-6"
                                    style={{ paddingBottom: '15px' }}>
                                    <LeaderboardSectionListItem data={contest} alternate={alt} />
                                </div>
                            );
                        })}
                    </div> : <div style={{ width: '50vw', margin: 'auto' }}><MiniLoading /></div>}
                </div>
            </div>
        )
    }
}

export default LeaderboardSection