import React from 'react';
import ReactDOM from 'react-dom';
// import './App.css';
import './App.scss';
//BOOTSTRAP
import 'jquery/dist/jquery';
import 'popper.js/dist/umd/popper';
import 'bootstrap/dist/js/bootstrap';
//END BOOTSTRAP

//change the global default of react table
import { ReactTableDefaults } from 'react-table';
import TablePagination from './components/tablePagination';
import ReactTableTh from './components/reactTableTh';
import TableRowIndicator from './components/tableRowIndicator';
//Using bootstrap instead
//import './css/Responsive.css';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import ReactGA from 'react-ga4';
import { myConfig } from '../src/commons/config';

ReactGA.initialize(myConfig.GA_TRACKING_ID, {
    debug: true
});

//Bootstrap already imported in App.css
//import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
Object.assign(ReactTableDefaults, {
    PaginationComponent: TablePagination,
    ThComponent: ReactTableTh,
    // onPageChange: TableRowIndicator,
})
//import '../node_modules/font-awesome/css/font-awesome.min.css';
ReactDOM.render(<App />, document.getElementById('root'));

registerServiceWorker();