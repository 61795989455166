import React from "react";
import Auxi from '../../auxi';
import _ from 'lodash';

const premiumDueListMobile = (props) => {
    const customName = [...props.customNames];
    const data = props.data;
    const title = props.title;
    const titleAccessors = title.map((item) => {
        return item.accessor;
    })
    const customNamesInside = _.pullAllBy(customName, title, 'accessor');
    let accord = data.map((item, index) => {
        const itemArray = Object.keys(item);
        const columnsInside = _.pullAll(itemArray, titleAccessors);
        let columns = [];
        let i = 0;
        let columnValue = "";
        while (i < columnsInside.length) {
            if (customNamesInside[i]) {
                if (customNamesInside[i].Cell) {
                    columnValue = customName[i].Cell({ value: item[columnsInside[i]] ? item[columnsInside[i]] : "" });
                } else {
                    columnValue = item[columnsInside[i]] ? item[columnsInside[i]] : "";
                }
                if (i % 2 === 1) {
                    columns.push(
                        <div className="data-accord__column col-7" key={i}>
                            <div className="data-accord__label">{customNamesInside[i].Header ? customNamesInside[i].Header : ""}</div>
                            <div className="data-accord__value">{columnValue}</div>
                        </div>
                    )
                } else {
                    columns.push(
                        <div className="data-accord__column col-5" key={i}>
                            <div className="data-accord__label">{customNamesInside[i].Header ? customNamesInside[i].Header : ""}</div>
                            <div className="data-accord__value">{columnValue}</div>
                        </div>
                    )
                }
            } else {
                columns.push("");
            }
            i++;
        }
        return (
            <Auxi key={item.key}>
                <div className={`data-accord__card ${index > 0 ? "mt-3" : ""} card border-0`}>
                    <div className="data-accord__toggle btn">
                        <div className="row data-accord__start">
                            <div className="col-5">
                                <button className="data-accord__name data-accord__name--clear">{title[0].Cell ? title[0].Cell({ value: item[titleAccessors[0]] ? item[titleAccessors[0]] : "" }) : item[titleAccessors[0]] ? item[titleAccessors[0]] : ""}</button>
                            </div>
                            <div className="col-7 d-flex justify-content-between">
                                <div className="font-neo-semibold">{title[1].Cell ? title[1].Cell({ value: item[titleAccessors[1]] ? item[titleAccessors[1]] : "" }) : item[titleAccessors[1]] ? item[titleAccessors[1]] : ""}</div>
                                
                                <div></div>
                                <button className="btn p-0" type="button" data-toggle="collapse" data-target={`#collapse-${index}`}>
                                    <div className="data-accord__caret">
                                        <div className="data-accord__caret-icon"></div>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div id={`collapse-${index}`} className="collapse" data-parent="#data-accord">
                        <div className="card-body data-accord__content">
                            <div className="row">
                                {columns}
                            </div>
                            {props.onClickAction && props.hasOnClickBtn? 
                                <div className="row">
                                    <div className="data-accord__column col-12 d-flex align-items-end justify-content-end">
                                        <button onClick={() => handleClickSummary(item, props)} className="btn btn-darkgreen rounded-pill data-accord__button">Summary</button>
                                    </div>
                                </div>
                            : ''}
                        </div>
                    </div>
                </div>
            </Auxi >
        );
    });




    return (
        <div className="data-accord row flex-wrap mt-4">
            <div className="data-accord__header">
                <div className="col-5">
                    <div className={`d-inline-flex align-items-center ${title[0].hasCaret ? "cs-pointer" : ""}`} onClick={title[0].onClick}>
                        {title[0].Header ? title[0].Header : ""} {title[0].hasCaret ? (
                            // <span className="icon-popout"></span>
                            <span className={
                                title[0].accessor == props.sortByField ?
                                    (props.sortOrder == "ASC" ?
                                        "icon-chevron-small-up"
                                        : "icon-chevron-dropdown"
                                    ) : "icon-popout"
                            }>
                            </span>
                        ) : ""}
                    </div>
                </div>
                <div className="col-7">
                    <div className={`d-inline-flex align-items-center ${title[1].hasCaret ? "cs-pointer" : ""}`} onClick={title[1].onClick}>
                        {title[1].Header ? title[1].Header : ""} {title[1].hasCaret ? (
                            <span className={
                                title[1].accessor == props.sortByField ?
                                    (props.sortOrder == "ASC" ?
                                        "icon-chevron-small-up"
                                        : "icon-chevron-dropdown"
                                    ) : "icon-popout"
                            }>
                            </span>
                        ) : ""}
                    </div>
                </div>
            </div>
            <div className="col-12 data-accord__body mt-1 position-relative">
                <div className="data-accord__collapse" id="data-accord">
                    {accord}
                </div>
                {props.children}
            </div>
        </div>
    );
}

function handleClickSummary (item, props) {
    let id = props.isClient ? item.key : item.POL_NO;
    props.onClickAction(id);
}

export default premiumDueListMobile;