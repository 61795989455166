import React, { Component } from 'react';
import AuthLoader from '../commons/authLoader';

class Otp extends Component {
    state = {
        first: { name: "first", index: 0, value: "" },
        second: { name: "second", index: 1, value: "" },
        third: { name: "third", index: 2, value: "" },
        fourth: { name: "fourth", index: 3, value: "" },
        fifth: { name: "fifth", index: 4, value: "" },
        sixth: { name: "sixth", index: 5, value: "" },
    }

    otpKeyList = ["first", "second", "third", "fourth", "fifth", "sixth"]

    showError = () => {
        let message = '';
        if (this.props.error !== '') {
            message =
                <div className="form-group mb-4 text-white">
                    <p>{this.props.error}</p>
                </div>
        }
        return message;
    }

    handleChange = (e) => {
        const otpInputs = this.state;
        const inputId = e.target.id;
        const otpInputIndex = this.otpKeyList.indexOf(inputId);

        let value;
        if(e.type === "keydown") {
            if(this.isBackspaceOrDelete(e)) {
                value = e.key;
            }
            else if(this.isEnter(e)) {
                this.props.handleSubmit(this.state);
            }
        }
        if(this.isChangeEvent(e)) {
            const changeValue = e.target.value;
            value = changeValue.charAt(changeValue.length - 1);
        }
        // if(this.isBackspaceOrDelete(e)) {
        //     value = e.key;
        // }
        // else if (this.isEnter(e)) {
        //     this.props.handleSubmit(this.state);
        // }
        // else if(this.isChangeEvent(e)) {
        //     value = e.target.value;
        // }

        if(value === "Backspace" || value === "Delete") {
            otpInputs[inputId].value = "";

            if(value === "Backspace") {
                if (otpInputIndex === 0) {
                    e.target.select();
                }
                else {
                    document.getElementById(this.otpKeyList[otpInputIndex - 1]).select();
                    e.preventDefault();
                }
            }
            else {
                e.target.select();
            }
        }
        else {
            if(!isNaN(value) && value !== "") {
                otpInputs[inputId].value = value;
                if (otpInputIndex !== 5) {
                    document.getElementById(this.otpKeyList[otpInputIndex + 1]).select();
                    e.preventDefault();
                }
            }
        }

        this.setState(otpInputs, () => {
            const otpInputCount = Object.keys(this.state).length;
            if (this.isOtpComplete(otpInputs) && otpInputIndex === otpInputCount - 1) {
                this.props.handleSubmit(this.state)
            }
            else if(value === "Enter") {
                this.props.handleSubmit(this.state)
            }
        })
    }

    isBackspaceOrDelete(e) {
        return e.type === "keydown" && (e.key === "Backspace" || e.key === "Delete");
    }

    isEnter(e) {
        return e.type === "keydown" && e.key === "Enter";
    }

    isChangeEvent(e) {
        return e.type === "change";
    }
    
    isKeyDownEvent(e) {
        return e.type === "keydown"
    }

    getEventValue(e) {
    }

    /**
     * Returns whether the passed otpInputs object is compeletely filled out
     * 
     * @param {object} otpInputs 
     * @returns {boolean}
     */
    isOtpComplete(otpInputs) {
        const emptyCells = Object.keys(otpInputs)
            .filter(otpInput => otpInputs[otpInput].value === "");
        return emptyCells.length === 0;
    }

    render() {
        return (
            <div className="verification-container">
                <form>
                    <h2 className="text-white mb-5 text-left text-md-center verify__header">Enter Verification Code</h2>
                    {this.showError()}
                    <div className="js-fields verification-fields form-group mb-5">
                        {Object.keys(this.state).map(key => (
                            <input
                                className="text-center auth__digit-field form-control"
                                value={this.state[key].value}
                                onChange={this.handleChange}
                                onKeyDown={this.handleChange}
                                id={key}
                                name={key}
                                minLength="1"
                                maxLength="1"
                                key={key}
                                type="number"
                                required />
                        ))}
                    </div>

                    <a onClick={() => this.props.handleSubmit(this.state)} className={`auth__button btn ${this.props.isLoading ? "btn-loading pes-none" : "btn-navyblue"}  text-uppercase btn-block text-white`}>
                        {this.props.isLoading ? <AuthLoader width="60" /> : "Verify"}
                    </a>

                    <div className="d-flex justify-content-center">
                        <a onClick={this.props.handleResend} className="auth__small-text text-white text-decoration-none mt-3 text-center">Didn't get a verification code?</a>
                    </div>
                </form>
            </div>
        )
    }
}

export default Otp;