import React, { Component } from 'react';
import '../../css/mis.scss';
import { apiModule, createQueryStrings } from '../../commons/apiCall';
import HorizontalBarChart from './horizontalBarChart';
import { haveValue, labelXAxis, showNoData, charts } from '../../commons/misConfig';
import { thousandSeparator, formatValues, formatValuesNoDecimals } from './formatter';
import CommentIcon from './commentIcon';

class FundAllocation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isChannel: false,
            channelData: [],
            data: {
                labels: [],
                datasets: []
            },
            isLoading: true
        }

        this.getFundAllocation = this.getFundAllocation.bind(this);
    }

    componentWillMount() {
        this.getFundAllocation(this.props);
        this.props.getCommentCount(this, 'fund', this.props.access_token);
        this.setState({
            ...this.props
        })
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.willUpdate) {
            this.setState({
                ...nextProps,
                isLoading: true
            })

            this.getFundAllocation(nextProps);
        } else {
            this.setState({
                willUpdate: nextProps.willUpdate
            })
        }

        if(nextProps.willUpdateCommentCount){
            nextProps.getCommentCount(this, 'fund', nextProps.access_token);
        }
    }

    getFundAllocation(props) {
        let that = this;
        let headerParams = {
            x_auth: props.access_token
        };

        let filter = []
        filter['metric'] = haveValue(props.metric) ? props.metric.value : 'ALL';
        filter['period'] = haveValue(props.period) ? props.period.value : 'ALL';
        filter['startDate'] = haveValue(props.startDate) ? props.startDate : '';
        filter['endDate'] = haveValue(props.endDate) ? props.endDate : '';
        filter['productOfferingType'] = haveValue(props.product) ? props.product.value : '';
        filter['channel'] = haveValue(props.channel) ? props.channel.value : '';
        filter['contractStatus'] = haveValue(props.status.value) ? props.status.value : '';
        filter['targetType'] = haveValue(props.target.value) ? props.target.value : '';

        apiModule("get_fund_allocation", headerParams, null, createQueryStrings(filter))
            .then(function (result) {
                let dataObject = {
                    labels: [],
                    datasets: [{
                        data: [],
                        dataName: [],
                        dataPct: [],
                        borderWidth: 2,
                        barThickness: 15,
                        borderColor: "#78DCFA",
                        backgroundColor: "#78DCFA"
                    }]
                };

                if (result.goalDetails) {
                    result.goalDetails.map(function (obj, index) {
                        dataObject.labels[index] = obj.fundCode || "";
                        dataObject.datasets[0].dataName[index] = obj.fundName || "";
                        dataObject.datasets[0].data[index] = obj.achievedFundAllocationAmount || 0; 
                        dataObject.datasets[0].dataPct[index] = obj.achievedFundAllocationPercentage || 0;
                    })
                }


                that.setState({
                    data: dataObject,
                    isLoading: false
                });

            })
            .catch(function (err) {
                console.log(err);
            });
            

        // let commentHeaderParams = { chartId: 'fund', x_auth: props.access_token };
        // let commentFilter = [];
        // commentFilter['filter'] = 'count';

        // apiModule("get_comments", commentHeaderParams, null, createQueryStrings(commentFilter))
        //     .then(function (result) {
        //         that.setState({
        //             totalComments: result.totalComments ? result.totalComments : 0
        //         })
        //     })
        //     .catch(function (err) {
        //         console.log(err);
        //     });

    }

    render(){
        const { data, willUpdate, isLoading, totalComments, currTarget } = this.state;
        const { currStatus, coinLoader, onClickCommentIcon } = this.props;
        let isSubmitted = currStatus.value.toUpperCase().includes('SUBMITTED');
        let isPD = currTarget.value.toUpperCase().includes('PD');
        
        return (
            <div className="container mis-container col-sm-12 col-md-12 col-lg-6 col-xl-6">
                {/* <div className="mis-comment-icon-container" onClick={() => onClickCommentIcon(charts.fund)}>
                    <div className="mis-comment-icon">
                        <img src = "/mis/comments.svg" className="mis-comment-icon-img"/>
                        <span className="mis-comment-count">
                            {formatValuesNoDecimals(totalComments)}
                        </span>
                    </div>
                </div> */}
                <CommentIcon
                    chart={charts.fund}
                    onClickCommentIcon={onClickCommentIcon} 
                    totalComments={totalComments} />
                <div className="card mis-card-4 bg-white p-3 shadow-sm rounded">
                    <div className="card-body mis-card-body">
                        <div className="card-title mis-card-title">
                            <span className="d-flex dashboard-data-header__text align-items-end font-neo-semibold text-headercolor">
                                Fund Allocation
                        </span>
                        </div>
                        {isSubmitted || isPD ? showNoData(true)
                            : isLoading ? coinLoader :
                                <HorizontalBarChart {...this.props} chartId="FundAllocationChart" data={data} willUpdate={willUpdate}
                                    clickChart={this.clickChart} />
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default FundAllocation;