const idb = require('idb');

var dbPromise = idb.open('salesportal', 1, db => {
  if (!db.objectStoreNames.contains('users')) {
    db.createObjectStore('users', {keyPath: 'id'});
  }
  if (!db.objectStoreNames.contains('sync-posts')) {
    db.createObjectStore('sync-posts', {keyPath: 'id'});
  }
  if (!db.objectStoreNames.contains('session')) {
    db.createObjectStore('session',{keyPath: 'id'});
  }
  if (!db.objectStoreNames.contains('search')) {
    db.createObjectStore('search',{keyPath: 'id'});
  }
  if (!db.objectStoreNames.contains('policy-client')) {
    db.createObjectStore('policy-client',{keyPath: 'id'});
  }
});


let readAllData = (st) => {
    return dbPromise
      .then(function(db) {
        var tx = db.transaction(st, 'readonly');
        var store = tx.objectStore(st);
        return store.getAll();
      });
}



let checkPermissions = (permissionsCheckList, permissionsList) =>{

  let isAllowed = false;

  if(permissionsList != null && permissionsList.length > 0){
    permissionsList.forEach(function(permissions, i) {
      if(permissionsCheckList.includes(permissions)){
     
        isAllowed = true;
      }
    });
  }
 
  if(permissionsCheckList != null && permissionsCheckList.length === 0) {
      isAllowed = true;
  }
  
  return isAllowed;

}

let getSessionInfo = () => {
    return new Promise(function(resolve, reject) {
        resolve(readAllData('session'));
    });
}

const checkChannels = (channels, channel) => {
  let isAllowed = false;

  if(channels && channels.length > 0) {
    isAllowed = channels.includes(channel)
  }
  else if(!channels || channels.length === 0) {
    isAllowed = true;
  }

  return isAllowed;
}

let checkLogin = (props) =>{

    return new Promise(function(resolve, reject) {
        readAllData('session').then(function(session) {
          if(session.length > 0 && session[0].access_token !== "" && session[0].access_token !== undefined) {
//            console.log("SHOULD GO IN");
//            console.log(session);
            resolve(false);
          }else {
  //          console.log("SHOULD NOT GO IN");
            resolve(true);
          }
        }).catch(function(err) {
    //      console.log("SHOULD NOT GO IN");
          resolve(true);
        })
    });
  }

  let inArray = (needle, haystack) => {
    var length = haystack.length;
    for(var i = 0; i < length; i++) {
        if(haystack[i] == needle) return true;
    }
    return false;
  }


  let isManager = (roles) => {
    if(roles === undefined || roles === null) {
      return false;
    }else {
      roles = roles.toLowerCase();
      let fa_roles = [
        "agency partner",
        "insurance specialist",
        "financial advisor",
        "senior financial advisor",
        "executive financial advisor"
      ];
      //console.log(roles);
      //return false;
      return !(inArray(roles,fa_roles));
    }
  }
  
  let isInternalUser = (channel) => {
    if(channel === undefined || channel === null) {
      return false;
    }else {
      channel = channel.toLowerCase();
     
      let internal_channels = ["head office", "ho","internal staff","is"]
      // return true;
     // console.log(inArray(channel,internal_channels));
     // console.log("channel " + channel);
      return (inArray(channel,internal_channels));
    }
  }

export {  isInternalUser, isManager, checkPermissions, checkChannels, checkLogin, getSessionInfo};
