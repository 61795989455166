import React, { Component, Fragment } from 'react';
import { ReportLoader } from '../../commons/utilityViews.js';
import { withRouter } from 'react-router-dom';
import 'react-vertical-timeline-component/style.min.css';
import { apiModule, createQueryStrings } from '../../commons/apiCall';
import Search from '../AgentPolicyList/Search';
import Nav from '../AgentPolicyList/Nav';
import withWindowDimensions from '../../commons/withWindowDimensions';
import { decrypt, fixName } from '../../commons/utility.js';
import Policies from '../AgentPolicyList/Policies';
import { myConfig } from '../../commons/config.js';
class ServiceDatatable extends Component {
    state = {
        // Permissions and page loading
        isLoading: true,

        // Search
        searchTerm: "",
        searchFilter: { label: "All", value: "all" },
        // Table
        isTableLoading: true,
        showModal: false,
        tableData: [],

        // Pagination
        currentPage: 1,
        pages: 0,
        totalSize: 0,

        // Actions
        hasSearched: false,

        pageTitle: "Policy Services",
        pageIcon: "",
        serviceOptions: [],
        service: null,
        datasetId: "",
    }

    constructor(props) {
        super(props);
    }

    async componentDidMount() {
        try {
            await this.setService(this.props.match.params.type)
        } catch (e) {
            this.displayError(myConfig.GENERIC_ERROR_MESSAGE);
        }
        this.setState({ isLoading: false });
    }

    setSearchQuery = () => {
        const equivalentValue = {
            polNo: "contractNumber",
            firstName: "contractHolderFirstName",
            lastName: "contractHolderLastName",
            all: "",
        }
        const searchFilter = { ...this.state.searchFilter };
        const searchTerm = `${this.state.searchTerm}`;
        let query = {};
        if (searchFilter && searchTerm) {
            query = {
                [equivalentValue[searchFilter.value]]: searchTerm
            }
        }

        return query;
    }

    fetchTransactions = async (datasetId = this.state.datasetId) => {
        this.setState({
            isTableLoading: true,
            datasetId
        });

        this.hideError();

        let searchQuery = this.setSearchQuery();
        let access_token = this.props.getSession().access_token;
        let headerParams = { x_auth: access_token };
        const paramsObject = {
            pageSize: myConfig.PAGE_SIZE,
            pageNumber: this.state.currentPage,
            datasetId,
            agentNumber: decrypt(this.props.getSession().agentNumber),
            ...searchQuery
        }
        let data = [];
        const res = await apiModule('policy_all', headerParams, null, createQueryStrings(paramsObject))
        const response = res;
        let status = 200;
        status = response.status || status;
        if (status !== 200) {
            // Display error message if status is not 200
            this.displayError(response.message);
        } else if (response.length > 0) {
            this.setState({
                pages: response[0].pageCount,
                totalSize: response[0].totalSize,
            })
            data = response[0].content.map(contract => {
                const contractNumber = this.modifiedValue(contract.contractNumber, "--");
                const contractHolder = this.modifiedValue(contract.contractHolder, {
                    "firstName": null,
                    "middleName": null,
                    "lastName": null,
                    "phoneNumber": null,
                    "email": null,
                    "birthDate": null,
                    "mobileAuthTag": "N",
                    "emailAuthTag": "N",
                });
                const phoneNumber = this.modifiedValue(contractHolder.phoneNumber, "--");
                const email = this.modifiedValue(contractHolder.email, "--");
                const birthDate = this.modifiedValue(contractHolder.birthDate, "--");
                const mobileAuthTag = contractHolder.mobileAuthTag && contractHolder.mobileAuthTag === "Y" ? true : false;
                const emailAuthTag = contractHolder.emailAuthTag && contractHolder.emailAuthTag === "Y" ? true : false;
                return {
                    policyNum: contractNumber,
                    ownerName: fixName(contractHolder),
                    dob: birthDate,
                    contactNum: phoneNumber,
                    email,
                    mobileTag: mobileAuthTag,
                    emailTag: emailAuthTag,
                }
            })
        }

        this.setState({
            tableData: [...data],
            isTableLoading: false,
        })

    }

    modifiedValue = (value, backupValue) => {
        let modifiedValue = value || backupValue;
        return modifiedValue;
    }

    // Helper functions
    displayError = (message) => {
        if (message) {
            this.props.displayError(message);
        }
    }

    hideError() {
        this.setState({ errorMessage: "" });
    }


    handleStateChange = async (stateContext, value) => {
        switch (stateContext) {
            case "searchTerm":
                this.handleUpdateSearchTerm(value);
                break;
            case "searchFilter":
                this.handleUpdateSearchFilter(value);
                break;
            case "changePage":
                this.handleChangePage(value);
                break;
            default:
                this.setState({ [stateContext]: value });
        }
    }

    handleUpdateSearchTerm = async (e) => {
        this.setState({ searchTerm: e.target.value });
    }

    handleUpdateSearchFilter = async (choice) => {
        this.setState({
            searchFilter: choice,
            searchTerm: "",
        }, async () => {
            if (this.state.hasSearched && choice.value === "all") {
                this.resetTableState();
                await this.fetchTransactions();
            }
        })
    }

    resetTableState = () => {
        this.setState({
            hasSearched: false,
            currentPage: 1,
        })
    }

    handleSubmit = async () => {
        await this.doSearch();
    }

    handlePressEnter = async (e) => {
        if (e.key === "Enter") {
            await this.doSearch();
        }
    }

    doSearch = async () => {
        this.setState({ hasSearched: true, currentPage: 1 })
        await this.fetchTransactions();
    }

    handleChangePage = async (page) => {
        this.setState({ currentPage: page + 1 }, async () => {
            await this.fetchTransactions();
        });
    }

    setService = async (value) => {
        switch (value) {
            case "digital-id":
                this.props.setPageTitle("Change Personal Data", "far fa-user")
                this.fetchTransactions("digitalId");
                break;
            case "fta":
                this.props.setPageTitle("Fund Transfer Agreement Form", "far fa-file-alt")
                this.fetchTransactions("endorsement");
                break;
        }
    }

    render() {
        const { pages, currentPage, totalSize, tableData, isTableLoading, isLoading } = this.state;
        if (isLoading) {
            return (
                <ReportLoader show={isLoading} />
            )
        }
        else {
            return (
                <Fragment>
                    <Nav policyList="active" />
                    <div className="dashboard-datatable mt-4">
                        <Search
                            searchTerm={this.state.searchTerm}
                            searchFilter={this.state.searchFilter}
                            handleStateChange={this.handleStateChange}
                            handleSubmit={this.handleSubmit}
                            handlePressEnter={this.handlePressEnter}
                        />
                        <div className="mt-4">
                            <Policies
                                match={this.props.match}
                                data={tableData}
                                datasetId={this.state.datasetId}
                                pages={pages}
                                currentPage={currentPage}
                                totalSize={totalSize}
                                isTableLoading={isTableLoading}
                                handleStateChange={this.handleStateChange}
                            />
                        </div>
                    </div>
                </Fragment>
            )
        }
    }
}


export default withRouter(withWindowDimensions(ServiceDatatable));
