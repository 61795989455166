import React from 'react';
import { prettifyDate, calculateAge, numberWithCommas, writeData, readAllData, truncateString } from '../commons/utility.js';
import { apiModule } from '../commons/apiCall';
import { Link } from "react-router-dom";
import { DashboardLoader } from '../commons/utilityViews.js';
import moment from "moment";

const alertsList = (props) => {


    let rowsData = (<tr><td className="dashboard-row-data font-neo-semibold center-align" colSpan={3}>No records found</td></tr>)
    console.log(props.list);
    if (props.list !== null && props.list !== undefined && props.list.length > 0) {
        rowsData = props.list.map((item, index) => {
            if (index < 7) {
                return (
                    <tr key={index} className="dashboard-row-data font-neo-semibold">
                        <td style={{ paddingRight: "1rem" }}>{truncateString(item.message, 200)}</td>
                        <td style={{ width: "5rem" }}>{moment(item.created_at).fromNow()} </td>
                        {/* {prettifyDate(item.created_at)} */}

                    </tr>
                )
            } else {
                return (
                    <tr key={index}></tr>
                )
            }
        });
    }


    let viewAll = (<span></span>);
    if (props.list !== null && props.list !== undefined && props.list.length > 0) {

        viewAll = (<Link to="/dashboard/alerts-and-updates" className="btn btn-headercolor dashboard-data-button">View All</Link>);
    }
    if (props.list === null) {
        return (

            <div className={`${props.css} mb-4`}>
                <div className="dashboard-data-content bg-white p-3 shadow-sm rounded">
                    <DashboardLoader />

                </div>
            </div>

        );
    } else {
        return (
            <div className={`${props.css} mb-4`}>
                <div className="dashboard-data-content bg-white p-3 shadow-sm rounded">
                    <div className="dashboard-upper-body">
                        <div className="dashboard-data-header">
                            <h6 className="d-flex dashboard-data-header__text align-items-end font-neo-semibold text-headercolor">Alerts and Updates
                        {/* <span className="dashboard-data-icon icon-chevron-dropdown"></span> */}
                            </h6>
                        </div>
                        <div className="dashboard-data-body">
                            <table className="table table-borderless dashboard-data-table">
                                <tbody>
                                    {rowsData}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="dashboard-data-footer">
                        {viewAll}
                    </div>
                </div>
            </div>

        );
    }
}

export default alertsList;
