import React, { Component } from 'react'
import rniLoader from '../../../assets/animation/rni-loader.json';
import Lottie from 'lottie-react-web';

class MiniLoading extends Component {
    render() {
        return (
            <div className="rni-mini-loading-container">
                <Lottie speed={1.15} options={{ animationData: rniLoader, loop: true }} />
            </div>
        )
    }
}

export default MiniLoading