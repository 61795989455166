import React, { Component } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { Loader } from '../../../commons/utilityViews.js';

export class OtherInfoPdfViewer extends Component {

    state = {
        numPages: 0,
        pageNumber: 1,
        controlWidth: 0
    }

    constructor(props) {
        super(props);
        pdfjs.GlobalWorkerOptions.workerSrc = `/js/pdf.worker.min.js`;
    }

    componentDidMount() {
        const pdfControlsWidth = document.getElementById("other-info-modal-pdf-modal-header").getBoundingClientRect().width;
        this.setState({
            controlWidth: this.props.isSm ? 1080 : (pdfControlsWidth - 10),
        });
        window.addEventListener("resize", this.updateDocumentCanvasSize);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDocumentCanvasSize);
    }

    onDocumentLoad = ({ numPages }) => {
        this.setState({
            numPages
        })
    }

    updateDocumentCanvasSize = () => {
        this.setState({
            controlWidth: 0,
        });
        const pdfControlsWidth = document.getElementById("other-info-modal-pdf-modal-header").getBoundingClientRect().width;
        this.setState({
            controlWidth: this.props.isSm ? 1080 : (pdfControlsWidth - 10),
        });
    }

    render() {
        return (
            <div id="js-pdf-content">
                <Document
                    className={!this.props.isSm ? 'other-info-modal-pdf__react-pdf__desktop' : 'other-info-modal-pdf__react-pdf__sm'}
                    file={this.props.linkPdf}
                    onLoadSuccess={(e) => { this.onDocumentLoad(e) }}
                    renderMode='svg'
                    loading={<Loader show={true} />}
                >
                    {
                        Array.apply(null, Array(this.state.numPages))
                            .map((x, i) => i + 1)
                            .map(page => <Page
                                className={'other-info-modal-pdf__react-pdf__page'}
                                loading={<Loader show={true} />}
                                key={page}
                                renderAnnotationLayer={false}
                                renderTextLayer={false}
                                pageNumber={page}
                                width={this.state.controlWidth}
                            />)
                    }
                </Document>
            </div>
        );
    }
}