import React from 'react';
import Select from 'react-select';
import withWindowDimensions from '../../../commons/withWindowDimensions';

const Statuses = (props) => {
    return (
        <Select
            styles={props.styles}
            options={props.statuses}
            isSearchable={false}
            className={`datatable-select mr-md-2 ${props.isSm? 'mt-2 ml-0 policy-without-payment-dropdown-search': ''}`}
            classNamePrefix="datatable-select"
            value={props.status}
            onChange={props.handleUpdateStatus}
            styles={{
                option: (provided) => ({
                    ...provided,
                    fontFamily: "neo_semibold",
                    fontSize: ".6rem",
                    color: "#9A9B9C",
                    backgroundColor: "white"
                }),
                menu: () => ({
                    position: "absolute",
                    border: 0,
                    zIndex: "1090",
                    backgroundColor: "white",
                    borderRadius: "5px",
                    boxShadow: "2px 2px 2px #DDDEE0"
                }),
            }}
        />
    )
}

export default withWindowDimensions(Statuses);