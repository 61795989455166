import React, { Component, useEffect, useState } from 'react';
import withWindowDimensions from '../../commons/withWindowDimensions';
import Auxi from "../auxi";
import LoaderGif from '../../commons/loaderGif';
import { TranslateIcon } from 'mdi-react';
import { Table } from 'antd';
import { fixName, calculateAge } from "../../commons/utility";

class BeneficiaryModal extends Component {

    constructor(props) {
        super(props);
        this.setState({
            show: this.props.showModal,
        });
    }

    componentDidMount() {
        this.setState({
            isModalLoading: this.props.isModalLoading,
        });
    }


    render() {
        const showModal = this.props.showModal;
        const showHideClass = showModal ? "" : "none";
        const applicationNumber = this.props.applicationNumber || "";
        const inlineStyle = {
            display: showHideClass,
            maxHeight: "95vh",
        };
        const applicationNumberStyle = {
            fontSize: "14px",
            marginLeft: "2px"
        }
        const closeContainerStyle = {
            transition: "0.2s linear",
            float: "right"
        }

        let modal = "";

        if (this.props.isSm) {
            // MOBILE VIEW
            modal = (
                <div className={`announcement-body`} style={inlineStyle}>
                    <div className="announcement-box mt-n5">
                        <span style={applicationNumberStyle}><strong>Application Number</strong>: {applicationNumber}</span>
                        <div style={closeContainerStyle}>
                            <h5 className="announcement-title"></h5>
                            <img className="announcement-close-icon" src="/announcement/close.png" alt="close-icon" onClick={this.props.handleClose} />
                        </div>
                        <div className="announcement-content" style={{ maxHeight: 'calc(100vh - 300px)' }}>
                            <div style={{ overflow: 'auto' }}>
                                {this.props.isModalLoading ? (
                                    <div style={{ minWidth: "10em", minHeight: "10em" }}>
                                        <LoaderGif css="mt-4 mb-4" />
                                    </div>
                                ) : (
                                    <div className="data-accord row flex-wrap mt-4" >
                                        <div className="data-accord__header">
                                            <div className="col-6">
                                                <div className="d-inline-flex align-items-center cs-pointer" style={{ fontSize: "1em" }}>
                                                    Beneficiary Name
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="d-inline-flex align-items-center cs-pointer" style={{ fontSize: "1em" }}>
                                                    Priority
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 data-accord__body mt-1">
                                            <div className="data-accord__collapse" id="data-accord">
                                                {
                                                    this.props.data.map((item, index) => {
                                                        let birthDate = "--";
                                                        let age = "--";
                                                        if (item.party) {
                                                            birthDate = item.party.birthDate;
                                                            if (item.party.birthDate) {
                                                                age = calculateAge(item.party.birthDate);
                                                            }
                                                        }
                                                        return (
                                                            <Auxi key={index}>
                                                                <div className={`data-accord__card ${index > 0 ? "mt-3" : ""} card border-0`}>
                                                                    <div className="data-accord__toggle btn" data-toggle="collapse" data-target={`#collapse-${index}`}>
                                                                        <div className="row data-accord__start">
                                                                            <div className="col-6">
                                                                                <button className="data-accord__name data-accord__name--clear text-capitalize">{fixName(item.party).toLowerCase()}</button>
                                                                            </div>
                                                                            <div className="col-6 d-flex justify-content-between">
                                                                                <div className="font-neo-semibold">{item.priorityLevel ? item.priorityLevel : "--"}</div>
                                                                                <button className="btn p-0" type="button">
                                                                                    <div className="data-accord__caret">
                                                                                        <div className="data-accord__caret-icon"></div>
                                                                                    </div>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div id={`collapse-${index}`} className="collapse" data-parent="#data-accord">
                                                                        <div className="card-body data-accord__content">
                                                                            <div className="row">
                                                                                <div className="data-accord__column col-11">
                                                                                    <div className="data-accord__label">Date of Birth</div>
                                                                                    <div className="data-accord__value">{birthDate}</div>
                                                                                </div>
                                                                                <div className="data-accord__column col-1">
                                                                                </div>
                                                                                <div className="data-accord__column col-11">
                                                                                    <div className="data-accord__label">Age</div>
                                                                                    <div className="data-accord__value">{age}</div>
                                                                                </div>
                                                                                <div className="data-accord__column col-1">
                                                                                </div>
                                                                            </div>
                                                                            <div className="row">
                                                                                <div className="data-accord__column col-11">
                                                                                    <div className="data-accord__label">Relationship</div>
                                                                                    <div className="data-accord__value text-capitalize">{item.relationship ? item.relationship.toLowerCase() : "--"}</div>
                                                                                </div>
                                                                                <div className="data-accord__column col-1">
                                                                                </div>
                                                                                <div className="data-accord__column col-11">
                                                                                    <div className="data-accord__label">Designation</div>
                                                                                    <div className="data-accord__value">{item.isRevocable ? "Revocable" : "Irrevocable"}</div>
                                                                                </div>
                                                                                <div className="data-accord__column col-1">
                                                                                </div>
                                                                            </div>
                                                                            <div className="row">
                                                                                <div className="data-accord__column col-11">
                                                                                    <div className="data-accord__label">% Share</div>
                                                                                    <div className="data-accord__value text-capitalize">{item.percentShare ? item.percentShare : "0"}</div>
                                                                                </div>
                                                                                <div className="data-accord__column col-1">
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Auxi >
                                                        );
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            modal = (
                // DESKTOP VIEW
                <div className={`announcement-body`} style={inlineStyle}>
                    <div className="announcement-box mt-n5">
                        <span style={applicationNumberStyle}><strong>Application Number</strong>: {applicationNumber}</span>
                        <div style={closeContainerStyle}>
                            <h5 className="announcement-title"></h5>
                            <img className="announcement-close-icon" src="/announcement/close.png" alt="close-icon" onClick={this.props.handleClose} />
                        </div>
                        <div className="announcement-content">
                            <div style={{ overflow: 'auto' }}>
                                {this.props.isModalLoading ? (
                                    <div style={{ minWidth: "10em", minHeight: "10em" }}>
                                        <LoaderGif css="mt-4 mb-4" />
                                    </div>
                                ) : (
                                    <table className="table-striped" style={{ width: "100%" }}>
                                        <tbody>
                                            <tr className="" style={{ verticalAlign: "bottom", fontSize: ".8rem" }}>
                                                <th className="p-3" scope="col">Beneficiary Name</th>
                                                <th className="p-3" style={{ width: "15%" }} scope="col">Date of Birth</th>
                                                <th className="p-3" scope="col">Age</th>
                                                <th className="p-3" scope="col">Relationship</th>
                                                <th className="p-3" scope="col">Priority</th>
                                                <th className="p-3" scope="col">Designation</th>
                                                <th className="p-3" style={{ width: "11%" }} scope="col">% Share</th>
                                            </tr>
                                            {
                                                this.props.data.map((item, index) => {
                                                    let birthDate = "--";
                                                    let age = "--";
                                                    if (item.party) {
                                                        birthDate = item.party.birthDate;
                                                        if (item.party.birthDate) {
                                                            age = calculateAge(item.party.birthDate);
                                                        }
                                                    }
                                                    return (
                                                        <tr key={index} style={{ fontSize: ".9em" }}>
                                                            <td className="p-3 text-left text-capitalize">{fixName(item.party).toLowerCase()}</td>
                                                            <td className="p-3 text-left">{birthDate}</td>
                                                            <td className="p-3 text-left">{age}</td>
                                                            <td className="p-3 text-left text-capitalize">{item.relationship ? item.relationship.toLowerCase() : "--"}</td>
                                                            <td className="p-3 text-left">{item.priorityLevel ? item.priorityLevel : "--"}</td>
                                                            <td className="p-3 text-left">{item.isRevocable ? "Revocable" : "Irrevocable"}</td>
                                                            <td className="p-3 text-left">{item.percentShare ? item.percentShare : "0"}</td>
                                                        </tr>
                                                    );
                                                })
                                            }
                                        </tbody>
                                    </table>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            modal
        );
    }

}
export default withWindowDimensions(BeneficiaryModal);
