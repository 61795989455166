import React, { Component } from 'react'
import Chart from 'chart.js';
import { toAbbreviatedNumber, formatValues, thousandSeparator, roundNumber } from './formatter';
import { haveValue, labelXAxis } from '../../commons/misConfig';

class BarChart extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeChart: ''
        }
        this.renderChart.bind(this);
        this.chartWidth = 0;
    }

    componentDidMount() {
        this.renderChart();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.willUpdate) {
            this.renderChart(nextProps);
        }
    }

    renderChart(nextProps) {
        let { data, chartId, targetValue, clickChart } = this.props;
        let { activeChart } = this.state;
        let that = this;
        data = nextProps ? nextProps.data : data;

        if (document.getElementById(chartId)) {
            if (activeChart) {
                activeChart.destroy();
                this.setState({
                    activeChart: null
                })
            }
        }

        let ctx = document.getElementById(chartId).getContext('2d');
        var dataChart = new Chart(ctx, {
            type: 'bar',
            data: data,
            plugins: {
                beforeDraw: function (chartInstance) {
                    let ctx = chartInstance.ctx;

                    ctx.textAlign = 'center';
                    ctx.textBaseline = 'middle';

                    if (data) {
                        data.datasets.forEach(function (dataset, i) {
                            var meta = chartInstance.controller.getDatasetMeta(i);
                            meta.data.forEach((bar, index) => {
                                const pctValue = dataset.dataPct ? dataset.dataPct[index] : '0';
                                const dataCount = dataset.dataCount ? dataset.dataCount[index] : '0';
                                var datasetValue = dataset.data[index];
                                ctx.font = Chart.helpers.fontString("10", "normal", 'neo_regular');
                                ctx.fillStyle = '#414141';

                                let isPositive = datasetValue >= 0 ? true : false;

                                if (isFinite(pctValue)) {
                                    ctx.fillText(formatValues(roundNumber(datasetValue, 0)), bar._model.x, bar._model.y + (isPositive ? -25 : 25));
                                }
                                else {
                                    ctx.fillText(formatValues(roundNumber(datasetValue, 0)), bar._model.x, bar._model.y + (isPositive ? -10 : 10));
                                }
                                ctx.font = Chart.helpers.fontString("10", "normal", 'neo_regular');
                                ctx.fillStyle = pctValue >= 100 ? '#1E8927' : '#DC3149';
                                if (isFinite(pctValue)) {
                                    ctx.fillText(pctValue + "%", bar._model.x, bar._model.y + (isPositive ? -10 : 10));
                                }
                            });
                        })
                    }
                },
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    yAxes: [{
                        display: true,
                        gridLines: {
                            display: true,
                            color: "rgba(0,0,0,0)"
                        },
                        ticks: {
                            display: false,
                            beginAtZero: true,
                            min: data.minVal && data.minVal < 0 ? data.minVal + (((data.maxVal - data.minVal) * -0.5) + (data.minVal * 0.25)) : 0
                        }
                    }],
                    xAxes: [{
                        display: true,
                        gridLines: {
                            display: false,
                            drawBorder: false,
                        },
                        ticks: {
                            beginAtZero: false,
                            autoSkip: false,
                            maxRotation: 0,
                            fontColor: "#414141",
                            fontFamily: 'neo_bold',
                            fontSize: 10,
                        },
                        barThickness: 30
                    }],
                },
                layout: {
                    padding: {
                        top: 40
                    }
                },
                hover: {
                    animationDuration: 1
                },
                legend: {
                    display: false
                },
                tooltips: {
                    enabled: true,
                    mode: 'index',
                    intersect: false,
                    bodyFontFamily: 'neo_regular',
                    footerFontFamily: 'neo_regular',
                    titleFontFamily: 'neo_regular',
                    displayColors: false,
                    callbacks: {
                        label: function (toolTipItem, data) {
                            return data.metric + " Achieved: " + thousandSeparator(roundNumber(toolTipItem.yLabel, 0));
                            // return data.metric + ": " + thousandSeparator(roundNumber(toolTipItem.yLabel, 0));
                        },
                        title: function (tooltipItems, data) {
                            //Return value for title
                            return tooltipItems[0].xLabel.join(" ");
                        },
                        afterBody: function (toolTipItem, data) {
                            let afterBodyText = "";
                            let count = data.datasets[toolTipItem[0].datasetIndex].dataCount[toolTipItem[0].index];
                            let target = data.datasets[toolTipItem[0].datasetIndex].targetData[toolTipItem[0].index];
                            let percent = data.datasets[toolTipItem[0].datasetIndex].dataPct[toolTipItem[0].index];
                            let countPercent = data.datasets[toolTipItem[0].datasetIndex].dataCountPct[toolTipItem[0].index];
                            let countTarget = data.datasets[toolTipItem[0].datasetIndex].targetDataCount[toolTipItem[0].index];

                            if(isFinite(count)){
                                afterBodyText += 'Count: ' + thousandSeparator(roundNumber(count, 0));
                            }

                            if(isFinite(target)){
                                afterBodyText += '\n' + data.metric + ' Target: ' + thousandSeparator(roundNumber(target, 0));
                            }

                            if(isFinite(percent)){
                                afterBodyText += '\n% Achieved: ' + percent + '%';
                            }

                            if(isFinite(countTarget)){
                                afterBodyText += '\nCount Target: ' + thousandSeparator(roundNumber(countTarget, 0));
                            }

                            if(isFinite(countPercent)){
                                afterBodyText += '\n% Achieved (Count): ' + countPercent + '%';
                            }

                            return afterBodyText;
                        }
                    }
                },
                onClick: function (event, activeElements) {
                    if (activeElements instanceof Array && activeElements.length !== 0) {
                        let chartData = activeElements[0]['_chart'].config.data;
                        let chartId = activeElements[0]['_index'];
                        let chartLabel = chartData.labels[chartId].join(' ')
                        if (nextProps) {
                            if (nextProps.clickChart) {
                                nextProps.clickChart(chartLabel)
                            }
                        } else if (clickChart) {
                            clickChart(chartLabel)
                        }
                    }
                    else {
                        let chartInstance = this.chart;
                        var base = chartInstance.chartArea.bottom;
                        var height = chartInstance.chart.height;
                        var width = chartInstance.chart.scales['x-axis-0'].width;
                        var offset = chartInstance.ctx.canvas.offsetTop - window.screenTop;
                        if (event.pageY > base + offset) {
                            var count = chartInstance.scales['x-axis-0'].ticks.length;
                            var padding_left = chartInstance.scales['x-axis-0'].paddingLeft + 60;
                            var padding_right = chartInstance.scales['x-axis-0'].paddingRight;
                            var xwidth = (width - padding_left - padding_right) / count;
                            // don't call for padding areas
                            var bar_index = (event.offsetX - padding_left - chartInstance.scales['y-axis-0'].width) / xwidth;
                            if (bar_index > 0 & bar_index < count) {
                                bar_index = Math.floor(bar_index);
                            }

                            let chartLabel = '';
                            if (chartInstance.scales['x-axis-0'].ticks[bar_index] !== null && chartInstance.scales['x-axis-0'].ticks[bar_index] !== undefined) {
                                chartLabel = chartInstance.scales['x-axis-0'].ticks[bar_index].join(' ');
                            }

                            if (nextProps && chartLabel !== null && chartLabel !== undefined && chartLabel !== '') {
                                if (nextProps.clickChart) {
                                    nextProps.clickChart(chartLabel)
                                }
                            }
                            else if (clickChart && chartLabel !== null && chartLabel !== undefined && chartLabel !== '') {
                                clickChart(chartLabel)
                            }
                        }
                    }
                },
            }
        });

        this.setState({
            activeChart: dataChart
        })
    }

    render() {
        const { chartId, data } = this.props;
        return (
            // <div id={chartId + "-canvas"} className={chartId + "-canvas" + " chartWrapper"} style={{ position: 'relative', overflow: 'auto'}}>
            <div id={chartId + "-canvas"} className={chartId + "-canvas" + " chartWrapper mis-mobile-overflow"} style={{ position: 'relative', overflow: data.labels.length > 5 ? 'auto' : 'unset' }}>
             {/* <div id={chartId + "-canvas"} className={chartId + "-canvas" + " chartWrapper"} style={{ position: 'relative', overflow: 'auto'}}> */}
                <div className="chartAreaWrapper mis-bar-chart" style={{ "--chartWidth": data.labels.length > 5 ? (120 * data.labels.length) + 'px' : '100%', "--chartLabelsLength": data.labels.length > 1 ? data.labels.length + 1 : '100%' }}>
                {/* <div className="chartAreaWrapper" style={{minWidth: (50 * data.labels.length) + 'px'}}> */}
                    {haveValue(data.labels) ? '' : <p className="mis-no-data-show">NO DATA TO SHOW</p>}
                    <canvas id={chartId} />
                </div>
            </div>
        )
    }
}

export default BarChart