import React, { Component } from 'react';

class AuthenticationComplete extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  render() {
    return (
      <article className="policy-services-submodule">
        <header className="d-flex flex-row">
          <h6 className="mr-auto">Select Policy 2/2: Confirmation</h6>
        </header>

        <article className="policy-services-submodule__user-area d-flex flex-column">
          <section className="policy-services-submodule__child-container">
            <div className="policy-services-submodule__auth-message policy-services-submodule__auth-message--complete">
              <span className="policy-services-submodule__auth-check-icon icon-check-circle"></span>
              <span>
                {
                  this.props.authMethod === "sms"
                  ? `Confirmation SMS has been successfully sent to +${this.props.baseCountryCode} ${this.props.baseMobileNum}. Please advise your client to approve the transaction. The link is valid for 48 hours.`
                  : `Confirmation Email has been successfully sent to ${this.props.baseEmail}. Please advise your client to approve the transaction. The link is valid for 48 hours.`
                }
              </span>
            </div>
            <div className="policy-services-submodule__auth-actions">
              <button
                className="policy-services-submodule__auth-action policy-services-submodule__auth-action--transaction-redirect"
                onClick={() => this.props.history.push("/dashboard/agent-transaction-history")}
              >
                Go to Transaction List
              </button>
            </div>
          </section>
        </article>
      </article>
    )
  }
}


export default AuthenticationComplete;
