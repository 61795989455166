import React, { Component } from 'react';
import { ReportLoader } from '../commons/utilityViews.js';
import { myConfig } from '../commons/config.js';
import { withRouter, Link } from 'react-router-dom';
import 'react-vertical-timeline-component/style.min.css';

import ReactTable from 'react-table';
import DatatableSearch from '../components/datatableSearch';
import { urlBase64ToUint8Array, jsonToURI, prettifyDate, calculateAge, writeData, readAllData, encrypt, decrypt, fixAddress, fixName, removeNull } from '../commons/utility.js';
import routeChange from '../commons/routeChange';
import { apiModule, createQueryStrings } from '../commons/apiCall';
import DpCustomHeader, { Years } from '../components/Datepicker/customHeader';
import DatePicker from 'react-datepicker';
import Users from '../components/Users/Users.js';
import handleSortOrder from '../components/misc/sortOrder';
import { isManager, checkLogin, checkPermissions, getSessionInfo } from '../commons/security.js';

class UserList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            error: "",
            users: [],
            loadTable: true,
            rows: -1,
            pages: 10,
            currentPage: 0,
            totalSize: 0,
            searchValue: "",
            optionSelected: { value: "firstName", label: "First Name" },
            sortByField: null,
            sortOrder: null,
            isLoadingViewAs: false,
            isAllowed : false,
        };
        this.pageHeader = "List of Users";
    }


    async componentDidMount() {
        
        let session = await getSessionInfo();
        let permissionsList = session[0].permissions;
        
        if (checkPermissions(['VIEW_CS'], permissionsList)) {
            this.setState({ isAllowed: true, isLoading: false });
        }else {
            this.setState({ isAllowed: false, isLoading: false, error: "You are not allowed to access this page" });
        }

    }

    handleLoadViewAs = (isLoadingViewAs) => {
        this.setState({
            isLoadingViewAs
        })
    }

    updateSearchValue = (value) => {
        console.log("Search Value " + value);
        this.setState({
            searchValue: value,
        });
    }

    updateOptionSelected = (option) => {
        console.log("Search Option ");
        console.log(option);
        this.setState({
            optionSelected: option
        });
    }

    handleSortAction = (sortByField, sortOrder) => {
        this.setState({
            sortByField: sortByField,
            sortOrder: sortOrder,
            isLoading: true,
            loadTable: true,
        }, () => {
            this.search(this.state.currentPage);
        })
    }
    headerClickedHandler = (sortByField) => {
        let sortOrder = handleSortOrder({ sortByField: sortByField, state: this.state });
        this.handleSortAction(sortByField, sortOrder)
    }

    fetchUsers = (access_token) => {
        let that = this;
        return new Promise(function (resolve, reject) {

            let headerParams = { x_auth: access_token };
            let filter = [];
            filter['pageNumber'] = (that.state.currentPage + 1);
            filter['pageSize'] = myConfig.PAGE_SIZE;
            //filter['agentNumberOfAdvisorOfAccountManager'] = INTM_NO;
            filter['moduleCode'] = "LIST_USER";

            that.setState({
                loadTable: true,
                isLoading: true
            });
            apiModule("accountmanagers_all", headerParams, null, createQueryStrings(filter))
                .then(function (response) {
                    console.log("accountmanagers_all");
                    console.log(response);

                    let result = response.content;
                    let totalSize = response.totalSize;
                    let numOfPages = parseInt((totalSize / myConfig.PAGE_SIZE), 10);

                    if ((totalSize % myConfig.PAGE_SIZE) > 0) {
                        numOfPages++;
                    }

                    let users = [];
                    //IF RESULT IS NULL THEN ERROR MESSAGE
                    if (result) {
                        for (let i = 0; i < result.length; i++) {
                            let faResult = result[i];

                            users.push({
                                globalId: faResult.globalId,
                                name: fixName(faResult), //"Lazaro, Sheila D.",
                                BIRTHDAY: prettifyDate(faResult.birthDate), //"YYYY-MM-DD",
                                ADDRESS: fixAddress(faResult.address),
                                phoneNumber: faResult.phoneNumber,
                                INTM_NO: faResult.agentNumber,
                                STATUS: faResult.employmentStatus,
                                email: faResult.email,
                                //licStat: `${faResult.phoneNumber}`,
                            });
                        }
                    } else {
                        //  that.errorMessage("No User Found");
                    }

                    that.setState({
                        users,
                        loadTable: false,
                        totalSize: totalSize,
                        pages: numOfPages,
                        isLoading: false
                    });
                })
                .catch(function (err) {
                    console.log(err);
                    that.errorMessage(myConfig.GENERIC_ERROR_MESSAGE);
                    that.setState({
                        users: [],
                        loadTable: false,
                        isLoading: false
                    });
                })
            // let result = null;



        });
    }
    errorMessage = (message) => {
        if (message) {
            this.setState({ error: message })
        }
    }

    showError = () => {
        let message = '';
        if (this.state.error !== '') {
            message =
                <div className="bg-error py-1 px-4">
                    <p className="text-white mb-0 font-neo-semibold">ERROR: {this.state.error}</p>
                </div>
        }
        return message;
    }

    search = (pageNumber) => {

        let that = this;
        let agentNumber = decrypt(that.props.getSession().agentNumber);
        let headerParams = { x_auth: that.props.getSession().access_token };
        let filter = [];
        filter['pageNumber'] = (pageNumber + 1);
        filter['pageSize'] = myConfig.PAGE_SIZE;
        //  filter['agentNumberOfAdvisorOfAccountManager'] = INTM_NO;
        filter['moduleCode'] = "LIST_USER";
        that.setState({
            loadTable: true,
            isLoading: true
        });

        if (this.state.optionSelected.value !== null) {

            if (this.state.searchValue !== "" && this.state.searchValue !== null && this.state.searchValue !== undefined) {
                if (this.state.optionSelected.value == "firstName") {
                    filter['firstName'] = this.state.searchValue;

                } else if (this.state.optionSelected.value == "name") {
                    filter['name'] = this.state.searchValue;

                } else if (this.state.optionSelected.value === 'agentNumber') {
                    filter['agentNumber'] = this.state.searchValue;
                }
            }

        }

        if (that.state.sortByField != null) {
            filter['sortBy'] = that.state.sortByField + ',' + that.state.sortOrder
        }

        apiModule("accountmanagers_all", headerParams, null, createQueryStrings(filter))
            .then(function (response) {
                let result = response.content;
                //let result = response.data.content;
                let totalSize = response.totalSize;
                let numOfPages = parseInt((totalSize / myConfig.PAGE_SIZE), 10);

                if ((totalSize % myConfig.PAGE_SIZE) > 0) {
                    numOfPages++;
                }

                let users = [];
                if (result) {
                    for (let i = 0; i < result.length; i++) {
                        let faResult = result[i];
                        users.push({
                            globalId: faResult.globalId,
                            name: fixName(faResult), //"Lazaro, Sheila D.",
                            BIRTHDAY: prettifyDate(faResult.birthDate), //"YYYY-MM-DD",
                            ADDRESS: fixAddress(faResult.address),
                            phoneNumber: faResult.phoneNumber,
                            INTM_NO: faResult.agentNumber,
                            STATUS: faResult.employmentStatus,
                            email: faResult.email,
                            //licStat: `${faResult.phoneNumber}`,
                        });

                    }
                } else {
                    // that.errorMessage("No Users Found");
                }

                that.setState({
                    users,
                    loadTable: false,
                    totalSize: totalSize,
                    currentPage: (pageNumber),
                    pages: numOfPages,
                    isLoading: false
                });
            })
            .catch(function (err) {
                console.log(err);
                that.setState({
                    users: [],
                    loadTable: false,
                    error: myConfig.GENERIC_ERROR_MESSAGE,

                });
            })
        // let result = null;

    }


    render() {
        let dataTable = "";
        if(!this.state.isAllowed){
            return (
                <div className="dashboard-section overflow-hidden">
                    {this.showError()}
                </div>
            );
        }else {
            console.log("Top is being rendered");
            if (this.state.users.length > 0) {
                dataTable = (
                    <Users
                        history={this.props.history}
                        users={this.state.users}
                        loadTable={this.state.loadTable}
                        isLoadingViewAs={this.state.isLoadingViewAs}
                        handleLoadViewAs={(isLoadingViewAs) => this.handleLoadViewAs(isLoadingViewAs)}
                        totalSize={this.state.totalSize}
                        search={(pageNumber) => this.search(pageNumber)}
                        rows={this.state.rows}
                        headerClickedHandler={(sortByField) => { this.headerClickedHandler(sortByField) }}
                        pages={this.state.pages}
                        currentPage={this.state.currentPage}
                        customNames={
                            {
                                name: "Name",
                                BIRTHDAY: "Date of Birth",
                                ADDRESS: "Location",
                                phoneNumber: "Contact Number",
                                INTM_NO: "Intermediary Number",
                                STATUS: "Intermediary Status",
                                //licStat: "License CA Status",
                            }
                        }
                        sortByField={this.state.sortByField}
                        sortOrder={this.state.sortOrder}
                        {...this.props} />
                )
            } else if (this.state.users.length == 0 && !this.state.isLoading) {
                dataTable = (
                    <div className="center-align dashboard-row-data font-neo-semibold mt-4">No records found </div> 
                ) 
            } else {
                dataTable = (
                    <>
                        {
                            this.state.isLoading && <ReportLoader handleClose={this.hideModal} show={this.state.isLoading} />
                        }                     
                    </>
                );
            }
            return (
                <div className="dashboard-section overflow-hidden">
                    {this.showError()}
                    <div className="dashboard-container container-fluid p-3">
                        <div className="dashboard-header">
                            <h6 className="font-neo-bold text-darkgray">{this.pageHeader}</h6>
                        </div>
                        <div className="dashboard-body">
                            <div className="dashboard-datatable mt-4">
                                <DatatableSearch
                                    //DROPDOWN OPTION
                                    optionSelectedValue={this.state.optionSelected}
                                    updateOptionSelected={this.updateOptionSelected}
                                    //END DROPDOWN OPTION

                                    //SEARCH VALUE
                                    searchValue={this.state.searchValue}
                                    updateSearchValue={this.updateSearchValue}
                                    //END SEARCH VALUE

                                    search={(pageNumber) => this.search(pageNumber)}

                                    placeholder=""
                                    styles={{
                                        option: (provided) => ({
                                            ...provided,
                                            fontFamily: "neo_semibold",
                                            fontSize: ".6rem",
                                            color: "#9A9B9C",
                                            backgroundColor: "white"
                                        }),
                                        menu: () => ({
                                            position: "absolute",
                                            border: 0,
                                            zIndex: "1090",
                                            backgroundColor: "white",
                                            borderRadius: "5px",
                                            boxShadow: "2px 2px 2px #DDDEE0"
                                        }),
                                    }}
                                    options={[
                                        { value: "agentNumber", label: "Intermediary No." },
                                        { value: "firstName", label: "First Name" },
                                        { value: "name", label: "Last Name" },
                                    ]}
                                />
                                {dataTable}
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
}
export default withRouter(UserList);
