import React, { Fragment } from 'react';
import withWindowDimensions from '../../commons/withWindowDimensions';

const TextInput = (props) => {
    return (
        <Fragment>
            <div style={{ display: props.isSm ? 'block' : 'flex'}}>
                <div className={`custom-searchbar datatable-search__container form-control rounded-pill mr-md-2 mb-2  ${props.isSm ? 'mt-2' : ''}`} style={{ width: props.isSm ? '80%' : '11rem', marginLeft: props.isSm ? 'auto' : '-8px', margin: 'auto' }}>
                    <input
                        value={props.value}
                        onChange={props.handleSearch}
                        className="searchbar__input datatable-search__input h-100"
                        type="text"
                        placeholder="Search for"
                    />
                    <a className="btn searchbar__button searchbar__button--gray px-2">
                        <span className="icon-search"></span>
                    </a>
                </div>
            </div>
        </Fragment>
    )
}

export default withWindowDimensions(TextInput);