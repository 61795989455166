import React from 'react';
import { prettifyDate, calculateAge, numberWithCommas, writeData, readAllData } from '../commons/utility.js';
import { apiModule } from '../commons/apiCall';
import { Link } from "react-router-dom";
import { DashboardLoader } from '../commons/utilityViews.js';

const birthdayList = (props) => {

    let rowsData = (<tr><td className="dashboard-row-data font-neo-semibold center-align" colSpan={3}>No records found</td></tr>)
    if (props.list !== null && props.list !== undefined && props.list.length > 0) {
        rowsData = props.list.map((item, index) => {
            if(index < 10) {
                return (
                    <tr key={index} className="dashboard-row-data font-neo-semibold">
                        <td>{`${item.name}`}</td>
                        <td>{prettifyDate(item.birthDate)}</td>

                    </tr>
                )
            }else {
                return (
                    <span></span>
                )
            }    
        });
    }

    let headerData = (<tr><td colSpan={3}></td></tr>)
    if (props.list !== null && props.list !== undefined && props.list.length > 0) {
        headerData = (<tr className="font-neo-light dashboard-table-headers text-darkgray">
            <th scope="col">Name</th>
            <th scope="col">Date of Birth</th>
        </tr>);
    }

    let viewAll = (<span></span>);
    if (props.list !== null && props.list !== undefined && props.list.length > 0) {

        viewAll = (<Link to="/dashboard/birthday-list-view" className="btn btn-headercolor dashboard-data-button">View All</Link>);
    }

    if(props.list === null) {
        return (

            <div className={`${props.css} mb-4`}>
                <div className="dashboard-data-content bg-white p-3 shadow-sm rounded">
                    <DashboardLoader />
                   
                </div>
            </div>
    
        );
    }else {
        return (

            <div className={`${props.css} mb-4`}>
                <div className="dashboard-data-content bg-white p-3 shadow-sm rounded">
                    <div className="dashboard-upper-body">
                        <div className="dashboard-data-header">
                            <h6 className="d-flex dashboard-data-header__text align-items-end font-neo-semibold text-headercolor">Birthday List
                        {/* <span className="dashboard-data-icon icon-chevron-dropdown"></span> */}
                            </h6>
                        </div>
                        <div className="dashboard-data-body">
                            <table className="table table-borderless dashboard-data-table">
                                <thead>
                                    {headerData}
                                </thead>
                                <tbody>
                                    {rowsData}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="dashboard-data-footer">
                        {viewAll}
                    </div>
                </div>
            </div>

        );
    }
}

export default birthdayList;
