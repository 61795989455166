import React, { Component } from 'react';
import { ReportLoader } from '../commons/utilityViews.js';
import { myConfig } from '../commons/config.js';
import { withRouter, Link } from 'react-router-dom';
import 'react-vertical-timeline-component/style.min.css';
// import { urlBase64ToUint8Array } from '../commons/utility.js';
import ReactTable from 'react-table';
import DatatableSearch from '../components/datatableSearch';
import { fixName, checkDateRange, checkSearch, renameByChannel, fixAddress, removeNull, prettifyDate, calculateAge, writeData, readAllData, encrypt, decrypt } from '../commons/utility.js';
import routeChange from '../commons/routeChange';
import { apiModule, createQueryStrings } from '../commons/apiCall';
import { isManager, checkLogin, checkPermissions, getSessionInfo } from '../commons/security.js';
import DpCustomHeader, { Years } from '../components/Datepicker/customHeader';
import DatePicker from 'react-datepicker';
import Fas from '../components/Fas/Fas.js';
import Select from 'react-select';
import handleSortOrder from '../components/misc/sortOrder';
import FaPersistency from '../components/faPersistency';
import * as moment from 'moment';

class FaList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            error: "",
            fas: [],
            loadTable: true,
            rows: -1,
            pages: 10,
            currentPage: 0,
            totalSize: 0,
            searchValue: "",
            optionSelected: { value: "firstName", label: "First Name" },
            //branch List
            branchList: [],
            branchValue: null,
            sortByField: null,
            sortOrder: null,
            isAllowed: false,

            showPersistency: false,
            selectedAgentNumber: null,
        };

        this.handleBranchListChange = this.handleBranchListChange.bind(this);
    }


    async componentDidMount() {
        let access_token = this.props.getSession().access_token;
        let session = await getSessionInfo();
        let permissionsList = session[0].permissions;
        console.log(permissionsList);
        if (checkPermissions(['VIEW_FA_LIST'], permissionsList)) {
            this.setState({ isAllowed: true, isLoading: true, access_token: access_token });
            await this.fetchFAs(this.props.getSession().access_token, decrypt(this.props.getSession().agentNumber));
        } else {
            this.setState({ isAllowed: false, isLoading: false, error: "You are not allowed to access this page" });
        }



    }

    async getBranchList(selectedOption) {
        await this.fetchBranchList(selectedOption, this.props.getSession().access_token, decrypt(this.props.getSession().agentNumber));
    }

    handleBranchListChange = (branchValue) => {
        this.setState({
            branchValue: branchValue
        }, () => {
            this.search(this.state.currentPage);
        });
    }

    updateSearchValue = (value) => {
        this.setState({
            searchValue: value,
        });
    }

    updateOptionSelected = (option) => {
        if (option.value == 'branchId' && this.state.optionSelected.value != 'branchId') {
            this.getBranchList(option);
        } else {
            this.setState({
                optionSelected: option
            });
        }
        // }
    }

    handleSortAction = (sortByField, sortOrder) => {
        this.setState({
            sortByField: sortByField,
            sortOrder: sortOrder,
            // isLoading: true
            loadTable: true,
        }, () => {
            this.search(this.state.currentPage);
        })
    }
    headerClickedHandler = (sortByField) => {
        let sortOrder = handleSortOrder({ sortByField: sortByField, state: this.state });
        this.handleSortAction(sortByField, sortOrder)
    }

    fetchBranchList = (selectedOption, access_token, agentNumber) => {
        let that = this;
        return new Promise(function (resolve, reject) {

            let headerParams = {
                x_auth: access_token,
                dataListId: 'intmBranches'
            };
            let filter = [];

            filter['key'] = agentNumber;

            apiModule("get_datalist", headerParams, null, createQueryStrings(filter))
                .then(function (response) {

                    let result = response;
                    let branchList = [];

                    //IF RESULT IS NULL THEN ERROR MESSAGE
                    if (result && result.error == null) {

                        Object.keys(result).forEach(function (key) {
                            let item = key;
                            branchList.push(
                                new Object({
                                    value: item,
                                    label: result[key]
                                })
                            );
                        });
                        console.log(branchList);
                        that.setState({
                            branchList: branchList,
                            isLoading: false,
                            optionSelected: selectedOption
                        });
                    } else {
                        that.setState({
                            isLoading: false,
                            optionSelected: selectedOption
                        }, () => {
                            //      that.errorMessage("No Records Found");
                        });
                    }
                })
                .catch(function (err) {
                    console.log(err);
                    that.setState({
                        isLoading: false,
                        policyStatuslist: []
                    }, () => {
                        that.errorMessage(myConfig.GENERIC_ERROR_MESSAGE);
                        console.log(err.message)
                    });
                })
        });
    }

    fetchFAs = (access_token, agentNumber) => {
        let that = this;
        return new Promise(function (resolve, reject) {

            let headerParams = { x_auth: access_token };
            let filter = [];
            filter['pageNumber'] = (that.state.currentPage + 1);
            filter['pageSize'] = myConfig.PAGE_SIZE;
            filter['agentNumberOfAdvisorOfAccountManager'] = agentNumber;
            filter['moduleCode'] = "LIST_FA";

            apiModule("accountmanagers_all", headerParams, null, createQueryStrings(filter))
                .then(function (response) {
                    console.log("accountmanagers_all");
                    console.log(response);

                    let result = response.content;
                    let totalSize = response.totalSize;
                    let numOfPages = parseInt((totalSize / myConfig.PAGE_SIZE), 10);

                    if ((totalSize % myConfig.PAGE_SIZE) > 0) {
                        numOfPages++;
                    }

                    let fas = [];
                    //IF RESULT IS NULL THEN ERROR MESSAGE
                    if (result) {
                        for (let i = 0; i < result.length; i++) {
                            let faResult = result[i];

                            let address = "";

                            if (faResult.address !== null && faResult.address !== undefined && faResult.address !== null) {
                                address = fixAddress(faResult.address);
                            }


                            fas.push({
                                faId: faResult.agentNumber,
                                name: fixName(faResult), //`${faResult.name}, ${faResult.firstName} ${faResult.middleName}`,
                                BIRTHDAY: prettifyDate(faResult.birthDate),
                                address: `${address}`,
                                PHONENUMBER: removeNull(faResult.phoneNumber),
                                INTM_NO: removeNull(faResult.agentNumber),
                                STATUS: removeNull(faResult.employmentStatus),
                                LDBRANCHCD: faResult.branchList,
                                employmentDate: prettifyDate(faResult.employmentDate),
                                //CERTIFICATESTATUS: `${faResult.phoneNumber}`,
                                RP13: parseFloat(faResult.rp13) || 0,
                                RP25: parseFloat(faResult.rp25) || 0,
                            });
                        }
                    } else {
                        // that.errorMessage("No Financial Advisor Found");
                    }

                    that.setState({
                        fas,
                        loadTable: false,
                        totalSize: totalSize,
                        pages: numOfPages,
                        currentPage: that.state.currentPage,
                        isLoading: false
                    });
                })
                .catch(function (err) {
                    console.log(err);
                    that.setState({
                        isLoading: false,
                        error: myConfig.GENERIC_ERROR_MESSAGE
                    });

                })
            // let result = null;



        });
    }
    errorMessage = (message) => {
        if (message) {
            this.setState({ error: message })
        }
    }

    showError = () => {
        let message = '';
        if (this.state.error !== '') {
            message =
                <div className="bg-error py-1 px-4">
                    <p className="text-white mb-0 font-neo-semibold">ERROR: {this.state.error}</p>
                </div>
        }
        return message;
    }

    search = (pageNumber) => {

        let that = this;
        let agentNumber = decrypt(that.props.getSession().agentNumber);
        let headerParams = { x_auth: that.props.getSession().access_token };
        let filter = [];
        let continueToApiCall = true;
        let errorMessageDate = "";
        filter['pageNumber'] = (that.state.currentPage + 1);
        filter['pageSize'] = myConfig.PAGE_SIZE;
        filter['agentNumberOfAdvisorOfAccountManager'] = agentNumber;
        filter['moduleCode'] = "LIST_FA";

        if (this.state.optionSelected.value !== null) {

            if (this.state.optionSelected.value == "BIRTHDAY") {
                if (this.state.dobStart != null) {
                    filter['dobStart'] = prettifyDate(this.state.dobStart);
                    errorMessageDate = checkDateRange(filter['birthDateTo'], filter['birthDateFrom']);
                }
                if (this.state.dobEnd != null) {
                    filter['dobEnd'] = prettifyDate(this.state.dobEnd);
                    errorMessageDate = checkDateRange(filter['birthDateTo'], filter['birthDateFrom']);
                }
            } else if (this.state.optionSelected.value === 'branchId') {
                if (this.state.branchValue !== null) {
                    filter[this.state.optionSelected.value] = this.state.branchValue.value;

                }
            } else if (this.state.searchValue != null && this.state.searchValue != "") {
                filter[this.state.optionSelected.value] = this.state.searchValue;
                continueToApiCall = checkSearch(this.state.searchValue, that.errorMessage, 2);
            }

            if (that.state.sortByField != null) {
                filter['sortBy'] = that.state.sortByField + ',' + that.state.sortOrder
            }

            if (errorMessageDate !== "") {
                that.errorMessage(errorMessageDate);
                continueToApiCall = false;
            }

        }

        if (continueToApiCall) {
            that.setState({ loadTable: true, error: '' }, () => {

                apiModule("accountmanagers_all", headerParams, null, createQueryStrings(filter))
                    .then(function (response) {
                        let result = response.content;
                        //let result = response.data.content;
                        let totalSize = response.totalSize;
                        let numOfPages = parseInt((totalSize / myConfig.PAGE_SIZE), 10);

                        if ((totalSize % myConfig.PAGE_SIZE) > 0) {
                            numOfPages++;
                        }

                        let fas = [];
                        if (result) {
                            for (let i = 0; i < result.length; i++) {
                                let faResult = result[i];
                                let address = "";

                                if (faResult.address !== null && faResult.address !== undefined && faResult.address !== null) {
                                    address = fixAddress(faResult.address);

                                }
                                fas.push({
                                    faId: faResult.agentNumber,
                                    name: fixName(faResult), //`${faResult.name}, ${faResult.firstName} ${faResult.middleName}`,
                                    BIRTHDAY: prettifyDate(faResult.birthDate),
                                    address: `${address}`,
                                    LDBRANCHCD: faResult.branchList,
                                    employmentDate: prettifyDate(faResult.employmentDate),
                                    PHONENUMBER: removeNull(faResult.phoneNumber),
                                    INTM_NO: removeNull(faResult.agentNumber),
                                    STATUS: removeNull(faResult.employmentStatus),
                                    CERTIFICATESTATUS: `${faResult.phoneNumber}`,
                                    RP13: parseFloat(faResult.rp13) || 0,
                                    RP25: parseFloat(faResult.rp25) || 0,
                                });

                            }
                        } else {
                            // that.errorMessage("No Financial Advisors Found");
                        }

                        that.setState({
                            fas,
                            loadTable: false,
                            totalSize: totalSize,
                            currentPage: pageNumber,
                            pages: numOfPages,
                            isLoading: false
                        });
                    })
                    .catch(function (err) {
                        console.log(err);
                        that.setState({
                            isLoading: false,
                            fas: []
                        });
                        that.errorMessage(myConfig.GENERIC_ERROR_MESSAGE);
                        console.log(err.message)
                    })

            });

        } else {
            that.setState({
                loadTable: false,
                isLoading: false
            });
        }
        // let result = null;

    }

    searchByOption = () => {
        let field = "";
        if (this.state.optionSelected.value == "branchId") {
            field = (<Select
                styles={{
                    option: (provided) => ({
                        ...provided,
                        fontFamily: "neo_semibold",
                        fontSize: ".6rem",
                        color: "#9A9B9C",
                        backgroundColor: "white"
                    }),
                    menu: () => ({
                        position: "absolute",
                        border: 0,
                        zIndex: "1090",
                        backgroundColor: "white",
                        borderRadius: "5px",
                        boxShadow: "2px 2px 2px #DDDEE0"
                    }),
                }}
                placeholder="Select..."

                //options
                options={this.state.branchList}
                isSearchable={false}
                className={`datatable-select ml-0 ${this.props.isSm ? "w-100" : ""}`}
                classNamePrefix="datatable-select"
                value={this.state.branchValue}
                onChange={this.handleBranchListChange}
            />)
        }
        return field;
    }

    generateCustomNames = (customNameSet) => {
        let customNames = {};

        for (let idx in customNameSet) {
            if (customNameSet[idx].display) {
                for (let key in customNameSet[idx]) {
                    if (key !== 'display') {
                        customNames[key] = customNameSet[idx][key];
                    }
                }
            }
        }

        return customNames;
    }

    setPersistencyModal(boo, metric) {
        this.setState({
            showPersistency: boo ? boo : false,
            metric,
        });
    }

    setSelectedAgentNumber(number) {
        this.setState({
            selectedAgentNumber: number,
        })
    }

    render() {
        let dataTable = "";

        let customNameSet = [
            { name: "Name", display: true },
            { BIRTHDAY: "Date of Birth", display: false },
            { INTM_NO: "Intermediary Number", display: !isManager(this.props.getSession().role) },
            { INTM_NO: "Intermediary Code", display: isManager(this.props.getSession().role) },
            { RP13: `RP13`, display: isManager(this.props.getSession().role) },
            { RP25: `RP25`, display: isManager(this.props.getSession().role) },
            { STATUS: "Intermediary Status", display: !isManager(this.props.getSession().role) },
            { employmentDate: "Effectivity Date", display: !isManager(this.props.getSession().role) },
            { LDBRANCHCD: "Service Office", display: !isManager(this.props.getSession().role) },
            { address: "Address", display: false },
            { PHONENUMBER: "Contact Number", display: false },
            { CERTIFICATESTATUS: "License CA Status", display: false },
        ];
        let customNames = this.generateCustomNames(customNameSet);

        if (this.state.isLoading) {
            return (
                <ReportLoader handleClose={this.hideModal} show={this.state.isLoading} />
            );
        } else if (!this.state.isAllowed) {
            return (
                <div className="dashboard-section overflow-hidden">
                    {this.showError()}
                </div>
            );
        } else {

            if (this.state.fas.length > 0) {
                dataTable = (
                    <Fas
                        history={this.props.history}
                        fas={this.state.fas}
                        loadTable={this.state.loadTable}
                        totalSize={this.state.totalSize}
                        beginLoading={() => this.beginLoading()}
                        search={(pageNumber) => this.search(pageNumber)}
                        rows={this.state.rows}
                        headerClickedHandler={(sortByField) => { this.headerClickedHandler(sortByField) }}
                        pages={this.state.pages}
                        currentPage={this.state.currentPage}
                        customNames={customNames}
                        sortByField={this.state.sortByField}
                        sortOrder={this.state.sortOrder}
                        setPersistencyModal={(boo, metric) => this.setPersistencyModal(boo, metric)}
                        setSelectedAgentNumber={(number) => this.setSelectedAgentNumber(number)}

                        {...this.props} />
                )
            } else {
                dataTable = (
                    <div className="center-align dashboard-row-data font-neo-semibold">No records found </div>
                );
            }
            let pageLabel = renameByChannel("List of Financial Advisors", this.props.getSession().channel, this.props.getSession().view_as_channel);
            return (
                <div className="dashboard-section overflow-hidden">
                    {this.showError()}
                    <div className="dashboard-container container-fluid p-3">
                        <div className="dashboard-header">
                            <h6 className="font-neo-bold text-darkgray">{pageLabel}</h6>
                        </div>
                        <div className="dashboard-body">
                            <div className="dashboard-datatable mt-4">
                                <DatatableSearch
                                    //DROPDOWN OPTION
                                    optionSelectedValue={this.state.optionSelected}
                                    updateOptionSelected={this.updateOptionSelected}
                                    //END DROPDOWN OPTION

                                    //SEARCH VALUE
                                    searchValue={this.state.searchValue}
                                    updateSearchValue={this.updateSearchValue}
                                    //END SEARCH VALUE

                                    search={(pageNumber) => {
                                        this.setState({ loadTable: true }, () => {
                                            this.search(pageNumber)
                                        })
                                    }}
                                    searchByOption={this.searchByOption()}
                                    placeholder=""
                                    styles={{
                                        option: (provided) => ({
                                            ...provided,
                                            fontFamily: "neo_semibold",
                                            fontSize: ".6rem",
                                            color: "#9A9B9C",
                                            backgroundColor: "white"
                                        }),
                                        menu: () => ({
                                            position: "absolute",
                                            border: 0,
                                            zIndex: "1090",
                                            backgroundColor: "white",
                                            borderRadius: "5px",
                                            boxShadow: "2px 2px 2px #DDDEE0"
                                        }),
                                    }}
                                    options={[
                                        { value: "branchId", label: "Location/Branch" },
                                        { value: "firstName", label: "First Name" },
                                        { value: "name", label: "Last Name" },
                                        { value: "agentNumber", label: "Intermediary Code" }
                                    ]}
                                />
                                {dataTable}
                            </div>
                        </div>
                    </div>
                    {this.state.showPersistency === true && this.state.selectedAgentNumber? <FaPersistency {...this.props} agentNumber={this.state.selectedAgentNumber} getSession={this.props.getSession()} hidePersistency={() => { this.setPersistencyModal(false) }} metric={this.state.metric} /> : null}
                </div>
            );
        }
    }
}
export default withRouter(FaList);
