import React, { Component } from 'react'

export default class pagenotfound extends Component {
    render() {
        return (
            <div className="container" style={{ textAlign: 'center' }}>
                <img src="/rni/404-error.svg" className="rni-pagenotfound-img" />
                <h1 className="rni-pagenotfound-h1">PAGE NOT FOUND</h1>
            </div>
        )
    }
}
