import React from "react";
import Auxi from '../../auxi';

const fasMobile = (props) => {
    const customName = props.customNames;
    const data = props.data;
    let fas = data.map((item, index) => {
        return (
            <Auxi key={index}>
                <div className={`data-accord__card ${index > 0 ? "mt-3" : ""} card border-0`}>
                    <div className="data-accord__toggle btn">
                        <div className="row data-accord__start">
                            <div className="col-5">
                                <button onClick={() => props.onClickAction(item.faId)} className="data-accord__name data-accord__name--clear">{item.name && customName.name? item.name: ''}</button>
                            </div>
                            <div className="col-7 d-flex justify-content-between">
                                <div className="font-neo-semibold">{item.INTM_NO && customName.INTM_NO? item.INTM_NO: ''}</div>
                                <button className="btn p-0" type="button" data-toggle="collapse" data-target={`#collapse-${index}`}>
                                    <div className="data-accord__caret">
                                        <div className="data-accord__caret-icon"></div>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div id={`collapse-${index}`} className="collapse" data-parent="#data-accord">
                        <div className="card-body data-accord__content">
                            <div className="row">
                                {/* <div className="data-accord__column col-5">
                                    <div className="data-accord__label">{customName.BIRTHDAY}</div>
                                    <div className="data-accord__value">{item.BIRTHDAY}</div>
                                </div>
                                <div className="data-accord__column col-7">
                                    <div className="data-accord__label">{customName.address}</div>
                                    <div className="data-accord__value">{item.address}</div>
                                </div>
                                <div className="data-accord__column col-5">
                                    <div className="data-accord__label">{customName.PHONENUMBER}</div>
                                    <div className="data-accord__value">{item.PHONENUMBER}</div>
                                </div>
                                <div className="data-accord__column col-7">
                                    <div className="data-accord__label">{customName.STATUS}</div>
                                    <div className="data-accord__value">{item.STATUS}</div>
                                </div>
                                <div className="data-accord__column col-5">
                                    <div className="data-accord__label">{customName.CERTIFICATESTATUS}</div>
                                    <div className="data-accord__value">{item.CERTIFICATESTATUS}</div>
                                </div> */}
                                <div className="data-accord__column col-5">
                                    <div className="data-accord__label">{customName.STATUS}</div>
                                    <div className="data-accord__value">{item.STATUS && customName.STATUS? item.STATUS: ''}</div>
                                </div>
                                <div className="data-accord__column col-7">
                                    <div className="data-accord__label">{customName.employmentDate}</div>
                                    <div className="data-accord__value">{item.employmentDate && customName.employmentDate? item.employmentDate: ''}</div>
                                </div>
                                <div className="data-accord__column col-5">
                                    <div className="data-accord__label">{customName.LDBRANCHCD}</div>
                                    <div className="data-accord__value">{item.LDBRANCHCD && customName.LDBRANCHCD? item.LDBRANCHCD: ''}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Auxi >
        );
    });

    return (
        <div className="data-accord row flex-wrap mt-4" >
            <div className="data-accord__header">
                <div className="col-5">
                    <div onClick={() => props.headerClickedHandler("name")} className="d-inline-flex align-items-center cs-pointer">
                        {customName.name} {customName.name? <span className="icon-popout"></span>: ''}
                    </div>
                </div>
                <div className="col-7">
                    <div onClick={() => props.headerClickedHandler("INTM_NO")} className="d-inline-flex align-items-center cs-pointer">
                        {customName.INTM_NO} {customName.INTM_NO? <span className="icon-popout"></span>: ''}
                    </div>
                </div>
            </div>
            <div className="col-12 data-accord__body mt-1">
                <div className="data-accord__collapse" id="data-accord">
                    {fas}
                </div>
            </div>
        </div>
    );
}

export default fasMobile;
