import React, { Component } from 'react';
import NavLink from 'react-router-dom';
export default class searchHeader extends Component {
    render() {
        const { label, src } = this.props;
        return (
            <div className="rni-section-header rni-sh2">
                <img src={src} alt='' />
                <label>{label}</label>
                {this.props.count ? this.props.count > 3 ? <div className="rni-section-view-more">
                    <label onClick={() => { this.props.clickedTab() }}>View more ({this.props.count})</label>
                </div> : '' : ''}
            </div>
        )
    }
}
