import React, { Component, useEffect, useState } from 'react';
import PropTypes from  'prop-types';
import DatePicker from "react-datepicker";
import DpCustomHeader from "../Datepicker/customHeader";
import Select from 'react-select';
import withWindowsDimensions from "../../commons/withWindowDimensions";
import { Years } from "../Datepicker/customHeader";

const Search = (props) => {
    const options = [
        { label: "All", value: "all" },
        { label: "Application Number", value: "applicationNumber" },
        { label: "Application Owner", value: "name" },
        { label: "Application Status", value: "status" },
        { label: "Application Status Date", value: "statusDate" },
        { label: "Receiving Status", value: "receivingStatus" },
        { label: "Encoded Date", value: "encodedDate" },
        { label: "Plan Name", value: "productOfferingName" }
    ]

    const mobileCss = () => {
        let mobileFlex = "";
        let searchBar = "";
        let dropdown = "";
        let resetBtn = "";
        if (props.isSm) {
            mobileFlex = "flex-wrap";
            searchBar = "w-100 mt-2";
            dropdown = "ml-0 flex-grow-1 mt-2";
            resetBtn = "ml-auto ml-0 w-25 mt-2"
        }
        return (
            {
                mobileFlex: mobileFlex,
                searchBar: searchBar,
                dropdown: dropdown,
                resetBtn: resetBtn
            }
        );
    }

    const additionalDropDown = () => {
        let field = (
            <div>
            </div>
        );
        if (props.additionalDropDown) {
            field = props.additionalDropDown
        }
        return field;
    }

    const yearDropdown = props.yearDropdown == false ? props.yearDropdown : true;
    const dateFormat = props.dateFormat ? props.dateFormat : "MM-dd-YYYY";

    const mobileScreenLimit = 600;

    return (
        <div className="datatable-search">
            <form className={`d-flex ${mobileCss().mobileFlex}`}>
                {additionalDropDown()}
                <Select
                    styles={props.styles}
                    options={options}
                    isSearchable={false}
                    className={`datatable-select mr-md-2 ${mobileCss().dropdown} ${props.isSm ? "w-100": ""}`}
                    classNamePrefix="datatable-select"
                    value={props.searchFilter}
                    onChange={props.handleUpdateSearchFilter}
                    styles={{
                        option: (provided) => ({
                            ...provided,
                            fontFamily: "neo_semibold",
                            fontSize: ".6rem",
                            color: "#9A9B9C",
                            backgroundColor: "white"
                        }),
                        menu: () => ({
                            position: "absolute",
                            border: 0,
                            zIndex: "1090",
                            backgroundColor: "white",
                            borderRadius: "5px",
                            boxShadow: "2px 2px 2px #DDDEE0"
                        }),
                    }}
                />

                {props.searchFilter.value === 'applicationNumber' || props.searchFilter.value === 'name' ? (

                    <div className={`custom-searchbar datatable-search__container form-control rounded-pill ${mobileCss().searchBar} mr-md-2`}>
                        <input
                            value={props.searchTerm}
                            onChange={props.handleUpdateSearchTerm}
                            className="searchbar__input datatable-search__input h-100"
                            type="text"
                            onKeyPress={props.handlePressEnter}
                            placeholder="Search for"
                        />
                        <a className="btn searchbar__button searchbar__button--gray px-2">
                            <span className="icon-search"></span>
                        </a>
                    </div>
                ) : ""}

                {(props.searchFilter.value === "status") ?
                    (
                        <Select
                            styles={props.styles}
                            options={props.status}
                            isSearchable={false}
                            className={`datatable-select mr-md-2 ${mobileCss().dropdown} ${ props.isSm? 'w-100': '' }`}
                            classNamePrefix="datatable-select"
                            value={props.statusSearch}
                            onChange={props.handleUpdateStatusSearch}
                            styles={{
                                option: (provided) => ({
                                    ...provided,
                                    fontFamily: "neo_semibold",
                                    fontSize: ".6rem",
                                    color: "#9A9B9C",
                                    backgroundColor: "white"
                                }),
                                menu: () => ({
                                    position: "absolute",
                                    border: 0,
                                    zIndex: "1090",
                                    backgroundColor: "white",
                                    borderRadius: "5px",
                                    boxShadow: "2px 2px 2px #DDDEE0"
                                }),
                            }}
                        />
                    ) : ""}

                    {(props.searchFilter.value === "productOfferingName") ?
                        (
                            <Select
                                styles={props.styles}
                                options={props.planNames}
                                isSearchable={true}
                                className={`datatable-select mr-md-2 ${mobileCss().dropdown} ${ props.isSm? 'w-100': '' }`}
                                classNamePrefix="datatable-select"
                                value={props.statusSearch}
                                onChange={props.handleUpdateStatusSearch}
                                styles={{
                                    option: (provided) => ({
                                        ...provided,
                                        fontFamily: "neo_semibold",
                                        fontSize: ".6rem",
                                        color: "#9A9B9C",
                                        backgroundColor: "white"
                                    }),
                                    menu: () => ({
                                        position: "absolute",
                                        border: 0,
                                        zIndex: "1090",
                                        backgroundColor: "white",
                                        borderRadius: "5px",
                                        boxShadow: "2px 2px 2px #DDDEE0"
                                    }),
                                }}
                            />
                        ) : ""}

                    {(props.searchFilter.value === "receivingStatus") ?
                        (
                            <Select
                                styles={props.styles}
                                options={props.receivingStatus}
                                isSearchable={false}
                                className={`datatable-select mr-md-2 ${mobileCss().dropdown} ${ props.isSm? 'w-100': '' }`}
                                classNamePrefix="datatable-select"
                                value={props.statusSearch}
                                onChange={props.handleUpdateStatusSearch}
                                styles={{
                                    option: (provided) => ({
                                        ...provided,
                                        fontFamily: "neo_semibold",
                                        fontSize: ".6rem",
                                        color: "#9A9B9C",
                                        backgroundColor: "white"
                                    }),
                                    menu: () => ({
                                        position: "absolute",
                                        border: 0,
                                        zIndex: "1090",
                                        backgroundColor: "white",
                                        borderRadius: "5px",
                                        boxShadow: "2px 2px 2px #DDDEE0"
                                    }),
                                }}
                            />
                        ) : ""}

                {(props.searchFilter.value === "statusDate" || props.searchFilter.value === "encodedDate" || props.searchFilter.value === "receivingStatus") ?
                    (
                        <>
                            <div className={`policy-datepicker__part d-flex align-items-center position-relative mx-md-2 ${ props.isSm? 'w-100 mt-2': '' }`} style={{ zIndex: 1000 }}>
                                <div className={`form-control policy-datepicker__container rounded-pill ${props.fromDateError && props.searchFilter.value === "receivingStatus" ? 'border-red' : 'border-0'} h-100`}>
                                    <DatePicker
                                        placeholderText="From"
                                        className="policy-datepicker__input text-gray policy-datepicker__input--placeholder"
                                        dateFormat="yyyy-MM-dd"
                                        selected={props.startDate}
                                        onChange={props.handleUpdateStartDate}
                                        maxDate={props.endDate}
                                        renderCustomHeader={({
                                            date,
                                            changeYear,
                                            decreaseMonth,
                                            increaseMonth,
                                            prevMonthButtonDisabled,
                                            nextMonthButtonDisabled
                                        }) => (
                                                <DpCustomHeader
                                                    dropdownStyle={{
                                                        option: (provided) => ({
                                                            ...provided,
                                                            fontFamily: "neo_bold",
                                                            fontSize: ".65rem",
                                                            color: "#1e4a73",
                                                            backgroundColor: "white"
                                                        }),
                                                        menu: () => ({
                                                            position: "absolute",
                                                            border: 0,
                                                            zIndex: "1090",
                                                            backgroundColor: "white",
                                                            borderRadius: "5px",
                                                            width: "4rem",
                                                            boxShadow: "2px 2px 2px #DDDEE0"
                                                        }),
                                                    }}
                                                    yearOptions={Years(1990)}
                                                    date={date}
                                                    changeYear={yearDropdown ? changeYear : null}
                                                    decreaseMonth={decreaseMonth}
                                                    increaseMonth={increaseMonth}
                                                    prevMonthButtonDisabled={prevMonthButtonDisabled}
                                                    nextMonthButtonDisabled={nextMonthButtonDisabled}
                                                />
                                            )}
                                    />
                                    <span className="icon-calendar text-gray"></span>
                                </div>
                            </div>
                            <div className={`policy-datepicker__part d-flex align-items-center position-relative mx-md-2 ${ props.isSm? 'w-100 mt-2': '' }`} style={{ zIndex: 1000 }}>
                                <div className={`form-control policy-datepicker__container  policy-datepicker__mobile rounded-pill ${props.toDateError && props.searchFilter.value === "receivingStatus" ? 'border-red' : 'border-0'} h-100`}>
                                    <DatePicker
                                        placeholderText="To"
                                        className="policy-datepicker__input text-gray policy-datepicker__input--placeholder"
                                        dateFormat={"yyyy-MM-dd"}
                                        selected={props.endDate}
                                        onChange={props.handleUpdateEndDate}
                                        minDate={props.startDate}
                                        renderCustomHeader={({
                                            date,
                                            changeYear,
                                            decreaseMonth,
                                            increaseMonth,
                                            prevMonthButtonDisabled,
                                            nextMonthButtonDisabled
                                        }) => (
                                                <DpCustomHeader
                                                    dropdownStyle={{
                                                        option: (provided) => ({
                                                            ...provided,
                                                            fontFamily: "neo_bold",
                                                            fontSize: ".65rem",
                                                            color: "#1e4a73",
                                                            backgroundColor: "white"
                                                        }),
                                                        menu: () => ({
                                                            position: "absolute",
                                                            border: 0,
                                                            zIndex: "1090",
                                                            backgroundColor: "white",
                                                            borderRadius: "5px",
                                                            width: "4rem",
                                                            boxShadow: "2px 2px 2px #DDDEE0"
                                                        }),
                                                    }}
                                                    yearOptions={Years(1990)}
                                                    date={date}
                                                    changeYear={yearDropdown ? changeYear : null}
                                                    decreaseMonth={decreaseMonth}
                                                    increaseMonth={increaseMonth}
                                                    prevMonthButtonDisabled={prevMonthButtonDisabled}
                                                    nextMonthButtonDisabled={nextMonthButtonDisabled}
                                                />
                                            )}
                                    />
                                    <span className="icon-calendar text-gray"></span>
                                </div>
                            </div>
                        </>
                    ) : ""
                }

                {props.searchFilter.value !== "all" && props.searchFilter.value !== "status" ? 
                (
                <a
                    className={`lh-1 d-flex mt-2 mt-md-0 justify-content-center align-items-center btn btn-dropdowncolor rounded-pill text-uppercase ${props.isSm ? "font-p75rem" : "font-p65rem"} font-neo-semibold text-white datatable-search__btn ${props.searchFilter.value === "statusDate" && props.isSm? "w-100 ml-0": "ml-2" }`}
                    onClick={props.handleSubmit}>
                    Submit
                    </a>
                ) : ""
                }
            </form>
        </div>
    );
}

// Search.propTypes = {
//     isLg: PropTypes.bool,
//     isMd: PropTypes.bool,
//     isSm: PropTypes.bool,
//     options: PropTypes.array,
//     searchTerm: PropTypes.string,
//     searchFilter: PropTypes.object
// }

export default withWindowsDimensions(Search);