import React, { Component } from 'react';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import withWindowDimensions from '../commons/withWindowDimensions';

class DatatableSearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filtered: [],
            filterAll: '',
            filterQuery: '',
            filterBy: '',
            dosearch: false,
        }

        this.handleOptionChange = this.handleOptionChange.bind(this);
        this.handleSearchValueChange = this.handleSearchValueChange.bind(this);
    }

    async componentDidMount() {
        console.log("remount");
    }

    componentDidUpdate() {
        //MAKE DATEPICKER INPUT FIELDS READONLY
        const datePicker = document.getElementsByClassName("react-datepicker__input-container");
        for (let i = 0; i < datePicker.length; i++) {
            datePicker[i].childNodes[0].setAttribute("readOnly", true);
        }
    }
    onFilteredChange(filtered) {


        // extra check for the "filterAll"
        if (filtered.length > 1 && this.state.filterAll.length) {
            // NOTE: this removes any FILTER ALL filter
            const filterAll = '';
            this.setState({ filtered: filtered.filter((item) => item.id != 'all'), filterAll })
        }
        else
            this.setState({ filtered });
    }

    doChange = (e) => {
        const { value } = e.target;
        const filterAll = value;
        let filterQuery = value;
        const filtered = [{ id: 'all', value: filterAll }];
        // NOTE: this completely clears any COLUMN filters
        this.setState({ filterAll, filtered, filterQuery });
    }

    handleOptionChange = (selectedOption) => {
        //nullify search value if option selected is different from current option state
        if (selectedOption.value !== this.props.optionSelectedValue.value) {
            this.nullOnChange();
        }
        //update selected option
        this.props.updateOptionSelected(selectedOption);
    }

    nullOnChange = () => {
        //turn search value to null every option change
        if (this.props.updateSearchValue) {
            this.props.updateSearchValue("");
        }
        if (this.props.updateDobStart) {
            this.props.updateDobStart(null);
        }
        if (this.props.updateDobEnd) {
            this.props.updateDobEnd(null);
        }
        if (this.props.updateEffDate) {
            this.props.updateEffDate(null);
        }
        if (this.props.updateExpDate) {
            this.props.updateExpDate(null);
        }
    }

    handleSearchValueChange = (searchValue) => {
        let value = searchValue.target.value;
        this.props.updateSearchValue(value);
    }

    doSearch = () => {
        console.log("doSearch");
        this.props.search(0);
    }

    mobileCss = () => {
        let mobileFlex = "";
        let searchBar = "";
        let dropdown = "";
        let resetBtn = "";
        if (this.props.isSm) {
            mobileFlex = "flex-wrap ";
            searchBar = "w-100";
            dropdown = "ml-0 w-70 mt-2 w-100";
            resetBtn = "ml-auto ml-0 w-25 mt-2"
        }
        return (
            {
                mobileFlex: mobileFlex,
                searchBar: searchBar,
                dropdown: dropdown,
                resetBtn: resetBtn
            }
        );
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.doSearch();
        } else {

        }
    }

    searchByOption = () => {
        let field = (
            <div className={`custom-searchbar datatable-search__container form-control rounded-pill ${this.mobileCss().searchBar}`}>
                <input value={this.props.searchValue} onChange={this.handleSearchValueChange} className="searchbar__input datatable-search__input h-100" type="text" placeholder="Search for" onKeyPress={(event) => this.handleKeyPress(event)} />
                <a onClick={() => this.doSearch()} className="btn searchbar__button searchbar__button--gray px-2">
                    <span className="icon-search"></span>
                </a>
            </div>
        );
        if (this.props.searchByOption) {
            field = this.props.searchByOption
        }
        return field;
    }

    render() {
        console.log("redraw");
        return (
            <div className="datatable-search">
                <form className={`d-flex ${this.mobileCss().mobileFlex}`}>
                    {this.searchByOption()}
                    <Select
                        styles={this.props.styles}
                        options={this.props.options}
                        isSearchable={false}
                        className={`datatable-select ${this.mobileCss().dropdown}`}
                        classNamePrefix="datatable-select"
                        placeholder={this.props.placeholder}
                        value={this.props.optionSelectedValue}
                        onChange={this.handleOptionChange}
                    />
                    <a
                        className={`lh-1 d-flex align-items-center justify-content-center btn btn-dropdowncolor rounded-pill text-uppercase ${this.props.isSm ? "font-p75rem" : "font-p65rem"} font-neo-semibold ml-2 text-white ${this.mobileCss().resetBtn} datatable-search__btn`}
                        onClick={() => { this.doSearch(); }}
                    // type="reset"
                    // type="submit"
                    // onClick={this.handleResetFields()}

                    >submit</a>
                </form>
            </div>

        );
    }
}
export default withWindowDimensions(DatatableSearch);
