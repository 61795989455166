import React, { Component, Fragment } from 'react';
import Select from 'react-select';
import * as DOMPurify from 'dompurify'
import UploadProofForm from './UploadProof/uploadProofForm';

class Form extends Component {
  state = {
    errorMessage: "",
    tooltips: {
      middleName: false,
    },
  }

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    // Manually add the target attribute on the FTA Restriction message. Unable to include target= attribute from BE response
    document.getElementById("js-fta-message").getElementsByTagName("a")[0].setAttribute("target", "_blank");
  }

  handleTextChanges(e) {
    this.props.handleTextChanges(e);
  }

  handleSelectChange(object, stateName) {
    this.props.handleSelectChange(object, stateName);
  }

  handleRadioChange(value) {
    this.props.handleRadioChange(value);
  }

  handleFileDataProofChange = (values, type) => {
    this.props.handleFileDataProofChange({ proofBase64Files: [...values.base64], proofRawFiles: [...values.rawFiles] }, type);
  }

  displayError = (message) => {
    if (message) {
      this.setState({ errorMessage: `${message}` });
    }
  }

  hideError() {
    this.setState({ errorMessage: "" });
  }

  tooltipHandler(action, tooltip) {
    let tooltipsState = { ...this.state.tooltips };
    switch (action) {
      case "enter":
        tooltipsState[tooltip] = true;
        break;
      case "leave":
      default:
        tooltipsState[tooltip] = false;
    }

    this.setState({
      tooltips: { ...tooltipsState }
    })
  }

  handleMiddleNameCheckbox = () => {
    this.props.handleMiddleNameCheckbox();
    this.handleTextChanges({ target: { name: "bankAccountMiddleInitial", value: "" } })
  }

  allowDigitsOnly = (e) => {
    const key = e.key;
    if ((/\D/).test(key)) {
      e.preventDefault();
      return false;
    }
    return true;
  }

  render() {
    let formValidation = {
      bank: { value: true, length: true, hasError: false },
      bankAddress: { value: true, length: true, hasError: false },
      bankAccountLastName: { value: true, length: true, hasError: false },
      bankAccountFirstName: { value: true, length: true, hasError: false },
      bankAccountMiddleInitial: { value: true, length: true, hasError: false },
      bankAccountName: { value: true, length: true, hasError: false },
      bankAccountNumber: { value: true, length: true, hasError: false },
      bankAccountNumberConfirmation: { value: true, length: true, hasError: false },
      bankAccountNumberMatch: { value: true, length: true, hasError: false },
      bankCurrency: { value: true, length: true, hasError: false },
      bankType: { value: true, length: true, hasError: false },
      bankCoDepositorName: { value: true, length: true, hasError: false },
      bankProofRawFiles: { value: true, hasError: false }
    }

    if (this.props.isFormSubmitted) {
      formValidation = { ...this.props.formValue };
    }
    return (
      <Fragment>
        {
          this.state.errorMessage.length > 0 &&
          <div className="alert alert-danger" role="alert">
            {this.state.errorMessage}
          </div>

        }
        <form id="js-fta-form">
          <div className="review-transaction__message review-transaction__message--padding">
            <div className="d-flex justify-content-between mb-0"> 
              <div className='fta-form__polnum'>
                <label className="mb-0">Policy Number</label>
                <input className="form-control fta-form__input" value={this.props.policyNum} disabled={true} readOnly />
              </div>
              <div className='fta-form__polnum'>
                <label className="mb-0">Policy Currency</label>
                <input className="form-control fta-form__input" value={this.props.policyCurrency} disabled={true} readOnly />
              </div>
              
            </div>
            
            <label className="mb-0 mt-4">Bank Name</label>
            <Select
              classNamePrefix="fta-select"
              styles={{
                option: (provided) => ({
                  ...provided,
                  fontFamily: "neo_semibold",
                  color: "white",
                  backgroundColor: "#255f8e"
                }),
                menu: () => ({
                  position: "absolute",
                  border: "1px solid white",
                  zIndex: "1090",
                  backgroundColor: "#255f8e",
                  borderRadius: "5px",
                }),
                control: (provided) => ({
                  ...provided,
                  borderColor: formValidation.bank.hasError ? "#FF2400" : "white",
                })
              }}
              placeholder="Select..."
              options={this.props.bankList}
              isSearchable={true}
              value={this.props.bank}
              name="bank"
              onChange={(event) => this.handleSelectChange(event, "bank")}
            />
            {!formValidation.bank.value &&
              <small className="fta-form__field-message">Please fill out this field.</small>
            }
            <p className={(!this.props.bank || ["BDO", "METRO", "PNB", "PNBFAB", "PNBS"].includes(this.props.bank.value)) ? "d-none" : ""}>
              <i>Fund transfers to banks other than BDO, Metrobank and Philippine National Bank are subject to charges at your own account</i>
            </p>
            <div>
              <label className="mb-0 mt-4">Bank Branch or Bank Address</label>
              <input
                className={`form-control fta-form__input ${formValidation.bankAddress.hasError ? "fta-form__input--error" : ""}`}
                type="text"
                maxLength="50"
                onChange={(event) => this.handleTextChanges(event)} name="bankAddress" value={this.props.bankAddress} />
              {!formValidation.bankAddress.value &&
                <small className="fta-form__field-message">Please fill out this field.</small>
              }
              {(formValidation.bankAddress.value && !formValidation.bankAddress.length) &&
                <small className="fta-form__field-message">The minimum number of characters is 2.</small>
              }
            </div>
            <p>
              <i>e.g PNB Allied Bank Center, Ayala Ave, Makati</i>
            </p>
            <label className="mb-0 mt-4">Bank Account Name</label>
            <div className="d-flex justify-content-between mb-0">
              <div className="fta-form__name">
                <input
                  className={`form-control fta-form__input ${formValidation.bankAccountLastName.hasError ? "fta-form__input--error" : ""}`}
                  type="text" onChange={(event) => this.handleTextChanges(event)} name="bankAccountLastName" value={this.props.bankAccountLastName}
                  placeholder="Last Name"
                />
                {!formValidation.bankAccountLastName.value &&
                  <small className="fta-form__field-message">Please fill out this field.</small>
                }
                {(formValidation.bankAccountLastName.value && !formValidation.bankAccountLastName.length) &&
                  <small className="fta-form__field-message">The minimum number of characters is 2.</small>
                }
              </div>
              <div className="fta-form__name">
                <input
                  className={`form-control fta-form__input ${formValidation.bankAccountFirstName.hasError ? "fta-form__input--error" : ""}`}
                  type="text" onChange={(event) => this.handleTextChanges(event)} name="bankAccountFirstName" value={this.props.bankAccountFirstName}
                  placeholder="First Name"
                />
                {!formValidation.bankAccountFirstName.value &&
                  <small className="fta-form__field-message">Please fill out this field.</small>
                }
                {(formValidation.bankAccountFirstName.value && !formValidation.bankAccountFirstName.length) &&
                  <small className="fta-form__field-message">The minimum number of characters is 2.</small>
                }
              </div>
              <div className="fta-form__name position-relative">
                {this.state.tooltips.middleName &&
                  <div className="fta-form__tooltip tooltip bs-tooltip-top opacity-1" role="tooltip">
                    <div className="arrow"></div>
                    <div className="tooltip-inner">
                      Please complete your middle name
                    </div>
                  </div>
                }
                <input
                  className={`form-control fta-form__input ${this.props.noMiddleName ? "pe-none" : ""} ${formValidation.bankAccountMiddleInitial.hasError ? "fta-form__input--error" : ""}`}
                  onMouseEnter={() => this.tooltipHandler("enter", "middleName")}
                  onMouseLeave={() => this.tooltipHandler("leave", "middleName")}
                  type="text"
                  placeholder={`${this.props.noMiddleName ? "N/A" : "Middle Name"}`}
                  onChange={(event) => this.handleTextChanges(event)}
                  name="bankAccountMiddleInitial"
                  value={this.props.bankAccountMiddleInitial}
                  disabled={this.props.noMiddleName}
                />
                {!formValidation.bankAccountMiddleInitial.value &&
                  <small className="fta-form__field-message">Please fill out this field.</small>
                }
                {(formValidation.bankAccountMiddleInitial.value && !formValidation.bankAccountMiddleInitial.length) &&
                  <small className="fta-form__field-message">The minimum number of characters is 2.</small>
                }
                <div className="review-transaction__metric-checkbox">
                  <span className="review-transaction__metric-span mt-1">
                    <input type="checkbox" checked={this.props.noMiddleName} />
                    <label>No middle name</label>
                    <span
                      className="review-transaction__checkmark"
                      onClick={this.handleMiddleNameCheckbox}
                    ></span>
                  </span>
                </div>
              </div>
            </div>
            {!formValidation.bankAccountName.length &&
              <small className="fta-form__field-message">The maximum number of characters for the Bank Account Name is 160 (You are currently using {formValidation.bankAccountName.value.length} characters).</small>
            }
            <p id="js-fta-message">
              <i>
                {this.props.restrictionMessages.map((item) => {
                  return (
                    <Fragment key={item.sortingNumber}>
                      <span className="text-break fta-form__message" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.printDescription) }} />
                    </Fragment>
                  )
                })}
              </i>
            </p>
            <section className="fta-form__upload-proof">
              <p className="text-center">Please upload the Policy Amendment form here</p>
              <UploadProofForm
                moduleHasError={false}
                documentClass={'Policy Amendment Form'}
                handleFileData={(obj) => this.handleFileDataProofChange(obj, 'policyAmendmentForm')}
                proofFiles={this.props.policyAmendmentRawFiles}
                proofBase64Files={this.props.policyAmendmentBase64Files}
              />
            </section>
            <section className="fta-form__upload-proof">
              <p className="text-center">Please upload proof of discrepancy here (e.g. Marriage Contract, Court Order, Birth Certificate)</p>
              <UploadProofForm
                moduleHasError={false}
                documentClass={'Valid Identification'}
                handleFileData={(obj) => this.handleFileDataProofChange(obj, 'validIdentification')}
                proofFiles={this.props.validIdentificationRawFiles}
                proofBase64Files={this.props.validIdentificationBase64Files}
              />
            </section>
            <div>
              <label className="mb-0 mt-4">Bank Account Number</label>
              <input
                className={`form-control fta-form__input ${formValidation.bankAccountNumber.hasError ? "fta-form__input--error" : ""}`}
                type="text"
                maxLength="25"
                onKeyPress={(e) => this.allowDigitsOnly(e)}
                onChange={(event) => this.handleTextChanges(event)} name="bankAccountNumber" value={this.props.bankAccountNumber} />
              {!formValidation.bankAccountNumber.value &&
                <small className="fta-form__field-message">Please fill out this field.</small>
              }
              {(formValidation.bankAccountNumber.value && !formValidation.bankAccountNumber.length) &&
                <small className="fta-form__field-message">The minimum number of characters is 2.</small>
              }
              <label className="mb-0 mt-4">Confirm Bank Account Number</label>
              <input
                className={`form-control fta-form__input ${formValidation.bankAccountNumberConfirmation.hasError ? "fta-form__input--error" : ""}`}
                name="bankAccountNumberConfirmation"
                type="text"
                value={this.props.bankAccountNumberConfirmation}
                onChange={(event) => this.handleTextChanges(event)}
                onPaste={(e) => { e.preventDefault(); return false; }}
                maxLength="25"
                onKeyPress={(e) => this.allowDigitsOnly(e)}
              />
              {!formValidation.bankAccountNumberConfirmation.value &&
                <small className="fta-form__field-message">Please fill out this field.&nbsp;</small>
              }
              {!formValidation.bankAccountNumberMatch.value &&
                <small className="fta-form__field-message">Bank Account Number and Confirm Bank Account Number does not match.</small>
              }
            </div>
            <label className="mb-0 mt-4">Bank Account Currency</label>
            <Select
              classNamePrefix="fta-select"
              styles={{
                option: (provided) => ({
                  ...provided,
                  fontFamily: "neo_semibold",
                  color: "white",
                  backgroundColor: "#255f8e"
                }),
                menu: () => ({
                  position: "absolute",
                  border: "1px solid white",
                  zIndex: "1090",
                  backgroundColor: "#255f8e",
                  borderRadius: "5px",
                }),
                control: (provided) => ({
                  ...provided,
                  borderColor: !formValidation.bankCurrency.value ? "#FF2400" : "white",
                })
              }}
              placeholder="Select..."
              options={this.props.bankCurrencyList}
              isSearchable={true}
              name="bankCurrency"
              value={this.props.bankCurrency}
              onChange={(event) => this.handleSelectChange(event, "bankCurrency")}
            />
            {!formValidation.bankCurrency.value &&
              <small className="fta-form__field-message">Please fill out this field.</small>
            }
            <p>
              <i>This must be in the same currency as the plan otherwise the fees to facilitate conversion and the conversion rate is determined by the releasing bank and shall be charged to your account.</i>
            </p>
            <label className="mb-0 mt-4">Type of Bank Account</label>
            <Select
              classNamePrefix="fta-select"
              styles={{
                option: (provided) => ({
                  ...provided,
                  fontFamily: "neo_semibold",
                  color: "white",
                  backgroundColor: "#255f8e"
                }),
                menu: () => ({
                  position: "absolute",
                  border: "1px solid white",
                  zIndex: "1090",
                  backgroundColor: "#255f8e",
                  borderRadius: "5px",
                }),
                control: (provided) => ({
                  ...provided,
                  borderColor: !formValidation.bankType.value ? "#FF2400" : "white",
                })
              }}
              placeholder="Select..."
              options={this.props.bankTypeList}
              isSearchable={true}
              value={this.props.bankType}
              name="bankType"
              onChange={(event) => this.handleSelectChange(event, "bankType")}
            />
            {!formValidation.bankType.value &&
              <small className="fta-form__field-message">Please fill out this field.</small>
            }
            {
              this.props.requiredCoDepositor &&
              <Fragment>
                <div>
                  <label className="mb-0 mt-4">Co-Depositor name</label>
                  <input
                    className={`form-control fta-form__input ${formValidation.bankCoDepositorName.hasError ? "fta-form__input--error" : ""}`}
                    type="text"
                    maxLength="160"
                    onChange={(event) => this.handleTextChanges(event)} name="bankCoDepositorName" value={this.props.bankCoDepositorName} />
                  {!formValidation.bankCoDepositorName.value &&
                    <small className="fta-form__field-message">Please fill out this field.</small>
                  }
                  {(formValidation.bankCoDepositorName.value && !formValidation.bankCoDepositorName.length) &&
                    <small className="fta-form__field-message">The minimum number of characters is 2.</small>
                  }
                </div>
              </Fragment>
            }
            <section className="fta-form__upload-proof">
              <p className="mt-4 mb-2">
                To complete the bank account enrollment, upload a valid proof that the bank account number enrolled is in the name of the Policy Owner. Upload a clear image of any of the following.
              </p>
              <ul>
                <li>Certificates from the bank (e.g. debit to casa/ bills payment)</li>
                <li>Deposit receipt with electronic validation</li>
                <li>Passbook</li>
                <li>Statement of Account</li>
              </ul>
              <p className="mt-2 mb-2">
                Valid proof of bank account ownership must contain all of the following.
              </p>
              <ul>
                <li>Name of the bank</li>
                <li>The bank account name (must be the same as the policy owner) and</li>
                <li>The bank account number</li>
              </ul>
              <p className="mt-2 mb-2">
                <strong>Expect a delay in payout if the proof of bank account ownership is not valid.</strong>
              </p>
              <p className="mt-2 mb-2">
                To ensure collection of data is limited to what is necessary, additional irrelevant data appearing in these artifacts may be excluded.
              </p>
              <UploadProofForm
                moduleHasError={formValidation.bankProofRawFiles.hasError}
                documentClass={'Proof of Bank Ownership'}
                handleFileData={(obj) => this.handleFileDataProofChange(obj, 'bankOwnership')}
                proofFiles={this.props.bankProofRawFiles}
                proofBase64Files={this.props.bankProofBase64Files}
              />
            </section>
            <p className="mt-4 mb-2">
              Do you want to update the Fund Transfer details above to all policies?
            </p>
            <label className="mr-2">
              <input
                className="review-transaction__radio-button d-none" type="radio"
                onClick={() => this.handleRadioChange(true)}
              ></input>
              <span
                className={'review-transaction__radio-button' +
                  (this.props.applyToAllPolicies
                    ? ' icon-radio-button-checked' : ' icon-radio-button-unchecked')
                }
              ></span>
              <span>Yes</span>
            </label>
            <label className="ml-2">
              <input
                className="review-transaction__radio-button d-none" type="radio"
                onClick={() => this.handleRadioChange(false)}
              ></input>
              <span
                className={'review-transaction__radio-button' +
                  (!this.props.applyToAllPolicies
                    ? ' icon-radio-button-checked' : ' icon-radio-button-unchecked')
                }
              ></span>
              <span>No</span>
            </label>

            {this.props.applyToAllPolicies ? 
                  this.props.showPolicies.map(policy => (
                    <div className="d-flex space-between mb-0 ml-4">
                      <p className='mb-1'>{policy.polNo}</p>
                      <p className='mb-1 ml-4'>{policy.productOfferingName}</p>
                    </div>))
                 : <></>
            }
            
          </div>
        </form>
      </Fragment>
    )
  }
}

export default Form;