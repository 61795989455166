import React from 'react';
import DatePicker from 'react-datepicker';
import DpCustomHeader, {Years} from "../../Datepicker/customHeader";
import withWindowDimensions from '../../../commons/withWindowDimensions';

const DateRange = (props) => {
    const dateFormat = "MM/dd/yyyy";
    const yearList = Years(1990).reverse();
    return (
        <>
            <div className={`policy-datepicker__part d-flex align-items-center position-relative ${props.isSm ? 'w-50' : ''} mr-2 ${props.isSm? 'mt-2' : ''}`} style={{ flexBasis: props.isSm? '30%' : 'auto' }}>
                <div className="form-control policy-datepicker__container rounded-pill border-0 h-100">
                    <DatePicker
                        placeholderText="From"
                        className="policy-datepicker__input text-gray policy-datepicker__input--placeholder"
                        dateFormat={dateFormat}
                        selected={props.startDate}
                        onChange={props.handleUpdateStartDate}
                        maxDate={props.endDate}
                        renderCustomHeader={({
                            date,
                            changeYear,
                            decreaseMonth,
                            increaseMonth,
                            prevMonthButtonDisabled,
                            nextMonthButtonDisabled
                        }) => (
                                <DpCustomHeader
                                    dropdownStyle={{
                                        option: (provided) => ({
                                            ...provided,
                                            fontFamily: "neo_bold",
                                            fontSize: ".65rem",
                                            color: "#1e4a73",
                                            backgroundColor: "white"
                                        }),
                                        menu: () => ({
                                            position: "absolute",
                                            border: 0,
                                            zIndex: "1090",
                                            backgroundColor: "white",
                                            borderRadius: "5px",
                                            width: "4rem",
                                            boxShadow: "2px 2px 2px #DDDEE0"
                                        }),
                                    }}
                                    yearOptions={yearList}
                                    date={date}
                                    // changeYear={yearDropdown ? changeYear : null}
                                    changeYear={changeYear}
                                    decreaseMonth={decreaseMonth}
                                    increaseMonth={increaseMonth}
                                    prevMonthButtonDisabled={prevMonthButtonDisabled}
                                    nextMonthButtonDisabled={nextMonthButtonDisabled}
                                />
                            )}
                    />
                    <span className="icon-calendar text-gray"></span>
                </div>
            </div>
            <div className={`policy-datepicker__part d-flex align-items-center position-relative mx-md-2 ${props.isSm? 'mt-2' : ''}`} style={{ flexBasis: props.isSm? '30%' : 'auto' }}>
                <div className="form-control policy-datepicker__container  policy-datepicker__mobile rounded-pill border-0 h-100">
                    <DatePicker
                        placeholderText="To"
                        className="policy-datepicker__input text-gray policy-datepicker__input--placeholder"
                        dateFormat={dateFormat}
                        selected={props.endDate}
                        onChange={props.handleUpdateEndDate}
                        minDate={props.startDate}
                        renderCustomHeader={({
                            date,
                            changeYear,
                            decreaseMonth,
                            increaseMonth,
                            prevMonthButtonDisabled,
                            nextMonthButtonDisabled
                        }) => (
                                <DpCustomHeader
                                    dropdownStyle={{
                                        option: (provided) => ({
                                            ...provided,
                                            fontFamily: "neo_bold",
                                            fontSize: ".65rem",
                                            color: "#1e4a73",
                                            backgroundColor: "white"
                                        }),
                                        menu: () => ({
                                            position: "absolute",
                                            border: 0,
                                            zIndex: "1090",
                                            backgroundColor: "white",
                                            borderRadius: "5px",
                                            width: "4rem",
                                            boxShadow: "2px 2px 2px #DDDEE0"
                                        }),
                                    }}
                                    yearOptions={yearList}
                                    date={date}
                                    changeYear={changeYear}
                                    decreaseMonth={decreaseMonth}
                                    increaseMonth={increaseMonth}
                                    prevMonthButtonDisabled={prevMonthButtonDisabled}
                                    nextMonthButtonDisabled={nextMonthButtonDisabled}
                                />
                            )}
                    />
                    <span className="icon-calendar text-gray"></span>
                </div>
            </div>
        </>
    );
}

export default withWindowDimensions(DateRange);