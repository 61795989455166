import React, { Component } from 'react';
import { ReportLoader } from '../commons/utilityViews.js';
import { myConfig } from '../commons/config.js';
import { withRouter, Link } from 'react-router-dom';
import 'react-vertical-timeline-component/style.min.css';
// import { urlBase64ToUint8Array } from '../commons/utility.js';
import ReactTable from 'react-table';
import ClientPolicyNav from '../components/clientPolicyNav';
import DatatableSearch from '../components/datatableSearch';
import { isManager, checkLogin, checkPermissions, getSessionInfo } from '../commons/security.js';
import Clients from '../components/Clients/Clients';
import { fixName, checkDateRange, fixAddress, removeNull, checkSearch, prettifyDate, calculateAge, writeData, readAllData, encrypt, decrypt, renameByChannel } from '../commons/utility.js';
import routeChange from '../commons/routeChange';
import { apiModule, createQueryStrings } from '../commons/apiCall';
import DpCustomHeader, { Years } from '../components/Datepicker/customHeader';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import withWindowDimensions from '../commons/withWindowDimensions';
import handleSortOrder from '../components/misc/sortOrder';

class ClientInfo extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            error: "",
            clients: null,
            loadTable: true,
            rows: -1,
            pages: 10,
            currentPage: 0,
            headerOptions: [],
            totalSize: 0,
            dobStart: null,
            dobEnd: null,
            searchValue: "",

            //branch List
            branchList: [],
            branchValue: null,

            isMgr: false,
            optionSelected: { value: "contractHolderFirstName", label: "Owner First Name" },
            faLabel: "Financial Advisor",
            sortByField: null,
            sortOrder: null,
            isAllowed: false,
            hasNewlyAssignedPolicies: false,

        };
        this.handleStartDobChange = this.handleStartDobChange.bind(this);
        this.handleEndDobChange = this.handleEndDobChange.bind(this);
        this.handleBranchListChange = this.handleBranchListChange.bind(this);
    }


    async componentDidMount() {

        let access_token = this.props.getSession().access_token;
        let session = await getSessionInfo();
        let permissionsList = session[0].permissions;

        if (checkPermissions(['VIEW_CL_LIST'], permissionsList)) {
            let faLabel = renameByChannel("Financial Advisor", this.props.getSession().channel, this.props.getSession().view_as_channel);
            
            this.setState({ isAllowed: true, isLoading: true, access_token: access_token, isMgr: isManager(this.props.getSession().role), faLabel });
            this.handleDisplayingNewlyAssignedPolicies(access_token, decrypt(this.props.getSession().agentNumber));
            await this.fetchClients(this.props.getSession().access_token, decrypt(this.props.getSession().agentNumber));
            
        }else {
           
            this.setState({ isAllowed: false, isLoading: false, error: "You are not allowed to access this page" });
        }
    }

    beginLoading = () => {
        this.setState({
            isLoading: true
        })
    }

    handleStartDobChange = (date) => {
        this.setState({
            dobStart: date,
        }, () => {
            if (this.state.dobEnd != null) {
                this.search(this.state.currentPage);
            }
        });
    }

    handleEndDobChange = (date) => {
        this.setState({
            dobEnd: date,
        }, () => {
            if (this.state.dobStart != null) {
                this.search(this.state.currentPage);
            }
        });
    }

    handleBranchListChange = (branchValue) => {
        this.setState({
            branchValue: branchValue
        }, () => {
            this.search(this.state.currentPage);
        });
    }

    updateOptionSelected = (option) => {
        if (option.value == 'branchId' && this.state.optionSelected.value != 'branchId') {
            this.getBranchList(option);
        } else {
            this.setState({
                optionSelected: option
            });
        }
    }

    updateSearchValue = (value) => {
        this.setState({
            searchValue: value,
        });
    }

    async getBranchList(selectedOption) {
        await this.fetchBranchList(selectedOption, this.props.getSession().access_token, decrypt(this.props.getSession().agentNumber));
    }

    handleSortAction = (sortByField, sortOrder) => {
        this.setState({
            sortByField: sortByField,
            sortOrder: sortOrder,
            // isLoading: true
            loadTable: true,
        }, () => {
            this.search(this.state.currentPage);
        })
    }
    headerClickedHandler = (sortByField) => {
        let sortOrder = handleSortOrder({ sortByField: sortByField, state: this.state });
        this.handleSortAction(sortByField, sortOrder)
    }


    fetchBranchList = (selectedOption, access_token, agentNumber) => {
        let that = this;
        return new Promise(function (resolve, reject) {

            let headerParams = {
                x_auth: access_token,
                dataListId: 'intmBranches'
            };
            let filter = [];

            filter['key'] = agentNumber;

            apiModule("get_datalist", headerParams, null, createQueryStrings(filter))
                .then(function (response) {

                    let result = response;
                    let branchList = [];

                    //IF RESULT IS NULL THEN ERROR MESSAGE
                    if (result && result.error == null) {

                        Object.keys(result).forEach(function (key) {
                            let item = key;
                            branchList.push(
                                new Object({
                                    value: item,
                                    label: result[key]
                                })
                            );
                        });
                        console.log(branchList);
                        that.setState({
                            branchList: branchList,
                            isLoading: false,
                            optionSelected: selectedOption
                        });
                    } else {
                        that.setState({
                            isLoading: false,
                            optionSelected: selectedOption
                        }, () => {
                            //  that.errorMessage("No Records Found");
                        });
                    }
                })
                .catch(function (err) {
                    console.log(err);
                    that.setState({
                        isLoading: false,
                        policyStatuslist: []
                    }, () => {
                        that.errorMessage(myConfig.GENERIC_ERROR_MESSAGE);
                        console.log(err.message)
                    });
                })
        });
    }

    handleDisplayingNewlyAssignedPolicies = (access_token, agentNumber) => {
        let that = this;
        return new Promise(function (resolve, reject) {

            let headerParams = { x_auth: access_token };

            let filter = [];
            filter['pageNumber'] = (that.state.currentPage + 1);
            filter['agentNumber'] = agentNumber;
            filter['pageSize'] = myConfig.PAGE_SIZE;
            filter['includeFields'] = "NEW_CLIENT";

            apiModule("policy_all", headerParams, null, createQueryStrings(filter))
                .then(function (response) {
                    if (response.length > 0) {
                        that.setState({ hasNewlyAssignedPolicies: true })
                    }
                })
                .catch(function (err) {
                    that.setState({ hasNewlyAssignedPolicies: false })

                });    
            }
        )
    }

    fetchClients = (access_token, agentNumber) => {
        let that = this;
        console.log("fetchClients - inside");
        return new Promise(function (resolve, reject) {

            let headerParams = { x_auth: access_token };
            let filter = [];
            filter['pageNumber'] = (that.state.currentPage + 1);
            filter['agentNumber'] = agentNumber;
            filter['pageSize'] = myConfig.PAGE_SIZE;
            filter['accountManagerId'] = agentNumber;


            apiModule("client_all", headerParams, null, createQueryStrings(filter))
                .then(function (response) {

                    if (response.length > 0) {
                        let result = response[0].content;
                        let totalSize = response[0].totalSize;
                        let numOfPages = parseInt((totalSize / myConfig.PAGE_SIZE), 10);

                        if ((totalSize % myConfig.PAGE_SIZE) > 0) {
                            numOfPages++;
                        }
                        let headerOptions = [];
                        let clients = [];
                        //IF RESULT IS NULL THEN ERROR MESSAGE
                        if (result) {



                            for (let i = 0; i < result.length; i++) {

                                let address = "--";
                                if (result[i].addresses !== null && result[i].addresses !== undefined) {
                                    if (result[i].addresses.length > 0) {
                                        address = fixAddress(result[i].addresses[0]);
                                    }
                                }




                                if (that.state.isMgr && result[i].accountManagers !== undefined && result[i].accountManagers !== null) {
                                    if (result[i].accountManagers.length > 0) {
                                        clients.push({
                                            clientId: result[i].globalId,
                                            name: `${fixName(result[i])}`, //.lastName}, ${result[i].firstName} ${result[i].middleName}`,
                                            last_name: `${result[i].lastName}`,
                                            first_name: `${result[i].firstName}`,
                                            age: calculateAge(result[i].birthDate),
                                            birthDate: prettifyDate(result[i].birthDate),
                                            LOCATION: removeNull(address),
                                            email: removeNull(result[i].email),
                                            contactno: removeNull(result[i].phoneNumber),
                                            FANAME: removeNull(result[i].accountManagers[0].name),
                                            fa_number: removeNull(result[i].accountManagers[0].agentNumber),
                                            fa_am_number: removeNull(result[i].accountManagers[0].agentNumberOfAdvisorOfAccountManager),
                                        });
                                    } else {
                                        clients.push({
                                            clientId: result[i].globalId,
                                            name: `${fixName(result[i])}`, //`${result[i].lastName}, ${result[i].firstName} ${result[i].middleName}`,
                                            last_name: `${result[i].lastName}`,
                                            first_name: `${result[i].firstName}`,
                                            age: calculateAge(result[i].birthDate),
                                            birthDate: prettifyDate(result[i].birthDate),
                                            LOCATION: removeNull(address),
                                            email: removeNull(result[i].email),
                                            contactno: removeNull(result[i].phoneNumber),
                                            FANAME: removeNull(null),
                                            fa_number: removeNull(null),
                                            fa_am_number: removeNull(null),
                                        });
                                    }

                                    headerOptions =
                                        [
                                            { value: "birthDate", label: "Birth Date Range" },
                                            { value: "branchId", label: "Location/Branch" },
                                            { value: "contractHolderFirstName", label: "Owner First Name" },
                                            { value: "contractHolderLastName", label: "Owner Last Name" },
                                            // { value: "insuredFirstName", label: "Insured First Name" },
                                            // { value: "insuredLastName", label: "Insured Last Name" },
                                            //  { value: "fa_name", label: "Financial Advisor" }
                                        ]

                                } else {
                                    clients.push({
                                        clientId: result[i].globalId,
                                        name: `${fixName(result[i])}`, //`${result[i].lastName}, ${result[i].firstName} ${result[i].middleName}`,
                                        birthDate: prettifyDate(result[i].birthDate),
                                        last_name: `${result[i].lastName}`,
                                        first_name: `${result[i].firstName}`,
                                        age: calculateAge(result[i].birthDate),
                                        LOCATION: `${address}`,
                                        email: removeNull(result[i].email),
                                        contactno: removeNull(result[i].phoneNumber),
                                    });

                                    headerOptions =
                                        [
                                            { value: "birthDate", label: "Birth Date Range" },
                                            { value: "branchId", label: "Location/Branch" },
                                            { value: "contractHolderFirstName", label: "Owner First Name" },
                                            { value: "contractHolderLastName", label: "Owner Last Name" },
                                            // { value: "insuredFirstName", label: "Insured First Name" },
                                            // { value: "insuredLastName", label: "Insured Last Name" }
                                        ]
                                }
                            }
                        } else {
                            //   that.errorMessage("No Records Found");
                        }

                        that.setState({
                            clients, headerOptions,
                            loadTable: false,
                            totalSize: totalSize,
                            pages: numOfPages,
                            isLoading: false
                        });
                    } else {
                        that.setState({
                            clients: [],
                            loadTable: false,
                            totalSize: 0,
                            pages: 0,
                            isLoading: false
                        });
                    }
                })
                .catch(function (err) {
                    console.log(err);
                    that.setState({
                        isLoading: false,
                        clients: []
                    }, () => {
                        that.errorMessage(myConfig.GENERIC_ERROR_MESSAGE);
                        console.log(err.message)
                    });
                })
            // let result = null;



        });
    }

    resetError = () => {
        // this.setState({ error: '', isLoading: true })
        this.setState({ error: '', loadTable: true });
    }
    errorMessage = (message) => {
        if (message) {
            this.setState({ error: message })
        }
    }

    showError = () => {
        let message = '';
        if (this.state.error !== '') {
            message =
                <div className="bg-error py-1 px-4">
                    <p className="text-white mb-0 font-neo-semibold">ERROR: {this.state.error}</p>
                </div>
        }
        return message;
    }

    search = (pageNumber) => {

        let that = this;
        let agentNumber = decrypt(that.props.getSession().agentNumber);
        let headerParams = { x_auth: that.props.getSession().access_token };
        let filter = [];
        let continueToApiCall = true;
        filter['pageNumber'] = (pageNumber + 1);
        filter['agentNumber'] = agentNumber;
        filter['pageSize'] = myConfig.PAGE_SIZE;
        filter['accountManagerId'] = agentNumber;

        if (this.state.optionSelected.value !== null) {

            if (this.state.optionSelected.value == "birthDate") {
                let errorMessageDate = "";
                if (this.state.dobStart != null) {
                    filter['birthDateFrom'] = prettifyDate(this.state.dobStart);
                    errorMessageDate = checkDateRange(filter['birthDateTo'], filter['birthDateFrom']);
                }
                if (this.state.dobEnd != null) {
                    filter['birthDateTo'] = prettifyDate(this.state.dobEnd);
                    errorMessageDate = checkDateRange(filter['birthDateTo'], filter['birthDateFrom']);
                }
                console.log("errorMessageDate : " + errorMessageDate);
                if (errorMessageDate !== "") {
                    that.errorMessage(errorMessageDate);
                    continueToApiCall = false;
                }

            } else if (this.state.optionSelected.value === 'branchId') {
                if (this.state.branchValue !== null) {
                    filter[this.state.optionSelected.value] = this.state.branchValue.value;
                    continueToApiCall = checkSearch(this.state.searchValue, that.errorMessage, 2);
                }
            } else if (this.state.searchValue != null && this.state.searchValue != "") {
                filter[this.state.optionSelected.value] = this.state.searchValue;
                continueToApiCall = checkSearch(this.state.searchValue, that.errorMessage, 2);
            }

            if (that.state.sortByField != null) {
                filter['sortBy'] = that.state.sortByField + ',' + that.state.sortOrder
            }

        }
        if (continueToApiCall) {
            that.resetError();
            apiModule("client_all", headerParams, null, createQueryStrings(filter))
                .then(function (response) {

                    if (response.length > 0) {
                        let result = response[0].content;
                        //let result = response.data.content;
                        let totalSize = response[0].totalSize;
                        let numOfPages = parseInt((totalSize / myConfig.PAGE_SIZE), 10);

                        if ((totalSize % myConfig.PAGE_SIZE) > 0) {
                            numOfPages++;
                        }
                        let headerOptions = [];
                        let clients = [];
                        if (result) {


                            for (let i = 0; i < result.length; i++) {

                                let address = "--";
                                if (result[i].addresses !== null && result[i].addresses !== undefined) {
                                    if (result[i].addresses.length > 0) {
                                        address = fixAddress(result[i].addresses[0]);
                                    }
                                }

                                let fa_name = "--";
                                let fa_number = "--";
                                let fa_am_number = "--";

                                if (result[i].accountManager !== null && result[i].accountManager !== undefined) {
                                    if (result[i].accountManager.name !== null && result[i].accountManager.name !== undefined) {
                                        fa_name = result[i].accountManager.name;
                                    }
                                    if (result[i].accountManager.agentNumber !== null && result[i].accountManager.agentNumber !== undefined) {
                                        fa_number = result[i].accountManager.agentNumber;
                                    }
                                    if (result[i].accountManager.agentNumberOfAdvisorOfAccountManager !== null && result[i].accountManager.agentNumberOfAdvisorOfAccountManager !== undefined) {
                                        fa_am_number = result[i].accountManager.agentNumberOfAdvisorOfAccountManager;
                                    }

                                }


                                if (that.state.isMgr && result[i].accountManagers !== undefined && result[i].accountManagers !== null) {
                                    if (result[i].accountManagers.length > 0) {
                                        clients.push({
                                            clientId: result[i].globalId,
                                            name: `${fixName(result[i])}`, //`${result[i].lastName}, ${result[i].firstName} ${result[i].middleName}`,
                                            last_name: `${result[i].lastName}`,
                                            first_name: `${result[i].firstName}`,
                                            age: calculateAge(result[i].birthDate),
                                            birthDate: prettifyDate(result[i].birthDate),
                                            LOCATION: removeNull(address),
                                            email: removeNull(result[i].email),
                                            contactno: removeNull(result[i].phoneNumber),
                                            FANAME: removeNull(result[i].accountManagers[0].name),
                                            fa_number: removeNull(result[i].accountManagers[0].agentNumber),
                                            fa_am_number: removeNull(result[i].accountManagers[0].agentNumberOfAdvisorOfAccountManager),
                                        });
                                    } else {
                                        clients.push({
                                            clientId: result[i].globalId,
                                            name: `${fixName(result[i])}`, //`${result[i].lastName}, ${result[i].firstName} ${result[i].middleName}`,
                                            last_name: `${result[i].lastName}`,
                                            first_name: `${result[i].firstName}`,
                                            age: calculateAge(result[i].birthDate),
                                            birthDate: prettifyDate(result[i].birthDate),
                                            LOCATION: removeNull(address),
                                            email: removeNull(result[i].email),
                                            contactno: removeNull(result[i].phoneNumber),
                                            FANAME: removeNull(null),
                                            fa_number: removeNull(null),
                                            fa_am_number: removeNull(null),
                                        });
                                    }

                                    headerOptions =
                                        [
                                            { value: "birthDate", label: "Birth Date Range" },
                                            { value: "branchId", label: "Location/Branch" },
                                            { value: "contractHolderFirstName", label: "Owner First Name" },
                                            { value: "contractHolderLastName", label: "Owner Last Name" },
                                            // { value: "insuredFirstName", label: "Insured First Name" },
                                            // { value: "insuredLastName", label: "Insured Last Name" },
                                            //  { value: "fa_name", label: "Financial Advisor" }
                                        ];

                                } else {
                                    clients.push({
                                        clientId: result[i].globalId,
                                        name: `${fixName(result[i])}`, //`${result[i].lastName}, ${result[i].firstName} ${result[i].middleName}`,
                                        birthDate: prettifyDate(result[i].birthDate),
                                        LOCATION: `${address}`,
                                        email: removeNull(result[i].email),
                                        contactno: removeNull(result[i].phoneNumber),
                                        last_name: `${result[i].lastName}`,
                                        first_name: `${result[i].firstName}`,
                                        age: calculateAge(result[i].birthDate),
                                        FANAME: removeNull(null),

                                    });

                                    headerOptions =
                                        [
                                            { value: "birthDate", label: "Birth Date Range" },
                                            { value: "branchId", label: "Location/Branch" },
                                            { value: "contractHolderFirstName", label: "Owner First Name" },
                                            { value: "contractHolderLastName", label: "Owner Last Name" },
                                            // { value: "insuredFirstName", label: "Insured First Name" },
                                            // { value: "insuredLastName", label: "Insured Last Name" }

                                        ];
                                }
                            }
                        } else {
                            //  that.errorMessage("No Records Found");
                        }

                        that.setState({
                            clients, headerOptions,
                            loadTable: false,
                            totalSize: totalSize,
                            currentPage: (pageNumber),
                            pageNumber: (pageNumber),
                            pages: numOfPages,
                            isLoading: false
                        });
                    } else {
                        that.setState({
                            clients: [],
                            loadTable: false,
                            totalSize: 0,
                            currentPage: (pageNumber),
                            pageNumber: (pageNumber),
                            pages: 0,
                            isLoading: false
                        });
                    }
                })
                .catch(function (err) {
                    console.log(err);
                    that.setState({
                        isLoading: false,
                        clients: []
                    }, () => {
                        that.errorMessage(myConfig.GENERIC_ERROR_MESSAGE);
                    })
                })
        } else {
            this.setState({
                isLoading: false
            });
        }
        // let result = null;

    }


    searchByOption = () => {
        let field = "";
        if (this.state.optionSelected.value == "birthDate") {
            field = (
                <div className="policy-datepicker__body flex-wrap justify-content-md-start justify-content-between align-items-stretch">
                    <div className="policy-datepicker__field d-flex align-items-center position-relative">
                        <div className="form-control policy-datepicker__container rounded-pill border-0 h-100">
                            <DatePicker
                                placeholderText="Birthdate from" className="policy-datepicker__input text-gray policy-datepicker__input--placeholder"
                                selected={this.state.dobStart}
                                onChange={this.handleStartDobChange}
                                dateFormat="MM-dd"
                                renderCustomHeader={({
                                    date,
                                    changeYear,
                                    decreaseMonth,
                                    increaseMonth,
                                    prevMonthButtonDisabled,
                                    nextMonthButtonDisabled
                                }) => (
                                        <DpCustomHeader
                                            dropdownStyle={{
                                                option: (provided) => ({
                                                    ...provided,
                                                    fontFamily: "neo_bold",
                                                    fontSize: ".65rem",
                                                    color: "#1e4a73",
                                                    backgroundColor: "white"
                                                }),
                                                menu: () => ({
                                                    position: "absolute",
                                                    border: 0,
                                                    zIndex: "1090",
                                                    backgroundColor: "white",
                                                    borderRadius: "5px",
                                                    width: "4rem",
                                                    boxShadow: "2px 2px 2px #DDDEE0"
                                                }),
                                            }}
                                            //CAN ADD MAX YEAR -> Years(minYear,maxYear)
                                            yearOptions={Years(1990)}
                                            date={date}
                                            // changeYear={changeYear}
                                            decreaseMonth={decreaseMonth}
                                            increaseMonth={increaseMonth}
                                            prevMonthButtonDisabled={prevMonthButtonDisabled}
                                            nextMonthButtonDisabled={nextMonthButtonDisabled}
                                        />
                                    )}
                            />
                            <span className="icon-calendar text-gray"></span>
                        </div>
                    </div>
                    <div className="policy-datepicker__field d-flex align-items-center position-relative ml-2">
                        <div className="form-control policy-datepicker__container policy-datepicker__mobile rounded-pill border-0 h-100">
                            <DatePicker
                                placeholderText="Birthdate to" className="policy-datepicker__input text-gray policy-datepicker__input--placeholder" selected={this.state.dobEnd}
                                onChange={this.handleEndDobChange}
                                dateFormat="MM-dd"
                                renderCustomHeader={({
                                    date,
                                    changeYear,
                                    decreaseMonth,
                                    increaseMonth,
                                    prevMonthButtonDisabled,
                                    nextMonthButtonDisabled
                                }) => (
                                        <DpCustomHeader
                                            dropdownStyle={{
                                                option: (provided) => ({
                                                    ...provided,
                                                    fontFamily: "neo_bold",
                                                    fontSize: ".65rem",
                                                    color: "#1e4a73",
                                                    backgroundColor: "white"
                                                }),
                                                menu: () => ({
                                                    position: "absolute",
                                                    border: 0,
                                                    zIndex: "1090",
                                                    backgroundColor: "white",
                                                    borderRadius: "5px",
                                                    width: "4rem",
                                                    boxShadow: "2px 2px 2px #DDDEE0"
                                                }),
                                            }}
                                            //CAN ADD MAX YEAR -> Years(minYear,maxYear)
                                            yearOptions={Years(1990)}
                                            date={date}
                                            // changeYear={changeYear}
                                            decreaseMonth={decreaseMonth}
                                            increaseMonth={increaseMonth}
                                            prevMonthButtonDisabled={prevMonthButtonDisabled}
                                            nextMonthButtonDisabled={nextMonthButtonDisabled}
                                        />
                                    )}
                            />
                            <span className="icon-calendar text-gray"></span>
                        </div>
                    </div>
                </div>
            );
        } else if (this.state.optionSelected.value == "branchId") {
            field = (<Select
                styles={{
                    option: (provided) => ({
                        ...provided,
                        fontFamily: "neo_semibold",
                        fontSize: ".6rem",
                        color: "#9A9B9C",
                        backgroundColor: "white"
                    }),
                    menu: () => ({
                        position: "absolute",
                        border: 0,
                        zIndex: "1090",
                        backgroundColor: "white",
                        borderRadius: "5px",
                        boxShadow: "2px 2px 2px #DDDEE0"
                    }),
                }}
                placeholder="Select..."

                //options
                options={this.state.branchList}
                isSearchable={false}
                className={`datatable-select ml-0 ${this.props.isSm ? "w-100" : ""}`}
                classNamePrefix="datatable-select"
                value={this.state.branchValue}
                onChange={this.handleBranchListChange}
            />)
        }

        return field;
    }
    render() {
        let dataTable = "";
        if (this.state.clients === null || this.state.isLoading === true) {
            return (
                <ReportLoader handleClose={this.hideModal} show={this.state.isLoading} />
            );
        } else if(!this.state.isAllowed) {
            return (
                <div className="dashboard-section overflow-hidden">
                    {this.showError()}
                </div>
            );
        } else {

            if (this.state.clients.length > 0) {
                dataTable = (
                    <Clients
                        history={this.props.history}
                        clients={this.state.clients}
                        loadTable={this.state.loadTable}
                        totalSize={this.state.totalSize}
                        faLabel={this.state.faLabel}
                        isMgr={this.state.isMgr}
                        beginLoading={() => this.beginLoading()}
                        search={(pageNumber) => {
                            this.setState({
                                // isLoading: true
                                loadTable: true,
                            }, () => {
                                this.search(pageNumber);
                            })
                        }}
                        headerClickedHandler={(sortByField) => { this.headerClickedHandler(sortByField) }}
                        rows={this.state.rows}
                        pages={this.state.pages}
                        currentPage={this.state.currentPage}
                        sortByField={this.state.sortByField}
                        sortOrder={this.state.sortOrder}
                        {...this.props} />
                );
            } else {
                dataTable = (
                    <div className="center-align dashboard-row-data font-neo-semibold">No records found </div>
                );
            }
            return (
                <div className="dashboard-section overflow-hidden">
                    {this.showError()}
                    <div className="dashboard-container container-fluid p-3">
                        <div className="dashboard-header">
                            <h6 className="font-neo-bold text-darkgray">List of Clients</h6>
                            {this.props.getViewAs}
                        </div>
                        <div className="dashboard-body">
                            <ClientPolicyNav clientInfo="active" hasNewlyAssignedPolicies={this.state.hasNewlyAssignedPolicies}/>
                            <div className="dashboard-datatable mt-4">
                                <DatatableSearch
                                    //DATE OF BIRTH RANGE
                                    dobStartValue={this.state.dobStart}
                                    dobEndValue={this.state.dobEnd}
                                    updateDobStart={this.handleStartDobChange}
                                    updateDobEnd={this.handleEndDobChange}
                                    //END DATE OF BIRTH RANGE

                                    //DROPDOWN OPTION
                                    optionSelectedValue={this.state.optionSelected}
                                    updateOptionSelected={this.updateOptionSelected}
                                    //END DROPDOWN OPTION

                                    //SEARCH VALUE
                                    searchValue={this.state.searchValue}
                                    updateSearchValue={this.updateSearchValue}
                                    //END SEARCH VALUE
                                    faLabel={this.state.faLabel}
                                    isMgr={this.state.isMgr}
                                    searchByOption={this.searchByOption()}
                                    search={(pageNumber) => {
                                        this.setState({ loadTable: true }, () => {
                                            this.search(pageNumber)
                                        })
                                    }}

                                    placeholder=""
                                    styles={{
                                        option: (provided) => ({
                                            ...provided,
                                            fontFamily: "neo_semibold",
                                            fontSize: ".6rem",
                                            color: "#9A9B9C",
                                            backgroundColor: "white"
                                        }),
                                        menu: () => ({
                                            position: "absolute",
                                            border: 0,
                                            zIndex: "1090",
                                            backgroundColor: "white",
                                            borderRadius: "5px",
                                            boxShadow: "2px 2px 2px #DDDEE0"
                                        }),
                                    }}
                                    options={this.state.headerOptions}
                                />
                                {dataTable}
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
}
export default withRouter(withWindowDimensions(ClientInfo));
