import React from 'react';
import Login from '../components/login';
import { checkLogin } from './security';
import ResetPassword from '../components/resetPassword';
import ChangePassword from '../components/changePassword';
import SidebarContainer from '../components/sidebarContainer';
import Auxi from '../components/auxi';
import Verify from '../components/verify';
import RewardsAndIncentives from '../pages/rni/handler';

import SingleClient from '../pages/singleClient';
import { writeData, readAllData, clearAllData } from './utility.js';
import { BrowserRouter as Router, Link, Route, Redirect } from 'react-router-dom';
import { myConfig } from './config.js';
import { menuConfig } from './menu.js';

import queryString from 'query-string';
import { ConsoleIcon } from 'mdi-react';
import ReactGA from 'react-ga4';
import SplashScreen from '../pages/rni/components/splashScreen';
import Client from '../pages/client';
import FundTransfer from '../pages/fundTransfer';

// var Homes = require('../pages/home');
let componentsToUse = [];
let connectedPages = {
  0: [""],
  1: [],
  3: ["single-client", "single-client-policy"],
  4: ["single-policy",
      "newly-assigned-policies",
  ],
  5: ["single-fa"],
  6: [
    "birthday-list",
    "policies-due",
    "client-list-per-location",
    "policy-list-per-status",
    "matured-and-paid-up-policies",
    "policy-list-per-servicing-fa-with-status",
    "client-list-per-servicing-fa",
    "list-of-fas-per-status",
    "generate-documents",
    "list-of-policies-without-payment-beyond-due-date",
    "production-report",
    "lapse-list",
    "company-persistency"
  ],
  9: ["agent-knowledgebase"],
  31: ["mis"],
};
let keyConnectedPages = Object.keys(connectedPages);

const Routes = (props) => {
  let fakeAuth = {
    isAuthenticated: false,
    authenticate(cb) {
      this.isAuthenticated = true;
      setTimeout(cb, 100); // fake async
    },
    signout(cb) {
      this.isAuthenticated = false;
      setTimeout(cb, 100);
    }
  };

  let storeSession = {
    isAuthenticated: {},
    authenticate(session) {
      this.isAuthenticated = session;
    }
  };

  let getActiveMenu = (label, rawUrl) => {
    let result;
    menuConfig.map((item, i) => {
      if (label.toUpperCase() == item.pageLabel.toUpperCase()) {
        // console.log(menuConfig[i].pageModule + " PAGEMODULE");
        menuConfig[i].isActive = 'active';
        result = item.pageModule;
      } else {
        menuConfig[i].isActive = '';
      }

    });
    keyConnectedPages.map(key => {

      if (connectedPages[key].includes(rawUrl)) {
        // console.log(key + " HELLO IM PRINTING");
        // console.log("LOOKING FOR PAGE MODULE");
        // console.log(menuConfig[key - 1].pageLabel);
        menuConfig[key - 1].isActive = 'active';
      }
    });
    return result;
  }

  let menuRoute = (that) => {
    let rawUrl = that.props.match.params.name;
    let url = rawUrl.toUpperCase().replace(/-/g, ' ');
    let pageModule = getActiveMenu(url, rawUrl);

    if (componentsToUse[pageModule] == null) {
      const ComponentToRender = require("../pages/" + pageModule).default;
      componentsToUse[pageModule] = (<ComponentToRender
        parent={that}
        toggleSidebar={() => props.toggleSidebar()}
        menuRoute={(index) => menuRoute(index)}
        auth={fakeAuth}
        clientId={props.clientId}
        getLoginMessage={() => this.getLoginMessage()}
        setLoginMessage={(item) => this.setLoginMessage(item)}
        setClientOrPolicy={(item) => props.setClientOrPolicy(item)}
        getClientOrPolicy={() => props.getClientOrPolicy()}
        setUserSession={(user) => props.setUserSession(user)}
        setViewAs={(view_as_number, view_as, view_as_email, history, path) => props.setViewAs(view_as_number, view_as, view_as_email, history, path)}
        changePageHandler={(pageId) => props.changePageHandler(pageId)}
        doSearch={(query, pageNumber) => props.doSearch(query, pageNumber)}
        getSearchResults={() => props.getSearchResults()}
        getSearchQuery={() => props.getSearchQuery()}
        getSearchError={() => props.getSearchError()}

        getSearchLoading={() => props.getSearchLoading()}
        getSearchMessage={() => props.getSearchMessage()}

        getSession={() => props.getSession()}
        location={props.location}
        getViewAs={props.getViewAs}
      />);

    }
    //REACTGA
    ReactGA.send({ hitType: "pageview", page: `${window.location.pathname + window.location.search}`});
    return (componentsToUse[pageModule]);
  }

  let RenderPage = () => {
    return (
      <div>
        {/**********************LOGIN**********************/}
        <Route exact path="/" render={() => <Login getLoginMessage={() => props.getLoginMessage()}
          setLoginMessage={(item) => props.setLoginMessage(item)} setViewAs={(view_as_number, view_as, view_as_email, history, path) => props.setViewAs(view_as_number, view_as, view_as_email, history, path)} setUserSession={(user) => props.setUserSession(user)} getSession={() => props.getSession()} />} />
        {/**********************LOGIN**********************/}
        <Route path="/client/:id?" render={() => <Client />} />
        {/**********************FUND TRANSFER AGREEMENT ( FTA )**********************/}
        <Route path="/fta/:id?" render={() => <FundTransfer />} />
        {/**********************VERIFY**********************/}
        <Route exact path="/verify" render={() => <Verify getLoginMessage={() => props.getLoginMessage()}
          setLoginMessage={(item) => props.setLoginMessage(item)} setViewAs={(view_as_number, view_as, view_as_email, history, path) => props.setViewAs(view_as_number, view_as, view_as_email, history, path)} setUserSession={(user) => props.setUserSession(user)} getSession={() => props.getSession()} setRefreshTokenTimer={() => props.setRefreshTokenTimer()} />} />
        {/**********************VERIFY**********************/}
        <Route exact path="/reset_password" render={() => <ResetPassword getLoginMessage={() => props.getLoginMessage()}
          setLoginMessage={(item) => props.setLoginMessage(item)} setViewAs={(view_as_number, view_as, view_as_email, history, path) => props.setViewAs(view_as_number, view_as, view_as_email, history, path)} setUserSession={(user) => props.setUserSession(user)} getSession={() => props.getSession()} />} />
        {/**********************VERIFY**********************/}
        <Route exact path="/change_password" render={() => <ChangePassword getLoginMessage={() => props.getLoginMessage()}
          setLoginMessage={(item) => props.setLoginMessage(item)} setViewAs={(view_as_number, view_as, view_as_email, history, path) => props.setViewAs(view_as_number, view_as, view_as_email, history, path)} setUserSession={(user) => props.setUserSession(user)} getSession={() => props.getSession()} />} />
        {/**********************Rewards and Incentives**********************/}
        <Route exact path="/rewards-and-incentives/" render={() => <SplashScreen />} />
        <Route exact path="/rewards-and-incentives/:name" render={() =>
          <RewardsAndIncentives
            menuRoute={(that) => menuRoute(that)}
            setViewAs={(view_as_number, view_as, view_as_email, history, path) => props.setViewAs(view_as_number, view_as, view_as_email, history, path)}
            setUserSession={(user) => props.setUserSession(user)}
            getSession={() => props.getSession()} />} />
        {/**********************HOME**********************/}
        <Route path="/dashboard/:name" render={() =>
          //(checkLogin())  ?
          (false) ?

            (<Redirect to="/" />) :
            (
              <SidebarContainer
                // toggleSidebar={() => props.toggleSidebar()}
                // sidebar={props.toggleDrawer}
                menuRoute={(that) => menuRoute(that)}
                doSearch={(query, pageNumber) => props.doSearch(query, pageNumber)}
                getSearchResults={() => props.getSearchResults()}
                getSearchQuery={() => props.getSearchQuery()}
                // auth={fakeAuth}
                // clientId={props.clientId}
                setUserSession={(user) => props.setUserSession(user)}
                // changePageHandler={(pageId) => props.changePageHandler(pageId)}
                getSession={() => props.getSession()}
                setViewAs={(view_as_number, view_as, view_as_email, history, path) => props.setViewAs(view_as_number, view_as, view_as_email, history, path)}
              // location={props.location}


              />
            )
        } />
        {/**********************SINGLE CLIENT**********************/}

        {/* <Route path="/dashboard/single" render={() => <Login />} /> */}
      </div>
    )
  }

  let content = null;

  //If there are no token query string. Use paths in RenderPage

  content = (
    <RenderPage />
  );

  return (
    <Auxi>
      <Router>
        <div>
          {content}

        </div>
      </Router>
    </Auxi>
  )

}

export default Routes;
