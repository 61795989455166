import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';

import { apiModule } from '../commons/apiCall';
import { LoaderGif2 } from '../commons/loaderGif';
import ProgressIndicator from '../components/FundTransferAgreement/progressIndicator';
import UserBirthdate from '../components/FundTransferAgreement/userBirthdate';
import ReviewTransaction from '../components/FundTransferAgreement/reviewTransaction';
import ConfirmTransaction from '../components/FundTransferAgreement/confirmTransaction';

class FundTransfer extends Component {

    constructor(props) {
      super(props);
      this.state = {
          id: '',
          token: "",
          transactionId: "",
          baseUrl: '',
          isFtaForm: false,
          userAction: "",
          progressStep: 1,
          contactInfo: {},
          policyInfo: {},
          subheaderText: [
            "Authentication",
            "",
            "Confirmation"
          ],
          clearScreen: false
      };
      
      this.props.match.params.id
        ? this.state.id = this.props.match.params.id
        : this.props.history.push('/');

      this.processTransactionData = this.processTransactionData.bind(this);
      this.setProgress = this.setProgress.bind(this);
      this.handleUserAction = this.handleUserAction.bind(this);
      this.clearScreen = this.clearScreen.bind(this);
      this.isFtaForm = this.isFtaForm.bind(this);
      this.state.baseUrl = this.props.match.url;
      
      this.getFullApiToken();
    }

    componentDidMount() {
      console.log(this.props)
        const { match: { params } } = this.props;
        this.setState({id: params.id});
    }

    setProgress(progressStep) {
      this.setState({ progressStep });
    }

    isFtaForm(isFtaForm) {
      this.setState({isFtaForm});
    }

    async getFullApiToken() {
      const requestParams = {
        tokenAlias: this.state.id
      }
      await apiModule("get_api_token_from_alias", requestParams, null, '', true
      ).then((result) => {
        console.warn("SUCCESS", result)
        if(result.data.isLinkExpired == true){
          this.clearScreen();
        } else {
          this.setState({ token: result["data"]["access_token"] });
        }
      })
      .catch((err) => {
        console.error(err);
      });
      
      this.setState({ isNextDisabled: false });
    }

    async processTransactionData(result) {
      console.warn("PROCESSING DATA", result)
      const policyInfo = {};
      const policies = await this.handleShowPolicies(result.transactionId);
      policyInfo["policyNumber"] = result.contractNumber;
      policyInfo["policyCurrency"] = result.policyCurrency;
      policyInfo["transactionNumber"] = result.transactionId;
      policyInfo["policies"] = policies;
      policyInfo["policyOwner"] = result.ownerName;
      
      this.setState({ policyInfo, transactionId: result.transactionId }, ()=> {console.log(this.state.policyInfo, this.state.transactionId)});
      this.props.history.push(`${this.state.baseUrl}/review-transaction`);

    }

    async handleShowPolicies(transactionId) {
      console.log("handleReviewTransaction has been triggered")
  
      return await apiModule("show_policies", { transactionId }, null, '', true)
        .then((result) => {
          console.log(result.data)
          return result.data;
        })
        .catch((err) => {
          console.error(err);
          return [];
        });
    }

    handleUserAction(type) {
      console.warn("STATE", this.state)
      console.warn(type);
      let userAction = "";
      if (type === "accept") {
        userAction = "Accept";
      } else if (type === "decline") {
        userAction = "Reject";
      } else {
        userAction = "Did Not Initiate";
      }

      this.submitUserApprovalAction(userAction);
    }
    
    async submitUserApprovalAction(remarks) {
      console.warn(this.state);
      const requestParams = {
        x_auth: this.state.token,
        transactionId: this.state.transactionId
      }
      const apiData = {
        remarks
      }
      await apiModule("user_transactions_id", requestParams, apiData, '', true
      ).then((result) => {
        this.setState({ userAction: remarks });
        console.warn("SUCCESS", result)
        this.props.history.push(`${this.state.baseUrl}/confirm-transaction`);
      })
      .catch((err) => {
        console.error(err);
      });
    }

    clearScreen() {
      this.setState({clearScreen: true});
    }

    render() {
      if(this.state.clearScreen) {
        return (
          <section className="client-approval d-flex">
            <aside className="client-approval__img-wrapper">
              <img className="client-approval__img auth__img" src={require('../assets/img/bg-login.jpg')} alt="Authentication background" />
            </aside>
            <main className="client-approval__user-content d-flex justify-content-center align-items-center flex-grow-1">
              <article className="client-approval__content">
                <header className="client-approval__content-header d-flex flex-column align-items-center">
                  <img className="client-approval__logo auth-logo" src={require("../assets/img/logo.png")} alt="Allianz Logo" />
                </header>
                <section className="client-approval__user-area client-approval__user-area--loading">
                  <div className="client-approval__error-message">
                    <span className="client-approval__error-text">Oops! The link has expired. You may contact your agent to request a new link.</span>
                  </div>
                </section>
              </article>
            </main>
          </section>
        );
      }
      else if (this.state.token) {
            return (
              <section className="client-approval d-flex">
                <aside className="client-approval__img-wrapper">
                  <img className="client-approval__img auth__img" src={require('../assets/img/bg-login.jpg')} alt="Authentication background" />
                </aside>
                <main className="client-approval__user-content d-flex justify-content-center align-items-center flex-grow-1">
                  <article className={`client-approval__content ${this.state.isFtaForm ? "client-approval__content--fta-form" : ""}`}>
                    <header className="client-approval__content-header d-flex flex-column align-items-center">
                      <img className="client-approval__logo auth-logo" src={require("../assets/img/logo.png")} alt="Allianz Logo" />
                      <p className="text-center">Fill out your Fund Transfer Agreement form in 3 easy steps.</p>
                      <ProgressIndicator step={this.state.progressStep} />
                      <span className="client-approval__subheader">{this.state.subheaderText[this.state.progressStep - 1]}</span>
                    </header>
                    <section className="client-approval__user-area">
                      <Switch>
                        <Route
                          exact
                          path={this.state.baseUrl + "/review-transaction"}
                          render={() =>
                            <ReviewTransaction
                              policyContactInfo={this.state.contactInfo}
                              isFtaForm={this.isFtaForm}
                              policyInfo={this.state.policyInfo}
                              history={this.props.history}
                              handleClick={this.handleUserAction}
                              setProgress={this.setProgress}
                              baseUrl={this.state.baseUrl}
                            />
                          }
                        />
                        <Route
                          exact
                          path={this.state.baseUrl + "/confirm-transaction"}
                          render={() =>
                            <ConfirmTransaction
                              userAction={this.state.userAction}
                              setProgress={this.setProgress}
                              history={this.props.history}
                            />
                          }
                        />
                        <Route
                          exact
                          path={this.state.baseUrl}
                          render={() =>
                            <UserBirthdate
                              {...this.props}
                              {...this.state}
                              processData={this.processTransactionData}
                              setProgress={this.setProgress}
                              clearScreen={this.clearScreen}
                            />
                          }
                        />
                      </Switch>
                    </section>
                  </article>
                </main>
              </section>
          );
        } else {
            return (
              <section className="client-approval d-flex">
                <aside className="client-approval__img-wrapper">
                  <img className="client-approval__img auth__img" src={require('../assets/img/bg-login.jpg')} alt="Authentication background" />
                </aside>
                <main className="client-approval__user-content d-flex justify-content-center align-items-center flex-grow-1">
                  <article className="client-approval__content">
                    <header className="client-approval__content-header d-flex flex-column align-items-center">
                      <img className="client-approval__logo auth-logo" src={require("../assets/img/logo.png")} alt="Allianz Logo" />
                      <p className="text-center">Fill out your Fund Transfer Agreement form in 3 easy steps.</p>
                    </header>
                    <section className="client-approval__user-area client-approval__user-area--loading">
                      <div className="client-approval__loader"><LoaderGif2 /></div>
                      <span className="client-approval__loader-text">Loading...</span>
                    </section>
                  </article>
                </main>
              </section>
          );
        }
    }
}
export default withRouter(FundTransfer);
