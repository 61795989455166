import React, { Component, Fragment } from 'react';
import { isInternalUser } from '../../commons/security';
import { AgentKnowledgeBaseFiles, BancaFiles } from '../../commons/agentKnowledgeBaseFiles';
import TextInput from './textInput';

export class DownloadableFiles extends Component {
    constructor(props) {
        super(props);
        this.state = {
            roles: props.roles,
            role: props.role,
            files: [],
            folders: [],
            searchTerm: "",
            saveFilesState: true,
            savedFiles: {
                files: [],
                folders: [],
            }
        }
        this.sortedFiles = this.sortedFiles.bind(this);
        this.getFiles = this.getFiles.bind(this);
        this.getFolder = this.getFolder.bind(this);
        this.back = this.back.bind(this);
        this.searchFiles = this.searchFiles.bind(this);
        this.setSearchTerm = this.setSearchTerm.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
    }

    setActivePdf = (file) => {
        this.props.setActivePdf(file);
    }

    componentDidMount() {
        const files = this.sortedFiles();
        this.setState({
            files
        })
    }

    componentWillUpdate(nextProps, nextState) {
        if (nextProps.role !== this.state.role) {
            const files = this.sortedFiles();
            this.setState({
                role: nextProps.role,
                files,
                folders: [],
                searchTerm: "",
            })
        }
    }

    sortedFiles = () => {
        let { role } = this.props;
        role = role.toLowerCase();
        let files = [];
        console.log("current role", role);
        if (['banca', 'pnb'].includes(role)) {
            return BancaFiles;
        } else if (!isInternalUser(role)) {
            return AgentKnowledgeBaseFiles;
        }
    }

    setSearchTerm = (e) => {
        this.setState({ searchTerm: e.target.value })
        setTimeout(() => this.handleSearch(), 500);
    }

    handleSearch = () => {
        if(this.state.saveFilesState) {
            this.saveFilesState();
        }
        const { searchTerm } = this.state;
        if (searchTerm) {
            this.setState({
                folders: [],
                files: this.searchFiles(),
                saveFilesState: false,
            })
        }
        else {
            this.setState({
                folders: this.state.savedFiles.folders,
                files: this.state.savedFiles.files,
                saveFilesState: true,
            })
        }
    }

    searchFiles = () => {
        const files = { ...this.sortedFiles() };
        const { searchTerm } = this.state;
        let searchedFiles = this.deepSearch(searchTerm, Object.values(files));
        searchedFiles.sort((a, b) => {
            const aFolderName = a.folderName || "";
            const bFolderName = b.folderName || "";
            return bFolderName.localeCompare(aFolderName);
        });
        return searchedFiles ? searchedFiles : this.sortedFiles();
    }

    saveFilesState = () => {
        this.setState({
            savedFiles: {
                files: [...this.state.files],
                folders: [...this.state.folders],
            }
        })
    }

    deepSearch = (searchTerm = "", files = []) => {
        let searchedFiles = [];
        for (let i = 0; i < files.length; i++) {
            const itemName = files[i].folderName || files[i].fileName || "";
            const filesList = files[i].files || [];

            if (itemName && itemName.toLowerCase().includes(searchTerm.toLowerCase())) {
                searchedFiles.push(files[i]);
            }

            if (filesList) {
                const foundFiles = this.deepSearch(searchTerm, filesList);
                searchedFiles = [...searchedFiles, ...foundFiles];
            }
        }

        return searchedFiles;
    }

    getFiles = (folderList) => {
        const { searchTerm } = this.state;
        this.setState({
            folders: folderList
        });

        let files = searchTerm ? this.searchFiles() : this.sortedFiles();

        folderList.map(folder => {
            files = files.filter(file => file.folderName && file.folderName === folder);
            files = files[0].files;
        });

        this.setState({
            files
        })
    }

    getFolder = (folder) => {
        if (folder) {
            const folderList = [...this.state.folders, folder];
            this.getFiles(folderList);
            this.props.setFolderList(folderList);
        }
    }

    back = () => {
        const folderList = this.state.folders;
        folderList.pop();
        this.props.setFolderList(folderList);

        if (this.state.folders.length > 0) {
            this.getFiles(folderList);
        }
        else {
            const { searchTerm } = this.state;
            this.setState({
                folders: folderList
            });
            const files = this.sortedFiles();
            this.setState({
                files: searchTerm != "" || searchTerm == undefined ? this.searchFiles() : files
            })
        }
    }

    generateDownloadableContainers = (filesArray) => {
        return filesArray.map(
            (file, key) => {
                let icon = "pdf-icon.svg";
                const fileUrl = file.fileUrl || file.alternateFileDownload;
                const fileType = fileUrl ? fileUrl.match(/\.[0-9a-z]+$/i)[0] : '';
                let isViewAble = true; // cpph-2525
                if(file.fileIsViewAble == false){
                    isViewAble = file.fileIsViewAble;
                }
                switch (fileType) {
                    case ".xlsx":
                    case ".xlsm":
                    case ".xls":
                        icon = "excel.svg";
                        break;
                    case ".pdf":
                        icon = "pdf-icon.svg";
                        break;
                    case ".docx":
                        icon = "word.svg";
                        break;
                    default:
                        icon = "folder.svg";

                }
                const row = (
                    <section className={"agent-knowledgebase__downloadable-file " + (file.folderName ? "folder" : "")} key={key} onClick={() => this.getFolder(file.folderName)}>
                        <div className="agent-knowledgebase__content-info">
                            <img src={`/img/icons/${icon}`} className="agent-knowledgebase__icon--pdf" />
                            <span className={(!file.fileDate ? "name-only" : "")}>{file.fileName ? file.fileName : file.folderName}</span>
                        </div>
                        <span className="agent-knowledgebase__content-info agent-knowledgebase__content-info--date">{file.fileDate ? file.fileDate : ''}</span>
                        <div className="agent-knowledgebase__content-info agent-knowledgebase__content-info--buttons">
                            {!['.xlsx', '.xls', '.xlsm'].includes(fileType) && fileType && isViewAble &&
                                <button
                                    onClick={() => this.setActivePdf(file)}
                                    className="agent-knowledgebase__button agent-knowledgebase__button-container"
                                >
                                    View
                                </button>
                            }
                            {fileType &&
                                <a
                                    href={file.alternateFileDownload ? file.alternateFileDownload : file.fileUrl}
                                    download
                                    className="agent-knowledgebase__button-container d-flex"
                                >
                                    <button className="agent-knowledgebase__button">
                                        <span className="agent-knowledgebase__button-icon icon-arrow-down"></span>
                                        Download
                                    </button>
                                </a>
                            }

                        </div>
                    </section>
                )
                if (!file.roles || (file.roles && this.state.roles.some(role => file.roles.includes(role)))) {
                    return row;
                } else {
                    return null;
                }
            });
    }

    render() {
        return (
            <article className="agent-knowledgebase__downloadable-files flex-column">
                <TextInput
                    value={this.state.searchTerm}
                    handleSearch={this.setSearchTerm} />
                {this.sortedFiles().length > 0 &&
                    <Fragment>
                        <header className="agent-knowledgebase__downloadable-files-header justify-content-around font-neo-semibold">
                            <span>File</span>
                            <span>Date</span>
                            <span>Actions</span>
                        </header>
                        {this.generateDownloadableContainers(this.state.files)}
                    </Fragment>
                }
                {
                    this.state.folders.length > 0 &&
                    <div className="agent-knowledgebase__back">
                        <button className="agent-knowledgebase__button" onClick={() => this.back()}>Back</button>
                    </div>
                }
                {
                    this.state.files.length == 0 &&
                    <div className="center-align dashboard-row-data font-neo-semibold">No records found </div>
                }

            </article>
        )
    }
}
