import React, {Component} from "react"
import withWindowDimensions from '../../commons/withWindowDimensions';
import '../../css/misMonthPicker.scss';
import MonthList from './monthList.js';

class MisMonthPicker extends Component{
    constructor(props, context) {
        super(props, context)
    }

    componentWillReceiveProps(nextProps){
        this.setState({
            value: nextProps.value
        })
    }

    render() {
        let {year, month, maxMonth, onChangeMonth} = this.props;
        return (
            <div className="ml-1 list-area">
                <MonthList 
                    year={year}
                    month={month}
                    maxMonth={maxMonth}
                    onChangeMonth={onChangeMonth}/>
            </div>
        )
    }
}

export default withWindowDimensions(MisMonthPicker);