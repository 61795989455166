import React, { Fragment } from 'react';
import TableLoader from '../TableLoader/tableLoader';
import ReactTable from 'react-table';
import { myConfig } from '../../commons/config.js';
import withWindowDimensions from '../../commons/withWindowDimensions';
import TransactionsMobile from '../AgentTransactionList/Mobile/TransactionsMobile';
import TablePagination from '../tablePagination';
import { Link } from 'react-router-dom';
import { toLower } from 'lodash';

const Transactions = (props) => {
    const { data, pages, totalSize, isTableLoading, handleStateChange, isMd } = props;
    let { currentPage } = props;
    currentPage = currentPage - 1;
    const columns = [
        {
            Header: "Actions",
            id: "actions",
            accessor: (data) => {
                let icon = [];
                if (data.verificationType === "SMS") {
                    icon = [...icon, <span key={`${data.transNum}-mobile-icon`} className="trans-icon"><i className="far fa-comment-alt"></i></span>]
                }
                else if (data.verificationType === "EMAIL") {
                    icon = [...icon, <span key={`${data.transNum}-email-icon`} className="trans-icon"><i className="fas fa-envelope"></i></span>]
                }
                return icon;
            },
        },
        {
            Header: "Status",
            id: "status",
            accessor: (data) => {
                return <div className={`trans-status__${toLower(data.status) === "waiting for approval" ? "waiting" : toLower(data.status)}`}>{data.status}</div>;
            },
        },
        {
            Header: "Transaction Number",
            accessor: "transNum"
        },
        {
            Header: "Transaction Date and Time",
            accessor: "transDateTime"
        },
        {
            Header: "Policy Number",
            accessor: "policyNum",
        },
        {
            Header: "Policy Owner Name",
            accessor: "ownerName",
        },
        {
            Header: "Policy Insured Name",
            accessor: "insuredName",
        },
        {
            Header: "Transaction Type",
            accessor: "transType",
        },
        {
            Header: "Apply to All?",
            accessor: "apply",
        },
        {
            Header: "Remarks",
            id: "remarks",
            accessor: (data) => {
                let remarks;
                if (data.remarks.length > 0) {
                    remarks = data.remarks.map((remark, idx) => <div key={`remarks-${idx}`} className="mb-2">{remark}</div>);
                } else {
                    remarks = "N/A"
                }
                return remarks;
            },
        },
        {
            Header: "Created By",
            accessor: "createdBy",
        }
    ]
    let view = (
        <ReactTable
            columns={columns}
            data={data}
            loading={isTableLoading}
            minRows={1}
            LoadingComponent={TableLoader}
            defaultPageSize={myConfig.PAGE_SIZE}
            pages={pages}
            manual
            page={currentPage}
            totalSize={totalSize}
            onPageChange={(page) => handleStateChange("changePage", page)}
        />
    );

    if (isMd) {
        view = (
            <TransactionsMobile
                title={[
                    {
                        Header: "Actions",
                        accessor: "actions"
                    },
                    {
                        Header: "Status",
                        accessor: "status"
                    },
                    {
                        Header: 'Policy Number',
                        accessor: 'policyNum',
                    },
                    {
                        Header: 'Policy Owner Name',
                        accessor: 'ownerName',
                    },
                ]
                }
                columns={columns}
                data={data}
                isTableLoading={isTableLoading}
            >
                {!props.isTableLoading ? (
                    <TablePagination
                        css="mt-2"
                        data={data}
                        page={currentPage}
                        totalSize={totalSize}
                        onPageChange={(page) => handleStateChange("changePage", page)}
                        pageSize={myConfig.PAGE_SIZE}
                        pages={pages}
                        isLoading={isTableLoading}
                    />
                ) : ""}
            </TransactionsMobile>
        )
    }

    if (data.length === 0 && !isTableLoading) {
        view = (
            <div className="center-align dashboard-row-data font-neo-semibold mt-4">No records found </div>
        )
    }

    return (
        <Fragment>
            {view}
        </Fragment>
    )
}

export default withWindowDimensions(Transactions);
