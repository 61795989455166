import React, { Component, useEffect, useState } from 'react';
import withWindowDimensions from '../../commons/withWindowDimensions';
import Auxi from "../auxi";
import LoaderGif from '../../commons/loaderGif';
import { fixName } from '../../commons/utility';
import { TranslateIcon } from 'mdi-react';
import { Table } from 'antd';

class Modal extends Component {

    constructor(props) {
        super(props);
        this.setState({
            show: this.props.showModal,
        });
    }

    componentDidMount() {
        this.setState({
            isModalLoading: this.props.isModalLoading,
        });
    }

    fixData(data) {
        const newData = [...data];
        if (newData.length > 0) {
            return newData.map((item) => {
                let policyOwner = "";
                let insured = [];
                if (item.contractLink) {
                    if (item.contractLink.contractHolder) {
                        const contractHolder = item.contractLink.contractHolder;
                        policyOwner = fixName(contractHolder);
                    }
                    if (Array.isArray(item.contractLink.insured) && item.contractLink.insured.length > 0) {
                        insured = item.contractLink.insured.map(insuredItem => fixName(insuredItem));
                    }
                }
                return { ...item, policyOwner, insured };
            });
        }
        return newData;
    }

    render() {
        const showModal = this.props.showModal;
        const data = this.fixData(this.props.data);
        const showHideClass = showModal? "": "none";
        const applicationNumber = this.props.applicationNumber || "";
        const inlineStyle = {
            display: showHideClass,
            maxHeight: "95vh",
        };
        const customHeadingStyle = {
            verticalAlign: "bottom",
            fontSize: ".8rem"
        };
        const applicationNumberStyle = {
            fontSize: "14px",
            marginLeft: "2px"
        }
        const closeContainerStyle = {
            transition: "0.2s linear",
            float: "right"
        }

        let rows = "";
        let modal = "";
        let modalLoading = this.props.isModalLoading;

        console.log("MODAL");
        console.log([showModal, this.props.data, showHideClass]);
        console.log("fixedData: ", data);

        if(this.props.isSm) {
            // MOBILE VIEW
            modal = (
                <div className={`announcement-body`} style={inlineStyle}>
                    <div className="announcement-box mt-n5">
                        <span style={applicationNumberStyle}><strong>Application Number</strong>: {applicationNumber}</span>
                        <div style={closeContainerStyle}>
                            <h5 className="announcement-title"></h5>
                            <img className="announcement-close-icon" src="/announcement/close.png" alt="close-icon" onClick={this.props.handleClose} />
                        </div>
                        <div className="announcement-content" style={{ maxHeight: 'calc(100vh - 300px)' }}>
                            <div style={{ overflow: 'auto' }}>
                            {this.props.isModalLoading? (
                                <div style={{ minWidth: "10em", minHeight: "10em" }}>
                                    <LoaderGif css="mt-4 mb-4" />
                                </div>
                            ) : (
                                <div className="data-accord row flex-wrap mt-4" >
                                    <div className="data-accord__header">
                                        <div className="col-6">
                                            <div className="d-inline-flex align-items-center cs-pointer" style={{ fontSize: "1em" }}>
                                                For
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="d-inline-flex align-items-center cs-pointer" style={{ fontSize: "1em" }}>
                                                Status
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 data-accord__body mt-1">
                                        <div className="data-accord__collapse" id="data-accord">
                                        {
                                            data.map((item, index) => {
                                                return (
                                                    <Auxi key={index}>
                                                        <div className={`data-accord__card ${index > 0 ? "mt-3" : ""} card border-0`}>
                                                            <div className="data-accord__toggle btn" data-toggle="collapse" data-target={`#collapse-${index}`}>
                                                                <div className="row data-accord__start">
                                                                    <div className="col-6">
                                                                        <button className="data-accord__name data-accord__name--clear">{item.responsibleUnit? item.responsibleUnit: "--"}</button>
                                                                    </div>
                                                                    <div className="col-6 d-flex justify-content-between">
                                                                        <div className="font-neo-semibold">{item.worklistStatus? item.worklistStatus: "--"}</div>
                                                                        <button className="btn p-0" type="button">
                                                                            <div className="data-accord__caret">
                                                                                <div className="data-accord__caret-icon"></div>
                                                                            </div>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div id={`collapse-${index}`} className="collapse" data-parent="#data-accord">
                                                                <div className="card-body data-accord__content">
                                                                    <div className="row">
                                                                        <div className="data-accord__column col-11">
                                                                            <div className="data-accord__label">Policy Owner Name</div>
                                                                            <div className="data-accord__value">{item.policyOwner ? item.policyOwner : "--"}</div>
                                                                        </div>
                                                                        <div className="data-accord__column col-11">
                                                                            <div className="data-accord__label">Insured Name</div>
                                                                            <div className="data-accord__value">{(item.insured && item.insured.length > 0) ? item.insured.join("\n") : "--"}</div>
                                                                        </div>
                                                                        <div className="data-accord__column col-11">
                                                                            <div className="data-accord__label">Pending Requirements</div>
                                                                            <div className="data-accord__value">{item.jobType? item.jobType: "--"}</div>
                                                                        </div>
                                                                        <div className="data-accord__column col-1">
                                                                        </div>
                                                                        <div className="data-accord__column col-11">
                                                                            <div className="data-accord__label">Remarks</div>
                                                                            <div className="data-accord__value">{item.unitComment? item.unitComment: "--"}</div>
                                                                        </div>
                                                                        <div className="data-accord__column col-1">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Auxi >
                                                );
                                            })
                                        }
                                        </div>
                                    </div>
                                </div>
                            )
                            }
                        </div>
                    </div>
                    </div>
                </div>
            );
        } else {
            modal = (
                // DESKTOP VIEW
                <div className={`announcement-body`} style={inlineStyle}>
                    <div className="announcement-box announcement-box--max-width-70p mt-n5">
                        <span style={applicationNumberStyle}><strong>Application Number</strong>: {applicationNumber}</span>
                        <div style={closeContainerStyle}>
                            <h5 className="announcement-title"></h5>
                            <img className="announcement-close-icon" src="/announcement/close.png" alt="close-icon" onClick={this.props.handleClose} />
                        </div>
                        <div className="announcement-content">
                            <div style={{ overflow: 'auto' }}>
                                {this.props.isModalLoading? (
                                    <div style={{ minWidth: "10em", minHeight: "10em" }}>
                                        <LoaderGif css="mt-4 mb-4" />
                                    </div>
                                ):(
                                    <table className="table-striped" style={{ width: "100%" }}>
                                        <tbody>
                                            <tr className="" style={{ verticalAlign: "bottom", fontSize: ".8rem" }}>
                                                <th className="p-3" scope="col" style={{ textAlign: 'left' }}>Policy Owner Name</th>
                                                <th className="p-3" scope="col">Insured Name</th>
                                                <th className="p-3" scope="col">For</th>
                                                <th className="p-3" scope="col">Pending Requirements</th>
                                                <th className="p-3" scope="col">Requirements Status</th>
                                                <th className="p-3" scope="col">Remark</th>
                                            </tr>
                                            {
                                                data.map((item, index) => {
                                                    return (
                                                        <tr key={index} style={{ fontSize: ".9em" }}>
                                                            <td className="p-3 text-left">{item.policyOwner ? item.policyOwner : "--"}</td>
                                                            <td className="p-3 text-left">{(item.insured && item.insured.length > 0) ? item.insured.join("\n") : "--"}</td>
                                                            <td className="p-3 text-left">{item.responsibleUnit? item.responsibleUnit: "--"}</td>
                                                            <td className="p-3 text-left">{item.jobType? item.jobType: "--"}</td>
                                                            <td className="p-3 text-left">{item.worklistStatus? item.worklistStatus: "--"}</td>
                                                            <td className="p-3 text-left">{item.unitComment? item.unitComment: "--"}</td>
                                                        </tr>
                                                    );
                                                })
                                            }
                                        </tbody>
                                    </table>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return(
            modal
        );
    }

}
export default withWindowDimensions(Modal);
