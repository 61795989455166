import React, { Component } from 'react'
import Chart from 'chart.js';
import { toAbbreviatedNumber, formatValues, thousandSeparator, roundNumber} from './formatter';
import { haveValue, labelXAxis } from '../../commons/misConfig';

class HorizontalBarChart extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeChart: ''
        }
        this.renderChart.bind(this);
        this.chartWidth = 0;
    }

    componentDidMount() {
        this.renderChart();
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.willUpdate) {
            this.renderChart(nextProps);
        }
    }

    renderChart(nextProps){
        let { data, chartId, targetValue, clickChart} = this.props;
        let { activeChart } = this.state;
        data = nextProps? nextProps.data: data;

        if(document.getElementById(chartId)) {
            if(activeChart) {
                activeChart.destroy();
                this.setState({
                    activeChart: null
                })
            }
        }

        let maxData = 0;

        if(data.datasets){
            if(data.datasets[0].data){
                maxData = data.datasets[0].data[0]
            }
        }

        let ctx = document.getElementById(chartId).getContext('2d');
        let dataChart = new Chart(ctx, {
            type: 'horizontalBar',
            data: data,
            plugins: {
                beforeDraw: function(chartInstance){
                    let ctx = chartInstance.ctx;
                
                    ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontSize, 'normal', Chart.defaults.global.defaultFontFamily);
                    // ctx.textAlign = 'center';
                    ctx.textBaseline = 'bottom';

                    chartInstance.data.datasets.forEach(function (dataset, i) {
                        var meta = chartInstance.controller.getDatasetMeta(i);
                        meta.data.forEach((bar, index) => {
                            var data = dataset.dataPct[index];
                            ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontSize, Chart.defaults.global.defaultFontStyle, 'neo_regular');
                            ctx.fillStyle = '#414141';
                            ctx.fillText(data + "%", bar._model.x + 10, bar._model.y + 4);
                        });
                    });
                },
                
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    display: false
                },
                scales: {
                    gridLines: {
                        display: false
                    },
                    xAxes: [{
                        ticks:{
                            beginAtZero: true,
                            fontColor: "#414141",
                            fontFamily: 'neo_bold',
                            max: maxData + (maxData * 0.4)
                        },
                        display: false
                    }],
                    yAxes: [{
                        gridLines: {
                            display: false,
                            drawBorder: true
                        },
                        ticks:{
                            fontColor: "#414141",
                            fontFamily: 'neo_bold'
                        },
                        barPercentage: 0.8,
                        barThickness: 40
                    }]
                },
                tooltips: {
                    enabled: true,
                    mode: 'index', 
                    intersect: false,
                    bodyFontFamily: 'neo_regular',
                    footerFontFamily: 'neo_regular',
                    titleFontFamily: 'neo_regular',
                    displayColors: false,
                    callbacks: {
                        label: function (toolTipItem, data) {
                            return data.datasets[toolTipItem.datasetIndex].dataName[toolTipItem.index];
                        },
                        afterBody: function(toolTipItem, data) {
                            let afterBodyText = "";
                            let amountAllocated = data.datasets[toolTipItem[0].datasetIndex].data[toolTipItem[0].index];
                            let percentAllocated = data.datasets[toolTipItem[0].datasetIndex].dataPct[toolTipItem[0].index];

                            if(isFinite(amountAllocated)){
                                afterBodyText += "Amount Allocated: " + thousandSeparator(roundNumber(amountAllocated, 0));
                            }

                            if(isFinite(percentAllocated)){
                                afterBodyText += "\n% of Total Funds Allocated: " + percentAllocated + '%';
                            }

                            return afterBodyText;
                        }
                    }
                },
            }
        });
    }

    render() {
        const { chartId, data } = this.props;
        return (
            <div id={chartId+"-canvas"} className={chartId+"-canvas" + " chartWrapper"} style={{position: 'relative', overflow: data.labels.length > 7 ? 'auto': 'unset'}}>
                <div className="chartAreaWrapper" style={{height:  data.labels.length > 7 ? (60 *  data.labels.length) + 'px' :  '100%', margin: 'auto 0'}}>
                    {haveValue(data.labels)? '': <p className="mis-no-data-show">NO DATA TO SHOW</p>}
                    <canvas id={chartId} style={{display : haveValue(data.labels) ? 'block' : 'none'}}/>
                </div>
            </div>
        )
    }
}

export default HorizontalBarChart