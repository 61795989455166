
import React, { Component } from 'react';
import ReactTable from 'react-table';
import { ReportLoader } from '../commons/utilityViews.js';
import { withRouter, Link } from 'react-router-dom';
import ReportDatePicker from "../components/reportDatepicker";
import { Years } from '../components/Datepicker/customHeader';
import Auxi from '../components/auxi';
import { apiModule, createQueryStrings } from '../commons/apiCall';
import { myConfig } from '../commons/config.js';
import { checkDateRange, prettifyDate, removeNull, numberWithCommas, calculateAge, writeData, readAllData, encrypt, decrypt, fixName } from '../commons/utility.js';
import TableLoader from '../components/TableLoader/tableLoader';
import withWindowDimensions from '../commons/withWindowDimensions';
import ReportsMobile from '../components/Reports/Mobile/ReportsMobile';
import TablePagination from '../components/tablePagination';
import handleSortOrder from '../components/misc/sortOrder';
import routeChange from '../commons/routeChange';
import moment from 'moment';

//default dates
// var toDate = new Date();
// toDate.setDate(new Date().getDate() + 30);
// var formattedCurrentDate = new Date().getFullYear() + "-" + (new Date().getMonth() - 1) + "-" + new Date().getDate();
// var formattedToDate = toDate.getFullYear() + "-" + (toDate.getMonth() + 1) + "-" + toDate.getDate();

/**
 * changed to Moment for better handling, and cleaner code
 */
var formattedCurrentDate = moment(new Date()).subtract(60, "days");
var formattedToDate = moment(new Date()).add(30, "days");

class PolicyDueReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            loadTable: false,
            error: '',
            startDate: Date.parse(formattedCurrentDate),
            endDate: Date.parse(formattedToDate),
            showTable: false,
            currentPage: 0,
            reports: [],
            sortByField: null,
            sortOrder: null
        };
        this.pageHeader = "Policy Due List"
    }

    componentDidMount() {
        this.setState({
            isLoading: true
        }, () => {
            this.getTableData();
        });
    }
    resetMessage = () => {
        this.setState({ error: '' })

    }
    showTable = () => {
        if (this.state.startDate && this.state.endDate) {
            this.setState({
                // isLoading: true
                loadTable: true,
            }, () => {
                this.getTableData();
            });
        }
    }

    async getTableData() {
        await this.fetchPolicyList(this.props.getSession().access_token, decrypt(this.props.getSession().agentNumber));
    }

    //SHOW ERROR IF ERROR STATE IS NOT NULL
    showError = () => {
        let message = '';
        if (this.state.error !== '') {
            message =
                <div className="bg-error py-1 px-4">
                    <p className="text-white mb-0 font-neo-semibold">ERROR: {this.state.error}</p>
                </div>
        }
        return message;
    }

    updateStartDate = (date) => {
        this.setState({
            startDate: date,
        }, () => {
            if (this.state.endDate != null) {
                this.setState({ currentPage: 0 });
                this.showTable();
            }
        });
    }

    updateEndDate = (date) => {
        this.setState({
            endDate: date,
        }, () => {
            if (this.state.startDate != null) {
                this.setState({ currentPage: 0 });
                this.showTable();
            }
        });
    }

    fetchPolicyList = (access_token, agentNumber) => {
        let that = this;
        return new Promise(function (resolve, reject) {

            let headerParams = { x_auth: access_token };
            let filter = [];
            let continueProcess = true;
            filter['pageNumber'] = (that.state.currentPage + 1);
            filter['agentNumber'] = agentNumber;
            filter['reportCode'] = 'RPT_POL_DUE'
            filter['pageSize'] = myConfig.PAGE_SIZE;

            if (that.state.startDate != null && !Number.isNaN(that.state.startDate)) {
                filter['dueDateFrom'] = prettifyDate(that.state.startDate);
                continueProcess = (checkDateRange(filter['dueDateTo'], filter['dueDateFrom']) === "") ? true : false;

            } else if(Number.isNaN(that.state.startDate)) {

                filter['dueDateFrom'] = formattedCurrentDate;
                continueProcess = (checkDateRange(filter['dueDateTo'], filter['dueDateFrom']) === "") ? true : false;
            } else {
                filter['dueDateFrom'] = formattedCurrentDate;
            }

            if (that.state.endDate != null && !Number.isNaN(that.state.endDate)) {
                filter['dueDateTo'] = prettifyDate(that.state.endDate);
                continueProcess = (checkDateRange(filter['dueDateTo'], filter['dueDateFrom']) === "") ? true : false;

            } else if(Number.isNaN(that.state.endDate)) {

                filter['dueDateTo'] = formattedToDate;
                continueProcess = (checkDateRange(filter['dueDateTo'], filter['dueDateFrom']) === "") ? true : false;
            } else {
                filter['dueDateTo'] = formattedToDate;
            }

            if (that.state.sortByField != null) {
                filter['sortBy'] = that.state.sortByField + ',' + that.state.sortOrder
            }

            if (continueProcess) {
                that.resetMessage();
                apiModule("reports", headerParams, null, createQueryStrings(filter))
                    .then(function (response) {

                        let result = [];
                        let totalSize = 0;

                        if (response != null) {
                            if (Array.isArray(response)) {
                                if (response.length > 0) {
                                    if (response.length > 0) {
                                        if (response.length > 0) {
                                            result = response[0].content;
                                            totalSize = response[0].totalSize;
                                        } else {
                                            result = [];
                                            totalSize = 0;
                                        }
                                    } else {
                                        result = [];
                                        totalSize = 0;
                                    }
                                } else {
                                    result = [];
                                    totalSize = 0;
                                }
                            } else {
                                result = response.content;
                                totalSize = response.totalSize;
                            }
                        }


                        let numOfPages = parseInt((totalSize / myConfig.PAGE_SIZE), 10);

                        if ((totalSize % myConfig.PAGE_SIZE) > 0) {
                            numOfPages++;
                        }

                        let reports = [];

                        if (result) {

                            for (let i = 0; i < result.length; i++) {

                                // contractNumber: "103000001",
                                // insured: "Crasco, James",
                                // productOfferingName: "Optimum Gold",
                                // inceptionDate: "11/15/2018",
                                // currency: "Php",
                                // contractBalance: "10,000",
                                // endDateTime: "11/15/2018",
                                // POL_STAT: "Lapsed"

                                reports.push(
                                    {
                                        POL_NO: removeNull(result[i].contractNumber),
                                        I_COMP_NAME: removeNull(fixName(result[i].contractHolder)),
                                        POL_DS: removeNull(result[i].productOfferingName),
                                        BUNDLE_ID: removeNull(result[i].typeOfBill),
                                        PMODE_CD: removeNull(result[i].paymentFrequency),
                                        EFF_DT: removeNull(prettifyDate(result[i].inceptionDate)),
                                        DUE_DT: prettifyDate(result[i].paidUntilDate),
                                        POL_CURR_CD: removeNull(result[i].currency),
                                        FACE_AMT: removeNull(numberWithCommas(result[i].contractBalance)),
                                        EXPIRY_DT: removeNull(prettifyDate(result[i].contractInterval.endDateTime)),
                                        DUE_DATE: removeNull(prettifyDate(result[i].paidUntilDate)),
                                        STATUS: removeNull(result[i].status),
                                    }
                                );
                            }

                            that.setState({
                                reports: reports
                            });

                        } else {
                            this.setState({
                                error: "No Account Found"
                            }, () => {
                                that.showError();
                            });

                        }

                        that.setState({
                            reports: reports,
                            loadTable: false,
                            totalSize: totalSize,
                            pages: numOfPages,
                            showTable: true,
                            error: '',
                            isLoading: false
                        });
                    })
                    .catch(function (err) {
                        console.log(err);
                        that.setState({
                            isLoading: false,
                            error: myConfig.GENERIC_ERROR_MESSAGE
                        }, () => {
                            that.showError();
                            console.log(err.message)
                        });
                    })
            } else {
                that.setState({
                    isLoading: false,
                    error: checkDateRange(filter['dueDateTo'], filter['dueDateFrom'])
                }, () => {
                    that.showError();
                });

            }
            // let result = null;



        });
    }

    handleSortAction = (sortByField, sortOrder) => {
        this.setState({
            sortByField: sortByField,
            sortOrder: sortOrder,
            // isLoading: true
            loadTable: true,
        }, () => {
            this.getTableData();
        })
    }
    headerClickedHandler = (sortByField) => {
        let sortOrder = handleSortOrder({ sortByField: sortByField, state: this.state });
        this.handleSortAction(sortByField, sortOrder)
    }

    onClickAction = (item) => {
        this.props.setClientOrPolicy({ policyId: item });
        writeData('policy-client', { policyId: item, id: 1 });
        routeChange(this.props.history, "/dashboard/single-policy");
    }

    changePage = (pageNumber) => {
        let that = this;
        if (this.state.currentPage != pageNumber) {
            this.setState({
                currentPage: pageNumber,
                // isLoading: true,
                loadTable: true,
            }, () => {
                this.getTableData();
                // await that.fetchBirthdayList(that.props.getSession().access_token, decrypt(that.props.getSession().agentNumber));
            }
            );
        }
    }
    /**
     * this function accepts an object and check for DUE_DATE attribute
     * then will determine if the DUE_DATE is already past due or not
     * a past due date is a day behind from current date with a status of INFORCE
     * @param {object} object 
     * @returns true if date is past due, false if it is not
     */
    handlePastDue = (date) => {
        //this function checks the date parameter if its considered PAST DUE or not.
        if (moment(date.DUE_DATE) < moment().subtract(1, 'days') && 
            //moment(date.DUE_DATE) > moment().subtract(60, "days") &&
            date.STATUS.toUpperCase() === "INFORCE") {
            return true
        } else {
            return false
        }
    }

    render() {
        const customNames = [
            {
                Header: 'Policy Number',
                accessor: 'POL_NO'
            },
            {
                Header: "Policy Owner",
                accessor: 'I_COMP_NAME'
            },
            {
                Header: "Plan Name",
                accessor: 'POL_DS'
            },
            {
                Header: "Payment Option",
                accessor: 'BUNDLE_ID',
            },
            {
                Header: "Mode of Payment",
                accessor: "PMODE_CD"
            },
            {
                Header: "Effective Date",
                accessor: 'EFF_DT',
            },
            {
                Header: "Due Date",
                accessor: 'DUE_DT',
            },
            {
                Header: "Currency",
                accessor: "POL_CURR_CD"
            },
            {
                Header: "Face Amount",
                accessor: "FACE_AMT"
            },
            {
                Header: "Expiry Date",
                accessor: "EXPIRY_DT"
            },
            /*
            **removing policy status due to inconsistency with the data source
            {
                Header: "Status",
                accessor: "POL_STAT",
                Cell: cellInfo => <span>{cellInfo.value.toUpperCase()}</span>
            }
            */
        ];

        if (this.state.isLoading) {
            return (
                <ReportLoader handleClose={this.hideModal} show={this.state.isLoading} />
            );
        } else {

            let dataTable = "";

            if (this.state.reports.length > 0) {
                if (this.props.isSm) {
                    dataTable = (
                        <ReportsMobile
                            title={[
                                {
                                    Header: 'Policy Number',
                                    accessor: 'POL_NO',
                                    hasCaret: true,
                                    onClick: () => {
                                        this.headerClickedHandler('POL_NO');
                                    }
                                },
                                /*
                                **removing policy status due to inconsistency with the data source
                                {
                                    Header: 'Status',
                                    accessor: 'POL_STAT',
                                    Cell: cellInfo => <span>{cellInfo.value.toUpperCase()}</span>
                                },
                                */
                            ]}
                            customNames={customNames}
                            data={this.state.reports}
                            sortByField={this.state.sortByField}
                            sortOrder={this.state.sortOrder}
                            hasOnClickBtn={true}
                            onClickAction={this.onClickAction}
                        >
                            <TablePagination
                                css="mt-2"
                                data={this.state.reports}
                                page={this.state.currentPage}
                                totalSize={this.state.totalSize}
                                onPageChange={this.changePage}
                                pageSize={myConfig.PAGE_SIZE}
                                pages={this.state.pages}
                            />
                            {this.state.loadTable ? <TableLoader loading={true} /> : ""}
                        </ReportsMobile>
                    );
                } else {
                    dataTable = (
                        <ReactTable
                            className={`mt-4 ${this.state.showTable ? "d-flex" : "d-none"} rt-no-hover`}
                            resizable={false}
                            loading={this.state.loadTable}
                            LoadingComponent={TableLoader}
                            columns={customNames}
                            defaultPageSize={myConfig.PAGE_SIZE}
                            minRows={0}
                            data={this.state.reports}
                            page={this.state.currentPage}
                            manual
                            onFetchData={(state, instance) => {
                                this.changePage(state.page);
                            }}
                            getTheadThProps={(state, rowInfo, column, instance) => ({
                                onClick: () => {
                                    this.headerClickedHandler(column.id);
                                },
                                sortClassName: (
                                    this.state.sortByField == column.id ?
                                        (this.state.sortOrder == "ASC" ?
                                            "icon-chevron-small-up"
                                            : "icon-chevron-dropdown")
                                        : ""
                                ),
                                sortColor: (
                                    this.state.sortByField == column.id ?
                                        "text-black"
                                        : ""
                                )
                            })}
                            getTrProps={(state, rowInfo, column, instance) => ({
                                onClick: () => {
                                    this.onClickAction(rowInfo.original.POL_NO);
                                },
                                style: {
                                    cursor: "pointer",
                                    color: (this.handlePastDue(rowInfo.original)
                                        ? '#CC0000'
                                        : "")
                                }
                            })}
                            pages={this.state.pages}
                            totalSize={this.state.totalSize}
                        />
                    );
                }
            } else {
                dataTable = (
                    <div className="center-align dashboard-row-data font-neo-semibold">No records found </div>
                );
            }

            let startDate = this.state.startDate;
            let endDate = this.state.endDate;

            if(Number.isNaN(startDate)){
                startDate = new Date();


            }

            if(Number.isNaN(endDate)){
                endDate = new Date();
                endDate.setDate(new Date().getDate() + 30);

            }

            return (
                <div className="dashboard-section">
                    {this.showError()}
                    <div className="dashboard-container container-fluid p-3">
                        <div className="dashboard-header">
                            <h6 className="font-neo-bold">
                                <Link className="text-darkgray text-decoration-none" to="/dashboard/reports">Reports</Link>
                                <span className="dashboard-header__subheader text-headercolor"> | {this.pageHeader}</span>
                            </h6>
                            {this.props.getViewAs}
                        </div>
                        <div className="dashboard-body mt-4">
                            <ReportDatePicker
                                startDateValue={startDate}
                                endDateValue={endDate}
                                updateStartDate={this.updateStartDate}
                                updateEndDate={this.updateEndDate}
                                showTable={this.showTable}
                                placeholderFrom="Due Date From"
                                placeholderTo="Due Date To"
                                //CAN ADD MAX YEAR -> Years(minYear,maxYear)
                                yearOptions={Years(1990)}
                            />
                            <p className="m-3 font-italic" style={{fontSize: ".9em",color: "#255f8e"}}>Note: Please be advised that those in red font are past due policies <br />
                            Data reflected here are the policies with current due. To check if paid, please go to the Billing and Payment page.</p>
                            {dataTable}

                        </div>
                    </div>
                </div>
            );
        }
    }
}

export default withRouter(withWindowDimensions(PolicyDueReport));
