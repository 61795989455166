import React, { Component } from 'react';
import Select from 'react-select';
import withWindowsDimensions from "../commons/withWindowDimensions";

class ReportDropdownSearch extends Component {
    constructor(props) {
        super(props);
    }
    // doSearch = () => {
    //     console.log("doSearch");
    //     this.props.search(this.state.filterBy, this.state.filterQuery, 1);
    // }

    updateSearch = (search) => {
        this.props.updateSearch(search);
    }

    updateDropdown = (value) => {
        this.props.updateDropdown(value);
    }

    mobileCss = () => {
        let mobileFlex = "";
        let searchBar = "";
        let dropdown = "";
        let resetBtn = "";
        if (this.props.isSm) {
            mobileFlex = "flex-wrap";
            searchBar = "w-100";
            dropdown = "ml-0 flex-grow-1 mt-2";
            resetBtn = "ml-auto ml-0 w-25 mt-2"
        }
        return (
            {
                mobileFlex: mobileFlex,
                searchBar: searchBar,
                dropdown: dropdown,
                resetBtn: resetBtn
            }
        );
    }

    searchBar = (boolean = true) => {

        let search = ""
        if (boolean === true) {
            search = (
                <div className={`custom-searchbar datatable-search__container form-control rounded-pill ${this.mobileCss().searchBar} mr-md-2`}>
                    <input value={this.props.searchValue} onChange={this.updateSearch} className="searchbar__input datatable-search__input h-100" type="text" placeholder="Search for" />
                    <a
                        // onClick={() => this.doSearch()}
                        className="btn searchbar__button searchbar__button--gray px-2">
                        <span className="icon-search"></span>
                    </a>
                </div>
            );
        } else {
            search = "";
        }
        return search;
    }

    additionalDropDown = () => {
      let field = (
          <div>
          </div>
      );
      if (this.props.additionalDropDown) {
          field = this.props.additionalDropDown
      }
      return field;
    }

    render() {
        return (
            <div className="datatable-search">
                <form className={`d-flex ${this.mobileCss().mobileFlex}`}>
                    {this.additionalDropDown()}
                    {this.searchBar(this.props.showSearchBar)}
                    <Select
                        styles={this.props.styles}
                        options={this.props.options}
                        isSearchable={false}
                        className={`datatable-select ${this.mobileCss().dropdown}`}
                        classNamePrefix="datatable-select"
                        placeholder={this.props.dropdownPlaceholder}
                        value={this.props.dropdownValue}
                        onChange={this.updateDropdown}
                    />
                    <a className={`lh-1 d-flex mt-2 mt-md-0 justify-content-center align-items-center btn btn-dropdowncolor rounded-pill text-uppercase ${this.props.isSm ? "font-p75rem" : "font-p65rem"} ml-2 font-neo-semibold text-white datatable-search__btn`} onClick={this.props.showTable}>view report</a>
                </form>
            </div>

        );
    }
}
export default withWindowsDimensions(ReportDropdownSearch);
