import React, { Component } from 'react'
import {
    ComposableMap,
    Geographies,
    Geography,
} from "react-simple-maps"
import { MAP_MODE, MAP_CONFIG, haveValue } from '../../commons/misConfig';
import phCodes from '../../data/ph_dist.json';

class DetailedMap extends Component {

    constructor(props) {
        super(props);
        this.state = {
            belowTargetColor: ['#DC3149', '#E36376', '#EA96A3', '#F1C8D0', '#3DA556']
        }
        this.getFillColor = this.getFillColor.bind(this)
    }

    getFillColor(value) {
        // console.log(parseInt(value * 4))
        const { fillColors } = this.props;
        if (value) {
            if(value >= 0){
                return fillColors[parseInt(value * 4)]
            }

            return '#DC3149'
            
        }
        return '#D3D3D3'
    }

    getUnderTargetColor(value) {
        const { belowTargetColor } = this.state;
        if (value) {
            return belowTargetColor[parseInt(value * 0.4)]
        }
        return '#D3D3D3'
    }

    render() {
        const { topoUrl, mode, mapConfig, setTooltipContent, data, activeRegion } = this.props;
        const modeTarget = mode === MAP_MODE.TARGET;
        let that = this;
        return (
            <ComposableMap
                data-tip=""
                width={250}
                height={250}
                projection='geoAlbers'
                projectionConfig={mapConfig}>
                <Geographies geography={topoUrl}>
                    {({ geographies }) => geographies.map((geo, index) => {
                        const { HASC_1, NAME_1 } = geo.properties;
                        let result = []
                        // console.log(data.result)
                        result = data.result.filter(obj => {
                            return HASC_1.includes(obj.province);
                        })

                        // console.log(result)

                        let performanceColor, targetColor, percentAchievedColor;
                        if(haveValue(result)){
                            let maxAchievedPremiumRate = result.reduce((a, b) => +a + +b.achievedPremiumRate, 0);
                            let maxPercentAchieved = result.reduce((a, b) => +a + +b.achievedPercentage, 0);
                            let colorToFill = (maxAchievedPremiumRate / data.maxActual) > 1? 1: (maxAchievedPremiumRate / data.maxActual);
                            // console.log(maxAchievedPremiumRate)
                            performanceColor = that.getFillColor(colorToFill);
                            targetColor = result[0].achievedPercentage ? (result[0].achievedPercentage > 100 ? '#1E8927': '#B71E3F'): '#a4a4a4';
                            percentAchievedColor = that.getUnderTargetColor(result[0].achievedPercentage);
                        }else {
                            performanceColor = that.getFillColor(0);
                            targetColor = '#d3d3d3'
                        }

                        /* if(haveValue(result)) {
                            console.log( HASC_1, result ) 
                        } */

                        let regionObj = result[0] ? result[0].region: '';
                        // console.log(result)
                        return <Geography
                            onMouseEnter={() => { setTooltipContent(result, NAME_1); }}
                            // onClick={() => { setTooltipContent(result, NAME_1); }}
                            onMouseLeave={() => { setTooltipContent(""); }}
                            key={geo.rsmKey} geography={geo} style={{
                                default: {
                                    fill: modeTarget ? percentAchievedColor? percentAchievedColor: '#d3d3d3' : (regionObj && regionObj === activeRegion? '#006192' :performanceColor),
                                    opacity: '1',
                                    outline: "none",
                                    stroke: "#fff",
                                    strokeWidth: 0.5,
                                },
                                hover: {
                                    fill: modeTarget? targetColor: '#006192',
                                    opacity: '1',
                                    stroke: "#fff",
                                    strokeWidth: 0.5,
                                },
                                pressed: {
                                    fill: modeTarget ? targetColor : '#006192',
                                    opacity: '1',
                                    stroke: "#fff",
                                    strokeWidth: 0.5,
                                }
                            }} />
                    }
                    )}
                </Geographies>
            </ComposableMap>
        )
    }
}

export default DetailedMap