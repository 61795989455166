import React, { Component } from 'react'
import BulletinSection from '../module/bulletinSection'
import { haveValue } from '../../../commons/misConfig';
import moment from 'moment';
import MiniLoading from './miniLoading';
import VisibilitySensor from 'react-visibility-sensor';

class SearchBulletin extends Component {

    constructor(props) {
        super(props)

        this.formatData = this.formatData.bind(this);
    }

    formatData(content) {
        let newContent = [];

        if (haveValue(content)) {
            content.map(function (obj, index) {
                let result = {
                    type: obj.type,
                    imgSrc: obj.imgUrl,
                    title: obj.title,
                    date: moment(obj.effectiveDt * 1000).format("MMM DD, YYYY"),
                    id: obj.id
                }

                newContent.push(result)
            })
        }

        return newContent
    }

    render() {
        const { searchResult, isLoading, standalone } = this.props;
        let formattedData = this.formatData(searchResult.content)
        return !isLoading ? (
            <div className="">
                {haveValue(formattedData) ? <BulletinSection bulletinList={formattedData} /> : <p style={{ textAlign: 'center', fontSize: '2rem', color: 'darkgray', fontFamily: 'neo_regular' }}>No Bulletin Found</p>}
                {standalone && haveValue(formattedData) ? <VisibilitySensor delayedCall={true} onChange={(e) => {
                    if (e) { this.props.nextPage(); }
                }}>
                    <div>
                        <p style={{
                            textAlign: 'center',
                            fontSize: '2rem',
                            fontWeight: '800',
                            marginBottom: '0px'
                        }}>. . .</p>
                    </div>
                </VisibilitySensor> : ""}
            </div>
        ) : <MiniLoading />
    }
}

export default SearchBulletin;