import React, { Component, useState, forwardRef } from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { LoaderGif2 } from '../../commons/loaderGif';

class ConfirmTransaction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      transactionMessage: ""
    }

    if (!this.props.userAction) {
      this.props.history.push('/');
    } else {
      console.warn("PROPS", this.props);
      // DIRECTLY SET STATE OR ELSE YOU GET THIS REACT RUNTIME ERROR:
      // Warning: Can't call setState on a component that is not yet mounted.
      this.state.transactionMessage = this.getTransactionResult();
      console.log(this.state);
      this.props.setProgress(3);
    }
  }

  getTransactionResult() {
    const action = this.props.userAction;
    let transactionMessage = "";
    
    if (action === "Accept") {
      transactionMessage = "Transaction has been successful. The updated information will be reflected in the system. Please click <a href='https://touch.allianzpnblife.ph/registration'>here</a> to register your Allianz Touch account.";
    } else if (action === "Reject") {
      transactionMessage = "Got it! We will not process the requested update. Your contact details on record will not be changed. You may contact your agent for any request to update your information."
    } else {
      transactionMessage = "The transaction was not processed based on your response. There will be no changes with your policy information in the system."
    }

    return transactionMessage;
  }

  render() {
    return(
      <section className="confirm-transaction d-flex flex-column">
        <div className="confirm-transaction__message">
          <span className="confirm-transaction__check icon-check-circle"></span>
          {
            this.state.transactionMessage && <span className="confirm-transaction__text" dangerouslySetInnerHTML={{ __html: this.state.transactionMessage }}/>
          }
        </div>
      </section>
    );
  }
}

export default ConfirmTransaction;
