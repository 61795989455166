import React, { Component } from 'react'
import LinesEllipsis from 'react-lines-ellipsis'
import TextEllipsis from 'react-text-ellipsis';
import NavLink from 'react-router-dom/NavLink';

class bulletinSection extends Component {
    constructor(props) {
        super(props)

        this.state = {
            bulletinList: this.props.bulletinList ? this.props.bulletinList : [],
        }
    }

    componentDidMount() {
        let props = this.props;
        this.setState({
            ...props
        });
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            ...nextProps
        })
    }

    render() {
        const { bulletinList } = this.state;
        return Array.isArray(bulletinList) ? (
            <div id="BulletinSection" className="rni-bulletin-section">
                {bulletinList.map(function (bulletinObj, bulletinIndex) {
                    return (
                        <NavLink key={'bulletin-item-' + bulletinIndex}
                            style={{ textDecoration: 'none' }}
                            to={"/rewards-and-incentives/bulletin?id=" + bulletinObj.id}>
                            <div className={`rni-bulletin-item rni-bulletin-item-bg-${bulletinObj.type}`}>
                                <img src={bulletinObj.imgSrc} className="rni-bulletin-item-img" />
                                <div className="rni-bulletin-item-description">
                                    <TextEllipsis
                                        lines={3}
                                        tag={'p'}
                                        ellipsisChars={'...'}
                                        tagClass={'className'}
                                        debounceTimeoutOnResize={200}
                                        useJsOnly={true}
                                    >
                                        {bulletinObj.title}
                                    </TextEllipsis>
                                    <div style={{ display: 'inline-flex', width: '100%' }}>
                                        <label><span className="rni-bulletin-type">{bulletinObj.type}</span></label>
                                        <label><span className="rni-bulletin-type-date">{bulletinObj.date}</span></label>
                                    </div>
                                </div>
                            </div>
                        </NavLink>
                    )
                })}
            </div>
        ) :
            null
    }
}

export default bulletinSection;