import React, { Component } from "react";
import DatePicker from "react-datepicker";
import DpCustomHeader from "../components/Datepicker/customHeader";
import Select from 'react-select';
import withWindowsDimensions from "../commons/withWindowDimensions";

class ReportDatepickerDropdown extends Component {

    constructor(props) {
        super(props);
    }

    updateDropdown = (value) => {
        this.props.updateDropdown(value);
    }

    mobileCss = () => {
        let mobileFlex = "";
        let searchBar = "";
        let dropdown = "";
        let resetBtn = "";

        if (this.props.isSm) {
            mobileFlex = "flex-wrap";
            searchBar = "w-100";
            dropdown = "w-50 ml-0 flex-grow-1 mt-2";
            resetBtn = "w-50 ml-auto ml-0 w-25 mt-2";
            
        }
        return (
            {
                mobileFlex: mobileFlex,
                searchBar: searchBar,
                dropdown: dropdown,
                resetBtn: resetBtn
            }
        );
    }

    additionalDropDown = () => {
      let field = (
          <div>
          </div>
      );
      if (this.props.additionalDropDown) {
          field = this.props.additionalDropDown
      }
      return field;
    }

    render() {
        return (
            <div className="datatable-search">
                <form className={`d-flex ${this.mobileCss().mobileFlex}`}>
                    {this.additionalDropDown()}
                    <Select
                        styles={this.props.styles}
                        options={this.props.options}
                        isSearchable={false}
                        className={`datatable-select ${this.mobileCss().dropdown}`}
                        classNamePrefix="datatable-select"
                        placeholder={this.props.dropdownPlaceholder}
                        value={this.props.dropdownValue}
                        onChange={this.updateDropdown}
                        defaultValue={this.props.defaultValue}
                    />
                    <a className={`lh-1 d-flex mt-md-0 justify-content-center align-items-center btn btn-dropdowncolor rounded-pill text-uppercase ${this.props.isSm ? "font-p75rem" : "font-p65rem"} ml-2 font-neo-semibold text-white datatable-search__btn`} onClick={this.props.showTable}>view report</a>
                </form>
            </div>

        );
    }
}

export default ReportDatepickerDropdown;
