import React, { Component, Fragment } from 'react';
import { ReportLoader } from '../commons/utilityViews.js';
import { withRouter } from 'react-router-dom';
// import 'react-vertical-timeline-component/style.min.css';
import { prettifyDate, calculateAge, numberWithCommas, writeData, readAllData, removeNullNumber, removeNull, fixName, decrypt, capitalize, replaceRemark } from '../commons/utility.js';
import { createQueryStrings } from "../commons/apiCall";
import moment from "moment";
import DatePicker from 'react-datepicker';
import Auxi from '../components/auxi';
import { format } from 'date-fns';

// import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
// import ArrowDropUp from '@material-ui/icons/ArrowDropUp';
import { ArrowDropUp, ArrowDropDown } from '@material-ui/icons';

import { apiModule } from '../commons/apiCall';
import { myConfig } from '../commons/config';
import { ConsoleLineIcon } from 'mdi-react';
import Status from "../components/SinglePolicy/status";
import { isManager } from "../commons/security";
import Modal from "../components/SinglePolicy/modal";
import EndorsementModal from "../components/SinglePolicy/endorsementModal";
import Dividends from "../components/SinglePolicy/Dividends";
import Surrender from "../components/SinglePolicy/Surrender";
import Reinstatement from "../components/SinglePolicy/reinstatement";
import TransactionStatement from "../components/SinglePolicy/transactionStatement";
// import TransactionStatement from "../components/SinglePolicy/transactionStatement";
import OtherInfoPdfModal from '../components/SinglePolicy/otherInfoPdfModal';

let hardcodePolicyId = false;
let policyId_benefitsentitlements = '103363131';
let policyId_claims = '103075784';
let policyId_coverages = '103363131';
let policyId_dividends = '300127594';
let policyId_endorsements = '103363131';
let policyId_endowments = '500074034';
let policyId_funds = '103862702';
let policyId_paymenttransactions = '102420577';
let policyId_reinstatements = '103363131';
let policyId_loans = '102563988';

let policyId_trad = '102563988';
let policyId_ul = '103362190';

class SinglePolicy extends Component {

    constructor(props) {
        super(props);
        this.state = {
            policyId: "",
            isLoading: false,
            isModalLoading: true,
            isFundTransactionLoading: true,
            showRequirement: false,
            showEndorsementRequirements: false,
            showOtherInfoPdfModal: false,
            selectedOtherInfoPdfModal: "",
            startDate: new Date(),
            endDate: new Date(),
            error: "",
            paymentTransactionInfo: [],
            paymentTransactionInfoTransaction: [],
            dashboardPolicyFieldsInfo: [],
            policyDetailFieldsInfo: null,
            basicInfo: null,
            contractNumber: "",

            requirements: [],
            billingInfo: [],
            insuredInfo: [],
            otherInfo: [],
            policyValueInfo: [],
            insuranceType: "",
            transactionModaltInfo: [],
            reinstatementModalInfo: {},
            linkedPlansInfo: [],
            traditionalPlansInfo: [],
            beneficiaryInfo: [],
            policyLoanInfo: [],
            endowmentsInfo: [],
            claimsInfo: [],
            coverageInfo: [],
            endorsementInfo: [],
            reinstatementInfo: [],
            fundValueInfo: [],
            policyFundValueInfo: [],
            fundValueAllocationInfo: [],
            fundTransactionInfo: [],
            policyDividends: [],
            tableOfBenefits: [],
            healthDetails: [],
            fundId: '1',
            selectedEndorsementId: "",
            fundTransactionsTableData: [],
        };
        this.handleStartDateChange = this.handleStartDateChange.bind(this);
        this.handleEndDateChange = this.handleEndDateChange.bind(this);
    }
    async componentDidMount() {
        let that = this;
        if (this.props.getClientOrPolicy().policyId === "-1") {
            readAllData('policy-client')
                .then(function (data) {
                    for (var dt of data) {
                        if (that.props.getSession().access_token !== "") {
                            that.state.policyId = dt.policyId;
                            that.fetchThisPolicy(that.props.getSession().access_token, dt.policyId);
                        }
                    }
                });

        } else {
            console.log("HAS Policy ID !!!!!!! ")

            if (this.props.getSession().access_token !== "") {
                that.state.policyId = this.props.getClientOrPolicy().policyId;
                await this.fetchThisPolicy(this.props.getSession().access_token, this.props.getClientOrPolicy().policyId);
            }
        }
    }
    errorMessage = (message) => {
        if (message) {
            this.setState({ error: message })
        }
    }

    getUserInformation = (insuredItem, homeAddress, workAddress, insuranceType) => {
        let myObject = {};
        let preferredContactChannelsWork = null;
        let preferredContactChannelsHome = null;
        let preferredContactChannels = [];

        if (insuredItem !== null) {
            myObject = {
                name: fixName(insuredItem), //`${insuredItem.firstName} ${insuredItem.middleName} ${insuredItem.lastName}`,
                gender: removeNull(insuredItem.gender),
                nationality: removeNull(insuredItem.nationality),
                //sss: " --- ",

                email: removeNull(insuredItem.email)
            };
            myObject['name'] = removeNull(myObject['name']);

            if (insuredItem.countryPrefix !== null && insuredItem.countryPrefix !== undefined) {
                myObject['contact'] = `(${insuredItem.countryPrefix})`;
            } else {
                myObject['contact'] = '';
            }

            if (insuredItem.phoneNumber !== null && insuredItem.phoneNumber !== undefined) {
                myObject['contact'] = myObject['contact'] + ` ${insuredItem.phoneNumber}`;
            }
            if (insuredItem.preferredContactChannels !== null && insuredItem.preferredContactChannels !== undefined) {
                preferredContactChannels = insuredItem.preferredContactChannels;

            }
            myObject['contact'] = removeNull(myObject['contact']);

            if (insuranceType === "health") {
                let healthObject = {};
                healthObject.memberCardNumber = insuredItem.memberCardNumber;
                healthObject.contingentOwner = insuredItem.hasOwnProperty("contingentOwner") ? fixName(insuredItem.contingentOwner) : false;
                healthObject.relToOwner = insuredItem.hasOwnProperty("relToOwner") ? insuredItem.relToOwner : false;
                healthObject.modalPremium = insuredItem.premiums.netPremium;
                healthObject.annualPremium = insuredItem.premiums.grossPremium;
                healthObject.civilStatus = insuredItem.maritalStatus;
                healthObject.dateOfBirth = insuredItem.birthDate;
                healthObject.age = insuredItem.hasOwnProperty("age") ? insuredItem.age : "";

                myObject = { ...myObject, ...healthObject };
            }
        }


        if (homeAddress !== null) {
            myObject['home'] = [];
            myObject['home_mailing'] = [];
            myObject['home_billing'] = [];
            myObject['self_home'] = [];
            for (let j = 0; j < homeAddress.length; j++) {
                myObject['home_mailing'][j] = false;
                myObject['home_billing'][j] = false;
                myObject['home'][j] = '';

                if (homeAddress[j].floorNumber !== null && homeAddress[j].floorNumber !== "null" && homeAddress[j].floorNumber !== undefined)
                    myObject['home'][j] = myObject['home'][j] + homeAddress[j].floorNumber;
                if (homeAddress[j].buildingName !== null && homeAddress[j].buildingName !== "null" && homeAddress[j].buildingName !== undefined)
                    myObject['home'][j] = myObject['home'][j] + " " + homeAddress[j].buildingName;
                if (homeAddress[j].street !== null && homeAddress[j].street !== "null" && homeAddress[j].street !== undefined)
                    myObject['home'][j] = myObject['home'][j] + " " + homeAddress[j].street;
                if (homeAddress[j].district !== null && homeAddress[j].district !== "null" && homeAddress[j].district !== undefined)
                    myObject['home'][j] = myObject['home'][j] + " " + homeAddress[j].district;
                if (homeAddress[j].city !== null && homeAddress[j].city !== "null" && homeAddress[j].city !== undefined)
                    myObject['home'][j] = myObject['home'][j] + ", " + homeAddress[j].city;
                if (homeAddress[j].state !== null && homeAddress[j].state !== "null" && homeAddress[j].state !== undefined)
                    myObject['home'][j] = myObject['home'][j] + ", " + homeAddress[j].state;
                if (homeAddress[j].zipCode !== null && homeAddress[j].zipCode !== "null" && homeAddress[j].zipCode !== undefined)
                    myObject['home'][j] = myObject['home'][j] + ", " + homeAddress[j].zipCode;
                if (homeAddress[j].countryCode !== null && homeAddress[j].countryCode !== "null" && homeAddress[j].countryCode !== undefined)
                    myObject['home'][j] = myObject['home'][j] + ", " + homeAddress[j].countryCode;

                myObject['home'][j] = removeNull(myObject['home'][j]);

                if (homeAddress[j].self !== null) {
                    myObject['self_home'][j] = homeAddress[j].self;
                } else {
                    myObject['self_home'][j] = "--";
                }

                if (preferredContactChannels != null) {
                    for (let i = 0; i < preferredContactChannels.length; i++) {
                        if (preferredContactChannels[i].addressType !== null &&
                            preferredContactChannels[i].addressType.toLowerCase() === "home" &&
                            preferredContactChannels[i].name.toLowerCase() === "mailing" &&
                            preferredContactChannels[i].dataSource == homeAddress[j].self) {
                            myObject['home_mailing'][j] = true;

                        } else if (preferredContactChannels[i].addressType !== null &&
                            preferredContactChannels[i].addressType.toLowerCase() === "home" &&
                            preferredContactChannels[i].name.toLowerCase() === "billing" &&
                            preferredContactChannels[i].dataSource == homeAddress[j].self) {
                            myObject['home_billing'][j] = true;
                        }
                    }


                }
            }



        }

        if (workAddress !== null) {

            myObject['work'] = [];
            myObject['work_mailing'] = [];
            myObject['work_billing'] = [];
            myObject['self_work'] = [];

            for (let j = 0; j < workAddress.length; j++) {
                myObject['work_mailing'][j] = false;
                myObject['work_billing'][j] = false;
                myObject['work'][j] = '';

                if (workAddress[j].floorNumber !== null && workAddress[j].floorNumber !== "null" && workAddress[j].floorNumber !== undefined)
                    myObject['work'][j] = myObject['work'][j] + workAddress[j].floorNumber;
                if (workAddress[j].buildingName !== null && workAddress[j].buildingName !== "null" && workAddress[j].buildingName !== undefined)
                    myObject['work'][j] = myObject['work'][j] + " " + workAddress[j].buildingName;
                if (workAddress[j].street !== null && workAddress[j].street !== "null" && workAddress[j].street !== undefined)
                    myObject['work'][j] = myObject['work'][j] + " " + workAddress[j].street;
                if (workAddress[j].district !== null && workAddress[j].district !== "null" && workAddress[j].district !== undefined)
                    myObject['work'][j] = myObject['work'][j] + " " + workAddress[j].district;
                if (workAddress[j].city !== null && workAddress[j].city !== "null" && workAddress[j].city !== undefined)
                    myObject['work'][j] = myObject['work'][j] + ", " + workAddress[j].city;
                if (workAddress[j].state !== null && workAddress[j].state !== "null" && workAddress[j].state !== undefined)
                    myObject['work'][j] = myObject['work'][j] + ", " + workAddress[j].state;
                if (workAddress[j].zipCode !== null && workAddress[j].zipCode !== "null" && workAddress[j].zipCode !== undefined)
                    myObject['work'][j] = myObject['work'][j] + ", " + workAddress[j].zipCode;
                if (workAddress[j].countryCode !== null && workAddress[j].countryCode !== "null" && workAddress[j].countryCode !== undefined)
                    myObject['work'][j] = myObject['work'][j] + ", " + workAddress[j].countryCode;

                myObject['work'][j] = removeNull(myObject['work'][j]);

                if (workAddress[j].self !== null) {
                    myObject['self_work'][j] = workAddress[j].self;
                } else {
                    myObject['self_work'][j] = "--";
                }
                if (preferredContactChannels != null) {

                    for (let i = 0; i < preferredContactChannels.length; i++) {
                        if (preferredContactChannels[i].addressType !== null &&
                            preferredContactChannels[i].addressType.toLowerCase() === "work" &&
                            preferredContactChannels[i].name.toLowerCase() === "mailing" &&
                            preferredContactChannels[i].dataSource == workAddress[j].self) {

                            myObject['work_mailing'][j] = true;

                        } else if (preferredContactChannels[i].addressType !== null &&
                            preferredContactChannels[i].addressType.toLowerCase() === "work" &&
                            preferredContactChannels[i].name.toLowerCase() === "billing" &&
                            preferredContactChannels[i].dataSource == workAddress[j].self) {

                            myObject['work_billing'][j] = true;

                        }
                    }


                }
            }

        }

        return myObject;

    }
    fetchThisPolicy = (access_token, policyId) => {
        let that = this;
        return new Promise(function (resolve, reject) {

            if (hardcodePolicyId) {
                policyId = policyId_trad;
            }
            let headerParams = { x_auth: access_token, policyId: policyId };

            apiModule("get_policy", headerParams, null)
                .then(function (result) {

                    console.log("get_policy");
                    console.log(result);
                    let dashboardPolicyFieldsInfo = [];
                    let policyDetailFieldsInfo = [];
                    let basicInfo = [];
                    let billingInfo = [];
                    let insuredInfo = [];
                    let otherInfo = that.state.otherInfo;
                    let policyValueInfo = [];
                    let beneficiaryInfo = [];
                    let endowmentsInfo = [];

                    let insuranceType = "traditional";
                    //IF RESULT IS NULL THEN ERROR MESSAGE
                    if (result) {
                        let item = null;
                        if (Array.isArray(result)) {
                            item = result[result.length - 1];
                        }
                        else {
                            item = result;
                        }

                        if (item.comment !== undefined && item.comment !== null) {
                            insuranceType = item.comment.toLowerCase();
                        }

                        that.fetchTransactionStatement(policyId)
                        writeData('policy-client', { policyId: policyId, PolicyType: insuranceType, id: 1 });

                        that.setState({
                            contractNumber: item.contractNumber,
                        });

                        //Dashboard information
                        dashboardPolicyFieldsInfo.push({
                            name: "Policy Number",
                            value: item.contractNumber
                        });

                        dashboardPolicyFieldsInfo.push({
                            name: "Plan Name",
                            value: item.productOfferingName
                        });

                        //Policy Details
                        if (item.contractInterval !== null && item.contractInterval !== undefined) {
                            policyDetailFieldsInfo.push({
                                name: "Start Date",
                                value: prettifyDate(item.contractInterval.startDateTime)
                            });

                            policyDetailFieldsInfo.push({
                                name: "Effectivity Date",
                                value: prettifyDate(item.contractInterval.startDateTime)
                            });

                            policyDetailFieldsInfo.push({
                                name: "Expiry Date",
                                value: prettifyDate(item.contractInterval.endDateTime)
                            });
                        }


                        policyDetailFieldsInfo.push({
                            name: "Status",
                            value: item.status
                        });



                        policyDetailFieldsInfo.push({
                            name: "Currency",
                            value: item.currency
                        });

                        policyDetailFieldsInfo.push({
                            name: "Contract Branch",
                            value: item.branchName
                        });

                        //Basic Information
                        basicInfo.push({
                            name: "Basic Plan",
                            value: item.productOfferingName
                        });

                        basicInfo.push({
                            name: "Status",
                            value: item.status
                        });

                        basicInfo.push({
                            name: "Face Amount",
                            value: numberWithCommas(item.contractBalance)
                        });

                        if (insuranceType === 'health') {
                            policyDetailFieldsInfo.push({
                                name: "Status Date",
                                value: prettifyDate(item.purchaseDate)
                            });
                        } else {
                            policyDetailFieldsInfo.push({
                                name: "PAR Date",
                                value: prettifyDate(item.purchaseDate)
                            });
                            basicInfo.push({
                                name: "Modal Premium",
                                value: numberWithCommas(item.netPremium)
                            });

                            basicInfo.push({
                                name: "Basic Annual Premium",
                                value: numberWithCommas(item.annualPremium)
                            });

                            basicInfo.push({
                                name: "Payment Option",
                                value: item.paymentMode
                            });

                            basicInfo.push({
                                name: "Plan Type",
                                value: item.contractType
                            });

                            basicInfo.push({
                                name: "Year Payable",
                                value: item.paymentDuration
                            });
                        }

                        basicInfo.push({
                            name: "Mode of Payment",
                            value: item.paymentFrequency
                        });

                        basicInfo.push({
                            name: "Policy Issue Date",
                            value: prettifyDate(item.inceptionDate)
                        });

                        if (item.contractDeliveryDate) {
                            basicInfo.push({
                                name: 'Policy Delivery Date',
                                value: item.contractDeliveryDate ? prettifyDate(item.contractDeliveryDate) : '--'
                            })
                        }

                        if (insuranceType === "traditional") {
                            //if trad only
                            if (item.lifeDetail !== null && item.lifeDetail !== undefined) {
                                basicInfo.push({
                                    name: "Special Settlement Option",
                                    value: item.lifeDetail.payoutMethod
                                });
                                basicInfo.push({
                                    name: "Default NFO",
                                    value: item.lifeDetail.nonPaymentForfeiture
                                });

                            }

                            //if trad only
                            basicInfo.push({
                                name: "Dividend Option",
                                value: item.dividendPaymentMethod
                            });
                        }

                        billingInfo.push({
                            name: "Billing Type",
                            value: item.typeOfBill
                        });

                        //Insured Owner Name
                        let insuredItem = item.insured;
                        let applicationOwnerItem = item.contractHolder;
                        let workAddress = [];
                        let homeAddress = [];

                        if (insuranceType === "health") {
                            let coverageList = item.coverageList;
                            let items = [];
                            insuredItem.map(insured => {
                                let number = insured.identityDocuments[0].number;

                                let coverage = coverageList.find(x => x.memberNumber === number);

                                if (!insured.hasOwnProperty("relToOwner")) {
                                    insured.contingentOwner = applicationOwnerItem.legalRepresentative;
                                    insured.preferredContactChannels = applicationOwnerItem.preferredContactChannels;
                                }

                                insured.premiums = coverage.premiums[0];
                                insured.memberCardNumber = number;

                                items.push(insured);
                            })
                            insuredItem = items;

                            basicInfo.push({
                                name: "Client Type",
                                value: applicationOwnerItem.clientType
                            });
                        }

                        insuredItem.map(insuredItem => {
                            if (insuredItem !== null && insuredItem !== undefined) {
                                if (insuredItem.addresses !== null && insuredItem.addresses !== undefined) {
                                    workAddress = [];
                                    homeAddress = [];
                                    for (let j = 0; j < insuredItem.addresses.length; j++) {
                                        if (insuredItem.addresses[j].type !== null && insuredItem.addresses[j].type !== undefined && insuredItem.addresses[j].type !== "") {
                                            if (insuredItem.addresses[j].type.toLowerCase() === "work") {
                                                workAddress.push(insuredItem.addresses[j]);
                                            } else if (insuredItem.addresses[j].type.toLowerCase() === "home") {
                                                homeAddress.push(insuredItem.addresses[j]);
                                            }
                                        }
                                    }
                                    insuredInfo.push(that.getUserInformation(insuredItem, homeAddress, workAddress, insuranceType));
                                }
                                else {
                                    insuredInfo.push(that.getUserInformation(insuredItem, [], [], insuranceType));
                                }
                            }
                        });
                        if(insuranceType !== "health") {
                            if (applicationOwnerItem !== null && applicationOwnerItem !== undefined) {
                                if (applicationOwnerItem.addresses !== null && applicationOwnerItem.addresses !== undefined) {

                                    workAddress = [];
                                    homeAddress = [];

                                    for (let j = 0; j < applicationOwnerItem.addresses.length; j++) {

                                        if (applicationOwnerItem.addresses[j].type !== null && applicationOwnerItem.addresses[j].type !== undefined && applicationOwnerItem.addresses[j].type !== "") {

                                            if (applicationOwnerItem.addresses[j].type.toLowerCase() === "work") {
                                                workAddress.push(applicationOwnerItem.addresses[j]);
                                            } else if (applicationOwnerItem.addresses[j].type.toLowerCase() === "home") {
                                                homeAddress.push(applicationOwnerItem.addresses[j]);
                                            }
                                        }
                                    }

                                    insuredInfo.push(that.getUserInformation(applicationOwnerItem, homeAddress, workAddress));
                                }
                            }
                        }

                        const contractHolder = item.contractHolder !== undefined;

                        let legalRepresentative = contractHolder ? item.contractHolder.legalRepresentative : null;
                        let collateralAssignee = contractHolder ? item.contractHolder.collateralAssignee : null;

                        //Policy Details Other info
                        otherInfo.push({
                            name: "Contingent Owner Name",
                            value: fixName(legalRepresentative), //`${legalRepresentative.lastName}, ${legalRepresentative.firstName} ${legalRepresentative.middleName}`
                        });
                        if (fixName(collateralAssignee) !== "--") {
                            otherInfo.push({
                                name: "Collateral Assignee Name",
                                value: fixName(collateralAssignee), //`${collateralAssignee.lastName}, ${collateralAssignee.firstName} ${collateralAssignee.middleName}`
                            });
                        }
                        if (collateralAssignee && collateralAssignee.currency && collateralAssignee.currency !== null) {
                            otherInfo.push({
                                name: "Collateral Currency",
                                value: collateralAssignee.currency
                            });
                        }
                        if (collateralAssignee && collateralAssignee.relationship && collateralAssignee.relationship !== null) {
                            otherInfo.push({
                                name: "Relationship to Proposed Insured",
                                value: collateralAssignee.relationship
                            });
                        }
                        if (collateralAssignee && collateralAssignee.collateralAssigneeAmount && collateralAssignee.collateralAssigneeAmount !== null) {
                            otherInfo.push({
                                name: "Collateral Assignee Amount",
                                value: numberWithCommas(collateralAssignee.collateralAssigneeAmount)
                            });
                        }
                        otherInfo.push({
                            name: "Sales Illustration",
                            id: "salesIllustration",
                            hasPdfModal: true
                        });
                        //Policy Value

                        if (item.lifeDetail !== null && item.lifeDetail !== undefined) {
                            let contractCalculation = item.lifeDetail.lifeContractCalculation;

                            policyValueInfo = [
                                {
                                    basicCash: numberWithCommas(contractCalculation.basicAmount),
                                    basicDate: prettifyDate(contractCalculation.basicCashDate),
                                    riderCash: numberWithCommas(contractCalculation.riderCashAmount),
                                    riderDate: prettifyDate(contractCalculation.riderCashDate),
                                    option: removeNull(item.lifeDetail.payoutMethod),
                                    dividend: numberWithCommas(item.lifeDetail.dividends),
                                    apdBal: numberWithCommas(contractCalculation.advancedPremiumDepositAmount),
                                    apdDate: prettifyDate(contractCalculation.advancedPremiumDepositDate),

                                    suspenseAmt: numberWithCommas(contractCalculation.suspenseAmount),
                                    ffrBal: numberWithCommas(item.lifeDetail.flexiFundContributionAmount),
                                    ffrDate: prettifyDate(item.lifeDetail.flexiFundContributionEffectiveDate),

                                    prdBal: numberWithCommas(contractCalculation.gracePeriodBalance),
                                    prdDate: prettifyDate(contractCalculation.gracePeriodDate),

                                    aplBal: numberWithCommas(contractCalculation.premiumLoanBalance),
                                    plBal: numberWithCommas(contractCalculation.loanBalance)
                                }
                            ];
                        } else {
                            policyValueInfo = [
                                {
                                    basicCash: 0,
                                    basicDate: null,
                                    riderCash: 0,
                                    riderDate: null,
                                    option: '--',
                                    dividend: 0,
                                    apdBal: 0,
                                    apdDate: null,
                                    suspenseAmt: 0,
                                    ffrBal: 0,
                                    ffrDate: null,
                                    prdBal: 0,
                                    prdDate: null,
                                    aplBal: 0,
                                    plBal: 0
                                }
                            ];
                        }

                    } else {
                        //that.errorMessage("No Client Found");
                    }

                    that.setState({

                        loadTable: false,
                        dashboardPolicyFieldsInfo,
                        policyDetailFieldsInfo,
                        basicInfo,
                        billingInfo,
                        insuredInfo,
                        otherInfo,
                        policyValueInfo,
                        insuranceType,
                        isLoading: false,
                    });
                    // resolve("Ok");
                })
                .catch(function (err) {
                    console.log(err);
                    that.setState({
                        loadTable: false,
                        isLoading: false,
                        policyDetailFieldsInfo: [],
                        basicInfo: []
                    });

                    that.errorMessage(myConfig.GENERIC_ERROR_MESSAGE);
                    console.log(err.message)
                    // resolve("Ok");
                });



            if (hardcodePolicyId) {
                policyId = policyId_loans;
                headerParams = { x_auth: access_token, policyId: policyId };
            }

            apiModule("get_policy_dividends", headerParams, null)
                .then(function (result) {
                    let policyDividends = [];
                    console.log("get_policy_loans");
                    console.log(result);
                    //let columns = ["Summary of Payments", "Outstanding Loan", "Loan Tenure", "Basic", "Interest", "Total", "Origin of Loan"];
                    if (result !== null && result !== undefined) {
                        for (let i = 0; i < result.length; i++) {
                            policyDividends.push({
                                currency: result[i].currency,
                                payOutAmount: numberWithCommas(result[i].payOutAmount)
                            })
                        }
                    }
                    that.setState({

                        loadTable: false,
                        policyDividends,

                    });

                });
            apiModule("get_policy_loans", headerParams, null)
                .then(function (result) {
                    let policyLoanInfo = [];
                    console.log("get_policy_loans");
                    console.log(result);
                    //let columns = ["Summary of Payments", "Outstanding Loan", "Loan Tenure", "Basic", "Interest", "Total", "Origin of Loan"];
                    if (result !== null && result !== undefined)
                        for (let i = 0; i < result.length; i++) {
                            let item = result[i];
                            let total = parseFloat(item.loanAmount) + parseFloat(item.outstandingAmount) + parseFloat(item.basicAmount) + parseFloat(item.interestAmount);
                            policyLoanInfo.push({
                                summary: numberWithCommas(item.loanAmount),
                                outLoan: numberWithCommas(item.outstandingAmount),
                                tenLoan: numberWithCommas(item.tenure),
                                basic: numberWithCommas(item.basicAmount),
                                interest: numberWithCommas(item.interestAmount),
                                total: numberWithCommas(total),
                                origin: item.lender,
                            });
                        }

                    that.setState({

                        loadTable: false,
                        policyLoanInfo,

                    });
                })
                .catch(function (err) {
                    console.log(err);
                    that.setState({
                        loadTable: false,
                        isLoading: false,

                    });
                    that.errorMessage(myConfig.GENERIC_ERROR_MESSAGE);
                    //   resolve("Ok");

                });

            if (hardcodePolicyId) {
                policyId = policyId_coverages;
                headerParams = { x_auth: access_token, policyId: policyId };
            }

            apiModule("get_policy_coverage", headerParams, null)
                .then(function (result) {
                    let coverageInfo = [];

                    console.log("get_policy_coverage");
                    console.log(result);

                    if (result !== null && result !== undefined)
                        for (let i = 0; i < result.length; i++) {
                            let item = result[i];
                            if (item.coverageInterval !== null && item.coverageInterval !== undefined) {
                                coverageInfo.push({
                                    riderName: removeNull(item.name),
                                    year: "--",
                                    expiry: prettifyDate(item.coverageInterval.endDateTime),
                                    month: removeNull(item.paymentDuration),
                                    status: removeNull(item.status),
                                    date: "--",
                                    amt: numberWithCommas(item.insuranceSum),
                                    premium: numberWithCommas(item.technicalPrice),

                                });
                            } else {
                                coverageInfo.push({
                                    riderName: `${item.name}`,
                                    year: "--",
                                    expiry: "--",
                                    month: removeNull(item.paymentDuration),
                                    status: removeNull(item.status),
                                    date: "--",
                                    amt: numberWithCommas(item.insuranceSum),
                                    premium: numberWithCommas(item.technicalPrice),

                                });
                            }

                        }

                    that.setState({

                        loadTable: false,
                        coverageInfo,

                    });
                })
                .catch(function (err) {
                    console.log(err);
                    that.setState({
                        loadTable: false,
                        isLoading: false,

                    });
                    that.errorMessage(myConfig.GENERIC_ERROR_MESSAGE);
                    //   resolve("Ok");

                });

            let headerFundsParams = { x_auth: access_token, policyId: policyId, fundId: that.state.fundId };

            if (hardcodePolicyId) {
                policyId = policyId_funds;
                headerFundsParams = { x_auth: access_token, policyId: policyId, fundId: that.state.fundId };
            }

            apiModule("get_policy_funds_transaction2", headerFundsParams, null)
                .then(function (result) {
                    let fundTransactionInfo = [];
                    console.log("get_policy_funds_transaction2");
                    console.log(result);

                    if (result !== null && result !== undefined)
                        for (let i = 0; i < result.length; i++) {
                            let item = result[i];
                            fundTransactionInfo.push({
                                No: removeNull(item.transactionNumber),
                                TransactionDate: prettifyDate(item.transactionOrderDate),
                                TransactionType: removeNull(item.transactionType),
                                PricingDate: prettifyDate(item.transactionExecutionDate),
                                AllocationAmount: numberWithCommas(item.transactionAmount),
                                UnitPrice: numberWithCommas(item.fundPrice),
                                Units: numberWithCommas(item.fundShare),
                                RunningBalance: numberWithCommas(item.balance),

                            });
                        }

                    that.setState({

                        loadTable: false,
                        fundTransactionInfo,

                    });
                })
                .catch(function (err) {
                    console.log(err);
                    that.setState({
                        loadTable: false,
                        isLoading: false,

                    });
                    that.errorMessage(myConfig.GENERIC_ERROR_MESSAGE);
                    //   resolve("Ok");

                });

            if (hardcodePolicyId) {
                policyId = policyId_funds;
                headerParams = { x_auth: access_token, policyId: policyId };
            }

            apiModule("get_policy_funds", headerParams, null)
                .then(function (result) {
                    let fundValueInfo = [];
                    let fundValueInfo2 = [];
                    let fundValueAllocationInfo = [];
                    let policyFundValueInfo = [];
                    policyFundValueInfo = result;
                    console.log("get_policy_funds");
                    console.log(result);

                    if (result !== null && result !== undefined) {
                        let initial = -1;
                        let current = -1;
                        let indicator = "";
                        let newItemObject = {
                            initial_numberOfShares: -1,
                            initial_fundName: "",
                            initial_fundPrice: -1,
                            initial_currentValue: -1,
                            current_numberOfShares: -1,
                            current_fundName: "",
                            current_fundPrice: -1,
                            current_currentValue: -1,
                            indicator: ""
                        }
                        //for (let i = (result.length-1) ; i >= 0 ; i--) {
                        for (let i = 0; i < result.length; i++) {
                            let item = result[i];

                            if (item.fundName !== null && item.fundName !== undefined) {
                                if (item.description === "INITIAL") {
                                    initial = item.fundPrice;

                                    let foundMyRecord = false;
                                    let recordCounter = 0;
                                    for (let i = 0; i < fundValueInfo.length; i++) {
                                        if (fundValueInfo[i].current_fundName === item.fundName) {
                                            // console.log("("+i+") Initial " + item.fundName + " found! Current " + fundValueInfo[i].current_fundName);
                                            newItemObject = fundValueInfo[i];
                                            recordCounter = i;
                                            foundMyRecord = true;
                                            // console.log(fundValueInfo);
                                            break;
                                        }
                                    }

                                    newItemObject.initial_numberOfShares = numberWithCommas(item.numberOfShares);
                                    newItemObject.initial_fundName = removeNull(item.fundName);
                                    newItemObject.initial_fundPrice = numberWithCommas(item.fundPrice);
                                    newItemObject.initial_currentValue = item.currentValue;
                                    //object is complete and ready to push
                                    if (!foundMyRecord) {
                                        if (initial >= current) {
                                            indicator = "down";
                                        } else {
                                            indicator = "up";
                                        }
                                        newItemObject.indicator = indicator;
                                        //push this new object
                                        // console.log("Push current for C " + newItemObject.current_fundName);
                                        // console.log("Push current for I " + newItemObject.initial_fundName);
                                        // console.log(newItemObject);
                                        fundValueInfo.push(newItemObject);
                                        // console.log(fundValueInfo);
                                        //reset the object
                                        newItemObject = {
                                            initial_numberOfShares: -1,
                                            initial_fundName: "",
                                            initial_fundPrice: -1,
                                            initial_currentValue: -1,
                                            current_numberOfShares: -1,
                                            current_fundName: "",
                                            current_fundPrice: -1,
                                            current_currentValue: -1,
                                            indicator: ""
                                        };
                                    } else {
                                        // console.log("recordCounter : " + recordCounter);
                                        // console.log(fundValueInfo);
                                        fundValueInfo[recordCounter] = newItemObject;
                                        newItemObject = {};
                                    }
                                } else if (item.description === "CURRENT") {

                                    let foundMyRecord = false;
                                    let recordCounter = 0;
                                    for (let i = 0; i < fundValueInfo.length; i++) {
                                        if (fundValueInfo[i].initial_fundName === item.fundName) {
                                            console.log("(" + i + ") Current " + item.fundName + " found! Initial " + fundValueInfo[i].current_fundName);
                                            newItemObject = fundValueInfo[i];
                                            recordCounter = i;
                                            foundMyRecord = true;
                                            break;
                                        }
                                    }

                                    current = item.fundPrice;

                                    newItemObject.current_numberOfShares = numberWithCommas(item.numberOfShares);
                                    newItemObject.current_fundName = removeNull(item.fundName);
                                    newItemObject.current_fundPrice = numberWithCommas(item.fundPrice);
                                    newItemObject.current_currentValue = item.currentValue;
                                    //object is complete and ready to push
                                    if (!foundMyRecord) {
                                        if (initial >= current) {
                                            indicator = "down";
                                        } else {
                                            indicator = "up";
                                        }
                                        newItemObject.indicator = indicator;
                                        //push this new object
                                        // console.log("Push current for C " + newItemObject.current_fundName);
                                        // console.log("Push current for I " + newItemObject.initial_fundName);
                                        // console.log(newItemObject);
                                        fundValueInfo.push(newItemObject);


                                        //reset the object
                                        newItemObject = {
                                            initial_numberOfShares: -1,
                                            initial_fundName: "",
                                            initial_fundPrice: -1,
                                            initial_currentValue: -1,
                                            current_numberOfShares: -1,
                                            current_fundName: "",
                                            current_fundPrice: -1,
                                            current_currentValue: -1,
                                            indicator: ""
                                        };
                                    } else {
                                        fundValueInfo[recordCounter] = newItemObject;
                                        //fundValueInfo.push(newItemObject);
                                        newItemObject = {};
                                    }

                                    fundValueAllocationInfo.push({
                                        fundName: removeNull(item.fundName),
                                        percentage: item.percentage,
                                    })
                                }


                                fundValueInfo2.push({
                                    description: removeNull(item.fundName),
                                    numberOfShares: numberWithCommas(item.numberOfShares),
                                    fundPrice: numberWithCommas(item.fundPrice),
                                    currentValue: numberWithCommas(item.currentValue),
                                    percentage: item.percentage,
                                    indicator: indicator
                                });
                                // console.log(item.description + " indicator : " + indicator);

                            } else {
                                fundValueInfo.push({
                                    description: removeNull(item.description),
                                    numberOfShares: numberWithCommas(item.numberOfShares),
                                    fundPrice: numberWithCommas(item.fundPrice),
                                    currentValue: numberWithCommas(item.currentValue),
                                    percentage: item.percentage,

                                });
                            }

                        }
                    }
                    // console.log("!! fundValueInfo");
                    // console.log(fundValueInfo);
                    that.setState({

                        loadTable: false,
                        fundValueInfo,
                        fundValueAllocationInfo,
                        policyFundValueInfo

                    });
                })
                .catch(function (err) {
                    console.log(err);
                    that.setState({
                        loadTable: false,
                        isLoading: false,

                    });
                    that.errorMessage(myConfig.GENERIC_ERROR_MESSAGE);
                    //   resolve("Ok");

                });

            if (hardcodePolicyId) {
                policyId = policyId_benefitsentitlements;
                headerParams = { x_auth: access_token, policyId: policyId };
            }

            apiModule("get_policy_benefits_entitlement", headerParams, null)
                .then(function (result) {
                    let beneficiaryInfo = [];
                    console.log("get_policy_benefits_entitlement");
                    console.log(result);
                    if (result !== null && result !== undefined)
                        for (let i = 0; i < result.length; i++) {
                            let item = result[i];
                            console.log(result);
                            let designation = "Irrevocable";
                            if (item.isRevocable) {
                                designation = "Revocable";
                            }

                            if (item.party !== null && item.party !== undefined) {
                                beneficiaryInfo.push({
                                    name: fixName(item.party),
                                    date: prettifyDate(item.party.birthDate),
                                    age: calculateAge(item.party.birthDate),
                                    relationship: removeNull(item.relationship),
                                    priority: removeNull(item.priorityLevel),
                                    designation: removeNull(designation),
                                    share: removeNullNumber(item.percentShare),
                                });
                            } else {
                                beneficiaryInfo.push({
                                    name: `---`,
                                    date: `---`,
                                    age: `---`,
                                    relationship: removeNull(item.relationship),
                                    priority: removeNull(item.priorityLevel),
                                    designation: removeNull(designation),
                                    share: removeNullNumber(item.percentShare),
                                });
                            }
                        }

                    that.setState({

                        loadTable: false,
                        beneficiaryInfo,

                    });
                })
                .catch(function (err) {
                    console.log(err);
                    that.setState({
                        loadTable: false,
                        isLoading: false,

                    });
                    that.errorMessage(myConfig.GENERIC_ERROR_MESSAGE);
                    //   resolve("Ok");

                });

            if (hardcodePolicyId) {
                policyId = policyId_endowments;
                headerParams = { x_auth: access_token, policyId: policyId };
            }
            apiModule("get_policy_endowments", headerParams, null)
                .then(function (result) {
                    let endowmentsInfo = [];
                    console.log("get_policy_endowments");
                    console.log(result);

                    if (result !== null && result !== undefined)
                        for (let i = 0; i < result.length; i++) {
                            let item = result[i];

                            endowmentsInfo.push({
                                name: removeNull(item.bankName),
                                currency: removeNull(item.currency),
                                type: removeNull(item.bankAccountType),
                                amt: numberWithCommas(item.amount),
                                date: prettifyDate(item.endowmentDate),
                                status: removeNull(item.endowmentStatus),
                                payoutOption: removeNull(item.payoutOption),
                                endowmentStatus: removeNull(item.endowmentStatus),
                                checkNoFTDate: removeNull(item.payoutOption).toLowerCase() === 'cheque' ?
                                    removeNull(item.checkNo) :
                                    removeNull(item.payoutOption).toLowerCase() === 'fund transfer' ?
                                        removeNull(item.transactionDate) : '--'
                            });
                        }

                    that.setState({

                        loadTable: false,
                        endowmentsInfo,

                    });
                })
                .catch(function (err) {
                    console.log(err);
                    that.setState({
                        loadTable: false,
                        isLoading: false,

                    });
                    that.errorMessage(myConfig.GENERIC_ERROR_MESSAGE);
                    //   resolve("Ok");

                });

            if (hardcodePolicyId) {
                policyId = policyId_claims;
                headerParams = { x_auth: access_token, policyId: policyId };
            }
            /*
            ** Hide claims due to claims decom and prep for assure integration
            apiModule("get_policy_claims", headerParams, null)
                .then(function (result) {
                    let claimsInfo = [];
                    console.log("get_policy_claims");
                    console.log(result);

                    if (result !== null && result !== undefined)
                        for (let i = 0; i < result.length; i++) {
                            let item = result[i];

                            if (item.claimants !== null && item.claimants !== undefined) {
                                for (let i = 0; i < item.claimants.length; i++) {
                                    claimsInfo.push({
                                        claimNum: removeNull(item.claimNumber),
                                        date: prettifyDate(item.creationDateTime),
                                        status: removeNull(item.claimStatus),
                                        amt: numberWithCommas(item.refundAmount),
                                        claimantId: removeNull(item.claimants[i].claimantId),
                                        claimant: removeNull(item.claimants[i].claimant),
                                        type: removeNull(item.claimCategory),
                                        requirementsLink: item.claimants[i].requirementsLink,
                                        subClaimId: removeNull(item.subclaimId),
                                    });
                                }
                            } else {
                                claimsInfo.push({
                                    claimNum: removeNull(item.claimNumber),
                                    date: prettifyDate(item.creationDateTime),
                                    status: removeNull(item.claimStatus),
                                    amt: numberWithCommas(item.refundAmount),
                                    claimant: '',
                                    type: removeNull(item.claimCategory),
                                });
                            }

                            // claimsInfo.push({
                            //     claimNum: removeNull(item.claimNumber),
                            //     date: prettifyDate(item.creationDateTime),
                            //     status: removeNull(item.claimStatus),
                            //     amt: numberWithCommas(item.refundAmount),
                            // });
                        }

                    that.setState({

                        loadTable: false,
                        claimsInfo,

                    });
                    // MARK
                    // fetch().then(function (requirements) {

                    // });
                })
                .catch(function (err) {
                    console.log(err);
                    that.setState({
                        loadTable: false,
                        isLoading: false,

                    });
                    that.errorMessage(myConfig.GENERIC_ERROR_MESSAGE);
                    //   resolve("Ok");

                });
            */

            if (hardcodePolicyId) {
                policyId = policyId_reinstatements;
                headerParams = { x_auth: access_token, policyId: policyId };
            }
            apiModule("get_policy_reinstatements", headerParams, null)
                .then(function (result) {
                    let reinstatementInfo = [];
                    console.log("get_policy_reinstatements");
                    console.log(result);

                    if (result !== null && result !== undefined)
                        for (let i = 0; i < result.length; i++) {
                            let item = result[i];

                            if (item.validityInterval !== null && item.validityInterval !== undefined) {
                                reinstatementInfo.push({
                                    posNum: removeNull(item.transactionNumber),
                                    option: removeNull(item.reinstatementOption),
                                    status: removeNull(item.reinstatementStatus),
                                    expiryDate: prettifyDate(item.validityInterval.endDateTime),
                                    validityDate: prettifyDate(item.validityInterval.startDateTime),
                                    rsmAmt: numberWithCommas(item.amount),
                                    ablAmt: numberWithCommas(item.balance),
                                });
                            } else {
                                reinstatementInfo.push({
                                    posNum: removeNull(item.transactionNumber),
                                    option: removeNull(item.reinstatementOption),
                                    status: removeNull(item.reinstatementStatus),
                                    expiryDate: "--",
                                    validityDate: "--",
                                    rsmAmt: numberWithCommas(item.amount),
                                    ablAmt: numberWithCommas(item.balance),
                                });
                            }

                        }

                    that.setState({
                        loadTable: false,
                        reinstatementInfo,
                    });
                })
                .catch(function (err) {
                    console.log(err);
                    that.setState({
                        loadTable: false,
                        isLoading: false,
                        reinstatementInfo: [],

                    });
                    that.errorMessage(myConfig.GENERIC_ERROR_MESSAGE);
                    //   resolve("Ok");

                });

            if (hardcodePolicyId) {
                policyId = policyId_endorsements;
                headerParams = { x_auth: access_token, policyId: policyId };
            }
            apiModule("get_policy_endorsements", headerParams, null)
                .then(function (result) {
                    let endorsementInfo = [];
                    console.log("get_policy_endorsements");
                    console.log(result);

                    if (result !== null && result !== undefined)
                        for (let i = 0; i < result.length; i++) {
                            let item = result[i];
                            endorsementInfo.push({
                                posNum: removeNull(item.orderNumber),
                                endorsement: removeNull(item.type),
                                date: prettifyDate(item.inceptionDate),
                                status: removeNull(item.status),
                                user: removeNull(item.userRemarks),
                                system: replaceRemark(removeNull(item.systemRemarks)),
                                hasRequirement: item.requiredDocument === "WITH_REQUIREMENT",
                            });
                        }

                    that.setState({

                        loadTable: false,
                        endorsementInfo,

                    });
                })
                .catch(function (err) {
                    console.log(err);
                    that.setState({
                        loadTable: false,
                        isLoading: false,

                    });
                    that.errorMessage(myConfig.GENERIC_ERROR_MESSAGE);
                    //   resolve("Ok");

                });

            if (hardcodePolicyId) {
                policyId = policyId_paymenttransactions;
                headerParams = { x_auth: access_token, policyId: policyId };
            }

            apiModule("get_policy_bills", headerParams, null)
                .then(function (result) {
                    let paymentTransactionInfo = [];
                    for (let i = 0; i < result.length; i++) {
                        let item = result[i];
                        paymentTransactionInfo.push({
                            transaction: removeNull(item.invoiceNumber),
                            mode: removeNull(item.typeOfBill),
                            orDate: prettifyDate(item.expirationDate),
                            coveredFrom: prettifyDate(item.coveredFrom),
                            coveredTo: prettifyDate(item.coveredTo),
                            amt: numberWithCommas(item.billedAmount),
                            balance: numberWithCommas(item.totalCost),
                        }
                        );
                    }
                    that.setState({
                        loadTable: false,
                        paymentTransactionInfo
                    });
                }).catch(function (err) {
                    console.log(err);
                    that.setState({
                        loadTable: false,
                        isLoading: false,

                    });
                    that.errorMessage(myConfig.GENERIC_ERROR_MESSAGE);
                });

            apiModule("get_policy_paymenttransactions", headerParams, null)
                .then(function (result) {
                    let paymentTransactionInfoTransaction = [];
                    for (let i = 0; i < result.length; i++) {
                        let item = result[i];
                        paymentTransactionInfoTransaction.push({
                            details: capitalize(removeNull(item.description)),
                            remarks: removeNull(item.remarks),
                            orNumber: removeNull(item.referenceNumber),
                            mode: removeNull(item.paymentMode),
                            orDate: prettifyDate(item.paidDate),
                            amt: numberWithCommas(item.amount),
                            status: removeNull(item.paymentStatus),
                        })

                    }
                    that.setState({
                        loadTable: false,
                        paymentTransactionInfoTransaction,
                    });
                }).catch(function (err) {
                    console.log(err);
                    that.setState({
                        loadTable: false,
                        isLoading: false,

                    });
                    that.errorMessage(myConfig.GENERIC_ERROR_MESSAGE);
                });

            apiModule("get_table_of_benefits", headerParams, null)
                .then(function (result) {

                    console.log("get_table_of_benefits");
                    console.log(result);
                    let tableOfBenefits = [];
                    if (Array.isArray(result)) {
                        tableOfBenefits = result;
                    }
                    that.setState({
                        loadTable: false,
                        isLoading: false,
                        tableOfBenefits
                    });
                })
                .catch(function (err) {
                    console.log(err);
                    that.setState({
                        loadTable: false,
                        isLoading: false,

                    });
                    that.errorMessage(myConfig.GENERIC_ERROR_MESSAGE);
                    //   resolve("Ok");

                });

            apiModule("get_health_policy", headerParams, null)
                .then(function (result) {

                    console.log("get_health_policy");
                    console.log(result);
                    let healthDetails = [];
                    if (Array.isArray(result) && result.length > 0) {
                        const details = result[0];

                        healthDetails.push({
                            name: "Deductible",
                            value: details.deductibleAmount
                        })

                        healthDetails.push({
                            name: "Co-Payment",
                            value: details.coPaymentRate.toString()
                        })

                        healthDetails.push({
                            name: "Area of Coverage",
                            value: details.countryOfCostGuarantee
                        })

                        healthDetails.push({
                            name: "Classification",
                            value: details.contractCategory
                        })
                    }
                    that.setState({
                        loadTable: false,
                        isLoading: false,
                        healthDetails
                    });
                })
                .catch(function (err) {
                    console.log(err);
                    that.setState({
                        loadTable: false,
                        isLoading: false,
                    });
                    that.errorMessage(myConfig.GENERIC_ERROR_MESSAGE);
                    //   resolve("Ok");

                });
        });
    }
    showRequirements = (policyClaim) => {
        this.fetchRequirements(this.state.contractNumber, policyClaim.claimNum, policyClaim.subClaimId, policyClaim.claimantId);
        this.setState({
            showRequirement: true,
        });
    }
    handleRequirementClose = () => {
        this.setState({
            showRequirement: false,
            showEndorsementRequirements: false,
            isModalLoading: true,
        });
    }
    showError = () => {
        let message = '';
        if (this.state.error !== '') {
            message =
                <div className="bg-error py-1 px-4">
                    <p className="text-white mb-0 font-neo-semibold">ERROR: {this.state.error}</p>
                </div>
        }
        return message;
    }
    handleStartDateChange = (date) => {
        this.setState({
            startDate: date
        });
    }
    handleEndDateChange = (date) => {
        this.setState({
            endDate: date
        });
    }

    renderDefaultRow = (item, index, trClass, labelClass, valueClass) => {
        if (labelClass !== null || valueClass !== null) {
            return (
                <tr key={index} className={trClass} >
                    <td className={labelClass}>{item.name ? item.name : "-------"}</td>
                    <td className={valueClass}>{item.value ? item.value : "-------"}</td>
                </tr>
            );
        } else {
            return (
                <tr key={index}>
                    <td>{item.name ? item.name : "-------"}</td>
                    <td>{item.value ? item.value : "-------"}</td>
                </tr>
            );
        }

    }

    async fetchRequirements(contractId, claimId, subClaimId, claimantId) {
        const requestParams = {
            x_auth: this.props.getSession().access_token,
            contractId: contractId,
            claimId: claimId
        };
        const query = {
            subClaimId,
            claimantId,
        };
        const response = await apiModule('get_policy_requirements', requestParams, null, createQueryStrings(query));
        console.log('REQUIREMENTS');
        console.log(response);
        this.setState({
            requirements: response,
        });
    }

    async fetchTransactionStatement(contractId) {
        const requestParams = {
            x_auth: this.props.getSession().access_token,
            policyId: contractId,
        };
        const response = await apiModule('get_transaction_statement', requestParams);
        if(response && this.state.insuranceType === "unit-link"){
            console.log("Initial Transaction Statement Data: ", response);
            response.approvalDate = response.approvalDate ? moment(response.approvalDate).format('LL') : "";
            response.inceptionDate = response.inceptionDate ? moment(response.inceptionDate).format('LL') : "";
            response.fundTransactions.map(item => {
                item.transactionAmount = item.transactionAmount
                    ? (item.transactionAmount < 0
                        ? "(" + numberWithCommas(Math.abs(item.transactionAmount)) + ")"
                        : numberWithCommas(item.transactionAmount))
                    : item.transactionAmount;

                item.fundShare = item.fundShare
                    ? (item.fundShare < 0
                        ? "(" + numberWithCommas(Math.abs(item.fundShare)) + ")"
                        : numberWithCommas(item.fundShare))
                    : item.fundShare;

                item.balance = item.balance
                    ? (item.balance < 0
                        ? "(" + numberWithCommas(Math.abs(item.balance)) + ")"
                        : numberWithCommas(item.balance))
                    : item.balance;

                item.transactionOrderDate = item.transactionOrderDate ? moment(item.transactionOrderDate).format('LL') : "";
                item.transactionExecutionDate = item.transactionExecutionDate ? moment(item.transactionExecutionDate).format('LL') : "";
            })
            let otherinfo = this.state.otherInfo;
            otherinfo.push({
                name: "Initial Transaction Statement",
                id: "transactionStatement",
                hasModal: true,
            })
            this.setState({otherInfo: otherinfo,fundTransactionsTableData : response})
        }
        this.setState({isFundTransactionLoading: false})
        
        return response;
    }

    /************************POLICY INFORMATION************************/
    dashboardPolicyFields = () => {

        let policyFields = this.state.dashboardPolicyFieldsInfo.map((item, index) => {
            return this.renderDefaultRow(item, index, "dashboard-policy__field", "dashboard-info__label", "dashboard-info__value form-control");
        });
        return (
            <div className="dashboard-info__fields">
                {policyFields}
            </div >
        );
    }
    policyDetailFields = (css = "") => {

        let policyDetailFields = this.state.policyDetailFieldsInfo.map((item, index) => {
            return this.renderDefaultRow(item, index, "policy-detail__field pt-1", "policy-detail__label", "policy-detail__value");
        });

        return (
            <div className={`policy-detail ${css}`}>
                {policyDetailFields}
            </div>
        );
    }

    basicInfo = (css) => {

        let basicInfoRows = this.state.basicInfo.map((item, index) => {
            return this.renderDefaultRow(item, index);
        });

        let healthInfoRows = this.state.healthDetails.map((item, index) => {
            return this.renderDefaultRow(item, index);
        });

        basicInfoRows = [...basicInfoRows, healthInfoRows];

        return (
            <div className={`dashboard-info__table-body ${css}`}>
                <p className="dashboard-info__table-header font-neo-semibold mb-1">Basic Information</p>
                <table className="dashboard-info__table table table-sm table-borderless mb-0">
                    <tbody className="font-neo-semibold">
                        {basicInfoRows}
                    </tbody>
                </table>
            </div>
        );
    }

    billingInfo = (css) => {


        let billingInfoRows = this.state.billingInfo.map((item, index) => {
            return this.renderDefaultRow(item, index);
        });

        return (
            <div className={`dashboard-info__table-body ${css}`}>
                <p className="dashboard-info__table-header font-neo-semibold mb-1">Billing Information</p>
                <table className="dashboard-info__table table table-sm table-borderless mb-0">
                    <tbody className="font-neo-semibold">
                        {billingInfoRows}
                    </tbody>
                </table>
            </div>
        );
    }
    renderChecks = (items) => {
        return items.map((item, index) => {
            if (item) {
                return (<span className="icon-check"><br /></span>);
            } else {
                return (<span><br /></span>)
            }
        });
    }
    renderCheck = (item) => {
        if (item) {
            return (<span className="icon-check"><br /></span>);
        } else {
            return (<span><br /></span>)
        }

    }
    addressesRender = (items, key) => {
        if (items.length == 0) {
            return (<span>--</span>)
        }
        return items.map((item, index) => {
            return (
                <span>{item ? item : "--"} <br /></span>
            );
        });
    }
    renderRow = (items, key, index) => {

        let trClassName = key + "_force";

        if (index == 0 && this.state.insuranceType !== "health") {
            if (items[key].length == 0) {
                return (<table className="full_width"><tbody><tr><td className="address_width">--</td><td className="mailing_width"></td></tr></tbody></table>)
            } else {
                let contents = items[key].map((item, index) => {
                    return (
                        <tr className={{ trClassName }}>
                            <td className="address_width ">{item ? item : "--"} </td>
                            <td className="mailing_width">{this.renderCheck(items[key + '_mailing'][index])}</td>
                        </tr>
                    );
                });

                return (
                    <table className="full_width">
                        <tbody>
                            {contents}
                        </tbody>
                    </table>
                )
            }

        } else {
            if (items[key].length == 0) {
                return (<table className="full_width"><tbody><tr><td className="address_width_two">--</td><td className="mailing_width_two"></td><td className="mailing_width_two"></td></tr></tbody></table>)
            } else {
                let contents = items[key].map((item, index) => {
                    return (
                        <tr className={{ trClassName }}>
                            <td className="address_width_two">{item ? item : "--"} </td>
                            <td className="mailing_width_two">{this.renderCheck(items[key + '_mailing'][index])}</td>
                            <td className="mailing_width_two">{this.renderCheck(items[key + '_billing'][index])}</td>
                        </tr>
                    );
                });

                return (
                    <table className="full_width">
                        <tbody>
                            {contents}
                        </tbody>
                    </table>
                )
            }
        }

    }
    getModalTable = (index) => {
        if (index == 0 && this.state.insuranceType !== "health") {
            return (
                <tbody>
                    <tr>
                        <td className="address_width"></td>
                        <td className="mailing_width">Mailing<br />Address</td>

                    </tr>
                </tbody>
            );
        } else {
            return (
                <tbody>
                    <tr>
                        <td className="address_width_two"></td>
                        <td className="mailing_width_two px-1">Mailing<br />Address</td>
                        <td className="mailing_width_two px-1">Billing<br />Address</td>
                    </tr>
                </tbody>
            );
        }
    }
    insuredInfo = (css = "", mobile = "") => {
        let that = this;
        let customName = {
            name: ["Insured  Name", "Owner Name"],
            gender: "Gender",
            nationality: "Nationality",
            // sss: "SSS/GSIS No.",
            home: "Home Address",
            work: "Work Address",
            contact: "Contact Number",
            email: "E-mail Address",
            contingentOwner: "Contingent Owner",
            relToOwner: "Relationship to Owner",
            modalPremium: "Modal Premium",
            annualPremium: "Annual Premium",
            dateOfBirth: "Date of Birth",
            civilStatus: "Civil Status",
            age: "Issue Age"

        };
        const idModals = [mobile + 'insuredModal', mobile + 'appModal'];
        console.log(this.state.insuredInfo);
        let insuredInfoRows = this.state.insuredInfo.map((item, index) => {
            const id = this.state.insuranceType === "health" ? mobile + 'insuredHealthModal' + index : idModals[index];
            return (
                <Auxi key={index}>
                    <tr>
                        {
                            this.state.insuranceType === "health" ?
                                <td className="w-30">{item.memberCardNumber}</td> :
                                <td className="w-30">{customName.name[index] ? customName.name[index] : "-------"}</td>
                        }

                        <td className="w-20">
                            {item.name ? item.name : "-------"}
                            <button type="button" className="btn btn-dropdowncolor font-neo-semibold policy-info__button d-block d-md-none w-100" data-toggle="modal" data-target={`#${id}`}>View Details</button>
                        </td>
                        <td className="d-none d-md-block w-md-50">
                            <button type="button" className="btn btn-dropdowncolor font-neo-semibold policy-info__button" data-toggle="modal" data-target={`#${id}`}>View Details</button>
                        </td>
                    </tr>
                </Auxi>
            );
        });

        let insuredModals = this.state.insuredInfo.map((item, index) => {
            const id = this.state.insuranceType === "health" ? mobile + 'insuredHealthModal' + index : idModals[index];
            const owner = this.state.insuranceType === "health" ? "Member Name" : customName.name[index];
            return (
                <Auxi key={index}>
                    <div className="modal policy-modal fade" id={id} tabIndex="-1" role="dialog">
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content policy-modal__content mx-1 p-md-2 border-0">
                                <button type="button" className="policy-modal__close" data-dismiss="modal">
                                    <span>&#x2715;</span>
                                </button>
                                <div className="modal-body table-modal font-neo-semibold">
                                    <table className="table table-borderless table-sm insured-modal">
                                        <tbody>
                                            <tr className="table-modal-row">
                                                <td>{owner}</td>
                                                <td>{item.name ? item.name : "-------"}</td>
                                            </tr>
                                            <tr className="table-modal-row">
                                                <td>{customName.gender}</td>
                                                <td>{item.gender}</td>
                                            </tr>
                                            <tr className="table-modal-row">
                                                <td>{customName.nationality}</td>
                                                <td>{item.nationality}</td>
                                            </tr>
                                            {
                                                this.state.insuranceType === "health" &&
                                                <>
                                                    <tr className="table-modal-row">
                                                        <td>&nbsp;</td>
                                                        <td>&nbsp;</td>
                                                    </tr>
                                                    {
                                                        item.contingentOwner ?
                                                            <tr className="table-modal-row">
                                                                <td>{customName.contingentOwner}</td>
                                                                <td>{item.contingentOwner}</td>
                                                            </tr> :
                                                            <tr className="table-modal-row">
                                                                <td>{customName.relToOwner}</td>
                                                                <td>{item.relToOwner}</td>
                                                            </tr>
                                                    }
                                                    <tr className="table-modal-row">
                                                        <td>{customName.modalPremium}</td>
                                                        <td>{item.modalPremium}</td>
                                                    </tr>
                                                    <tr className="table-modal-row">
                                                        <td>{customName.annualPremium}</td>
                                                        <td>{item.annualPremium}</td>
                                                    </tr>
                                                    <tr className="table-modal-row">
                                                        <td>{customName.dateOfBirth}</td>
                                                        <td>{item.dateOfBirth}</td>
                                                    </tr>
                                                    <tr className="table-modal-row">
                                                        <td>{customName.civilStatus}</td>
                                                        <td>{item.civilStatus}</td>
                                                    </tr>
                                                    <tr className="table-modal-row">
                                                        <td>{customName.age}</td>
                                                        <td>{item.age}</td>
                                                    </tr>
                                                </>
                                            }
                                            {/* <tr>
                                                <td>{customName.sss}</td>
                                                <td>{item.sss}</td>
                                            </tr> */}
                                        </tbody>
                                    </table>

                                    <table className="table table-borderless table-sm policy-modal__contact-info">
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>
                                                    <table className="full_width">
                                                        {this.getModalTable(index)}
                                                    </table>
                                                </th>
                                                {/* <th scope="col">Billing Address</th> */}
                                                {/* <th scope="col">Mailing Address</th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className="table-modal-row">
                                                <td>{customName.home}</td>
                                                {/* <td>{this.addressesRender(item.home)}</td> */}
                                                <td>{this.renderRow(item, 'home', index)}</td>

                                                {/* <td>{that.renderCheck(item.home_billing)}</td> */}
                                                {/* <td>{this.renderCheck(item.home_mailing)}</td> */}
                                            </tr>
                                            <tr className="table-modal-row">
                                                <td>{customName.work}</td>
                                                <td>{this.renderRow(item, 'work', index)}</td>
                                                {/* <td>{that.renderCheck(item.work_billing)}</td> */}
                                                {/* <td>{this.renderCheck(item.work_mailing)}</td> */}
                                            </tr>
                                            <tr className="table-modal-row">
                                                <td>{customName.contact}</td>
                                                <td>{item.contact}</td>
                                                {/* <td></td> */}
                                                <td></td>
                                            </tr>
                                            <tr className="table-modal-row">
                                                <td>{customName.email}</td>
                                                <td>{item.email}</td>
                                                {/* <td></td> */}
                                                <td></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </Auxi>
            );
        });
        const title = this.state.insuranceType === 'health' ? "Member" : "Application Owner and Proposed Insured";
        return (
            <Auxi>
                <div className={`dashboard-info__table-body d-flex`}>
                    <div className={`${css}`}>
                        <p className="dashboard-info__table-header font-neo-semibold mb-1">{title} Information</p>
                        <table className={`dashboard-info__table dashboard-info__table--td-clear table table-sm table-borderless mb-0`}>
                            {
                                this.state.insuranceType === 'health' &&
                                <thead className="font-neo-semibold">
                                    <tr>
                                        <td>Member Card No.</td>
                                        <td>Member Card Name</td>
                                    </tr>
                                </thead>
                            }
                            <tbody className="font-neo-semibold">
                                {insuredInfoRows}
                            </tbody>
                        </table>
                    </div>
                    {/* <div className="d-flex flex-column justify-content-end align-items-start w-50 pl-5">
                        <button type="button" className="btn btn-dropdowncolor font-neo-semibold policy-info__button" data-toggle="modal" data-target={`#${mobile}insuredModal`}>View Details</button>
                        <button type="button" className="btn btn-dropdowncolor font-neo-semibold policy-info__button" data-toggle="modal" data-target={`#${mobile}appModal`}>View Details</button>
                    </div> */}
                </div>
                {insuredModals}
            </Auxi>
        );
    }
    otherInfo = (css, mobile="") => {
        let infoModals = [];

        let otherInfoRender = this.state.otherInfo.map((item, index) => {
            if (item.hasModal) {
                infoModals = [...infoModals, 
                    this.otherInfoModals(item,mobile)
                ];
            }
            else if (item.hasPdfModal) {
                infoModals = [...infoModals, this.otherInfoPdfModals(item, mobile)];
            }
            else {
                return this.renderDefaultRow(item, index);
            }
        });

        return (
            <Fragment>
                <div className={`dashboard-info__table-body ${css}`}>
                    <p className="dashboard-info__table-header font-neo-semibold mb-1">Other Information</p>
                    {
                        this.state.insuranceType !== "health" &&
                        <table className="dashboard-info__table table table-sm table-borderless">
                            <tbody className="font-neo-semibold">
                                <Auxi key="otherInfoRender">
                                    {otherInfoRender}
                                </Auxi>
                            </tbody>
                        </table>
                    }
                </div>

                {/* Table rows with modals */}
                {
                    infoModals.length ?
                    <table className="dashboard-info__table table table-sm table-borderless dashboard-info__table--td-clear">
                        <tbody className="font-neo-semibold">
                            {infoModals}
                        </tbody>
                    </table> : ""
                }

            </Fragment>
        );
    }

    policyValue = (css = "", mobile = "") => {


        let customName = {
            basicCash: "Cash Value (Basic)",
            basicDate: "Cash Value (Basic) as of Date",
            riderCash: "Cash Value (Rider)",
            riderDate: "Cash Value (Rider) as of Date",
            dividend: 'Dividends',
            option: "Dividend Option",
            apdBal: "Advance Premium Deposit Balance",
            apdDate: "Advance Premium Deposit Balance as of Date",
            //apdBalDate: "Advance Premium Deposit Balance as of Date",
            suspenseAmt: "Suspense Amount",
            ffrBal: "Flexi Fund Rider Fund Balance",
            ffrDate: "Flexi Fund Rider Fund Balance as of Date",
            prdBal: 'Premium Deposit Balance',
            prdDate: 'Premium Deposit Balance as of Date',
            aplBal: 'Automatic Premium Loan Balance',
            plBal: "Policy Loan Balance",
        };

        let policyValueRows = this.state.policyValueInfo.map((item, index) => {
            return (
                <Auxi key={index}>
                    <tr>
                        <td>{customName.basicCash}</td>
                        <td>{item.basicCash}</td>
                    </tr>
                    <tr>
                        <td>{customName.basicDate}</td>
                        <td>{item.basicDate}</td>
                    </tr>
                    <tr>
                        <td>{customName.riderCash}</td>
                        <td>{item.riderCash}</td>
                    </tr>
                    <tr>
                        <td>{customName.riderDate}</td>
                        <td>{item.riderDate}</td>
                    </tr>
                    <tr className="h-2rem"></tr>
                    <tr>
                        <td>{customName.dividend}</td>
                        <td>{item.dividend ? item.dividend : '--'}
                            <button type="button" data-toggle="modal" data-target={`#${mobile}transactionModal`} className="btn btn-dropdowncolor policy-value__button font-neo-semibold policy-info__button d-block d-md-none ml-0 w-100">View Transactions</button>
                        </td>
                        <td>
                            <button type="button" data-toggle="modal" data-target={`#transactionModal`} className="btn btn-dropdowncolor policy-value__button font-neo-semibold policy-info__button">View Transactions</button>
                        </td>
                    </tr>
                    <tr>
                        <td>{customName.option}</td>
                        <td>{item.option}</td>
                    </tr>
                    <tr className="h-2rem"></tr>
                    <tr>
                        <td>{customName.apdBal}</td>
                        <td>{item.apdBal}</td>
                    </tr>
                    <tr>
                        <td>{customName.apdDate}</td>
                        <td>{item.apdDate}</td>
                    </tr>
                    <tr>
                        <td>{customName.suspenseAmt}</td>
                        <td>{item.suspenseAmt}</td>
                    </tr>
                    <tr>
                        <td>{customName.ffrBal}</td>
                        <td>{item.ffrBal}</td>
                    </tr>
                    <tr>
                        <td>{customName.ffrDate}</td>
                        <td>{item.ffrDate}</td>
                    </tr>
                    <tr>
                        <td>{customName.prdBal}</td>
                        <td>{item.prdBal}</td>
                    </tr>
                    <tr>
                        <td>{customName.prdDate}</td>
                        <td>{item.prdDate}</td>
                    </tr>
                    <tr>
                        <td>{customName.aplBal}</td>
                        <td>{item.aplBal}</td>
                    </tr>
                    <tr>
                        <td>{customName.plBal}</td>
                        <td>{item.plBal}</td>
                    </tr>
                </Auxi>
            );
        });

        return (
            <Auxi>
                <div className={`dashboard-info__table-body d-flex`}>
                    <div className={`${css}`}>
                        <table className={`dashboard-value__table dashboard-info__table table table-sm table-borderless mb-0`}>
                            <tbody className="font-neo-semibold">
                                {policyValueRows}
                            </tbody>
                        </table>
                    </div>
                </div>
            </Auxi >
        );
    }

    otherInfoModals = (item, mobile) => {
        console.log(item);
        return (
            <tr key={`infoModals-${item.id}`}>
                <td className="w-30">{item.name ? item.name : "-------"}</td>
                <td className="w-20 bg-white">
                    <button type="button" className="btn btn-dropdowncolor font-neo-semibold policy-info__button d-block d-md-none w-100" data-toggle="modal" data-target={`#mobile-${item.id}Modal`}>View Details</button>
                    <Fragment>
                        <div className="modal policy-modal fade" id={`${mobile+item.id}Modal`} tabIndex="-1" role="dialog">
                            <div className="modal-dialog modal-dialog-centered" role="document">
                                <div className="modal-content policy-modal__content mx-1 p-md-2 border-0">
                                    <button type="button" className="policy-modal__close" data-dismiss="modal">
                                        <span>&#x2715;</span>
                                    </button>
                                    <div className="modal-body font-neo-semibold">
                                        {item.id === "transactionStatement" && <TransactionStatement data={this.state.fundTransactionsTableData} />}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fragment>
                </td>
                <td className="d-none d-md-block w-md-50 clear-cell">
                    <button type="button" className="btn btn-dropdowncolor font-neo-semibold policy-info__button" data-toggle="modal" data-target={`#${item.id}Modal`}>View Details</button>
                    <Fragment>
                        <div className="modal policy-modal fade" id={`${item.id}Modal`} tabIndex="-1" role="dialog">
                            <div className="modal-dialog modal-dialog-centered" role="document">
                                <div className="modal-content policy-modal__content mx-1 p-md-2 border-0">
                                    <button type="button" className="policy-modal__close" data-dismiss="modal">
                                        <span>&#x2715;</span>
                                    </button>
                                    <div className="modal-body font-neo-semibold">
                                        {item.id === "transactionStatement" && <TransactionStatement data={this.state.fundTransactionsTableData} />}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fragment>
                </td>
            </tr>
        )
    }

    otherInfoPdfModals = (item, mobile) => {
        return (
            <tr key={`infoModals-${item.id}`}>
                <td className="w-30">{item.name ? item.name : "-------"}</td>
                <td className="w-20 bg-white">
                    <button 
                        type="button" 
                        className="btn btn-dropdowncolor font-neo-semibold policy-info__button d-block d-md-none w-100" 
                        onClick={() => { this.setState({ showOtherInfoPdfModal: !this.state.showOtherInfoPdfModal, selectedOtherInfoPdfModal: item.id}) }}
                    >
                        View Details
                    </button>              
                </td>
                <td className="d-none d-md-block w-md-50 clear-cell">
                    <button 
                        type="button" 
                        className="btn btn-dropdowncolor font-neo-semibold policy-info__button" 
                        onClick={() => { this.setState({ showOtherInfoPdfModal: !this.state.showOtherInfoPdfModal, selectedOtherInfoPdfModal: item.id }) }}
                    >
                        View Details
                    </button>
                </td>
            </tr>
        );
    }

    transactionModal = (css = "", mobile = "") => {
        let columns = ["Debit/Credit", "Currency", "Amount"];

        let transactionColumns = columns.map((item, index) => {
            return (
                <th key={index}>{item}</th>
            );
        });

        let transactionRows = this.state.policyDividends.map((item, index) => {
            return (
                <tr key={index}>
                    <td>{item.payOutAmount > 0 ? 'Credit' : 'Debit'}</td>
                    <td>{item.currency}</td>
                    <td>{item.payOutAmount}</td>
                </tr>
            );
        });
        if (this.state.policyDividends !== null && this.state.policyDividends !== undefined && this.state.policyDividends.length > 0) {
            return (
                <div className={`${css} modal fade`} id={`${mobile}transactionModal`} tabIndex="-1" role="dialog">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content mx-1 border-0">
                            <button type="button" className="policy-modal__close" data-dismiss="modal">
                                <span>&#x2715;</span>
                            </button>
                            <div className="modal-body">
                                <div className="">
                                    <table className="table coverage-table-row coverage-table-bordered coverage-table-striped coverage-table-header table-borderless mb-0">
                                        <thead>
                                            <tr>
                                                {transactionColumns}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {transactionRows}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className={`${css} modal fade`} id={`${mobile}transactionModal`} tabIndex="-1" role="dialog">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content mx-1 border-0">
                            <button type="button" className="policy-modal__close" data-dismiss="modal">
                                <span>&#x2715;</span>
                            </button>
                            <div className="modal-body">
                                <div className="">
                                    <span>No information to be displayed</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
    linkedPlans = (css) => {
        let columns = ["Rider Name", "Years Payable", "Months Payable", "Expiry Date", "Face Amount", "Premium"];

        let linkedPlansColumns = columns.map((item, index) => {
            return (
                <th key={index}>{item}</th>
            );
        });
        let linkedPlansRows = this.state.coverageInfo.map((item, index) => {
            return (
                <tr key={index}>
                    <td>{item.riderName}</td>
                    <td>{`${item.year}`}</td>
                    <td>{`${item.month}`}</td>
                    <td>{`${item.expiry}`}</td>
                    <td>{item.amt}</td>
                    <td>{item.premium ? item.premium : '--'}</td>
                </tr>
            );
        });

        if (this.state.insuranceType === 'health') {
            return (
                <div className={`${css}`}>
                    <div className="policy__table-container">
                        <span className="dashboard-row-data font-neo-semibold center-align">Coverage and Rider Not Yet Available</span>
                    </div>
                </div>
            )
        }
        return (
            <div className={`${css}`}>
                <h6 className="coverage-header text-headercolor font-neo-bold">For Unit-Linked Plans</h6>
                <div className="policy__table-container">
                    <table className="table coverage-table-row coverage-table-bordered coverage-table-striped coverage-table-header table-borderless mb-0">
                        <thead>
                            <tr>
                                {linkedPlansColumns}
                            </tr>
                        </thead>
                        <tbody>
                            {linkedPlansRows}
                        </tbody>
                    </table>
                </div>
            </div>

        );
    }

    traditionalPlans = (css) => {
        let columns = ["Rider Name", "Years Payable", "Months Payable", "Expiry Date", "Status", "Status Date", "Face Amount", "Premium"];

        let traditionalPlansColumns = columns.map((item, index) => {
            return (
                <th key={index}>{item}</th>
            );
        });
        let traditionalPlansRows = this.state.coverageInfo.map((item, index) => {
            return (
                <tr key={index}>
                    <td>{item.riderName}</td>
                    <td>{`${item.year}`}</td>
                    <td>{`${item.month}`}</td>
                    <td>{`${item.expiry}`}</td>
                    <td>{item.status}</td>
                    <td>{item.date}</td>
                    <td>{item.amt}</td>
                    <td>{item.premium ? item.premium : '--'}</td>
                </tr>
            );
        });

        return (
            <div className={`${css}`}>
                <br />
                <h6 className="coverage-header text-headercolor font-neo-bold">For Traditional Plans</h6>
                <div className="policy__table-container">
                    <table className="table coverage-table-row coverage-table-bordered coverage-table-striped coverage-table-header table-borderless mb-0">
                        <thead>
                            <tr>
                                {traditionalPlansColumns}
                            </tr>
                        </thead>
                        <tbody>
                            {traditionalPlansRows}
                        </tbody>
                    </table>
                </div>
            </div>

        );
    }
    beneficiary = (css) => {
        let columns = ["Beneficiary Name", "Date of Birth", "Age", "Relationship", "Priority", "Designation", "% Share"];

        let beneficiaryColumns = columns.map((item, index) => {
            return (
                <th key={index}>{item}</th>
            );
        });
        let beneficiaryRows = this.state.beneficiaryInfo.map((item, index) => {
            return (
                <tr key={index}>
                    <td>{item.name}</td>
                    <td>{item.date}</td>
                    <td>{item.age}</td>
                    <td>{item.relationship}</td>
                    <td>{item.priority}</td>
                    <td>{item.designation}</td>
                    <td>{item.share}</td>
                </tr>
            );
        });
        if (this.state.beneficiaryInfo === null || this.state.beneficiaryInfo.length === 0) {
            return (
                <div className={`${css}`}>
                    <div className="policy__table-container">
                        <span className="dashboard-row-data font-neo-semibold center-align">No information to be displayed</span>
                    </div>
                </div>
            )
        } else {
            return (
                <div className={`${css}`}>
                    {/* <h6 className="coverage-header text-headercolor font-neo-bold">For Unit-Linked Plans</h6> */}
                    <div className="policy__table-container">
                        <table className="table coverage-table-row coverage-table-bordered coverage-table-striped coverage-table-header table-borderless mb-0">
                            <thead>
                                <tr>
                                    {beneficiaryColumns}
                                </tr>
                            </thead>
                            <tbody>
                                {beneficiaryRows}
                            </tbody>
                        </table>
                    </div>

                </div>

            );
        }
    }
    fundValue = (css) => {
        let columns = ["Fund Name", "Number of Units", "Unit Price", "Fund Value", "Number of Units", "Unit Price", "Fund Value", ""];

        let loanColumns = columns.map((item, index) => {
            if (item === "Number of Units") {
                return (
                    <th className="left-box-line" key={index}>{item}</th>
                );
            } else if (item === "Fund Value") {
                return (
                    <th className="right-box-line" key={index}>{item}</th>
                );
            } else {
                return (
                    <th key={index}>{item}</th>
                );
            }

        });

        let allocationColumns = ["Fund Name", "Percentage Allocation"];

        let allocationLoanColumns = allocationColumns.map((item, index) => {
            return (
                <th key={"allocation_column_" + index}>{item}</th>
            );
        });


        let allocationRows = this.state.fundValueAllocationInfo.map((item, index) => {
            return (
                <tr key={"allocation_" + index}>
                    <td>{item.fundName}</td>
                    <td>{item.percentage}</td>
                </tr>
            );
        })

        let totalInitialValue = 0;
        let totalCurrentValue = 0;
        let fundDateAsOf = "";

        console.log("!!!!!!! ---- this.state.fundValueInfo  ----- !!!!!!!");
        console.log(this.state.fundValueInfo);
        
        if (this.state.policyFundValueInfo.length !== 0) {
            fundDateAsOf = this.state.policyFundValueInfo.reduce((a, b) => {
                if (a.fundDate === null || b.fundDate === null) {
                    return a.fundDate === null ? b : a ;
                }
                return new Date(b.fundDate) > new Date(a.fundDate) ? b : a;
            });
        }
        fundDateAsOf = fundDateAsOf.fundDate;
        if (fundDateAsOf !== null && fundDateAsOf !== undefined) {
            fundDateAsOf = format(new Date(fundDateAsOf), 'MM/DD/YYYY')
        } 

        let loanRows = this.state.fundValueInfo.map((item, index) => {
            if (item.indicator !== null && item.indicator !== undefined) {
                if (item.indicator !== "") {
                    // let spanClassName = "arrow-" + item.indicator;
                    // let className = "fa icon-arrow-" + item.indicator;
                    let indicator = "down";
                    if (item.initial_fundPrice < item.current_fundPrice) {
                        indicator = "up";
                    }
                    let arrowIndicator = indicator == "up" ? <ArrowDropUp className="text-success" /> : <ArrowDropDown className="text-danger" />;
                    totalInitialValue += parseFloat(item.initial_currentValue);
                    totalCurrentValue += parseFloat(item.current_currentValue);
                    return (
                        <tr key={index}>
                            <td>{item.initial_fundName}</td>
                            <td className="left-box-line">{item.initial_numberOfShares}</td>
                            <td className="">{item.initial_fundPrice}</td>
                            <td className="right-box-line">{numberWithCommas(item.initial_currentValue)}</td>
                            <td className="left-box-line">{item.current_numberOfShares}</td>
                            <td className="">{item.current_fundPrice}</td>
                            <td className="right-box-line">{numberWithCommas(item.current_currentValue)}</td>
                            <td>
                                {/* <span className={spanClassName}><i className={className} aria-hidden="true"></i></span> */}
                                {arrowIndicator}
                            </td>
                        </tr>
                    );
                } else {
                    return (
                        <tr key={index}>
                            <td>{item.description}</td>
                            <td>{item.numberOfShares}</td>
                            <td>{item.fundPrice}</td>
                            <td>{item.currentValue}</td>
                            <td>{item.percentage}</td>
                            <td></td>
                        </tr>
                    );
                }
            } else {
                return (
                    <tr key={index}>
                        <td>{item.description}</td>
                        <td>{item.numberOfShares}</td>
                        <td>{item.fundPrice}</td>
                        <td>{item.currentValue}</td>
                        <td>{item.percentage}</td>
                    </tr>
                );
            }

        });

        if (this.state.fundValueInfo.length === 0) {
            return (
                <div className={`${css}`}>
                    <div class="policy__table-container"><span class="dashboard-row-data font-neo-semibold center-align">No information to be displayed</span></div>
                </div>

            );
        } else {
            let indicator = ""
            if (totalInitialValue >= totalCurrentValue) {
                indicator = "down";
            } else {
                indicator = "up";
            }
            // let spanClassName = "arrow-" + indicator;
            // let className = "fa icon-arrow-" + indicator;
            let arrowIndicator = indicator == "up" ? <ArrowDropUp className="text-success" /> : <ArrowDropDown className="text-danger" />;
            return (
                <div className={`${css}`}>
                    {/* <h6 className="coverage-header text-headercolor font-neo-bold">For Unit-Linked Plans</h6> */}

                    <div className="policy__table-container">
                        <table className="table coverage-table-row coverage-table-bordered coverage-table-striped coverage-table-header table-borderless mb-0">
                            <thead>
                                <tr>
                                    {allocationLoanColumns}
                                </tr>
                            </thead>
                            <tbody>
                                {allocationRows}

                            </tbody>
                        </table>
                    </div>
                    <br />
                    <div className="policy__table-container">
                        <table className="table coverage-table-row coverage-table-bordered coverage-table-striped coverage-table-header table-borderless mb-0">
                            <thead>
                                {fundDateAsOf !== null ?
                                <tr>
                                    <td></td>
                                    <td colSpan={6}><strong>Fund Value as of {fundDateAsOf}</strong></td>
                                    <td></td>
                                </tr>
                                : <></>}
                                <tr>
                                    <td></td>
                                    <td colSpan={3} className="fund_value_header upper-box-line">INITIAL</td>
                                    <td colSpan={3} className="fund_value_header upper-box-line">CURRENT</td>
                                    <td></td>

                                </tr>
                                <tr>
                                    {loanColumns}
                                </tr>
                            </thead>
                            <tbody>

                                {loanRows}
                                <tr>
                                    <td><strong>Total</strong></td>
                                    <td className="lower-box-line left-box-line"></td>
                                    <td className="lower-box-line"></td>
                                    <td className="lower-box-line right-box-line"></td>
                                    <td className="lower-box-line left-box-line"></td>
                                    <td className="lower-box-line"></td>
                                    <td className="lower-box-line right-box-line"><strong>{numberWithCommas(totalCurrentValue)}</strong></td>
                                    <td>
                                        {/* {arrowIndicator} */}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div >

            );
        }

    }

    //if(this.state.insuranceType === "unit-link")
    fundTransactions = (css) => {
        let columns = ["No", "Transaction Date", "Transaction Type", "Pricing Date", "Allocation Amount", "Unit Price", "Units", "Running Balance"];

        let loanColumns = columns.map((item, index) => {
            return (
                <th key={index}>{item}</th>
            );
        });
        let loanRows = this.state.fundTransactionInfo.map((item, index) => {
            return (
                <tr key={index}>
                    <td>{item.No}</td>
                    <td>{item.TransactionDate}</td>
                    <td>{item.TransactionType}</td>
                    <td>{item.PricingDate}</td>
                    <td>{item.AllocationAmount}</td>
                    <td>{item.UnitPrice}</td>
                    <td>{item.Units}</td>
                    <td>{item.RunningBalance}</td>
                </tr>
            );
        });

        if (this.state.fundTransactionInfo === null || this.state.fundTransactionInfo.length === 0) {
            return (
                <div className={`${css}`}>
                    <div className="policy__table-container">
                        <span className="dashboard-row-data font-neo-semibold center-align">No information to be displayed</span>
                    </div>
                </div>
            )
        } else {
            return (
                <div className={`${css}`}>
                    {/* <h6 className="coverage-header text-headercolor font-neo-bold">For Unit-Linked Plans</h6> */}
                    <div className="policy__table-container">
                        <table className="table coverage-table-row coverage-table-bordered coverage-table-striped coverage-table-header table-borderless mb-0">
                            <thead>
                                <tr>
                                    {loanColumns}
                                </tr>
                            </thead>
                            <tbody>
                                {loanRows}
                            </tbody>
                        </table>
                    </div>
                </div>

            );
        }
    }

    dividends = (css) => {
        return (
            <Dividends css={css} access_token={this.props.getSession().access_token} policyId={this.state.policyId} />
        )
    }

    policyLoan = (css) => {
        let columns = ["Summary of Payments", "Outstanding Loan", "Loan Tenure", "Basic", "Interest", "Total", "Origin of Loan"];

        let loanColumns = columns.map((item, index) => {
            return (
                <th key={index}>{item}</th>
            );
        });
        let loanRows = this.state.policyLoanInfo.map((item, index) => {
            if (!isManager(this.props.getSession().role)) {
                return (
                    <tr key={index}>
                        <td>{item.summary}</td>
                        <td>{item.outLoan}</td>
                        <td>{item.tenLoan}</td>
                        <td>{item.basic}</td>
                        <td>{item.interest}</td>
                        <td>{item.total}</td>
                        <td>{item.origin ? item.origin : '--'}</td>
                    </tr>
                );
            }
        });

        if (this.state.policyLoanInfo === null || this.state.policyLoanInfo.length === 0) {
            return (
                <div className={`${css}`}>
                    <div className="policy__table-container">
                        <span className="dashboard-row-data font-neo-semibold center-align">No information to be displayed</span>
                    </div>
                </div>
            )
        } else {
            return (
                <div className={`${css}`}>
                    {/* <h6 className="coverage-header text-headercolor font-neo-bold">For Unit-Linked Plans</h6> */}
                    <div className="policy__table-container">
                        <table className="table coverage-table-row coverage-table-bordered coverage-table-striped coverage-table-header table-borderless mb-0">
                            <thead>
                                <tr>
                                    {loanColumns}
                                </tr>
                            </thead>
                            <tbody>
                                {loanRows}
                            </tbody>
                        </table>
                    </div>
                </div>

            );
        }
    }
    endowments = (css) => {
        let columns = ["Bank Name", "Account Currency", "Type of Account", "Endowment Payout Status", "Amount of Endowment", "Endowment Date", "Payout Option", "Cheque Number/\nFund Transfer Date"];

        let endowmentColumns = columns.map((item, index) => {
            return (
                <th key={index}>{item}</th>
            );
        });
        let endowmentRows = this.state.endowmentsInfo.map((item, index) => {
            return (
                <tr key={index}>
                    <td>{item.name}</td>
                    <td>{item.currency}</td>
                    <td>{item.type ? item.type : '-------'}</td>
                    <td>{item.endowmentStatus ? item.endowmentStatus : '-------'}</td>
                    <td>{item.amt}</td>
                    <td>{item.date}</td>
                    <td>{item.payoutOption ? item.payoutOption : '-------'}</td>
                    <td>{item.checkNoFTDate ? item.checkNoFTDate : '-------'}</td>
                </tr>
            );
        });
        const noteStyle = {
            fontSize: ".9em",
            color: "#255f8e",
        };
        const noteMessage = "Note: For Cheque availability, kindly give us an additional 5 to 7 business days from the displayed date below. For Fund Transfer, kindly give us an additional 1 to 2 business days for the actual posting in the client's account.";

        if (this.state.endowmentsInfo === null || this.state.endowmentsInfo.length === 0) {
            return (
                <div className={`${css}`}>
                    <div className="policy__table-container">
                        <span className="dashboard-row-data font-neo-semibold center-align">No endowment yet</span>
                    </div>
                </div>
            )
        } else {
            return (
                <div className={`${css}`}>
                    {/* <h6 className="coverage-header text-headercolor font-neo-bold">For Unit-Linked Plans</h6> */}
                    <p className="font-italic" style={noteStyle}>{noteMessage}</p>
                    <div className="policy__table-container">
                        <table className="table coverage-table-row coverage-table-bordered coverage-table-striped coverage-table-header table-borderless mb-0">
                            <thead>
                                <tr>
                                    {endowmentColumns}
                                </tr>
                            </thead>
                            <tbody>
                                {endowmentRows}
                            </tbody>
                        </table>
                    </div>
                </div>

            );
        }
    }

    surrender = (css) => {
        return (
            <Surrender css={css} access_token={this.props.getSession().access_token} policyId={this.state.policyId} />
        )
    }
    /************************END POLICY INFORMATION************************/

    claims = (css) => {
        const isUserManager = isManager(this.props.getSession().role);

        let columns;
        if (isUserManager) {
            columns = ["Claimant", "Type", "Requirements"];
        }
        else {
            columns = ["Claim Number", "Claimant", "Status", "File Date", "Claim Amount", "Type", "Requirements"];
        }

        let claimsColumns = columns.map((item, index) => {
            return (
                <th key={index}>{item}</th>
            );
        });

        let claimsRows = this.state.claimsInfo.map((item, index) => {
            let row;
            if (isUserManager) {
                row = (
                    <tr key={index}>
                        <td>{item.claimant ? item.claimant : '--'}</td>
                        <td>{item.type ? item.type : '--'}</td>
                        <td>{item.requirements ? 'View' : '--'}</td>
                    </tr>
                );
            }
            else {
                let viewButton = (
                    <a className="dashboard-datatable__button btn rounded-pill btn-dropdowncolor text-white"
                        style={{ minWidth: "60px" }}
                        onClick={() => { this.showRequirements(item) }}
                    >
                        View
                    </a>
                );

                row = (
                    <tr key={index}>
                        <td>{item.claimNum ? item.claimNum : '--'}</td>
                        <td>{item.claimant ? item.claimant : '--'}</td>
                        <td>{item.status ? <Status status={item.status} /> : '--'}</td>
                        <td>{item.date}</td>
                        <td>{item.amt ? item.amt : '--'}</td>
                        <td>{item.type ? item.type : '--'}</td>
                        <td>{item.requirementsLink ? viewButton : '--'}</td>
                    </tr>
                );
            }
            return row;
        });
        if (this.state.insuranceType === 'health') {
            return (
                <div className={`${css}`}>
                    <div className="policy__table-container">
                        <span className="dashboard-row-data font-neo-semibold center-align">Claims History Not Yet Available</span>
                    </div>
                </div>
            )
        }
        else if (this.state.claimsInfo === null || this.state.claimsInfo.length === 0) {
            return (
                <div className={`${css}`}>
                    <div className="policy__table-container">
                        <span className="dashboard-row-data font-neo-semibold center-align">No claim yet</span>
                    </div>
                </div>
            )
        } else {
            return (
                <div className={`${css}`}>
                    {/* <h6 className="coverage-header text-headercolor font-neo-bold">For Unit-Linked Plans</h6> */}
                    <div className="policy__table-container">
                        <table className="table coverage-table-row coverage-table-bordered coverage-table-striped coverage-table-header table-borderless mb-0">
                            <thead>
                                <tr>
                                    {claimsColumns}
                                </tr>
                            </thead>
                            <tbody>
                                {claimsRows}
                            </tbody>
                        </table>
                    </div>
                </div>

            );
        }
    }
    reinstatementElements = (reinstatementInfo, insuranceType, viewType) => (
        <Auxi key={"reinstatements-" + viewType}>
            <Reinstatement
                reinstatements={reinstatementInfo}
                css="mb-4"
                view={viewType}
                insuranceType={insuranceType}
                modalData={{ policyId: this.state.policyId, token: this.props.getSession().access_token, agent: decrypt(this.props.getSession().agentNumber) }}
            ></Reinstatement>
        </Auxi>
    );
    endorsement = (css) => {

        if (this.state.insuranceType === 'health') {
            return (
                <div className={`${css}`}>
                    <div className="policy__table-container">
                        <span className="dashboard-row-data font-neo-semibold center-align">Endorsement Not Yet Available</span>
                    </div>
                </div>
            )
        }

        let columns = ["POS Request Number", "Endorsement", "Effectivity Date", "Status", "User Remarks", "System Remarks", "Requirements"];

        let claimsColumns = columns.map((item, index) => {
            return (
                <th key={index}>{item}</th>
            );
        });
        let claimsRows = this.state.endorsementInfo.map((item, index) => {
            return (
                <tr key={index}>
                    <td>{item.posNum ? item.posNum : '--'}</td>
                    <td>{item.endorsement ? item.endorsement : '--'}</td>
                    <td>{item.date}</td>
                    <td>{item.status ? item.status : '--'}</td>
                    <td>{item.user ? item.user : '--'}</td>
                    <td style={css === "scrollable-table" ? {whiteSpace: "normal", minWidth: "15rem"} : null}>{item.system ? item.system : '--'}</td>
                    {
                        item.hasRequirement ? (
                            <td>{<a className="dashboard-datatable__button btn rounded-pill btn-dropdowncolor text-white"
                                style={{ minWidth: "60px" }}
                                onClick={() => { this.setState({ showEndorsementRequirements: true, selectedEndorsementId: item.posNum }) }}
                            >
                                View
                            </a>}</td>
                        ) : <td>--</td>
                    }
                </tr>
            );
        });

        if (this.state.endorsementInfo === null || this.state.endorsementInfo.length === 0) {
            return (
                <div className={`${css}`}>
                    <div className="policy__table-container">
                        <span className="dashboard-row-data font-neo-semibold center-align">No policy endorsement yet</span>
                    </div>
                </div>
            )
        } else {
            return (
                <div className={`${css}`}>
                    {/* <h6 className="coverage-header text-headercolor font-neo-bold">For Unit-Linked Plans</h6> */}
                    <div className={`policy__table-container ${css !== "scrollable-table" ? "overflow-auto" : ""}`}>
                        <table className="table coverage-table-row coverage-table-bordered coverage-table-striped coverage-table-header table-borderless mb-0">
                            <thead>
                                <tr>
                                    {claimsColumns}
                                </tr>
                            </thead>
                            <tbody>
                                {claimsRows}
                            </tbody>
                        </table>
                    </div>
                </div>

            );
        }
    }

    billTraditionalPlans = (css) => {
        let transactionColumns = ["Transaction Details", "OR Number", "Mode of Payment", "OR Date", "Payment Amount"];

        let columns = ["Billing Number", "Mode of Payment", "Due Date", "Covered Period From", "Covered Period to", "Premium Amount", "Balance"];

        let traditionalTransactionPlansColumns = transactionColumns.map((item, index) => {
            return (
                <th key={index}>{item}</th>
            );
        });
        let traditionalPlansColumns = columns.map((item, index) => {
            return (
                <th key={index}>{item}</th>
            );
        });

        let traditionalPlansRows = this.state.paymentTransactionInfo.map((item, index) => {
            return (
                <tr key={index}>
                    <td>{item.transaction ? item.transaction : '--'}</td>
                    <td>{item.mode ? item.mode : '--'}</td>
                    <td>{item.orDate}</td>
                    <td>{item.coveredFrom}</td>
                    <td>{item.coveredTo}</td>
                    <td>{item.amt ? item.amt : '--'}</td>
                    <td>{item.balance ? item.balance : '--'}</td>
                </tr>
            );
        });


        let traditionalPlansTransactionsRows = this.state.paymentTransactionInfoTransaction.map((item, index) => {
            let row = (
                    <tr key={index}>
                        <td>{item.details ? item.details : '--'}</td>
                        <td>{item.orNumber ? item.orNumber : '--'}</td>
                        <td>{item.mode ? item.mode : '--'}</td>
                        <td>{item.orDate}</td>
                        <td>{item.amt ? item.amt : '--'}</td>
                    </tr>
                )
            return row;
        });

        if (this.state.paymentTransactionInfo.length === 0 && this.state.paymentTransactionInfoTransaction.length === 0) {
            return (
                <div className={`${css}`}>
                    <div className="policy__table-container">
                        <span className="dashboard-row-data font-neo-semibold center-align">No billings and payment yet</span>
                    </div>
                </div>
            )
        } else {
            return (
                <div className={`${css}`}>
                    <h6 className="coverage-header text-headercolor font-neo-bold">For Traditional Plans</h6>
                    <br /><br />
                    <h6 className="coverage-header text-headercolor font-neo-bold">PAYMENTS</h6>
                    <div className="policy__table-container">
                        <table className="table coverage-table-row coverage-table-bordered coverage-table-striped coverage-table-header table-borderless mb-0">
                            <thead>
                                <tr>
                                    {traditionalTransactionPlansColumns}
                                </tr>
                            </thead>
                            <tbody>
                                {traditionalPlansTransactionsRows}
                            </tbody>
                        </table>
                    </div>

                    <h6 className="coverage-header text-headercolor font-neo-bold">BILLINGS</h6>
                    <div className="policy__table-container">

                        <table className="table coverage-table-row coverage-table-bordered coverage-table-striped coverage-table-header table-borderless mb-0">
                            <thead>
                                <tr>
                                    {traditionalPlansColumns}
                                </tr>
                            </thead>
                            <tbody>
                                {traditionalPlansRows}
                            </tbody>
                        </table>
                    </div>
                </div>

            );
        }



    }


    billLinkedPlans = (css) => {
        let transactionColumns = ["Transaction Details", "OR Number", "Mode of Payment", "OR Date", "Payment Amount"];

        let columns = ["Billing Number", "Mode of Payment", "Due Date", "Covered Period From", "Covered Period to", "Premium Amount", "Balance"];

        let linkedPlansTransactionColumns = transactionColumns.map((item, index) => {
            return (
                <th key={index}>{item}</th>
            );
        });
        let linkedPlansColumns = columns.map((item, index) => {
            return (
                <th key={index}>{item}</th>
            );
        });

        let linkedPlansRows = this.state.paymentTransactionInfo.map((item, index) => {
            return (
                <tr key={index}>
                    <td>{item.transaction ? item.transaction : '--'}</td>
                    <td>{item.mode ? item.mode : '--'}</td>
                    <td>{item.orDate}</td>
                    <td>{item.coveredFrom}</td>
                    <td>{item.coveredTo}</td>
                    <td>{item.amt ? item.amt : '--'}</td>
                    <td>{item.balance ? item.balance : '--'}</td>
                </tr>
            );
        });


        let linkedPlansTransactionsRows = this.state.paymentTransactionInfoTransaction.map((item, index) => {
            let row = (
                    <tr key={index}>
                        <td>{item.details ? item.details : '--'}</td>
                        <td>{item.orNumber ? item.orNumber : '--'}</td>
                        <td>{item.mode ? item.mode : '--'}</td>
                        <td>{item.orDate}</td>
                        <td>{item.amt ? item.amt : '--'}</td>
                    </tr>
                );
            return row;
        });

        if (this.state.paymentTransactionInfo.length === 0 && this.state.paymentTransactionInfoTransaction.length === 0) {
            return (
                <div className={`${css}`}>
                    <div className="policy__table-container">
                        <span className="dashboard-row-data font-neo-semibold center-align">No billings and payment yet</span>
                    </div>
                </div>
            )
        } else {
            const title = this.state.insuranceType === 'health' ? 'International Health Plans' : 'Unit-Linked Plans';
            return (
                <div className={`${css}`}>
                    <h6 className="coverage-header text-headercolor font-neo-bold">For {title}</h6>
                    <br /><br />
                    <h7 className="coverage-header text-headercolor font-neo-bold">PAYMENTS</h7>
                    <div className="policy__table-container">
                        <table className="table coverage-table-row coverage-table-bordered coverage-table-striped coverage-table-header table-borderless mb-0">
                            <thead>
                                <tr>
                                    {linkedPlansTransactionColumns}
                                </tr>
                            </thead>
                            <tbody>
                                {linkedPlansTransactionsRows}
                            </tbody>
                        </table>
                    </div>
                    <h7 className="coverage-header text-headercolor font-neo-bold">BILLINGS</h7>
                    <div className="policy__table-container">
                        <table className="table coverage-table-row coverage-table-bordered coverage-table-striped coverage-table-header table-borderless mb-0">
                            <thead>
                                <tr>
                                    {linkedPlansColumns}
                                </tr>
                            </thead>
                            <tbody>
                                {linkedPlansRows}
                            </tbody>
                        </table>
                    </div>
                </div>

            );
        }
    }

    //SHOW ERROR IF ERROR STATE IS NOT NULL
    showError = () => {
        let message = '';
        if (this.state.error !== '') {
            message =
                <div className="bg-error py-1 px-4">
                    <p className="text-white mb-0 font-neo-semibold">ERROR: {this.state.error}</p>
                </div>
        }
        return message;
    }
    renderVerticalNavs = () => {
        const traditionalNavs = {
            policyInfo: "Policy Information",
            billPay: "Billing and Payment",
            /*
            ** Hide claims due to claims decom and prep for assure integration
            claims: "Claims History",
            */
            endorse: "Endorsement",
            reinstate: "Reinstatement"
        };
        const notTraditionalNavs = {
            policyInfo: "Policy Information",
            billPay: "Billing and Payment",
            /*
            ** Hide claims due to claims decom and prep for assure integration
            claims: "Claims History",
            */
            endorse: "Endorsement",
            reinstate: "Reinstatement"
        };
        let verticalNavs = [];
        let i = 0;
        if (this.state.insuranceType === "traditional") {
            for (let key in traditionalNavs) {
                verticalNavs.push(
                    <a className={`vertical-nav nav-link ${i === 0 ? "active" : ""}`} data-toggle="pill" role="tab" href={`#${key}`}>{traditionalNavs[key]}</a>
                );
                i++;
            }
        } else {
            for (let key in notTraditionalNavs) {
                verticalNavs.push(
                    <a className={`vertical-nav nav-link ${i === 0 ? "active" : ""}`} data-toggle="pill" role="tab" href={`#${key}`}>{notTraditionalNavs[key]}</a>
                );
                i++;
            }
        }

        return (
            <div className="vertical-nav__container nav flex-column nav-pills" role="tablist">
                {verticalNavs}
            </div>
        );
    }

    billingPayment = (css) => {
        if (this.state.insuranceType === "traditional") {
            return this.billTraditionalPlans(css);
        } else {
            return this.billLinkedPlans(css);

        }
    }
    tableOfBenefits = (css) => {
        let columns = ["Description", "Value", "Type"];

        let tobColumns = columns.map((item, index) => {
            return (
                <th key={index}>{item}</th>
            );
        });

        let tobRows = this.state.tableOfBenefits.map((item, index) => {
            return (
                <tr key={index}>
                    <td>{item.name ? item.name : '--'}</td>
                    <td>{item.coverage ? item.coverage : '--'}</td>
                    <td>{item.benefitTypes ? item.benefitTypes : '--'}</td>
                </tr>
            );
        });
        return (
            <div className={`${css}`}>
                <div className="policy__table-container">
                    <table className="table coverage-table-row coverage-table-bordered coverage-table-striped coverage-table-header table-borderless mb-0">
                        <thead>
                            <tr>
                                {tobColumns}
                            </tr>
                        </thead>
                        <tbody>
                            {tobRows}
                        </tbody>
                    </table>
                </div>
            </div>

        );
    }
    renderHorizontalNavs = (css = "", mobile = "") => {
        const traditionalNavs = {
            policyDetails: "Policy Details",
            policyValue: "Policy Value",
            coverage: "Coverage & Rider",
            beneficiary: "Beneficiaries",
            policyLoan: "Policy Loan",
            endowment: "Endowments",
            policyLoan: "Policy Loans",
            surrender: "Surrender",
        };
        const notTraditionalNavs = {
            policyDetails: "Policy Details",
            fundValue: "Fund Value",
            fundTransactions: "Fund Transactions",
            coverage: "Coverage & Rider",
            beneficiary: "Beneficiaries",
            dividends: "Dividends",
            surrender: "Surrender",
            //  policyLoan: "Policy Loans"
        };
        const health = {
            policyDetails: "Policy Details",
            tableOfBenefits: "Table of Benefits",
            coverage: "Coverage & Rider"
        }
        const notForManagers = [
            'policyLoan',
        ];

        let horizontalNavs = [];
        let i = 0;

        if (this.state.insuranceType === "traditional") {
            for (let key in traditionalNavs) {
                if (!(isManager(this.props.getSession().role) && notForManagers.includes(key))) {
                    horizontalNavs.push(
                        <li className="dashboard-info__tab nav-item">
                            <a className={`dashboard-info__tab-link nav-link ${i === 0 ? "active" : ""}`} data-toggle="tab" href={`#${mobile}${key}`} role="tab">{traditionalNavs[key]}</a>
                        </li>
                    );
                    i++;
                }
            }
        } else if (this.state.insuranceType === "health") {
            for (let key in health) {
                if (!(isManager(this.props.getSession().role) && notForManagers.includes(key))) {
                    horizontalNavs.push(
                        <li className="dashboard-info__tab nav-item">
                            <a className={`dashboard-info__tab-link nav-link ${i === 0 ? "active" : ""}`} data-toggle="tab" href={`#${mobile}${key}`} role="tab">{health[key]}</a>
                        </li>
                    );
                    i++;
                }
            }
        } else {
            for (let key in notTraditionalNavs) {
                horizontalNavs.push(
                    <li className="dashboard-info__tab nav-item">
                        <a className={`dashboard-info__tab-link nav-link ${i === 0 ? "active" : ""}`} data-toggle="tab" href={`#${mobile}${key}`} role="tab">{notTraditionalNavs[key]}</a>
                    </li>
                );
                i++;
            }
        }
        return (
            <ul className={`dashboard-info__tabs nav nav-tabs ${css}`} role="tablist">
                {horizontalNavs}
            </ul>
        );
    }

    desktopView = (css = "") => {

        let coverageItem = this.linkedPlans("mt-4 mb-4");
        if (this.state.insuranceType === "traditional") {
            coverageItem = this.traditionalPlans("mb-4");
        }
        return (
            <div className={`${css} dashboard-policy__container row mt-3`}>
                <div className="vertical-navs">
                    {this.renderVerticalNavs()}
                </div>
                <div className="dashboard-policy__body">
                    <div className="dashboard-policy__content tab-content">
                        <div className="dashboard-policy__pane px-3 tab-pane fade show active" id="policyInfo" role="tabpanel">

                            {this.renderHorizontalNavs()}
                            <div className="tab-content">
                                <div className="tab-pane fade show active" id="policyDetails" role="tabpanel">
                                    {this.policyDetailFields("mt-4")}
                                    {this.basicInfo("mt-4 w-md-50")}
                                    {this.billingInfo("mt-3 w-md-50")}
                                    {this.insuredInfo('mt-3 w-100')}
                                    {this.otherInfo("mt-3 w-md-50")}
                                </div>
                                <div className="tab-pane fade" id="tableOfBenefits" role="tabpanel">

                                    {this.tableOfBenefits("mt-4")}
                                </div>
                                <div className="tab-pane fade" id="policyValue" role="tabpanel">
                                    <div className="pb-4">
                                        {this.policyValue("mt-4 w-100")}
                                        {this.transactionModal()}
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="coverage" role="tabpanel">

                                    {coverageItem}
                                </div>
                                <div className="tab-pane fade" id="beneficiary" role="tabpanel">
                                    {this.beneficiary("mt-4")}
                                </div>
                                <div className="tab-pane fade" id="policyLoan" role="tabpanel">
                                    {this.policyLoan("mt-4")}
                                </div>
                                <div className="tab-pane fade" id="endowment" role="tabpanel">
                                    {this.endowments("mt-4")}
                                </div>
                                <div className="tab-pane fade" id="surrender" role="tabpanel">
                                    {this.surrender("mt-4")}
                                </div>
                                <div className="tab-pane fade" id="fundValue" role="tabpanel">
                                    {this.fundValue("mt-4")}
                                </div>
                                <div className="tab-pane fade" id="fundTransactions" role="tabpanel">
                                    {this.fundTransactions("mt-4")}
                                </div>
                                <div className="tab-pane fade" id="dividends" role="tabpanel">
                                    {this.dividends("mt-4")}
                                </div>
                            </div>
                        </div>
                        <div className="dashboard-policy__pane tab-pane fade" id="billPay" role="tabpanel">
                            <div className="px-3">
                                {this.billingPayment("mt-4")}
                            </div>
                        </div>
                        {
                        /*
                        ** Hide claims due to claims decom and prep for assure integration
                        
                        <div className="dashboard-policy__pane tab-pane fade" id="claims" role="tabpanel">
                            <div className="px-3">
                                {this.claims("mb-4")}
                            </div>
                        </div> 
                        */
                        }

                        <div className="dashboard-policy__pane tab-pane fade" id="endorse" role="tabpanel">
                            <div className="px-3">
                                {this.endorsement("mb-4")}
                            </div>
                        </div>
                        <div className="dashboard-policy__pane tab-pane fade" id="reinstate" role="tabpanel">
                            <div className="px-3">
                                {this.reinstatementElements(this.state.reinstatementInfo, this.state.insuranceType, "desktop-")}
                            </div>
                        </div>
                        <div className="dashboard-policy__pane tab-pane fade" id="anniversary" role="tabpanel">
                            <div className="policy-datepicker px-3">
                                <h6 className="coverage-header font-neo-bold">Policy Anniversary Date</h6>
                                <div className="policy-datepicker__body mt-5">
                                    <div className="d-flex align-items-center position-relative">
                                        <span className="text-navyblue font-neo-semibold position-absolute right-12rem">From</span>
                                        <div className="form-control policy-datepicker__container">
                                            <DatePicker className="policy-datepicker__input" selected={this.state.startDate} onChange={this.handleStartDateChange} />
                                            <span className="icon-calendar text-dropdowncolor"></span>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center position-relative">
                                        <span className="text-navyblue font-neo-semibold position-absolute right-12rem">To</span>
                                        <div className="form-control policy-datepicker__container">
                                            <DatePicker className="policy-datepicker__input" selected={this.state.endDate} onChange={this.handleEndDateChange} />
                                            <span className="icon-calendar text-dropdowncolor"></span>
                                        </div>
                                    </div>

                                </div>
                                <div className="policy-datepicker__footer text-center mt-9">
                                    <button className="btn btn-dropdowncolor font-neo-semibold py-1 px-4">Generate</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    mobileView = (css) => {
        const mobile = "mobile-";
        let coverageItem = this.linkedPlans("scrollable-table  mt-4 mb-4");
        if (this.state.insuranceType === "traditional") {
            coverageItem = this.traditionalPlans("scrollable-table mb-4");
        }

        let policyInfoContent = (
            <Auxi>
                <div className="data-accord__toggle btn p-3">
                    <div className="d-flex justify-content-between">
                        <h6 className="data-accord__title font-neo-bold mb-0 text-darkgray">Policy Information</h6>
                        <button className="btn p-0" type="button" data-toggle="collapse" data-target={`#${mobile}policyInfo`}>
                            <div className="data-accord__caret">
                                <div className="data-accord__caret-icon"></div>
                            </div>
                        </button>
                    </div>
                </div>
                <div id={`${mobile}policyInfo`} className="collapse" data-parent={`#${mobile}policy-accord`}>
                    {this.renderHorizontalNavs("", mobile)}
                    <div className="card-body data-accord__content data-accord__content--minh10rem">
                        <div className="tab-content">
                            <div className="tab-pane fade show active" id={`${mobile}policyDetails`} role="tabpanel">
                                {this.policyDetailFields("")}
                                {this.basicInfo("mt-4 w-md-50")}
                                {this.billingInfo("mt-3 w-md-50")}
                                {this.insuredInfo('mt-3 w-100', mobile)}
                                {this.otherInfo("mt-3 w-md-50", mobile)}
                            </div>
                            <div className="tab-pane fade" id={`${mobile}tableOfBenefits`} role="tabpanel">

                                {this.tableOfBenefits("mt-4")}
                            </div>
                            <div className="tab-pane fade" id={`${mobile}policyValue`} role="tabpanel">
                                <div className="pb-4">
                                    {this.policyValue("mt-4 w-100", mobile)}
                                    {this.transactionModal("", mobile)}
                                </div>
                            </div>
                            <div className="tab-pane fade" id={`${mobile}coverage`} role="tabpanel">
                                {/* {this.linkedPlans("mt-4 mb-4")}
                                                        <hr />
                                                        {this.traditionalPlans("mb-4")} */}
                                {coverageItem}
                            </div>
                            <div className="tab-pane fade" id={`${mobile}beneficiary`} role="tabpanel">
                                {this.beneficiary("scrollable-table")}
                            </div>
                            <div className="tab-pane fade" id={`${mobile}policyLoan`} role="tabpanel">
                                {this.policyLoan("scrollable-table")}
                            </div>
                            <div className="tab-pane fade" id={`${mobile}endowment`} role="tabpanel">
                                {this.endowments("scrollable-table")}
                            </div>
                            <div className="tab-pane fade" id={`${mobile}surrender`} role="tabpanel">
                                {this.surrender("scrollable-table")}
                            </div>
                            <div className="tab-pane fade" id={`${mobile}fundValue`} role="tabpanel">
                                {this.fundValue("scrollable-table")}
                            </div>
                            <div className="tab-pane fade" id={`${mobile}fundTransactions`} role="tabpanel">
                                {this.fundTransactions("scrollable-table")}
                            </div>
                            <div className="tab-pane fade" id={`${mobile}dividends`} role="tabpanel">
                                {this.dividends("scrollable-table")}
                            </div>
                        </div>
                    </div>
                </div>
            </Auxi>
        );
        let billPayContent = (
            <Auxi>
                <div className="data-accord__toggle btn p-3">
                    <div className="d-flex justify-content-between">
                        <h6 className="data-accord__title font-neo-bold mb-0 text-darkgray">Billing and Payment</h6>
                        <button className="btn p-0" type="button" data-toggle="collapse" data-target={`#${mobile}billPay`}>
                            <div className="data-accord__caret">
                                <div className="data-accord__caret-icon"></div>
                            </div>
                        </button>
                    </div>
                </div>
                <div id={`${mobile}billPay`} className="collapse" data-parent={`#${mobile}policy-accord`}>
                    <div className="card-body data-accord__content data-accord__content--minh10rem">
                        {/* <BillingsPayment css="scrollable-table" getSession={this.props.getSession} errorMessage={this.errorMessage} insuranceType={this.state.insuranceType} /> */}
                        {this.billingPayment("scrollable-table")}
                    </div>
                </div>
            </Auxi>
        );
        /*
        ** Hide claims due to claims decom and prep for assure integration
        let claimsContent = (
            <Auxi>
                <div className="data-accord__toggle btn p-3">
                    <div className="d-flex justify-content-between">
                        <h6 className="data-accord__title font-neo-bold mb-0 text-darkgray">Claims History</h6>
                        <button className="btn p-0" type="button" data-toggle="collapse" data-target={`#${mobile}claims`}>
                            <div className="data-accord__caret">
                                <div className="data-accord__caret-icon"></div>
                            </div>
                        </button>
                    </div>
                </div>
                <div id={`${mobile}claims`} className="collapse" data-parent={`#${mobile}policy-accord`}>
                    <div className="card-body data-accord__content data-accord__content--minh10rem">
                        {this.claims("scrollable-table")}
                    </div>
                </div>
            </Auxi>
        );
        */
        let endorseContent = (
            <Auxi>
                <div className="data-accord__toggle btn p-3">
                    <div className="d-flex justify-content-between">
                        <h6 className="data-accord__title font-neo-bold mb-0 text-darkgray">Endorsement</h6>
                        <button className="btn p-0" type="button" data-toggle="collapse" data-target={`#${mobile}endorse`}>
                            <div className="data-accord__caret">
                                <div className="data-accord__caret-icon"></div>
                            </div>
                        </button>
                    </div>
                </div>
                <div id={`${mobile}endorse`} className="collapse" data-parent={`#${mobile}policy-accord`}>
                    <div className="card-body data-accord__content data-accord__content--minh10rem">
                        {this.endorsement("scrollable-table")}
                    </div>
                </div>
            </Auxi>
        );

        let reinstateContent = (
            <Auxi>
                <div className="data-accord__toggle btn p-3">
                    <div className="d-flex justify-content-between">
                        <h6 className="data-accord__title font-neo-bold mb-0 text-darkgray">Reinstatement</h6>
                        <button className="btn p-0" type="button" data-toggle="collapse" data-target={`#${mobile}reinstate`}>
                            <div className="data-accord__caret">
                                <div className="data-accord__caret-icon"></div>
                            </div>
                        </button>
                    </div>
                </div>
                <div id={`${mobile}reinstate`} className="collapse" data-parent={`#${mobile}policy-accord`}>
                    <div className="card-body data-accord__content data-accord__content--minh10rem">
                        {this.reinstatementElements(this.state.reinstatementInfo, this.state.insuranceType, mobile)}
                    </div>
                </div>
            </Auxi>
        );

        let accordContent = [];
        if (this.state.insuranceType === "traditional") {
            accordContent = [
                policyInfoContent,
                billPayContent,
                /*
                ** Hide claims due to claims decom and prep for assure integration
                claimsContent,
                */
                endorseContent,
                reinstateContent
            ];
        } else {
            accordContent = [
                policyInfoContent,
                billPayContent,
                /*
                ** Hide claims due to claims decom and prep for assure integration
                claimsContent,
                */
                endorseContent,
                reinstateContent
            ];
        }

        let policyAccord = accordContent.map(item => {
            return (
                <div className="data-accord__card mt-2 card border-0">
                    {item}
                </div>
            );
        });
        return (
            <div className={`${css} dashboard-policy__container mt-3`}>
                <div className="data-accord__collapse" id={`${mobile}policy-accord`}>
                    {policyAccord}
                </div>
            </div>
        );
    }

    contentView = () => {
        let desktopView = this.desktopView("d-md-flex d-none");
        let mobileView = this.mobileView("d-block d-md-none");

        return (
            <Auxi>
                {desktopView}
                {mobileView}
            </Auxi>
        );
    }
    render() {
        if (this.state.policyDetailFieldsInfo === null || this.state.basicInfo === null || this.state.isFundTransactionLoading === true) {
            return (
                <ReportLoader handleClose={this.hideModal} show={this.state.isLoading} />
            );
        } else {
            if (this.state.policyDetailFieldsInfo !== null && this.state.policyDetailFieldsInfo.length > 0) {
                return (
                    <div className={`dashboard-section policy-info`}>
                        <Modal
                            showModal={this.state.showRequirement}
                            handleClose={this.handleRequirementClose}
                            data={this.state.requirements}
                            isModalLoading={this.state.isModalLoading}
                        ></Modal>
                        {this.state.showEndorsementRequirements &&
                            <EndorsementModal
                                handleClose={() => { this.setState({ showEndorsementRequirements: false }) }}
                                modalData={{ transactionId: this.state.selectedEndorsementId, policyId: this.state.policyId, token: this.props.getSession().access_token, agent: decrypt(this.props.getSession().agentNumber) }}
                            />
                        }
                        {this.state.showOtherInfoPdfModal &&
                            <OtherInfoPdfModal
                                handleClose={() => { this.setState({ showOtherInfoPdfModal: false }) }}
                                modalData={{ modalId: this.state.selectedOtherInfoPdfModal, policyId: this.state.policyId, token: this.props.getSession().access_token }}
                            />
                        }
                        {this.showError()}
                        <div className="dashboard-container policy-info__container container-fluid pt-3 px-3 pb-0 h-100">
                            <div className="dashboard-header">
                                <h6 className="font-neo-bold text-darkgray">Policy Information</h6>
                                {this.props.getViewAs}
                            </div>
                            <div className="policy-info__body dashboard-body mt-4">
                                <div className="dashboard-policy">
                                    {this.dashboardPolicyFields()}
                                    {this.contentView()}
                                </div>
                            </div>
                        </div>
                    </div>
                );
            } else {
                return (
                    <div className={`dashboard-section policy-info`}>
                        {this.showError()}
                        <div className="dashboard-container policy-info__container container-fluid pt-3 px-3 pb-0 h-100">
                            <div className="dashboard-header">
                                <h6 className="font-neo-bold text-darkgray">Policy Information</h6>
                            </div>
                            <div className="policy-info__body dashboard-body mt-4">
                                <div className="dashboard-policy center-align">
                                    No Policy Information Available
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }

        }
    }
}
export default withRouter(SinglePolicy);
