import React, { Component } from 'react';
// import '../App.css';
import { writeData, jsonToURI, readAllData, encrypt, decrypt, adjustUsername } from '../commons/utility.js';
import { styles } from '../styles/styles.js';
import AuthLoader from '../commons/authLoader';
import { Modal, Loader } from '../commons/utilityViews.js';
import { myConfig } from '../commons/config.js';
import { BrowserRouter as Router, Route, Link, Redirect, withRouter } from 'react-router-dom';
import { user } from '../apiFactory/user.js';
import routeChange from '../commons/routeChange.js';
import { apiModule } from '../commons/apiCall.js';
class ResetPassword extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      payloadParams: {
        username: '',
       
      },
      error: '',
    };
  }

  async componentDidMount() {
    let access_token = this.props.getSession().access_token;
    console.log(this.props.getSession());

  }

  errorMessage = (message) => {
    if (message) {
        this.setState({ error: message,  isLoading: false })
    }else {
      this.setState({ isLoading: false })
    }
  }

  


  cancelButton = (that) => {
    if(window.confirm("Are you sure you want to cancel?")) {
        that.props.setLoginMessage("");
        routeChange(this.props.history, "/");
    }
}
  doResetPassword = (that) => {

    return new Promise(function (resolve, reject) {
      const uuidv5 = require('uuid/v5');

      let headerParams = {};
      let timestamp = new Date();

      readAllData('users')
        .then(function (data) {
          let deviceId = '';
          let fakeDeviceId = 'dc3f6ffa-f04c-5f97-90ed-8c5e95e9e14d';

          if(fakeDeviceId !== '') 
          {
            deviceId = fakeDeviceId;
          }else {
            if (data.length < 1) {
              deviceId = uuidv5('http://allianzpnblife.salesportal.com/' + timestamp + '/' + that.state.payloadParams, uuidv5.URL);
              writeData('users', { deviceId: deviceId, id: 1 });
            } else {
              for (var dt of data) {
                if (dt.deviceId === "" || dt.deviceId === undefined || dt.deviceId === null) {
                  deviceId = uuidv5('http://allianzpnblife.salesportal.com/' + timestamp + '/' + that.state.payloadParams, uuidv5.URL);
                  writeData('users', { deviceId: deviceId, id: 1 });
                } else {
                  deviceId = dt.deviceId;
                }
              }
            }
          }


          

          let payloadParams = {
            username: adjustUsername(that.state.payloadParams.username),
            appId: myConfig.KEYCLOAK_APPID,
            redirect_uri: myConfig.RESET_PASSWORD_URL
          };

          that.setState({
            isLoading: true,

          });
          apiModule("authentication_forgot_password", headerParams, payloadParams, '', true)
            .then(function (result) {
              if (result.status === 200) {
                that.props.setLoginMessage("We have sent a reset password link to your email address. Link is only valid for 5 minutes.");
                routeChange(that.props.history, "/");
              } else {
                that.props.setLoginMessage("We have sent a reset password link to your email address. Link is only valid for 5 minutes.");
                routeChange(that.props.history, "/");
              }
              resolve("Ok");
            })
            .catch(function (err) {
              console.log(err);
              that.props.setLoginMessage("We have sent a reset password link to your email address. Link is only valid for 5 minutes.");
              routeChange(that.props.history, "/");
              resolve("Ok");
            })

        });
    });
  }

  errorMessage = (message) => {
    if (message) {
      this.setState({ error: message, isLoading: false })
    }
  }

  showError = () => {
    let message = '';
    if (this.state.error !== '') {
      message =
        <div className="form-group mb-4 text-white">
          <p>{this.state.error}</p>
        </div>
    }
    return message;
  }
  handleKeyPress(e) {
   
    if (e.key === 'Enter') {
        e.preventDefault();
        this.doResetPassword(this);
    } else {

    }
}

  handleChanges(e) {
    let payloadParams = this.state.payloadParams;
    payloadParams[e.target.name] = e.target.value;
    
    this.setState({ payloadParams });
  }
  showCancelButton =() => {
    if(this.state.isLoading) {
      return (<span></span>);
    }else {
      return (<a onClick={() => this.cancelButton(this)} className={`auth__button btn ${this.state.isLoading ? "btn-loading pes-none" : "btn-navyblue"}  text-uppercase btn-block text-white`}>{this.state.isLoading ? <AuthLoader width="60" /> : "Cancel"}</a>);

    }
    
  }
  render() {

    return (
      <div className="auth-section">
        <div className="auth__picture">
          <img className="auth__img" src={require('../assets/img/bg-login.jpg')} alt="Authentication background" />
        </div>
        <div className="custom-skewed-block background--gradient-blue">

          <div className="auth-block">
            {/* ResetPassword CONTAINER */}
            <div className="auth-container">
              <form>
                <h1 className="auth__header text-white mb-4">Reset Password</h1>
                {this.showError()}
                <div className="form-group mb-4">
                  <input className="auth__text-field form-control" type="text" name="username" onChange={(event) => this.handleChanges(event)} onKeyDown={(event) => this.handleKeyPress(event)} placeholder="USERNAME" value={this.state.payloadParams.username} required />
                </div>
                <div className="form-group mb-4">
                  <div className="d-flex">
                 
                  </div>
                </div>
                {/* <a href="/verify" className="auth__button btn btn-navyblue text-uppercase btn-block">Submit</a> */}
                {/* <Link className="auth__button btn btn-navyblue text-uppercase btn-block" to="/verify">Submit</Link> */}
                {/* <a onClick={() => this.doResetPassword(this)} className="auth__button btn btn-navyblue text-uppercase btn-block text-white">Submit</a>
                <a onClick={() => this.cancelButton(this)} className="auth__button btn btn-navyblue text-uppercase btn-block text-white">Cancel</a> */}
                <a onClick={() => this.doResetPassword(this)} className={`auth__button btn ${this.state.isLoading ? "btn-loading pes-none" : "btn-navyblue"}  text-uppercase btn-block text-white`}>{this.state.isLoading ? <AuthLoader width="60" /> : "Submit"}</a>
                {this.showCancelButton()}
              </form>
            </div>
            {/* END ResetPassword CONTAINER */}
          </div>
          <div className="auth-logo-section">
            <div className="auth-logo-container">
              <img className="auth-logo" src={require("../assets/img/logo.png")} alt="Allianz Logo" />
            </div>
          </div>
        </div>
        
      </div>

    );
  }
}

export default withRouter(ResetPassword);
