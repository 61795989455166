import React, { Component } from 'react';
import * as DOMPurify from 'dompurify'

class ConfirmationPrompt extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  handleAcceptClick = () => {
    this.props.handleAcceptTrigger();
  }

  handleDeclineClick = () => {
    this.props.handleDeclineTrigger();
  }

  handleCloseClick = () => {
    this.props.handleCancelTrigger();
  }

  render() {
    return (
      <article className="policy-services-prompt">
        <header className="policy-services-prompt__header d-flex flex-row">
          <h4 className=" d-inline-flex align-items-center mr-auto mb-0"><span className={this.props.headerIconClasses}></span>{this.props.headerText}</h4>
          <span
            className={`policy-services-prompt__close ${this.props.headerCloseClasses}`}
            onClick={this.handleCloseClick}
          ></span>
        </header>

        <article className="policy-services-prompt__content">
          <div className="mb-3" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(this.props.content) }} />
          <div className="policy-services-prompt__button-container">
            {this.props.acceptText ?
              <button className="policy-services-prompt__button policy-services-prompt__button--confirm" onClick={this.handleAcceptClick}>
                {this.props.acceptText}
              </button>
              : null}
            {this.props.declineText ?
              <button className="policy-services-prompt__button" onClick={this.handleDeclineClick}>
                {this.props.declineText}
              </button>
              : null}

          </div>
        </article>
      </article>
    )
  }
}


export default ConfirmationPrompt;
