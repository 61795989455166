export const toAbbreviatedNumber = (value) => {
    let shortValue = value;
    var suffixes = ["", "K", "M", "B", "T", "Qa", "Qi", "Sx", "Sp", "Oc", "No"];
    var suffixNum = Math.floor(("" + value).length / 3);
    shortValue = parseFloat(suffixNum != 0 ? (value / Math.pow(1000, suffixNum)) : value);

    if (shortValue == 0) {
        return shortValue;
    }

    if (shortValue < 1) {
        return (shortValue * 1000).toFixed(0) + suffixes[suffixNum - 1];
    }

    shortValue = shortValue.toFixed(2);
    return shortValue + suffixes[suffixNum];
}

export function formatValues(value) {
    let result = {
        value: value,
        scaled: value,
        prefix: ''
    }
    var isNegative = false;
    var SI_PREFIXES = ["", "K", "M", "B", "T", "Qa", "Qi", "Sx", "Sp", "Oc", "No"];
    if (value < 0) {
        value = Math.abs(value);
        isNegative = true;
    }
    var tier = (Math.log(value) * Math.LOG10E) / 3 | 0;
    if (tier === 0) return isNegative ? ("-" + value) : value;
    var prefix = SI_PREFIXES[tier];
    var scale = Math.pow(10, tier * 3);
    var scaled = value / scale;

    //added number because of Javascript Math discrepancy (e.g. instead of 126.5, it became 126.4999)
    scaled = (Math.round(roundNumber(100 * scaled, 0))) / 100;

    // return isNegative ? ("-" + formatToTenthDecimal(scaled) + prefix) : (formatToTenthDecimal(scaled) + prefix);
    return isNegative ? ("-" + scaled + prefix) : (scaled + prefix);

}

export function formatValuesNoDecimals(value) {
    let result = {
        value: value,
        scaled: value,
        prefix: ''
    }
    var isNegative = false;
    var SI_PREFIXES = ["", "K", "M", "B", "T", "Qa", "Qi", "Sx", "Sp", "Oc", "No"];
    if (value < 0) {
        value = Math.abs(value);
        isNegative = true;
    }
    var tier = (Math.log(value) * Math.LOG10E) / 3 | 0;
    if (tier === 0) return value;
    var prefix = SI_PREFIXES[tier];
    var scale = Math.pow(10, tier * 3);
    var scaled = value / scale;

    //added number because of Javascript Math discrepancy (e.g. instead of 126.5, it became 126.4999)
    scaled = (Math.round(roundNumber(100 * scaled, 0))) / 100;
    scaled = roundNumber(scaled, 0);

    // return isNegative ? ("-" + formatToTenthDecimal(scaled) + prefix) : (formatToTenthDecimal(scaled) + prefix);
    return isNegative ? ("-" + scaled + prefix) : (scaled + prefix);

}

export function roundNumber(num, scale) {
    if (!("" + num).includes("e")) {
        return +(Math.round(num + "e+" + scale) + "e-" + scale);
    } else {
        var arr = ("" + num).split("e");
        var sig = ""
        if (+arr[1] + scale > 0) {
            sig = "+";
        }
        return +(Math.round(+arr[0] + "e" + sig + (+arr[1] + scale)) + "e-" + scale);
    }
}

function formatToTenthDecimal(value) {
    var num = value;
    var with1Decimal = num.toString().match(/^-?\d+(?:\.\d{0,1})?/)[0]
    return with1Decimal
}

export function formatDecimal(value) {
    if (value === 0) {
        return 0;
    }
    var log10 = value ? Math.floor(Math.log10(value)) : 0,
        div = log10 < 0 ? Math.pow(10, 1 - log10) : 100;

    var result = Math.round(value * div) / div;
    // if(Math.log10(result) < -3){
    //     return result.toExponential();
    // }
    return result;
}

export function changeCaseFirstLetter(params) {
    if (typeof params === 'string') {
        return params.charAt(0).toUpperCase() + params.slice(1).toLowerCase();
    }
    return params;
}

export function changeToLowerCase(params) {
    if (typeof params === 'string') {
        return params.toLowerCase();
    }
    return params;
}

export function thousandSeparator(newAmount, roundOff) {
    let splitAmount = newAmount ? newAmount.toString().split(".") : 0;
    let amount = splitAmount[0];
    amount = amount ? amount : 0;
    if (!roundOff && amount) {
        return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (splitAmount[1] ? "." + splitAmount[1] : '')
    } else if (roundOff && amount) {
        amount = amount > 0 ? amount : Math.abs(amount);
        return Math.ceil(amount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (splitAmount[1] ? "." + splitAmount[1] : '')
    } else if (roundOff) {
        return 0
    } else {
        return amount;
    }
}

export function getMinVal(goalDetails) {
    return goalDetails.reduce((min, property) => property.achievedPremiumRate < min ? property.achievedPremiumRate : min, goalDetails[0].achievedPremiumRate);
}

export function getMaxVal(goalDetails) {
    return goalDetails.reduce((max, property) => property.achievedPremiumRate > max ? property.achievedPremiumRate : max, goalDetails[0].achievedPremiumRate);
}

export function dateStringify(value) {
    return value.toString().length === 2 ? value : "0" + value;
}

export function getStringDate(year, month, day){
    return year + '-' + month + '-' + day;
}