import React, { Component } from 'react';
import { getParams, SEARCH_TAB } from '../commons/service';
import { Redirect } from 'react-router-dom';
import { searchRNI } from '../commons/restEndpoint';
import { haveValue } from '../../../commons/misConfig';
import { createQueryStrings } from '../../../commons/apiCall';
import SearchAll from '../components/searchAll';
import SearchUser from '../components/searchUser';
import SearchContest from '../components/searchContest';
import SearchBulletin from '../components/searchBulletin';

export default class search extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true
        }

        this.getSearchDetails = this.getSearchDetails.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        this.getSearchDetails(nextProps)
    }

    getSearchDetails(props) {
        switch (props.activeTab) {
            case SEARCH_TAB.ALL:
                return <SearchAll {...props} />;
            case SEARCH_TAB.BULLETIN:
                return <SearchBulletin standalone {...props} />;
            case SEARCH_TAB.CONTEST:
                return <SearchContest standalone {...props} />;
            case SEARCH_TAB.USER:
                return <SearchUser standalone {...props} />;
            default: return null
        }
    }

    render() {
        var { searchValue, searchResult, clickedSearched, navList, activeTab, isLoading } = this.props

        return (
            <div className="rni-search-result-container container">
                {clickedSearched ?
                    this.getSearchDetails(this.props)
                    :
                    <div className="rni-search-result-container">
                        <div className="div">
                            <img className="rni-search-result-container-img" src="/rni/search-human.png" alt="" />
                        </div>
                        <p className="rni-search-result-container-p">You came to the right place! <br />What are you searching?</p>
                    </div>}
            </div>
        )
    }
}
