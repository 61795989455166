import React from 'react';
import { components } from 'react-select';

const DropdownGroup = (props) => {
  return (
    <div>
      <components.Menu {...props} className="countries-dropdown__menu">{props.children}</components.Menu>
    </div>
  );
}

export default DropdownGroup;
