import React, { Component } from "react";
import DatePicker from "react-datepicker";
import Select from 'react-select';
import DpCustomHeader from "../Datepicker/customHeader";
import moment from "moment";
import withWindowDimensions from '../../commons/withWindowDimensions';
import StartDt from './datePickerCustomInputDate';

const customStylesForDate = {
    option: (provided, state) => ({
        ...provided,
        borderBottom: 'none',
        color: state.isSelected || state.isFocused ? '#006192' : '#414141',
        background: '#FFFFFF',
        fontSize: '10x',
        fontFamily: 'Allianz Neo',
        fontWeight: '400',
        padding: 5,
    }),
    indicatorSeparator: (provided, state) => ({
        opacity: 0,
    }),
    dropdownIndicator: (provided, state) => ({
        color: '#414141',
        opacity: 0,
        display: 'none',
    })
}

var years = [{ value: new Date().getFullYear(), label: new Date().getFullYear() }]; // []
var i;
for (i = new Date().getFullYear() - 15; i <= new Date().getFullYear() + 15; i++) years[i] = { value: i, label: i.toString() };

const months = [
    {
        value: 0,
        label: "January"
    },
    {
        value: 1,
        label: "February"
    },
    {
        value: 2,
        label: "March"
    },
    {
        value: 3,
        label: "April"
    },
    {
        value: 4,
        label: "May"
    },
    {
        value: 5,
        label: "June"
    },
    {
        value: 6,
        label: "July"
    },
    {
        value: 7,
        label: "August"
    },
    {
        value: 8,
        label: "September"
    },
    {
        value: 9,
        label: "October"
    },
    {
        value: 10,
        label: "November"
    },
    {
        value: 11,
        label: "December"
    },
];

class MisDatePicker extends Component {
    constructor(props) {
        super(props);
        this.handleStartDateChange = this.handleStartDateChange.bind(this);
        this.handleEndDateChange = this.handleEndDateChange.bind(this);
    }

    handleStartDateChange = (date) => {
        if(Date.parse(date)){
            if(new Date(date) > new Date(this.props.maxDate) || new Date(date) < new Date(this.props.minDate)){
                this.props.updateStartDate(moment(this.props.minDate).format('YYYY-MM-DD'))
            }
            else{
                this.props.updateStartDate(date);
            }
        }
        else{
            this.props.updateStartDate(moment(this.props.minDate).format('YYYY-MM-DD'));
        }
    }
    handleEndDateChange = (date) => {
        if(Date.parse(date)){
            if(new Date(date) > new Date(this.props.maxDate) || new Date(date) < new Date(this.props.minDate)){
                this.props.updateEndDate(moment(this.props.maxDate).format('YYYY-MM-DD'))
            }
            else{
                this.props.updateEndDate(date);
            }
        }
        else{
            this.props.updateEndDate(moment(this.props.maxDate).format('YYYY-MM-DD'));
        }
    }

    render() {
        const yearDropdown = this.props.yearDropdown == false ? this.props.yearDropdown : true;
        const dateFormat = this.props.dateFormat ? this.props.dateFormat : "MM-dd-yyyy";
        return (
            <div className={"policy-datepicker mis-datepicker " + this.props.className}>
                <div className="policy-datepicker__body flex-wrap justify-content-start align-items-stretch" style={{display:"grid"}}>
                    <label>From:</label>
                    <div className="policy-datepicker__part d-flex align-items-center position-relative mb-1">
                        <div className="form-control policy-datepicker__container policy-datepicker__mobile rounded-pill border-0 h-100">
                            <DatePicker
                                placeholderText={this.props.placeholderFrom}
                                className="policy-datepicker__input text-gray policy-datepicker__input--placeholder"
                                minDate={this.props.minDate}
                                maxDate={this.props.maxDate}
                                dateFormat={dateFormat}
                                selected={this.props.startDateValue}
                                onChange={this.handleStartDateChange}
                                showDisabledMonthNavigation
                                // onChangeRaw={this.handleStartDateRawChange}
                                customInput={this.props.isSm ? <StartDt /> : null}
                                disabledKeyboardNavigation={this.props.isSm}
                                withPortal={this.props.isSm}
                                renderCustomHeader={({
                                    date,
                                    decreaseMonth,
                                    changeMonth,
                                    changeYear,
                                    increaseMonth,
                                    prevMonthButtonDisabled,
                                    nextMonthButtonDisabled
                                }) => (
                                    <div className="datepicker-header">
                                        <a
                                            className="btn datepicker-header__button p-0 text-lightgray"
                                            onClick={decreaseMonth}
                                            disabled={prevMonthButtonDisabled}
                                        >
                                            <span className="icon-chevron-small-left"></span>
                                        </a>
                                        <span className="misdtpk" style={{ marginBottom: '0px', width: '100%' }}>
                                            <Select
                                                isSearchable={false}
                                                styles={customStylesForDate}
                                                value={months[new Date(date).getMonth()]}
                                                placeholder="Month"
                                                onChange={(e) => { changeMonth(e.value) }}
                                                options={months}
                                                className={`datatable-select ml-0 w-3p5rem h-init datepicker-header__control`}
                                                classNamePrefix="datatable-select"
                                            />
                                            <Select
                                                isDisabled={true}
                                                isSearchable={false}
                                                styles={customStylesForDate}
                                                // value={years[new Date(date).getFullYear()]}
                                                value={years[years.map(function (e) { return e.value; }).indexOf(new Date(date).getFullYear())]}
                                                placeholder="Year"
                                                onChange={(e) => { changeYear(e.value) }}
                                                options={years}
                                                className={`datatable-select ml-0 w-3p5rem h-init datepicker-header__control`}
                                                classNamePrefix="datatable-select"
                                            />
                                        </span>
                                        <a
                                            className="btn datepicker-header__button p-0 text-lightgray"
                                            onClick={increaseMonth}
                                            disabled={nextMonthButtonDisabled}
                                        >
                                            <span className="icon-chevron-tree-navi"></span>
                                        </a>
                                    </div>
                                    )}
                            />
                            <span className="icon-calendar text-gray"></span>
                        </div>
                    </div>
                    <label>To:</label>
                    <div className="policy-datepicker__part d-flex align-items-center position-relative">
                        <div className="form-control policy-datepicker__container  policy-datepicker__mobile rounded-pill border-0 h-100">
                            <DatePicker
                                placeholderText={this.props.placeholderTo}
                                className="policy-datepicker__input text-gray policy-datepicker__input--placeholder"
                                minDate={this.props.startDateValue}
                                maxDate={this.props.maxDate}
                                dateFormat={dateFormat}
                                showPopperArrow={false}
                                selected={this.props.startDateValue > this.props.endDateValue ? this.props.startDateValue : this.props.endDateValue}
                                onChange={this.handleEndDateChange}
                                showDisabledMonthNavigation
                                customInput={this.props.isSm ? <StartDt /> : null}
                                disabledKeyboardNavigation={this.props.isSm}
                                withPortal={this.props.isSm}
                                showYearDropdown
                                renderCustomHeader={({
                                    date,
                                    decreaseMonth,
                                    changeMonth,
                                    changeYear,
                                    increaseMonth,
                                    prevMonthButtonDisabled,
                                    nextMonthButtonDisabled
                                }) => (
                                    <div className="datepicker-header">
                                        <a
                                            className="btn datepicker-header__button p-0 text-lightgray"
                                            onClick={decreaseMonth}
                                            disabled={prevMonthButtonDisabled}
                                        >
                                            <span className="icon-chevron-small-left"></span>
                                        </a>
                                        <span className="misdtpk" style={{ marginBottom: '0px', width: '100%' }}>
                                            <Select
                                                isSearchable={false}
                                                styles={customStylesForDate}
                                                value={months[new Date(date).getMonth()]}
                                                placeholder="Month"
                                                onChange={(e) => { changeMonth(e.value) }}
                                                options={months}
                                                className={`datatable-select ml-0 w-3p5rem h-init datepicker-header__control`}
                                                classNamePrefix="datatable-select"
                                            />
                                            <Select
                                                isDisabled={true}
                                                isSearchable={false}
                                                styles={customStylesForDate}
                                                // value={years[new Date(date).getFullYear()]}
                                                value={years[years.map(function (e) { return e.value; }).indexOf(new Date(date).getFullYear())]}
                                                placeholder="Year"
                                                onChange={(e) => { changeYear(e.value) }}
                                                options={years}
                                                className={`datatable-select ml-0 w-3p5rem h-init datepicker-header__control`}
                                                classNamePrefix="datatable-select"
                                            />
                                        </span>
                                        <a
                                            className="btn datepicker-header__button p-0 text-lightgray"
                                            onClick={increaseMonth}
                                            disabled={nextMonthButtonDisabled}
                                        >
                                            <span className="icon-chevron-tree-navi"></span>
                                        </a>
                                    </div>
                                    )}
                            />
                            <span className="icon-calendar text-gray"></span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withWindowDimensions(MisDatePicker);
