import React, { Component } from "react";
import moment from "moment";
import NavLink from "react-router-dom/NavLink";
import { IndexColor, checkExpirationToDate } from "../commons/service";

export default class leaderboardSectionListItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: this.props.data
        ? this.props.data
        : {
          category: {
            id: 0,
            imgUrl: "",
            name: ""
          },
          createdDt: 0,
          description: "",
          effDt: 0,
          expDt: 0,
          id: 0,
          imgUrl: "",
          title: ""
        },
      alternate: this.props.alternate ? this.props.alternate : false
    };
  }

  render() {
    const { data, alternate } = this.state;
    var expiration = data.contestExpiryDt;
    expiration = moment(expiration * 1000);
    expiration = expiration.format("MMMM DD, YYYY");

    if (expiration === "Invalid date") {
      expiration = null;
    }

    let imgUrl = data.contestCategory ? data.contestCategory.imgUrl : data.contestImg;
    let prefixExpiration = checkExpirationToDate(expiration);
    const stringExpiration = prefixExpiration
      ? "Ended last " + expiration
      : "Ends in " + expiration;

    return (
      <NavLink
        style={{ textDecoration: "none" }}
        to={"/rewards-and-incentives/leaderboard?contest=" + data.contestId}
      >
        <div>
          {alternate === false ? (
            <div
              style={{ background: IndexColor(data.contestId) }}
              className="rni-contest-list-item"
            >
              <div
                style={{ background: "url(" + data.contestImg + ")" }}
                className="rni-contest-list-item-image"
              ></div>
              <div className="rni-contest-list-item-category" style={{
                padding: data.category ? '10px' : '3px'
              }}>
                <div
                  alt={data.title}
                  style={{
                    background: "url(" + imgUrl + ")",
                    width: "100%",
                    height: "100%",
                    backgroundSize: "contain",
                    backgroundPosition: "center",
                    transform: "rotateZ(-15deg)",
                    filter: "drop-shadow(0px 0px 1px #414141)",
                    borderRadius: data.category ? "0%" : "50%"

                  }}
                ></div>
              </div>
              <div className="rni-contest-list-item-labels">
                <h3 style={{ textAlign: "left" }}>{data.contestTitle}</h3>
                <p
                  style={{ textAlign: "left" }}
                  className="rni-margin-top-bot-12"
                >
                  {data.contestDescription}
                </p>
                <div>
                  <p style={{ textAlign: "left" }}> {stringExpiration}</p>
                  <p style={{ textAlign: "left" }}>
                    <img
                      style={{ width: "20px", height: "20px" }}
                      src="/rni/users-solid.svg"
                    />
                    &nbsp;{data.contestTotalUsers}&nbsp;qualified
                  </p>
                </div>
              </div>
            </div>
          ) : (
              <div
                style={{ background: IndexColor(data.contestId) }}
                className="rni-contest-list-item-alternate"
              >
                <div className="rni-contest-list-item-category-alternate" style={{
                  padding: data.contestCategory ? '10px' : '3px'
                }}>
                  <div
                    alt={data.contestTitle}
                    style={{
                      background: "url(" + imgUrl + ")",
                      width: "100%",
                      height: "100%",
                      backgroundSize: "contain",
                      backgroundPosition: "center",
                      transform: "rotateZ(-15deg)",
                      filter: "drop-shadow(0px 0px 1px #414141)",
                      borderRadius: data.contestCategory ? "0%" : "50%"
                    }}
                  ></div>
                </div>
                <div className="rni-contest-list-item-labels">
                  <h3>{data.contestTitle}</h3>
                  <p className="rni-margin-top-bot-12">{data.contestDescription}</p>
                  <div>
                    <p>{stringExpiration}</p>
                    <p>
                      <img
                        style={{ width: "20px", height: "20px" }}
                        src="/rni/users-solid.svg"
                      />
                      &nbsp;{data.contestTotalUsers}&nbsp;qualified
                    </p>
                  </div>
                </div>
                <div
                  style={{ background: "url(" + data.contestImg + ")" }}
                  className="rni-contest-list-item-image-alternate"
                ></div>
              </div>
            )}
        </div>
      </NavLink>
    );
  }
}
