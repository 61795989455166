import React, { Component, Fragment } from 'react';
import { thousandSeparator } from './formatter';
import { decrypt, writeData } from '../../commons/utility';
import { apiModule, createQueryStrings } from '../../commons/apiCall';
import { LoaderGif2 } from '../../commons/loaderGif';
import { myConfig } from '../../commons/config';
import { mockPersistencyPolicyList } from '../../commons/mockData';
import moment from 'moment';
import ReactTable from 'react-table';
import handleSortOrder from '../misc/sortOrder';
import routeChange from '../../commons/routeChange';

class faPersistencyPolicyList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            policyList: [],
            columns: [
                {
                    Header: 'Policy Owner',
                    accessor: 'policyOwner',
                    Cell: props => <a className="text-info" onClick={() => this.onClickPolicyNumber(props.original.policyNumber)} style={{ cursor: "pointer" }}>{props.value}</a>
                },
                {
                    Header: 'Policy Number',
                    accessor: 'policyNumber',
                    Cell: props => <a className="text-info" onClick={() => this.onClickPolicyNumber(props.value)} style={{ cursor: "pointer" }}>{props.value}</a>

                },
                {
                    Header: 'Currency',
                    accessor: 'currency'
                },
                {
                    Header: 'Policy Status',
                    accessor: 'policyStatus'
                },
                {
                    Header: 'Plan Name',
                    accessor: 'planName',
                },
                {
                    Header: 'Paid To Date',
                    accessor: 'paidToDate'
                },
                {
                    Header: 'Collectible Annualized Premium',
                    accessor: 'collectibleAnnualizedPremium'
                },
                {
                    Header: 'Actual Annualized Premium',
                    accessor: 'actualAnnualizedPremium'
                },
            ],
            sortByField: null,
            sortOrder: null,
            defaultPageSize: 20
        }
    }

    componentDidMount() {
        this.fetchPersistencyPolicyList();
    }

    async fetchPersistencyPolicyList() {
        let { session, persistencyPolicyList, agentNumber } = this.props;

        let headerParams = {
            accountManagerId: decrypt(session.agentNumber),
            x_auth: session.access_token,
        };

        let filter = [];
        filter['workflowId'] = persistencyPolicyList.workflowId;
        filter['classProductType'] = persistencyPolicyList.classProductType;
        filter['agentNumber'] = agentNumber || decrypt(session.agentNumber);

        let data = await new Promise(function (resolve) {
            apiModule("policy_search", headerParams, null, createQueryStrings(filter))
                .then(function (response) {

                    // Mock data
                    if (myConfig.ENABLE_MOCK_DATA) {
                        response = mockPersistencyPolicyList;
                    }

                    let result = response ? response : [];
                    result = result ? result.map(policy => {
                        return {
                            policyOwner: policy.contractHolder.birthName,
                            policyNumber: policy.contractNumber,
                            currency: policy.currency,
                            policyStatus: policy.status,
                            planName: policy.productOfferingName,
                            paidToDate: Date.parse(policy.paidUntilDate) ? moment(policy.paidUntilDate).format('YYYY-MM-DD') : policy.paidUntilDate,
                            collectibleAnnualizedPremium: policy.cap,
                            actualAnnualizedPremium: policy.aap
                        }
                    }) : [];
                    resolve(result);
                })
                .catch(function (err) {
                    resolve(null);
                })
        });

        this.setState({
            isLoading: false,
            policyList: data ? data : [],
        })
    }

    getCollectibleAnnualizedPremiumTotal() {
        try {
            return this.state.policyList.map(policy => policy.collectibleAnnualizedPremium).reduce((sum, current) => sum + current);
        }
        catch {
            return 0;
        }
    }

    getActualAnnualizedPremiumTotal() {
        try {
            return this.state.policyList.map(policy => policy.actualAnnualizedPremium).reduce((sum, current) => sum + current);
        }
        catch {
            return 0;
        }
    }

    getPeriodPersistencyRate() {
        try {
            return `${((this.getActualAnnualizedPremiumTotal() / this.getCollectibleAnnualizedPremiumTotal()) * 100).toFixed(2)}%`;
        }
        catch {
            return "-";
        }
    }

    headerClickedHandler(sortByField) {
        this.setState({
            isLoading: true
        })
        const state = this.state;
        let sortOrder = handleSortOrder({ sortByField, state });
        this.handleSortAction(sortByField, sortOrder);
    }

    handleSortAction(sortByField, sortOrder) {
        let { policyList } = this.state;
        let newPolicyList = JSON.parse(JSON.stringify(policyList));
        newPolicyList.sort((a, b) => {
            console.log(typeof a[sortByField], typeof b[sortByField])
            if (typeof a[sortByField] == "string" && typeof b[sortByField] == "string") {
                if (sortOrder.includes("ASC")) return b[sortByField].localeCompare(a[sortByField]);
                if (sortOrder.includes("DESC")) return a[sortByField].localeCompare(b[sortByField]);
            }

            if (typeof a[sortByField] == "number" && typeof b[sortByField] == "number") {
                if (sortOrder.includes("ASC")) return b[sortByField] - a[sortByField];
                if (sortOrder.includes("DESC")) return a[sortByField] - b[sortByField];

            }
        })
        this.setState({
            sortByField,
            sortOrder,
            policyList: newPolicyList,
            isLoading: false
        })
    }

    onClickPolicyNumber = (item) => {
        this.props.setClientOrPolicy({ policyId: item });
        writeData('policy-client', { policyId: item, id: 1 });
        routeChange(this.props.history, "/dashboard/single-policy");
    }

    render() {
        let { isLoading, policyList } = this.state;
        let { togglePersistencyItem, persistencyPolicyList } = this.props;
        const loader = <div className="faPersistency-list-loader"><LoaderGif2 /></div>
        return (
            <div className="faPersistency-list-modal">
                <div className="faPersistency-list-container">
                    <div className="faPersistency-list-header">
                        <label onClick={() => togglePersistencyItem()} className="faPersistency-list-close">x</label>
                    </div>
                    <div className="ml-4">
                        <label className="font-weight-bold">{this.props.dateAndYear}</label>
                    </div>
                    {!isLoading && policyList.length === 0 && <p className="faPersistency-policy-no-data-show">NO DATA TO SHOW</p>}
                    {isLoading ? loader :
                        policyList.length > 0 &&
                        <Fragment>
                            <ReactTable
                                className={"faPersistency-list-table"}
                                resizable={false}
                                columns={this.state.columns}
                                data={policyList}
                                defaultPageSize={this.state.defaultPageSize}
                                getTheadThProps={(_state, _rowInfo, column) => ({
                                    onClick: () => {
                                        this.headerClickedHandler(column.id);
                                    },
                                    sortClassName: (
                                        this.state.sortByField == column.id ?
                                            (this.state.sortOrder == "ASC") ?
                                                "icon-chevron-small-up"
                                                : "icon-chevron-dropdown"
                                            : ""
                                    ),
                                    sortColor: (
                                        this.state.sortByField == column.id ?
                                            "text-black" : ""
                                    )
                                })}>
                            </ReactTable>
                            <div className="faPersistency-list-footer">
                                <label>Total</label>
                                <label>CAP - {thousandSeparator(this.getCollectibleAnnualizedPremiumTotal().toFixed(2))}</label>
                                <label></label>
                                <label>AAP - {thousandSeparator(this.getActualAnnualizedPremiumTotal().toFixed(2))}</label>
                                <label>{persistencyPolicyList.period}M Persistency Rate</label>
                                <label>{this.getPeriodPersistencyRate()}</label>
                            </div>
                        </Fragment>
                    }
                </div>
                <div onClick={() => togglePersistencyItem()} className="faPersistency-list-backdrop"></div>
            </div>
        )
    }
}

export default faPersistencyPolicyList
