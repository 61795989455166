import React, { Component } from 'react';
import { Loader } from '../commons/utilityViews.js';
import { withRouter } from 'react-router-dom';
import Auxi from '../components/auxi';
import { prettifyDate, calculateAge, numberWithCommas, writeData, readAllData, decrypt, encrypt } from '../commons/utility.js';
import { apiModule, createQueryStrings } from '../commons/apiCall';
import { isManager, checkLogin, checkPermissions, isInternalUser, getSessionInfo } from '../commons/security.js';
import moment from "moment";

class Alerts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            alertsList: null,
        };
        this.header = "Alerts and Updates"
    }

    async componentDidMount() {
        let that = this;
        that.fetchAlerts();
    }

    fetchAlerts = async() => {
        let session = this.props.getSession();

        let channel = this.props.getSession().channel;
       
        let isIU = isInternalUser(channel);
        
        let sessionInfo = await getSessionInfo();
        let permissionList = sessionInfo[0].permissions ? sessionInfo[0].permissions : [];
        let viewMIS = checkPermissions(["VIEW_MIS"], permissionList);

        let username = decrypt(this.props.getSession().username)
        let that = this;

        let headerParams = {
            accountManagerId: username,
            x_auth: session.access_token,
        };

        //MIS Users (which are HO users) should be able to access Alerts Page for Notifications
        if(isIU && !viewMIS) {
            headerParams = {
                accountManagerId: decrypt(session.view_as_email),
                x_auth: session.access_token,
            };
        }
        let payloadParams = {};
        let filter = [];
        filter['group_by_date'] = true;
        // filter['page_size'] = 5;

        let alertsList = [];
        return new Promise(function (resolve, reject) {
            apiModule("get_inbox_dashboard", headerParams, payloadParams, createQueryStrings(filter))
                .then(function (result) {

                    for (let i = 0; i < result.message.data.length; i++) {
                        let dateItem = result.message.data[i];

                        let dataItems = dateItem.data;
                        let alerts = [];

                        for (let j = dataItems.length-1; j >= 0; j--) {
                            let date_item = dataItems[j];

                            alerts.push({
                                text: date_item.message,
                                time: moment(date_item.created_at).fromNow()
                            })
                        }
                        alertsList.push({
                            date: moment(dateItem["_id"]).format('ll'),
                            alerts,
                        })
                    }
                    that.setState({ alertsList, isLoading: false })


                })
                .catch(function (err) {
                    that.setState({ alertsList: [], isLoading: false })


                })
        });
    }

    alerts = () => {
        let data = [
            {
                date: "Jan 9, 2019",
                alerts: [
                    {
                        text: "Merry Christmas: Merry Christmas Everybody!",
                        time: "3 mins ago"
                    },
                    {
                        text: "We have a new product launched! Go check it out.",
                        time: "30 mins ago"
                    },
                    {
                        text: "5 policies were rejected",
                        time: "55 mins ago"
                    },
                    {
                        text: "10 policies due are about to expire",
                        time: "1 hr ago"
                    },
                    {
                        text: "5 Upcoming Birthdays",
                        time: "3 mins ago"
                    },
                    {
                        text: "System Maintenance on February 15, 2019",
                        time: "30 mins ago"
                    },
                    {
                        text: "5 policies were rejected",
                        time: "55 mins ago"
                    },
                    {
                        text: "10 policies due are about to expire",
                        time: "1 hr ago."
                    }
                ]
            },
            {
                date: "Jan 10, 2019",
                alerts: [
                    {
                        text: "5 Upcoming Birthdays",
                        time: "3 mins ago",
                    },
                    {
                        text: "System Maintenance on February 15, 2019",
                        time: "30 mins ago"
                    },
                    {
                        text: "5 Policies were rejected",
                        time: "55 mins ago"
                    },
                    {
                        text: "10 policies due are about to expire",
                        time: "1 hr ago"
                    }
                ]
            }
        ];
        let alertsList = null;
        if (this.state.alertsList === null) {
            alertsList = (
                <Auxi>

                    <div className="alert__content">
                        <table className="table table-sm alert__table table-borderless">
                            <tr><td colspan={3}>No Records Found</td></tr>
                        </table>
                    </div>
                </Auxi>
            );
        } else {

            alertsList = this.state.alertsList.map((item, index) => {
                let alert = item.alerts.map((alert, index) => {
                    return (
                        <tr className="alert__row" key={index}>
                            <td className="alert__bar">
                                <div className="alert__icon"></div>
                                <div className="alert__line"></div>
                            </td>
                            <td className="alert__text">{alert.text}</td>
                            <td className="alert__time">{alert.time}</td>
                        </tr>
                    )
                });

                return (
                    <Auxi key={index}>
                        <p className="alert__date">{item.date}</p>
                        <div className="alert__content">
                            <table className="table table-sm alert__table table-borderless">
                                <tbody>
                                    {alert}
                                </tbody>
                            </table>
                        </div>
                    </Auxi>
                );
            });
        }

        return (
            <div className="alert">
                {alertsList}
            </div>

        );
    }

    render() {
        if (this.state.isLoading) {
            return (
                <Loader handleClose={this.hideModal} show={this.state.isLoading} />
            );
        } else {
            return (
                <div className="dashboard-section">
                    <div className="dashboard-container container-fluid p-3">
                        <div className="dashboard-header">
                            <h6 className="font-neo-bold text-darkgray">{this.header}</h6>
                            {this.props.getViewAs}
                        </div>
                        <div className="dashboard-body mt-4">
                            <div className="mt-3 bg-white p-3 shadow rounded">
                                {this.alerts()}
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
}

export default withRouter(Alerts);
