import React, { Component } from 'react';
import Menu from '../components/menu';
import SectionHeader from '../components/sectionHeader';
import ContestBanner from './contestBanner';
import BulletinSection from './bulletinSection';
import MiniLoading from '../components/miniLoading';
import { getContestBanners, getBulletinList } from '../commons/restEndpoint';
import moment from 'moment';

class home extends Component {

    constructor(props) {
        super(props)

        this.state = {
            page: 0,
            size: 5,
            isLoading: true,
            homeMenu: [{
                name: 'Bulletin',
                imgUrl: '/rni/bulletin.svg',
                link: '/rewards-and-incentives/bulletin'
            },
            {
                name: 'Contests',
                imgUrl: '/rni/contest.svg',
                link: '/rewards-and-incentives/contests'
            },
            {
                name: 'Leaderboard',
                imgUrl: '/rni/leaderboard.svg',
                link: '/rewards-and-incentives/leaderboard'
            },
            ],
            bulletinList: [],
            doneGettingBulletinList: false,
            bannerList: null,
        }
        this.fetchBulletinList = this.fetchBulletinList.bind(this);
    }

    async fetchBulletinList() {
        let { page, size } = this.state;
        let thisCom = this;
        var params = {
            page: page,
            size: size
        };
        let x = await getBulletinList(params, this.props.session.access_token);
        if (x) {
            if (x.data) {
                var y = x.data.content;
                var list = [];
                y.map(function (bulletin, index) {
                    let outputDate = "";
                    if (bulletin.expiryDt) {
                        let now = moment(new Date());
                        let convertedFetchedDate = moment(bulletin.expiryDt * 1000);
                        let fetchedDate = moment(convertedFetchedDate ? convertedFetchedDate : now);
                        let duration = moment.duration(now.diff(fetchedDate));
                        let outputDate = "h";
                        if (duration.asSeconds() <= 59) {
                            outputDate = "now";
                        } else if (duration.asMinutes().toFixed(0) <= 59 && duration.asMinutes().toFixed(0) >= 1) {
                            outputDate = duration.asMinutes().toFixed(0) + "m";
                        } else if (duration.asHours().toFixed(0) <= 24 && duration.asHours().toFixed(0) >= 1) {
                            outputDate = duration.asHours().toFixed(0) + "h";
                        } else if (duration.asDays().toFixed(0) <= 2) {
                            outputDate = duration.asDays().toFixed(0) + "d";
                        } else {
                            outputDate = convertedFetchedDate.format("MMMM D, YYYY");
                        }
                        console.log(outputDate);
                    } else {
                        outputDate = "No Expiry Date Found";
                    }
                    list.push({
                        title: bulletin.title,
                        type: bulletin.type,
                        id: bulletin.id,
                        imgSrc: bulletin.imgUrl,
                        date: outputDate,
                    });
                    return null;
                });
                this.setState({
                    bulletinList: list,
                    doneGettingBulletinList: true,
                });
            }
        }
    }

    componentWillMount() {
        this.fetchContestBanner();
        this.fetchBulletinList();
    }

    async fetchContestBanner(nextProps) {

        let that = this;
        let result = await getContestBanners(this.props.getSession().access_token)
        this.setState({
            bannerList: result ? result.data ? result.data.content ? result.data.content : [] : [] : [],
            isLoading: false
        })
    }

    render() {
        const { homeMenu, bannerList, isLoading, doneGettingBulletinList, bulletinList } = this.state;
        return (<div style={
            { textAlign: 'center' }
        }>
            <Menu menuList={homeMenu}
                callback={(menuSelected) => { }}
            /> <div className="container-fluid rni-home-body" >
                <div className="rni-home-content col-lg-12 col-md-12" >
                    <SectionHeader label="Hot Contests"
                        src="/rni/hot.svg" /> {!isLoading ?
                            <div style={{ width: '100%' }}
                                className="contest-banner-container" >
                                <ContestBanner bannerList={bannerList} /> </div>
                            :
                            <MiniLoading />}
                    <br />
                    {/* <SectionHeader label="Latest Updates" src="/rni/announcement.svg" />
                    {doneGettingBulletinList ?
                        <BulletinSection bulletinList={bulletinList} session={this.props.session} />
                        :
                        <div style={{ width: '50vw', margin: 'auto' }}><MiniLoading /></div>} */}
                </div>
            </div>
        </div>
        )
    }
}

export default home