import React, { Component, Fragment } from 'react';

class Confirmation extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  writeMobileData = (mobile, country, code) => mobile ? (
    <Fragment>
      <h5 className="policy-services-submodule__bolded-header">Phone Number</h5>
        <div className="d-flex align-items-center">
          {
            country
            ? <div className="policy-services-mobile-input__flag-container"><img className="policy-services-mobile-input__flag" src={`/img/flags/${country}.jpg`} /></div>
            : <div className="policy-services-mobile-input__flag-container"><img className="policy-services-mobile-input__flag" src={"/img/flags/Philippines.jpg"} /></div>
          }
          <div className="policy-services-mobile-input policy-services-mobile-input--confirmation d-flex">
            <span
              className={
                "policy-services-mobile-input__user-data " +
                "policy-services-mobile-input__user-data--country-code"
              }
          >{code ? "+" + code : ""}
            </span>
            <span
              className={
                "policy-services-mobile-input__user-data" +
                "policy-services-mobile-input__user-data--mobile-number"
              }>{mobile}</span>
          </div>
        </div>
    </Fragment>
  ) : null;

  writeEmailData = (email) => email ? (
    <Fragment>
      <h5 className="policy-services-submodule__bolded-header">E-mail</h5>
      <span>{email}</span>
    </Fragment>
  ) : null;

  render() {
    console.log(this.props);
    const {
      baseCountry,
      baseCountryCode,
      baseMobileNum,
      baseEmail,
      newCountry,
      newCountryCode,
      newMobileNum,
      newEmail,
      isAppliedToAll
    } = this.props;
    return (
      <article className="policy-services-submodule">
        <header className="d-flex flex-column">
          <h6 className="mr-auto mb-3">Select Policy 2/2: Confirmation</h6>
          <h6 className="mr-auto">Before proceeding, please wait if the updated information below is correct:</h6>
        </header>

        <article className="policy-services-submodule__user-area policy-services-submodule__user-area--contact-info d-flex flex-column">
          <section className="policy-services-submodule__child-container policy-services-submodule__child-container--confirmation">
            <div className="policy-services-submodule__child-content">
              <span className="policy-services-submodule__content-marker mb-3">Current:</span>
              {this.writeMobileData(baseMobileNum, baseCountry, baseCountryCode)}
            </div>
            <div className="policy-services-submodule__child-content">
              <span className="policy-services-submodule__content-marker mb-3">Updated:</span>
              {this.writeMobileData(newMobileNum, newCountry, newCountryCode)}
            </div>
          </section>
          <div className="policy-horizontal-line policy-horizontal-line--darker"></div>
          <section className="policy-services-submodule__child-container policy-services-submodule__child-container--confirmation">
            <div className="policy-services-submodule__child-content">
              {this.writeEmailData(baseEmail)}
            </div>
            <div className="policy-services-submodule__child-content">
              {this.writeEmailData(newEmail)}
            </div>
          </section>
          <div className="policy-horizontal-line policy-horizontal-line--darker"></div>
          <section className="policy-services-submodule__child-container policy-services-submodule__child-container--confirmation">
            <div className="policy-services-submodule__child-content policy-services-submodule__child-content--apply-policies">
              <h5 className="policy-services-submodule__bolded-header">Apply changes to all policy?</h5>
              {isAppliedToAll ? "YES": "NO"}
            </div>
          </section>
        </article>
      </article>
    )
  }
}


export default Confirmation;
