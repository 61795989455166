import React, { Component } from 'react';
import ReactTable from 'react-table';
import { Loader, ReportLoader } from '../commons/utilityViews.js';

import { withRouter } from 'react-router-dom';
import withWindowDimensions from '../commons/withWindowDimensions';
import Auxi from '../components/auxi';
import BirthdayListMobile from '../components/BirthdayList/Mobile/BirthdayListMobile';
import { myConfig } from '../commons/config.js';
import { prettifyDate, calculateAge, writeData, readAllData, encrypt, decrypt } from '../commons/utility.js';
import { apiModule, createQueryStrings } from '../commons/apiCall';
import TableLoader from '../components/TableLoader/tableLoader';
import TablePagination from '../components/tablePagination';
import ReportsMobile from '../components/Reports/Mobile/ReportsMobile';
import handleSortOrder from '../components/misc/sortOrder';

class BirthdayListView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            error: '',
            birthdays: [],
            loadTable: false,
            rows: -1,
            pages: myConfig.PAGE_SIZE,
            currentPage: 0,
            totalSize: 0,
            sortByField: null,
            sortOrder: null,
        };
        this.pageHeader = "Birthday List"
    }

    componentDidMount() {
        this.setState({
            isLoading: true
        }, () => {
            this.getTableData();
        });
    }

    errorMessage = (message) => {
        if (message) {
            this.setState({ error: message })
        }
    }

    changePage = (pageNumber) => {
        console.log("changePage " + pageNumber + " : " + this.state.currentPage);
        if (pageNumber.selected != null) {
            pageNumber = pageNumber.selected;
            if (this.state.currentPage != pageNumber) {
                this.beginLoading(pageNumber);
                this.search(pageNumber);
            }

        } else if (this.state.currentPage != pageNumber) {

            this.beginLoading(pageNumber);

            this.search(pageNumber);
        }
    }
    getTableData() {
        this.setState({
            currentPage: 0
        }, async () => {
            await this.fetchBirthdayList(this.props.getSession().access_token, decrypt(this.props.getSession().agentNumber));
        });
    }

    search = (pageNumber) => {
        console.log("search " + pageNumber);
        let that = this;
        let session = that.props.getSession();
        let headerParams = {
            accountManagerId: decrypt(session.agentNumber),
            // accountManagerId: username,
            x_auth: session.access_token,
            datasetId: 'birthdays'
        };
        let filter = [];
        filter['pageNumber'] = pageNumber + 1;
        filter['pageSize'] = myConfig.PAGE_SIZE;

        if (that.state.sortByField != null) {
            filter['sortBy'] = that.state.sortByField + ',' + that.state.sortOrder
        }
        let payloadParams = {};
        apiModule("get_dashboard_dataset", headerParams, payloadParams, createQueryStrings(filter))
            .then(function (response) {

                let result = [];
                let totalSize = 0;
                if (Array.isArray(response)) {
                    result = response[0].data.paginatedBirthdays.content;
                    totalSize = response[0].data.paginatedBirthdays.totalSize;
                } else {
                    result = response.data.paginatedBirthdays.content;
                    totalSize = response.data.paginatedBirthdays.totalSize;
                }

                let numOfPages = parseInt((totalSize / myConfig.PAGE_SIZE), 10);

                if ((totalSize % myConfig.PAGE_SIZE) > 0) {
                    numOfPages++;
                }

                let birthdays = [];

                if (result) {

                    for (let i = 0; i < result.length; i++) {

                        var location = "";
                        var accountManager = "";

                        if (result[i].accountManager != null && result[i].accountManager.length > 0) {
                            accountManager = result[i].accountManager.name;
                        }

                        birthdays.push(
                            {
                                // id: result[i].accountManager.agentNumber,
                                NAME: result[i].name,
                                BIRTHDATE: prettifyDate(result[i].birthDate),
                                // contact: result[i].contactNum,
                                // email: result[i].email
                            }
                        );
                    }

                } else {
                    that.showError("No Account Found");
                }

                that.setState({
                    birthdays: birthdays,
                    loadTable: false,
                    totalSize: totalSize,
                    pages: numOfPages,
                    currentPage: pageNumber,
                    showTable: true,
                    error: '',
                    isLoading: false
                });
            })
            .catch(function (err) {
                console.log(err);
                that.setState({
                    isLoading: false,
                    loadTable: false,
                    currentPage: pageNumber,
                }, () => {
                    that.errorMessage(myConfig.GENERIC_ERROR_MESSAGE);
                    console.log(err.message)
                });
            })

    }

    fetchBirthdayList = (access_token, agentNumber) => {
        let that = this;
        return new Promise(function (resolve, reject) {

            let session = that.props.getSession();
            let headerParams = {
                accountManagerId: decrypt(session.agentNumber),
                // accountManagerId: username,
                x_auth: session.access_token,
                datasetId: 'birthdays'
            };
            let filter = [];
            filter['pageNumber'] = (that.state.currentPage + 1);
            filter['pageSize'] = myConfig.PAGE_SIZE;
            //filter['sortBy'] =myConfig.defaultPageSize;


            let payloadParams = {};
            apiModule("get_dashboard_dataset", headerParams, payloadParams, createQueryStrings(filter))
                .then(function (response) {

                    let result = [];
                    let totalSize = 0;
                    if (Array.isArray(response)) {
                        result = response[0].data.paginatedBirthdays.content;
                        totalSize = response[0].data.paginatedBirthdays.totalSize;
                    } else {
                        result = response.data.paginatedBirthdays.content;
                        totalSize = response.data.paginatedBirthdays.totalSize;
                    }

                    let numOfPages = parseInt((totalSize / myConfig.PAGE_SIZE), 10);

                    if ((totalSize % myConfig.PAGE_SIZE) > 0) {
                        numOfPages++;
                    }

                    let birthdays = [];

                    if (result) {

                        for (let i = 0; i < result.length; i++) {

                            var location = "";
                            var accountManager = "";

                            if (result[i].accountManager != null && result[i].accountManager.length > 0) {
                                accountManager = result[i].accountManager.name;
                            }

                            birthdays.push(
                                {
                                    // id: result[i].accountManager.agentNumber,
                                    NAME: result[i].name,
                                    BIRTHDATE: prettifyDate(result[i].birthDate),
                                    // contact: result[i].contactNum,
                                    // email: result[i].email
                                }
                            );
                        }

                        that.setState({
                            birthdays: birthdays
                        });

                    } else {
                        that.showError("No Account Found");
                    }

                    that.setState({
                        birthdays: birthdays,
                        loadTable: false,
                        totalSize: totalSize,
                        pages: numOfPages,
                        showTable: true,
                        error: '',
                        isLoading: false
                    });
                })
                .catch(function (err) {
                    console.log(err);
                    that.setState({
                        isLoading: false,
                        loadTable: false,
                    }, () => {
                        that.errorMessage(myConfig.GENERIC_ERROR_MESSAGE);
                        console.log(err.message)
                    });
                })
            // let result = null;



        });
    }


    //SHOW ERROR IF ERROR STATE IS NOT NULL
    showError = () => {
        let message = '';
        if (this.state.error !== '') {
            message =
                <div className="bg-error py-1 px-4">
                    <p className="text-white mb-0 font-neo-semibold">ERROR: {this.state.error}</p>
                </div>
        }
        return message;
    }

    beginLoading = (pageNumber) => {
        this.setState({
            // isLoading: true,
            loadTable: true,
            currentPage: pageNumber
        })
    }

    handleSortAction = (sortByField, sortOrder) => {
        this.setState({
            sortByField: sortByField,
            sortOrder: sortOrder,
            // isLoading: true
            loadTable: true,
        }, () => {
            this.search(this.state.currentPage);
        })
    }
    headerClickedHandler = (sortByField) => {
        let sortOrder = handleSortOrder({ sortByField: sortByField, state: this.state });
        this.handleSortAction(sortByField, sortOrder)
    }

    render() {
        const customNames = [
            {
                Header: "Name",
                accessor: "NAME",
            },
            {
                Header: "Date of Birth",
                accessor: "BIRTHDATE"
            }
        ]
        if (this.state.isLoading) {
            return (
                <ReportLoader handleClose={this.hideModal} show={this.state.isLoading} />

            );
        } else {
            let dataView = (
                <ReactTable
                    className={`mt-5 rt-no-hover`}
                    loading={this.state.loadTable}
                    LoadingComponent={TableLoader}
                    resizable={false}
                    columns={customNames}
                    defaultPageSize={myConfig.PAGE_SIZE}
                    minRows={0}
                    search={(pageNumber) => this.search(pageNumber)}
                    rows={this.state.rows}
                    onPageChange={this.changePage}
                    pages={this.state.pages}
                    data={this.state.birthdays}
                    manual
                    // onFetchData={(state, instance) => {
                    //     this.changePage(state.page);
                    // }}
                    getTheadThProps={(state, rowInfo, column, instance) => ({
                        onClick: () => {
                            this.headerClickedHandler(column.id);
                        },
                        sortClassName: (
                            this.state.sortByField == column.id ?
                                (this.state.sortOrder == "ASC" ?
                                    "icon-chevron-small-up"
                                    : "icon-chevron-dropdown")
                                : ""
                        ),
                        sortColor: (
                            this.state.sortByField == column.id ?
                                "text-black"
                                : ""
                        )
                    })}
                    totalSize={this.state.totalSize}
                    page={this.state.currentPage}
                />
            );
            if (this.props.isSm) {
                dataView = (
                    // <BirthdayListMobile
                    //     data={this.state.birthdays}
                    //     search={(pageNumber) => this.search(pageNumber)}
                    //     rows={this.state.rows}
                    //     pages={this.state.pages}
                    //     currentPage={this.state.currentPage}
                    //     customNames={customNames}
                    // />
                    <ReportsMobile
                        title={[
                            {
                                Header: 'Name',
                                accessor: 'NAME',
                                hasCaret: true,
                                onClick: () => {
                                    this.headerClickedHandler('NAME');
                                }
                            },
                            {
                                Header: 'Date of Birth',
                                accessor: 'BIRTHDATE',
                                hasCaret: true,
                                onClick: () => {
                                    this.headerClickedHandler('BIRTHDATE');
                                }
                            },
                        ]}

                        customNames={customNames}
                        data={this.state.birthdays}
                        sortByField={this.state.sortByField}
                        sortOrder={this.state.sortOrder}
                    >
                        <TablePagination
                            css="mt-2"
                            data={this.state.birthdays}
                            page={this.state.currentPage}
                            totalSize={this.state.totalSize}
                            onPageChange={this.changePage}
                            pageSize={myConfig.PAGE_SIZE}
                            pages={this.state.pages}
                        />
                        {this.state.loadTable ? <TableLoader loading={true} /> : ""}
                    </ReportsMobile>
                );
            }
            return (
                <div className="dashboard-section">
                    {this.showError()}
                    <div className="dashboard-container container-fluid p-3">
                        <div className="dashboard-header">
                            <h6 className="font-neo-bold text-darkgray">{this.pageHeader}</h6>
                            {this.props.getViewAs}
                        </div>
                        <div className="dashboard-body mt-4">
                            {dataView}
                        </div>
                    </div>
                </div>
            );
        }
    }
}

export default withRouter(withWindowDimensions(BirthdayListView));
