import React, { Component } from 'react';
import '../../css/mis.scss';
import { apiModule, createQueryStrings } from '../../commons/apiCall';
import { formatValues, formatDecimal, thousandSeparator, roundNumber, formatValuesNoDecimals } from './formatter';
import { haveValue, charts } from '../../commons/misConfig';
import ReactTooltip from 'react-tooltip';
import CommentIcon from './commentIcon';

class PercentAchieved extends Component {
    constructor(props) {
        super(props);
        this.state = {
            achievedPercentage: 0,
            achievedPremiumRate: 0,
            plannedPremiumRate: 0,
            isLoading: true,
            toolTipContent: "",
            isCommentVisible: false,
            totalComments: 0
        }
        this.getPercentAchieved = this.getPercentAchieved.bind(this);
        this.setPercentAchievedTooltip = this.setPercentAchievedTooltip.bind(this);
    }

    componentDidMount() {
        this.getPercentAchieved(this.props);
        this.props.getCommentCount(this, 'achievement', this.props.access_token);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.willUpdate) {
            this.getPercentAchieved(nextProps);
        }

        if(nextProps.willUpdateCommentCount){
            nextProps.getCommentCount(this, 'achievement', nextProps.access_token);
        }
    }

    async getPercentAchieved(nextProps) {
        this.setState({
            isLoading: true
        });

        let thisComponent = this;
        let headerParams = {
            x_auth: nextProps.access_token
        };
        let filter = [];
        filter['period'] = haveValue(nextProps.period.value) ? nextProps.period.value : '';
        filter['metric'] = haveValue(nextProps.metric.value) ? nextProps.metric.value : '';
        filter['productOfferingType'] = haveValue(nextProps.product.value) ? nextProps.product.value : '';
        filter['channel'] = haveValue(nextProps.channel.value) ? nextProps.channel.value : '';
        filter['startDate'] = haveValue(nextProps.startDate) ? nextProps.startDate : '';
        filter['endDate'] = haveValue(nextProps.endDate) ? nextProps.endDate : '';
        filter['contractStatus'] = haveValue(nextProps.status.value) ? nextProps.status.value : '';
        filter['targetType'] = haveValue(nextProps.target.value) ? nextProps.target.value : '';
        apiModule("get_percent_achieved", headerParams, null, createQueryStrings(filter))
            .then(function (result) {
                let response = result.goalDetails[0];
                thisComponent.setState({
                    achievedPercentage: response.achievedPercentage ? response.achievedPercentage : 0,
                    achievedPremiumRate: response.achievedPremiumRate ? response.achievedPremiumRate : 0,
                    plannedPremiumRate: response.plannedPremiumRate ? response.plannedPremiumRate : 0,
                    isLoading: false
                });

            })
            .catch(function (err) {
                console.log(err);
            });
    }

    setPercentAchievedTooltip(metric, achievedPremiumRate, plannedPremiumRate, achievedPercentage){
        let currentMetric = metric ? (metric.label + " "): "";
        let tooltip = (
            <div className="mis-percent-achieved-tooltip">
                {achievedPremiumRate ? <div>{currentMetric} Achieved: {thousandSeparator(roundNumber(achievedPremiumRate, 0))}</div> : null}
                {plannedPremiumRate ? <div>{currentMetric} Target: {thousandSeparator(roundNumber(plannedPremiumRate, 0))}</div> : null}
                {achievedPercentage ? <div>% Achieved: {achievedPercentage}%</div> : null}
            </div>
        )

        this.setState({
            toolTipContent: tooltip
        })
    }

    render() {
        const { currMetric, coinLoader, onClickCommentIcon } = this.props;
        const { achievedPercentage, achievedPremiumRate, plannedPremiumRate, isLoading, toolTipContent, totalComments } = this.state;
        let calculatedPercentage = achievedPercentage ? achievedPercentage * .8 : 0;
        let hasData = achievedPremiumRate > 0 || plannedPremiumRate > 0;
        return (
            <div className="container mis-container col-sm-12 col-md-12 col-lg-12 col-xl-12">
                {/* <div className="mis-comment-icon-container" onClick={() => onClickCommentIcon(charts.achievement)}>
                    <div className="mis-comment-icon">
                        <img src = "/mis/comments.svg" className="mis-comment-icon-img"/>
                        <span className="mis-comment-count">
                            {formatValuesNoDecimals(totalComments)}
                        </span>
                    </div>
                </div> */}
                <CommentIcon
                    chart={charts.achievement}
                    onClickCommentIcon={onClickCommentIcon} 
                    totalComments={totalComments} />
                <div className="card mis-card bg-white shadow-sm rounded" style={{ height: "146px" }}>
                    <div className="card-body mis-card-body">
                        <div className="card-title mis-card-title">
                            <span className="d-flex dashboard-data-header__text align-items-end font-neo-semibold text-headercolor pt-3 pl-3">% Achieved</span>
                        </div>
                        { isLoading ? 
                            coinLoader:
                            !hasData ? <p className="mis-no-data-show">NO DATA TO SHOW</p> :
                            <span>
                                <div className="mis-progress-target-container">
                                    <div className="mis-progress-target-text">
                                        {formatValuesNoDecimals(plannedPremiumRate)}
                                    </div>
                                    <div className="mis-progress-target" />
                                </div>
                                <div className="progress mis-progress-bar">
                                    <div className={'progress-bar mis-percent-achieved achieved-' + (calculatedPercentage >= 80 ? "complete" : "incomplete")}
                                        style={{ width: calculatedPercentage + "%" }}
                                        onMouseEnter={() => this.setPercentAchievedTooltip(currMetric, achievedPremiumRate, plannedPremiumRate, achievedPercentage)}
                                        onMouseLeave={() => this.setPercentAchievedTooltip(currMetric, achievedPremiumRate, plannedPremiumRate, achievedPercentage)}
                                        data-tip="" data-for="percentAchievedTooltip"
                                    >
                                        <div style={{
                                            position: achievedPercentage >= 16 ? "inherit" : "absolute",
                                            left: achievedPercentage >= 16 ? 0 : achievedPercentage + 2 + "%"
                                        }}>
                                            <div>
                                                {achievedPercentage + "%"}
                                                <br />
                                                <span style={{ fontWeight: "500", fontSize: "13px" }}>{formatValues(achievedPremiumRate)}</span>
                                            </div>
                                        </div>
                                        <ReactTooltip id="percentAchievedTooltip">{toolTipContent}</ReactTooltip>
                                    </div>
                                </div>
                            </span>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default PercentAchieved;


