import React, { Component, Fragment } from 'react';
import { withRouter, Link } from 'react-router-dom';
import withWindowDimensions from '../commons/withWindowDimensions';
import ErrorMessage from "../components/errorMessage";
import Select from "react-select";
import { MonthYearField } from "../components/Fields/customFields"
import { apiModule, createQueryStrings } from "../commons/apiCall";
import { ReportLoader } from '../commons/utilityViews.js';
import { decrypt, checkSearch, checkDateRange, fixName } from "../commons/utility";
import { myConfig } from "../commons/config.js";
import LoaderGif from '../commons/loaderGif';
import * as moment from "moment";
import Document from "../components/Documents/document";
import DownloadSuccessModal from "../components/Reports/DownloadSuccessModal";
class CompanyPersistency extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errorMessage: "",
            isLoadingDocs: false,
            isLoading: true,
            documents: [],

            documentTypeOptions: [],

            type: {},
            date: "",

            showDownloadSuccessModal: false,
        }

        this.pageHeader = "Company Persistency"
    }

    async componentDidMount() {
        let documentTypeOptions = [];
        let type = {};
        try {
            const documentTypes = await this.fetchDocumentTypes();
            documentTypeOptions = this.createDocumentTypeOptions(documentTypes);
            type = documentTypeOptions.length > 0 ? documentTypeOptions[0] : {};
        }
        catch (error) {
            this.displayError(myConfig.GENERIC_ERROR_MESSAGE);
        }

        this.setState({ isLoading: false, documentTypeOptions, type });
    }

    async fetchDocumentTypes() {
        const jwt = this.props.getSession().access_token;
        const headerParams = { x_auth: jwt };

        return await apiModule("get_persistency_document_types", headerParams);
    }

    createDocumentTypeOptions(object) {
        const choices = [];
        Object.keys(object).forEach(key => {
            const choice = { label: "", value: "" };
            choice.label = object[key];
            choice.value = key;
            choices.push(choice);
        });
        return choices;
    }

    handleUpdateDate = (date) => {
        this.setState({ date })
    }

    handleClear = () => {
        this.setState({
            date: "",
        });
    }

    handleSubmit = async () => {
        await this.doSearch();
    }

    displayError = (errorMessage) => {
        if (errorMessage) {
            this.setState({ errorMessage: errorMessage });
        }
    }

    async fetchDocuments(type, dateTo = "",) {
        const jwt = this.props.getSession().access_token;
        const headerParams = { x_auth: jwt };
        const queryString = createQueryStrings({ type, dateTo });

        let returnValue;
        const response = await apiModule('get_documents', headerParams, null, queryString);
        if (response.hasOwnProperty("error")) {
            returnValue = { ok: false, documents: [] }
        }
        else {
            returnValue = { ok: true, documents: response };
        }

        return returnValue;
    }

    renderFields(type = "") {
        const dateNow = new Date();
        dateNow.setMonth(dateNow.getMonth() - 1);
        type = type.toLowerCase();
        let fields = (
            <Fragment>
                <MonthYearField
                    maxDate={dateNow}
                    minDate={new Date("2019-01-01")}
                    value={this.state.date}
                    handleUpdate={this.handleUpdateDate}
                />
            </Fragment>
        )

        switch (type) {
            case "":
                fields = "";
        }

        return fields;
    }

    hideError = () => {
        this.setState({ errorMessage: "" });
    }

    closeModal = () => {
        this.setState({
            showDownloadSuccessModal: false,
        })
    }

    async doSearch() {
        const date = this.state.date;
        // Clear document list
        this.setState({ documents: [], success: false, successMessage: "", rawDocuments: [] });
        this.hideError();
        if (date) {
            const lastDate = new Date(date.getFullYear(), date.getMonth() + 1, 0)

            const dateTo = moment(lastDate).format("YYYY-MM-DD");
            try {
                this.setState({ isLoadingDocs: true })
                const documentResponse = await this.fetchDocuments(
                    this.state.type.value,
                    dateTo,
                );
                const documents = documentResponse.documents.map((document, ctr) => {
                    return {
                        link: `data:application/pdf;base64,${document.data}`,
                        name: document.fileName
                    };
                })

                if (documents.length === 0) {
                    this.displayError(`There is no ${this.state.type.label} on the selected Date of Measurement ${moment(date).format("YYYY-MM")}`);
                }
                else {
                    this.setState({ documents, showDownloadSuccessModal: true, isLoadingDocs: false }, () => {
                        document.querySelector(".js-download-link").click();
                    });
                }
            } catch (e) {
                this.displayError(myConfig.GENERIC_ERROR_MESSAGE);
            } finally {
                this.setState({ isLoadingDocs: false });
            }
        } else {
            this.displayError('Invalid input, Date of Measurement cannot be empty');
        }
    }

    render() {
        if (this.state.isLoading) {
            return (
                <ReportLoader show={this.state.isLoading} />
            )
        } else {
            return (
                <div className="dashboard-section overflow-hidden">
                    <ErrorMessage message={this.state.errorMessage} />
                    <DownloadSuccessModal
                        showModal={this.state.showDownloadSuccessModal}
                        closeModal={this.closeModal}
                        subText=""
                    />
                    <div className="dashboard-container container-fluid p-3">
                        <div className="dashboard-header">
                            <h6 className="font-neo-bold">
                                <Link className="text-darkgray text-decoration-none" to="/dashboard/reports">Reports</Link>
                                <span className="dashboard-header__subheader text-headercolor"> | {this.pageHeader}</span>
                            </h6>
                        </div>
                        <div className="dashboard-body">
                            <div className="dashboard-datatable mt-4 documents-report__search">
                                <div className="d-flex flex-wrap">
                                    <div className="w-100">
                                        <Select
                                            options={this.state.documentTypeOptions}
                                            isSearchable={false}
                                            className={`datatable-select ml-0 mr-md-2 ${this.props.isSm ? 'report-dropdown-inner-mobile w-100 mb-2' : 'w-25'}`}
                                            classNamePrefix="datatable-select"
                                            value={this.state.type}
                                            onChange={(value) => {
                                                this.hideError();
                                            }}
                                            styles={{
                                                option: (provided) => ({
                                                    ...provided,
                                                    fontFamily: "neo_semibold",
                                                    fontSize: ".6rem",
                                                    color: "#9A9B9C",
                                                    backgroundColor: "white",
                                                }),
                                                menu: () => ({
                                                    position: "absolute",
                                                    border: 0,
                                                    zIndex: "1090",
                                                    backgroundColor: "white",
                                                    borderRadius: "5px",
                                                    boxShadow: "2px 2px 2px #DDDEE0",
                                                }),
                                            }}
                                        />
                                    </div>
                                    <div className={`d-flex mt-2 ${this.props.isSm ? "flex-wrap w-100" : ""}`}>
                                        {this.renderFields(this.state.type.value)}
                                        <a
                                            className={`lh-1 d-flex flex-grow-0 mt-3 mt-md-0 justify-content-center align-items-center btn btn-dropdowncolor rounded-pill text-uppercase ${this.props.isSm ? "font-p75rem report-generate-btn-mobile" : "font-p65rem report-clear-btn"} font-neo-semibold text-white datatable-search__btn ${this.props.isSm ? "w-100 ml-0" : "ml-1"}`}
                                            onClick={() => this.handleClear()}>
                                            Clear
                                        </a>
                                    </div>
                                </div>
                                <div className="d-flex mt-4">
                                    <a
                                        className={`lh-1 d-flex flex-grow-0 mt-3 mt-md-0 justify-content-center align-items-center btn btn-dropdowncolor rounded-pill text-uppercase ${this.props.isSm ? "font-p75rem report-generate-btn-mobile" : "font-p65rem report-generate-btn"} font-neo-semibold text-white datatable-search__btn ${this.props.isSm ? "w-100" : ""}`}
                                        onClick={() => this.handleSubmit()}>
                                        Generate
                                    </a>
                                </div>
                            </div>
                            <ul className="documents-report__results">
                                {this.state.isLoadingDocs ? (
                                    <div className="mt-4 mb-4">
                                        <LoaderGif />
                                    </div>
                                ) : (
                                    this.state.documents.map(document => <Document downloadable={true} name={document.name} link={document.link} key={document.link} />)
                                )}
                            </ul>
                        </div>
                    </div>
                </div >
            )
        }
    }
}

export default withRouter(withWindowDimensions(CompanyPersistency));
