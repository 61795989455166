import React, { Component } from 'react';
import { ReportLoader } from '../commons/utilityViews.js';
import { decrypt, readAllData } from '../commons/utility.js';
import { withRouter } from 'react-router-dom';
import routeChange from '../commons/routeChange.js';
import { apiModule } from '../commons/apiCall.js';
import Auxi from '../components/auxi';
import { myConfig } from '../commons/config.js';
import HelpInfo from '../components/ChangePassword/helpInfo.js';

class ChangePass extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            error: "",
            message: "",
            userObject: {
                oldPassword: '',
                newPassword: '',
                confirmPassword: ''
            },
            toggles: {
                oldPassword: false,
                newPassword: false,
                confirmPassword:  false
            },
            isCurrentShown: false,
            showHelpInfo: true,
            closeHelpInfo: false,
        };
        this.pageHeader = "Change Password"
    }
    async componentDidMount() {
        let that = this;
        let session = this.props.getSession();
        if (session.access_token === undefined || session.access_token === null || session.access_token === "") {
            readAllData('session')
                .then(function (data) {
                    for (var dt of data) {
                        console.log(dt);
                        dt.agentNumber = decrypt(dt.agentNumber);
                        dt.name = decrypt(dt.name);
                        dt.username = decrypt(dt.username);
                        that.props.setUserSession(dt);
                    }
                });
        }

    };

    hasMetPasswordPolicy = (password) => {
        if (password === null || password === undefined) {
            console.log("Error: Password is blank");
            return false;
        }

        if (password.length < 8) {
            console.log("Error: Password must contain at least 8 characters!");
            return false;
        }

        let format = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
        if (!format.test(password)) {
            console.log("Error: password must contain special characters");
            return false;
        }
        // let re = /[a-z]/;
        // if(!re.test(password)) {
        //   console.log("Error: password must contain at least one lowercase letter (a-z)!");

        //   return false;
        // }
        let reU = /[A-Z]/;
        if (!reU.test(password)) {
            console.log("Error: password must contain at least one uppercase letter (A-Z)!");
            return false;
        }

        return true;
    }

    //SHOW ERROR IF ERROR STATE IS NOT NULL
    showError = () => {
        let message = '';
        if (this.state.error !== '') {
            message =
                <div className="bg-error py-1 px-4">
                    <p className="text-white mb-0 font-neo-semibold">ERROR: {this.state.error}</p>
                </div>
        } else if (this.state.message !== '') {
            message =
                <div className="bg-success py-1 px-4">
                    <p className="text-white mb-0 font-neo-semibold">{this.state.message}</p>
                </div>
        }
        return message;
    }

    handleChanges(e) {
        let userObject = this.state.userObject;
        userObject[e.target.name] = e.target.value;
        this.setState({ userObject });
    }

    errorMessage = (message) => {
        if (message) {
            this.setState({ error: message })
        }
    }

    showMessage = (message) => {
        if (message) {
            this.setState({ message: message })
        }
    }
    cancelButton = (that) => {
        if (window.confirm("Are you sure you want to cancel?")) {
            routeChange(this.props.history, "/dashboard/user-info");
            //routeChange(that.props.history, "/dashboard/dashboard");
        }
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.changePassword(this);
        } else {

        }
    }

    changePassword = (that) => {
        if (!that.hasMetPasswordPolicy(that.state.userObject.newPassword)) {
            this.errorMessage("The new password does not meet the minimum criteria");

        } else if (that.state.userObject.confirmPassword === that.state.userObject.newPassword) {
            let session = that.props.getSession();


            let headerParams = {
                accountManagerId: session.agentNumber,
                x_auth: session.access_token,
            };

            if (session.username === undefined || session.username === null || session.username === "") {
                readAllData('session')
                    .then(function (data) {
                        for (var dt of data) {

                            dt.agentNumber = decrypt(dt.agentNumber);
                            dt.name = decrypt(dt.name);
                            dt.username = decrypt(dt.username);

                            that.props.setUserSession(dt);
                            that.setState({
                                isLoading: true
                            })
                            let payloadParams = {
                                password: that.state.userObject.oldPassword,
                                new_password: that.state.userObject.newPassword,
                                username: decrypt(dt.username),
                                appId: myConfig.APP_ID,
                                access_token: that.props.getSession().access_token
                            };


                            return new Promise(function (resolve, reject) {
                                apiModule("user_change_password", headerParams, payloadParams, '', true)
                                    .then(function (result) {
                                        if (result.result === "failed" || result.error) {
                                            that.errorMessage("Something went wrong. Please check your current password and try again or contact IT.Helpdesk@allianzpnblife.ph");
                                        } else {
                                            that.showMessage("Change Password is successful ");
                                        }
                                        console.log(result.result);
                                        that.setState({
                                            isLoading: false,
                                            userObject: {
                                                oldPassword: '',
                                                newPassword: '',
                                                confirmPassword: ''
                                            }
                                        })
                                        resolve();
                                    })
                                    .catch(function (err) {
                                        that.errorMessage("Something went wrong. Please check your current password and try again or contact IT.Helpdesk@allianzpnblife.ph");
                                        that.setState({
                                            isLoading: false,
                                            userObject: {
                                                oldPassword: '',
                                                newPassword: '',
                                                confirmPassword: ''
                                            }
                                        })
                                        resolve([]);
                                    })
                            })


                        }
                    });
            }
            else {
                that.setState({
                    isLoading: true
                })
                let payloadParams = {
                    password: that.state.userObject.oldPassword,
                    new_password: that.state.userObject.newPassword,
                    username: decrypt(that.props.getSession().username).split('@')[0],
                    appId: myConfig.KEYCLOAK_APPID,
                    access_token: that.props.getSession().access_token
                };

                return new Promise(function (resolve, reject) {
                    apiModule("user_change_password", headerParams, payloadParams, '', true)
                        .then(function (result) {
                            if (result.result === "failed" || result.error) {
                                that.errorMessage("Something went wrong. Please check your current password and try again or contact IT.Helpdesk@allianzpnblife.ph");
                            } else {
                                that.showMessage("Change Password is successful ");
                            }
                            console.log(result.result);
                            that.setState({
                                isLoading: false,
                                userObject: {
                                    oldPassword: '',
                                    newPassword: '',
                                    confirmPassword: ''
                                }
                            })
                            resolve();
                        })
                        .catch(function (err) {
                            that.errorMessage("Something went wrong. Please check your current password and try again or contact IT.Helpdesk@allianzpnblife.ph");
                            that.setState({
                                isLoading: false,
                                userObject: {
                                    oldPassword: '',
                                    newPassword: '',
                                    confirmPassword: ''
                                }
                            })
                            resolve([]);                            
                        })
                })

            }

        } else {
            this.errorMessage("Passwords do not match");
        }

    }

    showCurrentPassword = (item) => {
        console.log("AHH")
        let toggles = this.state.toggles;
        
        toggles[item] = !toggles[item];
        
        this.setState({
            toggles
        });
    }

    render() {
        const customStyles = {
            color: 'black',
        };

        if (this.state.isLoading) {
            return (
                <ReportLoader handleClose={this.hideModal} show={this.state.isLoading} />
            );
        } else {
            return (
                <div className="dashboard-section">
                    {this.showError()}
                    <div className="dashboard-container container-fluid p-3">
                        <div className="dashboard-header">
                            <h6 className="font-neo-bold text-darkgray">{this.pageHeader}</h6>
                            {this.props.getViewAs}
                        </div>
                        <div className="dashboard-body mt-4">
                            <div className="mt-3 bg-white p-4 shadow rounded w-md-50">
                                <div className="change-pass">
                                    <div className="d-flex change-pass__input align-items-center">
                                        <input className="change-pass__value flex-grow-1 border-0" autoComplete="off" placeholder="Current Password" onChange={(e) => this.handleChanges(e)} name="oldPassword" type={this.state.toggles.oldPassword === false ? "password" : "text"} required />
                                        <div className="cs-pointer lh-0">
                                            <span onClick={() => this.showCurrentPassword("oldPassword")} className={`${this.state.toggles.oldPassword === false ? "icon-hide-password-o" : "icon-show-password-o"} font-1p5rem text-lightgray auth__icon`}></span>
                                        </div>
                                    </div>

                                    <div className="d-flex change-pass__input align-items-center">
                                        <input className="change-pass__value flex-grow-1 border-0" autoComplete="off" placeholder="New Password" onChange={(e) => this.handleChanges(e)} name="newPassword" type={this.state.toggles.newPassword === false ? "password" : "text"} required />
                                        <div className="cs-pointer lh-0">
                                            <span onClick={() => this.showCurrentPassword("newPassword")} className={`${this.state.toggles.newPassword === false ? "icon-hide-password-o" : "icon-show-password-o"} font-1p5rem text-lightgray auth__icon`}></span>
                                        </div>
                                    </div>

                                    <div className="d-flex change-pass__input align-items-center">
                                        <input className="change-pass__value flex-grow-1 border-0" autoComplete="off" placeholder="Re-type Password" onChange={(e) => this.handleChanges(e)} name="confirmPassword" type={this.state.toggles.confirmPassword === false ? "password" : "text"} required />
                                        <div className="cs-pointer lh-0">
                                            <span onClick={() => this.showCurrentPassword("confirmPassword")} className={`${this.state.toggles.confirmPassword === false ? "icon-hide-password-o" : "icon-show-password-o"} font-1p5rem text-lightgray auth__icon`}></span>
                                        </div>
                                    </div>

                                    <div className="mt-4">
                                        <HelpInfo
                                            showHelpInfo={this.state.showHelpInfo}
                                            closeHelpInfo={this.handleCloseHelpInfo}
                                            entry={this.state.userObject.newPassword}
                                            confirmEntry={this.state.userObject.confirmPassword}
                                            customStyles={customStyles}
                                        />
                                    </div>

                                    {/* <input className="change-pass__input" autoComplete="off" placeholder="New Password" onChange={(e) => this.handleChanges(e)} name="newPassword" type="password" required />
                                    <input className="change-pass__input" autoComplete="off" placeholder="Re-type Password" onChange={(e) => this.handleChanges(e)} onKeyPress={(event) => this.handleKeyPress(event)} name="confirmPassword" type="password" required /> */}
                                    <button className="change-pass__sbmt btn btn-dropdowncolor py-1 info-action font-neo-semibold text-uppercase w-100" type="button" onClick={() => this.changePassword(this)}>submit</button>
                                    <button className="change-pass__sbmt btn btn-danger py-1 info-action font-neo-semibold text-uppercase w-100" type="button" onClick={() => this.cancelButton(this)}>cancel</button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
}

export default withRouter(ChangePass);
