import React, { Component } from "react";

export default function withWindowDimensions(WrappedComponent) {
    return class extends Component {
        state = { width: 0, height: 0 };

        componentDidMount() {
            this.updateWindowDimensions();
            window.addEventListener("resize", this.updateWindowDimensions);
        }

        componentWillUnmount() {
            window.removeEventListener("resize", this.updateWindowDimensions);
        }

        updateWindowDimensions = () => {
            this.setState({ width: window.innerWidth, height: window.innerHeight });
        };

        render() {
            const xl = 1200.99;
            const lg = 992.99;
            const md = 768.99;
            const sm = 576.99;
            return (
                <WrappedComponent
                    {...this.props}
                    isSm={this.state.width <= md}
                    isMd={this.state.width <= lg}
                    isLg={this.state.width <= xl}
                    sm={sm}
                />
            );
        }
    };
}
