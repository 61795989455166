import { PDFDocument, PageSizes } from "pdf-lib";
import Compressor from 'compressorjs';

const A4_PAPER_DIMENSIONS = {
    width: PageSizes.A4[0],
    height: PageSizes.A4[1],
};

export const directFileToBase64 = async (file) => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = () => resolve(fileReader.result.toString());
        fileReader.onerror = () => reject(new Error("Failed to convert file to base64."));
    });
}

const compressImg = async (file) => {
    return new Promise((resolve, reject) => {
        new Compressor(file, {
            quality: 0.9,
            convertTypes: ['image/png'],
            convertSize: file.size * 0.9,
            success: async (result) => {
                resolve(result);
            },
            error: () => {
                reject(new Error("Failed to compress file."));
            }
        })
    });
}

export const generatePdfFromImages = async (file) => {
    //? compress image file
    const compressedImgFile = await compressImg(file);
    //? convert file to base64
    const image = await directFileToBase64(compressedImgFile);
    //? get type of file(base64 format)
    const type = image.split(';')[0].split('/')[1];

    //? create pdf document (initialize)
    const pdf = await PDFDocument.create();

    //? embed img for adding into pdf
    let img;
    if (type === 'png') {
        img = await pdf.embedPng(image);
    }
    else {
        img = await pdf.embedJpg(image);
    }

    const page = pdf.addPage([A4_PAPER_DIMENSIONS.width, A4_PAPER_DIMENSIONS.height]);

    const imgDimensions = img.scaleToFit(A4_PAPER_DIMENSIONS.width, A4_PAPER_DIMENSIONS.height);

    page.drawImage(img, {
        x: (page.getWidth() - imgDimensions.width) / 2,
        y: (page.getHeight() - imgDimensions.height) / 2,
        width: imgDimensions.width,
        height: imgDimensions.height,
    });
    const base64String = await pdf.saveAsBase64({ dataUri: true, useObjectStreams: false });
    return base64String;
};