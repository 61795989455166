import React, { Component } from "react";
import { apiModule } from "../../commons/apiCall";
import { LoaderGif2 } from '../../commons/loaderGif';

class ReinstatementModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      transactionId: "0",
      isLoading: false,
      columns: ["For", "Pending Requirements", "Requirements Status", "Remark"],
      dataColumns: ["responsibleUnit", "jobType", "worklistStatus", "unitComment"],
      containerClasses: this.props.view === "mobile-" ? " modal-table-row-container--mobile" : "",
      tableClasses: this.props.view === "mobile-" ? " modal-table-row--mobile" : "",
      reinstatementInfo: [],
    }
  }

  componentDidMount = () => { }

  fetchModalInfo = async () => {
    if (this.props.transactionId !== 0) {
      this.setState({ ...this.state, isLoading: true });
      await apiModule(
        "get_pending_reinstatement_requirements",
        {
          x_auth: this.props.modalData.token,
          contractNum: this.props.modalData.policyId,
          agentNumber: this.props.modalData.agent,
          transactionId: this.props.transactionId,
        },
        null
      ).then((result) => {
        this.setState({ ...this.state, transactionId: this.props.transactionId, isLoading: false, reinstatementInfo: result });
      })
        .catch((err) => {
          console.error(err);
          this.setState({ ...this.state, transactionId: this.props.transactionId, isLoading: false });
        });
    }
  }

  render = () => {
    if (this.props.transactionId !== this.state.transactionId && !this.state.isLoading) {
      this.fetchModalInfo();
    }

    return (
      <div className="modal fade" id={this.props.view + "reinstatementModal"} tabIndex="-1" role="dialog">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content mx-1 p-md-2 border-0">
            <button type="button" className="reinstatement-modal__close" data-dismiss="modal">
              <span>&#x2715;</span>
            </button>
            <div className="modal-body">
              <div className={"modal-table-row-container" + this.state.containerClasses}>
                {
                  this.state.isLoading
                    ? <div className="modal-loader d-flex justify-content-center w-50 m-auto py-5"><LoaderGif2 /></div>
                    : <table className={"table modal-table-row mb-0" + this.state.tableClasses}>
                      <thead>
                        <tr>
                          {this.state.columns.map((column, i) => <th key={i}>{column}</th>)}
                        </tr>
                      </thead>
                      <tbody>
                        {
                          this.state.reinstatementInfo.length !== 0
                            ? this.state.reinstatementInfo.map(
                              (requirements, i) => <tr key={i}>
                                {this.state.dataColumns.map(
                                  (key, index) => <td key={index}>{requirements[key] ? requirements[key] : "-------"}</td>
                                )}
                              </tr>
                            )
                            : <tr>{this.state.dataColumns.map((key, i) => <td key={i}>-------</td>)}</tr>
                        }
                      </tbody>
                    </table>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ReinstatementModal;
