import React, { Component } from 'react';
import { myConfig } from '../../commons/config';


let privacyEmail = myConfig.PRIVACY_EMAIL_REF;
let agentStrings = [
    {
        contentHead: 'Who is the personal information controller?',
        preListParagraph: 'A Personal Information Controller is the individual or legal person who controls and is responsible to keep and use personal information in paper or electronic files. For your transactions and interactions with us, Allianz PNB Life is the personal information controller as defined by relevant information protection laws and regulations. ',
        listSubcontent: [],
        postListParagraph: '',
    },
    {
        contentHead: 'What Personal Data will be collected, processed and used? ',
        preListParagraph: 'We will collect, process and use the following types of your Personal Data: ',
        listSubcontent: [
            { subContent: 'Basic personal information (such as your surname, first name, address, date of birth, gender, telephone numbers, email address etc.) ', },
            { subContent: 'Professional information (such as your License Number, area of assignment, position, previous experiences, etc.) ', },
            { subContent: 'Performance-related information (such as your performance, production or feedback from peers) ', },
            { subContent: 'Compensation-related information (such as your commissions, bank account details, etc.)  ', },
            { subContent: 'Development-related information (such as your attendance to training courses)  ', },
            { subContent: 'Information in relation to the use of Allianz Assist, Allianz Journey, and other digital assets. ', },
        ],
        postListParagraph: 'Among these Personal Data, we may also collect, process and use sensitive Personal Data about you, for example, information related to any disabilities you may have or other health-related information, in compliance with applicable laws and regulations. ',
    },
    {
        contentHead: 'For which purposes do we collect, process and use your Personal Data? ',
        preListParagraph: 'We will process your Personal Data for the following purposes: ',
        listSubcontent: [
            { subContent: 'To facilitate company-wide collaboration and communication; ', },
            { subContent: 'General administration of core Distribution related matters; ', },
            { subContent: 'Performance and compensation management; ', },
            { subContent: 'Rewards and Incentives Management; ', },
            { subContent: 'Learning and development; ', },
            { subContent: 'Distributor planning and analytics; ', },
            { subContent: 'Internal investigations; ', },
            { subContent: 'To meet any legal obligations (for example, tax, accounting, regulatory and administrative obligations); ', },
            { subContent: 'To comply with law enforcement requests or discovery procedures, or where required or permitted by applicable laws, court orders, government regulations, or government authorities; ', },
            { subContent: 'Where it is necessary to investigate, prevent or take action regarding illegal activities, suspected fraud, situations involving potential threats to the safety of any person or violations of Allianz PNB Life policies and standards. ', },
        ],
        postListParagraph: '',
    },
    {
        contentHead: 'On which legal bases do we collect, process and use your Personal Data? ',
        preListParagraph: 'We use the following legal bases for the collection, processing and use of your Personal Data: ',
        listSubcontent: [
            { subContent: 'Performance of your Agency Contract if the processing is necessary to perform your  contract (for example, with regard to paying your commissions); ' },
            { subContent: 'Compliance with a legal obligation to which we are subject (for example, where we are legally obliged to retain certain tax-related data); ' },
            { 
                subContent: 'Where the processing is necessary for our legitimate interests unless these are overridden by your own legitimate interests; our legitimate interests include – as the case may be – the following: ',
                listSubSubContent: [
                    { subSubContent: 'Enabling global collaboration and workforce planning & analytics;  ' },
                    { subSubContent: 'Ensuring business continuity (for example, via succession planning); ' },
                    { subSubContent: 'Protecting Allianz Group and/or its employees from harm, by preventing or detecting illegal activities, suspected fraud or situations involving potential threats to the safety of any person or violations of Allianz Group policies or terms;  ' },
                    { subSubContent: 'Recruiting, overall group wide talent management, reward & performance; group wide activities like directories;  ' },
                ],
            },
            { subContent: 'Your consent, to the extent this is required by applicable data protection laws and regulations. ' },
        ],
        postListParagraph: '',
    },
    {
        contentHead: 'Who will have access to your Personal Data? ',
        preListParagraph: 'For the stated purposes, we may disclose your Personal Data to the following recipients or categories of recipients: ',
        listSubcontent: [
            { subContent: 'Allianz SE and authorized persons working for or on behalf of Allianz SE;  ' },
            { subContent: 'Other Allianz Group companies, technical consultants, experts, lawyers, medical doctors and service companies to discharge operations (claims, IT, postal, document management); ' },
            { subContent: 'Third party agents, service providers and advisers (for example, for providing training and development courses, IT maintenance and support, legal advice, analytics of usage behaviour, procurement services, compliance vetting);  ' },
            { subContent: 'Law enforcement or government authorities where necessary to comply with applicable laws; ' },
            { subContent: 'Other parties in the event of any contemplated or actual reorganization, merger, sale, joint venture, assignment, transfer or other disposition of all or any portion of our business, assets or stock (including in any insolvency or similar proceedings). ' },
        ],
        postListParagraph: '',
    },
    {
        contentHead: 'Where will my Personal Data be processed? ',
        preListParagraph: 'Your personal information may be processed both inside and outside of the Philippines, subject always to contractual restrictions regarding confidentiality and security in line with applicable information protection laws and regulations. We will not disclose your personal information to parties who are not authorized to process them. ',
        listSubcontent: [],
        postListParagraph: '',
    },
    {
        contentHead: 'What are your rights in respect of your Personal Data? ',
        preListParagraph: 'Where permitted by applicable law or regulation, you have the right to: ',
        listSubcontent: [
            { subContent: 'Access your personal information held about you and to learn the origin of the information, the purposes and ends of the processing, the details of the personal information controller(s), the information processor(s) and the parties to whom the information may be disclosed; ' },
            { subContent: 'Withdraw your consent at any time where your personal information is processed with your consent; ' },
            { subContent: 'Update or correct your personal information so that it is always accurate; ' },
            { subContent: 'Delete your personal information from our records if it is no longer needed for the purposes indicated above; ' },
            { subContent: 'Restrict the processing of your personal information in certain circumstances, for example where you have contested the accuracy of your personal information, for the period enabling us to verify its accuracy; ' },
            { subContent: 'Obtain your personal information in an electronic format for you or for your new insurer; and ' },
            { subContent: 'File a complaint with us and/or the relevant information protection authority.' },
        ],
        postListParagraph: 'You may exercise these rights by contacting us as detailed in section 9 below providing your name, email address, and the purpose of your request.',
    },
    {
        contentHead: 'How long do we keep your Personal Data? ',
        preListParagraph: 'We will retain your personal information while you are employed with us, unless a longer retention period is required or as permitted by law. We will not retain your personal information for longer than necessary and we will hold it only for the purposes for which it was obtained. ',
        listSubcontent: [],
        postListParagraph: '',
    },
    {
        contentHead: 'How can you contact us?', 
        preListParagraph: (
            <div className="PrivacyNotice__contact mx-4 my-2">
            If you have any queries about how we use your personal information, you can contact us through any of  the following: 
                <div className="font-neo-regular mt-2">
                    <div><i>Allianz PNB Life Insurance, Inc. </i></div>
                    <div><i>Data Protection Officer </i></div>
                    <div><i>9th Floor, Allied Bank Center, 6754 Ayala Avenue corner Legaspi Street, Makati City, Philippines </i></div>
                    <div><i>Email: <a href={'mailto:'+ privacyEmail}>{privacyEmail}</a></i></div>
                    <div><i>Customer Service Hotline: 8818-4357 </i></div>
                </div>
            </div>
        ),
        listSubcontent: [],
        postListParagraph: '',
    },
    {
        contentHead: 'How often do we update this Privacy Notice? ',
        preListParagraph: 'We regularly review this privacy notice and we will inform you directly when there is  an important change that may impact you. This privacy notice was last updated September 11, 2020. ',
        listSubcontent: [],
        postListParagraph: '',
    },
];

let bancaStrings = [
    {
        contentHead: 'Who is the personal information controller?',
        preListParagraph: 'A Personal Information Controller is the individual or legal person who controls and is responsible to keep and use personal information in paper or electronic files. For your transactions and interactions with us, Allianz PNB Life is the personal information controller as defined by relevant information protection laws and regulations. ',
        listSubcontent: [],
        postListParagraph: '',
    },
    {
        contentHead: 'What Personal Data will be collected, processed and used? ',
        preListParagraph: 'We will collect, process and use the following types of your Personal Data: ',
        listSubcontent: [
            { subContent: 'Basic personal information (such as your surname, first name, address, date of birth, gender, telephone numbers, email address etc.) ', },
            { subContent: 'Professional information (such as your License Number, area of assignment, position, previous experiences, etc.) ', },
            { subContent: 'Performance-related information (such as your performance, production or feedback from peers) ', },
            { subContent: 'Compensation-related information (such as your commissions, bank account details, etc.)  ', },
            { subContent: 'Development-related information (such as your attendance to training courses)  ', },
            { subContent: 'Information in relation to the use of Allianz Assist, Allianz Journey, and other digital assets. ', },
        ],
        postListParagraph: 'Among these Personal Data, we may also collect, process and use sensitive Personal Data about you, for example, information related to any disabilities you may have or other health-related information, in compliance with applicable laws and regulations. ',
    },
    {
        contentHead: 'For which purposes do we collect, process and use your Personal Data? ',
        preListParagraph: 'We will process your Personal Data for the following purposes: ',
        listSubcontent: [
            { subContent: 'To facilitate company-wide collaboration and communication; ', },
            { subContent: 'General administration of core Distribution related matters; ', },
            { subContent: 'Performance and compensation management; ', },
            { subContent: 'Rewards and Incentives Management; ', },
            { subContent: 'Learning and development; ', },
            { subContent: 'Distributor planning and analytics; ', },
            { subContent: 'Internal investigations; ', },
            { subContent: 'To meet any legal obligations (for example, tax, accounting, regulatory and administrative obligations); ', },
            { subContent: 'To comply with law enforcement requests or discovery procedures, or where required or permitted by applicable laws, court orders, government regulations, or government authorities; ', },
            { subContent: 'Where it is necessary to investigate, prevent or take action regarding illegal activities, suspected fraud, situations involving potential threats to the safety of any person or violations of Allianz PNB Life policies and standards. ', },
        ],
        postListParagraph: '',
    },
    {
        contentHead: 'On which legal bases do we collect, process and use your Personal Data? ',
        preListParagraph: 'We use the following legal bases for the collection, processing and use of your Personal Data: ',
        listSubcontent: [
            { subContent: 'Performance of your Financial Advisors’s contract if the processing is necessary to perform your  contract (for example, with regard to paying your commissions); ' },
            { subContent: 'Compliance with a legal obligation to which we are subject (for example, where we are legally obliged to retain certain tax-related data); ' },
            { 
                subContent: 'Where the processing is necessary for our legitimate interests unless these are overridden by your own legitimate interests; our legitimate interests include – as the case may be – the following: ',
                listSubSubContent: [
                    { subSubContent: 'Enabling global collaboration and workforce planning & analytics;  ' },
                    { subSubContent: 'Ensuring business continuity (for example, via succession planning); ' },
                    { subSubContent: 'Protecting Allianz Group and/or its employees from harm, by preventing or detecting illegal activities, suspected fraud or situations involving potential threats to the safety of any person or violations of Allianz Group policies or terms;  ' },
                    { subSubContent: 'Recruiting, overall group wide talent management, reward & performance; group wide activities like directories;  ' },
                ],
            },
            { subContent: 'Your consent, to the extent this is required by applicable data protection laws and regulations. ' },
        ],
        postListParagraph: '',
    },
    {
        contentHead: 'Who will have access to your Personal Data? ',
        preListParagraph: 'For the stated purposes, we may disclose your Personal Data to the following recipients or categories of recipients: ',
        listSubcontent: [
            { subContent: 'Allianz SE and authorized persons working for or on behalf of Allianz SE;  ' },
            { subContent: 'Other Allianz Group companies, technical consultants, experts, lawyers, medical doctors and service companies to discharge operations (claims, IT, postal, document management); ' },
            { subContent: 'Third party agents, service providers and advisers (for example, for providing training and development courses, IT maintenance and support, legal advice, analytics of usage behaviour, procurement services, compliance vetting);  ' },
            { subContent: 'Law enforcement or government authorities where necessary to comply with applicable laws; ' },
            { subContent: 'Other parties in the event of any contemplated or actual reorganization, merger, sale, joint venture, assignment, transfer or other disposition of all or any portion of our business, assets or stock (including in any insolvency or similar proceedings). ' },
        ],
        postListParagraph: '',
    },
    {
        contentHead: 'Where will my Personal Data be processed? ',
        preListParagraph: 'Your personal information may be processed both inside and outside of the Philippines, subject always to contractual restrictions regarding confidentiality and security in line with applicable information protection laws and regulations. We will not disclose your personal information to parties who are not authorized to process them. ',
        listSubcontent: [],
        postListParagraph: '',
    },
    {
        contentHead: 'What are your rights in respect of your Personal Data? ',
        preListParagraph: 'Where permitted by applicable law or regulation, you have the right to: ',
        listSubcontent: [
            { subContent: 'Access your personal information held about you and to learn the origin of the information, the purposes and ends of the processing, the details of the personal information controller(s), the information processor(s) and the parties to whom the information may be disclosed; ' },
            { subContent: 'Withdraw your consent at any time where your personal information is processed with your consent; ' },
            { subContent: 'Update or correct your personal information so that it is always accurate; ' },
            { subContent: 'Delete your personal information from our records if it is no longer needed for the purposes indicated above; ' },
            { subContent: 'Restrict the processing of your personal information in certain circumstances, for example where you have contested the accuracy of your personal information, for the period enabling us to verify its accuracy; ' },
            { subContent: 'Obtain your personal information in an electronic format for you or for your new insurer; and ' },
            { subContent: 'File a complaint with us and/or the relevant information protection authority.' },
        ],
        postListParagraph: 'You may exercise these rights by contacting us as detailed in section 9 below providing your name, email address, and the purpose of your request.',
    },
    {
        contentHead: 'How long do we keep your Personal Data? ',
        preListParagraph: 'We will retain your personal information while you are employed with us, unless a longer retention period is required or as permitted by law. We will not retain your personal information for longer than necessary and we will hold it only for the purposes for which it was obtained. ',
        listSubcontent: [],
        postListParagraph: '',
    },
    {
        contentHead: 'How can you contact us?', 
        preListParagraph: (
            <div className="PrivacyNotice__contact mx-4 my-2">
            If you have any queries about how we use your personal information, you can contact us through any of  the following: 
                <div className="font-neo-regular mt-2">
                    <div><i>Allianz PNB Life Insurance, Inc. </i></div>
                    <div><i>Data Protection Officer </i></div>
                    <div><i>9th Floor, Allied Bank Center, 6754 Ayala Avenue corner Legaspi Street, Makati City, Philippines </i></div>
                    <div><i>Email: <a href={'mailto:'+ privacyEmail}>{privacyEmail}</a></i></div>
                    <div><i>Customer Service Hotline: 8818-4357 </i></div>
                </div>
            </div>
        ),
        listSubcontent: [],
        postListParagraph: '',
    },
    {
        contentHead: 'How often do we update this Privacy Notice? ',
        preListParagraph: 'We regularly review this privacy notice and we will inform you directly when there is  an important change that may impact you. This privacy notice was last updated September 11, 2020. ',
        listSubcontent: [],
        postListParagraph: '',
    },
];

let agentContent = (
    <>
    <p className="PrivacyNotice__paragraph text-justify my-2 mx-1">
        We, Allianz PNB Life Insurance, Inc. (“Allianz PNB Life”), are part of the Allianz Group, and are an authorized insurance company in the Republic of the Philippines providing insurance products and services. Protecting your privacy is a top priority for us. This privacy notice explains how and what type of personal information will be collected, why it is collected and to whom it is shared or disclosed. Please read this notice carefully.   
        <br/>
        <br/>
        We process your personal data to manage all distributor-related matters in a user-friendly and highly efficient manner. 
        <br/>
        <br/>
        Distributor processing activities entails the processing of personal information (i.e. data which can be linked/attributed to an individual person, “Personal Information”). We will pay special attention to protect your privacy in the context of these activities. We believe in transparency and we are committed to being upfront about our privacy practices, including how we treat your personal information. 
        <br/>
        <br/>
        This Privacy Notice sets out which kind of Personal Data will be collected, processed and used by Allianz PNB Life, for which purposes, to whom this Personal Data may be shared or disclosed and which rights you, as a data subject, have in this regard. 
        <br/>
        <br/>
    </p>
    <ol>
        {
        agentStrings.map(function(item, index) {
            return(
                <div className="mb-4">
                    <li className="PrivacyNotice__numbered-item PrivacyNotice__list-item">{item.contentHead}</li>
                    <div className="PrivacyNotice__paragraph text-justify">{item.preListParagraph}</div>
                    <div>
                        {
                            item.listSubcontent? 
                            <ul>
                                {
                                    item.listSubcontent.map(function(subItem, idx) {
                                        return (
                                            <>
                                            <li className="PrivacyNotice__list-item">{subItem.subContent}</li>
                                            {
                                                subItem.listSubSubContent? (
                                                    <ul>
                                                        {
                                                            subItem.listSubSubContent.map(function(subSubItem, i) {
                                                                return( <li className="PrivacyNotice__list-item">{subSubItem.subSubContent}</li> );
                                                            })
                                                        }
                                                    </ul>
                                                )
                                                : ''
                                            }
                                            </>
                                        );
                                    })
                                }
                            </ul>
                            : ''
                        }
                    </div>
                    <div className="PrivacyNotice__paragraph text-justify">{item.postListParagraph}</div>
                </div>
            );
        })
        }
    </ol>
    </>
);

let bancaContent = (
    <>
    <p className="PrivacyNotice__paragraph text-justify my-2 mx-1">
        We, Allianz PNB Life Insurance, Inc. (“Allianz PNB Life”), are part of the Allianz Group, and are an authorized insurance company in the Republic of the Philippines providing insurance products and services. Protecting your privacy is a top priority for us. This privacy notice explains how and what type of personal information will be collected, why it is collected and to whom it is shared or disclosed. Please read this notice carefully.   
        <br/>
        <br/>
        We process your personal data to manage all distributor-related matters in a user-friendly and highly efficient manner. 
        <br/>
        <br/>
        Distributor processing activities entails the processing of personal information (i.e. data which can be linked/attributed to an individual person, “Personal Information”). We will pay special attention to protect your privacy in the context of these activities. We believe in transparency and we are committed to being upfront about our privacy practices, including how we treat your personal information. 
        <br/>
        <br/>
        This Privacy Notice sets out which kind of Personal Data will be collected, processed and used by Allianz PNB Life, for which purposes, to whom this Personal Data may be shared or disclosed and which rights you, as a data subject, have in this regard. 
        <br/>
        <br/>
    </p>
    <ol>
        {
        bancaStrings.map(function(item, index) {
            return(
                <div className="mb-4">
                    <li className="PrivacyNotice__numbered-item PrivacyNotice__list-item">{item.contentHead}</li>
                    <div className="PrivacyNotice__paragraph text-justify">{item.preListParagraph}</div>
                    <div>
                        {
                            item.listSubcontent? 
                            <ul>
                                {
                                    item.listSubcontent.map(function(subItem, idx) {
                                        return (
                                            <>
                                            <li className="PrivacyNotice__list-item">{subItem.subContent}</li>
                                            {
                                                subItem.listSubSubContent? (
                                                    <ul>
                                                        {
                                                            subItem.listSubSubContent.map(function(subSubItem, i) {
                                                                return( <li className="PrivacyNotice__list-item">{subSubItem.subSubContent}</li> );
                                                            })
                                                        }
                                                    </ul>
                                                )
                                                : ''
                                            }
                                            </>
                                        );
                                    })
                                }
                            </ul>
                            : ''
                        }
                    </div>
                    <div className="PrivacyNotice__paragraph text-justify">{item.postListParagraph}</div>
                </div>
            );
        })
        }
    </ol>
    </>
);

let noAvailableContent = (
    <div className="PrivacyNotice__body font-neo-semibold p-4">
        No Available Content...
    </div>
);

let getPrivacyContent = (channel) => {
    let returnContent = '';

    if(channel === 'Agency') {
        returnContent = agentContent;
    } else if(channel === 'Banca') {
        returnContent = bancaContent;
    } else {
        returnContent = noAvailableContent;
    }

    return returnContent;
}

export { getPrivacyContent };