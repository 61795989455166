import React, { Component } from 'react';
import '../../css/mis.scss';
import BarChart from './barChart';
import { apiModule, createQueryStrings } from '../../commons/apiCall';
import { haveValue, labelXAxis, charts, showNoData } from '../../commons/misConfig';
import { changeCaseFirstLetter, getMinVal, getMaxVal, formatValues, formatValuesNoDecimals } from './formatter';
import CommentIcon from './commentIcon';

class PerStatus extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                labels: [],
                datasets: []
            },
            isLoading: true,
            isCommentVisible: false,
            totalComments: 0
        }
        this.getValuePerStatus = this.getValuePerStatus.bind(this);
    }

    componentWillMount() {
        this.getValuePerStatus(this.props);
        this.props.getCommentCount(this, 'contractStatus', this.props.access_token);
        // this.setState({
        //     ...this.props
        // })
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.willUpdate){
            this.getValuePerStatus(nextProps);
        }

        if(nextProps.willUpdateCommentCount){
            nextProps.getCommentCount(this, 'contractStatus', nextProps.access_token);
        }
        // else {
        //     this.setState({
        //         willUpdate: nextProps.willUpdate
        //     })
        // }
    }

    getValuePerStatus(props) {
        this.setState({
            // ...nextProps,
            isLoading: true
        })

        let that = this;
        let headerParams = {
            x_auth: props.access_token
        };
        let filter = []
        filter['metric'] = haveValue(props.metric)? props.metric.value: '';
        filter['period'] = haveValue(props.period)? props.period.value: '';
        filter['startDate'] = haveValue(props.startDate)? props.startDate: '';
        filter['endDate'] = haveValue(props.endDate)? props.endDate: '';
        filter['productOfferingType'] = haveValue(props.product)? props.product.value: 'ALL';
        filter['channel'] = haveValue(props.channel)? props.channel.value: 'ALL';
        filter['contractStatus'] = haveValue(props.status.value) ? props.status.value : '';
        filter['targetType'] = haveValue(props.target.value) ? props.target.value : '';
        
        apiModule("get_metric_per_status", headerParams, null, createQueryStrings(filter))
            .then(function (result) {
                let dataObject = {
                    labels: [],
                    datasets: [{
                        data: [],
                        targetData: [],
                        dataPct:[],
                        dataCount: [],
                        targetDataCount: [],
                        dataCountPct: [],
                        borderWidth: 2,
                        borderColor: "#78DCFA",
                        backgroundColor: "#78DCFA"
                    }]
                };
                if(result.goalDetails){
                    result.goalDetails.map(function (obj, index) {
                        dataObject.labels[index] = labelXAxis(changeCaseFirstLetter(obj.contractStatus));
                        dataObject.datasets[0].data[index] = obj.achievedPremiumRate;
                        dataObject.datasets[0].targetData[index] = obj.plannedPremiumRate;
                        dataObject.datasets[0].dataPct[index] = obj.achievedPercentage;
                        dataObject.datasets[0].dataCount[index] = obj.numberOfInsuranceCompanyContracts;
                        dataObject.datasets[0].targetDataCount[index] = obj.plannedNumberOfInsuranceCompanyContracts;
                        dataObject.datasets[0].dataCountPct[index] = obj.plannedPercentage;
                        dataObject.metric = haveValue(props.metric) ? props.metric.value : ''
                        dataObject.minVal = getMinVal(result.goalDetails);
                        dataObject.maxVal = getMaxVal(result.goalDetails);
                    })
                }

                that.setState({
                    data: dataObject,
                    isLoading: false
                });

            })
            .catch(function (err) {
                console.log(err);
            });
    }

    render() {
        const { onClickCommentIcon, currMetric } = this.props;
        const { data, willUpdate, isLoading, totalComments } = this.state;
        let isNBV = currMetric.value.toUpperCase().includes('NBV');

        return (
            <div className="container mis-container col-sm-7 col-md-7 col-lg-4 col-xl-4">
                {/* <div className="mis-comment-icon-container" onClick={() => onClickCommentIcon(charts.contractStatus)}>
                    <div className="mis-comment-icon">
                        <img src = "/mis/comments.svg" className="mis-comment-icon-img"/>
                        <span className="mis-comment-count">
                            {formatValuesNoDecimals(totalComments)}
                        </span>
                    </div>
                </div> */}
                <CommentIcon
                    chart={charts.contractStatus}
                    onClickCommentIcon={onClickCommentIcon} 
                    totalComments={totalComments} />
                <div className="card mis-card bg-white p-3 shadow-sm rounded">
                    <div className="card-body mis-card-body">
                        <div className="card-title mis-card-title">
                            <span className="d-flex dashboard-data-header__text align-items-end font-neo-semibold text-headercolor">
                                Value Per Status
                            </span>
                        </div>
                        {isNBV ? showNoData(true) :
                            isLoading ? this.props.coinLoader :
                            <BarChart chartId="PerStatusBarChart" data={data} {...this.props} willUpdate={willUpdate}/>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default PerStatus;