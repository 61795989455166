import React from "react";
import getMonth from "date-fns/get_month";
import getYear from "date-fns/get_year";
import Select from "react-select";
import range from "lodash/range";
import * as moment from "moment";

const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
];

const years = (minYear, maxYear) => {
    const years = range(minYear, (maxYear ? maxYear : getYear(new Date()) + 1), 1);
    let mappedYears = years.map(year => {
        return { label: `${year}`, value: year };
    });
    return mappedYears;
}

const customHeader = (props) => {
    let year = getYear(props.date);
    return (

        <div className="datepicker-header">
            {props.hideLeftArrow ? null :
                <a
                    className={`btn datepicker-header__button p-0 text-lightgray ${!props.prevMonthButtonDisabled || "datepicker-disable-btn"}`}
                    onClick={props.decreaseMonth}
                    disabled={props.prevMonthButtonDisabled}
                >
                    <span className="icon-chevron-small-left"></span>
                </a>
            }
            {/* <select
                value={getYear(props.date)}
                onChange={({ target: { value } }) => changeYear(value)}
            >
                {years.map(option => (
                    <option key={option} value={option}>
                        {option}
                    </option>
                ))}
            </select> */}
            <div className="datepicker-header__date">
                <div className="datepicker-header__month">
                    {!props.hideMonth && months[getMonth(props.date)]}{props.year && getYear(props.year)}
                </div>
                {props.changeYear ?
                    <Select
                        // styles={{
                        //     option: (provided) => ({
                        //         ...provided,
                        //         fontFamily: "neo_bold",
                        //         fontSize: ".65rem",
                        //         color: "#1e4a73",
                        //         backgroundColor: "white"
                        //     }),
                        //     menu: () => ({
                        //         position: "absolute",
                        //         border: 0,
                        //         zIndex: "1090",
                        //         backgroundColor: "white",
                        //         borderRadius: "5px",
                        //         width: "4rem",
                        //         boxShadow: "2px 2px 2px #DDDEE0"
                        //     }),
                        // }}
                        styles={props.dropdownStyle}
                        options={props.yearOptions}
                        isSearchable={false}
                        className={`datatable-select ml-0 w-3p5rem h-init datepicker-header__control`}
                        classNamePrefix="datatable-select"
                        placeholder={props.dropdownPlaceholder}
                        value={{ label: `${year}`, value: year }}
                        onChange={(e) => props.changeYear(e.value)}
                    />
                    : ""}
            </div>
            {props.hideRightArrow ? null :
                <a
                    className={`btn datepicker-header__button p-0 text-lightgray ${!props.nextMonthButtonDisabled || "datepicker-disable-btn"}`}
                    onClick={props.increaseMonth}
                    disabled={props.nextMonthButtonDisabled}
                >
                    <span className="icon-chevron-tree-navi"></span>
                </a>
            }
        </div>
    )
}

export default customHeader;
export { years as Years };
