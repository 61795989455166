import React, { Component } from 'react'
import ContestLanding from './contestLanding';
import ContestDetails from './contestDetails';
import ContestCategory from './contestCategory';
import Menu from '../components/menu';
import { getParams, isEmpty } from '../commons/service';
import NotFound from './pagenotfound';
import Loading from '../components/loading';
import { getContest, getContestUnderCategory } from '../commons/restEndpoint';
import MiniLoading from '../components/miniLoading';

export class ArchivedContest extends Component {

    constructor(props) {
        super(props)

        this.state = {
            doneGettingContestsMenu: false,
            contestMenu: [],
            backupContestMenu: [
                {
                    "contestId": 1,
                    "title": "MDRT",
                    "image": "/rni/mdrt-icon.png",
                    "categoryId": null
                },
                {
                    "contestId": 2,
                    "title": "Annual Award",
                    "image": "/rni/annual-awards-icon.png",
                    "categoryId": null
                },
                {
                    "contestId": null,
                    "title": "Travel Incentive",
                    "image": "/rni/travel-icon.png",
                    "categoryId": 1
                },
                {
                    "contestId": null,
                    "title": "Campaign",
                    "image": "/rni/campaign-icon.png",
                    "categoryId": 2
                }
            ],
        }
        this.getActiveModule = this.getActiveModule.bind(this);
        this.setMenu = this.setMenu.bind(this);
        this.getContests = this.getContests.bind(this);
    }

    async getContests() {
        let menuButtons = [];
        let params = {
            status: this.props.archived ? 'inactive' : 'active'
        }
        let contestsList = await getContest(params, this.props.session.access_token);
        console.log(contestsList)
        if (contestsList) {
            if (contestsList.data) {
                contestsList.data.map(function (contest, index) {
                    var link = "";
                    if (contest.categoryId === null && contest.contestId !== null) {
                        link = "contestDetails=" + contest.contestId;
                    }
                    if (contest.categoryId !== null && contest.contestId === null) {
                        link = "category=" + contest.categoryId;
                    }
                    menuButtons.push({
                        categoryId: contest.categoryId,
                        contestId: contest.contestId,
                        name: contest.title,
                        imgUrl: contest.image,
                        link: 'archivedContests?' + link,
                    });
                    return null;
                });
            }
        }
        this.setState({
            contestMenu: menuButtons,
            doneGettingContestsMenu: true,
        });
    }

    async setMenu(menuSelected) {
        var thisCom = this;
        this.setState({
            doneGettingContestsMenu: false,
        }, async function () {
            thisCom.getContests();
        });
    }

    getActiveModule(props) {
        let params = getParams();
        let activeComponent;
        var hasContestDetail = params.contestDetails ? true : false;
        var hasCategory = params.category ? true : false;
        if (hasContestDetail && hasCategory) {
            activeComponent = <NotFound {...props}
            />;
        } else if (hasContestDetail) {
            activeComponent = <ContestDetails {...props} contestId={params.contestDetails}
            />;
        } else if (hasCategory) {
            var name;
            this.state.contestMenu.map(function (item, index) {
                if (item.categoryId) {
                    if (item.categoryId.toString() === params.category) {
                        name = item.name;
                    }
                }
                return null;
            });
            activeComponent = <ContestCategory {...props} categoryId={params.category} name={name} archived={this.props.archived}
            />;
        } else if (isEmpty(params)) {
            activeComponent = <ContestLanding {...props}
            />;
        } else {
            if(params.contest){
                activeComponent = null;
            }else{
                activeComponent = <NotFound {...props}
            />;
            }
        }

        return activeComponent;
    }

    componentDidMount() {
        this.getContests();
    }

    render() {
        let params = getParams();
        const showMenu = !params.contestDetails;
        const { contestMenu, doneGettingContestsMenu } = this.state;
        return (<div className="rni-contest-page">
            {doneGettingContestsMenu ?
                (showMenu ? <Menu menuList={contestMenu} callback={(menuSelected) => { this.setMenu(menuSelected); }} /> : null)
                :
                <div style={{ width: '15vw', margin: 'auto' }}><MiniLoading /></div>}
            {doneGettingContestsMenu ? this.getActiveModule(this.props) : <div style={{ width: '50vw', margin: 'auto' }}><MiniLoading /></div>}
        </div>
        )
    }
}

export default ArchivedContest
