import React, { Fragment } from 'react';

const TransactionStatement = (props) => {
    const headData = {
        contractNumber: props.data.contractNumber || " -- ",
        contractHolder: props.data.contractHolder ? props.data.contractHolder.lastName : " -- ",
        insured: props.data.insured ? props.data.insured[0].lastName : " -- ",
        productOfferingName: props.data.productOfferingName || " -- ",
        inceptionDate: props.data.inceptionDate || " -- ",
        currency: props.data.currency || " -- ",
        contractMode: props.data.contractMode || " -- ",
        approvalDate: props.data.approvalDate || " -- ",
        transactions: props.data.initialTransactions || " -- ",
    }
    const headTableColumns = [
        {
            name: "Policy Number",
            accessor: "contractNumber",
        },
        {
            name: "Policy Owner",
            accessor: "contractHolder",
        },
        {
            name: "Insured",
            accessor: "insured",
        },
        {
            name: "Plan",
            accessor: "productOfferingName",
        },
        {
            name: "Effective Date",
            accessor: "inceptionDate",
        },
        {
            name: "Currency",
            accessor: "currency",
        },
        {
            name: "Type of Transaction",
            accessor: "contractMode",
        },
        {
            name: "Approval Date",
            accessor: "approvalDate",
        },
        {
            name: "No. of similar transactions during the current policy year",
            accessor: "transactions",
        },
    ];
    const headTable = headTableColumns.map((item) => {
        return (
            <tr key={item.accessor}>
                <td>{item.name ? item.name : "-------"}</td>
                <td>{headData[item.accessor] ? headData[item.accessor] : "-------"}</td>
            </tr>
        )
    })
    const footTableColumns = [
        {
            name: "Transaction Date",
            accessor: "transactionOrderDate",
        },
        {
            name: "Transaction Type",
            accessor: "transactionType",
        },
        {
            name: "Amount",
            accessor: "transactionAmount",
        },
        {
            name: "Pricing Date",
            accessor: "transactionExecutionDate",
        },
        {
            name: "NAVPU",
            accessor: "fundPrice",
        },
        {
            name: "No. of Units",
            accessor: "fundShare",
        },
        {
            name: "Outstanding Units",
            accessor: "balance",
        }
    ]
    let groupedTransactions = [];
    let fundTransactionNames = [];

    if (props.data.fundTransactions) {
        props.data.fundTransactions.map(item => {
            if (item.fundName && !fundTransactionNames.includes(item.fundName)) {
                fundTransactionNames.push(item.fundName)
            }
        })

        fundTransactionNames.map((item, index) => {
            let transactionContainer = [];
            props.data.fundTransactions.map(transaction => {
                if (transaction.fundName == item) {
                    transactionContainer.push(transaction)
                }
            })

            groupedTransactions[index] = transactionContainer;
        })
    }

    let transactionTable = (test) => {
        return (
            test.map((value, index) => {
                return (
                    <tr key={`data-${index}`} className="table-modal-row">
                        {
                            footTableColumns.map(column => {
                                return <td key={column.accessor}>{value[column.accessor]}</td>
                            })
                        }
                    </tr>
                )
            })
        )
    }

    let transactionBody = "";
    if (groupedTransactions.length != 0) {
        transactionBody = groupedTransactions.map((data, index) => {
            return (
                <Fragment>
                    <h5 className="transaction-statement-header">{fundTransactionNames[index]}</h5>
                    <div className="overflow-auto">
                        <table className="table table-borderless table-sm transaction-statement-table mb-0 custom-table-width">
                            <thead>
                                <tr>
                                    {
                                        footTableColumns.map(item => {
                                            return <th key={item.accessor}>{item.name}</th>
                                        })
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {transactionTable(data)}
                            </tbody>
                        </table>
                    </div>
                </Fragment>
            );
        })
    } else {
        transactionBody = <div className='mt-4'><h5>Fund Transaction is not available for this policy.</h5></div>
    }

    return (
        <Fragment>
            <div className="p-3">
                <table className="dashboard-info__table table table-sm table-borderless mb-0">
                    <tbody className="font-neo-semibold">
                        {headTable}
                    </tbody>
                </table>
                {transactionBody}
            </div>
        </Fragment>
    )
}

export default TransactionStatement;
