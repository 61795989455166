import React, { Component } from 'react';

class Declarations extends Component {
  constructor(props) {
    super(props);
  }

  setDeclarationData(isAppliedToAll) {
    this.props.emitDeclarationData(isAppliedToAll);
  }

  render() {
    return (
      <article className="policy-services-submodule policy-services-submodule--declarations">
        <article className="policy-services-submodule__user-area policy-services-submodule__user-area--declarations">
          <section className="policy-services-submodule__child-container">
            <h5 className="policy-services-submodule__bolded-header text-black">
              Apply to all policies of the client?
            </h5>
            <form className="policy-services-submodule__declaration-form d-flex">
              <label>
                <input
                  className="policy-services-submodule__radio-button d-none"
                  onClick={() => this.setDeclarationData(true)}
                  defaultChecked={this.props.isApplied}
                  name="changeApplication"
                  type="radio"
                ></input>
                <span
                  className={'policy-services-submodule__radio-button' +
                    (this.props.isApplied
                      ? ' policy-services-submodule__radio-button--selected icon-radio-button-checked'
                      : ' icon-radio-button-unchecked')
                  }
                ></span>
                <span>Yes</span>
              </label>
              <label>
                <input
                  className="policy-services-submodule__radio-button-native d-none"
                  onClick={() => this.setDeclarationData(false)}
                  defaultChecked={!this.props.isApplied}
                  name="changeApplication"
                  type="radio"
                ></input>
                <span
                  className={'policy-services-submodule__radio-button' +
                    (!this.props.isApplied
                      ? ' policy-services-submodule__radio-button--selected icon-radio-button-checked'
                      : ' icon-radio-button-unchecked')
                  }
                ></span>
                <span>No</span>
              </label>
            </form>
          </section>
        </article>
      </article>
    )
  }
}


export default Declarations;