import React, { Component } from 'react';
import Select, { components } from 'react-select';
import { emailFormat } from "../../commons/utility";
import { decrypt } from '../../commons/utility';
import { apiModule } from '../../commons/apiCall';
import { ReportLoader } from '../../commons/utilityViews.js';

import DropdownOption from "./countrySelect/dropdownOption";
import DropdownMenu from "./countrySelect/dropdownMenu";
import DropdownValue from './countrySelect/dropdownValue';
import DropdownPlaceholder from './countrySelect/dropdownPlaceholder';

class ContactInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      areFormsDisabled: true,
      isEmailValid: true,
      isEditMode: false,
      email: "",
      contactNum: "",
      country: "",
      countryname: "",
      countryArray: [],
      countryObject: {},
      countryIndex: 0
    }

    this.props.newEmail
      ? this.state.email = this.props.newEmail
      : this.state.email = this.props.baseEmail;

    this.props.newCountryCode
      ? this.state.country = this.props.newCountryCode
      : this.state.country = this.props.baseCountryCode;

    this.props.newMobileNum
      ? this.state.contactNum = this.props.newMobileNum
      : this.state.contactNum = this.props.baseMobileNum;

    this.getCountryLists();
  }

  async getCountryLists() {
    await apiModule("get_datalist", {
      x_auth: decrypt(this.props.getSession().access_token),
      dataListId: "COUNTRYCODES"
    },
      null
    ).then((result) => {
      const countryArray = [];
      let countryIndex = 0;

      Object.keys(result).forEach((key) => {
        countryArray.push({
            label: key,
            value: result[key]
        });
      });

      countryIndex = countryArray.findIndex((country) => country.value === this.state.country);

      this.setState({
        countryArray,
        countryIndex,
        countryObject: result,
      });

      this.state.countryIndex !== -1
        ? this.props.emitBaseCountry(this.state.countryArray[this.state.countryIndex]["label"])
        : this.props.emitBaseCountry("");
      this.props.onDoneLoading();
    })
      .catch((err) => {
        console.error(err);
      });
  }

  handleCountryChange = (e) => {
    const countryIndex = this.state.countryArray.findIndex((country) => country.label === e.label);
    this.setState({ country: e.value, countryName: e.label, countryIndex });
  }

  handleMobileChange = (e) => {
    const contactNum = e.target.value;
    if (contactNum && /^[0-9]+$/.test(contactNum)) {
      this.props.setInvalidForms(false);
    } else if (contactNum === "--" || !contactNum) {
      this.props.setInvalidForms(true);
    } else {
      this.props.setInvalidForms(true);
    }
    this.setState({ contactNum });
  };

  handleEmailChange = (e) => {
    this.setState({ email: e.target.value });
  }

  isContactNumValid = () => {
    const contactNum = this.state.contactNum;
    if (contactNum && /^[0-9]+$/.test(contactNum)) {
      return true;
    } else if (contactNum === "--" || !contactNum) {
      return false;
    }

    return false;
  }

  validateMobileInput = (e) => {
    if (/[^0-9]/.test(e.key)) {
      e.preventDefault();
    }
  }

  handleChangeUpdate = () => {
    if (
      this.state.countryObject[this.state.country] !== this.props.baseCountry ||
      this.state.country !== this.props.baseCountryCode ||
      this.state.contactNum !== this.props.baseMobileNum ||
      this.state.email !== this.props.baseEmail
    ) {
      console.warn("Setting parent state...", this.state);
      this.props.onUnmount({
        newCountry: this.state.countryArray[this.state.countryIndex]["label"],
        newCountryCode: this.state.country,
        newMobileNum: this.state.contactNum,
        newEmail: this.state.email
      });
    }

    if (!this.state.areFormsDisabled && !emailFormat(this.state.email)) {
      this.setState({ isEmailValid: false });
    } else if (!this.state.areFormsDisabled && (!this.isContactNumValid() || !this.state.country)) {
      this.setState({ isEmailValid: true });
      this.props.setInvalidForms(true);
    } else {
      this.setState({ isEmailValid: true, areFormsDisabled: !this.state.areFormsDisabled });
      this.props.setIsNextDisabled(this.state.areFormsDisabled);
    }

    this.setState({ isEditMode: !this.state.isEditMode })
  }

  showInvalidPopup = (text, modifier) => (
    <div className={`policy-services-submodule__input-popup${modifier ? " policy-services-submodule__input-popup" + modifier : ""}`}>
      <span className=" policy-services-submodule__input-popup-icon icon-exclamation-circle"></span>
      {text}
    </div>
  );

  removePrefix = (countryPrefix, contactNum) => {
    let newContactNum = contactNum;
    const countryPrefixLength = this.state.country.length
    const mobilePrefix = contactNum.substr(0, countryPrefixLength);
    if (countryPrefix == mobilePrefix) {
      newContactNum = contactNum.slice(countryPrefixLength);
    }
    return newContactNum;
  }

  render() {
    if (this.state.countryArray.length === 0) {
      return (
        <article className="policy-services-submodule policy-services-submodule--loading">
          <ReportLoader show={this.state.isLoading} />
        </article>
      );
    } else {
      return (
        <article className="policy-services-submodule policy-services-submodule--contact-information">
          <header className="d-flex flex-row">
            <h6 className="mr-auto">Select Policy 1/2: Contact Information</h6>
            <h6>Next Step: Confirmation</h6>
          </header>

          <article className="policy-services-submodule__user-area policy-services-submodule__user-area--contact-info">
            <section className="policy-services-submodule__child-container">
              <h5 className="policy-services-submodule__bolded-header">Phone Number</h5>
              <div className="d-flex position-relative">
                {this.state.isEditMode ?
                  <Select
                    isDisabled={this.state.areFormsDisabled}
                    defaultValue={this.state.countryIndex ? this.state.countryArray[this.state.countryIndex] : ""}
                    className="policy-services-submodule__countries mr-1"
                    components={{
                      Option: DropdownOption,
                      Menu: DropdownMenu,
                      SingleValue: DropdownValue,
                      Placeholder: DropdownPlaceholder
                    }}
                    options={this.state.countryArray}
                    onChange={this.handleCountryChange}
                  >
                  </Select>
                  : ""}
                {
                  this.props.isFormInvalid && !this.state.country
                    ? this.showInvalidPopup("Please select a country", "--country")
                    : null
                }
                <div className={`${this.state.isEditMode ? "" : "text-mode"} policy-services-mobile-input d-flex position-relative`}>
                  <span className="policy-services-mobile-input__user-input policy-services-mobile-input__user-input--country-code">
                    {this.state.country ? "+" + this.state.country : ""}
                  </span>
                  <input
                    defaultValue={this.state.contactNum}
                    disabled={this.state.areFormsDisabled}
                    maxLength={10}
                    className={"policy-services-mobile-input__user-input flex-grow-1" +
                      (this.state.areFormsDisabled ? " policy-services-mobile-input__user-input--disabled" : "")}
                    onChange={this.handleMobileChange}
                    onKeyPress={this.validateMobileInput}
                  ></input>
                  {
                    this.props.isFormInvalid && !this.isContactNumValid()
                      ? this.showInvalidPopup("Please fill out your number", "--mobile")
                      : null
                  }
                </div>
              </div>
            </section>
            <div className="policy-horizontal-line policy-horizontal-line--darker"></div>
            <section className={`policy-services-submodule__child-container d-flex flex-column position-relative ${this.state.isEditMode ? "edit-mode" : ""}`}>
              <h5 className="policy-services-submodule__bolded-header">E-mail</h5>
              <input
                maxLength={64}
                defaultValue={this.state.email}
                disabled={this.state.areFormsDisabled}
                onChange={this.handleEmailChange}
                className={"policy-services-mobile-input__user-input policy-services-mobile-input__user-input--email w-50" + (this.state.areFormsDisabled ? " policy-services-mobile-input__user-input--disabled" : "")}
              ></input>
              {
                !this.state.isEmailValid
                  ? this.showInvalidPopup("Please enter a valid email address")
                  : null
              }
              {
                this.props.isFormInvalid && this.state.email === "--"
                  ? this.showInvalidPopup("Please fill out your email")
                  : null
              }
            </section>
            <div className="policy-horizontal-line policy-horizontal-line--darker"></div>
            <section className="policy-services-submodule__child-container d-flex flex-column align-items-center">
              <button
                className="policy-services-submodule__change-update-button"
                onClick={this.handleChangeUpdate}
              >
                <span className="icon-edit mr-1"></span> {this.state.isEditMode ? "Done" : "Change / Update"}
              </button>
            </section>
          </article>
        </article>
      )
    }
  }
}


export default ContactInformation;
