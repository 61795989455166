import { myConfig } from "../../commons/config.js";
import axios from "axios";

const announcementUrl = {
    "https://dev-usermanagement-api.allianzpnblife.ph": "https://fnadevapi.allianzpnblife.ph/azpnbl_website-api",
    "https://uat-usermanagement-api.allianzpnblife.ph": "https://fnauatapi.allianzpnblife.ph/azpnbl_website-api",
    "https://assist-usermanagement-api.allianzpnblife.ph": "https://fnaapi.allianzpnblife.ph/azpnbl_website-api",
}

const announcementApi = announcementUrl[myConfig.LOGIN_URL]

export async function getAnnouncement() {
    // * Commented until API found the fix
    // let call = axios({
    //     method: 'GET',
    //     url: announcementApi + '/announcements/5',
    //     headers: {
    //         "Accept": "application/json"
    //     },
    // })
    //     .then(function (response) {
    //         return response.data;
    //     })
    //     .catch(function (error) {
    //         return { data: null };
    //     })
    // const [response] = await Promise.all([call]);
    // // writeData('actions', { last_action: Date.now(), id: 1 });

    // return response;

    // * Hardcoded response until API found the fix
    let response = { "data": { "id": 5, "assetName": "Allianz Assist", "message": "<p>In view of the COVID-19 pandemic that we are facing, Allianz PNB Life is taking precautionary measures to protect the safety of our employees while ensuring business continuity.</p> <br/><p>Starting on March 16, 2020, the Company is maintaining a skeletal workforce in the Head Office within business hours while implementing work-from-home arrangements for some. As such, we hope for your understanding if this arrangement may affect the usual SLA for service delivery.</p> <br/><p>At this time, we highly encourage those who are already given access to leverage Allianz Assist for your customers' policy-related inquiries. You may access it using any device (desktop, laptop, tablet, and smart phone) at <a href='https://assist.allianzpnblife.ph/' target='_blank' >https://assist.allianzpnblife.ph/</a></p><br/><div style='background-color: #003781; border-radius: 8px; color: #ffffff; padding: 8px'><p>You may also continue to course your concerns through the following:</p><br/><p><b>Policy Servicing:</b><br/>customercare@allianzpnblife.ph<br/>8818-4357</p><br/><p><b>Allianz Journey, SI and Allianz Assist:</b><br/>IT.Helpdesk@allianzpnblife.ph</p></div> <br/><p style='color: #003781'><b>Thank you and let's all have #CourageOverCovid. We're with you.</b></p>", "startDt": 1584327600, "endDt": 1586660400, "createDt": 1584338370 }, "message": "Success" }

    return response;
}