import React, { Component } from 'react';
import { Line } from 'react-chartjs-2';
import Select from 'react-select';
import moment from 'moment';
import Auxi from './auxi';
import { arrayClearDuplicate } from '../commons/utility.js';
import { apiModule, createQueryStrings } from '../commons/apiCall';
import { DashboardLoader } from '../commons/utilityViews.js';
import withWindowDimensions from '../commons/withWindowDimensions';

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        color: 'white',
        fontSize: ".6rem",
        fontFamily: "neo_semibold",
        backgroundColor: "#255F8E",
        textAlign: "left",
        cursor: "pointer"
    }),
    singleValue: (provided, state) => ({
        color: "#255F8E",
        fontFamily: 'neo_semibold',
        fontSize: ".9rem"
    }),
    dropdownIndicator: () => ({
        color: "#1E4A73",
    }),
    placeholder: () => ({
        fontSize: ".9rem"
    }),
    menu: () => ({
        backgroundColor: "#255F8E",
        borderRadius: "5px",
        // left: 0,
        // width: "10rem",
        whiteSpace: "nowrap",
        zIndex: "1090"
    })
}
class FundChart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedFundOption: null,
            selectedTimeOption: null,
            fundOptions: [],
            fundCode: '',
            fundTypes: [{ fundAge: 10, fundCode: "PEF", fundName: "Peso Equity Fund" }],
            timeOptions: [
                { value: '3', label: '3 Years' },
                { value: '5', label: '5 Years' },
                { value: '7', label: '7 Years' },
                { value: '10', label: '10 Years' },
            ],
            latestFundHistory: {
                dataValue: 0,
                dateTime: null
            },
            fundChartValues: null,

            //xAxis datetime values
            xData: [],
            isLoading: true,
        }
        this.fetchFundOptions(this);
    }

    fundChange = (selectedFundOption) => {
        this.setState({
            selectedFundOption,
            isLoading: true
        }, () => {
            this.fetchFundDetails(this, selectedFundOption.value);
        });
    }

    timeChange = (selectedTimeOption) => {
        this.setState({
            isLoading: true
        }, () => {
            this.fetchFundDetailsAge(this, selectedTimeOption);
        });
    }

    fundChartView = () => {
        let { selectedFundOption } = this.state;
        let { selectedTimeOption } = this.state;

        let selectFund = (
            <div />
        );

        if (this.state.fundOptions != null) {
            selectFund = (
                <Select
                    styles={customStyles}
                    placeholder="Select Fund"
                    className="fund-select"
                    classNamePrefix="fund-select"
                    value={selectedFundOption}
                    onChange={this.fundChange}
                    options={this.state.fundOptions}
                    isSearchable={false}
                />
            );
        }

        let selectTime = (
            <div />
        );

        if (this.state.fundChartValues != null) {
            selectTime = (
                <Select
                    styles={customStyles}
                    className="time-select"
                    classNamePrefix="time-select"
                    onChange={this.timeChange}
                    value={selectedTimeOption}
                    options={this.state.timeOptions}
                    isSearchable={false}
                />
            );
        }

        let lineChart = (
            <div />
        );

        //if (this.state.fundChartValues != null) {
        if (this.state.fundChartValues != null && this.state.latestFundHistory !== null && this.state.latestFundHistory !== undefined) {
            lineChart = (
                <Line
                    data={this.state.fundChartValues}
                    options={{
                        maintainAspectRatio: false,
                        legend: {
                            display: false,
                        },
                        tooltips: {
                            callbacks: {
                                title: (tooltip, data) => {
                                    const labels = this.state.xData;
                                    return moment(labels[tooltip[0].index]).format("MMMM DD, YYYY");
                                },
                                label: (tooltip) => {
                                    return tooltip.yLabel.toFixed(3);
                                },
                            },
                            mode: 'index',
                            intersect: false,
                            backgroundColor: "#255F8E",
                            titleFontStyle: "normal",
                            titleFontFamily: "neo_semibold",
                            // titleFontSize: 0,
                            // titleMarginBottom: 0,
                            bodyFontFamily: "neo_semibold",
                            bodyFontSize: "11",
                            displayColors: false,
                        },
                        scales: {
                            xAxes: [{
                                gridLines: {
                                    display: false
                                },
                                ticks: {
                                    // stepSize:1,
                                    autoSkip: false,
                                    // maxRotation: this.props.isLg ? 50 : 0,
                                    //change rotation of labels to 50 if time option chosen is 5, 7, or 10 AND resolution width is small device
                                    maxRotation: ['5','7', '10'].includes(this.state.selectedTimeOption.value) ? (this.props.isLg ? 50 : 0) : 0,
                                    // maxRotation: 0,
                                    // maxTicksLimit: 5,
                                    // maxTicksLimit: 1000,
                                }
                            }],
                            yAxes: [{
                                ticks: {
                                    beginAtZero: true,
                                }
                            }]
                        }
                    }
                    }
                />
            );
        }else {
            lineChart = (
                <div>No Results Found</div>
            );
        }

        let fundInfo = (
            <div />
        );
        console.log(this.state.latestFundHistory);
        if (this.state.fundChartValues != null && this.state.latestFundHistory !== null && this.state.latestFundHistory !== undefined) {
            
            fundInfo = (
                <div className="font-neo-semibold mt-5 text-center">
                    <p className="text-headercolor mb-2">
                        {/* As of {normalDate(this.state.latestFundHistory.dateTime).toString()}: */}
                        As of {moment(this.state.latestFundHistory.dateTime).format("DD/MM/YY")}:
                    </p>
                    <button className="fund-chart__total btn btn-headercolor pes-none px-4">{this.state.latestFundHistory.dataValue.toFixed(3)}</button>
                </div>
            );
        }

        const desktopView = (
            <>
            <div className={`${this.props.css}-12 d-none d-md-block`}>
            </div>
            <div className={`${this.props.css} mb-4 d-none d-md-block`}>
                <div className="dashboard-data-content bg-white shadow-sm rounded py-3 pr-2">
                    <div className="dashboard-upper-body fund-chart">
                        <div className="w-30">
                            <div className="dashboard-data-header d-inline-block pl-3 w-md-90">
                                {selectFund}
                            </div>
                            {fundInfo}
                        </div>
                        <div className="dashboard-data-body w-70">
                            <div className="dashboard-data-header d-inline-block">
                                {selectTime}
                            </div>
                            <div className="dashboard-data__line-chart mt-6">
                                {lineChart}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </>
        );

        const mobileView = (
            <div className={`${this.props.css} mb-4 d-block d-md-none`}>
                <div className="dashboard-data-content bg-white shadow-sm rounded py-3 px-3">
                    <div className="dashboard-upper-body fund-chart flex-column">
                        <div className="w-100">
                            <div className="dashboard-data-header d-inline-block w-100">
                                {selectFund}
                            </div>
                            {fundInfo}
                        </div>
                        <div className="dashboard-data-body w-100">
                            <div className="dashboard-data-header d-inline-block mt-5">
                                {selectTime}
                            </div>
                            <div className="dashboard-data__line-chart mt-4">
                                {lineChart}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
        return (
            <Auxi>
                {desktopView}
                {mobileView}
            </Auxi>
        );
    }

    sortDateArray = (dateArray, order) => {

        let sortedDateArray = [];
        let dateArrayCopy = dateArray.slice();
        let initialLength = dateArrayCopy.length;

        if (order == 'desc') {

            // dateArrayCopy.sort(function (a, b) {
            //     return a.dateTime == b.dateTime ? 0 : +(a.dateTime < b.dateTime) || -1;
            // });

            sortedDateArray = dateArrayCopy.slice();

        } else {

            // dateArrayCopy.sort(function (a, b) {
            //     return a.dateTime == b.dateTime ? 0 : +(a.dateTime > b.dateTime) || -1;
            // });

            sortedDateArray = dateArrayCopy.slice();
        }

        return sortedDateArray;

    }

    fetchDailyFundDetails = (that, fundDetails) => {

        let tempLabels = [];
        let tempData = [];
        let xData = [];
        if (fundDetails != null && fundDetails.length > 0) {
            console.log(fundDetails);
            fundDetails.forEach(function (res, i) {
                // console.log("this is the normal date string");
                // console.log(res.dateTime);
                // console.log(moment(res.dateTime).format("YYYY"));
                // console.log(normalDate(res.dateTime).toString());
                // tempLabels.push(normalDate(res.dateTime).toString());
                // that.setState({ xData: res.dateTime });
                // console.log("this is the res date time");
                // console.log(res.dateTime);
                xData.push(res.dateTime);
                // tempLabels.push(res.dateTime);
                const years = moment(res.dateTime).format("YYYY");
                tempLabels.push(years);
                tempData.push(res.dataValue);
            });

            this.setState({
                xData: xData
            })
        }
        let latestFundHistory = fundDetails[fundDetails.length - 1];
        // console.log(arrayClearDuplicate(tempLabels));
        let returnObject = {
            latestFundHistory: latestFundHistory,
            fundChartValues: {
                labels: arrayClearDuplicate(tempLabels),
                datasets: [{
                    data: tempData,
                    // backgroundColor: "#48638B",
                    pointRadius: 0,
                    pointHoverRadius: 0,
                    backgroundColor: "transparent",
                    borderColor: "#48638B",
                }],
            }
        }

        return returnObject;


    }

    fetchMonthlyFundDetails = (that, fundDetails) => {
        // normalDate(this.state.latestFundHistory.dateTime).getMonth()+1).toString();
    }
    fetchFundDetailsAge = (that, selectedTimeOption) => {
        let headerParams = {
            fundCode: this.state.fundCode,
            x_auth: that.props.getSession().access_token,
        };
        let payloadParams = {};
        let filter = [];
        filter['fundAge'] = parseInt(selectedTimeOption.value);

        // console.log("THIS IS THE SELECTED OPTION " + filter['fundAge']);
        apiModule("get_dashboard_fund_details", headerParams, payloadParams, createQueryStrings(filter))
            .then(function (result) {

                let tempDateArray = result.slice();

                result = that.sortDateArray(tempDateArray, 'desc');

                let returnObject = that.fetchDailyFundDetails(that, result);

                that.setState({
                    latestFundHistory: returnObject.latestFundHistory,
                    fundChartValues: returnObject.fundChartValues,
                    selectedTimeOption,
                    isLoading: false
                });

            })
            .catch(function (err) {
                that.setState({
                    isLoading: false
                }, () => {
                    console.log(err);
                });
            });
    }
    fetchFundDetails = (that, fundCode) => {
        let headerParams = {
            fundCode: fundCode,
            x_auth: that.props.getSession().access_token,
        };
        let payloadParams = {};
        let timeOptions = [{ value: '3', label: '3 Years' }];
        let selectedTimeOption = { value: '3', label: '3 Years' };
        let selectedFundAge = 3;

        that.state.fundTypes.forEach(function (fund, i) {
            console.log(fund[0]);
            console.log(fundCode);
            if (fund[0].fundCode === fundCode) {
                selectedFundAge = parseInt(fund[0].fundAge);
                console.log(selectedFundAge + " : " + fund[0].fundName);

                if (selectedFundAge > 3) {
                    timeOptions.push({ value: '5', label: '5 Years' });

                }
                if (selectedFundAge > 5) {
                    timeOptions.push({ value: '7', label: '7 Years' });

                }
                if (selectedFundAge > 7) {
                    timeOptions.push({ value: '10', label: '10 Years' });

                }


            }
        });
        let filter = [];
        filter['fundAge'] = 3;

        apiModule("get_dashboard_fund_details", headerParams, payloadParams, createQueryStrings(filter))
            .then(function (result) {

                //result = that.sortDateArray(tempDateArray, 'desc');

                let returnObject = that.fetchDailyFundDetails(that, result);

                that.setState({
                    latestFundHistory: returnObject.latestFundHistory,
                    fundChartValues: returnObject.fundChartValues,
                    selectedTimeOption,
                    timeOptions,
                    fundCode,
                    isLoading: false
                });

            })
            .catch(function (err) {
                that.setState({
                    isLoading: false
                }, () => {
                    console.log(err);
                });

            });
    }

    fetchFundOptions = (that) => {
        let headerParams = { x_auth: that.props.getSession().access_token };
        let payloadParams = {};
        let fundTypes = [];
        let stringParams = {};

        console.log('CHANNEL');
        console.log(stringParams);

        if(that.props.getSession().channel === 'HSBC') {
            stringParams['channel'] = that.props.getSession().channel;
        }

        apiModule("get_dashboard_funds", headerParams, payloadParams, createQueryStrings(stringParams))
        .then(function (result) {

                let tempFundOptions = [];

                if (result != null && result.length > 0) {
                    result.forEach(function (res, i) {
                        tempFundOptions.push({ value: res.fundCode, label: res.fundName });
                        fundTypes.push([res]);
                    });
                }

                that.setState({
                    fundOptions: tempFundOptions,
                    fundTypes,
                    selectedFundOption: tempFundOptions[0]
                }, () => {
                    if(that.state.fundOptions[0] !== null && that.state.fundOptions[0] !== undefined) {
                        that.fetchFundDetails(that, that.state.fundOptions[0].value);
                    }
                });
                //ADD HERE

            })
        .catch(function (err) {
                console.log(err);
        });

    }

    render() {
        // console.log(this.state.fundChartValues);
        if (this.state.isLoading) {
            return (
                <div className={`${this.props.css} ${!this.props.isSm? 'col-12': ''} mb-4 d-md-block`}>
                    <div className="dashboard-data-content bg-white p-3 shadow-sm rounded">
                        <DashboardLoader />
                    </div>
                </div>
            )
        } else {
            return (
                this.fundChartView()
            )
        }

    }
}

export default withWindowDimensions(FundChart);
