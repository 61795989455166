import React, { Component } from 'react';
import ReactTable from 'react-table';
import { Link } from 'react-router-dom';
import routeChange from '../../commons/routeChange';

import Auxi from '../auxi';
import LoaderGif from "../../commons/loaderGif";
import { myConfig } from '../../commons/config.js';
import { prettifyDate, calculateAge, writeData, readAllData, encrypt, decrypt, fixName } from '../../commons/utility.js';
import { apiModule, createQueryStrings } from '../../commons/apiCall';
import loadingGif from '../../commons/loaderGif';
import TableLoader from '../TableLoader/tableLoader';
import withWindowDimensions from '../../commons/withWindowDimensions';
// import ReactPaginate from 'react-paginate';
import TablePagination from '../../components/tablePagination';

class Clients extends Component {

    constructor(props) {
        super(props);
        this.state = {
            clients: [],
            showClientModal: false,
            clientModalData: "",
            // clientModalRender: null,
            jwt: '',
            targetClicked: "",
            currentCaret: "",
            pageNumber: -1,
            redrawn: true,
            tableHeight: 0,
        }
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    async componentDidMount() {
        // window.addEventListener("resize", this.resize.bind(this));
        //this.resize();

        // let that = this;
        // console.log(this.props.getSession());
        // let clientModal = [];


        // if (this.state.redrawn) {
        //     const data = that.props.clients;
        //     let asyncLoop = require('node-async-loop');
        //     let i = 0;
        //     asyncLoop(data, function (item, next) {

        //         that.mobileModal(i, item)
        //             .then(function (divRender) {
        //                 clientModal[item.clientId] = divRender;
        //                 i++;
        //                 next();

        //             }).catch(function (err) {
        //                 i++;
        //                 next();
        //             })

        //     }, function (err) {

        //         that.setState({ clientModal, redrawn: false });
        //     });

        // }
    }
    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            // alert('You clicked outside of me!');
            this.setState({ showClientModal: false });
        }
    }

    fetchThisClientMobile = (clientId) => {
        let that = this;
        console.log("fetchThisClientMobile - " + clientId);
        return new Promise(function (resolve, reject) {
            let returnObject = { client_info: {}, policies: [] }
            let headerParams = { x_auth: that.props.getSession().access_token, clientId: clientId };

            apiModule("get_client", headerParams, null)
                .then(function (result) {
                    //IF RESULT IS NULL THEN ERROR MESSAGE
                    if (result) {
                        let item = null;
                        if (Array.isArray(result)) {
                            item = result[result.length - 1];
                        }
                        else {
                            item = result;
                        }
                        returnObject.client_info.last = item.lastName;
                        returnObject.client_info.first = item.firstName;
                        returnObject.client_info.dob = prettifyDate(item.birthDate);
                        returnObject.client_info.gender = item.gender;
                        returnObject.client_info.age = calculateAge(item.birthDate);
                        returnObject.client_info.anniversary_date = prettifyDate(item.inceptionDate);

                    } else {
                        //  that.errorMessage("No Client Found");
                    }



                    let filter = [];
                    filter['agentNumber'] = decrypt(that.props.getSession().agentNumber);
                    filter['pageSize'] = myConfig.PAGE_SIZE;
                    filter['accountManagerId'] = decrypt(that.props.getSession().agentNumber);
                    filter['pageNumber'] = 1;

                    apiModule("get_client_policies", headerParams, null, createQueryStrings(filter))
                        .then(function (result) {
                            // console.log(result);
                            let policies = [];
                            //IF RESULT IS NULL THEN ERROR MESSAGE
                            if (result) {
                                for (let i = 0; i < result.length; i++) {
                                    let item = result[i].content[0];
                                    // console.log(item);
                                    if (item.insured === null || item.insured === undefined) {


                                        returnObject.policies.push({
                                            poNum: item.contractNumber,
                                            planName: item.productOfferingName,
                                            //status: item.status,
                                            insured: `---`,
                                            insuredLast: `---`,
                                            insuredFirst: `---`,
                                            effDate: prettifyDate(item.inceptionDate),
                                            currency: item.currency,
                                            expDate: prettifyDate(item.contractInterval.endTimeDate)
                                        });
                                    } else {
                                        returnObject.policies.push({
                                            poNum: item.contractNumber,
                                            planName: item.productOfferingName,
                                            //status: item.status,
                                            insured: fixName(item.insure), //`${item.insured.lastName}, ${item.insured.firstName} ${item.insured.middleName}`,
                                            insuredLast: `${item.insured.lastName}`,
                                            insuredFirst: `${item.insured.firstName}`,
                                            effDate: prettifyDate(item.inceptionDate),
                                            currency: item.currency,
                                            expDate: prettifyDate(item.contractInterval.endTimeDate)
                                        });
                                    }
                                }

                            } else {
                                //  that.errorMessage("No Client Found");
                            }


                            resolve(returnObject);
                        })
                        .catch(function (err) {
                            resolve(returnObject);
                        })
                })
                .catch(function (err) {
                    resolve(returnObject);
                })
        });
    }

    onClickAction = (item, url) => {
        let page = url ? url : "single-client";
        this.props.setClientOrPolicy({ clientId: item });
        writeData('policy-client', { clientId: item, id: 1 });
        routeChange(this.props.history, "/dashboard/" + page);
    }

    onClickViewPolicy = (item) => {
        this.props.setClientOrPolicy({ clientId: item });
        writeData('policy-client', { clientId: item, id: 1 });
    }

    changePage = (pageNumber) => {

        if (pageNumber.selected != null) {
            pageNumber = pageNumber.selected;
            if (this.props.currentPage != pageNumber) {
                this.props.beginLoading();
                this.props.search(pageNumber);
            }

        } else if (this.props.currentPage != pageNumber) {

            this.props.search(pageNumber);
        }
    }

    mobileModal = (clientId) => {
        let that = this;
        return new Promise(function (resolve, reject) {
            that.fetchThisClientMobile(clientId)
                .then(function (result) {
                    // console.log(result);
                    let clientData = [result.client_info];
                    let clientDataRow = clientData.map((item, index) => {
                        let customName = {
                            last: "Last Name",
                            first: "First Name",
                            dob: "Date of Birth",
                            gender: "Gender",
                            age: "Age",
                            anniversary_date: "Anniversary Date"
                        };
                        return (
                            <Auxi key={index}>
                                <tr>
                                    <td className="font-neo-bold">{customName.last}:</td>
                                    <td>{item.last}</td>
                                </tr>
                                <tr>
                                    <td className="font-neo-bold">{customName.first}:</td>
                                    <td>{item.first}</td>
                                </tr>
                                <tr>
                                    <td className="font-neo-bold">{customName.dob}:</td>
                                    <td>{item.dob}</td>
                                </tr>
                                <tr>
                                    <td className="font-neo-bold">{customName.gender}:</td>
                                    <td>{item.gender}</td>
                                </tr>
                                <tr>
                                    <td className="font-neo-bold">{customName.age}:</td>
                                    <td>{item.age}</td>
                                </tr>
                                <tr>
                                    <td className="font-neo-bold">{customName.anniversary_date}:</td>
                                    <td>{item.anniversary_date}</td>
                                </tr>
                            </Auxi>
                        );
                    });

                    console.log("header info");

                    let clientPolicyList = result.policies.map((item, index) => {
                        let collapseId = `client-collapse-${index}`
                        const customName = {
                            planName: "Plan Name",
                            insured: "Proposed Insured",
                            effDate: "Effective Date",
                            currency: "Currency",
                            expDate: "Expiry Date"
                        };
                        return (
                            <Auxi key={index}>
                                <div className="data-accord__card card border-0 mt-0 data-accord__card--odd-bg rounded-0">
                                    <div className="data-accord__toggle btn">
                                        <div className="row data-accord__start">
                                            <div className="col-5">
                                                <div className="data-accord__name">{item.poNum}</div>
                                            </div>
                                            <div className="col-7 d-flex justify-content-between">
                                                {/*<button className="btn btn-darkgreen rounded-pill data-accord__button">{item.status}</button>*/}
                                                <button className="btn data-accord__button"></button>
                                                <button className="btn p-0" type="button" data-toggle="collapse" data-target={`#${collapseId}`}>
                                                    <div className="data-accord__caret">
                                                        <div className="data-accord__caret-icon"></div>
                                                    </div>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div id={`${collapseId}`} className="collapse" data-parent="#data-accord-modal">
                                        <div className="card-body data-accord__content">
                                            <div className="row">
                                                <div className="data-accord__column col-5">
                                                    <div className="data-accord__label data-accord__label--fontp8rem font-neo-bold">{customName.planName}</div>
                                                    <div className="data-accord__value">{item.planName}</div>
                                                </div>
                                                <div className="data-accord__column col-7">
                                                    <div className="data-accord__label data-accord__label--fontp8rem font-neo-bold">{customName.insured}</div>
                                                    <div className="data-accord__value">{`${item.insuredLast}, ${item.insuredFirst}`}</div>
                                                </div>
                                                <div className="data-accord__column col-5">
                                                    <div className="data-accord__label data-accord__label--fontp8rem font-neo-bold">{customName.effDate}</div>
                                                    <div className="data-accord__value">{item.effDate}</div>
                                                </div>
                                                <div className="data-accord__column col-7">
                                                    <div className="data-accord__label data-accord__label--fontp8rem font-neo-bold">{customName.currency}</div>
                                                    <div className="data-accord__value">{item.currency}</div>
                                                </div>
                                                <div className="data-accord__column col-5">
                                                    <div className="data-accord__label data-accord__label--fontp8rem font-neo-bold">{customName.expDate}</div>
                                                    <div className="data-accord__value">{item.expDate}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Auxi >
                        );
                    });
                    console.log("after policies");
                    // let modalId = `modal-${id}`;
                    let clientModalRender = (
                        <Auxi>
                            <table className="table table-borderless table-sm client-modal__table">
                                <tbody>
                                    {clientDataRow}
                                </tbody>
                            </table>
                            <div className="data-accord row flex-wrap mt-4">
                                <div className="data-accord__header font-neo-bold font-p8rem">
                                    <div className="col-5 d-flex align-items-center pl-0">Policy Number</div>
                                    {/*<div className="col-7">Status</div>*/}
                                </div>
                                <div className="col-12 data-accord__body mt-1 px-0">
                                    <div className="data-accord__collapse" id="data-accord-modal">
                                        {clientPolicyList}
                                    </div>
                                </div>
                            </div>
                        </Auxi>
                    );
                    console.log("Done!!!");
                    // console.log(clientModalRender);
                    // resolve(clientModalRender)
                    // return (clientModalRender);
                    that.setState({ clientModalData: clientModalRender });

                })
                .catch(function (err) {
                    // console.log(err);
                    reject(err)
                });
        });

    }

    handleShowClientModal = (clientId) => {
        this.setState({
            showClientModal: true,
            clientModalData: ""
        });
        this.mobileModal(clientId);
    }

    mobileAccord = () => {
        const data = this.props.clients;
        let that = this;

        let clients = data.map((item, index) => {
            const customName = {
                birthDate: "Date of Birth",
                LOCATION: "Location",
                contactno: "Contact Number",
                email: "Email",
                FANAME: "Financial Advisor",
            };

            return (
                <Auxi key={index}>
                    <div className={`data-accord__card ${index > 0 ? "mt-3" : ""} card border-0`}>
                        <div className="data-accord__toggle btn">
                            <div className="row data-accord__start">
                                <div className="col-5">
                                    <button onClick={() => this.onClickAction(item.clientId)} className="data-accord__name data-accord__name--clear">{item.name}</button>
                                </div>
                                <div className="col-7 d-flex justify-content-between">
                                    <button
                                        className="btn btn-darkgreen rounded-pill data-accord__button"
                                        onClick={() => this.handleShowClientModal(item.clientId)}
                                    // data-toggle="modal"
                                    // data-target={`#policy-modal`}
                                    >
                                        View Policies
                                    </button>
                                    <button className="btn p-0" type="button" data-toggle="collapse" data-target={`#collapse-${index}`}>
                                        <div className="data-accord__caret">
                                            <div className="data-accord__caret-icon"></div>
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div id={`collapse-${index}`} className="collapse" data-parent="#data-accord">
                            <div className="card-body data-accord__content">
                                <div className="row">
                                    <div className="data-accord__column col-5">
                                        <div className="data-accord__label">{customName.birthDate}</div>
                                        <div className="data-accord__value">{item.birthDate}</div>
                                    </div>
                                    <div className="data-accord__column col-7">
                                        <div className="data-accord__label">{customName.LOCATION}</div>
                                        <div className="data-accord__value">{item.LOCATION}</div>
                                    </div>
                                    <div className="data-accord__column col-5">
                                        <div className="data-accord__label">{customName.contactno}</div>
                                        <div className="data-accord__value">{item.contactno}</div>
                                    </div>
                                    <div className="data-accord__column col-7">
                                        <div className="data-accord__label">{customName.email}</div>
                                        <div className="data-accord__value">{item.email}</div>
                                    </div>
                                    <div className="data-accord__column col-5">
                                        <div className="data-accord__label">{customName.FANAME}</div>
                                        <div className="data-accord__value">{item.FANAME}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* {this.renderModal(item.clientId)} */}

                </Auxi >
            );
        });

        return (

            <div className="data-accord row flex-wrap mt-4" >
                <div className="data-accord__header">
                    <div className="col-5">
                        <div onClick={() => this.props.headerClickedHandler("name")} className="d-inline-flex align-items-center cs-pointer">
                            Name <span className="icon-popout"></span>
                        </div>
                    </div>
                    <div className="col-7">
                        <div className="d-inline-flex align-items-center">
                            Actions
                        </div>
                    </div>
                </div>
                <div className="col-12 data-accord__body mt-1">
                    <div className="data-accord__collapse" id="data-accord">
                        {clients}
                    </div>
                </div>
            </div>
        );
    }

    tableView = () => {
        let columns = [];
        if (this.props.isMgr) {
            columns = [
                {
                    Header: 'Name',
                    accessor: 'name'
                },
                {
                    Header: 'Date of Birth',
                    accessor: 'birthDate',
                },
                {
                    Header: 'Location',
                    accessor: 'LOCATION'
                },
                {
                    Header: "Contact Number",
                    accessor: 'contactno'
                },
                {
                    Header: "Email",
                    accessor: 'email'
                },
                {
                    Header: this.props.faLabel,
                    accessor: 'FANAME'
                },
                {
                    Header: "Actions",
                    // id: "actions",
                    accessor: "actions",
                    // accessor: data => data.clientId,
                    sortable: false,
                    // Cell: <button className="dashboard-datatable__button btn btn-darkgreen rounded-pill">View Policies</button>,
                    Cell: <button className="dashboard-datatable__button btn btn-darkgreen rounded-pill">View Policies</button>,
                    width: 120,
                    // maxWidth: 70,
                }
            ];
        } else {
            columns = [
                {
                    Header: 'Name',
                    accessor: 'name'
                },
                {
                    Header: 'Date of Birth',
                    accessor: 'birthDate',
                },
                {
                    Header: 'Location',
                    accessor: 'LOCATION'
                },
                {
                    Header: "Contact Number",
                    accessor: 'contactno'
                },
                {
                    Header: "Email",
                    accessor: 'email'
                },
                {
                    Header: "Actions",
                    accessor: "actions",
                    sortable: false,
                    // Cell: <button className="dashboard-datatable__button btn btn-darkgreen rounded-pill">View Policies</button>,
                    Cell: <button className="dashboard-datatable__button btn btn-darkgreen rounded-pill">View Policies</button>,
                    width: 120,
                    // maxWidth: 70,
                }
            ];
        }
        let desktopView = (
            <ReactTable
                className={`mt-4 d-md-flex d-none`}
                resizable={false}
                columns={columns}
                loading={this.props.loadTable}
                // data={this.clientRows(100)}
                defaultPageSize={myConfig.PAGE_SIZE}
                minRows={0}
                tableHeight={this.state.tableHeight}
                totalSize={this.props.totalSize}
                data={this.props.clients}
                manual
                LoadingComponent={TableLoader}
                onFetchData={(state, instance) => {
                    this.changePage(state.page);
                }}
                pages={this.props.pages}
                page={this.props.currentPage}
                getTheadThProps={(state, rowInfo, column, instance) => ({
                    isSortable: column.sortable,
                    onClick: () => {
                        this.props.headerClickedHandler(column.id);
                    },
                    sortClassName: (
                        this.props.sortByField == column.id ?
                            (this.props.sortOrder == "ASC" ?
                                "icon-chevron-small-up"
                                : "icon-chevron-dropdown")
                            : ""
                    ),
                    sortColor: (
                        this.props.sortByField == column.id ?
                            "text-black"
                            : ""
                    )
                })}
                // getTrProps={(state, rowInfo, column, instance) => (
                //     {
                //         onClick: () => this.onClickAction(rowInfo.original.clientId)
                //     }
                // )}
                getTdProps={
                    (state, rowInfo, column, instance) => (
                        {
                            onClick: column.id != "actions" ? () => this.onClickAction(rowInfo.original.clientId, "single-client") : () => this.onClickAction(rowInfo.original.clientId, "single-client-policy"),

                        }
                    )
                }
            />
        );
        let mobileView = (
            <div className="d-block d-md-none position-relative">
                {this.mobileAccord()}
                {/* <ReactPaginate
                    pageCount={this.props.pages}
                    containerClassName="mobilePaginationContainer"
                    onPageChange={this.changePage}
                    activeClassName={"activeMobilePagination"}
                    initialPage={this.props.currentPage}
                /> */}
                {/* {tableProps ? <TablePagination css="mt-2" {...tableProps.state} onPageChange={tableProps.onPageChange} /> : ""} */}
                <TablePagination
                    css="mt-2"
                    data={this.props.clients}
                    page={this.props.currentPage}
                    totalSize={this.props.totalSize}
                    onPageChange={this.changePage}
                    // pageSize={this.props.clients.length}
                    pageSize={myConfig.PAGE_SIZE}
                    pages={this.props.pages}
                />
                {this.props.loadTable ? <TableLoader loading={true} /> : ""}
            </div>
        );

        // if (this.props.loadTable === true) {
        //     desktopView = <LoaderGif css="d-md-block d-none" />;
        //     mobileView = <LoaderGif css="d-block d-md-none" />;
        // }
        return (
            <Auxi>
                {/* {desktopView}
                {mobileView} */}
                {this.props.isSm ? mobileView : desktopView}
            </Auxi>
        );
    }

    clientModal = (css) => {

        document.addEventListener('mousedown', this.handleClickOutside);
        const modalClose = (
            <button type="button" className="policy-modal__close" onClick={() => this.setState({ showClientModal: false })} data-dismiss="modal">
                <span>&#x2715;</span>
            </button>
        );
        return (
            <div
                className={`modal client-modal ${css}`}
                // style={{ display: "block", paddingRight: "15px" }}
                id={"policy-modal"}
                tabIndex="-1"
                role="dialog"
            >
                <div className="modal-dialog d-flex" role="document">
                    <div
                        className="modal-content m-1 border-0"
                        ref={this.setWrapperRef}
                    >

                        {/* {this.state.clientModalData != <LoaderGif /> ? modalClose : ""} */}
                        {modalClose}
                        <div className="modal-body">
                            <div className="client-modal__body">
                                {/* {this.state.clientModalData != "" ? this.state.clientModalData : <LoaderGif />} */}
                                {this.state.clientModalData == "" ? <LoaderGif /> : this.state.clientModalData}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    render() {

        if (this.state.showClientModal === true) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }

        // console.log("THIS IS THE CLIENT MODAL DATA " + this.state.clientModalData);
        return (
            <Auxi>
                {this.tableView()}
                {this.state.showClientModal ? this.clientModal("d-block animation-fadein") : this.clientModal("d-none")}
            </Auxi>
        );
    }
}
export default withWindowDimensions(Clients);
