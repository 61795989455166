import React from "react";
import Auxi from "../../auxi";
import TableLoader from '../../TableLoader/tableLoader';

const ApplicationsMobile = (props) => {
    const customName = props.customName;
    const data = props.data;
    const title = props.title;
    const notIncluded = [
        'Status',
        'Application Number',
        'Plan Name'
    ];

    let getEntry = function(col, item) {
        let columnData = '';
        if(col && item) {
            if(col.Header === "Proposed Insured"){
                columnData = col.Cell({value: item.insured})? col.Cell({value: item.insured}): '--';
            }else if(col.Cell && typeof col.Cell === 'function') {
                columnData = col.Cell(item)? col.Cell(item): '--';
            } else if(col.accessor && typeof col.accessor === 'function') {
                columnData = col.accessor(item)? col.accessor(item): '--';
            } else if(col.accessor) {
                columnData = item[col.accessor]? item[col.accessor]: '--';
            }
        }
        return columnData;
    }

    let fas = data.map((item, index) => {
        console.log(customName);
        return (
            <Auxi key={index}>
                <div className={`data-accord__card ${index > 0 ? "mt-3" : ""} card border-0`}>
                    <div className="data-accord__toggle btn" data-toggle="collapse" data-target={`#collapse-${index}`}>
                        <div className="row data-accord__start">
                            <div className="col-3">
                                <button className="data-accord__name data-accord__name--clear">{item.contractNumber? item.contractNumber: "--"}</button>
                            </div>
                            <div className="col-3">
                                <button className="data-accord__name data-accord__name--clear">{item.productOfferingName? item.productOfferingName: "--"}</button>
                            </div>
                            <div className="col-6 d-flex justify-content-between">
                                <div className="data-accord__status font-neo-semibold">{item.status ? title[1].Cell({ value: item.status }) : '--'}</div>
                                <button className="btn p-0" type="button">
                                    <div className="data-accord__caret ml-2">
                                        <div className="data-accord__caret-icon"></div>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div id={`collapse-${index}`} className="collapse" data-parent="#data-accord">
                        <div className="card-body data-accord__content">
                            <div className="row">
                                {
                                    customName.map(function(col, idx) {
                                        if(col && !notIncluded.includes(col['Header'])) {
                                            return (
                                                <>
                                                {
                                                    idx !== customName.length-1 && idx !== customName.length-2? (
                                                        <>
                                                        <div className="data-accord__column col-5">
                                                            <div className="data-accord__label">{col["Header"]? col["Header"]: '--'}</div>
                                                            <div className="data-accord__value">{ getEntry(col, item) }</div>
                                                        </div>
                                                        <div className="data-accord__column col-7">
                                                        </div>
                                                        </>
                                                    ):(
                                                        idx === customName.length-2?
                                                        <>
                                                        <div className="data-accord__column col-7">
                                                            <div className="data-accord__label">{col["Header"]? col["Header"]: '--'}</div>
                                                            <div className="data-accord__value">{ getEntry(col, item) }</div>
                                                        </div>
                                                        <div className="data-accord__column col-5">
                                                            <div className="data-accord__label">{customName[customName.length-1]["Header"]? customName[customName.length-1]["Header"]: '--'}</div>
                                                            <div className="data-accord__value">{ getEntry(customName[customName.length-1], item) }</div>
                                                        </div>
                                                        </> : ('')
                                                    )
                                                }
                                                </>
                                            )
                                        }
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </Auxi >
        );
    });

    return (
        <>
        <div className="data-accord row flex-wrap mt-4" >
                {(
                    <div className="data-accord__header">
                        <div className="col-3">
                            <div className="d-inline-flex align-items-center cs-pointer">
                                {props.title[0]["Header"]}
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="d-inline-flex align-items-center cs-pointer">
                                {props.title[2]["Header"]}
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="d-inline-flex align-items-center cs-pointer">
                                {props.title[1]["Header"]}
                            </div>
                        </div>
                    </div>
                )}
            <div className="col-12 data-accord__body mt-1">
                {props.isTableLoading? <div className="mt-5"><TableLoader loading={true} /></div>: ""}
                <div className="data-accord__collapse" id="data-accord">
                    {data.length === 0 && !props.isTableLoading? (<div className="center-align dashboard-row-data font-neo-semibold mt-4">No records found </div>): "" }
                    {!props.isTableLoading? fas: ""}
                </div>
            </div>
        </div>
        {props.children}
        </>
    );
}

export default ApplicationsMobile;
