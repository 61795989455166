import { myConfig } from './config.js';

class ViewSDKClient {
    constructor() {
        this.readyPromise = new Promise((resolve) => {
            if (window.AdobeDC) {
                resolve();
            } else {
                /* Wait for Adobe Acrobat Services PDF Embed API to be ready */
                document.addEventListener("adobe_dc_view_sdk.ready", () => {
                    resolve();
                });
            }
        });
        this.adobeDCView = undefined;
        this.clientId = window.location.hostname === 'localhost' ? '8a58831efc5f4903b17eda5001db9bc3' : myConfig.ADOBE_PDF_CLIENT_ID;
    }

    ready() {
        return this.readyPromise;
    }

    previewFile(divId, pdfProperties, viewerConfig) {
        const config = {
            clientId: this.clientId, // ? Deployed cid: ce3e622bd04b4b4a87347f133d03b190 // ? localhost: 8a58831efc5f4903b17eda5001db9bc3
            sendAutoPDFAnalytics: false,
        };
        if (divId) {
            config.divId = divId;
        }
        this.adobeDCView = new window.AdobeDC.View(config);
        const previewFilePromise = this.adobeDCView.previewFile({
            content: {
                location: {
                    url: pdfProperties.url,
                },
            },
            metaData: {
                fileName: pdfProperties.fileName,
            }
        },
            {
                ...viewerConfig,
                enableLinearization: true
            });
        return previewFilePromise;
    }

    eventAdobeLoad() {
        return new Promise((resolve, reject) => {
            this.adobeDCView.registerCallback(
                window.AdobeDC.View.Enum.CallbackType.EVENT_LISTENER,
                (event) => { event.type === 'APP_RENDERING_START' && resolve() }
            );
        });
    }
}

export default ViewSDKClient;