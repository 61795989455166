import React, { Fragment } from 'react';
import TableLoader from '../TableLoader/tableLoader';
import ReactTable from 'react-table';
import { myConfig } from '../../commons/config.js';
import withWindowDimensions from '../../commons/withWindowDimensions';
import PoliciesMobile from '../AgentPolicyList/Mobile/PoliciesMobile';
import TablePagination from '../tablePagination';
import { Link } from 'react-router-dom';

const Policies = (props) => {
    const {
        data,
        pages,
        totalSize,
        isTableLoading,
        handleStateChange,
        datasetId,
        isMd
    } = props;
    let { currentPage } = props;
    currentPage = currentPage - 1;
    let columns = [
        {
            Header: "Policy Number",
            accessor: "policyNum",
        },
        {
            Header: "Policy Owner Name",
            accessor: "ownerName",
        },
        {
            Header: "Date of Birth",
            accessor: "dob",
        },
        {
            Header: "Contact Number",
            accessor: "contactNum",
        },
        {
            Header: "Email",
            accessor: "email",
        },
        {
            Header: (
                <div className="auth-tag">
                    Authentication Tag
                    <div className="auth-tag__columns d-flex justify-content-between">
                        <div>Mobile</div>
                        <div>Email</div>
                    </div>
                </div>
            ),
            id: "authTag",
            accessor: (data) => {
                let returnValue;
                const mobileTag = data.mobileTag;
                const emailTag = data.emailTag;
                returnValue = (
                    <div className="auth-tag-value">
                        <label className="custom-checkbox custom-checkbox--container pe-none">
                            <input type="checkbox" checked={mobileTag} readOnly />
                            <span className="checkmark"></span>
                        </label>
                        <label className="custom-checkbox custom-checkbox--container pe-none">
                            <input type="checkbox" checked={emailTag} readOnly />
                            <span className="checkmark"></span>
                        </label>
                    </div>
                )
                return returnValue;
            }
        },
        {
            Header: "Actions",
            id: "actions",
            accessor: (data) => {
                return (
                    <Link
                        key={data.policyNum}
                        to={{
                            pathname: `/dashboard/policy-services/change-personal-data`,
                            query: { policyId: data.policyNum },
                            state: {
                                userData: data
                            }
                        }}
                        className={`dashboard-datatable__button btn btn-darkgreen rounded-pill px-4`}>
                        <i className="fas fa-plus mr-1"></i> New<br />Transaction
                    </Link>
                )
            }
        },
    ]

    if (datasetId === "endorsement") {
        columns = [
            {
                Header: "Policy Number",
                accessor: "policyNum",
            },
            {
                Header: "Policy Owner Name",
                accessor: "ownerName",
            },
            {
                Header: "Date of Birth",
                accessor: "dob",
            },
            {
                Header: "Contact Number",
                accessor: "contactNum",
            },
            {
                Header: "Email",
                accessor: "email",
            },
            {
                Header: "Actions",
                id: "actions",
                accessor: (data) => {
                    return (
                        <Link
                            key={data.policyNum}
                            to={{
                                pathname: `/dashboard/policy-services/new-fund-transfer-agreement`,
                                query: { policyId: data.policyNum },
                                state: {
                                    userData: data
                                }
                            }}
                            className={`dashboard-datatable__button btn btn-darkgreen rounded-pill px-4`}>
                            <i className="fas fa-plus mr-1"></i> New<br />Transaction
                        </Link>
                    )
                }
            },
        ]
    }

    let view = (
        <ReactTable
            columns={columns}
            data={data}
            loading={isTableLoading}
            minRows={1}
            LoadingComponent={TableLoader}
            defaultPageSize={myConfig.PAGE_SIZE}
            pages={pages}
            manual
            page={currentPage}
            totalSize={totalSize}
            onPageChange={(page) => handleStateChange("changePage", page)}
        />
    );

    if (isMd) {
        view = (
            <PoliciesMobile
                title={[
                    {
                        Header: 'Policy Number',
                        accessor: 'policyNum',
                    },
                    {
                        Header: 'Policy Owner Name',
                        accessor: 'ownerName',
                    },
                    {
                        Header: "Actions",
                        accessor: "actions"
                    },
                ]
                }
                columns={columns}
                data={data}
                isTableLoading={isTableLoading}
            >
                {!props.isTableLoading ? (
                    <TablePagination
                        css="mt-2"
                        data={data}
                        page={currentPage}
                        totalSize={totalSize}
                        onPageChange={(page) => handleStateChange("changePage", page)}
                        pageSize={myConfig.PAGE_SIZE}
                        pages={pages}
                        isLoading={isTableLoading}
                    />
                ) : ""}
            </PoliciesMobile>
        )
    }

    if (data.length === 0 && !isTableLoading) {
        view = (
            <div className="center-align dashboard-row-data font-neo-semibold mt-4">No records found </div>
        )
    }

    return (
        <Fragment>
            {view}
        </Fragment>
    )
}

export default withWindowDimensions(Policies);
