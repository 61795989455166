import React, { Component } from 'react';
import '../../css/mis.scss';
import { apiModule, createQueryStrings } from '../../commons/apiCall';
import { haveValue, charts } from '../../commons/misConfig';
import LineGraph from './lineGraph';
import { LINE_GRAPH_MODE, months, showNoData } from '../../commons/misConfig';
import Lottie from 'lottie-react-web';
import Coins from '../../assets/animation/coins.json'
import { formatValues, formatValuesNoDecimals, getStringDate, dateStringify } from './formatter';
import CommentIcon from './commentIcon';
import withWindowDimensions from '../../commons/withWindowDimensions';

class ActualVsTarget extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isAccumulative: true,
            isLoading: true,
            isActualVsActual: false,
            toggledAccumulative: false,
            toggledActual: false,
            totalComments: 0,

            data: {
                labels: [],
                datasets: []
            },

            chartColors: {
                actual: 'rgba(0,122,179,0.75)',
                target: 'rgba(61,165,86,0.75)',
                previous: 'rgba(150,220,250,0.9)',
                best: 'rgba(253,210,92,0.9)',
                forecast: 'rgba(202,212,222,0.50)'
            },

            customData: {
                normalDatasets: [],
                accumulativeDatasets: []
            },

            coinLoaderBig: <div style={{ height: '200px', marginTop: '80px' }}> <Lottie speed={4} options={{ animationData: Coins, loop: true }} /></div>,
            lineGraphMode: LINE_GRAPH_MODE.ACTUALVSTARGET
        }

        this.getActualVsTargetGraph = this.getActualVsTargetGraph.bind(this);
        this.onChangeLineGraphMode = this.onChangeLineGraphMode.bind(this);
        this.onChangeAccumulative = this.onChangeAccumulative.bind(this);
    }

    componentDidMount() {
        this.setState({
            // ...this.props,
            toggledAccumulative: true,
        })
        
        if (this.state.isActualVsActual) {
            this.getActualVsActualGraph(this.props);
            this.props.getCommentCount(this, 'actualActual', this.props.access_token);
        }
        else {
            this.getActualVsTargetGraph(this.props);
            this.props.getCommentCount(this, 'actualTarget', this.props.access_token);
        }

        if(!this.props.hasUpdateTime){
            this.getUpdateTime(this.props);
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.willUpdate) {
            this.setState({
                // ...nextProps,
                toggledAccumulative: false,
                isLoading: true,
            })
    
            if (this.state.isActualVsActual) {
                this.getActualVsActualGraph(nextProps);
            }
            else {
                this.getActualVsTargetGraph(nextProps);
            }
        }
        else {
            this.setState({
                willUpdate: nextProps.willUpdate,
                toggledAccumulative: false,
                toggledActual: false
            })
        }

        if(nextProps.willUpdateCommentCount){
            if(this.state.isActualVsActual){
                this.props.getCommentCount(this, 'actualActual', nextProps.access_token);
            }
            else{
                this.props.getCommentCount(this, 'actualTarget', nextProps.access_token);
            }
        }
    }

    async getUpdateTime(thisProps){
        let currentYear = new Date(thisProps.endDate).getFullYear();
        let currentMonth = new Date(thisProps.endDate).getMonth() + 1;

        let hasUpdateTime = false;
        let lastUpdateTimeDay = "";
        let lastUpdateTimeMonth = "";

        for(let i = currentMonth; i > 0; i--){
            let headerParams = {
                x_auth: thisProps.access_token
            };

            let lastDay = new Date(currentYear, i, 0).getDate();
            if(i == currentMonth){
                lastDay = new Date(thisProps.endDate).getDate();
            }
    
            let filter = [];
            filter['period'] = haveValue(thisProps.period.value) ? thisProps.period.value : '';
            filter['metric'] = haveValue(thisProps.metric.value) ? thisProps.metric.value : '';
            filter['productOfferingType'] = haveValue(thisProps.product.value) ? thisProps.product.value : '';
            filter['channel'] = haveValue(thisProps.channel.value) ? thisProps.channel.value : '';
            filter['startDate'] = getStringDate(currentYear, dateStringify(i), "01");
            filter['endDate'] = getStringDate(currentYear, dateStringify(i), dateStringify(lastDay));
            filter['contractStatus'] = haveValue(thisProps.status.value) ? thisProps.status.value : '';
            filter['targetType'] = haveValue(thisProps.target.value) ? thisProps.target.value : '';
            filter['workflowId'] = "CURRENT";
    
            let response = await apiModule("get_actual_line_graph", headerParams, null, createQueryStrings(filter))
                .then(function (result) {
                    return result;
                })
                .catch(function (err) {
                    console.log(err);
                    return err;
                });
            
            for(let j = lastDay - 1; j >= 0; j--){
                if(response.goalDetails && response.goalDetails[j].achievedPremiumRate != 0){
                    hasUpdateTime = true;
                    lastUpdateTimeDay = response.goalDetails[j].day;
                    lastUpdateTimeMonth = response.goalDetails[j].month;
                    break;
                }
            }

            if(hasUpdateTime){
                break;
            }
        }

        if(hasUpdateTime){
            let monthIndex = months.findIndex(month => month === lastUpdateTimeMonth) + 1;
            if (monthIndex < 10) monthIndex = `0${monthIndex}`;
            if (!monthIndex) {
                thisProps.getLatestUpdateTime("No Datetime Available");
            }
            else {
                thisProps.getLatestUpdateTime(`${currentYear}-${monthIndex}-${lastUpdateTimeDay}`);
            }
            // thisProps.getLatestUpdateTime(lastUpdateTimeMonth.substring(0,3) + ". " + lastUpdateTimeDay + ", " + currentYear);
        }
        else{
            thisProps.getLatestUpdateTime("No Datetime Available");
        }
    }

    async getActualVsTargetGraph(thisProps) {
        let period = thisProps.period;

        const { chartColors } = this.state;

        let currentYear = new Date(thisProps.endDate).getFullYear();
        let currentMonth = new Date(thisProps.endDate).toLocaleString('default', { month: 'long' });
        let currentDay = new Date(thisProps.endDate).getDate();

        let that = this;
        let maxDays = new Date(currentYear, new Date(thisProps.endDate).getMonth() + 1, 0).getDate();
        let requestActual, requestTarget, requestPreviousMonth, requestBestMonth = [];
        let hasActual, hasTarget, hasPrevious, hasBest, isPreviousAndBestSame = false;
        let actualLabel, previousLabel, bestLabel;
        
        const isMTD = haveValue(period) ? (period.value.toUpperCase() == 'MTD' || period.value.toUpperCase() === 'LAST MONTH') : false;
        const isYTD = haveValue(period) ? period.value.toUpperCase() == 'YTD' : false;
        const isDateRange = haveValue(period) ? period.value.toUpperCase().includes('DATE') || period.value.toUpperCase().includes('Q1') || period.value.toUpperCase().includes('Q2') || period.value.toUpperCase().includes('Q3') || period.value.toUpperCase().includes('Q4') : false;

        requestActual = this.getGraphResource(thisProps, "get_actual_line_graph", "CURRENT");
        requestTarget = this.getGraphResource(thisProps, "get_target_line_graph", "CURRENT");
        if (isMTD) {
            requestPreviousMonth = this.getGraphResource(thisProps, "get_actual_line_graph", "PREV_MTH");
            requestBestMonth = this.getGraphResource(thisProps, "get_actual_line_graph", "BEST");
        }

        const [resultActual, resultTarget, resultPreviousMonth, resultBestMonth] = await Promise.all([requestActual, requestTarget, requestPreviousMonth, requestBestMonth])

        hasActual = resultActual.goalDetails !== null && resultActual.goalDetails !== undefined;
        hasTarget = resultTarget.goalDetails != null && resultTarget.goalDetails !== undefined;
        hasPrevious = isMTD ? resultPreviousMonth.goalDetails !== null && resultPreviousMonth.goalDetails !== undefined : false;
        hasBest = isMTD ? resultBestMonth.goalDetails !== null && resultBestMonth.goalDetails !== undefined : false;
        isPreviousAndBestSame = isMTD && hasPrevious && hasBest ? resultPreviousMonth.goalDetails[0].month == resultBestMonth.goalDetails[0].month : false;

        if (isMTD) {
            actualLabel = hasActual ? " (" + resultActual.goalDetails[0].month + ")" : "";
            previousLabel = hasPrevious ? " (" + resultPreviousMonth.goalDetails[0].month + ")" : "";
            bestLabel = hasBest ? " (" + resultBestMonth.goalDetails[0].month + ")" : "";

            // if (haveValue(period)) {
            //     if (period.value.toUpperCase() === 'LAST MONTH') {
            //         actualLabel = hasActual ? " (" + months[new Date(resultActual.goalDetails[0].month).getMonth()] + ")" : "";
            //         previousLabel = hasPrevious ? " (" + months[new Date(resultPreviousMonth.goalDetails[0].month).getMonth()] + ")" : "";
            //         bestLabel = hasBest ? " (" + months[new Date(resultBestMonth.goalDetails[0].month).getMonth()] + ")" : "";
            //     }
            // }
        }

        if (isYTD) {
            actualLabel = hasActual ? " (" + currentYear + ")" : "";
        }

        if (isDateRange) {
            actualLabel = hasActual ?
                resultActual.goalDetails.length == 0 ? "" :
                    resultActual.goalDetails[0].month == resultActual.goalDetails[resultActual.goalDetails.length - 1].month ?
                        " (" + resultActual.goalDetails[0].month + ")" :
                        " (" + resultActual.goalDetails[0].month + " - " + resultActual.goalDetails[resultActual.goalDetails.length - 1].month + ")" : "";
        }

        let dataTemplate = {
            labels: [],
            datasets: [{
                label: 'Actual' + actualLabel,
                fill: true,
                backgroundColor: chartColors.actual,
                borderColor: chartColors.actual,
                pointRadius: 0,
                data: []
            },
            {
                label: 'Target',
                fill: false,
                backgroundColor: chartColors.target,
                borderColor: chartColors.target,
                pointRadius: 0,
                data: []
            },
            {
                label: 'Forecast',
                fill: true,
                backgroundColor: chartColors.forecast,
                borderColor: chartColors.actual,
                borderDash: [5, 5],
                pointRadius: 0,
                data: []
            },
            {
                label: 'Previous Month' + previousLabel,
                fill: true,
                backgroundColor: chartColors.previous,
                borderColor: chartColors.previous,
                pointRadius: 0,
                data: []
            },
            {
                label: (isPreviousAndBestSame ? 'Previous/' : '') + 'Best Month' + bestLabel,
                fill: true,
                backgroundColor: chartColors.best,
                borderColor: chartColors.best,
                pointRadius: 0,
                data: []
            }]
        }

        let customData = {
            dailyData: {},
            accumulativeData: {}
        }

        customData.dailyData = JSON.parse(JSON.stringify(dataTemplate));
        customData.accumulativeData = JSON.parse(JSON.stringify(dataTemplate));

        let maxLength = 0;
        let actualLength = hasActual ? resultActual.goalDetails.length : 0;
        let previousMonthLength = hasPrevious ? resultPreviousMonth.goalDetails.length : 0;
        let bestMonthLength = hasBest ? resultBestMonth.goalDetails.length : 0;

        maxLength = Math.max(actualLength, previousMonthLength, bestMonthLength);

        for (let i = 0; i < maxLength; i++) {
            if (isMTD) {
                customData.dailyData.labels[i] = i + 1;
                customData.accumulativeData.labels[i] = i + 1;
            }
            else if (isYTD) {
                customData.dailyData.labels[i] = that.shortMonth(months[i]);
                customData.accumulativeData.labels[i] = that.shortMonth(months[i]);
            }
            else if (isDateRange) {
                let resultObj = resultActual.goalDetails[i];
                customData.dailyData.labels[i] = haveValue(resultObj.day) ? resultObj.day : that.shortMonth(resultObj.month);
                customData.accumulativeData.labels[i] = haveValue(resultObj.day) ? resultObj.day : that.shortMonth(resultObj.month);
            }
        }


        if (hasActual) {
            let total = 0
            let isCurrentMonth = false;
            let isCurrentDay = false;

            resultActual.goalDetails.map(function (obj, index) {
                total += obj.achievedPremiumRate;

                // customData.dailyData.labels[index] = haveValue(obj.day) ? obj.day : that.shortMonth(obj.month);
                // customData.accumulativeData.labels[index] = haveValue(obj.day) ?obj.day : that.shortMonth(obj.month);

                let dataLabel = haveValue(obj.day) ?obj.day : that.shortMonth(obj.month);
                let isLastItem = (index + 1) === resultActual.goalDetails.length;
                let serverMonth = that.props.formattedCurrentMonthDate ? new Date(that.props.formattedCurrentMonthDate).getMonth() + 1 : new Date().getMonth() + 1;
                let objMonth = months.indexOf(obj.month) + 1;
                let isPreviousMonth = serverMonth >= objMonth;
                let isQuarter = period.value.toUpperCase().includes('Q1') || period.value.toUpperCase().includes('Q2') || period.value.toUpperCase().includes('Q3') || period.value.toUpperCase().includes('Q4');
                let isZero = obj.achievedPremiumRate === 0;
                if(!isCurrentDay && !isCurrentMonth){
                    if (isQuarter) {
                        if ((!isZero && !isLastItem && isPreviousMonth) ||
                            (isZero && isLastItem && isPreviousMonth) ||
                            (!isZero && isLastItem && isPreviousMonth) ||
                            (isZero && !isLastItem && isPreviousMonth)) {
                            customData.dailyData.datasets[0].data[index] = {
                                x: dataLabel,
                                y: haveValue(obj.achievedPremiumRate) ? obj.achievedPremiumRate : 0
                            }
                            customData.accumulativeData.datasets[0].data[index] = {
                                x: dataLabel,
                                y: total
                            }
                        }
                    } else {
                        customData.dailyData.datasets[0].data[index] = {
                            x: dataLabel,
                            y: haveValue(obj.achievedPremiumRate) ? obj.achievedPremiumRate : 0
                        }

                        customData.accumulativeData.datasets[0].data[index] = {
                            x: dataLabel,
                            y: total
                        }
                    }
                }
            })
        }

        // //Target Rate
        if (hasTarget && hasActual) {
            let total = 0;
            let isDateRangeOneMonth = isDateRange && resultTarget.goalDetails.length == 1;
            let isDateRangeMoreThanOneMonth = isDateRange && resultTarget.goalDetails.length > 1;

            resultTarget.goalDetails.map(function (obj, index) {
                let dataLabel = haveValue(obj.day) ? obj.day : that.shortMonth(obj.month);

                if (isYTD || isDateRangeMoreThanOneMonth) {
                    total += obj.plannedPremiumRate;

                    customData.dailyData.datasets[1].data[index] = {
                        x: dataLabel,
                        y: haveValue(obj.plannedPremiumRate) ? obj.plannedPremiumRate : 0
                    }

                    customData.accumulativeData.datasets[1].data[index] = {
                        x: dataLabel,
                        y: total
                    }
                }
                else if (isMTD || isDateRangeOneMonth) {
                    resultActual.goalDetails.map(function (obj, index) {
                        customData.dailyData.datasets[1].data[index] = {
                            x: obj,
                            y: haveValue(resultTarget.goalDetails[0].plannedPremiumRate) ? resultTarget.goalDetails[0].plannedPremiumRate /
                                maxDays : 0
                        }

                        customData.accumulativeData.datasets[1].data[index] = {
                            x: obj,
                            y: resultTarget.goalDetails[0].plannedPremiumRate * (index + 1) / maxDays
                        }
                    })
                }
            })
        }

        //Forecast
        if (hasActual) {
            let length = customData.accumulativeData.datasets[1].data.length;

            let peakTarget = length > 0 ? customData.accumulativeData.datasets[1].data[customData.accumulativeData.datasets[1].data.length - 1].y : 0;
            let isCurrentMonth = false;
            let isCurrentDay = false;
            let lastAchieved = 0;
            let lastNum = 0;
            let difference = 0;
            let serverMonth = that.props.formattedCurrentMonthDate ? months[new Date(that.props.formattedCurrentMonthDate).getMonth()] : months[new Date().getMonth()];
            
            if(peakTarget != 0){
                resultActual.goalDetails.map(function (obj, index) {
                    let dataLabel = haveValue(obj.day) ? obj.day : that.shortMonth(obj.month);
                    let isQuarter = haveValue(period) ? period.value.toUpperCase() === 'Q1' || period.value.toUpperCase() === 'Q1' || period.value.toUpperCase() === 'Q3' || period.value.toUpperCase() === 'Q4' : false;
                    if ((index < customData.accumulativeData.datasets[0].data.length) && ((isMTD && currentDay == obj.day) || (isYTD && currentMonth == obj.month) || (isQuarter && serverMonth == obj.month))) {
                        isCurrentDay = true;
                        isCurrentMonth = true;
                        lastAchieved = customData.accumulativeData.datasets[0].data[index].y ? customData.accumulativeData.datasets[0].data[index].y : 0;
                        lastNum = index + 1;
                        difference = (peakTarget - lastAchieved) / (resultActual.goalDetails.length - lastNum)
                    }

                    let lastIndex = isMTD ? currentDay : new Date(currentYear, currentMonth, currentDay).getMonth() + 1;

                    if (lastIndex != resultActual.goalDetails.length) {
                        if (isCurrentDay || isCurrentMonth) {
                            customData.accumulativeData.datasets[2].data[index] = {
                                x: dataLabel,
                                y: lastAchieved + (difference * ((index + 1) - lastNum))
                            }
                        }
                        else {
                            customData.accumulativeData.datasets[2].data[index] = {
                                x: dataLabel
                            }
                        }
                    }
                })
            }
        }

        //Previous Month
        if (hasPrevious && !isPreviousAndBestSame) {
            let total = 0;

            resultPreviousMonth.goalDetails.map(function (obj, index) {
                let achievedPremiumRate = obj ? obj.achievedPremiumRate : 0;

                total += achievedPremiumRate;

                let dataLabel = haveValue(obj.day) ? obj.day : that.shortMonth(obj.month);

                customData.dailyData.datasets[3].data[index] = {
                    x: dataLabel,
                    y: achievedPremiumRate
                }

                customData.accumulativeData.datasets[3].data[index] = {
                    x: dataLabel,
                    y: total
                }
            })
        }

        // //Best Month
        if (hasBest) {
            let total = 0;

            resultBestMonth.goalDetails.map(function (obj, index) {
                let achievedPremiumRate = obj ? obj.achievedPremiumRate : 0;

                total += achievedPremiumRate;

                let dataLabel = haveValue(obj.day) ? obj.day : that.shortMonth(obj.month);

                customData.dailyData.datasets[4].data[index] = {
                    x: dataLabel,
                    y: achievedPremiumRate
                }

                customData.accumulativeData.datasets[4].data[index] = {
                    x: dataLabel,
                    y: total
                }
            })
        }


        // let chartId = 'actualTarget';
        // let commentFilter = [];
        // commentFilter['filter'] = 'count';

        // this.getGraphCommentCount(that.props, chartId, commentFilter);

        this.setState({
            isLoading: false,
            customData: customData
        })
    }

    async getActualVsActualGraph(thisProps) {
        let period = thisProps.period;
        const { chartColors } = this.state;
        let that = this;

        // if(haveValue(thisProps.period)){
        //     period = {
        //         value: new Date(thisProps.startDate).getMonth() == new Date(thisProps.endDate).getMonth() ? 'MTD' : 'YTD',
        //         label: 'Date Range'
        //     }
        // }

        let currentYear = new Date(thisProps.endDate).getFullYear();
        let currentMonth = new Date(thisProps.endDate).toLocaleString('default', { month: 'long' });
        let currentDay = new Date(thisProps.endDate).getDate();

        let requestActual, requestPreviousYear = [];
        let hasActual = false;
        let hasPrevious = false;
        let actualLabel, previousLabel = "";
        
        const isMTD = haveValue(period) ? (period.value.toUpperCase() == 'MTD' || period.value.toUpperCase() === 'LAST MONTH') : false;
        const isYTD = haveValue(period) ? period.value.toUpperCase() == 'YTD' : false;
        const isDateRange = haveValue(period) ? period.value.toUpperCase().includes('DATE') || period.value.toUpperCase().includes('Q1') || period.value.toUpperCase().includes('Q2') || period.value.toUpperCase().includes('Q3') || period.value.toUpperCase().includes('Q4') : false;

        requestActual = this.getGraphResource(thisProps, "get_actual_line_graph", "CURRENT");
        requestPreviousYear = this.getGraphResource(thisProps, "get_actual_line_graph", "PREV_YR");

        const [resultActual, resultPreviousYear] = await Promise.all([requestActual, requestPreviousYear])

        hasActual = resultActual.goalDetails !== null && resultActual.goalDetails !== undefined;
        hasPrevious = resultPreviousYear.goalDetails !== null && resultPreviousYear.goalDetails !== undefined;

        if (isMTD) {
            actualLabel = resultActual.goalDetails !== null && resultActual.goalDetails !== undefined ? " (" + resultActual.goalDetails[0].month + ")" : "";
            previousLabel = hasPrevious ? " (" + resultPreviousYear.goalDetails[0].month + ")" : "";

            // if (haveValue(period)) {
            //     if (period.value.toUpperCase() === 'LAST MONTH') {
            //         actualLabel = hasActual ? " (" + months[new Date(resultActual.goalDetails[0].month).getMonth()] + ")" : "";
            //         previousLabel = hasPrevious ? " (" + months[new Date(resultPreviousYear.goalDetails[0].month).getMonth()] + ")" : "";
            //     }
            // }
        }

        // let isQuarter = haveValue(period) ? period.value.toUpperCase() === 'Q1' || period.value.toUpperCase() === 'Q1' || period.value.toUpperCase() === 'Q3' || period.value.toUpperCase() === 'Q4' : false;

        if(isYTD){
            actualLabel = resultActual.goalDetails !== null && resultActual.goalDetails !== undefined ? " (" + currentYear + ")" : "";
        }

        if (isDateRange || isYTD) {
            actualLabel = hasActual ? 
                resultActual.goalDetails.length == 0 ? "" :
                    resultActual.goalDetails[0].month == resultActual.goalDetails[resultActual.goalDetails.length - 1].month ?
                        " (" + resultActual.goalDetails[0].month + ")" :
                        " (" + resultActual.goalDetails[0].month + " - " + resultActual.goalDetails[resultActual.goalDetails.length - 1].month + ")" : "";
        }

        let dataTemplate = {
            labels: [],
            datasets: [{
                label: 'Actual' + actualLabel,
                fill: true,
                backgroundColor: chartColors.actual,
                borderColor: chartColors.actual,
                lineTension: 0.4,
                pointRadius: 0,
                data: []
            },
            {
                label: 'Previous Year' + previousLabel,
                fill: true,
                backgroundColor: chartColors.previous,
                borderColor: chartColors.previous,
                lineTension: 0.4,
                pointRadius: 0,
                data: []
            }]
        }

        let customData = {
            dailyData: {},
            accumulativeData: {}
        }

        customData.dailyData = JSON.parse(JSON.stringify(dataTemplate));
        customData.accumulativeData = JSON.parse(JSON.stringify(dataTemplate));
        
        let maxLength = 0;
        let actualLength = hasActual ? resultActual.goalDetails.length : 0;
        let previousYearLength = hasPrevious ? resultPreviousYear.goalDetails.length : 0;

        maxLength = Math.max(actualLength, previousYearLength);

        //Labels
        for (let i = 0; i < maxLength; i++) {
            if (isMTD) {
                customData.dailyData.labels[i] = i + 1;
                customData.accumulativeData.labels[i] = i + 1;
            }
            else if (isYTD) {
                customData.dailyData.labels[i] = that.shortMonth(months[i]);
                customData.accumulativeData.labels[i] = that.shortMonth(months[i]);
            }
            else if (isDateRange) {
                let resultObj = resultActual.goalDetails[i];
                customData.dailyData.labels[i] = haveValue(resultObj.day) ? resultObj.day : that.shortMonth(resultObj.month);
                customData.accumulativeData.labels[i] = haveValue(resultObj.day) ? resultObj.day : that.shortMonth(resultObj.month);
            }
        }

        //Actual Rate
        if (hasActual) {
            let total = 0
            let isCurrentMonth = false;
            let isCurrentDay = false;

            resultActual.goalDetails.map(function (obj, index) {
                total += obj.achievedPremiumRate;

                // customData.dailyData.labels[index] = haveValue(obj.day) ? obj.day : that.shortMonth(obj.month);
                // customData.accumulativeData.labels[index] = haveValue(obj.day) ?obj.day : that.shortMonth(obj.month);

                let dataLabel = haveValue(obj.day) ?obj.day : that.shortMonth(obj.month);
                let isLastItem = (index + 1) === resultActual.goalDetails.length;
                let serverMonth = that.props.formattedCurrentMonthDate ? new Date(that.props.formattedCurrentMonthDate).getMonth() + 1 : new Date().getMonth() + 1;
                let objMonth = months.indexOf(obj.month) + 1;
                let isPreviousMonth = serverMonth >= objMonth;
                let isQuarter = period.value.toUpperCase().includes('Q1') || period.value.toUpperCase().includes('Q2') || period.value.toUpperCase().includes('Q3') || period.value.toUpperCase().includes('Q4');
                let isZero = obj.achievedPremiumRate === 0;

                if(!isCurrentDay && !isCurrentMonth){
                    if (isQuarter) {
                        if ((!isZero && !isLastItem && isPreviousMonth) ||
                            (isZero && isLastItem && isPreviousMonth) ||
                            (!isZero && isLastItem && isPreviousMonth) ||
                            (isZero && !isLastItem && isPreviousMonth)) {
                            customData.dailyData.datasets[0].data[index] = {
                                x: dataLabel,
                                y: haveValue(obj.achievedPremiumRate) ? obj.achievedPremiumRate : 0
                            }
                            customData.accumulativeData.datasets[0].data[index] = {
                                x: dataLabel,
                                y: total
                            }
                        }
                    } else {
                        customData.dailyData.datasets[0].data[index] = {
                            x: dataLabel,
                            y: haveValue(obj.achievedPremiumRate) ? obj.achievedPremiumRate : 0
                        }
                        customData.accumulativeData.datasets[0].data[index] = {
                            x: dataLabel,
                            y: total
                        }
                    }
                }
            })
        }

        //Previous Year
        if (hasPrevious) {
            let total = 0;

            resultPreviousYear.goalDetails.map(function (obj, index) {
                total += obj.achievedPremiumRate;

                let dataLabel = haveValue(obj.day) ? obj.day : that.shortMonth(obj.month);

                customData.dailyData.datasets[1].data[index] = {
                    x: dataLabel,
                    y: haveValue(obj.achievedPremiumRate) ? obj.achievedPremiumRate : 0
                }

                customData.accumulativeData.datasets[1].data[index] = {
                    x: dataLabel,
                    y: total
                }
            })
        }

        this.setState({
            isLoading: false,
            customData: customData
        })
    }

    getGraphResource(thisProps, moduleName, workflowId) {
        const { maxDays } = this.props;
        let headerParams = {
            x_auth: thisProps.access_token
        };

        //No Date Range
        if(thisProps.period.value.toUpperCase().includes("DATE RANGE")){
            return {};
        }

        let filter = [];
        filter['period'] = haveValue(thisProps.period.value) ? thisProps.period.value : '';
        filter['metric'] = haveValue(thisProps.metric.value) ? thisProps.metric.value : '';
        filter['productOfferingType'] = haveValue(thisProps.product.value) ? thisProps.product.value : '';
        filter['channel'] = haveValue(thisProps.channel.value) ? thisProps.channel.value : '';
        filter['startDate'] = haveValue(thisProps.startDate) ? thisProps.startDate : '';
        filter['endDate'] = haveValue(thisProps.endDate) ? thisProps.endDate : '';
        filter['contractStatus'] = haveValue(thisProps.status.value) ? thisProps.status.value : '';
        filter['targetType'] = haveValue(thisProps.target.value) ? thisProps.target.value : '';
        filter['workflowId'] = workflowId;

        let response = apiModule(moduleName, headerParams, null, createQueryStrings(filter))
            .then(function (result) {
                return result;
            })
            .catch(function (err) {
                console.log(err);
                return err;
            });

        return response;
    }

    // getGraphCommentCount(thisProps, chartId, filter){
    //     let that = this;
    //     let headerParams = {
    //         chartId: chartId,
    //         x_auth: thisProps.access_token
    //     };

    //     apiModule("get_comments", headerParams, null, createQueryStrings(filter))
    //         .then(function (result) {
    //             that.setState({
    //                 totalComments: result.totalComments
    //             })
    //         })
    //         .catch(function (err) {
    //             console.log(err);
    //         });
    // }

    shortMonth(month) {
        return haveValue(month) ? month.slice(0, 3) : month;
    }

    onChangeLineGraphMode(e) {
        let isActualVsActual = e.target.value == LINE_GRAPH_MODE.ACTUALVSACTUAL ? true : false;
        this.setState({
            lineGraphMode: e.target.value,
            toggledActual: true,
            isActualVsActual: isActualVsActual,
            isLoading: true
        })

        if (isActualVsActual) {
            this.getActualVsActualGraph(this.props);
            this.props.getCommentCount(this, 'actualActual', this.props.access_token);
        }
        else {
            this.getActualVsTargetGraph(this.props);
            this.props.getCommentCount(this, 'actualTarget', this.props.access_token);
        }
    }

    onChangeAccumulative() {
        const { isAccumulative } = this.state;
        this.setState({
            isAccumulative: !isAccumulative,
            toggledAccumulative: true
        })
    }

    render() {
        const { isLoading, isAccumulative, customData, toggledAccumulative, toggledActual, coinLoaderBig, isActualVsActual, totalComments } = this.state;
        const { willUpdate, onClickCommentIcon, currTarget } = this.props;
        let avtHeight = this.container ? this.container.offsetHeight : 330;
        let isPD = currTarget.value.toUpperCase().includes('PD');
        return (
            <div className="container mis-container col-sm-12 col-md-12 col-lg-6 col-xl-6">
                {/* <div className="mis-comment-icon-container" onClick={() => onClickCommentIcon(isActualVsActual ? charts.actualActual : charts.actualTarget)}>
                    <div className="mis-comment-icon">
                        <img src = "/mis/comments.svg" className="mis-comment-icon-img"/>
                        <span className="mis-comment-count">
                            {formatValuesNoDecimals(totalComments)}
                        </span>
                    </div>
                </div> */}
                <CommentIcon
                    chart={isActualVsActual ? charts.actualActual : charts.actualTarget}
                    onClickCommentIcon={onClickCommentIcon} 
                    totalComments={totalComments} />
                <div className="card mis-card-2 bg-white p-3 shadow-sm rounded">
                    <div ref={e => { this.container = e; }} className="card-body mis-card-body">
                        <div className="card-title mis-card-title">
                            <select className="mdb-select md-form d-flex dashboard-data-header__text align-items-end font-neo-semibold text-headercolor mis-select float-sm-left float-md-left float-lg-left float-xl-left"
                                style={{ border: '2px #0003 solid' }}
                                onChange={(e) => { this.onChangeLineGraphMode(e) }}>
                                <option value={LINE_GRAPH_MODE.ACTUALVSTARGET} defaultValue>Actual Vs Target</option>
                                <option value={LINE_GRAPH_MODE.ACTUALVSACTUAL}>Actual Vs Actual (Previous Year)</option>
                            </select>
                            <span className="mis-accumulative-button">
                                <span className="mis-accumulative-desc">Accumulative</span>
                                <label className="switch">
                                    <input type="checkbox"
                                        onClick={this.onChangeAccumulative}
                                    />
                                    <span className="slider round" />
                                </label>
                            </span>
                        </div>
                        <div style={{ clear: "both" }} />
                        {isPD && isActualVsActual ? showNoData(true) :
                            isLoading ? coinLoaderBig :
                            <LineGraph height={'calc(' + avtHeight + 'px' + ' - 30px - 16px ' + (this.props.isSm ? '- 60px ' : '- 15px ') + '- 0.75rem)'} chartId="actualVsTarget"
                                {...this.props}
                                willUpdate={willUpdate || toggledAccumulative || toggledActual}
                                data={isAccumulative ? customData.accumulativeData : customData.dailyData} />
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default withWindowDimensions(ActualVsTarget); 
