import React, { Component } from 'react';
import { Loader } from '../commons/utilityViews.js';
import { withRouter } from 'react-router-dom';
import { ReportLoader } from '../commons/utilityViews.js';
import 'react-vertical-timeline-component/style.min.css';
import { decrypt, encrypt, checkString, checkCharacters, urlBase64ToUint8Array } from '../commons/utility.js';
import { apiModule } from '../commons/apiCall';
import { myConfig } from '../commons/config.js';
import routeChange from '../commons/routeChange.js';
import Select from "react-select";
import { BlackMesaIcon } from 'mdi-react';
import ReactTooltip from 'react-tooltip';

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        // color: "#9A9B9C",
        color: "black",
        fontSize: ".8rem",
        // fontFamily: "neo_semibold",
        backgroundColor: "white",
        ':active': {
            backgroundColor: "#BED9EE",
        },
        textAlign: "left",
        cursor: "pointer"
    }),
    singleValue: (provided, state) => ({
        // fontFamily: 'neo_semibold',
        // fontSize: ".9rem"
    }),
    // placeholder: () => ({
    //     fontSize: ".9rem"
    // }),
    menu: () => ({
        position: "absolute",
        // backgroundColor: "#255F8E",
        borderRadius: "5px",
        // left: 0,
        // width: "10rem",
        width: "100%",
        whiteSpace: "nowrap",
        boxShadow: "2px 2px 2px #DDDEE0",
        zIndex: "1090"
    }),
    control: (provided, state) => ({
        ...provided,
        borderWidth: "1px",
        boxShadow: 0,
        minHeight: "initial",
        padding: ".1rem",
    }),
    indicatorSeparator: (provided) => ({
        ...provided,
        width: 0,
    }),
    valueContainer: (provided) => ({
        ...provided,
        padding: ".1rem .5rem",
        fontSize: ".9rem"
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        padding: 0,
    }),
    placeholder: () => ({
        color: "black",
    })
}

class Contact extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            value: '',
            message: '',
            error: '',
            concern_type: {value: "", label: "Please select" },
            showPrompt: false,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleConcernChange = this.handleConcernChange.bind(this);
        this.concernOptions = [
            { label: "Technical Support", value: "technical" },
            { label: "Distribution Support", value: "distribution" }
        ];
    }

    async componentDidMount() {
    }

    errorMessage = (message) => {
        if (message) {
            this.setState({ error: message, isLoading: false })
        }
    }
    resetError = () => {
        this.setState({ error: '', isLoading: true, showPrompt: false })
    }
    //SHOW ERROR IF ERROR STATE IS NOT NULL
    showError = () => {
        let message = '';
        if (this.state.error !== '') {
            message =
                <div className="bg-error py-1 px-4">
                    <p className="text-white mb-0 font-neo-semibold">ERROR: {this.state.error}</p>
                </div>
        }

        return message;
    }

    userPrompt = (css) => {
        document.addEventListener('mousedown', this.handleClickOutside);
        return (
            <div className={`user-prompt modal fade ${css}`} tabIndex="-1" role="dialog">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div
                        className="modal-content user-prompt__content"
                        ref={this.setWrapperRef}
                    >
                        <div className="modal-body user-prompt__body py-5">
                            <h5 className="user-prompt__body-header font-neo-bold">{this.state.message}</h5>
                            <div className="user-prompt__btns mt-6">
                                <button onClick={() => this.setState({ showPrompt: false })} className="btn user-prompt__btn btn-headercolor font-neo-semibold mx-2">Ok</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    handleChange(event) {
        this.setState({ value: event.target.value });
    }

    handleConcernChange = (option) => {
        console.log("concern type is " + option.value);
        this.setState({ concern_type: option });
    }

    cancelButton = (that) => {
        if (window.confirm("Are you sure you want to cancel?")) {
            routeChange(that.props.history, "/dashboard/dashboard");
        }
    }

    handleSubmit(event) {
        console.log('Form Value: ' + this.state.value);
        window.scrollTo(0, 0);
        if (this.state.value === "") {

            this.setState({
                error: "Invalid input. Minimum number of characters is 25",
                isLoading: false,
            });
        }
        else if (!checkString(this.state.value, this.errorMessage, 25)) {

            this.setState({
                isLoading: false,
            });
        } else if (this.state.concern_type.value === "") {
            this.setState({
                isLoading: false,
                error: "Please indicate if your requesting for technical or  distribution support"
            });
        } else {
            this.resetError();
            let access_token = this.props.getSession().access_token;
            let username = decrypt(this.props.getSession().username);
            let agentNumber = decrypt(this.props.getSession().agentNumber);
            let name = decrypt(this.props.getSession().name);

            let formatUser = "";
            formatUser += "Name: " + name + "\n";
            formatUser += "Email: " + username + "\n";
            formatUser += "Agent Number: " + agentNumber + "\n";
            formatUser += "Message: \n " + this.state.value;

            let that = this;

            let headerParams = {
                accountManagerId: username,
                x_auth: access_token
            };
            let toEmail = "customercare@allianzpnblife.ph";
            if (this.state.concern_type.value === "technical") {
                toEmail = "IT.Helpdesk@allianzpnblife.ph";
            }
            let payloadParams =
            {
                "from": username,//"ramon.salvador@allianzpnblife.ph",
                "to": [
                    //"aldrich.abrogena@dice205.com",
                    toEmail

                ],
                "message": formatUser,
                "subject": "Contact Us Form Submission",

            };


            apiModule("send_email", headerParams, payloadParams, '', true)
                .then(function (result) {
                    that.setState({
                        loadTable: false,
                        isLoading: false,
                        error: '',
                        showPrompt: true,
                        message: "Thank you for connecting with us!"
                    })
                })
                .catch(function (err) {
                    console.log(err);
                    that.setState({
                        loadTable: false,
                        isLoading: false,
                    });
                    that.errorMessage(myConfig.GENERIC_ERROR_MESSAGE);
                    //   resolve("Ok");

                });

        }

    }

    render() {
        if (this.state.isLoading) {
            return (
                <ReportLoader handleClose={this.hideModal} show={this.state.isLoading} />
            );
        } else {
            return (
                <div className="dashboard-section">
                    {this.showError()}
                    <div className="dashboard-container container-fluid p-3">

                        <div className="dashboard-header">
                            <h6 className="font-neo-bold text-darkgray">Contact Us</h6>
                            {this.props.getViewAs}
                        </div>

                        <div className="dashboard-body mt-4">
                            <div className="bg-white p-4 pb-7 shadow-sm rounded">
                                <h1 className="contact-header text-headercolor">At Allianz PNB Life, we are here to help.</h1>
                                <p className="text-calmblack text-calmblack">
                                    Should you have any concerns or complaints, you may reach us at any of the following options:
                                </p>

                                <div className="block-center">
                                    <div className="block-center-flex">
                                        <div className="contact-block block1">
                                            <h3 className="contact-block__header text-headercolor mb-1">Contact:</h3>
                                            <h4 className="contact-block__header2 text-headercolor mb-1">Distribution:</h4>
                                            <p className="text-calmblack">
                                                HOTLINE: 8818-HELP(4357) <br />
                                                TRUNKLINE: 8818-LIFE(5433) <br />
                                                FAX: 8818-2701 <br />
                                                Domestic Toll Free Number: <br />
                                                1-800-10-818-5433
                                            </p>
                                            <h4 className="contact-block__header2 text-headercolor mb-1">Technical:</h4>
                                            <p className="text-calmblack">
                                                HOTLINE: 555-4911 <br />
                                            </p>
                                        </div>

                                        <div className="contact-block block2">
                                            <h3 className="contact-block__header text-headercolor mb-1">Email Address:</h3>
                                            <h3 className="contact-block__header2 text-headercolor mb-1">Distribution:</h3>
                                            <p className="text-calmblack">
                                                customercare@allianzpnblife.ph
                                            </p>
                                            <p className="text-calmblack">
                                                MAKATI CENTRAL OFFICE <br />
                                                P.O. Box 3191
                                            </p>
                                            <h3 className="contact-block__header2 text-headercolor mb-1">Technical:</h3>
                                            <p className="text-calmblack">
                                                IT.Helpdesk@allianzpnblife.ph
                                            </p>

                                        </div>


                                        <div className="contact-block block3">
                                            <h3 className="contact-block__header text-headercolor mb-1">Office Address:</h3>
                                            <p className="text-calmblack">
                                                9th Floor, Allied Bank Center, <br />
                                                6754 Ayala Avenue corner <br />
                                                Legaspi Street, Makati City
                                            </p>
                                        </div>
                                    </div>

                                    <div className="line_separator"></div>

                                    <div className="form_message mt-4 mt-xl-0">

                                        {/* <div className="row mx-auto">
                                                <div className="ml-1"><input type="radio" name="concern_type" value={"technical"} onChange={(e) => this.handleRadioChange(e)}></input><span className="ml-2">Technical Support</span></div>
                                                <div className="ml-6"><input type="radio" name="concern_type" value={"distribution"} onChange={(e) => this.handleRadioChange(e)}></input><span className="ml-2">Distribution Support</span></div>
                                            </div> */}
                                        <div className="">
                                            <p className="mb-0 d-flex align-items-center">
                                                <span>Send message to:</span>
                                                <a data-for="info-message" className="btn text-headercolor p-0 lh-0" data-tip data-iscapture="true">
                                                    <span style={{ fontSize: "1.25rem" }} className="icon-info-circle-o ml-1"></span>
                                                </a>
                                            </p>
                                            <ReactTooltip id="info-message" type="info">
                                                <span>Choose to send the message<br />to Technical Support or Distribution Support</span>
                                            </ReactTooltip>
                                        </div>
                                        <Select
                                            styles={customStyles}
                                            options={this.concernOptions}
                                            isSearchable={false}
                                            className="concern-select mb-2 mt-2"
                                            classNamePrefix="concern-select"
                                            onChange={this.handleConcernChange}
                                            value={this.state.concern_type}
                                            placeholder="Please select"
                                        />
                                        <label>
                                            Message
                                            </label>

                                        <div className="message-textarea">
                                            <textarea className="message-textarea__input" value={this.state.value} onChange={this.handleChange} />
                                        </div>
                                        <button className="mt-3 font-neo-semibold btn btn-dropdowncolor btn--w110px" type="button" value="submit" onClick={(e) => this.handleSubmit(e)}>
                                            Submit
                                        </button>
                                        &nbsp;&nbsp;&nbsp;
                                        <button className="mt-3 font-neo-semibold btn btn-danger btn--w110px" type="button" value="cancel" onClick={(e) => this.cancelButton(this)}>
                                            Cancel
                                        </button>


                                    </div>
                                    {this.state.showPrompt ? this.userPrompt("d-block animation-fadein") : this.userPrompt("d-none")}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
}
export default withRouter(Contact);
