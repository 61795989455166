import React, { Component } from 'react'
import Lottie from 'lottie-react-web';
import Medal from '../../../assets/animation/blue-medal.json';
import { Redirect } from 'react-router-dom';

class SplashScreen extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            time: 0
        }

        this._isMounted = false;
    }
    

    componentWillMount() {
        this._isMounted = true;
        let that = this;
        let sec = 1;
        setInterval(() => {
            if(that._isMounted) {
                that.setState({
                    time: sec++
                })
            }
        }, 1000);
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        const { time } = this.state;
        return time <= 2 ?(
            <div className="rni-splash-screen-container">
                <div className="rni-splash-screen-medal">
                    {this._isMounted? <Lottie speed={0.75} options={{ animationData: Medal, loop: false }} />: null}
                </div>
            </div>
        ): <Redirect to="/rewards-and-incentives/home" />
    }
}

export default SplashScreen