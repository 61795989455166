import React, { Component } from 'react'
import { NavLink } from 'react-router-dom';
import { getParams } from '../commons/service';

class Menu extends Component {

    constructor(props) {
        super(props)

        this.state = {
            menuList: [],
            fallbackImg: '/rni/fallback-menu.svg',
            callback: ''
        }

        this.imgOnError = this.imgOnError.bind(this);
        this.clickedMenu = this.clickedMenu.bind(this);
    }

    componentDidMount() {
        const { menuList, callback } = this.props;
        this.setState({
            menuList: menuList,
            callback: callback
        })
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            ...nextProps
        })
    }

    imgOnError(e) {
        const { fallbackImg } = this.state;
        e.target.onerror = null;
        e.target.src = fallbackImg;
    }

    clickedMenu(selectedMenu) {
        const { callback } = this.state;
        if (callback) {
            callback(selectedMenu)
        }
    }

    render() {
        const { menuList, fallbackImg } = this.state;
        let that = this;
        let params = getParams();
        return Array.isArray(menuList) ? (
            <div id="rni-menu-container" className="rni-menu">
                <div className="rni-menu-container">
                    {menuList.map(function (menuObj, menuIndex) {
                        let sameId = false;
                        if(that.props.supportType !== null && that.props.supportType !== undefined) {
                            sameId= menuObj.name === that.props.supportType;
                        }
                        if (params.category !== null && params.category !== undefined) {
                            sameId = (menuObj.categoryId ? menuObj.categoryId.toString() : '') === params.category;
                        }
                        if (params.contestDetails !== null && params.contestDetails !== undefined) {
                            sameId = (menuObj.contestId ? menuObj.contestId.toString() : '') === params.contestDetails;
                        }
                        let hasHierarchy = that.props.hierarchy ? true : false;
                        if (hasHierarchy === true) {
                            sameId = that.props.hierarchy === menuObj.name;
                        }

                        return menuObj.link ?
                            <NavLink key={`rni-menu-` + menuIndex} to={menuObj.link} style={{ textDecoration: 'none' }}>
                                <div className="rni-menu-item" onClick={() => { that.clickedMenu(menuObj) }}>
                                    <div className={"rni-menu-item-img " + (sameId === true ? "rni-menu-item-active" : "")}>
                                        <img src={menuObj.imgUrl ? menuObj.imgUrl : fallbackImg} onError={that.imgOnError}></img>
                                    </div>
                                    <p className="rni-menu-item-name">{menuObj.name}</p>
                                </div>
                            </NavLink> :
                            <div key={`rni-menu-` + menuIndex} style={{ textDecoration: 'none' }} className="rni-disabled-menu-item">
                                <div className="rni-menu-item" onClick={() => { that.clickedMenu(menuObj) }}>
                                    <div className={"rni-menu-item-img " + (sameId === true ? "rni-menu-item-active" : "")}>
                                        <img src={menuObj.imgUrl ? menuObj.imgUrl : fallbackImg} onError={that.imgOnError}></img>
                                    </div>
                                    <p className="rni-menu-item-name">{menuObj.name}</p>
                                </div>
                            </div>
                    })}
                </div>
                {!this.props.noBorder ? <hr className="rni-border-line-menu" />:""}
            </div>
        ) :
            null
    }
}

export default Menu