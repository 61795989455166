import React, { Component } from 'react'
import SectionHeader from '../components/sectionHeader';
import MiniLoading from '../components/miniLoading';
import { getViewRewards } from '../commons/restEndpoint';
import Modal from 'react-responsive-modal';

export class RewardsCatalog extends Component {

    constructor(props) {
        super(props)
        console.log(this.props)
        this.state = {
            open:false,
            doneGettingRewards: false,
            rewardsList: []
        }
        this.closeModal= this.closeModal.bind(this);
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    async fetchRewards(){
        let params = {
            page: 0,
            size: 50
        }
        let x = await getViewRewards(params, this.props.session.access_token);
        console.log(x)
        if(x && x.data && x.data.content){
            this.setState({
                rewardsList: x.data.content,
                doneGettingRewards: true
            })
        }
    }

    componentWillMount(){
        this.fetchRewards();
    }

    openModal(data){
        this.setState({
            imgUrl: data.imgUrl,
            open: true
        })
    }

    closeModal(){
        this.setState({
            open: false
        })
    }

    render() {
        const { doneGettingRewards, open } = this.state;
        let that = this;
        return (
            <div className="container rni-rewards-section">
                <SectionHeader label="Popular Rewards" src="/rni/rewards-icon.png" />
                {doneGettingRewards ?
                  <div className="rni-reward-section-list row">
                        {this.state.rewardsList.map( function(content, index) {
                            return(
                                <div className="col-md-3 col-xs-12 col-sm-12 col-lg-3"
                                style={{ paddingBottom: '15px' }} key={index}>
                                    <div className="rni-rewards-card">
                                        <div className="rni-rewards-card-image">
                                            <img src={content.imgUrl} onClick={() => {that.openModal(content)}} className="contact-card"></img>
                                        </div>
                                        <div className="rni-rewards-card-content">
                                            <b>{content.name}</b>
                                        </div>
                                    </div>
                               </div>
                            );
                        })}
                        <Modal open={open}  onClose={that.closeModal} center>
                            <img className="img-modal" src={this.state.imgUrl}></img>
                        </Modal>
                        
                  </div>
                : <div style={{ width: '50vw', margin: 'auto' }}><MiniLoading /></div>}
            </div>
        )
    }
}

export default RewardsCatalog
