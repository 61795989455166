export const config = {
    SERVER_URL: "https://assist-salesportal-api.allianzpnblife.ph/xzuul/sp",
    ACTIVATION_URL: "https://assist-salesportal-api.allianzpnblife.ph/activation-service",
    KEYCLOAK_URL: "https://assist-salesportal-api.allianzpnblife.ph/keycloak-multirealm",
    LOGIN_URL: "https://assist-usermanagement-api.allianzpnblife.ph",
    RESET_PASSWORD_URL: "https://assist-portal.allianzpnblife.ph/reset-password",
    ALLIANZ_TOUCH_URL: "https://touch.allianzpnblife.ph",
    KEYCLOAK_APPID: "AZ-ASSIST",
    SITE_URL: "https://assist.allianzpnblife.ph",
    NDBX_URL: "https://assist-portal.allianzpnblife.ph/",
    GA_TRACKING_ID: 'G-853PVDKSX5',
}