import React, { Component } from 'react'

export default class sectionHeader extends Component {
    render() {
        const { label, src } = this.props;
        return (
            <div className="rni-section-header">
                <img src={src} alt=''/>
                <label>{label}</label>
            </div>
        )
    }
}
