import React, { Fragment } from "react";
import TableLoader from '../../TableLoader/tableLoader';

const PoliciesMobile = (props) => {
    const { columns, data, title, isTableLoading, children } = props;
    const filteredAccessorData = columns.filter((item) => item.id);
    let customAccessorData = {};
    filteredAccessorData.forEach(item => {
        customAccessorData = { ...customAccessorData, [item["id"]]: item["accessor"] }

    });
    console.log("check customaccessorData", customAccessorData);

    const notIncluded = [
        'Policy Number',
        'Policy Owner Name',
        'Actions'
    ];


    let getEntry = function (col, item) {
        let columnData = '';
        if (col && item) {
            if (col.accessor) {
                columnData = item[col.accessor] || '--';
            }
        }
        return columnData;
    }

    let fas = data.map((item, index) => {
        let expandedView = [];
        columns.forEach(function (col, idx) {
            if (col && !notIncluded.includes(col['Header']) && !col["id"]) {
                expandedView = [...expandedView,
                <Fragment key={idx}>
                    <div className="data-accord__column col-5">
                        <div className="data-accord__label">{col["Header"] || '--'}</div>
                        <div className="data-accord__value">{getEntry(col, item)}</div>
                    </div>
                    <div className="data-accord__column col-7">
                    </div>
                </Fragment>
                ]
            } else if (col["id"] === "authTag") {
                expandedView = [...expandedView,
                <Fragment key={idx}>
                    <div className="data-accord__column col-3">
                        <div className="data-accord__label">{col["Header"] || '--'}</div>
                        <div className="data-accord__value">{customAccessorData["authTag"](item)}</div>
                    </div>
                    <div className="data-accord__column col-9">
                    </div>
                </Fragment>
                ]
            }
        })

        return (
            <Fragment key={item.policyNum}>
                <div className={`data-accord__card ${index > 0 ? "mt-3" : ""} card border-0`}>
                    <div className="data-accord__toggle btn" data-toggle="collapse" data-target={`#collapse-${index}`}>
                        <div className="row data-accord__start">
                            <div className="col-3">
                                <button className="data-accord__name data-accord__name--clear">{item.policyNum}</button>
                            </div>
                            <div className="col-5">
                                <button className="data-accord__name data-accord__name--clear">{item.ownerName}</button>
                            </div>
                            <div className="col-4 d-flex justify-content-between">
                                <div>{customAccessorData["actions"](item)}</div>
                                <button className="btn p-0" type="button">
                                    <div className="data-accord__caret ml-2">
                                        <div className="data-accord__caret-icon"></div>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div id={`collapse-${index}`} className="collapse" data-parent="#data-accord">
                        <div className="card-body data-accord__content">
                            <div className="row">
                                {expandedView}
                            </div>
                        </div>
                    </div>
                </div >
            </Fragment>
        );
    });

    return (
        <>
            <div className="data-accord row flex-wrap mt-4" >
                {(
                    <div className="data-accord__header">
                        <div className="col-3">
                            <div className="d-inline-flex align-items-center cs-pointer">
                                {title[0]["Header"]}
                            </div>
                        </div>
                        <div className="col-5">
                            <div className="d-inline-flex align-items-center cs-pointer">
                                {title[1]["Header"]}
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="d-inline-flex align-items-center cs-pointer">
                                {title[2]["Header"]}
                            </div>
                        </div>
                    </div>
                )}
                <div className="col-12 data-accord__body mt-1">
                    {isTableLoading ? <div className="mt-5"><TableLoader loading={true} /></div> : ""}
                    <div className="data-accord__collapse" id="data-accord">
                        {data.length === 0 && !isTableLoading ? (<div className="center-align dashboard-row-data font-neo-semibold mt-4">No records found </div>) : ""}
                        {!isTableLoading ? fas : ""}
                    </div>
                </div>
            </div>
            {children}
        </>
    );
}

export default PoliciesMobile;
