import React, { Component } from 'react';
import withWindowDimensions from '../commons/withWindowDimensions';
import { DownloadableFiles } from '../components/AgentKnowledgebase/downloadableFiles';
import { PdfPopupModal } from '../components/AgentKnowledgebase/pdfPopupModal';
import { AdobePdfModal } from '../components/AgentKnowledgebase/adobePdfModal';
import { checkPermissions, getSessionInfo } from '../commons/security.js';
import { decrypt } from '../commons/utility.js';
import Select from 'react-select';
import { isInternalUser } from '../commons/security';
import { apiModule } from '../commons/apiCall';

class AgentKnowledgebase extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activePdfLink: '',
            activePdfName: '',
            activePdfRotation: 0,
            isModalShown: false,
            activePageNum: 1,
            totalPageNum: 1,

            roleList: [
                {
                    label: "PNB",
                    value: "pnb",
                },
                {
                    label: "Agency",
                    value: "agency",
                }
            ],

            role: {
                label: "PNB",
                value: "pnb",
             },
            roleValue: "",
            roles: [],
            channel: "",
            folderList: []
        }

        this.setActivePdf = this.setActivePdf.bind(this);
        this.setPdfModalClose = this.setPdfModalClose.bind(this);
        this.setPdfTotalPages = this.setPdfTotalPages.bind(this);
        this.setPdfRotation = this.setPdfRotation.bind(this);
        this.setFolderList = this.setFolderList.bind(this);
    }

    async componentDidMount() {
        await this.getRoles();
        this.setRole();
        console.log(this.props.getSession());
    }

    setRole() {
        let roleValue = "";
        const channel = this.props.getSession().channel;
        if(this.state.roles.includes("PNB_CHANNEL_HEAD") || isInternalUser(channel)) {
            roleValue = "pnb";
        } else {
            roleValue = channel;
        }
        this.setState({
            roleValue,
            channel,
        })
    }

    getRoles = async () => {
        const sessionInfo = await getSessionInfo();
        const headerParams = { x_auth: this.props.getSession().access_token, webUser: decrypt(sessionInfo[0].username) };
        const rolesData = await apiModule("get_webuser_roles", headerParams);
        let roles = [];
        if (rolesData && rolesData.data.length > 0) {
            rolesData.data.forEach(item => {
                roles = [...roles, item.name];
            })
        }
        this.setState({
            roles
        })

        console.log("GET ROLES", roles);
    }

    setPdfModalClose = () => {
        this.setState({
            activePdfLink: null,
            activePdfName: null,
            activePdfRotation: 0,
            isModalShown: false,
            activePageNum: 1,
            totalPageNum: 1
        });
    }

    setActivePdf = (file) => {
        if (!this.state.isModalShown) {
            this.setState({
                activePdfLink: file.alternateFileView ? file.alternateFileView : file.fileUrl,
                activePdfName: file.fileName,
                isModalShown: true,
                file
             });
        } else {
            this.setPdfModalClose();
        }
    }

    setPdfTotalPages = (totalPageNum) => {
        this.setState({ totalPageNum });
    }

    setPdfNavigation = (number) => {
        const activePageNum = this.state.activePageNum + number;
        this.setState({ activePageNum });
    }

    setPdfRotation = () => {
        const currentRotation = this.state.activePdfRotation;
        if (currentRotation === 360) {
            this.setState({ activePdfRotation: 0 });
        } else {
            this.setState({ activePdfRotation: currentRotation + 90 });
        }
    }

    setFolderList = (folderList) => {
        this.setState({
            folderList
        })
    }

    handleRoleChange = (role) => {
        this.setState({
            role,
            roleValue: role.value,
        });
    }

    render() {
        return (
            <div className="agent-knowledgebase dashboard-section d-flex flex-column">
                <div className="agent-knowledgebase__container dashboard-container container-fluid p-0 d-flex flex-column h-100">
                    <header className="agent-knowledgebase__header d-flex p-3">
                        <h4 className="agent-knowledgebase__header-text font-neo-bold text-darkgray">
                            Agent Knowledgebase
                            {
                                this.state.folderList.length > 0 && 
                                this.state.folderList.map(folder => {
                                    return ' > ' + folder
                                })
                            }
                        </h4>
                        
                    </header>
                    { (isInternalUser(this.state.channel) && !this.state.roles.includes('PNB_CHANNEL_HEAD')) &&
                    <div className="agent-knowledgebase__select">
                        <Select
                            styles={{
                                option: (provided) => ({
                                    ...provided,
                                    fontFamily: "neo_semibold",
                                    fontSize: ".6rem",
                                    color: "#9A9B9C",
                                    backgroundColor: "white"
                                }),
                                menu: () => ({
                                    position: "absolute",
                                    border: 0,
                                    zIndex: "1090",
                                    backgroundColor: "white",
                                    borderRadius: "5px",
                                    boxShadow: "2px 2px 2px #DDDEE0"
                                }),
                            }}
                            placeholder="Channel"
                            //options
                            options={this.state.roleList}
                            isSearchable={false}
                            className={`datatable-select ml-0 ${this.props.isSm ? "w-100" : ""}`}
                            classNamePrefix="datatable-select"
                            value={this.state.role}
                            onChange={this.handleRoleChange}
                        />
                    </div>
                    }
                    { this.state.roleValue &&
                        <main className="agent-knowledgebase__content flex-grow-1 position-relative">
                            <DownloadableFiles
                                setActivePdf={this.setActivePdf}
                                role={this.state.roleValue}
                                roles={this.state.roles}
                                setFolderList={this.setFolderList}
                            />
                        </main>
                    }
                {
                    this.state.isModalShown &&
                    // <PdfPopupModal
                    //     headerText={this.state.activePdfName}
                    //     activePdfLink={this.state.activePdfLink}
                    //     activePdfRotation={this.state.activePdfRotation}
                    //     activePageNum={this.state.activePageNum}
                    //     totalPageNum={this.state.totalPageNum}

                    //     onCloseClick={this.setPdfModalClose}
                    //     setPdfTotalPages={this.setPdfTotalPages}
                    //     setPdfNavigation={this.setPdfNavigation}
                    //     setPdfRotation={this.setPdfRotation}
                    //     file={this.state.file}
                    //     isSm={this.props.isSm}
                    // />
                    <AdobePdfModal
                        headerText={this.state.activePdfName}
                        activePdfLink={this.state.activePdfLink}
                        onCloseClick={this.setPdfModalClose}
                        isSm={this.props.isSm}
                    />
                }
                </div>
            </div>
        )
    }
}


export default withWindowDimensions(AgentKnowledgebase);
