import React, { Component } from 'react';
import { Pie } from 'react-chartjs-2';
import { prettifyDate, calculateAge, numberWithCommas, writeData, readAllData } from '../commons/utility.js';
import { apiModule } from '../commons/apiCall';
import Select from 'react-select';
import withWindowDimensions from '../commons/withWindowDimensions';
import { DashboardLoader } from '../commons/utilityViews.js';

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        color: 'white',
        fontSize: ".6rem",
        fontFamily: "neo_semibold",
        backgroundColor: "#255F8E",
        textAlign: "left",
        cursor: "pointer",
    }),
    singleValue: (provided, state) => ({
        color: "#255F8E",
        fontFamily: 'neo_semibold',
        fontSize: ".9rem"
    }),
    dropdownIndicator: () => ({
        color: "#1E4A73",
    }),
    placeholder: () => ({
        fontSize: ".9rem"
    }),
    menu: () => ({
        backgroundColor: "#255F8E",
        borderRadius: "5px",
        // left: 0,
        // width: "10rem",
        whiteSpace: "nowrap",
        zIndex: "1090",
        maxWidth: "15em",
    }),
    noOptionsMessage: () => ({
        color: "white",
        fontSize: ".6rem",
        fontFamily: "neo_semibold",
        padding: "0 1rem"
    })
}

class StatusPortfolio extends Component {
    constructor(props) {
        super(props);
        this.state = {
            teammates: [],
        }
    }



    pieChart = (arg, list) => {
        if (arg == "data") {
            arg = {
                labels: [],
                datasets: [{
                    data: [],
                    backgroundColor: [
                        '#89679B',
                        '#89D8F9',
                        '#C8D85C',
                        '#FF934F',
                        '#7FE4E0',
                    ],
                }],
            };
            let normalized = [];

            if(list !== undefined && list !== null) {
                for (let i = 0; i < list.length; i++) {
                    if (normalized[list[i].status] == null || normalized[list[i].status] == undefined || normalized[list[i].status] == 0) {
                        normalized[list[i].status] = parseInt(list[i].numberOfPolicies);
                        arg.labels.push(list[i].status);
                    } else {
                        normalized[list[i].status] += parseInt(list[i].numberOfPolicies);
                    }
    
                }

                for (let i = 0; i < arg.labels.length; i++) {
                    arg.datasets[0].data.push(normalized[arg.labels[i]]);
                }
    
            } else {

            }

        } else if (arg == "options") {
            let legendPosition = 'left';
            let maintainAr = true;
            let messageExtension = this.getRangePostfix();

            if (this.props.isSm) {
                legendPosition = 'bottom';
                maintainAr = false;
            }
            arg = {
                legend: {
                    onClick: function (e) {
                        e.stopPropagation();
                    },
                    position: legendPosition,
                    labels: {
                        fontSize: 10,
                        fontFamily: "neo_semibold"
                        // padding: 20
                    }
                },
                tooltips: {
                    callbacks: {
                        label: function(items, data) {
                            let that = this;
                            return ' ' + data.datasets[0].data[items.index] + ' ' + data.labels[items.index] + messageExtension;
                        },
                    }
                },
                maintainAspectRatio: maintainAr,
            }
        } else {
            arg = null;
        }
        return arg;
    }

    getRangePostfix = () => {
        let range = this.props.selectedRange;
        let postfix = ': ';
        let today = new Date();
        let month = today.getMonth();
        let year = today.getFullYear();
        let months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

        if(range.value === 'MNTH') {
            let monthString = months[month];
            postfix += monthString + ' ' + today.toLocaleDateString();
        } else if(range.value === 'QTR') {
            postfix = year;

            if(month >= 0 && month <= 2) {
                postfix += ' Quarter 1 (Jan-Mar)';
            } else if(month >= 3 && month <= 5) {
                postfix += ' Quarter 2 (Apr-Jun)';
            } else if(month >= 6 && month <= 8) {
                postfix += ' Quarter 3 (Jul-Sep)';
            } else if(month >= 9 && month <= 11) {
                postfix += ' Quarter 4 (Oct-Dec)';
            }
        } else if(range.value === 'YEAR') {
            postfix += 'Year ' + year;
        } else if(range.value === 'ALL') {
            postfix += 'All Time';
        }
        
        if(range.value === null || range.value === undefined) {
            postfix = '';
        } 

        return postfix;
    }

    statusPortfolio = (props) => {
        let dropdownTeam = "";
        let dropdownRange = "";
        let rangeOptions = this.props.rangeOptions;

        console.log('PORTFOLIO STATUS LIST');
        console.log(props.list);

        if (props.isMgr) {
            dropdownTeam = (
                <Select
                    styles={customStyles}
                    className="fund-select fund-select--text-ellipsis fund-select--justify-end"
                    classNamePrefix="fund-select"
                    onChange={(e) => props.updateSelectedOption(e)}
                    value={props.selectedOption}
                    placeholder="My Team"
                    options={props.dropdownItems}
                    isSearchable={false}
                />
            );
        }

        dropdownRange = (
            <Select
                styles={customStyles}
                className="fund-select fund-select--text-ellipsis fund-select--justify-end"
                classNamePrefix="fund-select"
                onChange={(e) => props.updateSelectedRange(e)}
                value={props.selectedRange}
                placeholder="Range"
                options={rangeOptions}
                isSearchable={false}
            />
        );

        if (props.list === null )  {
            return (

                <div className={`${props.css} mb-4`}>
                    <div className="dashboard-data-content bg-white p-3 shadow-sm rounded">
                        <DashboardLoader />
                    </div>
                </div>

            );
        } else  if ( props.list === undefined )  {
            return (
                <div className={`${props.css} mb-4`}>
                <div className="dashboard-data-content bg-white shadow-sm rounded">
                    <div className="dashboard-upper-body">
                        <div className="dashboard-data-header px-3 pt-3 d-flex align-items-center justify-content-between">
                            <h6 className="dashboard-data-header__text dashboard-data-header__text--mb-p25rem  d-flex align-items-end font-neo-semibold text-headercolor">Portfolio by Status</h6>
                        </div>
                        <div className="">
                            {/* {this.spielMessage()} */}
                        </div>
                        <div className="dashboard-data-body dashboard-data-content-empty py-3 px-4 center-align">
                            { this.props.isPortfolioLoading? 
                                <div className="px-4 py-3">
                                    <DashboardLoader/>
                                </div>
                                : 
                                <div style={{ minHeight: "3em" }}>
                                    <span className="dashboard-row-data font-neo-semibold center-align">No Portfolio Record Available</span> 
                                </div>
                            }
                        </div>
                        { !this.props.isPortfolioLoading? (
                                <div className="mb-2">
                                    <div className="mr-4">
                                        {dropdownTeam}
                                    </div>
                                    <div className="mr-4">
                                        {dropdownRange}
                                    </div>
                                </div>
                            ) : ('') }
                    </div>
                </div>
                </div>
                
            );
        }else {
            return (
                <div className={`${props.css} mb-4`}>
                    <div className="dashboard-data-content bg-white shadow-sm rounded">
                        <div className="dashboard-upper-body">
                            <div className="dashboard-data-header px-3 pt-3 d-flex align-items-center justify-content-between">
                                <h6 className="dashboard-data-header__text dashboard-data-header__text--mb-p25rem  d-flex align-items-end font-neo-semibold text-headercolor">Portfolio by Status</h6>
                            </div>
                            <div>
                                {/* {this.spielMessage()} */}
                            </div>
                            <div className="dashboard-data-body py-3 px-4">
                                <div className="dashboard-data__pie-chart">
                                    { this.props.isPortfolioLoading? 
                                        <DashboardLoader/>
                                        : 
                                        <div style={{ minHeight: "3em" }}>
                                            <Pie
                                                data={this.pieChart("data", props.list)}
                                                options={this.pieChart("options")} 
                                            />
                                        </div>
                                     }
                                </div>
                            </div>
                            { !this.props.isPortfolioLoading? (
                                <div className="mb-2">
                                    <div className="mr-4">
                                        {dropdownTeam}
                                    </div>
                                    <div className="mr-4">
                                        {dropdownRange}
                                    </div>
                                </div>
                            ) : ('') }
                        </div>
                    </div>
                </div>
            );
        }

    }

    render() {
        return this.statusPortfolio(this.props);
    }

}
export default withWindowDimensions(StatusPortfolio);
