import React, { Component } from 'react';
import { formatValuesNoDecimals } from './formatter';
import { charts } from '../../commons/misConfig';

class CommentIcon extends Component{
    constructor(props){
        super(props);
    }

    render(){
        let { onClickCommentIcon, totalComments, chart } = this.props;
        return(
            <div className="mis-comment-icon-container" onClick={() => onClickCommentIcon(chart)}>
                <div className="mis-comment-icon">
                    <img src = "/mis/comments.svg" className="mis-comment-icon-img"/>
                    {isFinite(totalComments) && totalComments > 0 ? 
                        <span className="mis-comment-count">
                            {formatValuesNoDecimals(totalComments)}
                        </span>
                        : ""
                    }
                </div>
            </div>
        )
    }
}

export default CommentIcon;