export const menuConfig = [{
    pageId: 10,
    pageLabel: 'Dashboard',
    isActive: 'active',
    icon: 'icon-cards',
    activeIcon: 'icon-cards-hover',
    mobileActiveIcon: "",
    // pageModule: 'dashboard',
    pageModule: 'dashboard',
    permission: [],
    // permission: ["general_read", "general_create", "general_update", "general_delete", "general_report", "admin_read", "admin_create", "admin_update", "admin_delete", "admin_report"]
},
{
    pageId: 20,
    pageLabel: 'Application Status',
    isActive: '',
    icon: 'icon-material-assignment',
    activeIcon: 'icon-material-assignment-active',
    mobileActiveIcon: 'icon-material-assignment',
    pageModule: 'applicationList',
    permission: ["VIEW_CL_LIST"]
},
{
    pageId: 30,
    pageLabel: 'Client Information',
    isActive: '',
    icon: 'icon-user-x',
    activeIcon: 'icon-user-hover',
    mobileActiveIcon: 'icon-user-x',
    pageModule: 'clientInfo',
    permission: ["VIEW_CL_LIST"]
},
{
    pageId: 40,
    pageLabel: 'List of Policies',
    isActive: '',
    icon: 'icon-list',
    activeIcon: '',
    mobileActiveIcon: "",
    pageModule: 'policyList',
    permission: ["VIEW_POL_LIST"]
},
{
    pageId: 50,
    pageLabel: 'List of FA',
    isActive: '',
    icon: 'icon-user-o',
    activeIcon: '',
    mobileActiveIcon: "",
    pageModule: 'faList',
    permission: ["VIEW_FA_LIST"]
},
{
    pageId: 60,
    pageLabel: 'Reports',
    isActive: '',
    icon: 'icon-file-text',
    activeIcon: '',
    mobileActiveIcon: "",
    pageModule: 'reports',
    permission: ["VIEW_REP", "VIEW_REP_CL_BD", "VIEW_REP_POL_DUE", "VIEW_REP_CL_LOC", "VIEW_REP_POL_SERV", "VIEW_LEAD_REPORT_ALLIANZ_ENGAGE", "VIEW_PRODUCTION_REPORT", "VIEW_PRODUCTION_REPORT_MASTER"]
},
{
    pageId: 70,
    pageLabel: 'Policy Services',
    isActive: '',
    icon: 'icon-user-hover',
    activeIcon: '',
    mobileActiveIcon: "",
    pageModule: 'agentUpdatingClient',
    permission: ["VIEW_AGENT_UPDATING_OF_CLIENT"]
},
{
    pageId: 80,
    pageLabel: 'Agent Transaction History',
    isActive: '',
    icon: 'icon-file-text',
    activeIcon: '',
    mobileActiveIcon: "",
    pageModule: 'agentTransactionHistory',
    permission: ["VIEW_AGENT_TRANSACTION_HISTORY"]
},
{
    pageId: 90,
    pageLabel: "Other Links",
    isActive: '',
    // icon: 'icon-setting-o',
    icon: 'icon-link',
    activeIcon: '',
    mobileActiveIcon: "",
    pageModule: 'otherLinks',
    permission: ["VIEW_LINKS", "VIEW_AGENT_KNOWLEDGEBASE"]
},
{
    pageId: 100,
    pageLabel: "Contact Us",
    isActive: '',
    icon: 'icon-phone-chat-o',
    activeIcon: 'icon-phone-chat-hover',
    mobileActiveIcon: "",
    pageModule: 'contact',
    permission: ["VIEW_CONTACT", "VIEW_CONTACT_DETAILS"]
},
{
    pageId: 110,
    pageLabel: "List of Users",
    isActive: '',
    icon: 'icon-user-business-o',
    activeIcon: '',
    mobileActiveIcon: "",
    pageModule: 'userList',
    permission: ['VIEW_CS']
},
{
    pageId: 120,
    pageLabel: "Single Client",
    isActive: '',
    css: 'd-none',
    icon: '',
    activeIcon: '',
    mobileActiveIcon: "",
    pageModule: 'singleClient',
    permission: ["VIEW_CL"]
},
{
    pageId: 130,
    pageLabel: "Single Client Policy",
    isActive: '',
    css: 'd-none',
    icon: '',
    activeIcon: '',
    mobileActiveIcon: "",
    pageModule: 'singleClientPolicy',
    permission: ["VIEW_CL"]
},
{
    pageId: 140,
    pageLabel: "Single Policy",
    isActive: '',
    css: 'd-none',
    icon: '',
    activeIcon: '',
    mobileActiveIcon: "",
    pageModule: 'singlePolicy',
    permission: ["VIEW_POL_DET",
        "VIEW_POL_VAL",
        "VIEW_POL_COV",
        "VIEW_POL_BENE",
        "VIEW_POL_FUND_DET",
        "VIEW_POL_FUND_TRANS",
        "VIEW_POL_LOANS",
        "VIEW_POL_ENDOW"
    ]
},
{
    pageId: 150,
    pageLabel: "User Info",
    isActive: '',
    css: 'd-none',
    icon: '',
    activeIcon: '',
    mobileActiveIcon: "",
    pageModule: 'userInfo',
    permission: ["VIEW_UP"]
},
{
    pageId: 160,
    pageLabel: "Change Password",
    isActive: '',
    css: 'd-none',
    icon: '',
    activeIcon: '',
    pageModule: 'changePass',
    permission: []
},
{
    pageId: 170,
    pageLabel: "Alerts and Updates",
    isActive: '',
    css: 'd-none',
    icon: '',
    activeIcon: '',
    mobileActiveIcon: "",
    pageModule: 'alerts',
    permission: ["VIEW_ALERTS"]
},
{
    pageId: 180,
    pageLabel: "Search Results",
    isActive: '',
    css: 'd-none',
    icon: '',
    activeIcon: '',
    mobileActiveIcon: "",
    pageModule: 'searchResults',
    permission: ["SEARCH_POL", "SEARCH_CL"]
},
{
    pageId: 190,
    pageLabel: "Birthday List",
    isActive: '',
    css: 'd-none',
    icon: '',
    activeIcon: '',
    mobileActiveIcon: "",
    pageModule: 'birthdayReport',
    permission: ["VIEW_REP_CL_BD"]
},
{
    pageId: 200,
    pageLabel: "Policies Due",
    isActive: '',
    css: 'd-none',
    icon: '',
    activeIcon: '',
    mobileActiveIcon: "",
    pageModule: 'policyDueReport',
    permission: ["VIEW_REP_POL_DUE"]
},
{
    pageId: 210,
    pageLabel: "Client List per Location",
    isActive: '',
    css: 'd-none',
    icon: '',
    activeIcon: '',
    mobileActiveIcon: "",
    pageModule: 'clientLocationReport',
    permission: ["VIEW_REP_CL_LOC"]
},
{
    pageId: 220,
    pageLabel: "Policy List per Status",
    isActive: '',
    css: 'd-none',
    icon: '',
    activeIcon: '',
    mobileActiveIcon: "",
    pageModule: 'policyStatusReport',
    permission: ["VIEW_REP_POL_DUE"]
},
{
    pageId: 230,
    pageLabel: "Matured and Paid up Policies",
    isActive: '',
    css: 'd-none',
    icon: '',
    activeIcon: '',
    mobileActiveIcon: "",
    pageModule: 'policyMaturedReport',
    permission: ["VIEW_REP_POL_DUE"]
},
{
    pageId: 240,
    pageLabel: "Single Fa",
    isActive: '',
    css: 'd-none',
    icon: '',
    activeIcon: '',
    mobileActiveIcon: "",
    pageModule: "singleFa",
    permission: ["admin_read", "admin_create", "admin_update", "admin_delete"]
},
{
    pageId: 250,
    pageLabel: "Premium Due List View",
    isActive: '',
    css: 'd-none',
    icon: '',
    activeIcon: '',
    mobileActiveIcon: "",
    pageModule: "premiumDueListView",
    permission: ["admin_read", "admin_create", "admin_update", "admin_delete"]
},
{
    pageId: 260,
    pageLabel: "Birthday List View",
    isActive: '',
    css: 'd-none',
    icon: '',
    activeIcon: '',
    mobileActiveIcon: "",
    pageModule: "birthdayListView",
    permission: ["admin_read", "admin_create", "admin_update", "admin_delete"]
},
{
    pageId: 270,
    pageLabel: "Policy List per Servicing FA with status",
    isActive: '',
    css: 'd-none',
    icon: '',
    activeIcon: '',
    mobileActiveIcon: "",
    pageModule: 'policyListPerFaReport',
    permission: []
},
{
    pageId: 280,
    pageLabel: "Client List per Servicing FA",
    isActive: '',
    css: 'd-none',
    icon: '',
    activeIcon: '',
    mobileActiveIcon: "",
    pageModule: 'clientListFAReport',
    permission: []
},
{
    pageId: 290,
    pageLabel: "List of FAs per status",
    isActive: '',
    css: 'd-none',
    icon: '',
    activeIcon: '',
    mobileActiveIcon: "",
    pageModule: 'faStatusReport',
    permission: []
},
{
    pageId: 300,
    pageLabel: "Hierarchy",
    isActive: '',
    css: 'd-none',
    icon: '',
    activeIcon: '',
    mobileActiveIcon: "",
    pageModule: 'hierarchy',
    permission: []
},
{
    pageId: 310,
    pageLabel: "MIS",
    isActive: '',
    icon: 'icon-bookmark',
    activeIcon: 'icon-bookmark-plain',
    mobileActiveIcon: "",
    pageModule: 'mis',
    permission: ["VIEW_MIS"]
},
{
    pageId: 320,
    pageLabel: "Lead Reports",
    isActive: '',
    css: 'd-none',
    icon: '',
    activeIcon: '',
    mobileActiveIcon: "",
    pageModule: 'leadReports',
    permission: ["VIEW_LEAD_REPORT_ALLIANZ_ENGAGE"]
},
{
    pageId: 330,
    pageLabel: "Generate Documents",
    isActive: '',
    css: 'd-none',
    icon: '',
    activeIcon: '',
    mobileActiveIcon: "",
    pageModule: 'documentsReport',
    permission: []
},
{
    pageId: 331,
    pageLabel: "Production Report",
    isActive: '',
    css: 'd-none',
    icon: '',
    activeIcon: '',
    mobileActiveIcon: "",
    pageModule: 'productionReport',
    permission: ["VIEW_PRODUCTION_REPORT", "VIEW_PRODUCTION_REPORT_MASTER"]
},
{
    pageId: 340,
    pageLabel: "List of Policies Without Payment Beyond Due Date",
    isActive: '',
    css: 'd-none',
    icon: '',
    activeIcon: '',
    mobileActiveIcon: "",
    pageModule: 'paymentsBeyondDueReport',
    permission: []
},
{
    pageId: 350,
    pageLabel: "Privacy Notice",
    isActive: '',
    css: '',
    icon: 'icon-material-privacy-tip',
    activeIcon: 'icon-material-privacy-tip',
    mobileActiveIcon: "",
    pageModule: 'privacyNotice',
    permission: [],
    channels: ['PNB', 'AGENCY', 'BANCA']
},
{
    pageId: 360,
    pageLabel: "Agent Knowledgebase",
    isActive: '',
    css: 'd-none',
    icon: '',
    activeIcon: '',
    mobileActiveIcon: "",
    pageModule: 'agentKnowledgebase',
    permission: ["VIEW_AGENT_KNOWLEDGEBASE"]
},
{
    pageId: 370,
    pageLabel: "Lapse List",
    isActive: '',
    css: 'd-none',
    icon: '',
    activeIcon: '',
    mobileActiveIcon: "",
    pageModule: 'lapseListReport',
    permission: ["VIEW_REP_POL_DUE"]
},
{
    pageId: 380,
    pageLabel: "Company Persistency",
    isActive: '',
    css: 'd-none',
    icon: '',
    activeIcon: '',
    mobileActiveIcon: "",
    pageModule: 'companyPersistency',
    permission: ["VIEW_COMPANY_PERSISTENCY"]
},
{
    pageId: 390,
    pageLabel: 'Newly Assigned Policies',
    isActive: '',
    css: 'd-none',
    icon: '',
    activeIcon: '',
    mobileActiveIcon: "",
    pageModule: 'newlyAssignedPolicyList',
    permission: ["VIEW_POL_LIST"]
},
];
