import React, { Component } from 'react';
import { ReportLoader } from '../commons/utilityViews.js';
import { withRouter } from 'react-router-dom';
// import 'react-vertical-timeline-component/style.min.css';
import { prettifyDate, calculateAge, writeData, readAllData, encrypt, decrypt, getAddressDetails, fixName } from '../commons/utility.js';

import routeChange from '../commons/routeChange';
import { apiModule, createQueryStrings } from '../commons/apiCall';
import Auxi from '../components/auxi';
import { myConfig } from '../commons/config';
import LoaderGif from '../commons/loaderGif';
import Select from "react-select";
import { InboxIcon } from 'mdi-react';
import TablePagination from '../components/tablePagination';
import { isManager } from '../commons/security.js';
import Insured from '../components/Applications/insured';


const customStyles = {
    option: (provided, state) => ({
        ...provided,
        color: "#9A9B9C",
        fontSize: ".6rem",
        fontFamily: "neo_semibold",
        backgroundColor: "white",
        ':active': {
            backgroundColor: "#BED9EE",
        },
        textAlign: "left",
        cursor: "pointer"
    }),
    singleValue: (provided, state) => ({
        fontFamily: 'neo_semibold',
        fontSize: ".91rem"
    }),
    // placeholder: () => ({
    //     fontSize: ".9rem"
    // }),
    menu: () => ({
        position: "absolute",
        // backgroundColor: "#255F8E",
        borderRadius: "5px",
        // left: 0,
        // width: "10rem",
        width: "100%",
        whiteSpace: "nowrap",
        boxShadow: "2px 2px 2px #DDDEE0",
        zIndex: "1090"
    }),
    control: (provided, state) => ({
        ...provided,
        borderWidth: 0,
        boxShadow: 0,
        minHeight: "initial",
    }),
    indicatorSeparator: (provided) => ({
        ...provided,
        width: 0,
    }),
    valueContainer: (provided) => ({
        ...provided,
        padding: 0,
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        padding: 0,
    })
}

class SingleClientPolicy extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            clientDashboardData: [],
            error: "",
            policies: [],
            basicInfo: [],
            contactInfo: [],
            address: [],
            loadTable: true,
            home_address: [],
            work_address: [],
            //address
            addressSelected: { label: "Home Address", value: "home" },

            pages: 10,
            currentPage: 0,
            totalSize: 0,
        };
        this.addressOptions = [
            { label: "Home Address", value: "home" },
            { label: "Work Address", value: "work" }
        ];

        this.handleAddressChange = this.handleAddressChange.bind(this);
    }

    handleAddressChange = (addressSelected) => {
        this.setState({ addressSelected });
    }
    async componentDidMount() {
        let access_token = this.props.getSession().access_token;
        let clientId = this.props.getClientOrPolicy().clientId;
        let that = this;
        if (clientId === null || clientId === '-1' || clientId === -1) {
            console.log("clientId == null");
            readAllData('policy-client')
                .then(function (data) {
                    for (var dt of data) {
                        clientId = dt.clientId;
                        that.setState({ access_token: access_token, clientId: clientId });
                        //hardcode muna
                        //  clientId = '2007.0629.34270296.1';
                        that.fetchThisClient(that.props.getSession().access_token, clientId);
                    }
                });
        } else {
            console.log("clientId == " + clientId);
            //clientId = '2007.0629.34270296.1';
            this.setState({ access_token: access_token, clientId: clientId });
            await this.fetchThisClient(this.props.getSession().access_token, clientId);

        }


    }

    // getAddressDetails = (itemAddress) => {
    //     let address = [];

    //     address.push({
    //         fieldName: "Company/ Building Name",
    //         value: itemAddress.buildingName,
    //     });

    //     address.push({
    //         fieldName: "Lot/ Block No.",
    //         value: itemAddress.floorNumber,
    //     });

    //     address.push({
    //         fieldName: "Street No./ Street",
    //         value: itemAddress.street,
    //     });

    //     address.push({
    //         fieldName: "Barangay/ Subdivision/ Village",
    //         value: itemAddress.district,
    //     });

    //     address.push({
    //         fieldName: "City/ Municipality",
    //         value: itemAddress.city,
    //     });

    //     address.push({
    //         fieldName: "Country",
    //         value: itemAddress.countryCode,
    //     });

    //     address.push({
    //         fieldName: "Province/ State",
    //         value: itemAddress.state,
    //     });

    //     address.push({
    //         fieldName: "Zip Code",
    //         value: itemAddress.zipCode,
    //     });

    //     return address;
    // }

    fetchThisClient = (access_token, clientId) => {
        let that = this;
        return new Promise(function (resolve, reject) {

            let headerParams = { x_auth: access_token, clientId: clientId };

            apiModule("get_client", headerParams, null)
                .then(function (result) {
                    let basicInfo = [];
                    let clientDashboardData = [];
                    let address = [];
                    let contactInfo = [];
                    let home_address = [];
                    let work_address = [];
                    //IF RESULT IS NULL THEN ERROR MESSAGE
                    if (result) {
                        let item = null;
                        if (Array.isArray(result)) {
                            item = result[result.length - 1];
                        }
                        else {
                            item = result;
                        }

                        /* CLIENT DASHBOARD INFORMATION */
                        clientDashboardData.push({
                            fieldName: "Last Name",
                            value: item.lastName,
                        });

                        clientDashboardData.push({
                            fieldName: "First Name",
                            value: item.firstName,
                        });

                        clientDashboardData.push({
                            fieldName: "Date of Birth",
                            value: prettifyDate(item.birthDate),
                        });

                        clientDashboardData.push({
                            fieldName: "Gender",
                            value: item.gender,
                        });

                        clientDashboardData.push({
                            fieldName: "Age",
                            value: calculateAge(item.birthDate)
                        });

                        contactInfo.push({
                            fieldName: "Country Code",
                            value: item.countryPrefix
                        });

                        contactInfo.push({
                            fieldName: "Area Code",
                            value: item.prefix
                        });

                        contactInfo.push({
                            fieldName: "Contact Number",
                            value: item.phoneNumber
                        });

                        contactInfo.push({
                            fieldName: "Email",
                            value: item.email
                        });


                        // clientDashboardData.push({
                        //     fieldName: "Anniversary Date",
                        //     value:  prettifyDate(item.inceptionDate)
                        // });

                        /* BASIC INFORMATION */
                        basicInfo.push({
                            fieldName: "Designation",
                            value: item.personalTitles,
                        });

                        basicInfo.push({
                            fieldName: "First Name",
                            value: item.firstName,
                        });

                        basicInfo.push({
                            fieldName: "Middle Name",
                            value: item.middleName,
                        });

                        basicInfo.push({
                            fieldName: "Last Name",
                            value: item.lastName,
                        });

                        basicInfo.push({
                            fieldName: "Date of Birth",
                            value: prettifyDate(item.birthDate),
                        });

                        basicInfo.push({
                            fieldName: "Gender",
                            value: item.gender,
                        });

                        basicInfo.push({
                            fieldName: "Age",
                            value: calculateAge(item.birthDate)
                        });

                        basicInfo.push({
                            fieldName: "Company Name",
                            value: item.companyName,
                        });

                        basicInfo.push({
                            fieldName: "Other Name",
                            value: " -- ",
                        });

                        basicInfo.push({
                            fieldName: "Civil Status",
                            value: item.maritalStatus,
                        });

                        basicInfo.push({
                            fieldName: "Nationality",
                            value: item.nationality,
                        });

                        // basicInfo.push({
                        //     fieldName: "TIN No.",
                        //     value: " -- ",
                        // });

                        // basicInfo.push({
                        //     fieldName: "US TIN",
                        //     value: " -- ",
                        // });

                        // basicInfo.push({
                        //     fieldName: "SSS/GSIS No.",
                        //     value: " -- ",
                        // });

                        basicInfo.push({
                            fieldName: "Anniversary Date",
                            value: item.inceptionDate
                        });

                        // basicInfo.push({
                        //     fieldName: "Email Address",
                        //     value: item.email,
                        // });

                        if (item.addresses !== null && item.addresses !== undefined) {
                            for (let i = 0; i < item.addresses.length; i++) {
                                let add = item.addresses[i];
                                if (add.type !== null && add.type !== undefined && add.type !== "") {
                                    if (add.type.toLowerCase() === "home") {
                                        home_address = getAddressDetails(add);
                                        address = getAddressDetails(add);
                                    } else {
                                        work_address = getAddressDetails(add);
                                    }
                                }
                            }
                        }



                    } else {
                        // that.errorMessage("No Client Found");
                    }



                    let filter = [];
                    filter['agentNumber'] = decrypt(that.props.getSession().agentNumber);
                    filter['pageSize'] = myConfig.PAGE_SIZE;
                    filter['accountManagerId'] = decrypt(that.props.getSession().agentNumber);
                    filter['pageNumber'] = that.state.currentPage + 1;

                    apiModule("get_client_policies", headerParams, null, createQueryStrings(filter))
                        .then(function (response) {
                            console.log(response);
                            let policies = [];
                            //IF RESULT IS NULL THEN ERROR MESSAGE
                            if (response.length > 0) {
                                let totalSize = response[0].totalSize;
                                let numOfPages = parseInt((totalSize / myConfig.PAGE_SIZE), 10);

                                if ((totalSize % myConfig.PAGE_SIZE) > 0) {
                                    numOfPages++;
                                }
                                that.setState({
                                    totalSize,
                                    pages: numOfPages,
                                });
                            } else {
                                that.setState({
                                    totalSize: 0,
                                    pages: 0,
                                });
                            }

                            if (response.length > 0) {
                                let result = response[0].content;
                                console.log(result);
                                for (let i = 0; i < result.length; i++) {
                                    let item = result[i];
                                    console.log(item);
                                    let expiryDate = ""
                                    if (item.contractInterval !== null && item.contractInterval !== undefined) {
                                        if (item.contractInterval.endDateTime !== null && item.contractInterval.endDateTime !== undefined) {
                                            expiryDate = prettifyDate(item.contractInterval.endDateTime);
                                        }
                                    }
                                    if (item.insured === null || item.insured === undefined) {

                                        policies.push({

                                            policyNumber: item.contractNumber,
                                            planName: item.productOfferingName,
                                            //status: item.status,
                                            insured: `---`,
                                            effectiveDate: prettifyDate(item.inceptionDate),
                                            currency: item.currency,
                                            expiryDate: expiryDate
                                        });
                                    } else {
                                        policies.push({

                                            policyNumber: item.contractNumber,
                                            planName: item.productOfferingName,
                                            //status: item.status,
                                            insured: item.insured,
                                            effectiveDate: prettifyDate(item.inceptionDate),
                                            currency: item.currency,
                                            expiryDate: expiryDate
                                        });
                                    }
                                }

                            } else {
                                //  that.errorMessage("No Client Found");
                            }

                            that.setState({
                                clientDashboardData,
                                loadTable: false,
                                // totalSize,
                                // pages: numOfPages,
                                policies,
                                basicInfo,
                                address,
                                home_address,
                                contactInfo,
                                work_address,
                                isLoading: false,
                            });
                            resolve("Ok");
                        })
                        .catch(function (err) {
                            console.log(err);
                            that.setState({
                                clientDashboardData,
                                loadTable: false,
                                basicInfo,
                                // totalSize: 0,
                                address,
                                home_address,
                                contactInfo,
                                work_address,
                                isLoading: false,

                            });
                            // that.errorMessage(err.message);
                            that.errorMessage("No Policy List for this Client");
                            resolve("Ok");

                        })
                })
                .catch(function (err) {
                    console.log(err);
                    //that.errorMessage(err.message);
                    // that.errorMessage("No Client Found");

                    that.setState({
                        isLoading: false,
                        error: myConfig.GENERIC_ERROR_MESSAGE,

                    });
                    resolve("Ok");
                })
            // let result = null;



        });
    }


    errorMessage = (message) => {
        if (message) {
            this.setState({ error: message })
        }
    }

    showError = () => {
        let message = '';
        if (this.state.error !== '') {
            message =
                <div className="bg-error py-1 px-4">
                    <p className="text-white mb-0 font-neo-semibold">ERROR: {this.state.error}</p>
                </div>
        }
        return message;
    }

    dashboardInfoFields = () => {

        let clientDashboardData = this.state.clientDashboardData;

        let infoFields = clientDashboardData.map((item, index) => {
            return (
                <div key={index} className="dashboard-info__field">
                    <label className="dashboard-info__label">{item.fieldName}</label>
                    <div className="dashboard-info__value form-control">{item.value}</div>
                </div>
            )
        });
        return (
            <div className="dashboard-info__fields">
                {infoFields}
            </div >
        );
    }

    basicInfo = () => {
        let infoTableRows = null;
        if (this.state.basicInfo.length > 0) {
            infoTableRows = this.state.basicInfo.map((item, index) => {
                return (
                    <tr key={index}>
                        <td>{item.fieldName ? item.fieldName : "-------"}</td>
                        <td>{item.value ? item.value : "-------"}</td>
                    </tr>
                );
            });
        } else {
            infoTableRows = (
                <tr key={1}>
                    <td>No Client Info</td>
                </tr>
            )
        }

        return (
            <div className="dashboard-info__table-body w-md-30">
                <p className="dashboard-info__table-header font-neo-semibold mb-1">Basic Information</p>
                <table className="dashboard-info__table table table-sm table-borderless">
                    <tbody>
                        {infoTableRows}
                    </tbody>
                </table>
            </div>
        );
    }

    address = () => {
        let addressRows = null;
        console.log(" address " + this.state.addressSelected.value);
        console.log(this.state.home_address);
        if (this.state.addressSelected.value !== "work") {
            if (this.state.home_address.length === 0) {
                addressRows = (
                    <tr><td colspan={2}>No Home Address</td></tr>
                )
            } else {
                addressRows = this.state.home_address.map((item, index) => {
                    return (
                        <tr key={index}>
                            <td>{item.fieldName ? item.fieldName : "-------"}</td>
                            <td>{item.value ? item.value : "-------"}</td>
                        </tr>
                    );
                });
            }


        } else {
            if (this.state.work_address.length === 0) {
                addressRows = (
                    <tr><td colspan={2}>No Work Address</td></tr>
                )
            } else {
                addressRows = this.state.work_address.map((item, index) => {
                    return (
                        <tr key={index}>
                            <td>{item.fieldName ? item.fieldName : "-------"}</td>
                            <td>{item.value ? item.value : "-------"}</td>
                        </tr>
                    );
                });
            }
        }

        return (
            <div className="dashboard-info__table-body w-100">
                {/* <p className="dashboard-info__table-header font-neo-semibold mb-1">Address</p> */}
                <Select
                    styles={customStyles}
                    options={this.addressOptions}
                    isSearchable={false}
                    className="address-select"
                    classNamePrefix="address-select"
                    onChange={this.handleAddressChange}
                    value={this.state.addressSelected}
                />
                <table className="dashboard-info__table table table-sm table-borderless">
                    <tbody>
                        {addressRows}
                    </tbody>
                </table>
            </div>
        );
    }

    contactInformation = () => {

        let riskRows = this.state.contactInfo.map((item, index) => {
            return (
                <tr key={index}>
                    <td>{item.fieldName ? item.fieldName : "-------"}</td>
                    <td>{item.value ? item.value : "-------"}</td>
                </tr>
            );
        });
        return (
            <div className="dashboard-info__table-body w-100">
                <p className="dashboard-info__table-header font-neo-semibold mb-1">Contact Information</p>
                <table className="dashboard-info__table table table-sm table-borderless">
                    <tbody>
                        {riskRows}
                    </tbody>
                </table>
            </div>
        );
    }

    onClickAction = (item) => {
        console.log(item);

        this.props.setClientOrPolicy({ policyId: item });

        writeData('policy-client', { policyId: item, id: 1 });
        routeChange(this.props.history, "/dashboard/single-policy");
    }

    async getTableData() {
        await this.fetchThisClient(this.props.getSession().access_token, decrypt(this.props.getSession().agentNumber));
    }

    changePage = (pageNumber) => {
        let that = this;
        if (this.state.currentPage != pageNumber) {
            this.setState({
                currentPage: pageNumber,
                // isLoading: true,
                loadTable: true,
            }, () => {
                this.getTableData();
                // await that.fetchBirthdayList(that.props.getSession().access_token, decrypt(that.props.getSession().agentNumber));
            }
            );
        }
    }

    policyTable = (css) => {
        const columns = [
            "Policy Number",
            "Plan Name",
            //"Status",
            "Proposed Insured",
            "Effective Date",
            "Currency",
            "Expiry Date"
        ];


        let policyColumns = columns.map((item, index) => {
            return (
                <th key={index} scope="col">{item}</th>
            );
        });
        let policyRows = this.state.policies.map((item, index) => {
            return (
                <tr className="policy-row cs-pointer" key={index} onClick={() => this.onClickAction(item.policyNumber)}>
                    <td scope="row">{item.policyNumber}</td>
                    <td>{item.planName}</td>
                    {
                    /*
                    <td><button className={"dashboard-datatable__button btn rounded-pill text-white " + item.status.toLowerCase()}>{item.status}</button></td>
                    */
                    }
                    <td><Insured insured={item.insured} /></td>
                    <td>{item.effectiveDate}</td>
                    <td>{item.currency}</td>
                    <td>{item.expiryDate}</td>
                </tr>
            )
        });

        if (this.state.policies.length > 0)
            return (
                <table className={`dashboard-policy__table table table-borderless ${css}`}>
                    <thead>
                        <tr>
                            {policyColumns}
                        </tr>
                    </thead>
                    <tbody>
                        {policyRows}
                    </tbody>
                </table>
            );
        else {
            return (
                <div className={`dashboard-policy__table table table-borderless ${css}`}>
                    <div className="dashboard-datatable mt-4">
                        <div className="center-align dashboard-row-data font-neo-semibold">No records found </div>
                    </div>
                </div>
            );
        }
    }


    mobileAccord = (css) => {
        const data = this.state.policies;
        const customName = {
            planName: "Plan Name",
            modalPremium: "Modal Premium",
            paymentMode: "Mode of Payment",
            insured: "Insured",
            effectiveDate: "Effective Date",
            currency: "Currency",
            expiryDate: "Expiry Date"
        };

        let policies = data.map((item, index) => {
            {
            /*
            let status_class = item.status.toLowerCase();
            let class_url = "btn " + status_class + " text-white rounded-pill data-accord__button pes-none";
            */
            }
            return (
                <div key={index} className={`data-accord__card card border-0`}>
                    <button className="data-accord__toggle btn">
                        <div className="row data-accord__start">
                            <div className="col-5">
                                <div className="data-accord__name">{item.policyNumber}</div>
                            </div>
                            <div className="col-7 d-flex justify-content-between">
                            {
                            /*
                                <button className={class_url}>{item.status}</button>
                            */
                            }
                                <button className="btn data-accord__button"></button>
                                <button className="btn p-0" type="button" data-toggle="collapse" data-target={`#collapse-${index}`}>
                                    <div className="data-accord__caret">
                                        <div className="data-accord__caret-icon"></div>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </button>
                    <div id={`collapse-${index}`} className="collapse" data-parent="#data-accord">
                        <div className="card-body data-accord__content">
                            <div className="row">
                                <div className="data-accord__column col-5">
                                    <div className="data-accord__label">{customName.planName}</div>
                                    <div className="data-accord__value">{item.planName}</div>
                                </div>
                                {/* <div className="data-accord__column col-7">
                                    <div className="data-accord__label">{customName.modalPremium}</div>
                                    <div className="data-accord__value">{item.modalPremium}</div>
                                </div>
                                <div className="data-accord__column col-5">
                                    <div className="data-accord__label">{customName.paymentMode}</div>
                                    <div className="data-accord__value">{item.paymentMode}</div>
                                </div> */}
                                <div className="data-accord__column col-7">
                                    <div className="data-accord__label">{customName.insured}</div>
                                    <div className="data-accord__value"><Insured insured={item.insured} /></div>
                                </div>
                                <div className="data-accord__column col-5">
                                    <div className="data-accord__label">{customName.effectiveDate}</div>
                                    <div className="data-accord__value">{item.effectiveDate}</div>
                                </div>
                                <div className="data-accord__column col-7">
                                    <div className="data-accord__label">{customName.currency}</div>
                                    <div className="data-accord__value">{item.currency}</div>
                                </div>
                                <div className="data-accord__column col-5">
                                    <div className="data-accord__label">{customName.expiryDate}</div>
                                    <div className="data-accord__value">{item.expiryDate}</div>
                                </div>
                                <div className="data-accord__column col-7 d-flex align-items-end justify-content-end">
                                    <button onClick={() => this.onClickAction(item.policyNumber)} className="btn btn-darkgreen rounded-pill data-accord__button">Summary</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            );
        });

        if (this.state.policies.length > 0) {
            return (
                <div className={`data-accord row flex-wrap mt-4 ${css}`}>
                    <div className="data-accord__header">
                        <div className="col-5 d-flex align-items-center">Policy Number <span className="icon-popout"></span></div>
                        {
                        /*
                        <div className="col-7 d-flex align-items-center">Status <span className="icon-popout"></span></div>
                        */
                        }
                    </div>
                    <div className="col-12 data-accord__body mt-1">
                        <div className="data-accord__collapse" id="data-accord">
                            {policies}
                        </div>
                    </div>
                </div>
            );
        } else {
            return (

                <div className={`data-accord row flex-wrap mt-4 ${css}`}>
                    <div className="dashboard-datatable mt-4">
                        <div className="center-align dashboard-row-data font-neo-semibold">No policy records found </div>
                    </div>
                </div>
            );
        }

    }

    tableView = () => {
        let desktopView = this.policyTable("d-md-table d-none");
        let mobileView = this.mobileAccord("d-block d-md-none");

        if (this.state.loadTable === true) {
            desktopView = <LoaderGif css="d-md-block d-none" />;
            mobileView = <LoaderGif css="d-block d-md-none" />;
        }
        return (
            <Auxi>
                {desktopView}
                {mobileView}
                {this.state.policies.length > 0 ?
                    <TablePagination
                        css={`mt-2 ${this.state.loadTable ? "d-none" : ""}`}
                        data={this.state.policies}
                        page={this.state.currentPage}
                        totalSize={this.state.totalSize}
                        onPageChange={this.changePage}
                        pageSize={myConfig.PAGE_SIZE}
                        pages={this.state.pages}
                    /> : ""
                }
            </Auxi>

        );
    }

    render() {
        if (this.state.isLoading) {
            return (
                <ReportLoader handleClose={this.hideModal} show={this.state.isLoading} />
            );
        } else {
            if (this.state.clientDashboardData.length > 0) {
                return (
                    <div className="dashboard-section">
                        <div className="dashboard-container container-fluid p-3">
                            <div className="dashboard-header">
                                <h6 className="font-neo-bold text-darkgray">Client Information</h6>
                                {this.props.getViewAs}
                            </div>
                            {this.showError()}
                            <div className="dashboard-body mt-4">
                                <div className="dashboard-info">
                                    {this.dashboardInfoFields()}
                                    <ul className="dashboard-info__tabs nav nav-tabs mt-4 justify-content-center justify-content-md-start" role="tablist">
                                        <li className="dashboard-info__tab nav-item">
                                            <a className="dashboard-info__tab-link nav-link" data-toggle="tab" href="#clientInfo" role="tab">Client Information</a>
                                        </li>
                                        <li className="dashboard-info__tab nav-item">
                                            <a className="dashboard-info__tab-link nav-link active" data-toggle="tab" href="#policyList" role="tab">List of Policies</a>
                                        </li>
                                    </ul>
                                    <div className="tab-content">
                                        <div className="tab-pane fade" id="clientInfo" role="tabpanel">
                                            <div className="dashboard-info__table-container mt-3 bg-white p-4 shadow rounded">
                                                {this.basicInfo()}
                                                <div className="d-flex flex-column w-md-60">
                                                    {this.address()}
                                                    {this.contactInformation()}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade show active" id="policyList" role="tabpanel">
                                            {this.tableView()}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            } else {
                return (
                    <div className="dashboard-section">
                        <div className="dashboard-container container-fluid p-3">
                            <div className="dashboard-header">
                                <h6 className="font-neo-bold text-darkgray">Client Information</h6>
                                {this.props.getViewAs}
                            </div>
                            {this.showError()}
                            <div className="dashboard-body mt-4">
                                <div className="dashboard-policy center-align">
                                    No Client Information Available
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
        }
    }
}
export default withRouter(SingleClientPolicy);
