import React, { Component } from "react";
import { apiModule } from "../../../commons/apiCall";
import LoaderGif from "../../../commons/loaderGif";
import withWindowDimensions from "../../../commons/withWindowDimensions";

class PolicyProvision extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...this.props.modalData,
            data64: '',
            isLoading: true,
            isError: false,
        }
    }

    componentDidMount = async () => {
        await this.fetchData();
    }

    fetchData = async () => {
        // try {
        //     const reqParams = {
        //         type: 'SALES_ILLUSTRATION',
        //         contractId: this.state.policyId, //? hardcoded policy id working :190335737,
        //         x_auth: this.state.token,
        //     }
        //     console.log("%cPDF is Downloading...", "background: green; color: white; display: block;");
        //     const response = await apiModule(`get_document_other_info`, reqParams)
        //         .then((response) => {
        //             if (!response.error) {
        //                 console.log("%cPDF is done downloading,%c RESPONSE:", "background: green; color: white; display: block;", "background: #255F8E; color: white; display: block;", response);
        //                 this.setState({ isLoading: false });
        //                 return response;
        //             }
        //             console.log("%cPDF Error,%c RESPONSE:", "background: red; color: white; display: block;", "background: #255F8E; color: white; display: block;", response);
        //             this.setState({ isLoading: false, isError: true });
        //             throw Error();
        //         });
        //     this.setState({ data64: response[0].data });
        // }
        // catch (e) {
        //     console.log(e);
        // }
    }

    render = () => {
        const contentStyle = this.props.isSm ? { height: '75vh' } : { height: '90vh' };
        return (
            <div style={contentStyle}>
                <p>Policy Provision</p>
                {
                    this.state.isLoading ?
                        <LoaderGif css="other-info-modal-pdf__modal-loading" style={contentStyle} /> :
                        !this.state.isError ?
                            <iframe onContextMenu={() => { document.body.addEventListener("contextmenu", function (evt) { evt.preventDefault(); return false; }); }} src={`data:application/pdf;base64,${this.state.data64}#toolbar=0&navpanes=0&view=FitH`} height="95%" width="100%" /> :
                            <p className="help__error-txt">Error</p>
                }
            </div>

        );
    }
}

export default withWindowDimensions(PolicyProvision);