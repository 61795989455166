
import React, { Component } from 'react';
import ReactTable from 'react-table';
import { ReportLoader } from '../commons/utilityViews.js';
import { withRouter, Link } from 'react-router-dom';
import ReportDatePicker from "../components/reportDatepicker";
import DpCustomHeader, { Years } from '../components/Datepicker/customHeader';
import Auxi from '../components/auxi';
import DatePicker from 'react-datepicker';
import DatatableSearch from '../components/datatableSearch';
import { apiModule, createQueryStrings } from '../commons/apiCall';
import { myConfig } from '../commons/config.js';
import { checkDateRange, prettifyDate, removeNull, checkSearch, numberWithCommas, calculateAge, writeData, readAllData, encrypt, decrypt, fixName } from '../commons/utility.js';
import TableLoader from '../components/TableLoader/tableLoader';
import withWindowDimensions from '../commons/withWindowDimensions';
import ReportsMobile from '../components/Reports/Mobile/ReportsMobile';
import TablePagination from '../components/tablePagination';
import handleSortOrder from '../components/misc/sortOrder';
import routeChange from '../commons/routeChange';
import "../components/Documents/documents.scss";
import { isManager } from '../commons/security.js';

//default dates
var toDate = new Date();
toDate.setDate(new Date().getDate() + 30);
var formattedCurrentDate = new Date().getFullYear() + "-" + (new Date().getMonth() + 1) + "-" + new Date().getDate();
var formattedToDate = toDate.getFullYear() + "-" + (toDate.getMonth() + 1) + "-" + toDate.getDate();

class LapseListReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            loadTable: false,
            error: '',
            startDate: null,
            endDate: null,
            showTable: false,
            currentPage: 0,
            reports: [],
            sortByField: null,
            sortOrder: null,
            isMgr: "-",
            searchValue: "",
            optionSelected: { value: "lapseDate", label: "Lapse Date" },
        };
        this.pageHeader = "Lapsed List"
    }

    componentDidMount() {
        this.setState({
            isLoading: true,
            isMgr: isManager(this.props.getSession().role)
        }, () => {
            this.getTableData();
        });
    }
    resetMessage = () => {
        this.setState({ error: '' })

    }
    errorMessage = (message) => {
        console.log(message)
        if (message) {
            this.setState({ error: message, isLoading: false })
        }
    }
    showTable = () => {
        if (this.state.startDate && this.state.endDate) {
            this.setState({
                // isLoading: true
                currentPage: 0,
                loadTable: true,
            }, () => {
                this.getTableData();
            });
        }
    }

    async getTableData() {
        await this.fetchPolicyList(this.props.getSession().access_token, decrypt(this.props.getSession().agentNumber));
    }

    //SHOW ERROR IF ERROR STATE IS NOT NULL
    showError = () => {
        let message = '';
        if (this.state.error !== '') {
            message =
                <div className="bg-error py-1 px-4">
                    <p className="text-white mb-0 font-neo-semibold">ERROR: {this.state.error}</p>
                </div>
        }
        return message;
    }

    updateStartDate = (date) => {
        this.setState({
            startDate: date,
        }, () => {
            if (this.state.endDate != null) {
                this.showTable();
            }
        });
    }

    updateEndDate = (date) => {
        this.setState({
            endDate: date,
        }, () => {
            if (this.state.startDate != null) {
                this.showTable();
            }
        });
    }

    search = (pageNumber) => {

        let that = this;
        let headerParams = { x_auth: this.props.getSession().access_token };
        let agentNumber = decrypt(this.props.getSession().agentNumber);
        let filter = [];
        let continueProcess = true;
        filter['pageNumber'] = (pageNumber + 1);
        filter['agentNumber'] = agentNumber;
        filter['reportCode'] = 'RPT_POL_LAP';
        filter['pageSize'] = myConfig.PAGE_SIZE;
        filter['sortBy'] = "LAP_START_DT,DESC";

        if (this.state.optionSelected.value !== null) {

            if (this.state.optionSelected.value == "lapseDate") {
                let errorMessageDate = "";
                if (this.state.startDate != null) {
                    filter['dueDateFrom'] = prettifyDate(this.state.startDate);
                    errorMessageDate = checkDateRange(filter['dueDateTo'], filter['dueDateFrom']);
                }
                if (this.state.endDate != null) {
                    filter['dueDateTo'] = prettifyDate(this.state.endDate);
                    errorMessageDate = checkDateRange(filter['dueDateTo'], filter['dueDateFrom']);
                }
                //console.log("errorMessageDate : " + errorMessageDate);
                if (errorMessageDate !== "") {
                    this.setState({ error: errorMessageDate });
                    continueProcess = false;
                }

            } else if (this.state.searchValue != null && this.state.searchValue != "") {
                filter[this.state.optionSelected.value] = this.state.searchValue;
                continueProcess = checkSearch(this.state.searchValue, this.errorMessage, 2);
            }

            if (this.state.sortByField != null) {
                filter['sortBy'] = this.state.sortByField + ',' + this.state.sortOrder
            }
            if (continueProcess) {
                that.resetMessage();
                apiModule("policy_all", headerParams, null, createQueryStrings(filter))
                    .then(function (response) {
                        //console.log(response)
                        let result = [];
                        let totalSize = 0;

                        if (response != null) {
                            if (Array.isArray(response)) {
                                if (response.length > 0) {
                                    result = response[0].content;
                                    totalSize = response[0].totalSize;
                                }
                            } else {
                                result = response.content;
                                totalSize = response.totalSize;
                            }
                        }


                        let numOfPages = parseInt((totalSize / myConfig.PAGE_SIZE), 10);

                        if ((totalSize % myConfig.PAGE_SIZE) > 0) {
                            numOfPages++;
                        }

                        let reports = [];

                        if (result) {

                            for (let i = 0; i < result.length; i++) {

                                let reportItem = {
                                    FA_NAME: removeNull(result[i].agent.name),
                                    POL_NO: removeNull(result[i].contractNumber),
                                    CREDIT_SO: removeNull(result[i].branchName),
                                    O_LAST_NAME: removeNull(fixName(result[i].contractHolder)),
                                    I_COMP_NAME: removeNull(fixName(result[i].insured[0])),
                                    POL_DS: removeNull(result[i].productOfferingName),
                                    EFF_DT: removeNull(prettifyDate(result[i].inceptionDate)),
                                    POL_CURR_CD: removeNull(result[i].currency),
                                    FACE_AMT: removeNull(numberWithCommas(result[i].contractBalance)),
                                    EXPIRY_DT: removeNull(prettifyDate(result[i].contractInterval.endDateTime)),
                                    LAP_START_DT: removeNull(prettifyDate(result[i].lapsedStartDate)),
                                    DAYS_LAPSED: removeNull(result[i].daysSinceLapsed),
                                    PMODE_CD: removeNull(result[i].paymentFrequency),
                                }
                                if (!that.state.isMgr) {
                                    delete reportItem.FA_NAME;
                                }
                                reports.push(reportItem);
                            }

                            that.setState({
                                reports: reports,
                                currentPage: pageNumber
                            });

                        } else {
                            this.setState({
                                error: "No Account Found"
                            }, () => {
                                that.showError();
                            });

                        }

                        that.setState({
                            reports: reports,
                            loadTable: false,
                            totalSize: totalSize,
                            pages: numOfPages,
                            showTable: true,
                            error: '',
                            isLoading: false
                        });
                    })
                    .catch(function (err) {
                        //console.log(err);
                        that.setState({
                            isLoading: false,
                            error: myConfig.GENERIC_ERROR_MESSAGE
                        }, () => {
                            that.showError();
                            //console.log(err.message)
                        });
                    })
            } else {
                that.setState({
                    isLoading: false,
                }, () => {
                    that.showError();
                });

            }
        }
    }

    fetchPolicyList = (access_token, agentNumber) => {
        let that = this;
        return new Promise(function (resolve, reject) {

            let headerParams = { x_auth: access_token };
            let filter = [];
            let continueProcess = true;
            filter['pageNumber'] = (that.state.currentPage + 1);
            filter['agentNumber'] = agentNumber;
            filter['reportCode'] = 'RPT_POL_LAP';
            filter['pageSize'] = myConfig.PAGE_SIZE;
            filter['sortBy'] = "LAP_START_DT,DESC";

            if (that.state.startDate != null && !Number.isNaN(that.state.startDate)) {
                filter['dueDateFrom'] = prettifyDate(that.state.startDate);
                continueProcess = (checkDateRange(filter['dueDateTo'], filter['dueDateFrom']) === "") ? true : false;
            }

            if (that.state.endDate != null && !Number.isNaN(that.state.endDate)) {
                filter['dueDateTo'] = prettifyDate(that.state.endDate);
                continueProcess = (checkDateRange(filter['dueDateTo'], filter['dueDateFrom']) === "") ? true : false;
            }

            if (that.state.sortByField != null) {
                filter['sortBy'] = that.state.sortByField + ',' + that.state.sortOrder
            }
            if (continueProcess) {
                that.resetMessage();
                apiModule("policy_all", headerParams, null, createQueryStrings(filter))
                    .then(function (response) {
                        let result = [];
                        let totalSize = 0;

                        if (response != null) {
                            if (Array.isArray(response)) {
                                if (response.length > 0) {
                                    result = response[0].content;
                                    totalSize = response[0].totalSize;
                                }
                            } else {
                                result = response.content;
                                totalSize = response.totalSize;
                            }
                        }


                        let numOfPages = parseInt((totalSize / myConfig.PAGE_SIZE), 10);

                        if ((totalSize % myConfig.PAGE_SIZE) > 0) {
                            numOfPages++;
                        }

                        let reports = [];

                        if (result) {

                            for (let i = 0; i < result.length; i++) {

                                let reportItem = {
                                    FA_NAME: removeNull(result[i].agent.name),
                                    POL_NO: removeNull(result[i].contractNumber),
                                    CREDIT_SO: removeNull(result[i].branchName),
                                    O_LAST_NAME: removeNull(fixName(result[i].contractHolder)),
                                    I_COMP_NAME: removeNull(fixName(result[i].insured[0])),
                                    POL_DS: removeNull(result[i].productOfferingName),
                                    EFF_DT: removeNull(prettifyDate(result[i].inceptionDate)),
                                    POL_CURR_CD: removeNull(result[i].currency),
                                    FACE_AMT: removeNull(numberWithCommas(result[i].contractBalance)),
                                    EXPIRY_DT: removeNull(prettifyDate(result[i].contractInterval.endDateTime)),
                                    LAP_START_DT: removeNull(prettifyDate(result[i].lapsedStartDate)),
                                    DAYS_LAPSED: removeNull(result[i].daysSinceLapsed),
                                    PMODE_CD: removeNull(result[i].paymentFrequency),
                                }
                                if (!that.state.isMgr) {
                                    delete reportItem.FA_NAME;
                                }
                                reports.push(reportItem);
                            }

                            that.setState({
                                reports: reports
                            });

                        } else {
                            this.setState({
                                error: "No Account Found"
                            }, () => {
                                that.showError();
                            });

                        }

                        that.setState({
                            reports: reports,
                            loadTable: false,
                            totalSize: totalSize,
                            pages: numOfPages,
                            showTable: true,
                            error: '',
                            isLoading: false
                        });
                    })
                    .catch(function (err) {
                        console.log(err);
                        that.setState({
                            isLoading: false,
                            error: myConfig.GENERIC_ERROR_MESSAGE
                        }, () => {
                            that.showError();
                            console.log(err.message)
                        });
                    })
            } else {
                that.setState({
                    isLoading: false,
                    error: checkDateRange(filter['dueDateTo'], filter['dueDateFrom'])
                }, () => {
                    that.showError();
                });

            }
            // let result = null;
        });
    }

    handleSortAction = (sortByField, sortOrder) => {
        this.setState({
            sortByField: sortByField,
            sortOrder: sortOrder,
            // isLoading: true
            loadTable: true,
        }, () => {
            this.getTableData();
        })
    }
    headerClickedHandler = (sortByField) => {
        let sortOrder = handleSortOrder({ sortByField: sortByField, state: this.state });
        this.handleSortAction(sortByField, sortOrder)
    }

    onClickAction = (item) => {
        this.props.setClientOrPolicy({ policyId: item });
        writeData('policy-client', { policyId: item, id: 1 });
        routeChange(this.props.history, "/dashboard/single-policy");
    }

    changePage = (pageNumber) => {
        let that = this;
        if (this.state.currentPage != pageNumber) {
            this.setState({
                currentPage: pageNumber,
                // isLoading: true,
                loadTable: true,
            }, () => {
                this.getTableData();
            }
            );
        }
    }

    updateOptionSelected = (option) => {
        this.setState({ optionSelected: option });
    }
    updateSearchValue = (value) => {
        this.setState({
            searchValue: value,
        });
    }
    searchByOption = () => {
        let field = "";
        if (this.state.optionSelected.value == "lapseDate") {
            field = (
                <div className="policy-datepicker__body flex-wrap justify-content-md-start justify-content-between align-items-stretch">
                    <div className="policy-datepicker__field d-flex align-items-center position-relative">
                        <div className="form-control policy-datepicker__container rounded-pill border-0 h-100">
                            <DatePicker
                                placeholderText="Lapse date from" className="policy-datepicker__input text-gray policy-datepicker__input--placeholder"
                                selected={this.state.startDate}
                                onChange={this.updateStartDate}
                                dateFormat="yyyy-MM-dd"
                                renderCustomHeader={({
                                    date,
                                    changeYear,
                                    decreaseMonth,
                                    increaseMonth,
                                    prevMonthButtonDisabled,
                                    nextMonthButtonDisabled
                                }) => (
                                    <DpCustomHeader
                                        dropdownStyle={{
                                            option: (provided) => ({
                                                ...provided,
                                                fontFamily: "neo_bold",
                                                fontSize: ".65rem",
                                                color: "#1e4a73",
                                                backgroundColor: "white"
                                            }),
                                            menu: () => ({
                                                position: "absolute",
                                                border: 0,
                                                zIndex: "1090",
                                                backgroundColor: "white",
                                                borderRadius: "5px",
                                                width: "4rem",
                                                boxShadow: "2px 2px 2px #DDDEE0"
                                            }),
                                        }}
                                        //CAN ADD MAX YEAR -> Years(minYear,maxYear)
                                        yearOptions={Years(1990)}
                                        date={date}
                                        changeYear={changeYear}
                                        decreaseMonth={decreaseMonth}
                                        increaseMonth={increaseMonth}
                                        prevMonthButtonDisabled={prevMonthButtonDisabled}
                                        nextMonthButtonDisabled={nextMonthButtonDisabled}
                                    />
                                )}
                            />
                            <span className="icon-calendar text-gray"></span>
                        </div>
                    </div>
                    <div className="policy-datepicker__field d-flex align-items-center position-relative ml-2">
                        <div className="form-control policy-datepicker__container policy-datepicker__mobile rounded-pill border-0 h-100">
                            <DatePicker
                                placeholderText="Lapse date to" className="policy-datepicker__input text-gray policy-datepicker__input--placeholder"
                                selected={this.state.endDate}
                                onChange={this.updateEndDate}
                                dateFormat="yyyy-MM-dd"
                                renderCustomHeader={({
                                    date,
                                    changeYear,
                                    decreaseMonth,
                                    increaseMonth,
                                    prevMonthButtonDisabled,
                                    nextMonthButtonDisabled
                                }) => (
                                    <DpCustomHeader
                                        dropdownStyle={{
                                            option: (provided) => ({
                                                ...provided,
                                                fontFamily: "neo_bold",
                                                fontSize: ".65rem",
                                                color: "#1e4a73",
                                                backgroundColor: "white"
                                            }),
                                            menu: () => ({
                                                position: "absolute",
                                                border: 0,
                                                zIndex: "1090",
                                                backgroundColor: "white",
                                                borderRadius: "5px",
                                                width: "4rem",
                                                boxShadow: "2px 2px 2px #DDDEE0"
                                            }),
                                        }}
                                        //CAN ADD MAX YEAR -> Years(minYear,maxYear)
                                        yearOptions={Years(1990)}
                                        date={date}
                                        changeYear={changeYear}
                                        decreaseMonth={decreaseMonth}
                                        increaseMonth={increaseMonth}
                                        prevMonthButtonDisabled={prevMonthButtonDisabled}
                                        nextMonthButtonDisabled={nextMonthButtonDisabled}
                                    />
                                )}
                            />
                            <span className="icon-calendar text-gray"></span>
                        </div>
                    </div>
                </div>
            );
        }
        return field;
    }

    render() {
        const customNames = [
            {
                Header: 'Intermediary Name',
                accessor: 'FA_NAME',
                width: 110,
            },
            {
                Header: 'Contract Branch',
                accessor: 'CREDIT_SO'
            },
            {
                Header: 'Policy Number',
                accessor: 'POL_NO'
            },
            {
                Header: "Policy Owner",
                accessor: 'I_COMP_NAME'
            },
            {
                Header: "Policy Insured",
                accessor: 'I_COMP_NAME'
            },
            {
                Header: "Plan Name",
                accessor: 'POL_DS'
            },
            {
                Header: "Effective Date",
                accessor: 'EFF_DT',
            },
            {
                Header: "Currency",
                accessor: 'POL_CURR_CD'
            },
            {
                Header: "Face Amount",
                accessor: 'FACE_AMT'
            },
            {
                Header: "Expiry Date",
                accessor: 'EXPIRY_DT'
            },
            {
                Header: "Lapse date",
                accessor: 'LAP_START_DT'
            },
            {
                Header: "Days Lapse",
                accessor: 'DAYS_LAPSED'
            },
            {
                Header: "Mode of Payment",
                accessor: 'PMODE_CD'
            }
        ];
        if (!this.state.isMgr) {
            customNames.splice(0, 1)
        }
        if (this.state.isLoading) {
            return (
                <ReportLoader handleClose={this.hideModal} show={this.state.isLoading} />
            );
        } else {

            let dataTable = "";
            let options = [
                { value: "lapseDate", label: "Lapse Date" },
                { value: "faName", label: "Intermediary Name" }
            ]
            if (!this.state.isMgr) {
                options.splice(1, 1)
            }
            if (this.state.reports.length > 0) {
                if (this.props.isSm) {
                    dataTable = (
                        <ReportsMobile
                            title={[
                                {
                                    Header: 'Policy Number',
                                    accessor: 'POL_NO',
                                    hasCaret: true,
                                    onClick: () => {
                                        this.headerClickedHandler('POL_NO');
                                    }
                                },
                            ]}
                            customNames={customNames}
                            data={this.state.reports}
                            sortByField={this.state.sortByField}
                            sortOrder={this.state.sortOrder}
                            hasOnClickBtn={true}
                            onClickAction={this.onClickAction}
                        >
                            <TablePagination
                                css="mt-2"
                                data={this.state.reports}
                                page={this.state.currentPage}
                                totalSize={this.state.totalSize}
                                onPageChange={this.changePage}
                                pageSize={myConfig.PAGE_SIZE}
                                pages={this.state.pages}
                            />
                            {this.state.loadTable ? <TableLoader loading={true} /> : ""}
                        </ReportsMobile>
                    );
                } else {
                    dataTable = (
                        <ReactTable
                            className={`mt-4 ${this.state.showTable ? "d-flex" : "d-none"} rt-no-hover`}
                            resizable={false}
                            loading={this.state.loadTable}
                            LoadingComponent={TableLoader}
                            columns={customNames}
                            defaultPageSize={myConfig.PAGE_SIZE}
                            minRows={0}
                            data={this.state.reports}
                            page={this.state.currentPage}
                            manual
                            onFetchData={(state, instance) => {
                                this.changePage(state.page);
                            }}
                            getTheadThProps={(state, rowInfo, column, instance) => ({
                                onClick: () => {
                                    this.headerClickedHandler(column.id);
                                },
                                sortClassName: (
                                    this.state.sortByField == column.id ?
                                        (this.state.sortOrder == "ASC" ?
                                            "icon-chevron-small-up"
                                            : "icon-chevron-dropdown")
                                        : ""
                                ),
                                sortColor: (
                                    this.state.sortByField == column.id ?
                                        "text-black"
                                        : ""
                                )
                            })}
                            getTrProps={(state, rowInfo, column, instance) => ({
                                onClick: () => {
                                    this.onClickAction(rowInfo.original.POL_NO);
                                },
                                style: {
                                    cursor: 'pointer',
                                    color: (rowInfo.original.DAYS_LAPSED > 1096 ? '#CC0000' : '') //it takes 3 years (or 1095.7 days) for a policy to be unreinstate-able
                                }
                            })}
                            pages={this.state.pages}
                            totalSize={this.state.totalSize}
                        />
                    );
                }
            } else {
                dataTable = (
                    <div className="center-align dashboard-row-data font-neo-semibold">No records found </div>
                );
            }

            let startDate = this.state.startDate;
            let endDate = this.state.endDate;

            if (Number.isNaN(startDate)) {
                startDate = new Date();


            }

            if (Number.isNaN(endDate)) {
                endDate = new Date();
                endDate.setDate(new Date().getDate() + 30);

            }

            return (
                <div className="dashboard-section">
                    {this.showError()}
                    <div className="dashboard-container container-fluid p-3">
                        <div className="dashboard-header">
                            <h6 className="font-neo-bold">
                                <Link className="text-darkgray text-decoration-none" to="/dashboard/reports">Reports</Link>
                                <span className="dashboard-header__subheader text-headercolor"> | {this.pageHeader}</span>
                            </h6>
                            {this.props.getViewAs}
                        </div>
                        <div className="dashboard-body mt-4">
                            <DatatableSearch
                                //DROPDOWN OPTION
                                optionSelectedValue={this.state.optionSelected}
                                updateOptionSelected={this.updateOptionSelected}
                                //END DROPDOWN OPTION

                                //SEARCH VALUE
                                searchValue={this.state.searchValue}
                                updateSearchValue={this.updateSearchValue}
                                //END SEARCH VALUE

                                searchByOption={this.searchByOption()}
                                search={(pageNumber) => {
                                    this.setState({ loadTable: true }, () => {
                                        this.search(pageNumber)
                                    })
                                }}
                                // search={(options, search, pageNumber) => this.search(options, search, pageNumber)}
                                styles={{
                                    option: (provided) => ({
                                        ...provided,
                                        fontFamily: "neo_semibold",
                                        fontSize: ".6rem",
                                        color: "#9A9B9C",
                                        backgroundColor: "white"
                                    }),
                                    menu: () => ({
                                        position: "absolute",
                                        border: 0,
                                        zIndex: "1090",
                                        backgroundColor: "white",
                                        borderRadius: "5px",
                                        boxShadow: "2px 2px 2px #DDDEE0"
                                    }),
                                }}
                                options={options}
                            />
                            <div className='instruction-box border border-info p-1 m-3 bg-light'>
                                <span className='report-generate-spiel text-headercolor'>
                                    <p className='mb-0'>
                                        <span>
                                            <b>Note:</b> Please be advised that those lapsed policies in red color are not reinstate-able.<br />
                                        </span>
                                        {this.state.optionSelected.value == "faName"
                                            ? <span className='font-italic text-danger'>You may search the Intermediary name by their Last name , First name, or First name + Last name</span>
                                            : null}
                                    </p>
                                </span>
                            </div>
                            {dataTable}

                        </div>
                    </div>
                </div>
            );
        }
    }
}

export default withRouter(withWindowDimensions(LapseListReport));
