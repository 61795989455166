import React, { Component } from 'react';
// import '../App.css';
import { writeData, jsonToURI, readAllData, encrypt, decrypt } from '../commons/utility.js';
import { styles } from '../styles/styles.js';

import { Modal, Loader } from '../commons/utilityViews.js';
import { myConfig } from '../commons/config.js';
import { BrowserRouter as Router, Route, Link, Redirect, withRouter } from 'react-router-dom';
import { user } from '../apiFactory/user.js';
import AuthLoader from '../commons/authLoader';
import routeChange from '../commons/routeChange.js';
import { apiModule } from '../commons/apiCall.js';
import HelpInfo from './ChangePassword/helpInfo.js';
class ChangePassword extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      payloadParams: {
        username: '',
        old_password: '',
        new_password: '',
        confirm: ''
      },

      showOldPassword: false,
      showNewPassword: false,
      showConfirmPassword: false,
      showHelpInfo: true,
      error: 'Please provide your new password.',
      token: ''
    };
  }

  async componentDidMount() {
    let access_token = this.props.getSession().access_token;

    const query = this.props.location.search;
    const token = query.replace('?token=', '');

    console.log(this.props.getSession());

    console.log('token', token);

    if(token) {
      this.setState({
        token
      })
    } else {
      this.props.history.push('/');
    }    
  }

  hasMetPasswordPolicy = (password) => {
    if(password === null || password === undefined) {
      console.log("Error: Password is blank");
      return false;
    }

    if(password.length < 8) {
      console.log("Error: Password must contain at least 8 characters!");
      return false;
    }
   
    let format = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
    if(!format.test(password)) {
      console.log("Error: password must contain special characters");
      return false;
    }
    // let re = /[a-z]/;
    // if(!re.test(password)) {
    //   console.log("Error: password must contain at least one lowercase letter (a-z)!");
     
    //   return false;
    // }
    let reU = /[A-Z]/;
    if(!reU.test(password)) {
      console.log("Error: password must contain at least one uppercase letter (A-Z)!");
      return false;
    }

    return true;
  } 


  doChangePassword = (that) => {

    return new Promise(function (resolve, reject) {

      if(!that.hasMetPasswordPolicy(that.state.payloadParams.new_password)) {
        that.errorMessage("The new password does not meet the minimum criteria");
        resolve("Ok");
      }
      else if(that.state.payloadParams.confirm === that.state.payloadParams.new_password) {
        let headerParams = {};
            let payloadParams = {
              password: that.state.payloadParams.new_password,
              appId: myConfig.KEYCLOAK_APPID,
              code: that.state.token
            };
            that.setState({
              isLoading: true,
  
            });
            apiModule("authentication_change_password", headerParams, payloadParams, '', true)
            .then(function (result) {
                that.props.setLoginMessage("Your password has been changed.");
                routeChange(that.props.history, "/");
                resolve("Ok");
            })
            .catch(function (err) {
                console.log(err);

                console.log('err', err)
              const response = JSON.stringify(err);
              const { response: { data: { error } } = { data: {error: ''}} } = JSON.parse(response);

              const errorCodes = {
                704: "Invalid password reset code.",
                705: "Invalid password reset code.",
                706: "The input for the new password is incorrect. Please provide a new password.",
                707: "New and old password must not match. Please provide a new password.",
                "400 BAD_REQUEST": "The input for the new password is incorrect. Please provide a new password."
              }

              if(errorCodes.hasOwnProperty(error)) {
                that.setState({
                  isLoading: false,
                  error: errorCodes[error]
                });
              } else {
                that.setState({
                  isLoading: false,
                  error: myConfig.GENERIC_ERROR_MESSAGE
                });
              }
                resolve("Ok");
            })

      
      }else {
        that.errorMessage("Passwords do not match");
        resolve("Ok");
      }
    });

  }

  errorMessage = (message) => {
    if (message) {
      this.setState({ error: message })
    }
  }

  showError = () => {
    let message = '';
    if (this.state.error !== '') {
      message =
        <div className="form-group mb-4 text-white">
          <p className="help__error-txt">{this.state.error}</p>
        </div>
    }
    return message;
  }
  handleChanges(e) {
    let payloadParams = this.state.payloadParams;
    payloadParams[e.target.name] = e.target.value;
    this.setState({ payloadParams });
  }

  handleOldPasswordVisibility = () => {
    if (this.state.showOldPassword === false) {
      this.setState({ showOldPassword: true })
    } else {
      this.setState({ showOldPassword: false })
    }
  } 
  handleConfirmPasswordVisibility = () => {
    if (this.state.showConfirmPassword === false) {
      this.setState({ showConfirmPassword: true })
    } else {
      this.setState({ showConfirmPassword: false })
    }
  }
  handleNewPasswordVisibility = () => {
    if (this.state.showNewPassword === false) {
      this.setState({ showNewPassword: true })
    } else {
      this.setState({ showNewPassword: false })
    }
  }

  handleKeyPress(e) {
    if (e.key === 'Enter') {
      this.doChangePassword(this);
    } else {

    }
  }
  render() {
    const customStyles = {
      color: "white",
    };

    return (
      <div className="auth-section">
        <div className="auth__picture">
          <img className="auth__img" src={require('../assets/img/bg-login.jpg')} alt="Authentication background" />
        </div>
        <div className="custom-skewed-block background--gradient-blue">

          <div className="auth-block">
            {/* ChangePassword CONTAINER */}
            <div className="auth-container">
              <form>
                <h1 className="auth__header text-white mb-4 mt-4">Change Password</h1>
                {this.showError()}
                <div className="form-group mb-2">
                  <div className="auth__text-field form-control d-flex">
                    <input className="flex-grow-1 border-0" type={this.state.showNewPassword === true ? "text" : "password"} name="new_password" onChange={(event) => this.handleChanges(event)} placeholder="NEW PASSWORD" value={this.state.payloadParams.new_password} required />
                    <a className="d-flex align-items-center cs-pointer" onClick={this.handleNewPasswordVisibility}>
                      <span className={`${this.state.showNewPassword === false ? "icon-hide-password-o" : "icon-show-password-o"} font-1p5rem text-lightgray auth__icon`}></span>
                    </a>
                  </div>
                </div>
                <div className="form-group mb-4">
                  <div className="auth__text-field form-control d-flex">
                    <input className="flex-grow-1 border-0" type={this.state.showConfirmPassword === true ? "text" : "password"} onKeyPress={(event) => this.handleKeyPress(event)} name="confirm" onChange={(event) => this.handleChanges(event)} placeholder="CONFIRM NEW PASSWORD" value={this.state.payloadParams.confirm} required />
                    <a className="d-flex align-items-center cs-pointer" onClick={this.handleConfirmPasswordVisibility}>
                      <span className={`${this.state.showConfirmPassword === false ? "icon-hide-password-o" : "icon-show-password-o"} font-1p5rem text-lightgray auth__icon`}></span>
                    </a>
                  </div>
                </div>
                <HelpInfo
                  showHelpInfo={this.state.showHelpInfo}
                  closeHelpInfo={this.handleCloseHelpInfo}
                  entry={this.state.payloadParams.new_password}
                  confirmEntry={this.state.payloadParams.confirm}
                  customStyles={customStyles}
                />
                {/* <a href="/verify" className="auth__button btn btn-navyblue text-uppercase btn-block">Submit</a> */}
                {/* <Link className="auth__button btn btn-navyblue text-uppercase btn-block" to="/verify">Submit</Link> */}
                {/* <a onClick={() => this.doChangePassword(this)} className="auth__button btn btn-navyblue text-uppercase btn-block text-white">Submit</a> */}
                <a onClick={() => this.doChangePassword(this)} className={`auth__button btn ${this.state.isLoading ? "btn-loading pes-none" : "btn-navyblue"}  text-uppercase btn-block text-white`}>{this.state.isLoading ? <AuthLoader width="60" /> : "Submit"}</a>
              </form>
            </div>
            {/* END ChangePassword CONTAINER */}
          </div>
          <div className="auth-logo-section">
            <div className="auth-logo-container">
              <img className="auth-logo" src={require("../assets/img/logo.png")} alt="Allianz Logo" />
            </div>
          </div>
        </div>
      
      </div>

    );
  }
}

export default withRouter(ChangePassword);
