import React from 'react';
import withWindowDimensions from '../../commons/withWindowDimensions';

const Insured = (props) => {
    const { insured } = props;
    return (
        <>
            {Array.isArray(insured) ? insured.map((item, i) => item.name ? <p>{item.name}</p> : <p key={i}>{item.lastName}{item.suffix ? ' ' + item.suffix : '' }, {item.firstName} {(item.middleName ? item.middleName : '')}</p>) : "---"}
        </>
    );
}

export default withWindowDimensions(Insured);
