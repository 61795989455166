import React from 'react';
import Select from 'react-select';
import withWindowDimensions from '../../../commons/withWindowDimensions';
import TextInput from "./textInput";
import DateRange from "./dateRange";
import Statuses from './statuses';

const Search = (props) => {
    const options = [
        { label: "Policy Owner", value: "contractHolderName" },
        { label: "Policy Number", value: "contractNumber" },
        { label: "Missed Premium Date", value: "paidUntilDate" },
        //{ label: "Policy Status", value: "status" },
    ];
    return (
        <div className="datatable-search">
            <form className={props.isSm? "d-flex flex-wrap": "d-flex"}>
                <div className={props.isSm? "w-100": ""}>
                    <Select
                        styles={props.styles}
                        options={options}
                        isSearchable={false}
                        className={`datatable-select ${props.isSm? "md-2 ml-0 w-100": "mr-md-2"}`}
                        classNamePrefix="datatable-select"
                        value={props.searchFilter}
                        onChange={props.handleUpdateSearchFilter}
                        styles={{
                            option: (provided) => ({
                                ...provided,
                                fontFamily: "neo_semibold",
                                fontSize: ".6rem",
                                color: "#9A9B9C",
                                backgroundColor: "white",
                            }),
                            menu: () => ({
                                position: "absolute",
                                border: 0,
                                zIndex: "1090",
                                backgroundColor: "white",
                                borderRadius: "5px",
                                boxShadow: "2px 2px 2px #DDDEE0",
                            }),
                        }}
                    />
                </div>
                {determineSearchFields(props.searchFilter.value, props)}
                <a
                    className={`lh-1 d-flex align-items-center justify-content-center btn btn-dropdowncolor rounded-pill text-uppercase font-p65rem font-neo-semibold ml-2 ${props.isSm? 'mt-2': ''} text-white datatable-search__btn`}
                    onClick={props.handleSubmit}
                    style={{ flexBasis: props.isSm? '27%': '' }}
                >
                    submit
                </a>
                {sortDropdownField(props)}
            </form>
        </div>
    )
}

function sortDropdownField(props) {
    let sortDropdown = '';

    if(props.isSm) {

        const sortOptions = [
            { label: "Missed Premium Date: most recent to oldest", value: "MISSED_PREMIUM_DATE", order: "DESC" },
            { label: "Missed Premium Date: oldest to most recent", value: "MISSED_PREMIUM_DATE", order: "ASC" },
            { label: "Outstanding Premium: highest to lowest", value: "PAYABLE_PREMIUM", order: "DESC" },
            { label: "Outstanding Premium: lowest to highest", value: "PAYABLE_PREMIUM", order: "ASC" },
        ];

        sortDropdown = (
            <Select
                styles={props.styles}
                options={sortOptions}
                isSearchable={false}
                className={`datatable-select mt-2 md-2 ml-0 w-100`}
                classNamePrefix="datatable-select"
                value={props.sortFilter}
                placeholder='Sort By...'
                onChange={props.handleSortDropdownFilter}
                styles={{
                    option: (provided) => ({
                        ...provided,
                        fontFamily: "neo_semibold",
                        fontSize: ".6rem",
                        color: "#9A9B9C",
                        backgroundColor: "white",
                    }),
                    menu: () => ({
                        position: "absolute",
                        border: 0,
                        zIndex: "1090",
                        backgroundColor: "white",
                        borderRadius: "5px",
                        boxShadow: "2px 2px 2px #DDDEE0",
                    }),
                }}
            />
        );
    }

    return sortDropdown;
}

function determineSearchFields(searchFilter, props) {
    let searchFields;
    switch (searchFilter) {
        case "contractHolderName":
        case "contractNumber":
            searchFields = (
                <TextInput
                    searchTerm={props.searchTerm}
                    handleUpdateSearchTerm={props.handleUpdateSearchTerm}
                    handlePressEnter={props.handlePressEnter}
                ></TextInput>
            );
            break;
        case "paidUntilDate":
            searchFields = (
                <DateRange
                    startDate={props.startDate}
                    endDate={props.endDate}
                    handleUpdateStartDate={props.handleUpdateStartDate}
                    handleUpdateEndDate={props.handleUpdateEndDate}
                >
                </DateRange>
            );
            break;
        case "status":
            searchFields = (
                <Statuses
                    statuses={props.statuses}
                    status={props.status}
                    handleUpdateStatus={props.handleUpdateStatus}
                    styles={{ flexBasis: props.isSm? '70%': '', color: 'red' }}
                ></Statuses>
            );
            break;
        default:
            searchFields = "";
    }
    return searchFields;
}

export default withWindowDimensions(Search);