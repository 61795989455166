import React, { Component, Fragment } from 'react';
import { withRouter, Route, Switch, Link } from 'react-router-dom';
import { ReportLoader } from '../commons/utilityViews.js';
import { decrypt, fixName } from '../commons/utility';
import { apiModule } from '../commons/apiCall';

import withWindowDimensions from '../commons/withWindowDimensions';
import ContactInformation from '../components/PolicyServices/contactInformation';
import PolicyInformation from '../components/PolicyServices/policyInformation';
import Declarations from '../components/PolicyServices/declarations';
import Confirmation from '../components/PolicyServices/confirmation';
import ConfirmationPrompt from '../components/PolicyServices/confirmationPrompt';
import AuthenticationMethods from '../components/PolicyServices/authenticationMethods';
import FtaAuthenticationComplete from '../components/PolicyServices/fta/ftaAuthenticationComplete';

class FundTransferAgreement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            agent: "",
            policyId: "",
            transactionId: "",
            ownerName: "",
            insuredName: "",
            baseUrl: "",
            baseCountryCode: "",
            baseMobileNum: "",
            baseEmail: "",
            authMethod: "",
            isNextDisabled: true,
            isConfirmationPromptShown: false,
            isFtaWarningPromptShown: false,
            isLoading: true,
            isSubmoduleLoading: true,
            isAllowed: "",
            policyErrorMessage: "Something went wrong. Please try again later",
        }

        const baseRoute = "/dashboard/policy-services/fta";
        if (typeof this.props.location.query !== "object") {
            this.props.history.push(baseRoute);
        } else if (Object.keys(this.props.location.query).includes("policyId")) {
            this.state.policyId = this.props.location.query.policyId;
        } else {
            this.props.history.push(baseRoute);
        }

        if (typeof this.props.location.state !== "object") {
            this.props.history.push(baseRoute);
        } else if (Object.keys(this.props.location.state.userData).includes("ownerName")) {
            const { ownerName, email, contactNum } = this.props.location.state.userData;
            this.state.ownerName = ownerName;
            this.state.baseEmail = email;
            this.state.baseMobileNum = contactNum;
        } else {
            this.props.history.push(baseRoute);
        }

        this.state.baseUrl = this.props.match.url;

        this.handleSetAuthMethod = this.handleSetAuthMethod.bind(this);

        this.handleConfirmationYes = this.handleConfirmationYes.bind(this);
        this.handleConfirmationCancel = this.handleConfirmationCancel.bind(this);
        this.handleResendFta = this.handleResendFta.bind(this);
    }

    async componentDidMount() {
        if (typeof this.props.location.query === "object" && typeof this.props.location.state === "object") {
            await this.getContractIdInfo(this.state.policyId);
        }
        await this.sendUserUpdatedInfo();
        this.setState({ isNextDisabled: false, isLoading: false, isSubmoduleLoading: false });
    }

    async getContractIdInfo(policyId) {
        await apiModule(
            "get_policy",
            {
                x_auth: decrypt(this.props.getSession().access_token),
                policyId
            },
            null
        ).then((result) => {
            const agent = result.agent.agentNumber;
            const insuredName = fixName(result.insured[0]);
            const baseCountryCode = result.contractHolder.countryPrefix || "63";
            this.setState({ baseCountryCode, insuredName, agent, baseMobileNum: this.removePrefix(baseCountryCode, this.state.baseMobileNum) });
        })
            .catch((err) => {
                console.error(err);
            });
    }

    async handleConfirmationYes() {
        const { agent, policyId, transactionId, authMethod } = this.state;

        if (agent && policyId && transactionId) {
            this.setState({ isSubmoduleLoading: true });

            const apiData = {
                transactionId,
                "contractNumber": policyId,
                "agent": {
                    "agentNumber": agent
                },
                "transactionType": "TTYP_FTA",
                "status": "WAITING FOR APPROVAL",
                "verificationType": authMethod,
            };

            const requestParams = {
                x_auth: decrypt(this.props.getSession().access_token),
                userTransactionId: transactionId
            }

            await apiModule("auth_transaction_id", requestParams, apiData, '', true
            ).then(() => {
                this.props.history.push(`${this.state.baseUrl}/authentication-success`);
            })
                .catch((err) => {
                    console.error(err);
                });
            this.setState({ isSubmoduleLoading: false });
        }
    }

    handleConfirmationCancel() {
        this.setState({ isConfirmationPromptShown: false });
    }

    handleSetAuthMethod(authMethod) {
        this.setState({ authMethod, isConfirmationPromptShown: true });
    }

    async sendUserUpdatedInfo(remarks) {
        this.setState({ isLoading: true })
        const {
            agent,
            policyId,
        } = this.state;

        let apiData = {
            "contractNumber": policyId,
            "agent": {
                "agentNumber": agent
            },
            "transactionType": "TTYP_FTA",
            "initiatedBy": decrypt(this.props.getSession().username)
        };
        if (remarks) {
            apiData["remarks"] = remarks
        }

        const requestParams = {
            x_auth: decrypt(this.props.getSession().access_token),
            "contractNumber": policyId
        }

        await apiModule("user_transactions_contract", requestParams, apiData, '', true
        ).then((result) => {
            const { transactionId } = result.data;
            this.setState({ transactionId });
        })
            .catch((err) => {
                console.error(err);
                this.setState({ isFtaWarningPromptShown: true })
                if (err.response.data.message) {
                    this.setState({ policyErrorMessage: err.response.data.message })
                } else {
                    this.setState({ policyErrorMessage: "Something went wrong. Please try again later" })
                }
                if (err.response.data.message && err.response.data.message.includes("Would you still like to proceed")) {
                    this.setState({ isAllowed: true })
                } else {
                    this.setState({ isAllowed: false })
                }
            });
        this.setState({ isLoading: false })
    }

    handleResendFta() {
        this.sendUserUpdatedInfo("Yes");
        this.setState({ isFtaWarningPromptShown: false })
    }

    removePrefix = (countryPrefix = "", contactNum = "") => {
        let newContactNum = contactNum;
        const countryPrefixLength = countryPrefix.length
        const mobilePrefix = contactNum.substr(0, countryPrefixLength);
        if (countryPrefix == mobilePrefix) {
            newContactNum = contactNum.slice(countryPrefixLength);
        }
        return newContactNum;
    }

    render() {
        if (this.state.isLoading) {
            return (
                <div className="policy-dashboard policy-dashboard--loading d-flex h-100 w-100 justify-content-center align-items-center">
                    <ReportLoader show={this.state.isLoading} />
                </div>
            );
        } else {
            return (
                <div className="policy-dashboard d-flex h-100">
                    <div className="policy-dashboard__container dashboard-container container-fluid p-0 d-flex flex-column">
                        <header className="policy-dashboard__header d-flex">
                            <span className="policy-dashboard__icon far fa-file-alt"></span>
                            <h4 className="font-neo-bold text-darkgray">Fund Transfer Agreement Form</h4>
                        </header>
                        <main className="policy-dashboard__content flex-grow-1 position-relative">
                            {
                                this.state.ownerName
                                    ? PolicyInformation(this.state.policyId, this.state.ownerName, this.state.insuredName, this.state.transactionId)
                                    : null
                            }
                            <div className="policy-progress-line">
                                <div
                                    className={"policy-progress-line__fill-color bg-headercolor policy-progress-line__fill-color--change-step2"}
                                ></div>
                            </div>
                            {
                                this.state.isSubmoduleLoading ?
                                    <article className="policy-services-submodule policy-services-submodule--loading">
                                        <ReportLoader show={this.state.isLoading} />
                                    </article>
                                    : (
                                        <Switch>
                                            <Route
                                                path={this.state.baseUrl + "/authentication-success"}
                                                render={() => <FtaAuthenticationComplete {...this.props} {...this.state} />}
                                            />
                                            <Route
                                                path={this.state.baseUrl}
                                                render={() => <AuthenticationMethods
                                                    setPrompt={(x) => this.handleSetAuthMethod(x)}
                                                    previousRoute={"/dashboard/policy-services/fta"}
                                                    isFta
                                                    toSpiel="to fill out the fund transfer agreement form"
                                                    {...this.props} {...this.state}
                                                />}
                                            />
                                        </Switch>
                                    )
                            }
                            {
                                this.state.isConfirmationPromptShown
                                    ? <div
                                        className="policy-dashboard__prompt-overlay"
                                        onClick={() => this.setState({ isConfirmationPromptShown: false })}
                                    >
                                        <ConfirmationPrompt
                                            headerIconClasses="icon-question mr-2"
                                            headerText="Confirmation"
                                            headerCloseClasses="icon-close mb-0"
                                            content={`Do you want to send it to <strong>${this.state.authMethod === "sms" ? `+${this.state.baseCountryCode} ${this.state.baseMobileNum}` : this.state.baseEmail}</strong>?`}
                                            acceptText="Yes"
                                            declineText="No"

                                            handleAcceptTrigger={this.handleConfirmationYes}
                                            handleDeclineTrigger={this.handleConfirmationCancel}
                                            handleCancelTrigger={this.handleConfirmationCancel}
                                        />
                                    </div>
                                    : null
                            }
                            {
                                this.state.isFtaWarningPromptShown
                                    ? <div className="policy-dashboard__prompt-overlay">
                                        <ConfirmationPrompt
                                            headerIconClasses="icon-question mr-2"
                                            headerText="Confirmation"
                                            headerCloseClasses="icon-close mb-0"
                                            content={this.state.policyErrorMessage}
                                            acceptText={this.state.isAllowed ? "Yes" : null}
                                            declineText={this.state.isAllowed ? "No" : null}

                                            handleAcceptTrigger={this.handleResendFta}
                                            handleDeclineTrigger={() => this.props.history.push("/dashboard/policy-services/fta")}
                                            handleCancelTrigger={() => this.props.history.push("/dashboard/policy-services/fta")}
                                        />
                                    </div>
                                    : null
                            }
                        </main>
                    </div>
                </div>
            );
        }
    }
}


export default withRouter(withWindowDimensions(FundTransferAgreement));
