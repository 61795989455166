import React, { Component } from 'react'

class LeaderboardItem extends Component {

    render() {
        const { item } = this.props;
        return item ? (
            <div id={`item-` + item.id} className="rni-leaderboard-contest-item">
                <img src={item.imgUrl} alt=""/>
                <p>{item.name}</p>
            </div>
        ) :
            null
    }
}

export default LeaderboardItem