import React, { Component } from 'react';
import { ReportLoader } from '../commons/utilityViews.js';
import { myConfig } from '../commons/config.js';
import { withRouter, Link } from 'react-router-dom';
import 'react-vertical-timeline-component/style.min.css';
// import { urlBase64 } from '../commons/utility.js';
import ReactTable from 'react-table';
import DatatableSearch from '../components/datatableSearch';
import { fixName, checkDateRange, checkSearch, renameByChannel, fixAddress, removeNull, prettifyDate, calculateAge, writeData, readAllData, encrypt, decrypt } from '../commons/utility.js';
import { apiModule, createQueryStrings } from '../commons/apiCall';
import { isManager, checkLogin, checkPermissions, getSessionInfo } from '../commons/security.js';
import DpCustomHeader, { Years } from '../components/Datepicker/customHeader';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import handleSortOrder from '../components/misc/sortOrder';
import Applications from '../components/Applications/Applications.js';
import Search from '../components/Applications/search';
import moment from "moment";
import { AppleMobilemeIcon } from 'mdi-react';
import Error from "../components/Applications/error";
import Modal from "../components/Applications/modal";
import BeneficiaryModal from "../components/Applications/beneficiaryModal";
import withWindowDimensions from '../commons/withWindowDimensions';

class ApplicationList extends Component {
    state = {
        // Permissions and page loading
        isLoading: true,
        isAllowed: false,

        // Search
        searchTerm: "",
        searchFilter: { label: "All", value: "all" },
        startDate: "",
        endDate: "",
        status: [],
        receivingStatus: [],
        planNames: [],
        statusSearch: { label: "All", value: "all" },

        // Table
        isTableLoading: true,
        showReqModal: false,
        showBeneficiaryModal: false,
        tableData: [],
        applicationNumber: "",

        // Pagination
        currentPage: 0,
        pages: 0,
        totalSize: 0,

        applications: [],

        // Utility
        errorMessage: "",
        fromDateError: false,
        toDateError: false
    }

    // Component lifecycle methods
    async componentDidMount() {
        const agentNumber = decrypt(this.props.getSession().agentNumber);
        const jwt = this.props.getSession().access_token;

        this.setState({
            isLoading: true,
            isTableLoading: true,
            isModalLoading: true,
        });

        // Fetch the applications
        try {
            const applicationResponse = await this.fetchApplications(agentNumber, this.state.currentPage, jwt);
            const applications = applicationResponse.content;
            this.setState({
                isAllowed: true,
                applications: applications,
                pages: applicationResponse.pageCount,
                totalSize: applicationResponse.totalSize,
                isLoading: false,
                isTableLoading: false,
            });
        }
        catch (error) {
            this.displayError(myConfig.GENERIC_ERROR_MESSAGE)
        }

        // Fetch the status list
        try {
            const statusList = [];
            const statusResponse = await this.fetchStatus(jwt);
            Object.keys(statusResponse).map((statusKey) => {
                const statusItem = statusResponse[statusKey];
                statusList.push({ label: statusItem, value: statusItem })
            });
            statusList.unshift({ label: "All", value: "all" })
            this.setState({
                status: statusList,
            });
        }
        catch (error) {
            this.displayError(myConfig.GENERIC_ERROR_MESSAGE)
        }

        // Fetch the receiving status list
        try {
            const receivingStatusList = [];
            const receivingStatusResponse = await this.fetchReceivingStatus(jwt);
            Object.keys(receivingStatusResponse).map((key) => {
                const receivingStatusItem = receivingStatusResponse[key];
                receivingStatusList.push({ label: receivingStatusItem, value: key })
            });
            this.setState({
                receivingStatus: receivingStatusList
            });
        }
        catch (error) {
            this.displayError(myConfig.GENERIC_ERROR_MESSAGE)
        }

        // Fetch the plan name list
        try {
            const planNamesList = [];
            const planNamesResponse = await this.fetchPlanNames(jwt);
            Object.keys(planNamesResponse).map((planNameKey) => {
                const planNameItem = planNamesResponse[planNameKey];
                planNamesList.push({ label: planNameItem, value: planNameItem })
            });
            //planNamesList.unshift({ label: "All", value: "all"})
            this.setState({
                planNames: planNamesList
            });
        }
        catch (error) {
            console.log('error', error);
            this.displayError(myConfig.GENERIC_ERROR_MESSAGE)
        }

        this.setState({ isLoading: false });
        console.log(this.props.getSession());
    }


    // DATA FETCH
    async fetchApplications(agentNumber, pageNumber, jwt, searchItem = {}) {
        const headerParams = {
            x_auth: jwt
        };
        const query = {
            contractProcessState: "APPLICATION",
            agentNumber,
            pageSize: 10,
            pageNumber: pageNumber + 1,
            ...searchItem
        };

        const response = await apiModule("get_applications", headerParams, null, createQueryStrings(query));
        let returnValue;
        if (response[0]) {
            returnValue = response[0];
        }
        else {
            returnValue = {
                content: [],
                nextPageNumber: 0,
                pageCount: 0,
                previousPageNumber: null,
                totalSize: 0
            };
        }
        return returnValue;
    }

    async fetchStatus(jwt) {
        const headerParams = { x_auth: jwt };
        const response = await apiModule("get_status", headerParams);
        return response;
    }

    async fetchReceivingStatus(jwt) {
        const headerParams = { x_auth: jwt };
        const response = await apiModule("get_receiving_status", headerParams);
        return response;
    }

    async fetchPendingRequirements(agentNumber, contractNumber, username, role, jwt) {
        const headerParams = {
            x_auth: jwt,
            username: username,
            role: role
        };
        const query = {
            contractNumber: contractNumber,
            accountManager: agentNumber
        }

        const response = await apiModule("get_pending_requirements", headerParams, null, createQueryStrings(query));
        return response;
    }

    async fetchBeneficiary(contractNumber, jwt) {
        const headerParams = {
            x_auth: jwt,
            policyId: contractNumber,
        };
        const query = {
            workflowId: "APPLICATION"
        }

        const response = await apiModule("get_policy_benefits_entitlement", headerParams, null, createQueryStrings(query));
        return response;
    }

    async fetchPlanNames(jwt) {
        const headerParams = {
            x_auth: jwt,
            accountManager: decrypt(this.props.getSession().agentNumber)
        };
        const response = await apiModule("get_plan_names", headerParams);
        return response;
    }

    // HANDLERS
    // Search-related handlers
    handleUpdateSearchTerm = async (e) => {
        this.setState({ searchTerm: e.target.value });
    }

    handleUpdateSearchFilter = async (choice) => {
        this.setState({
            searchFilter: choice,
            searchTerm: "",
            statusSearch: { label: "", value: "" }

        }, async () => {
            if (choice.value === "all") {
                this.hideError();
                await this.doSearch();
            }
        })
    }

    handleUpdateStartDate = async (startDate) => {
        this.setState({ startDate });
    }

    handleUpdateEndDate = async (endDate) => {
        this.setState({ endDate });
    }

    handleUpdateStatusSearch = async (choice) => {
        this.hideError();
        this.setState({ statusSearch: choice }, async () => { if (this.state.searchFilter.value !== 'receivingStatus') await this.doSearch() });
    }

    handleSubmit = async () => {
        await this.doSearch();
    }

    handlePressEnter = async (e) => {
        if (e.key === "Enter")
            await this.doSearch();
    }

    doSearch = async (page = 0) => {

        this.setState({ isTableLoading: true, fromDateError: false, toDateError: false });

        const agentNumber = decrypt(this.props.getSession().agentNumber);
        const jwt = this.props.getSession().access_token;

        let searchItem = {};
        let applicationResponse = {
            content: this.state.applications,
            nextPageNumber: 0,
            pageCount: this.state.pages,
            previousPageNumber: null,
            totalSize: this.state.totalSize
        };

        const filter = this.state.searchFilter.value;
        if (filter === "applicationNumber" || filter === "name") {
            searchItem[filter] = this.state.searchTerm;

            if (checkSearch(this.state.searchTerm, this.displayError, 2)) {
                let maxLength;
                if (filter === "applicationNumber") {
                    maxLength = 9;
                }
                else if (filter === "name") {
                    maxLength = 50
                }

                if (this.state.searchTerm.length <= maxLength) {
                    this.hideError();
                    applicationResponse = await this.fetchApplications(agentNumber, page, jwt, searchItem)
                    this.setState({ currentPage: page });
                }
                else {
                    this.displayError(`Invalid input. Maximum number of characters is ${maxLength}`)
                }
            }

        }
        else if (filter === "status" || filter === "productOfferingName" || filter === "receivingStatus") {
            if (this.state.statusSearch.value !== "all") {
                if (filter === "receivingStatus") {
                    if (this.state.statusSearch.value) {
                        searchItem[filter] = this.state.statusSearch.value;
                    }
                } else {
                    searchItem[filter] = this.state.statusSearch.value;
                }
            }

            if (filter === "receivingStatus") {
                const startDate = this.state.startDate;
                const endDate = this.state.endDate;
                const startDateFormatted = moment(startDate).format("YYYY-MM-DD");
                const endDateFormatted = moment(endDate).format("YYYY-MM-DD");

                console.log(`Start Date: ${startDate}`);
                console.log(`End Date: ${endDate}`);

                let dateRangeValidMessage = checkDateRange(endDate, startDate);
                console.log(`Date range error message ${dateRangeValidMessage}`)
                if ((this.state.startDate === "" || this.state.startDate === null) && (this.state.endDate === "" || this.state.endDate === null)) {
                    this.displayError("Please specify From and To date.")
                    this.setState({
                        fromDateError: true,
                        toDateError: true
                    });
                } else if ((this.state.startDate === "" || this.state.startDate === null)) {
                    this.displayError("Please specify From date.")
                    this.setState({
                        fromDateError: true
                    });
                } else if ((this.state.endDate === "" || this.state.endDate === null)) {
                    this.displayError("Please specify To date.")
                    this.setState({
                        toDateError: true
                    });
                } else if (dateRangeValidMessage.length > 0) {
                    this.displayError(dateRangeValidMessage)
                } else {
                    this.hideError();
                    searchItem = { ...searchItem, signedFromDate: startDateFormatted, signedUntilDate: endDateFormatted };
                    applicationResponse = await this.fetchApplications(agentNumber, page, jwt, searchItem);
                    this.setState({ currentPage: page });
                }
            } else {
                this.hideError();
                applicationResponse = await this.fetchApplications(agentNumber, page, jwt, searchItem);
                this.setState({ currentPage: page });
            }

        }
        else if (filter === "statusDate" || filter === "encodedDate") {
            const startDate = this.state.startDate;
            const endDate = this.state.endDate;
            const startDateFormatted = moment(startDate).format("YYYY-MM-DD");
            const endDateFormatted = moment(endDate).format("YYYY-MM-DD");

            // Validte the date range here. Sorry, I know that this is bad but crunch time beckons.
            // This sounds confusing. The function actually returns a STRING of the error message, not a boolean >:
            console.log(`Start Date: ${startDate}`);
            console.log(`End Date: ${endDate}`);

            let dateRangeValidMessage = checkDateRange(endDate, startDate);
            console.log(`Date range error message ${dateRangeValidMessage}`)
            if (dateRangeValidMessage.length > 0) {
                this.displayError(dateRangeValidMessage)
            }
            else {
                this.hideError();
                searchItem = filter === "statusDate" ? { startDate: startDateFormatted, endDate: endDateFormatted }
                    : { receivedFromDate: startDateFormatted, receivedUntilDate: endDateFormatted };
                applicationResponse = await this.fetchApplications(agentNumber, page, jwt, searchItem)
                this.setState({ currentPage: page });
            }
        }
        else {
            applicationResponse = await this.fetchApplications(agentNumber, page, jwt);
            this.setState({ currentPage: page });
        }

        const applications = applicationResponse.content;

        this.setState({
            isTableLoading: false,
            applications: applications,
            pages: applicationResponse.pageCount,
            totalSize: applicationResponse.totalSize
        });
    }

    // Table actions
    handleShowRequirements = async (data) => {
        let contractNumber = "";
        let requirementsResponse = [];

        this.setState({
            showReqModal: true,
        })

        if (data.row != null) {
            contractNumber = data.row.contractNumber;
        } else {
            contractNumber = data.contractNumber;
        }

        if (contractNumber != null || contractNumber != "") {
            console.log(contractNumber);
            const agentNumber = decrypt(this.props.getSession().agentNumber);
            console.log(`Viewing requirements as agent #${agentNumber} for contract ${contractNumber}`);
            const jwt = this.props.getSession().access_token;

            const role = decrypt(this.props.getSession().role);
            const username = decrypt(this.props.getSession().username);

            requirementsResponse = await this.fetchPendingRequirements(agentNumber, contractNumber, username, role, jwt)
            console.log(requirementsResponse);
        } else {
            console.error('EMPTY CONTRACT NUMBER');
        }

        this.setState({
            applicationNumber: contractNumber,
            tableData: requirementsResponse,
            isModalLoading: false,
        });

    }

    // Table actions
    handleShowBeneficiary = async (data) => {
        let contractNumber = "";
        let beneficiaryResponse = [];

        this.setState({
            showBeneficiaryModal: true,
        })

        if (data.row != null) {
            contractNumber = data.row.contractNumber;
        } else {
            contractNumber = data.contractNumber;
        }

        if (contractNumber != null || contractNumber != "") {
            const jwt = this.props.getSession().access_token;
            beneficiaryResponse = await this.fetchBeneficiary(contractNumber, jwt)
        }

        this.setState({
            applicationNumber: contractNumber,
            tableData: beneficiaryResponse,
            isModalLoading: false,
        });

    }

    handleHideModal = () => {
        this.setState({
            showReqModal: false,
            showBeneficiaryModal: false,
            applicationNumber: "",
            tableData: [],
            isModalLoading: true,
        })
    }

    // Pagination
    handleChangePage = async (page) => {
        /*this.setState({ currentPage: page }, async () => {
            await this.doSearch(page);
        });*/
        await this.doSearch(page);
    }

    // Helper functions
    displayError = (message) => {
        console.log(message);
        if (message) {
            this.setState({ errorMessage: `${message}` });
        }
    }

    hideError() {
        this.setState({ errorMessage: "" });
    }


    render() {
        const noteMessage = "Please be advised that the update of requirement status will take 2-3 business days upon submission.";
        const noteStyle = {
            fontSize: ".9em",
            color: "#255f8e",
        };
        const containerStyle = {
            background: "white",
            display: "inline-block",
            padding: "10px",
            border: "1px solid lightblue",
        }

        const disclaimerContent = "If you can't find the application you're looking for, please reach out to the Contact Center.";

        if (this.state.isLoading) {
            return (
                <ReportLoader show={this.state.isLoading} />
            )
        }
        else {
            return (
                <div className="dashboard-section overflow-hidden">
                    <Modal
                        applicationNumber={this.state.applicationNumber}
                        showModal={this.state.showReqModal}
                        data={this.state.tableData}
                        handleClose={this.handleHideModal}
                        isModalLoading={this.state.isModalLoading}
                    />
                    <BeneficiaryModal
                        applicationNumber={this.state.applicationNumber}
                        showModal={this.state.showBeneficiaryModal}
                        data={this.state.tableData}
                        handleClose={this.handleHideModal}
                        isModalLoading={this.state.isModalLoading}
                    />
                    {/* Note: I tried extracting this error message into its own component, unfortunately, global css does not apply
                to it for some reason :<, */}
                    {/* <Error errorMessage={this.state.errorMessage} /> */}
                    {this.state.errorMessage.length > 0 ? (
                        <div className="bg-error py-1 px-4">
                            <p className="text-white mb-0 font-neo-semibold">ERROR: {this.state.errorMessage}</p>
                        </div>) : ""
                    }

                    <div className="dashboard-container container-fluid p-3">
                        <div className="dashboard-header" style={{ display: "block" }}>
                            <h6 className="font-neo-bold text-darkgray">Application Status</h6>
                            <span style={containerStyle}>
                                <p style={noteStyle}><strong>Note: {noteMessage}</strong></p>
                                <p style={noteStyle}>{disclaimerContent}</p>
                            </span>
                            {this.props.getViewAs}
                        </div>
                        <div className="dashboard-body">
                            <div className="dashboard-datatable mt-4">
                                <Search
                                    options={this.options}
                                    searchTerm={this.state.searchTerm}
                                    searchFilter={this.state.searchFilter}
                                    startDate={this.state.startDate}
                                    endDate={this.state.endDate}
                                    page={this.state.page}
                                    pages={this.state.pages}
                                    handleUpdateSearchTerm={this.handleUpdateSearchTerm}
                                    handleUpdateSearchFilter={this.handleUpdateSearchFilter}
                                    handleUpdateStartDate={this.handleUpdateStartDate}
                                    handleUpdateEndDate={this.handleUpdateEndDate}
                                    handleUpdateStatusSearch={this.handleUpdateStatusSearch}
                                    handleSubmit={this.handleSubmit}
                                    handlePressEnter={this.handlePressEnter}
                                    status={this.state.status}
                                    receivingStatus={this.state.receivingStatus}
                                    planNames={this.state.planNames}
                                    fromDateError={this.state.fromDateError}
                                    toDateError={this.state.toDateError}
                                />

                                <div className="mt-4">
                                    <Applications
                                        applications={this.state.applications}
                                        isTableLoading={this.state.isTableLoading}
                                        handleShowRequirements={this.handleShowRequirements}
                                        handleShowBeneficiary={this.handleShowBeneficiary}
                                        pages={this.state.pages}
                                        currentPage={this.state.currentPage}
                                        totalSize={this.state.totalSize}
                                        handleChangePage={this.handleChangePage}
                                        role={this.props.getSession().role}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

    }
}


export default withRouter(withWindowDimensions(ApplicationList));
