import React, { Component } from 'react';
import {apiModule, createQueryStrings } from '../../commons/apiCall';
import LoaderGif, { LoaderGif2, LoaderGif3 } from '../../commons/loaderGif';

class TableList extends Component {
    constructor(props){
        super(props);
        this.state = {
            marginList: {
                content: [],
                nextPageNumber: 0,
                pageCount: 0,
                previousPageNumber: 0,
                totalSize: 0
            },
            pageNumber: 1,
            pageSize: 10,
            totalPages: 0,
            isLoading: null,
            visiblePages: []
        }

        
        this.getMarginList = this.getMarginList.bind(this);
        this.renderVisiblePages = this.renderVisiblePages.bind(this);
        this.changePage = this.changePage.bind(this);
    }

    componentDidMount(){
         this.getMarginList(this.props.access_token);
    }

    async getMarginList(access_token){
        let { isLoading } = this.state;
        let that = this;

        if(isLoading === null || isLoading === false){
            that.setState({
                isLoading: true,
            })
        }

        let { pageNumber, pageSize } = this.state;
        let headerParams = {
            x_auth: access_token,
        };
        let filter = [];
        filter['pageNumber'] = pageNumber;
        filter['pageSize'] = pageSize;
        let result = await apiModule("get_nbv_margin_list", headerParams , null, createQueryStrings(filter))
        .then(function (result) {
            return result;
        })
        .catch(function (err) {
            console.log(err);
        });

        this.setState({
            marginList: result,
            totalPages: result.totalSize !== null || result.totalSize !== undefined ? Math.ceil(result.totalSize / 10) : 0,
            isLoading: false
        }, function(){
            this.renderVisiblePages();
        })
    }

    renderVisiblePages(){
        let { pageNumber, totalPages } = this.state;
        let visiblePages = [];
        let pageStart = pageNumber - 4 > 0 ? pageNumber - 4 : 1;
        let pageEnd = pageNumber + 4 < totalPages ? pageNumber + 4 : totalPages;
        for(let i = pageStart, j = 0; i <= pageEnd; i++, j++){
            visiblePages[j] = i;
        }

        this.setState({
            visiblePages
        })
    }

    changePage(pageNumber){
        let { access_token } = this.props;
        this.setState({
            pageNumber: pageNumber
        }, function(){
            this.getMarginList(access_token);
        })
    }

    render(){
        let { title } = this.props;
        let { pageNumber, pageSize, marginList, totalPages, isLoading, visiblePages} = this.state;
        title = title ? title : "Outdated ANP Margins";
        let hasData = marginList && marginList.content && marginList.content.length > 0;
        const loader = <div className="nbv-table-loader"><LoaderGif2 css="ml-auto" /></div>
        let that = this;
        return(
            <div className="nbv-table-modal">
                <div className="nbv-table-container">
                    <div className="nbv-table-modal-header">
                        {title}
                    </div>
                    {isLoading === null || isLoading === true ? loader :
                        hasData ?
                        <span>
                        <div className="nbv-table-modal-body">
                            <table className="nbv-table-modal-list">
                                <thead>
                                    <tr>
                                        <th>Channel</th>
                                        <th>Region</th>
                                        <th>Product</th>
                                        <th>Last Update</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {marginList.content.map( function(item, i) {
                                        return(
                                                <tr key={i}>
                                                    <td>{item.channel}</td>
                                                    <td>{item.region}</td>
                                                    <td>{item.productOfferingName }</td>
                                                    <td>{item.lastUpdatedMonthYear}</td>
                                                </tr>
                                            )
                                    })}
                                </tbody>
                            </table>
                        </div>
                        <div className="nbv-table-modal-footer">
                            <div className="nbv-table-modal-info">
                                <span className="nbv-table-modal-footer-entries">Showing {pageNumber * pageSize - (pageSize - 1)} to {marginList.totalSize < pageNumber * pageSize ? marginList.totalSize : pageNumber * pageSize} of {marginList.totalSize} entries</span>
                                <span className="nbv-table-modal-footer-pages" id="pageButtons">
                                    {!(visiblePages.includes(1)) && <span key={1} className="nbv-table-modal-footer-page" onClick={() => that.changePage(1)}>1 .. </span>}
                                    {visiblePages.map(function (page, i){
                                        return <span key={page} className={"nbv-table-modal-footer-page" + (pageNumber == page ? " highlighted-page" : "")} onClick={() => that.changePage(page)}>{page}</span>
                                    })}
                                    {!(visiblePages.includes(totalPages)) && <span key={totalPages} className="nbv-table-modal-footer-page" onClick={() => that.changePage(totalPages)}> .. {totalPages}</span>}
                                </span>
                            </div>
                            <button className="nbv-table-modal-close-button" onClick={this.props.onClickOverlay}>Close</button>
                        </div>
                        </span>
                    : 
                    <div>
                        <p className="mis-no-data-show nbv-table-text-align-center">NO DATA TO SHOW</p>
                        <button className="nbv-table-modal-close-button nbv-table-no-data-close-button" onClick={this.props.onClickOverlay}>Close</button>
                    </div>
                    }
                </div>
                <div className="nbv-table-bg-overlay" onClick={this.props.onClickOverlay}></div>
            </div>
        )
    }
}

export default TableList;