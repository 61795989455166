import React, { Component } from 'react'
import { decrypt } from '../../../commons/utility';
import { NavLink } from 'react-router-dom';

class SidebarProfile extends Component {

    render() {

        const { name, image, channel } = this.props.session;
        const { closeSidebar } = this.props;

        return (
            <div className="user-panel not-draggable rni-profile-image-channel-container">
                <NavLink className="pull-left image not-draggable rni-profile-userpic-container" onClick={(e) => { e.stopPropagation(); closeSidebar() }} to="/rewards-and-incentives/performance">
                    <img src={image} className="img-circle not-draggable" alt="User Image" />
                </NavLink>
                <NavLink className="pull-left info not-draggable" onClick={(e) => { e.stopPropagation(); closeSidebar() }} style={{ textOverflow: 'ellipsis', width: '75%', overflow: 'hidden' }} to="/rewards-and-incentives/performance">
                    <p>{decrypt(name)}</p>
                    <span className="whiteText">{channel}</span>
                </NavLink>
                <NavLink to='/dashboard/user-info'>
                    <img src='/rni/pen.png' alt='' className="rni-edit-profile" />
                </NavLink>
            </div>
        )
    }
}


export default SidebarProfile