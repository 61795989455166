import React, { Component } from 'react'
import Chart from 'chart.js';
import { formatValues, formatDecimal } from './formatter';
import { haveValue, RESOLUTIONS } from '../../commons/misConfig';
import { isDate } from 'date-fns';


export default class LineGraph extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeChart: '',
            height: 330
        }
        this.renderChart = this.renderChart
    }

    componentDidMount() {
        this.renderChart(this.props)
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.willUpdate) {
            this.renderChart(nextProps)
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        return nextProps.willUpdate || this.props.height !== nextProps.height;
    }

    renderChart(props) {
        let { data, chartId, onChangeFilter, currPeriod, startDate, endDate } = this.props;
        let { activeChart } = this.state;
        data = props ? props.data : data;

        if (document.getElementById(chartId)) {
            if (activeChart) {
                activeChart.destroy();
                this.setState({
                    activeChart: null
                })
            }
        }

        Chart.defaults.LineWithDash = Chart.defaults.line;
        Chart.controllers.LineWithDash = Chart.controllers.line.extend({
            draw: function (ease) {
                Chart.controllers.line.prototype.draw.call(this, ease);

                if (this.chart.tooltip._active && this.chart.tooltip._active.length) {
                    var activePoint = this.chart.tooltip._active[0],
                        ctx = this.chart.ctx,
                        x = activePoint.tooltipPosition().x,
                        topY = this.chart.scales['y-axis-0'].top,
                        bottomY = this.chart.scales['y-axis-0'].bottom;

                    // draw line
                    ctx.save();
                    ctx.beginPath();
                    ctx.moveTo(x, topY);
                    ctx.lineTo(x, bottomY);
                    ctx.lineWidth = 2;
                    ctx.strokeStyle = 'rgba(0,0,0,0.1)';
                    ctx.stroke();
                    ctx.restore();
                }
            }
        });

        let ctx = document.getElementById(props.chartId).getContext('2d');
        let dataChart = new Chart(ctx, {
            type: 'LineWithDash',
            data: data,
            options: {
                legendCallback: function (chart) {
                    let text = [];
                    text.push('<ul class="' + chart.id + '-legend customLegend">');
                    for (let i = 0; i < chart.data.datasets.length; i++) {
                        if (chart.data.length <= 0) {
                            break;
                        }

                        if (chart.data.datasets[i].data.length <= 0) {
                            continue;
                        }

                        let lineStyle = '<div style="background-color:' + chart.data.datasets[i].backgroundColor + ';" class="legend-solid-line"></div>';

                        text.push('<li class="legendTransition ">' + lineStyle + '<p>');
                        if (chart.data.datasets[i].label) {
                            text.push(chart.data.datasets[i].label);
                        }
                        text.push('</p></li>');
                    }
                    text.push('</ul>');
                    return text.join("");

                },
                responsive: true,
                maintainAspectRatio: false,
                title: {
                    display: false,
                },
                tooltips: {
                    mode: 'index',
                    intersect: false,
                    enabled: false,
                    custom: function (tooltipModel) {
                        let hasActual, hasTarget, hasBest;
                        let actual, target, best;

                        // Tooltip Element
                        let tooltipEl = document.getElementById('chartjs-tooltip-linegraph');
                        // Create element on first render
                        if (!tooltipEl) {
                            let graphContainer = document.getElementById(props.chartId + '-canvas')
                            tooltipEl = document.createElement('div');
                            tooltipEl.id = 'chartjs-tooltip-linegraph';
                            tooltipEl.innerHTML = '<table></table>';
                            graphContainer.appendChild(tooltipEl);
                        }

                        // Hide if no tooltip
                        if (tooltipModel.opacity === 0) {
                            tooltipEl.style.opacity = 0;
                            return;
                        }

                        // Set caret Position
                        tooltipEl.classList.remove('above', 'below', 'no-transform');
                        if (tooltipModel.yAlign) {
                            tooltipEl.classList.add(tooltipModel.yAlign);
                        } else {
                            tooltipEl.classList.add('no-transform');
                        }

                        function getBody(bodyItem) {
                            return bodyItem.lines;
                        }

                        // Set Text
                        if (tooltipModel.body) {
                            let titleLines = tooltipModel.title || [];
                            let bodyLines = tooltipModel.body.map(getBody);

                            let innerHtml = '<thead>';

                            titleLines.forEach(function (title) {
                                innerHtml += '<tr><th>' + title + '</th></tr>';
                            });
                            innerHtml += '</thead><tbody>';

                            bodyLines.forEach(function (body, i) {
                                let colors = tooltipModel.labelColors[i];
                                let style = 'background-color:' + colors.backgroundColor;
                                style += '; border-color:' + colors.borderColor;
                                style += '; padding: 0.25px 8px';
                                style += '; margin-right: 2px';
                                style += '; border-width: 1px;';
                                style += '; border-style: solid;';
                                let span = '<span style="' + style + '"></span>';
                                // innerHtml += '<tr><td>' + span + body + '</td></tr>';
                                innerHtml += '<tr><td>' + span + body[0].label + ': <b style="font-weight:800;color:' + body[0].color + '">' + body[0].value + '</b></td></tr>';
                                if (body[0].label.toUpperCase().includes("ACTUAL")) {
                                    hasActual = true;
                                    actual = tooltipModel.dataPoints[i].yLabel;
                                }

                                if (body[0].label.toUpperCase().includes("TARGET")) {
                                    hasTarget = true;
                                    target = tooltipModel.dataPoints[i].yLabel;
                                }

                                if (body[0].label.toUpperCase().includes("BEST")) {
                                    hasBest = true;
                                    best = tooltipModel.dataPoints[i].yLabel;
                                }
                            });

                            if (hasActual && hasTarget) {
                                let difference = actual - target;
                                let color = difference >= 0 ? '#3DA556' : '#E4003A';
                                innerHtml += '<tr><td>Actual vs. Target: ';
                                innerHtml += '<span style="color: ' + color + '">' + formatValues(difference) + '</span>';
                                innerHtml += '</td></tr>';
                            }

                            if (hasActual && hasBest) {
                                let difference = actual - best;
                                let color = difference >= 0 ? '#3DA556' : '#E4003A';
                                innerHtml += '<tr><td>Actual vs. Best: ';
                                innerHtml += '<span style="color: ' + color + '">' + formatValues(difference) + '</span>';
                                innerHtml += '</td></tr>';
                            }

                            innerHtml += '</tbody>';

                            var tableRoot = tooltipEl.querySelector('table');
                            tableRoot.innerHTML = innerHtml;
                        }

                        let offsetX = 0, offsetY = 0;
                        // `this` will be the overall tooltip
                        var position = this._chart.canvas.getBoundingClientRect();

                        if (tooltipModel.caretX + tooltipModel.width > this._chart.width) {
                            offsetX = (tooltipModel.caretX + tooltipModel.width) - this._chart.width;
                        }

                        if (tooltipModel.caretY + tooltipModel.height > this._chart.height) {
                            offsetY = (tooltipModel.caretY + tooltipModel.height) - this._chart.height;
                        }

                        // Display, position, and set styles for font
                        tooltipEl.style.opacity = 'inherit';
                        tooltipEl.style.position = 'absolute';
                        // tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX - offsetX + 'px';
                        // tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY - offsetY + 'px';
                        tooltipEl.style.left = tooltipModel.caretX - offsetX + 'px';
                        tooltipEl.style.top = tooltipModel.caretY - offsetY + 'px';
                        // tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
                        tooltipEl.style.fontFamily = 'neo_regular'
                        tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
                        tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
                        tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
                        tooltipEl.style.borderRadius = '10px';
                        tooltipEl.style.pointerEvents = 'none';
                        tooltipEl.style.backgroundColor = 'rgba(0,0,0,0.8)';
                        tooltipEl.style.color = 'rgba(255,255,255,1)';
                        tooltipEl.style.textAlign = 'left';
                        tooltipEl.style.zIndex = '1';
                    },
                    callbacks: {
                        label: function (toolTipItem, data) {
                            let color = toolTipItem.yLabel >= 0 ? '#FFFFFF' : '#E4003A';
                            return {
                                label: data.datasets[toolTipItem.datasetIndex].label,
                                value: formatValues(toolTipItem.yLabel),
                                color: color
                            };
                        },
                    }
                },
                hover: {
                    mode: 'x-axis',
                    intersect: false
                },
                scales: {
                    yAxes: [{
                        display: true,
                        ticks: {
                            fontFamily: 'neo_regular',
                            padding: 7,
                            callback: function (label, index, labels) {
                                return formatValues(label);
                            },
                            min: 0

                        },
                        scaleLabel: {
                            display: false
                        },
                        gridLines: {
                            drawTicks: false,
                        }

                    }],
                    xAxes: [{
                        gridLines: {
                            display: false
                        },
                        ticks: {
                            fontFamily: 'neo_regular',
                            autoSkip: true,
                            maxRotation: 0,
                            maxTicksLimit: window.screen.width <= RESOLUTIONS.sm ? 8 : 31,
                        }
                    }]
                },
                legend: {
                    display: false,
                    fontSize: '50px',
                    labels: {
                        filter: function (item, chart) {
                            if (chart.datasets[item.datasetIndex].data.length <= 0) {
                                return !item.text.includes(item.text);
                            }
                            return item;
                        }
                    }
                },
                // onClick: function (event, activeElements) {
                //     if (activeElements instanceof Array && activeElements.length !== 0) {
                //         let chartData = activeElements[0]['_chart'].config.data;
                //         let chartId = activeElements[0]['_index'];
                //         let selectedStartDate = new Date(new Date(startDate).getFullYear(), chartId, 1);
                //         let selectedEndDate = new Date(new Date(endDate).getFullYear(), (chartId + 1), 0);

                //         if (currPeriod.value.toUpperCase() === 'YTD') {
                //             onChangeFilter('period', { value: 'DATE RANGE', label: 'DATE RANGE' }, true, selectedStartDate, selectedEndDate);
                //         }
                //     }
                // }
            }
        });

        let legendContainer = document.getElementById('line-legend-id');
        legendContainer.innerHTML = dataChart.generateLegend();
        let legendItems = legendContainer.querySelectorAll('li');

        for (let i = 0; i < dataChart.legend.legendItems.length; i++) {
            legendItems[i].addEventListener("click", function (event) {
                event = event || window.event;

                let target = event.target || event.srcElement;
                while (target.nodeName !== 'LI') {
                    target = target.parentElement;
                }

                dataChart.legend.options.onClick.call(dataChart, event, dataChart.legend.legendItems[i]);
                if (!dataChart.legend.legendItems[i].hidden) {
                    target.classList.remove('grayscale');
                } else {
                    target.classList.add('grayscale');
                }
            }, false);
        }

        this.setState({
            activeChart: dataChart
        })
    }

    hasData(datasets) {
        if (datasets instanceof Object) {
            for (let i = 0; i < datasets.length; i++) {
                if (datasets[i].data && datasets[i].data.length) {
                    return true;
                }
            }
        }

        return false;
    }

    render() {
        const { chartId, data, currPeriod, willUpdate } = this.props;
        var height = this.props.height;
        const dataHasValue = this.hasData(data.datasets);
        const isDateRange = currPeriod ? currPeriod.value.toUpperCase().includes("DATE RANGE") : false;
        // console.log(this.props.alt);
        return (
            <div id={chartId + "-canvas"} className={chartId + "-canvas"} style={{ position: 'relative', height: height ? height : '330px' }}>
                <div className="line-legend" id="line-legend-id" />
                {/* {dataHasValue ? '' : <p className="mis-no-data-show">{isDateRange ? 'DATE RANGE NOT APPLICABLE' : 'NO DATA TO SHOW'}</p>} */}
                {dataHasValue ? '' : <p className="mis-no-data-show">NO DATA TO SHOW</p>}
                {/* <canvas id={chartId} style={{ opacity: dataHasValue && !isDateRange? '1' : '0' }} /> */}
                <canvas alt={this.props.alt} id={chartId} style={{ opacity: dataHasValue ? '1' : '0' }} />
            </div>
        )
    }
}