import React from "react";

const DropdownOption = (props) => {
  const { innerProps, innerRef } = props;

  return (
    <div ref={innerRef} {...innerProps} className="policy-dropdown-option d-flex flex-row p-1">
      <div className="policy-dropdown-option__flag-container"><img src={`/img/flags/${props.data.label}.jpg`} className="policy-dropdown-option__flag" /></div>
      <span className="pl-2">{props.data.label}</span>
      <span className="ml-auto pr-2">+{props.data.value}</span>
    </div>
  );
};

export default DropdownOption;
