import React, { Component } from 'react'
import MiniLoading from './miniLoading';
import { haveValue } from '../../../commons/misConfig';
import SearchBulletin from './searchBulletin';
import SearchContest from './searchContest';
import SearchUser from './searchUser';
import SearchHeader from './searchHeader';
import { SEARCH_TAB } from '../commons/service';

export default class SearchAll extends Component {

    constructor(props) {
        super(props);

    }

    render() {
        const { searchResult, isLoading } = this.props;
        let bulletinResult = {
            content: searchResult.bulletinResult ? searchResult.bulletinResult.bulletins : [],
            size: searchResult.bulletinResult ? searchResult.bulletinResult.size : 0
        }
        let contestResult = {
            content: searchResult.contestResult ? searchResult.contestResult.contests : [],
            size: searchResult.contestResult ? searchResult.contestResult.size : 0
        }
        let userResult = {
            content: searchResult.userResult ? searchResult.userResult.users : [],
            size: searchResult.userResult ? searchResult.userResult.size : 0
        }
        return !isLoading ? (
            <div>
                {/* !haveValue(bulletinResult.content) && */ !haveValue(contestResult.content) && !haveValue(userResult.content) ? <div>
                    <p style={{ textAlign: 'center', fontSize: '2rem', color: 'darkgray', fontFamily: 'neo_regular' }}>Nothing found</p>
                </div> : ""}
                {/* haveValue(bulletinResult.content) ? <SearchHeader count={bulletinResult.size} clickedTab={() => { this.props.clickedTab(SEARCH_TAB.BULLETIN) }} src="/rni/bulletin.svg" label="Bulletin" /> : null */}
                {/* haveValue(bulletinResult.content) ? <SearchBulletin searchResult={bulletinResult} /> : null */}
                {haveValue(contestResult.content) ? <SearchHeader count={contestResult.size} clickedTab={() => { this.props.clickedTab(SEARCH_TAB.CONTEST) }} src="/rni/contest.svg" label="Contest" /> : null}
                {haveValue(contestResult.content) ? <SearchContest searchResult={contestResult} /> : null}
                {haveValue(userResult.content) ? <SearchHeader count={userResult.size} clickedTab={() => { this.props.clickedTab(SEARCH_TAB.USER) }} src="/rni/leaderboard.svg" label="Leaderboard" /> : null}
                {haveValue(userResult.content) ? <SearchUser searchResult={userResult} /> : null}
            </div>
        ) : <MiniLoading />
    }
}
