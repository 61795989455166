import React, { Component, Fragment } from 'react';
import { apiModule } from '../../commons/apiCall';
import Select from 'react-select';
import Form from './form.js';
import FormVerification from './formVerification';
import { myConfig } from '../../commons/config';
import AuthLoader from '../../commons/authLoader';
import { LoaderGif2 } from '../../commons/loaderGif';
import FtaModal from './ftaModal';

class ReviewTransaction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOnPolicyAgreementScreen: false,
      isOnDeclarationAndAgreementScreen: false,
      isOnGeneralDeclarationScreen: false,
      isOnFtaFormScreen: false,
      isOnVerificationScreen: false,
      isOnConfirmationScreen: false,

      isLoading: false,
      isFormSubmitted: false,
      isSubmitting: false,

      areButtonsDisabled: false,
      showReloadModal: false,
      showFtaFormModal: false,

      messages: [],
      declarationsAndAgreementMessages: [],
      generalDeclarationMessages: [],
      ftaRestrictionMessages: [],
      userHasAgreed: false,
      requiredCoDepositor: false,

      bankList: [],

      bankTypeList: [],

      bankCurrencyList: [],

      /* Actual FTA Form variables  */
      policyNumber: "",
      bank: "",
      bankAddress: "",
      bankAccountFirstName: "",
      bankAccountLastName: "",
      bankAccountMiddleInitial: "",
      bankAccountNumber: "",
      bankAccountNumberConfirmation: "",
      bankCurrency: "",
      bankType: "",
      bankCoDepositorName: "",
      applyToAllPolicies: false,
      noMiddleName: false,

      bankProofRawFiles: [],
      bankProofBase64Files: [],

      policyAmendmentRawFiles: [],
      policyAmendmentBase64Files: [],

      validIdentificationRawFiles: [],
      validIdentificationBase64Files: [],
      /* Actual FTA Form variables  */

      applicationStatus: "",
    }
    this.handleTextChanges = this.handleTextChanges.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleRadioChange = this.handleRadioChange.bind(this);
    this.submitFtaForm = this.submitFtaForm.bind(this);
    this.handleMiddleNameCheckbox = this.handleMiddleNameCheckbox.bind(this);
    if (Object.keys(this.props.policyInfo).length === 0) {
      this.props.history.push(this.props.baseUrl);
    }
  }

  componentDidMount() {
    window.onpopstate = () => {
      this.props.isFtaForm(false)
    }
    this.fetchData();
  }

  async fetchData() {
    this.setState({ isLoading: true })
    try {
      await apiModule("get_terms_and_conditions", { consentCode: "FTA_AGRMT" }, null, '', true)
        .then((result) => {
          console.log("SUCCESS FTA_AGRMT : ", result.data)
          this.setState({ messages: result.data })
        })
        .catch((err) => {
          console.error(err);
        });

      await apiModule("get_terms_and_conditions", { consentCode: "FTA_DECL_AGRMT" }, null, '', true)
        .then((result) => {
          console.log("SUCCESS FTA_DECL_AGRMT : ", result.data)
          this.setState({ declarationsAndAgreementMessages: result.data })
        })
        .catch((err) => {
          console.error(err);
        });

      await apiModule("get_terms_and_conditions", { consentCode: "FTA_GEN_DECL" }, null, '', true)
        .then((result) => {
          console.log("SUCCESS FTA_GEN_DECL : ", result.data)
          this.setState({ generalDeclarationMessages: result.data })
        })
        .catch((err) => {
          console.error(err);
        });

      await this.fetchFtaFields();
    } catch {
      this.setState({ showReloadModal: true })
    }
    this.setState({ isLoading: false })
  }

  async fetchFtaFields() {
    await apiModule("get_terms_and_conditions", { consentCode: "FTA_RESTRICTION" }, null, '', true)
      .then((result) => {
        this.setState({ ftaRestrictionMessages: result.data })
      })
      .catch((err) => {
        console.error(err);
      });


    let bankList = await apiModule('get_act_datalist', { dataListId: "bankNames" });
    bankList = Object.keys(bankList).map(key => {
      return {
        value: key, label: bankList[key]
      }
    })
    this.setState({
      bankList,
    });

    let bankCurrencyList = await apiModule('get_act_datalist', { dataListId: "currency" });
    bankCurrencyList = Object.keys(bankCurrencyList).map(key => {
      return {
        value: key, label: bankCurrencyList[key]
      }
    })
    this.setState({
      bankCurrencyList,
    });

    let bankTypeList = await apiModule('get_act_datalist', { dataListId: "bankAccountTypes" });
    bankTypeList = Object.keys(bankTypeList).map(key => {
      return {
        value: key, label: bankTypeList[key]
      }
    })
    this.setState({
      bankTypeList,
    });
  }

  async submitFtaForm() {
    this.setState({
      isFormSubmitted: true,
    })

    const { transactionNumber, policyNumber } = this.props.policyInfo

    const transactionId = transactionNumber;
    if (this.handleFormValidation()) {
      if (transactionId) {
        this.setState({ isSubmitting: true });

        const apiData = {
          "transactionType": "TTYP_FTA",
          "contractNumber": policyNumber,
          "isApplyToAllPolicies": this.state.applyToAllPolicies ? "Y" : "N",
          "bank": {
            "name": this.state.bank.value,
            "branchName": this.state.bankAddress,
            "accountLastName": this.state.bankAccountLastName,
            "accountMiddleName": this.state.bankAccountMiddleInitial,
            "accountFirstName": this.state.bankAccountFirstName,
            "accountNumber": this.state.bankAccountNumberConfirmation,
            "currency": this.state.bankCurrency.value,
            "type": this.state.bankType.value,
            "coDepositorName": this.state.bankCoDepositorName,
          },
          "documents": [
            ...this.state.bankProofBase64Files,
            ...this.state.validIdentificationBase64Files,
            ...this.state.policyAmendmentBase64Files,
          ]
        };

        const requestParams = {
          transactionId
        }

        await apiModule("user_transactions_id", requestParams, apiData, '', true
        ).then(() => {
          this.handleScreenChanger("isOnDeclarationAndAgreementScreen", 2, false)
          this.setState({ isSubmitting: false });
        })
          .catch((err) => {
            console.error(err);
            this.setState({ isSubmitting: false });
          });
      }

    } else {
      this.scrollToFieldError();
      this.setState({ showFtaFormModal: true });
    }
  }

  scrollToFieldError() {
    const fields = [
      "bank",
      "bankAddress",
      "bankAccountLastName",
      "bankAccountFirstName",
      "bankAccountMiddleInitial",
      "bankAccountName",
      "bankAccountNumber",
      "bankAccountNumberConfirmation",
      "bankCurrency",
      "bankType",
      "bankCoDepositorName",
    ]
    const formValue = this.handleFormValidation("value");
    const firstInvalidField = fields.find((key) => formValue[key].hasError);
    if (firstInvalidField) {
      let scroll = document.createElement("span");
      scroll.setAttribute("id", "scrollTemp")
      document.getElementById("js-fta-form").elements[firstInvalidField].parentElement.before(scroll);
      document.getElementById("scrollTemp").scrollIntoView();
      document.getElementById("scrollTemp").remove();
    }
  }

  async handleReviewTransaction() {
    console.log("handleReviewTransaction has been triggered")

    await apiModule("review_transactions", { transactionId: this.props.policyInfo.transactionNumber }, null, '', true)
      .then((result) => {
        console.log(result)
      })
      .catch((err) => {
        console.error(err);
      });
  }

  async handleConfirmationApi() {
    this.setState({ isLoading: true })
    const apiData = {
      remarks: "ACCEPT",
      transactionId: this.props.policyInfo.transactionNumber
    }
    await apiModule("user_transactions_id", { transactionId: this.props.policyInfo.transactionNumber }, apiData, '', true)
      .then((result) => {
        this.setState({ applicationStatus: result.data.data.bank.endorsementStatus });
        this.handleScreenChanger("isOnConfirmationScreen", 3)
        console.log("STATUS : ", result.data.data.bank.endorsementStatus);
      })
      .catch((err) => {
        console.log(err)
        this.setState({ applicationStatus: "ERROR" })
      })
    this.setState({ isLoading: false })
  }

  handleClick(type) {
    this.setState({ areButtonsDisabled: true })
    this.props.handleClick(type);
  }

  handleMiddleNameCheckbox = () => {
    this.setState({ noMiddleName: !this.state.noMiddleName })
  }

  handleScreenChanger(screenName, progress, hasAgreed) {
    let screenList = ["isOnPolicyAgreementScreen",
      "isOnFtaFormScreen",
      "isOnDeclarationAndAgreementScreen",
      "isOnGeneralDeclarationScreen",
      "isOnVerificationScreen",
      "isOnConfirmationScreen"]


    screenList.map((item) => {
      if (item == screenName) {
        this.setState({ [item]: true })
      } else {
        this.setState({ [item]: false })
      }
    })

    if (screenName == "isOnFtaFormScreen") {
      this.props.isFtaForm(true);
    } else if (screenName == "isOnDeclarationAndAgreementScreen") {
      this.props.isFtaForm(false);
    }

    if (progress) {
      this.props.setProgress(progress);
    }

    if (hasAgreed == true || hasAgreed == false) {
      this.setState({ userHasAgreed: hasAgreed })
    }
  }

  goToConfirmationScreen = () => {
    this.handleConfirmationApi();
  }

  showAuthConfirmationHeader = () => (
    <Fragment>
      <p>Dear {this.props.policyInfo.policyOwner},</p>
      <p>Did you initiate the request for Fund Transfer Agreement Form?</p>
    </Fragment>
  );

  handleFormValidation = (type = "") => {
    const bankAccountNameString = this.state.bankAccountLastName + this.state.bankAccountFirstName + this.state.bankAccountMiddleInitial;
    const bank = { value: this.state.bank, };
    const bankAddress = { value: this.state.bankAddress, length: this.state.bankAddress.length >= 2 };
    const bankAccountLastName = { value: this.state.bankAccountLastName, length: this.state.bankAccountLastName.length >= 2 };
    const bankAccountFirstName = { value: this.state.bankAccountFirstName, length: this.state.bankAccountFirstName.length >= 2 };
    const bankAccountMiddleInitial = { value: this.state.bankAccountMiddleInitial || this.state.noMiddleName, length: this.state.bankAccountMiddleInitial.length >= 2 || this.state.noMiddleName };
    const bankAccountName = { value: bankAccountNameString, length: bankAccountNameString.length <= 160 };
    const bankAccountNumber = { value: this.state.bankAccountNumber, length: this.state.bankAccountNumber.length >= 2 };
    const bankAccountNumberConfirmation = { value: this.state.bankAccountNumberConfirmation };
    const bankAccountNumberMatch = { value: this.state.bankAccountNumber === this.state.bankAccountNumberConfirmation };
    const bankCurrency = { value: this.state.bankCurrency };
    const bankType = { value: this.state.bankType };
    const bankCoDepositorName = { value: this.state.bankCoDepositorName || !this.state.requiredCoDepositor, length: this.state.bankCoDepositorName.length >= 2 || !this.state.requiredCoDepositor };
    const bankProofRawFiles = { value: this.state.bankProofRawFiles.length > 0 };
    let formValidation = {
      bank,
      bankAddress,
      bankAccountLastName,
      bankAccountFirstName,
      bankAccountMiddleInitial,
      bankAccountName,
      bankAccountNumber,
      bankAccountNumberConfirmation,
      bankAccountNumberMatch,
      bankCurrency,
      bankType,
      bankCoDepositorName,
      bankProofRawFiles,
    };
    Object.keys(formValidation).forEach(key => {
      formValidation[key].hasError = !formValidation[key].value || (formValidation[key].hasOwnProperty("length") ? !formValidation[key].length : false)
    })

    console.log("check validation", formValidation);
    switch (type) {
      case "value":
        return formValidation;
      default:
        return Object.keys(formValidation).every(element => !formValidation[element].hasError);
    }
  }

  showFtaFormScreen = () => {
    return (
      <Form
        {...this.state}
        handleTextChanges={this.handleTextChanges}
        handleSelectChange={this.handleSelectChange}
        handleRadioChange={this.handleRadioChange}
        handleMiddleNameCheckbox={this.handleMiddleNameCheckbox}
        restrictionMessages={this.state.ftaRestrictionMessages}
        policyNum={this.props.policyInfo.policyNumber}
        policyCurrency={this.props.policyInfo.policyCurrency}
        formValue={this.handleFormValidation("value")}
        handleFileDataProofChange={this.handleFileDataProofChange}
        showPolicies={this.props.policyInfo.policies}
      />
    )
  };

  showVerificationScreen = () => {
    return (
      <FormVerification
        {...this.state}
        transactNo={this.props.policyInfo.transactionNumber}
        policyNumber={this.props.policyInfo.policyNumber}
        policyOwner={this.props.policyInfo.policyOwner}
      />
    )
  };

  showConfirmationScreen = () => {
    return (
      <Fragment>
        {this.state.applicationStatus == "Pending"
          ? <div className="review-transaction__confirmation">
            <span className="policy-services-submodule__auth-check-icon icon-check-circle"></span>
            <p className="mb-0">Transaction has been successfully submitted and is pending for approval.
              Your Request will be reflected in the system. You may register or log in to <a href={myConfig.ALLIANZ_TOUCH_URL} target='blank'>Allianz Touch</a> to monitor your transaction.</p>
          </div>
          : <div className="review-transaction__confirmation">
            <span className="policy-services-submodule__auth-check-icon icon-check-circle"></span>
            <p className="mb-0">Transaction has been submitted. Your Request will be reflected in the system</p>
          </div>
        }
      </Fragment>
    )
  }

  handleAgreementCheckbox = () => {
    this.setState({ userHasAgreed: !this.state.userHasAgreed })
  }

  showMessage = (article) => {
    return (
      <Fragment>
        {(this.state.isOnDeclarationAndAgreementScreen ? <p><b>Declarations and Agreements</b></p> : null)}
        {(this.state.isOnGeneralDeclarationScreen ? <p><b>General Declaration</b></p> : null)}
        <div className="review-transaction__message">
          {article.map((item) => {
            return (
              <p key={item.sortingNumber} className="text-justify">
                {(item.sortingNumber === 3 && this.state.isOnPolicyAgreementScreen
                  ? <b>{item.printDescription}</b>
                  : item.printDescription)}
              </p>
            )
          })}
        </div>
        <div className="review-transaction__metric-checkbox">
          <span className="review-transaction__metric-span" >
            <input type="checkbox" checked={this.state.userHasAgreed} />
            <label> I agree </label>
            <span className="review-transaction__checkmark" onClick={this.handleAgreementCheckbox}></span>
          </span>
        </div>
      </Fragment>
    )
  };

  handleTextChanges(e) {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleRadioChange(value) {
    this.setState({ applyToAllPolicies: value })
  }

  handleFileDataProofChange = (values, type) => {
    switch (type) {
      case 'bankOwnership':
        this.setState({
          bankProofRawFiles: [...values.proofRawFiles],
          bankProofBase64Files: [...values.proofBase64Files]
        }, () => {
          console.log('bank proof files: ', this.state.bankProofRawFiles, this.state.bankProofBase64Files);
        });
        break;
      case 'policyAmendmentForm':
        this.setState({
          policyAmendmentRawFiles: [...values.proofRawFiles],
          policyAmendmentBase64Files: [...values.proofBase64Files]
        }, () => {
          console.log('policy amendment files: ', this.state.policyAmendmentRawFiles, this.state.policyAmendmentBase64Files);
        });
        break;
      case 'validIdentification':
        this.setState({
          validIdentificationRawFiles: [...values.proofRawFiles],
          validIdentificationBase64Files: [...values.proofBase64Files]
        }, () => {
          console.log('valid identification files: ', this.state.validIdentificationRawFiles, this.state.validIdentificationBase64Files);
        });
        break;
    }
  }

  handleSelectChange = (object, stateName) => {
    this.setState({ [stateName]: object });
    if (stateName == "bankType") {
      switch (object.value) {
        case "JOINT_BANK_ACCT_OR":
        case "JOINT_BANK_ACCT_AND_OR":
        case "IN_TRUST_FOR":
          this.setState({ requiredCoDepositor: true });
          break;
        default:
          this.setState({ requiredCoDepositor: false, bankCoDepositorName: "" });
      }
    }
  }

  render() {
    let nextButtonClassName = "review-transaction__button review-transaction__button--next ml-2" + (this.state.userHasAgreed ? "" : " review-transaction__button--disabled");
    let backButtonClassName = "review-transaction__button review-transaction__button--next mr-2";
    if (this.state.isLoading) {
      return (
        <section className="client-approval__user-area client-approval__user-area--loading">
          <div className="client-approval__loader"><LoaderGif2 /></div>
          <span className="client-approval__loader-text">Loading...</span>
        </section>
      )
    }
    return (
      <section className="review-transaction">
        <header className="review-transaction__header">
          {
            !this.state.isOnPolicyAgreementScreen &&
            !this.state.isOnFtaFormScreen &&
            !this.state.isOnDeclarationAndAgreementScreen &&
            !this.state.isOnGeneralDeclarationScreen &&
            !this.state.isOnVerificationScreen &&
            !this.state.isOnConfirmationScreen &&
            this.showAuthConfirmationHeader()
          }
          {
            this.state.isOnPolicyAgreementScreen &&
            this.showMessage(this.state.messages)
          }
          {
            this.state.isOnFtaFormScreen &&
            this.showFtaFormScreen()
          }
          {
            this.state.isOnDeclarationAndAgreementScreen &&
            this.showMessage(this.state.declarationsAndAgreementMessages)
          }
          {
            this.state.isOnGeneralDeclarationScreen &&
            this.showMessage(this.state.generalDeclarationMessages)
          }
          {
            this.state.isOnVerificationScreen &&
            this.showVerificationScreen()
          }
          {
            this.state.isOnConfirmationScreen &&
            this.showConfirmationScreen()
          }
        </header>
        <div className="review-transaction__buttons d-flex justify-content-between">
          {
            !this.state.isOnPolicyAgreementScreen &&
            !this.state.isOnFtaFormScreen &&
            !this.state.isOnDeclarationAndAgreementScreen &&
            !this.state.isOnGeneralDeclarationScreen &&
            !this.state.isOnVerificationScreen &&
            !this.state.isOnConfirmationScreen &&
            <Fragment>
              <button
                disabled={this.state.areButtonsDisabled}
                onClick={() => !this.state.areButtonsDisabled && this.handleClick("uninitiated")}
                className={"review-transaction__button review-transaction__button--uninitiated" + (
                  this.state.areButtonsDisabled ? " review-transaction__button--disabled" : ""
                )}
              >No</button>
              <button
                disabled={this.state.areButtonsDisabled}
                onClick={() => this.handleScreenChanger("isOnPolicyAgreementScreen", 1)}
                className={"review-transaction__button review-transaction__button--next-auth" + (
                  this.state.areButtonsDisabled ? " review-transaction__button--disabled" : ""
                )}
              >Yes</button>
            </Fragment>
          }
          {
            this.state.isOnPolicyAgreementScreen &&
            <Fragment>
              <button className={backButtonClassName} onClick={() => this.handleScreenChanger("")}>Back</button>
              <button className={nextButtonClassName} onClick={() => this.handleScreenChanger("isOnFtaFormScreen", 2, true)} disabled={!this.state.userHasAgreed}>Next</button>
            </Fragment>
          }
          {
            this.state.isOnFtaFormScreen &&
            <Fragment>
              <button className={backButtonClassName} onClick={() => this.handleScreenChanger("isOnPolicyAgreementScreen", 1)}>Back</button>
              <button className={`${nextButtonClassName} ${this.state.isSubmitting ? "btn-loading pes-none" : ""}`} onClick={this.submitFtaForm}>
                {this.state.isSubmitting ? <AuthLoader width="60" /> : "Next"}
              </button>
            </Fragment>
          }
          {
            this.state.isOnDeclarationAndAgreementScreen &&
            <Fragment>
              <button className={backButtonClassName} onClick={() => this.handleScreenChanger("isOnFtaFormScreen", 2, true)}>Back</button>
              <button className={nextButtonClassName} onClick={() => this.handleScreenChanger("isOnGeneralDeclarationScreen", 2, false)} disabled={!this.state.userHasAgreed}>Next</button>
            </Fragment>
          }
          {
            this.state.isOnGeneralDeclarationScreen &&
            <Fragment>
              <button className={backButtonClassName} onClick={() => this.handleScreenChanger("isOnDeclarationAndAgreementScreen", 2, true)}>Back</button>
              <button className={nextButtonClassName} onClick={() => this.handleScreenChanger("isOnVerificationScreen", 2, false)} disabled={!this.state.userHasAgreed}>Next</button>
            </Fragment>
          }
          {
            this.state.isOnVerificationScreen &&
            <Fragment>
              <button className={backButtonClassName} onClick={() => this.handleScreenChanger("isOnFtaFormScreen", 2, true)}>Back</button>
              <button className="review-transaction__button review-transaction__button--next ml-2" onClick={this.goToConfirmationScreen}>Submit</button>
            </Fragment>
          }

        </div>
        {this.state.showReloadModal &&
          <FtaModal
            text="Something went wrong. Please try again."
            close={() => {
              this.setState({ showReloadModal: false });
              this.fetchData();
            }} />
        }
        {this.state.applicationStatus == "ERROR" &&
          <FtaModal
            text="Something went wrong. Please try again."
            close={() => {
              this.setState({ applicationStatus: "" });
            }} />
        }
        {this.state.showFtaFormModal &&
          <FtaModal
            close={() => {
              this.setState({ showFtaFormModal: false });
            }} />
        }
      </section>
    );
  }
}

export default ReviewTransaction;