import React from "react";

const sortOrder = ({ sortByField, state }) => {
    let sortOrder = ""
    if (sortByField == state.sortByField) {
        if (state.sortOrder == 'ASC') {
            sortOrder = 'DESC'
        } else {
            sortOrder = 'ASC'
        }
    } else {
        sortOrder = 'DESC';
    }
    return sortOrder;
}

export default sortOrder;
