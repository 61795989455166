import React, { Component } from 'react'
import Chart from 'chart.js';
import { formatValues, thousandSeparator, roundNumber } from './formatter';
import { haveValue, RESOLUTIONS } from '../../commons/misConfig';
import { CommentProcessingOutlineIcon } from 'mdi-react';
import ReactTooltip from 'react-tooltip';

export default class pieChart extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeChart: null,
            content: null
        }
        this.renderChart.bind(this);
        this.setTooltip = this.setTooltip.bind(this);
    }

    componentDidMount() {
        this.renderChart(this.props);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.willUpdate) {
            this.renderChart(nextProps);
        }
    }

    renderChart(props) {
        let { data } = this.props;
        data = props ? props.data : data;
        const chartId = props.chartId;
        let { activeChart } = this.state;
        let oldCanvas = document.getElementById(chartId);
        let oldSizeMonitors = document.getElementsByClassName('chartjs-size-monitor');

        if (activeChart) {
            activeChart.destroy();
        }

        if (oldCanvas != null && document.getElementById('dataChartContainer')) {
            for (let i = 0; i < oldSizeMonitors.length; i++) {
                document.getElementById('dataChartContainer').removeChild(oldSizeMonitors[i]);
            }
            document.getElementById('dataChartContainer').removeChild(oldCanvas);
            let canvasContainer = document.getElementById('dataChartContainer');
            let newCanvas = document.createElement('canvas');
            newCanvas.id = chartId;
            canvasContainer.append(newCanvas);
        }

        let ctx = document.getElementById(chartId).getContext('2d');
        var myPieChart = new Chart(ctx, {
            type: 'pie',
            data: props.data,
            plugins: {
                // beforeDraw: function(chartInstance){
                //     let ctx = chartInstance.ctx;

                //     ctx.textAlign = 'center';
                //     ctx.textBaseline = 'bottom';
                //     var thatData = data;
                //     data.datasets.forEach(function (dataset, i) {

                //         var meta = chartInstance.controller.getDatasetMeta(i);
                //         meta.data.forEach((bar, index) => {
                //             var dataLabel = thatData.labels[index]
                //             const arrSum = dataset.data.reduce((a, b) => a + b, 0);
                //             const pctValue = ((dataset.data[index] / arrSum) * 100).toFixed(1);
                //             var data = dataset.data[index];

                //             ctx.font = Chart.helpers.fontString("12", "bold", 'neo_regular');
                //             ctx.fillStyle = dataset.backgroundColor[index];
                //             ctx.fillText(dataLabel, index ? bar._model.x - 200 : bar._model.x + 200, index ? bar._model.y - 85 : bar._model.y + 85);
                //             ctx.font = Chart.helpers.fontString("13", "bold", 'neo_regular');
                //             ctx.fillStyle = '#414141';
                //             ctx.fillText(formatValues(data), index ? bar._model.x - 200 : bar._model.x + 200, index ? bar._model.y - 70 : bar._model.y + 100);
                //         });
                //     });

                // }
            },
            options: {
                maintainAspectRatio: false,
                legend: {
                    display: false
                },
                hover: {
                    animationDuration: 1
                },
                tooltips: {
                    enabled: false,
                    custom: function(tooltipModel) {

                        // Tooltip Element
                        let tooltipEl = document.getElementById('chartjs-tooltip-piechart');
                        // Create element on first render
                        if (!tooltipEl) {
                            tooltipEl = document.createElement('div');
                            tooltipEl.id = 'chartjs-tooltip-piechart';
                            tooltipEl.className = 'mis-heatmap-tooltip';
                            tooltipEl.innerHTML = '<table></table>';
                            document.body.appendChild(tooltipEl);
                        }
                        tooltipEl.className = 'mis-heatmap-tooltip';
        
                        // Hide if no tooltip
                        if (tooltipModel.opacity === 0) {
                            tooltipEl.style.opacity = 0;
                            return;
                        }
        
                        // Set caret Position
                        tooltipEl.classList.remove('above', 'below', 'no-transform');
                        if (tooltipModel.yAlign) {
                            tooltipEl.classList.add(tooltipModel.yAlign);
                        } else {
                            tooltipEl.classList.add('no-transform');
                        }
        
                        function getBody(bodyItem) {
                            return bodyItem.lines;
                        }
        
                        // Set Text
                        if (tooltipModel.body) {
                            let titleLines = tooltipModel.title || [];
                            let bodyLines = tooltipModel.body.map(getBody);
        
                            let innerHtml = '<thead>';
        
                            titleLines.forEach(function(title) {
                                innerHtml += '<tr><th>' + title + '</th></tr>';
                            });
                            innerHtml += '</thead><tbody>';

                            innerHtml += '<tr id="header"><td></td><td>' + data.metric + '</td><td>Adjustment*</td></tr>';
                            if(data){
                                data.labels.map(function(obj, i) {
                                    innerHtml += '<tr id='+i+'>';
                                    innerHtml += '<td>'+obj+'</td>';
                                    innerHtml += '<td>'+thousandSeparator(roundNumber(data.datasets[0].data[i], 0))+'</td>';
                                    innerHtml += '<td>'+thousandSeparator(roundNumber(data.datasets[0].negativeData[i], 0))+'</td>';
                                })
                            }
                            innerHtml += '<tr id="footer"><td colSpan="3">*Transfer of Branch/Wrong Encoding/Cancelled Policy</td></tr>';
                            innerHtml += '</tbody>';
        
                            var tableRoot = tooltipEl.querySelector('table');
                            tableRoot.innerHTML = innerHtml;
                        }
        
                        let offsetX = 0, offsetY = 0;
                        // `this` will be the overall tooltip
                        var position = this._chart.canvas.getBoundingClientRect();

                        // if(tooltipModel.caretX + tooltipModel.width < this._chart.width){
                        //     offsetX = (tooltipModel.caretX + tooltipModel.width) + this._chart.width;
                        // }
                        
                        // if(tooltipModel.caretY + tooltipModel.height < this._chart.height){
                        //     offsetY = (tooltipModel.caretY + tooltipModel.height) + this._chart.height;
                        // }
                        
                        offsetX = 155;
                        offsetY = 125;
                        
                        // Display, position, and set styles for font
                        tooltipEl.style.opacity = 'inherit';
                        tooltipEl.style.position = 'absolute';
                        tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX - offsetX + 'px';
                        tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY - offsetY + 'px';
                        // tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
                        tooltipEl.style.fontFamily = 'neo_regular'
                        tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
                        tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
                        tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
                        tooltipEl.style.borderRadius = '10px';
                        tooltipEl.style.pointerEvents = 'none';
                        tooltipEl.style.backgroundColor = 'rgba(0,0,0,0.8)';
                        tooltipEl.style.color = 'rgba(255,255,255,1)'
                    },
                }
            }
        });

        this.setState({
            activeChart: myPieChart
        })
    }

    setTooltip(data, index){
        let obj = 
        (
            <table className="mis-heatmap-tooltip">
                <tbody>
                    <tr id='header'>
                        <td></td>
                        <td>{data.metric}</td>
                        <td>Adjustment*</td>
                    </tr>
                    {data.labels.map(function (obj, i) {
                        return (
                        <tr id={i}>
                            <td>{obj}</td>
                            <td>{thousandSeparator(roundNumber(data.datasets[0].data[i], 0))}</td>
                            <td>{thousandSeparator(roundNumber(data.datasets[0].negativeData[i], 0))}</td>
                        </tr>)
                    })}
                    <tr id='footer'>
                        <td colSpan="3">*Transfer of Branch/Wrong Encoding/Cancelled Policy</td>
                    </tr>
                </tbody>
            </table>
        );

        this.setState({
            content: obj,
        })
    }

    render() {
        const { chartId, data, nonZero, channel } = this.props;
        const { activeChart, content } = this.state;
        let isBancassurance = channel.value.toUpperCase().includes('BANCASSURANCE');
        return (
            <div id='PieChart' className="dataChartContainer" style={{ padding: '5px', position: 'relative', height: 'calc(100% - 20px)' }}>
                {nonZero ? '': <p className="mis-no-data-show">{isBancassurance ? 'NO DATA TO SHOW' : 'FILTER NOT APPLICABLE'}</p>}
                <canvas id={chartId} style={{opacity: nonZero && isBancassurance ? '1': '0'}}/>
                {nonZero && isBancassurance && data ?
                    data.datasets[0].data.map((obj , i) => {
                        let dataLabel = data.labels[i];
                        let dataValue = obj;
                        return (
                        <div key={data.labels[i] + '-id'}className="mis-client-type-label" style={{
                                "--pieChartTopOffset": (i ? 85 : -85) + 'px', 
                                "--pieChartLeftOffset": (i ? 200 : -200) + 'px',
                                "--pieChartMobileTopOffset": (i ? 135 : -135) + 'px', 
                                "--pieChartMobileLeftOffset": (i ? 40 : -40) + '%', 
                                color: data.datasets[0].backgroundColor[i]}} 
                            onMouseEnter={() => this.setTooltip(data, i)} onMouseLeave={() => this.setTooltip(data, i)}>
                                {dataLabel} <br/>
                            <span className="mis-client-type-value">{formatValues(dataValue)}</span>
                        </div>)
                    })
                : ""}
                {/* <ReactTooltip id="pieChartTooltip">{content}</ReactTooltip> */}
            </div>
        )
    }
}