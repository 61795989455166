import React, { Component } from 'react';
import Menu from '../components/menu';
import LeaderboardListItem from '../components/leaderboardListItem';
import { getLeaderboardListContestId, getContestDetails, getRegionList, getBranchLists, getViewRoles, getHierarchies, getSort, getSubcontest } from '../commons/restEndpoint';
import Select from 'react-select';
import MiniLoading from '../components/miniLoading';
import NavLink from 'react-router-dom/NavLink';
import { decrypt } from '../../../commons/utility';
import { haveValue } from '../../../commons/misConfig';
import moment from "moment";

const rniViewTypes = [
    {
        value: "AGENCY_PARTNER",
        label: "Agency Partner"
    },
    {
        value: "LEAD_PARTNER",
        label: "Lead Partner"
    },
    {
        value: "MANAGING_PARTNER",
        label: "Managing Partner"
    }
];

const fieldSortType = [
    {
        value: "ANP",
        label: "Sort by ANP"
    },
    {
        value: "CC",
        label: "Sort by CC"
    },
];

const tierType = [
    {
        value: "1",
        label: "Power Up 30"
    },
    {
        value: "2",
        label: "Power Up 60"
    },
    {
        value: "3",
        label: "Power Up 90"
    },
];

const customStyles = {
    menu: (provided, state) => ({ ...provided, zIndex: 100 }),
    option: (provided, state) => ({
        ...provided,
        borderBottom: 'none',
        color: state.isSelected || state.isFocused ? '#006192' : '#414141',
        background: '#FFFFFF',
        fontSize: '16px',
        fontFamily: 'Allianz Neo',
        fontWeight: '400',
        padding: 15,
    }),
    indicatorSeparator: (provided, state) => ({
        opacity: 0,
    }),
    dropdownIndicator: (provided, state) => ({
        color: '#414141',
    })
}

class LeaderboardList extends Component {

    constructor(props) {
        super(props)

        this.state = {
            title: "",
            contestId: this.props.contestId ? this.props.contestId : 0,
            role: '',
            asOfDt: 0,
            leaderBoardMenu: [],
            regionType: [],
            rniViewTypes: [],
            branchType: [],
            fieldSortType: [],
            tierType: [],
            activeHierarchy: {
                id:'',
                name: '',
                imgUrl: ''
            },
            activeFieldSort: {
                value: "",
                label: ""
            },
            activeView: {
                value: "",
                label: ""
            },
            activeTier: this.props.contestId === '5' ? {
                value: "",
                label: ""
            } : {
                value: "",
                label: ""
            },
            activeRegion: {
                value: "",
                label: "",
            },
            activeBranch: {
                value: "",
                label: "",
            },
            leaderboardItems: [],
            doneLoadingItem: false,
            isLegitContest: false,
            isLoading: false,
        }

        this.fetchLeaderboardList = this.fetchLeaderboardList.bind(this);
        this.changeFieldSort = this.changeFieldSort.bind(this);
        this.changeView = this.changeView.bind(this);
        this.changeTier = this.changeTier.bind(this);
        this.changeBranch = this.changeBranch.bind(this);
        this.changeRegion = this.changeRegion.bind(this);
        this.fetchContestDetails = this.fetchContestDetails.bind(this);
    }

    async fetchContestDetails() {
        let x = await getContestDetails(this.state.contestId, this.props.session.access_token);
        if (x && x.data) {
            this.setState({
                title: x.data.contestTitle,
                image: x.data.contestImg,
                asOfDt: x.data.asOfDt,
                isLegitContest: true,
                isLoading: false,
            });
        } else {
            this.setState({
                isLegitContest: false,
                isLoading: false,
            });
        }
    }

    changeTier(value) {
        let thisCom = this;
        this.setState({
            activeTier: value,
        }, async function () {
            thisCom.fetchLeaderboardList(this.state);
        });
    }

    changeFieldSort(value) {
        let thisCom = this;
        this.setState({
            activeFieldSort: value,
        }, async function () {
            thisCom.fetchLeaderboardList(this.state);
        });
    }

    changeView(view) {
        let thisCom = this;
        this.setState({
            activeView: view,
        }, async function () {
            let x = await thisCom.fetchSort(this.state)
            thisCom.fetchLeaderboardList(x);
        });
    }

    changeRegion(value) {
        console.log(value)
        let thisCom = this;
        this.setState({
            activeRegion: value,
        }, async function () {
            if(this.state.activeHierarchy.name === 'BRANCH'){
               let branch =  thisCom.fetchBranch(this.state);
               thisCom.fetchLeaderboardList(branch);
            }else{
               thisCom.fetchLeaderboardList(this.state);
            }
        });
    }

    changeBranch(value) {
        let thisCom = this;
        this.setState({
            activeBranch: value,
        }, async function () {
            thisCom.fetchLeaderboardList(this.state);
        });
    }

    labelModifier(str) {
        return str.toLowerCase().split(' ').map(function (word) {
            return word.replace(word[0], word[0].toUpperCase());
        }).join(' ');
    }

    async fetchLeaderboardList(data) {
        console.log(data)
        this.setState({
            doneLoadingItem: false,
        });

        let params = {
            hierarchyId: data.activeHierarchy.id,
            paramId: data.activeFieldSort.value,
            viewId: data.activeView.value,
            regionId: (data.activeHierarchy.name === 'REGION' || data.activeHierarchy.name === 'BRANCH') && (this.state.role === 'CAO' || this.state.role === 'HO' || this.state.role === 'RBM') ? data.activeRegion.value: '',
            branchId: data.activeHierarchy.name === 'BRANCH' && (this.state.role === 'CAO' || this.state.role === 'HO' || this.state.role === 'RBM') ? data.activeBranch.value: '',
            tierNo: haveValue(this.state.activeTier) ? data.activeTier.value:'',
            page: 0,
            size: 100,
        };
        let x = await getLeaderboardListContestId(this.state.contestId, params, this.props.session.access_token);
        console.log(x)
        if (x && x.data && x.data.leaderboards.content) {
            data.leaderboardItems = x.data.leaderboards.content.length >= 1 ? x.data.leaderboards.content : [];
            data.doneLoadingItem = true;
            data.role =  x.data.role.name;
        } else {
            data.leaderboardItems = [];
            data.doneLoadingItem = true;
            data.role =  '';
        }

        this.setState({
            activeHierarchy: data.activeHierarchy,
            rniViewTypes: data.rniViewTypes,
            activeView: data.activeView,
            fieldSortType: data.fieldSortType,
            activeFieldSort: data.activeFieldSort,
            regionType: data.regionType ? data.regionType : [],
            activeRegion: data.activeRegion ?  data.activeRegion : {value: '', label: ''},
            branchType: data.branchType ? data.branchType : [],
            activeBranch: data.activeBranch ? data.activeBranch : {value: '', label: ''},
            tierType: data.tierType ? data.tierType : [],
            activeTier: data.activeTier ?  data.activeTier : {value: '', label: ''},
            leaderboardItems: data.leaderboardItems,
            doneLoadingItem: true,
            role: data.role
        });

    }

    async fetchHierarchy(){
        let hierarchy = await getHierarchies(this.state.contestId,this.props.session.access_token);
        let data = {};
        let hierarchyType = [];

        if(hierarchy && haveValue(hierarchy.data)){
            hierarchy = hierarchy.data.map((a) => {
                hierarchyType.push({
                    id: a.id ,
                    name: a.name,
                    imgUrl: a.imgUrl
                })
            })
            data = {
                leaderBoardMenu : hierarchyType,
                activeHierarchy: {
                    id: hierarchyType[0].id,
                    name: hierarchyType[0].name,
                    imgUrl: hierarchyType[0].imgUrl
                }
            }
        }else{
            data = {
                leaderBoardMenu : hierarchyType,
                activeHierarchy: {
                    id: '',
                    name: '',
                    imgUrl: ''
                }
            }
        }

        this.setState({
            leaderBoardMenu: data.leaderBoardMenu,
            activeHierarchy: data.activeHierarchy,
        })
        return data;
    }

    async fetchViewRoles(data) {
        let params = {
            hierarchyId: data.activeHierarchy.id
        }
        let viewType = [];
        let view = await getViewRoles(this.state.contestId, params, this.props.session.access_token);
        
        if (view && haveValue(view.data)) {
            view = view.data.map((a) => {
                viewType.push({
                    value: a.id,
                    label: this.labelModifier(a.name)
                })
            })

            data.rniViewTypes = viewType;
            data.activeView = {
                value: viewType[0].value,
                label: viewType[0].label
            }
        } else {
            data.rniViewTypes = viewType;
            data.activeView = {
                value: '',
                label: ''
            }
        }
        return data;
    }

    async fetchSort(data){
        let params = {
            viewId: data.activeView.value
        }
        let sortType = await getSort(this.state.contestId, params, this.props.session.access_token);
        let sortTypeList = []

        if(sortType && haveValue(sortType.data)){
            sortType = sortType.data.map((a)=> {
                sortTypeList.push({
                    value: a.id,
                    label: 'Sort by ' + a.name
                })
            })

            data.fieldSortType = sortTypeList;
            data.activeFieldSort = {
                value: sortTypeList[0].value,
                label: sortTypeList[0].label
            }
        }else{
            data.fieldSortType = sortTypeList;
            data.activeFieldSort = {
                value: '',
                label: ''
            }
        }

        data.regionType = [];
        data.activeRegion = {value: '', label: ''};
        data.branchType = [];
        data.activeBranch = {value: '', label: ''};

        return data;
        //this.fetchRegions(data)
    }

    async fetchRegions(data) {
        let region = await getRegionList(this.props.session.access_token);
        let regionTypes = [];

        if (region && region.data) {
            region = region.data.map((a) => {
                regionTypes.push({
                    value: a.id,
                    label: this.labelModifier(a.name)
                });
            })

            data.regionType = regionTypes
            data.activeRegion = {
                value: regionTypes[0].value,
                label: regionTypes[0].label
            }
        }else{
            data.regionType = regionTypes
            data.activeRegion = {
                value: '',
                label: ''
            }
        }
        return data;
    }

    async fetchBranch(data) {
        let branch = await getBranchLists(data.activeRegion.value, this.props.session.access_token);
        let branchTypes = [];
        if (branch && branch.data) {
            branch = branch.data.map((a) => {
                branchTypes.push({
                    value: a.id,
                    label: this.labelModifier(a.name)
                });
            });

            data.branchType = branchTypes;
            data.activeBranch = {
                value: branchTypes[0].value,
                label: branchTypes[0].label
            }
        }else{
            data.branchType = branchTypes;
            data.activeBranch = {
                value: '',
                label: ''
            }
        }

        return data;
        //this.fetchLeaderboardList(data);
    }

    async fetchTierType(data){
        let tier = await getSubcontest(this.state.contestId, this.props.session.access_token);
        console.log(tier);
        let listOfTier = [];
        if(tier && haveValue(tier.data)){ 
            tier.data.map((a) => {
                listOfTier.push({
                    value: a.tierId,
                    label: a.name
                })
            })

            data.tierType = listOfTier;
            data.activeTier = {
                value: listOfTier[0].value,
                label: listOfTier[0].label
            }
        }else{
            data.tierType = listOfTier
            data.activeTier = {
                value: '',
                label: ''
            }
        }
        return data;
    }

    async componentWillMount() {
        let contest = await this.fetchContestDetails();
        let hierarchy = await this.fetchHierarchy();
        let view = await this.fetchViewRoles(hierarchy);
        let sort = await this.fetchSort(view)
        let tier = await this.fetchTierType(sort);
        let leaderBoard = await this.fetchLeaderboardList(tier);

        let { username, name } = this.props.session
        let surveyDtls = {
            username: decrypt(username),
            name: decrypt(name)
        }

        window.fsr && window.fsr.initSurvey(surveyDtls)
    }

    async onClickMenu(data){
        console.log(data)
        this.setState({
            activeHierarchy: data
        })
        data.activeHierarchy = data;
        let roles = await this.fetchViewRoles(data);
        let sort = await this.fetchSort(roles);
        let tier = await this.fetchTierType(sort);
        let region = '';
        let branch = '';
        let leaderBoard = '';

        if(data.name === 'REGION'){
            region = await this.fetchRegions(tier);
            leaderBoard = await this.fetchLeaderboardList(region);
        }else if(data.name === 'BRANCH'){
            region = await this.fetchRegions(tier);
            branch = await this.fetchBranch(region);
            leaderBoard = await this.fetchLeaderboardList(branch);
        }else{
            leaderBoard = await this.fetchLeaderboardList(tier);
        }

    }

    render() {
        let thisCom = this;
        let expiration = this.state.asOfDt;
        expiration = moment(expiration * 1000);
        expiration = expiration.format("MM/DD/YYYY HH:mm");

        const { leaderBoardMenu, leaderboardItems, mode, activeHierarchy, doneLoadingItem, activeView, activeFieldSort, activeTier, activeRegion, activeBranch, title } = this.state;
        return (
            <div id='LeaderBoardList' className="container rni-leaderboard-container">
                <div style={{ width: '100%', textAlign: 'left' }}>
                    <NavLink style={{ textDecorationLine: 'none' }} to={"/rewards-and-incentives/leaderboard"}>
                        <img style={{ width: '24px', height: '24px' }} src="/rni/angle-left-solid.svg" />
                        <label style={{ fontSize: '16px', cursor: 'pointer', fontFamily: 'neo_semibold', marginBottom: '0px', color: '#007ab3' }}>Back to Leaderboards</label>
                    </NavLink>
                </div>
                <p className="rni-leaderboard-container-h1">{title}</p>
                {this.state.asOfDt ?  <h6 style={{fontSize:'0.8rem'},{marginTop:'-15px'}}>Updated as of : {expiration}</h6> : ''}
                <Menu menuList={leaderBoardMenu} callback={(value) => {
                    this.onClickMenu(value);
                }}
                    hierarchy={activeHierarchy.name} />
                <div className="rni-leaderboard-filter-container" style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                    {haveValue(this.state.rniViewTypes) ?
                        <Select
                            styles={customStyles}
                            value={activeView}
                            placeholder="View"
                            onChange={(e) => { this.changeView(e); }}
                            onClick={() => { }}
                            onBlur={() => { }}
                            options={this.state.rniViewTypes}
                            className="rni-select-container-2"
                            classNamePrefix="rni-select-2"
                        /> : ''}
                    {haveValue(this.state.fieldSortType) ? <Select
                        styles={customStyles}
                        value={activeFieldSort}
                        placeholder="Field Sort"
                        onChange={(e) => { this.changeFieldSort(e); }}
                        onClick={() => { }}
                        onBlur={() => { }}
                        options={this.state.fieldSortType}
                        className="rni-select-container-2"
                        classNamePrefix="rni-select-2"
                    />: ''}
                    {(this.state.role === 'CAO' || this.state.role === 'HO' || this.state.role === 'RBM') && (this.state.activeHierarchy.name === 'REGION' || this.state.activeHierarchy.name === 'BRANCH') ?
                        <Select
                            styles={customStyles}
                            value={activeRegion}
                            placeholder="Region"
                            onChange={(e) => { this.changeRegion(e); }}
                            onClick={() => { }}
                            onBlur={() => { }}
                            options={this.state.regionType}
                            className="rni-select-container-2"
                            classNamePrefix="rni-select-2"
                        /> : ''
                    }
                    {(this.state.role === 'CAO' || this.state.role === 'HO' || this.state.role === 'RBM') && this.state.activeHierarchy.name === 'BRANCH' ?
                        <Select
                            styles={customStyles}
                            value={activeBranch}
                            placeholder="Branch"
                            onChange={(e) => { this.changeBranch(e); }}
                            onClick={() => { }}
                            onBlur={() => { }}
                            options={this.state.branchType}
                            className="rni-select-container-2"
                            classNamePrefix="rni-select-2"
                        /> : ''
                    }
                    {haveValue(this.state.tierType) ? <Select
                        styles={customStyles}
                        value={activeTier}
                        placeholder="Tier Type"
                        onChange={(e) => { this.changeTier(e); }}
                        onClick={() => { }}
                        onBlur={() => { }}
                        options={this.state.tierType}
                        className="rni-select-container-2"
                        classNamePrefix="rni-select-2"
                    /> : ""}
                </div>
                {doneLoadingItem ? <div className="row rni-leaderboard-list-row">
                    {haveValue(leaderboardItems) ?
                        leaderboardItems.map(function (leaderboardItem, leaderboardIndex) {
                            return (
                                <div key={leaderboardIndex} className="col-md-12 col-xs-12 col-sm-12 col-lg-12 rni-leaderboard-list-progress-item">
                                    <LeaderboardListItem data={leaderboardItem} mode={(activeFieldSort.label).replace('Sort by ','')} />
                                </div>
                            );
                        }) :
                        <p style={{ fontSize: '2rem', fontFamily: 'neo_regular', textAlign: 'center', color: '#D7D3D9', width: '100%' }}>No results yet.</p>}
                </div> : <div style={{ width: '50vw', margin: 'auto' }}><MiniLoading /></div>}
            </div>
        )
    }
}

export default LeaderboardList