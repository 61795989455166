import React from 'react';

const tableRowIndicator = (props) => {
    const pageData = props.data;
    const page = pageData.page; //start is 0

    const pageSize = pageData.pageSize; //current item count
    const totalSize = pageData.totalSize;
    // const allData = pageData.sortedData;
    const startIdx = page * pageSize;
    // const lastIdx = (page + 1) * pageSize;
    // const currentData = allData.slice(startIdx, startIdx + pageSize).map((item) => item._original);

    // let toValue = ((page + 1) * pageData.defaultPageSize) - (pageData.defaultPageSize - currentData.length);
    let toValue = ((page * pageSize) + pageData.data.length);

    if (toValue > totalSize) {
        toValue = totalSize;
    }

    let returnVal = (
        <div className="Table__visibleRows text-headercolor">
            Showing {startIdx + 1} to {toValue} of {totalSize} entries
        </div>
    );

    return (
        totalSize > 0? returnVal: ''
    );
}

export default tableRowIndicator;
