import React, { Component } from 'react';
import { ReportLoader } from '../commons/utilityViews.js';
import { withRouter, Link } from 'react-router-dom';
import { myConfig } from '../commons/config.js';
import 'react-vertical-timeline-component/style.min.css';
// import { urlBase64ToUint8Array } from '../commons/utility.js';
import ReactTable from 'react-table';
import ClientPolicyNav from '../components/clientPolicyNav';
import DatatableSearch from '../components/datatableSearch';
import Policies from '../components/Policies/Policies';
import { removeNull, checkSearch, prettifyDate, calculateAge, writeData, numberWithCommas, decrypt, encrypt, fixName } from '../commons/utility.js';
import routeChange from '../commons/routeChange';
import { apiModule, createQueryStrings } from '../commons/apiCall';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import handleSortOrder from '../components/misc/sortOrder';
import { isManager, checkLogin, checkPermissions, getSessionInfo } from '../commons/security.js';

class NewlyAssignedPolicyList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            error: '',
            policies: [],
            rows: -1,
            pages: 10,
            currentPage: 0,
            totalSize: 0,
            //search text field value
            searchValue: "",
            isMgr: false,
            hasFa: false,
            //dropdown option
            optionSelected: { value: "contractHolderName", label: "Policy Owner Name" },

            //effective date
            effDate: null,

            //expiry date
            expDate: null,

            //policy POL_STAT
            policyStatusList: [],
            policyStatusValue: null,

            //branch List
            branchList: [],
            branchValue: null,

            sortByField: null,
            sortOrder: null,
            isAllowed: false,

            //newly assigned policy --additional query value
            includeFields: "NEW_CLIENT",
        };

        this.handlePolicyStatusChange = this.handlePolicyStatusChange.bind(this);
        this.handleBranchListChange = this.handleBranchListChange.bind(this);
    }

    errorMessage = (message) => {
        if (message) {
            this.setState({ error: message, isLoading: false })
        }
    }
    resetError = () => {
        // this.setState({ error: '', isLoading: true })
        this.setState({ error: '', loadTable: true });
    }
    //SHOW ERROR IF ERROR STATE IS NOT NULL
    showError = () => {
        let message = '';
        if (this.state.error !== '') {
            message =
                <div className="bg-error py-1 px-4">
                    <p className="text-white mb-0 font-neo-semibold">ERROR: {this.state.error}</p>
                </div>
        }
        return message;
    }

    async componentDidMount() {

        console.log(this.props)
        let access_token = this.props.getSession().access_token;
        let session = await getSessionInfo();
        let permissionsList = session[0].permissions;

        if (checkPermissions(['VIEW_POL_LIST'], permissionsList)) {
            this.setState({ isAllowed: true, isLoading: true, access_token: access_token, isMgr: isManager(this.props.getSession().role) });
            await this.fetchPolicies(this.props.getSession().access_token, decrypt(this.props.getSession().agentNumber));
        } else {
            this.setState({ isAllowed: false, isLoading: false, error: "You are not allowed to access this page" });
        }




    }

    async getPolicyStatusList(selectedOption) {
        await this.fetchPolicyStatusList(selectedOption, this.props.getSession().access_token, decrypt(this.props.getSession().agentNumber));
    }

    async getBranchList(selectedOption) {
        await this.fetchBranchList(selectedOption, this.props.getSession().access_token, decrypt(this.props.getSession().agentNumber));
    }

    updateOptionSelected = (option) => {
        if (option.value == 'status' && this.state.optionSelected.value != 'status') {
            this.getPolicyStatusList(option);
        } else if (option.value == 'branchId' && this.state.optionSelected.value != 'branchId') {
            this.getBranchList(option);
        } else {
            this.setState({
                policyStatusValue: null,
                branchValue: null,
                optionSelected: option
            });
        }
    }

    updateSearchValue = (value) => {
        this.setState({
            searchValue: value,
        });
    }

    handleEffDateChange = (date) => {
        this.setState({
            effDate: date
        })
    }

    handleExpDateChange = (date) => {
        this.setState({
            expDate: date
        })
    }

    handleSortAction = (sortByField, sortOrder) => {
        this.setState({
            sortByField: sortByField,
            sortOrder: sortOrder,
            // isLoading: true
            loadTable: true,
        }, () => {
            this.search(this.state.currentPage);
        })
    }
    headerClickedHandler = (sortByField) => {
        let sortOrder = handleSortOrder({ sortByField: sortByField, state: this.state });
        this.handleSortAction(sortByField, sortOrder)
    }


    fetchPolicies = (access_token, agentNumber) => {
        let that = this;
        return new Promise(function (resolve, reject) {

            let headerParams = { x_auth: access_token };

            let filter = [];
            filter['pageNumber'] = (that.state.currentPage + 1);
            filter['agentNumber'] = agentNumber;
            filter['pageSize'] = myConfig.PAGE_SIZE;
            filter['includeFields'] = that.state.includeFields;

            apiModule("policy_all", headerParams, null, createQueryStrings(filter))
                .then(function (response) {

                    if (response.length > 0) {


                        let result = response[0].content;
                        let policies = [];
                        let hasFa = false;
                        //IF RESULT IS NULL THEN ERROR MESSAGE
                        if (result) {
                            for (let i = 0; i < result.length; i++) {
                                if (that.state.isMgr && result[i].accountManager !== undefined && result[i].accountManager !== null) {
                                    policies.push({
                                        POL_NO: removeNull(result[i].contractNumber),
                                        POL_DS: removeNull(result[i].productOfferingName),
                                        POL_STAT: removeNull(result[i].status.toUpperCase()),
                                        TOT_MODAL_PREM: numberWithCommas(result[i].netPremium),
                                        PMODE_CD: removeNull(result[i].paymentFrequency),
                                        insured: `${fixName(result[i].insured)}`, //`${result[i].insured.lastName}, ${result[i].insured.firstName}`,
                                        EFF_DT: prettifyDate(result[i].inceptionDate),
                                        POL_CURR_CD: removeNull(result[i].currency),
                                        EXPIRY_DT: prettifyDate(result[i].contractInterval.endDateTime),
                                        fa_name: removeNull(result[i].accountManager.name),
                                        fa_number: removeNull(result[i].accountManager.agentNumber),
                                        fa_am_number: removeNull(result[i].accountManager.agentNumberOfAdvisorOfAccountManager),


                                        INS1_COMP_NAME: `${fixName(result[i].contractHolder)}`, //`${result[i].contractHolder.lastName}, ${result[i].contractHolder.firstName}`,
                                        FACE_AMT: numberWithCommas(result[i].contractBalance),
                                        CREDIT_SO: removeNull(result[i].branchName),
                                    });
                                    hasFa = true;
                                } else {
                                    policies.push({
                                        POL_NO: removeNull(result[i].contractNumber),
                                        POL_DS: removeNull(result[i].productOfferingName),
                                        POL_STAT: removeNull(result[i].status.toUpperCase()),
                                        TOT_MODAL_PREM: numberWithCommas(result[i].netPremium),
                                        PMODE_CD: removeNull(result[i].paymentFrequency),
                                        insured: `${fixName(result[i].insured)}`, //`${result[i].insured.lastName}, ${result[i].insured.firstName}`,
                                        EFF_DT: prettifyDate(result[i].inceptionDate),
                                        POL_CURR_CD: removeNull(result[i].currency),
                                        EXPIRY_DT: prettifyDate(result[i].contractInterval.endDateTime),
                                        FA_NAME: removeNull(result[i].agent.name),

                                        INS1_COMP_NAME: `${fixName(result[i].contractHolder)}`,//`${result[i].contractHolder.lastName}, ${result[i].contractHolder.firstName}`,
                                        FACE_AMT: numberWithCommas(result[i].contractBalance),
                                        CREDIT_SO: removeNull(result[i].branchName),
                                    });
                                }

                            }
                        } else {
                            // that.errorMessage("No Policy Found");
                        }

                        that.setState({
                            policies,
                            hasFa,
                            totalSize: response[0].totalSize,
                            pages: response[0].pageCount,
                            loadTable: false,
                            isLoading: false
                        });
                    } else {
                        routeChange(that.props.history, "/dashboard/list-of-policies");
                        that.setState({
                            policies: [],

                            totalSize: 0,
                            pages: 0,
                            loadTable: false,
                            isLoading: false
                        });
                    }
                })
                .catch(function (err) {
                    console.log(err);
                    that.setState({
                        policies: [],
                        error: myConfig.GENERIC_ERROR_MESSAGE,
                        isLoading: false
                    });
                    //that.errorMessage(myConfig.GENERIC_ERROR_MESSAGE);
                    console.log(err.message)

                })
            // let result = null;



        });
    }

    fetchPolicyStatusList = (selectedOption, access_token, agentNumber) => {
        let that = this;
        return new Promise(function (resolve, reject) {

            let headerParams = {
                x_auth: access_token,
                dataListId: 'policyStatus'
            };
            let filter = [];
            filter['pageNumber'] = (that.state.currentPage + 1);
            filter['agentNumber'] = agentNumber;
            filter['pageSize'] = myConfig.PAGE_SIZE;
            filter['accountManagerId'] = agentNumber;

            apiModule("get_datalist", headerParams, null, createQueryStrings(filter))
                .then(function (response) {

                    let result = response;
                    let policyStatusList = [];

                    //IF RESULT IS NULL THEN ERROR MESSAGE
                    if (result) {
                        // policyStatusList = Object.entries(result).map(([key, val]) => ({ [key]: val }));
                        Object.keys(result).forEach(function (key) {
                            let item = key;
                            policyStatusList.push(
                                new Object({
                                    value: item,
                                    label: result[key]
                                })
                            );
                        });
                        console.log(policyStatusList);
                        that.setState({
                            policyStatusList: policyStatusList,
                            isLoading: false,
                            optionSelected: selectedOption
                        });
                    } else {
                        that.setState({
                            isLoading: false,
                            optionSelected: selectedOption
                        }, () => {
                            // that.errorMessage("No Records Found");
                        });
                    }
                })
                .catch(function (err) {
                    console.log(err);
                    that.setState({
                        isLoading: false,
                        policyStatuslist: []
                    }, () => {
                        that.errorMessage(myConfig.GENERIC_ERROR_MESSAGE);
                        console.log(err.message)
                    });
                })
        });
    }

    fetchBranchList = (selectedOption, access_token, agentNumber) => {
        let that = this;
        return new Promise(function (resolve, reject) {

            let headerParams = {
                x_auth: access_token,
                dataListId: 'intmBranches'
            };
            let filter = [];
            // filter['pageNumber'] = (that.state.currentPage + 1);
            // filter['agentNumber'] = agentNumber;
            // filter['pageSize'] = myConfig.PAGE_SIZE;
            filter['key'] = agentNumber;

            apiModule("get_datalist", headerParams, null, createQueryStrings(filter))
                .then(function (response) {

                    let result = response;
                    let branchList = [];

                    //IF RESULT IS NULL THEN ERROR MESSAGE
                    if (result && result.error == null) {

                        Object.keys(result).forEach(function (key) {
                            let item = key;
                            branchList.push(
                                new Object({
                                    value: item,
                                    label: result[key]
                                })
                            );
                        });
                        console.log(branchList);
                        that.setState({
                            branchList: branchList,
                            isLoading: false,
                            optionSelected: selectedOption
                        });
                    } else {
                        that.setState({
                            isLoading: false
                        }, () => {
                            //  that.errorMessage("No Records Found");
                        });
                    }
                })
                .catch(function (err) {
                    console.log(err);
                    that.setState({
                        isLoading: false,
                        policyStatuslist: []
                    }, () => {
                        that.errorMessage(myConfig.GENERIC_ERROR_MESSAGE);
                        console.log(err.message)
                    });
                })
        });
    }

    search = (pageNumber) => {
        // let headerParams = { x_auth: access_token };
        let headerParams = { x_auth: this.props.getSession().access_token };
        let that = this;
        let continueToApiCall = true;
        let filter = [];
        filter['agentNumber'] = decrypt(that.props.getSession().agentNumber);
        filter['pageSize'] = myConfig.PAGE_SIZE;
        filter['accountManagerId'] = decrypt(that.props.getSession().agentNumber);
        filter['pageNumber'] = pageNumber + 1;
        filter['includeFields'] = this.state.includeFields;

        if (this.state.optionSelected.value !== null) {
            if (this.state.optionSelected.value === 'status') {
                if (this.state.policyStatusValue !== null) {
                    filter[this.state.optionSelected.value] = this.state.policyStatusValue.value;
                    continueToApiCall = checkSearch(this.state.searchValue, that.errorMessage, 2);
                }
            } else if (this.state.optionSelected.value === 'branchId') {
                if (this.state.branchValue !== null) {
                    filter[this.state.optionSelected.value] = this.state.branchValue.value;
                    continueToApiCall = checkSearch(this.state.searchValue, that.errorMessage, 2);
                }
            } else if (this.state.searchValue != null && this.state.searchValue != "") {
                filter[this.state.optionSelected.value] = this.state.searchValue;
                continueToApiCall = checkSearch(this.state.searchValue, that.errorMessage, 2);
            }
        }

        if (that.state.sortByField != null) {
            filter['sortBy'] = that.state.sortByField + ',' + that.state.sortOrder
        }

        if (continueToApiCall) {
            that.resetError();

            apiModule("policy_all", headerParams, null, createQueryStrings(filter))
                .then(function (response) {
                    if (response.length > 0) {
                        let result = [];
                        if (response.length > 0) {
                            result = response[0].content;

                        } else {
                            result = [];
                        }

                        let policies = [];
                        let hasFa = false;
                        //IF RESULT IS NULL THEN ERROR MESSAGE
                        if (result) {
                            for (let i = 0; i < result.length; i++) {


                                if (that.state.isMgr && result[i].accountManager !== undefined && result[i].accountManager !== null) {
                                    policies.push({
                                        POL_NO: removeNull(result[i].contractNumber),
                                        POL_DS: removeNull(result[i].productOfferingName),
                                        POL_STAT: removeNull(result[i].status.toUpperCase()),
                                        TOT_MODAL_PREM: numberWithCommas(result[i].netPremium),
                                        PMODE_CD: removeNull(result[i].paymentFrequency),
                                        insured: `${fixName(result[i].insured)}`,//`${result[i].insured.lastName}, ${result[i].insured.firstName}`,
                                        EFF_DT: prettifyDate(result[i].inceptionDate),
                                        POL_CURR_CD: removeNull(result[i].currency),
                                        EXPIRY_DT: prettifyDate(result[i].contractInterval.endDateTime),
                                        fa_name: removeNull(result[i].accountManager.name),
                                        fa_number: removeNull(result[i].accountManager.agentNumber),
                                        fa_am_number: removeNull(result[i].accountManager.agentNumberOfAdvisorOfAccountManager),


                                        INS1_COMP_NAME: fixName(result[i].contractHolder),//`${result[i].contractHolder.lastName}, ${result[i].contractHolder.firstName}`,
                                        FACE_AMT: numberWithCommas(result[i].contractBalance),
                                        CREDIT_SO: removeNull(result[i].branchName),
                                    });
                                    hasFa = true;
                                } else {
                                    policies.push({
                                        POL_NO: removeNull(result[i].contractNumber),
                                        POL_DS: removeNull(result[i].productOfferingName),
                                        POL_STAT: removeNull(result[i].status.toUpperCase()),
                                        TOT_MODAL_PREM: numberWithCommas(result[i].netPremium),
                                        PMODE_CD: removeNull(result[i].paymentFrequency),
                                        insured: `${fixName(result[i].insured)}`,//`${result[i].insured.lastName}, ${result[i].insured.firstName}`,
                                        EFF_DT: prettifyDate(result[i].inceptionDate),
                                        POL_CURR_CD: removeNull(result[i].currency),
                                        EXPIRY_DT: prettifyDate(result[i].contractInterval.endDateTime),
                                        FA_NAME: removeNull(result[i].agent.name),

                                        INS1_COMP_NAME: fixName(result[i].contractHolder),//`${result[i].contractHolder.lastName}, ${result[i].contractHolder.firstName}`,
                                        FACE_AMT: numberWithCommas(result[i].contractBalance),
                                        CREDIT_SO: removeNull(result[i].branchName),
                                    });
                                }

                                // if (that.state.isMgr && result[i].accountManager !== undefined && result[i].accountManager !== null) {
                                //     policies.push({
                                //         POL_NO: result[i].contractNumber,
                                //         POL_DS: result[i].productOfferingName,
                                //         POL_STAT: result[i].status.toUpperCase(),
                                //         TOT_MODAL_PREM: numberWithCommas(result[i].netPremium),
                                //         PMODE_CD: result[i].paymentFrequency,
                                //         insured: `${result[i].insured.lastName}, ${result[i].insured.firstName}`,
                                //         EFF_DT: prettifyDate(result[i].inceptionDate),
                                //         POL_CURR_CD: result[i].currency,
                                //         EXPIRY_DT: prettifyDate(result[i].contractInterval.endDateTime),
                                //         fa_name: result[i].accountManager.name,
                                //         fa_number: result[i].accountManager.agentNumber,
                                //         fa_am_number: result[i].accountManager.agentNumberOfAdvisorOfAccountManager,


                                //         INS1_COMP_NAME:  `${result[i].contractHolder.lastName}, ${result[i].contractHolder.firstName}`,
                                //         FACE_AMT : numberWithCommas(result[i].contractBalance),
                                //         CREDIT_SO: result[i].branchName,
                                //     });
                                //     hasFa = true;
                                // } else {
                                //     policies.push({
                                //         POL_NO: result[i].contractNumber,
                                //         POL_DS: result[i].productOfferingName,
                                //         POL_STAT: result[i].status.toUpperCase(),
                                //         TOT_MODAL_PREM: numberWithCommas(result[i].netPremium),
                                //         PMODE_CD: result[i].paymentFrequency,
                                //         insured: `${result[i].insured.lastName}, ${result[i].insured.firstName}`,
                                //         EFF_DT: prettifyDate(result[i].inceptionDate),
                                //         POL_CURR_CD: result[i].currency,
                                //         EXPIRY_DT: prettifyDate(result[i].contractInterval.endDateTime),

                                //         INS1_COMP_NAME:  `${result[i].contractHolder.lastName}, ${result[i].contractHolder.firstName}`,
                                //         FACE_AMT : numberWithCommas(result[i].contractBalance),
                                //         CREDIT_SO: result[i].branchName,
                                //     });
                                // }
                            }
                        } else {
                            //that.errorMessage("No Policy Found");
                        }

                        that.setState({
                            policies,
                            hasFa,
                            loadTable: false,
                            //isLoading: false,
                            totalSize: response[0].totalSize,
                            pages: response[0].pageCount,
                            currentPage: (pageNumber),
                            pageNumber: (pageNumber),
                            isLoading: false
                        });
                    } else {
                        that.setState({
                            policies: [],
                            loadTable: false,
                            //isLoading: false,
                            totalSize: 0,
                            pages: 0,
                            currentPage: (pageNumber),
                            pageNumber: (pageNumber),
                            isLoading: false
                        });
                    }
                })
                .catch(function (err) {
                    console.log(err);
                    that.setState({
                        isLoading: false
                    }, () => {
                        that.errorMessage(myConfig.GENERIC_ERROR_MESSAGE);
                        console.log(err.message)
                    });

                })
        } else {
            this.setState({
                isLoading: false
            });
        }
    }

    handlePolicyStatusChange = (policyStatus) => {
        this.setState({
            policyStatusValue: policyStatus
        }, () => {
            this.search(this.state.currentPage);
        });
    }

    handleBranchListChange = (branchValue) => {
        this.setState({
            branchValue: branchValue
        }, () => {
            this.search(this.state.currentPage);
        });
    }

    searchByOption = () => {
        let field = "";
        if (this.state.optionSelected.value == "EFF_DT") {
            field = (
                <div className="policy-datepicker__body flex-wrap justify-content-start align-items-stretch h-1p8rem">
                    <div className="policy-datepicker__main d-flex align-items-center position-relative h-100">
                        <div className="form-control policy-datepicker__container rounded-pill border-0 h-100">
                            <DatePicker placeholderText="Select Date" className="policy-datepicker__input text-gray policy-datepicker__input--placeholder" selected={this.state.effDate} onChange={this.handleEffDateChange} />
                            <span className="icon-calendar text-gray"></span>
                        </div>
                    </div>
                </div>
            );
        } else if (this.state.optionSelected.value == "EXPIRY_DT") {
            field = (
                <div className="policy-datepicker__body flex-wrap justify-content-start align-items-stretch h-1p8rem">
                    <div className="policy-datepicker__main d-flex align-items-center position-relative h-100">
                        <div className="form-control policy-datepicker__container rounded-pill border-0 h-100">
                            <DatePicker placeholderText="Select Date" className="policy-datepicker__input text-gray policy-datepicker__input--placeholder" selected={this.state.expDate} onChange={this.handleExpDateChange} />
                            <span className="icon-calendar text-gray"></span>
                        </div>
                    </div>
                </div>
            );
        } else if (this.state.optionSelected.value == "status") {
            field = (<Select
                styles={{
                    option: (provided) => ({
                        ...provided,
                        fontFamily: "neo_semibold",
                        fontSize: ".6rem",
                        color: "#9A9B9C",
                        backgroundColor: "white"
                    }),
                    menu: () => ({
                        position: "absolute",
                        border: 0,
                        zIndex: "1090",
                        backgroundColor: "white",
                        borderRadius: "5px",
                        boxShadow: "2px 2px 2px #DDDEE0"
                    }),
                }}
                placeholder="Select..."

                //options
                options={this.state.policyStatusList}
                isSearchable={false}
                className={`datatable-select ml-0 ${this.props.isSm ? "w-100" : ""}`}
                classNamePrefix="datatable-select"
                value={this.state.policyStatusValue}
                onChange={this.handlePolicyStatusChange}
            />)
        } else if (this.state.optionSelected.value == "branchId") {
            field = (<Select
                styles={{
                    option: (provided) => ({
                        ...provided,
                        fontFamily: "neo_semibold",
                        fontSize: ".6rem",
                        color: "#9A9B9C",
                        backgroundColor: "white"
                    }),
                    menu: () => ({
                        position: "absolute",
                        border: 0,
                        zIndex: "1090",
                        backgroundColor: "white",
                        borderRadius: "5px",
                        boxShadow: "2px 2px 2px #DDDEE0"
                    }),
                }}
                placeholder="Select..."

                //options
                options={this.state.branchList}
                isSearchable={false}
                className={`datatable-select ml-0 ${this.props.isSm ? "w-100" : ""}`}
                classNamePrefix="datatable-select"
                value={this.state.branchValue}
                onChange={this.handleBranchListChange}
            />)
        }
        return field;
    }

    beginLoading = () => {
        this.setState({
            isLoading: true
        })
    }

    render() {
        let dataTable = "";

        const noteStyle = {
            fontSize: ".9em",
            color: "#255f8e",
        };
        const noteMessage = "Note: Updated every 7 AM (PST) daily, go to Policy Information to see the current status of the Policy. ";
        const intermediaryNote = "You may search the Intermediary name by their Last name , First name, or First name + Last name";

        let options = [
            { value: "status", label: "Policy Status" },
            { value: "branchId", label: "Location/Branch" },
            { value: "contractNumber", label: "Policy Number" },
            //{ value: "POL_DS", label: "Plan Name" },
            //{ value: "TOT_MODAL_PREM", label: "Modal Premium" },
            //{ value: "PMODE_CD", label: "Mode of Payment" },
            { value: "insuredName", label: "Person Insured Name" },
            { value: "contractHolderName", label: "Policy Owner Name" },
            //{ value: "EFF_DT", label: "Effective Date" },
            //{ value: "POL_CURR_CD", label: "Currency" },
            //{ value: "EXPIRY_DT", label: "Expiry Date" }
        ]
        if (this.state.isMgr) {
            options.push({ value: "faName", label: "Intermediary Name" })
        }
        if (this.state.isLoading) {
            return (
                <ReportLoader handleClose={this.hideModal} show={this.state.isLoading} />
            );
        } else if (!this.state.isAllowed) {
            return (
                <div className="dashboard-section overflow-hidden">
                    {this.showError()}
                </div>
            );
        } else {
            if (this.state.policies.length > 0) {
                dataTable = (
                    <Policies
                        policies={this.state.policies}
                        search={(pageNumber) => this.search(pageNumber)}
                        rows={this.state.rows}
                        pages={this.state.pages}
                        totalSize={this.state.totalSize}
                        beginLoading={() => this.beginLoading()}
                        isMgr={this.state.isMgr}
                        hasFa={this.state.hasFa}
                        headerClickedHandler={(sortByField) => { this.headerClickedHandler(sortByField) }}
                        currentPage={this.state.currentPage}
                        //totalSize={this.state.totalSize}
                        loadTable={this.state.loadTable}
                        errorMessage={this.errorMessage}
                        sortByField={this.state.sortByField}
                        sortOrder={this.state.sortOrder}
                        {...this.props}
                    />
                );
            } else {
                dataTable = (
                    <div className="center-align dashboard-row-data font-neo-semibold">No records found </div>
                );
            }
            return (
                <div className="dashboard-section overflow-hidden">
                    {this.showError()}
                    <div className="dashboard-container container-fluid p-3">
                        <div className="dashboard-header">
                            <h6 className="font-neo-bold text-darkgray">List of Newly Assigned Policies</h6>
                            {this.props.getViewAs}
                        </div>
                        <div className="dashboard-body">
                            <ClientPolicyNav newlyAssignedPolicyList="active" hasNewlyAssignedPolicies={true}/>
                            <div className="dashboard-datatable mt-4">
                                <DatatableSearch
                                    //DROPDOWN OPTION
                                    optionSelectedValue={this.state.optionSelected}
                                    updateOptionSelected={this.updateOptionSelected}
                                    //END DROPDOWN OPTION

                                    //SEARCH VALUE
                                    searchValue={this.state.searchValue}
                                    updateSearchValue={this.updateSearchValue}
                                    //END SEARCH VALUE

                                    //EFF DATE
                                    updateEffDate={this.handleEffDateChange}

                                    //EXP DATE
                                    updateExpDate={this.handleExpDateChange}

                                    searchByOption={this.searchByOption()}
                                    search={(pageNumber) => {
                                        this.setState({ loadTable: true }, () => {
                                            this.search(pageNumber)
                                        })
                                    }}
                                    // search={(options, search, pageNumber) => this.search(options, search, pageNumber)}
                                    styles={{
                                        option: (provided) => ({
                                            ...provided,
                                            fontFamily: "neo_semibold",
                                            fontSize: ".6rem",
                                            color: "#9A9B9C",
                                            backgroundColor: "white"
                                        }),
                                        menu: () => ({
                                            position: "absolute",
                                            border: 0,
                                            zIndex: "1090",
                                            backgroundColor: "white",
                                            borderRadius: "5px",
                                            boxShadow: "2px 2px 2px #DDDEE0"
                                        }),
                                    }}
                                    options={options}
                                />
                                <p className="m-3 font-italic" style={noteStyle}>{noteMessage}</p>
                                {(this.state.optionSelected.value === "faName" ? <p className="ml-3 text-danger font-italic" style={noteStyle}>{intermediaryNote}</p> : null)}
                                {dataTable}
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
}
export default withRouter(NewlyAssignedPolicyList);
