import React from 'react';

const ErrorMessage = (props) => {
    let returnValue;
    if (props.message.length > 0) {
        returnValue = (
            <div className="bg-error py-1 px-4">
                <p className="text-white mb-0 font-neo-semibold">ERROR: {props.message}</p>
            </div>)
    }
    else{
        returnValue = null;
    }
    return returnValue;
}

export default ErrorMessage;