import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';

import { apiModule } from '../commons/apiCall';
import { LoaderGif2 } from '../commons/loaderGif';
import ProgressIndicator from '../components/ClientApproval/progressIndicator';
import UserBirthdate from '../components/ClientApproval/userBirthdate';
import ReviewTransaction from '../components/ClientApproval/reviewTransaction';
import ConfirmTransaction from '../components/ClientApproval/confirmTransaction';

class Client extends Component {

    constructor(props) {
      super(props);
      this.state = {
          id: '',
          token: "",
          transactionId: "",
          baseUrl: '',
          userAction: "",
          progressStep: 1,
          contactInfo: {},
          policyInfo: {},
          subheaderText: [
            "Authentication",
            "Review Transaction",
            "Confirmation"
          ],
          clearScreen: false
      };
      
      this.props.match.params.id
        ? this.state.id = this.props.match.params.id
        : this.props.history.push('/');

      this.processTransactionData = this.processTransactionData.bind(this);
      this.setProgress = this.setProgress.bind(this);
      this.handleUserAction = this.handleUserAction.bind(this);
      this.clearScreen = this.clearScreen.bind(this);
      this.state.baseUrl = this.props.match.url;
      
      this.getFullApiToken();
    }

    componentDidMount() {
        const { match: { params } } = this.props;
        this.setState({id: params.id});
    }

    setProgress(progressStep) {
      this.setState({ progressStep });
    }

    async getFullApiToken() {
      const requestParams = {
        tokenAlias: this.state.id
      }
      await apiModule("get_api_token_from_alias", requestParams, null, '', true
      ).then((result) => {
        console.warn("SUCCESS", result)
        if(result.data.isLinkExpired == true){
          this.clearScreen();
        } else {
          this.setState({ token: result["data"]["access_token"] });
        }
      })
      .catch((err) => {
        console.error(err);
      });
      
      this.setState({ isNextDisabled: false });
    }

    async processTransactionData(result) {
      console.warn("PROCESSING DATA", result)
      const contactInfo = {
        "CURRENT": {},
        "NEW": {}
      };
      const policyInfo = {};
      
      const contractNumber = result.contractNumber;
      const requestParams = {
        contractNumber
      }

      await apiModule("user_contract", requestParams).then((result) => {
        if(result.contractHolder) {
          Object.assign(contactInfo['NEW'], { Mobile: { value: result.contractHolder.phoneNumber ? result.contractHolder.phoneNumber : '--'  } });
          Object.assign(contactInfo['CURRENT'], { Mobile: { value: result.contractHolder.phoneNumber ? result.contractHolder.phoneNumber : '--'  } });
          Object.assign(contactInfo['NEW'], { Email: { value: result.contractHolder.email ? result.contractHolder.email : '--' } });
          Object.assign(contactInfo['CURRENT'], { Email: { value: result.contractHolder.email ? result.contractHolder.email : '--' } });
        }        
      })
      .catch((err) => {
        console.error(err);
      });

      result.contactChannel.map(
        (contactChannel) => {
          const changeType = contactChannel.type === "Change in Mobile" ? "Mobile" : "Email";
          console.warn(changeType, contactInfo[contactChannel.remarks])
          Object.assign(contactInfo[contactChannel.remarks], { [changeType]: contactChannel });
        }
      );

      policyInfo["policyNumber"] = result.contractNumber;
      policyInfo["transactionNumber"] = result.transactionId;
      policyInfo["policyOwner"] = result.ownerName;
      policyInfo["insuredName"] = result.insuredName;
      policyInfo["isApplyToAllPolicies"] = result.isApplyToAllPolicies;
      
      console.warn(contactInfo, policyInfo);
      this.setState({ contactInfo, policyInfo, transactionId: result.transactionId });
      this.props.history.push(`${this.state.baseUrl}/review-transaction`);
    }

    handleUserAction(type) {
      console.warn("STATE", this.state)
      console.warn(type);
      let userAction = "";
      if (type === "accept") {
        userAction = "Accept";
      } else if (type === "decline") {
        userAction = "Reject";
      } else {
        userAction = "Did Not Initiate";
      }

      this.submitUserApprovalAction(userAction);
    }
    
    async submitUserApprovalAction(remarks) {
      console.warn(this.state);
      const requestParams = {
        x_auth: this.state.token,
        transactionId: this.state.transactionId
      }
      const apiData = {
        remarks
      }
      await apiModule("user_transactions_id", requestParams, apiData, '', true
      ).then((result) => {
        this.setState({ userAction: remarks });
        console.warn("SUCCESS", result)
        this.props.history.push(`${this.state.baseUrl}/confirm-transaction`);
      })
      .catch((err) => {
        console.error(err);
      });
    }

    clearScreen() {
      this.setState({clearScreen: true});
    }

    render() {
      if(this.state.clearScreen) {
        return (
          <section className="client-approval d-flex">
            <aside className="client-approval__img-wrapper">
              <img className="client-approval__img auth__img" src={require('../assets/img/bg-login.jpg')} alt="Authentication background" />
            </aside>
            <main className="client-approval__user-content d-flex justify-content-center align-items-center flex-grow-1">
              <article className="client-approval__content">
                <header className="client-approval__content-header d-flex flex-column align-items-center">
                  <img className="client-approval__logo auth-logo" src={require("../assets/img/logo.png")} alt="Allianz Logo" />
                </header>
                <section className="client-approval__user-area client-approval__user-area--loading">
                  <div className="client-approval__error-message">
                    <span className="client-approval__error-text">Oops! The link has expired. You may contact your agent for any request to update your information.</span>
                  </div>
                </section>
              </article>
            </main>
          </section>
        );
      }
      else if (this.state.token) {
            return (
              <section className="client-approval d-flex">
                <aside className="client-approval__img-wrapper">
                  <img className="client-approval__img auth__img" src={require('../assets/img/bg-login.jpg')} alt="Authentication background" />
                </aside>
                <main className="client-approval__user-content d-flex justify-content-center align-items-center flex-grow-1">
                  <article className="client-approval__content">
                    <header className="client-approval__content-header d-flex flex-column align-items-center">
                      <img className="client-approval__logo auth-logo" src={require("../assets/img/logo.png")} alt="Allianz Logo" />
                      Update your contact information in 3 easy steps.
                      <ProgressIndicator step={this.state.progressStep} />
                      <span className="client-approval__subheader">{this.state.subheaderText[this.state.progressStep - 1]}</span>
                    </header>
                    <section className="client-approval__user-area">
                      <Switch>
                        <Route
                          exact
                          path={this.state.baseUrl + "/review-transaction"}
                          render={() =>
                            <ReviewTransaction
                              policyContactInfo={this.state.contactInfo}
                              policyInfo={this.state.policyInfo}
                              history={this.props.history}
                              handleClick={this.handleUserAction}
                              setProgress={this.setProgress}
                            />
                          }
                        />
                        <Route
                          exact
                          path={this.state.baseUrl + "/confirm-transaction"}
                          render={() =>
                            <ConfirmTransaction
                              userAction={this.state.userAction}
                              setProgress={this.setProgress}
                              history={this.props.history}
                            />
                          }
                        />
                        <Route
                          exact
                          path={this.state.baseUrl}
                          render={() =>
                            <UserBirthdate
                              {...this.props}
                              {...this.state}
                              processData={this.processTransactionData}
                              setProgress={this.setProgress}
                              clearScreen={this.clearScreen}
                            />
                          }
                        />
                      </Switch>
                    </section>
                  </article>
                </main>
              </section>
          );
        } else {
            return (
              <section className="client-approval d-flex">
                <aside className="client-approval__img-wrapper">
                  <img className="client-approval__img auth__img" src={require('../assets/img/bg-login.jpg')} alt="Authentication background" />
                </aside>
                <main className="client-approval__user-content d-flex justify-content-center align-items-center flex-grow-1">
                  <article className="client-approval__content">
                    <header className="client-approval__content-header d-flex flex-column align-items-center">
                      <img className="client-approval__logo auth-logo" src={require("../assets/img/logo.png")} alt="Allianz Logo" />
                      Update your Personal Data in 3 easy steps
                    </header>
                    <section className="client-approval__user-area client-approval__user-area--loading">
                      <div className="client-approval__loader"><LoaderGif2 /></div>
                      <span className="client-approval__loader-text">Loading...</span>
                    </section>
                  </article>
                </main>
              </section>
          );
        }
    }
}
export default withRouter(Client);
