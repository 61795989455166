import React, { Component } from 'react';
import { ReportLoader } from '../commons/utilityViews.js';
import { withRouter, Link } from 'react-router-dom';
import 'react-vertical-timeline-component/style.min.css';
import { writeData, numberWithCommas, prettifyDate, fixName } from '../commons/utility.js';
import ReactTable from 'react-table';
import ClientPolicyNav from '../components/clientPolicyNav';
import routeChange from '../commons/routeChange';
import DatatableSearch from '../components/datatableSearch';
import Insured from '../components/Applications/insured';

class SearchResults extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,

        };
    }

    /*getSearchLoading={() => this.getSearchLoading()}
        getSearchMessage={() => this.getSearchMessage()}
        */
    policyListData = (x, rows) => {
        let results = this.props.getSearchResults();
        console.log(results);

        const columns = [
            {
                Header: 'Policy Number',
                // id: 'policyNumber',
                // accessor: d => Number(d.policyNumber),
                accessor: 'contractNumber'
            },
            {
                Header: "Policy Owner",
                id: "contractHolder",
                accessor: (data) => data.contractHolder ? `${data.contractHolder.lastName}, ${data.contractHolder.firstName} ${data.contractHolder.middleName}` : "--"
            },
            {
                Header: "Insured",
                accessor: 'insured',
                Cell: props => <Insured insured={props.value} />
            },
            {
                Header: 'Plan Name',
                accessor: 'productOfferingName',
            },
            /*
            **removing policy status due to inconsistency with the data source
            {
                Header: 'Status',
                accessor: 'status',
                Cell: props => <button className={"dashboard-datatable__button btn rounded-pill text-uppercase text-white " + props.value.toLowerCase()}>{props.value}</button>
            },
            */
            {
                Header: "Modal Premium",
                id: 'netPremium',
                accessor: d => numberWithCommas(d.netPremium),
            },
            {
                Header: "Mode of Payment",
                accessor: 'paymentFrequency'
            },
            {
                Header: "Face Amount",
                id: 'contractBalance',
                accessor: d => numberWithCommas(d.contractBalance),
            },
            {
                Header: "Effective Date",
                id: 'inceptionDate',
                accessor: d => prettifyDate(d.inceptionDate),
            },
            {
                Header: "Contract Branch",
                accessor: 'branchName',
            },
            {
                Header: "Currency",
                accessor: "currency",
                minWidth: 50,
                // maxWidth: 100
            },
            {
                Header: "Expiry Date",
                id: "contractInterval.endTimeDate",
                accessor: d => prettifyDate(d.contractInterval.endDateTime),
            }
        ]
        const data = [];
        if (x == "data") {

        } else if (x == "columns") {
            return columns;
        }
    }
    showError = () => {
        let message = '';
        let error = this.props.getSearchError();
        if (error !== '') {
            message =
                <div className="bg-error py-1 px-4">
                    <p className="text-white mb-0 font-neo-semibold">ERROR: {error}</p>
                </div>
        }
        return message;
    }
    onClickAction = (item) => {
        console.log(item.original.contractNumber);

        this.props.setClientOrPolicy({ policyId: item.original.contractNumber });

        writeData('policy-client', { policyId: item.original.contractNumber, id: 1 });
        routeChange(this.props.history, "/dashboard/single-policy");
    }
    render() {
        if (this.props.getSearchLoading()) {
            return (
                <ReportLoader handleClose={this.hideModal} show={this.props.getSearchLoading()} />
            );
        }
        else if (this.props.getSearchResults() != null && this.props.getSearchResults().length > 0) {
            return (
                <div className="dashboard-section overflow-hidden">
                {this.showError()}
                    <div className="dashboard-container container-fluid p-3">

                        <div className="dashboard-header">
                            <h6 className="font-neo-bold text-darkgray">Search Results for "{this.props.getSearchQuery()}"</h6>
                            {this.props.getViewAs}
                        </div>

                        <div className="dashboard-body">
                            <div className="dashboard-datatable mt-4">

                                <ReactTable className="result-table mt-4" resizable={false} data={this.props.getSearchResults()}
                                    columns={this.policyListData("columns")}
                                    defaultPageSize={6}
                                    minRows={0}
                                    getTrProps={(state, rowInfo, column, instance) => (
                                        { onClick: () => this.onClickAction(rowInfo) }
                                    )}
                                    showPagination={false}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
           return  (
            <div className="dashboard-section overflow-hidden">
            {this.showError()}
            <div className="dashboard-container container-fluid p-3">

                <div className="dashboard-header">
                    <h6 className="font-neo-bold text-darkgray">Search Results for "{this.props.getSearchQuery()}"</h6>
                    {this.props.getViewAs}
                </div>

                <div className="dashboard-body">
                    <div className="dashboard-datatable mt-4">
                        <div className="center-align dashboard-row-data font-neo-semibold">No records found </div>
                    </div>
                </div>
            </div>
            </div>
            );
        }
    }
}
export default withRouter(SearchResults);
