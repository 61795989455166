import React, { Component } from 'react';
import Chart from 'chart.js';
import { PERSISTENCY_TOOLTIP_SEQUENCE } from '../../commons/misConfig';
import { myConfig } from '../../commons/config';
class faPersistencyChart extends Component {

    constructor(props) {
        super(props);

        this.state = {
            activeChart: null,
            height: 330,
            data: props.data ? props.data : null,
            metricLabels: [],
        }

        this.renderChart = this.renderChart.bind(this);
    }

    componentDidMount() {
        if (this.state.data !== null && this.hasData(this.state.data.datasets)) {
            this.renderChart();
        }
    }

    componentWillReceiveProps(nextProps) {
        let t = this;
        this.setState({
            data: nextProps.data ? nextProps.data : null,
        }, function () {
            if (t.state.data !== null && this.hasData(this.state.data.datasets)) {
                t.renderChart();
            }
        });
    }

    componentWillUnmount(){
        let tooltipEl = document.getElementById('chartjs-tooltip-linegraph');
        if(tooltipEl){
            document.getElementById('chartjs-tooltip-linegraph').remove();
        }
    }

    fitTextOnCanvas(text, fontface, min, max, ctx, maxWidth) {
        var size = this.measureTextBinaryMethod(text, fontface, min, max, maxWidth, ctx);
        return size;
    }

    measureTextBinaryMethod(text, fontface, min, max, desiredWidth, ctx) {
        if (max - min < 1) {
            return min;
        }
        var test = min + ((max - min) / 2); //Find half interval
        ctx.font = test + "px " + fontface;
        var measureTest = ctx.measureText(text).width;
        var found;
        if (measureTest > desiredWidth) {
            found = this.measureTextBinaryMethod(text, fontface, min, test, desiredWidth, ctx)
        } else {
            found = this.measureTextBinaryMethod(text, fontface, test, max, desiredWidth, ctx)
        }
        return found;
    }

    getBlockPct(blockSize, data){
        return blockSize / (data.maxVal - data.minVal > 0 ? data.maxVal - data.minVal : blockSize);
        // return blockSize / 100;
    }

    renderChart() {
        let { activeChart, data } = this.state;
        let { getSession, togglePersistencyItem } = this.props;

        let hasData = data ? data.datasets ? data.datasets.length !== 0 ? true : false : false : false;
        if (hasData === true) {
            let t = this;
            if (document.getElementById('faPersistencyGraph')) {
                if (activeChart !== null) {
                    activeChart.destroy();
                    this.setState({
                        activeChart: null
                    }, function () {
                        t.forceUpdate();
                    });
                }
            }

            Chart.pluginService.register({
                beforeDraw: function (chart, easing) {
                    var ctx = chart.ctx;
                    var chartArea = chart.chartArea;

                    if (chart.canvas.id === "faPersistencyGraph") {
                        ctx = chart.ctx;
                        // console.log(chart);
                        let metricLabels = [];
                        let points = [];
                        let lastItemPerPoint = [];
                        let datasetsCount = chart.data ? chart.data.datasets ? chart.data.datasets.length : 0 : 0; // IDENTIFY DATA SET COUNT / LENGTH
                        // console.log('datasetsCount', datasetsCount, chart);
                        for (let index = 0; index < datasetsCount; index++) { // GET ALL DATA POINTS
                            // console.log(index, t.props.data.datasets);
                            if (chart.getDatasetMeta(index) !== undefined) {
                                points.push(
                                    chart.getDatasetMeta(index).data
                                );
                            }
                        }

                        points.map(function (point, index) { // GET LAST ITEM OF EVERY DATA SET POINTS
                            if (point.length - 1 >= 0) {
                                for(let i = point.length - 1; i >= 0 ; i--){
                                    let dataValue = chart.data.datasets[index].data[i].y !== null ? chart.data.datasets[index].data[i].y : 0;
                                    if(dataValue > 0){
                                        lastItemPerPoint[index] = point[i];
                                        break;
                                    }
                                    else{
                                        lastItemPerPoint[index] = point[i];
                                    }
                                }
                                // lastItemPerPoint[index] = point[point.length - 1];
                            }
                            return null;
                        });

                        lastItemPerPoint.map(function (point, index) { // GET X, Y, LABEL, AND VALUE, AND PUSH THEM TO METRIC LABELS
                            var positionX = point._model.x ? point._model.x : 0;
                            var positionY = point._model.y ? point._model.y : 0;
                            // var label = chart.data.datasets[point._datasetIndex].label ? chart.data.datasets[point._datasetIndex].label : "--";
                            // var value = chart.data.datasets[point._datasetIndex].data[chart.data.datasets[point._datasetIndex].data.length - 1].y !== null ? chart.data.datasets[point._datasetIndex].data[chart.data.datasets[point._datasetIndex].data.length - 1].y : "--";
                            // var label = chart.data.datasets[point._datasetIndex].label ? chart.data.datasets[point._datasetIndex].label : '--';
                            // var value = chart.data.datasets[point._datasetIndex].data[chart.data.datasets[point._datasetIndex].data.length - 1].y !== null ? chart.data.datasets[point._datasetIndex].data[chart.data.datasets[point._datasetIndex].data.length - 1].y : 0;
                            var label = chart.data.datasets[point._datasetIndex].label ? chart.data.datasets[point._datasetIndex].label : '--';
                            var value = chart.data.datasets[point._datasetIndex].data[point._index].y !== null ? chart.data.datasets[point._datasetIndex].data[point._index].y : 0;

                            // if(value > 0){
                            //     metricLabels.push({
                            //         x: positionX,
                            //         // y: positionY,
                            //         y: value,
                            //         label: label,
                            //         value: value,
                            //     });
                            // }

                            metricLabels.push({
                                x: positionX,
                                // y: positionY,
                                y: value,
                                label: label,
                                value: value,
                            });
                            return null;
                        });

                        metricLabels = metricLabels.sort((label0, label1) => (label0.value < label1.value) ? 1 : -1);
                        var height = t.state.height - 26; // SUBTRACT OFFSET FOR X-AXIS LABELS
                        var prev = 0;
                        var pct = 0;
                        var ctr = 0;
                        var stackLabelsValue = 0;
                        // console.log('===========')
                        metricLabels.map(function (metric, index) { // STEP 1 POSITION CALCULATION (INITIAL)
                            //Showing 0 Values
                            // let calculatedMetricY = 1 - (metric.y / 100);
                            //Showing no 0 Values
                            let calculatedMetricY = 1 - (metric.y - data.minVal) / (100 - data.minVal); //GET CALCULATED MARGIN TOP BASED ON SIMPLIFIED FRACTION BETWEEN METRIC VALUE AND CHART MAX VALU (100)
                            // pct = (height * (100 - metric.y)) * 0.01; // GET MARGIN TOP HEIGHT (FROM 100% of CANVAS HEIGHT SUBTRACT POINT VALUE) IN PIXEL
                            pct = (height * calculatedMetricY) - 10; // GET MARGIN TOP HEIGHT (FROM 100% of CANVAS HEIGHT SUBTRACT POINT VALUE) IN PIXEL
                            // metric.y = (pct - prev) - (ctr * 20) >= 0 ? (pct - prev) - (ctr * 20) : 0 // SET HEIGHT FROM PCT SUBTRACT BY PREVIOUS PCT
                            metric.y = (pct - prev) - (stackLabelsValue + ctr * 20) >= 0 ? (pct - prev) - (stackLabelsValue + ctr * 20) : 0 // SET HEIGHT FROM PCT SUBTRACT BY PREVIOUS PCT

                            // prev += pct;
                            prev = pct;

                            ctr = ctr ? ctr : ctr +=1;
                            stackLabelsValue = metric.y === 0 ? stackLabelsValue+=15 : 0;

                            return null;
                        });
                        var hasOverflow = false;
                        var totalHeight = 0;
                        var maxAntiOverflow = 10; // # of retries to fix overflow.

                        metricLabels.map(function (metric, index) { // STEP 2 POSITION CALCULATION (OVERFLOW CHECK)
                            totalHeight += metric.y + 20; // THE + ## IS FROM THE DATA POINT LABEL HEIGHT
                            if (totalHeight >= height && hasOverflow === false) {
                                hasOverflow = true;
                            }
                            return null;
                        });

                        if (hasOverflow === true) {
                            for (let index = 0; index < maxAntiOverflow; index++) {
                                totalHeight = 0;
                                hasOverflow = false;

                                metricLabels.map(function (metric, index) { // STEP 2 POSITION CALCULATION (OVERFLOW CHECK)
                                    totalHeight += metric.y + 20; // THE + ## IS FROM THE DATA POINT LABEL HEIGHT
                                    if (totalHeight >= height && hasOverflow === false) {
                                        hasOverflow = true;
                                    }
                                    return null;
                                });
                                if (hasOverflow === true) {
                                    // console.log('hasOverflow', hasOverflow, totalHeight, ctr, 'trial# ' + index);
                                    // console.log('!!========!!')
                                    metricLabels.map(function (metric, index1) { // STEP 3 POSITION CALCULATION (OFFSET/CENTER)
                                        metric.y = metric.y - 20 >= 0 ? metric.y - 20 : metric.y;
                                        // console.log(metric.label + ": " + metric.y);
                                        return null;
                                    });
                                }
                            }
                        }

                        t.setState({
                            metricLabels: metricLabels,
                        });
                        t.forceUpdate();

                        if(getSession.channel === 'BANCA'){
                            ctx.save();

                            let chartBlockPctQtrBonus = t.getBlockPct(10, data);
                            let chartBlockPctAllowance = t.getBlockPct(10, data);
                            let canvasBGSize = (chartArea.bottom - chartArea.top);
                            let backgroundColorQtrBonusSize = canvasBGSize * (chartBlockPctQtrBonus > 1 ? 1 : chartBlockPctQtrBonus);
                            let backgroundColorAllowanceSize = canvasBGSize * (chartBlockPctAllowance > 1 ? 1 : chartBlockPctAllowance);
                            backgroundColorAllowanceSize = (backgroundColorQtrBonusSize + backgroundColorAllowanceSize) > canvasBGSize ? canvasBGSize - backgroundColorQtrBonusSize : backgroundColorAllowanceSize;

                            // ctx.fillStyle = "#EECCD5"; // the rest
                            ctx.fillStyle = "#C1EBFB"; // the rest
                            ctx.fillRect(chartArea.left, chartArea.top, chartArea.right - chartArea.left, (chartArea.bottom - chartArea.top) - 0);

                            // ctx.fillStyle = "#C0DDBD"; // 90% above
                            ctx.fillStyle = "#2EC0F9"; // 90% above
                            // ctx.fillRect(chartArea.left, chartArea.top, chartArea.right - chartArea.left, ((chartArea.bottom - chartArea.top) / 10) + 5);
                            ctx.fillRect(chartArea.left, chartArea.top, chartArea.right - chartArea.left, backgroundColorQtrBonusSize);

                            // ctx.fillStyle = "#FFE8B0"; // 80% to 89%
                            ctx.fillStyle = "#96DCFA"; // // 80% to 89%
                            // ctx.fillRect(chartArea.left, chartArea.top + ((chartArea.bottom - chartArea.top) / 10) + 5, chartArea.right - chartArea.left, ((chartArea.bottom - chartArea.top) / 10) + 5);
                            ctx.fillRect(chartArea.left, chartArea.top + (chartArea.bottom - chartArea.top) * chartBlockPctQtrBonus, chartArea.right - chartArea.left, backgroundColorAllowanceSize);

                            // var centerpoint = (chartArea.right - chartArea.left) / 2;
                            var centerpoint = (chart.width / 2);
                            // ctx.strokeStyle = "red"; // CENTER TEST
                            // ctx.moveTo(centerpoint, 20);
                            // ctx.lineTo(centerpoint, 250);
                            // ctx.stroke();

                            var fontSize = t.fitTextOnCanvas("QUARTERLY BONUS (90%)", "neo_semibold", 0, 20, ctx, chart.width - 50) // CONSISTENCY
                            ctx.font = fontSize + "px neo_semibold";
                            ctx.fillStyle = "rgba(0, 0, 0, 0.35)";
                            ctx.textAlign = "center";
                            // ctx.fillText("+ ALLOWANCE & QUARTERLY BONUS (89%)", centerpoint, fontSize + 5);
                            // ctx.fillText("QUARTERLY BONUS (90%)", centerpoint, (canvasBGSize * chartBlockPctQtrBonus - (canvasBGSize * .1)) + fontSize + 2.5);
                            ctx.fillText("QUARTERLY BONUS (90%)", centerpoint, backgroundColorQtrBonusSize - (canvasBGSize * .1) + fontSize + 2.5);
                            // ctx.fillText("+ ALLOWANCE (80%)", centerpoint, chartArea.top + ((chartArea.bottom - chartArea.top) / 10) + fontSize + 10);
                            // ctx.fillText("ALLOWANCE (80%)", centerpoint, chartArea.top + (canvasBGSize * chartBlockPctGreen) + fontSize + 2.5);
                            // ctx.fillText("ALLOWANCE (80%)", centerpoint, chartArea.top + (canvasBGSize * (chartBlockPctAllowance + chartBlockPctQtrBonus) - (canvasBGSize * .1)) + fontSize + 2.5);
                            if(data.minVal < 90){
                                ctx.fillText("ALLOWANCE (80%)", centerpoint, backgroundColorQtrBonusSize + backgroundColorAllowanceSize - (canvasBGSize * .1) + fontSize + 2.5);
                            }
                            ctx.restore();
                        }
                        else if(getSession.channel === 'AGENCY'){
                            ctx.save();

                            let chartBlockPctPersistencyTarget= t.getBlockPct(20, data);
                            let canvasBGSize = (chartArea.bottom - chartArea.top);
                            let backgroundColorPersistencyTargetSize = canvasBGSize * (chartBlockPctPersistencyTarget > 1 ? 1 : chartBlockPctPersistencyTarget);

                            ctx.fillStyle = "#C1EBFB"; // the rest
                            ctx.fillRect(chartArea.left, chartArea.top, chartArea.right - chartArea.left, (chartArea.bottom - chartArea.top) - 0);
                            ctx.fillStyle = "#2EC0F9"; // 80% above
                            ctx.fillRect(chartArea.left, chartArea.top, chartArea.right - chartArea.left, backgroundColorPersistencyTargetSize);

                            var centerpoint = (chart.width / 2);
                            var fontSize = t.fitTextOnCanvas("PERSISTENCY TARGET (80%)", "neo_semibold", 0, 20, ctx, chart.width - 50) // CONSISTENCY
                            ctx.font = fontSize + "px neo_semibold";
                            ctx.fillStyle = "rgba(0, 0, 0, 0.35)";
                            ctx.textAlign = "center";

                            ctx.fillText("PERSISTENCY TARGET (80%)", centerpoint, backgroundColorPersistencyTargetSize - (canvasBGSize * .1) + fontSize + 2.5);

                            ctx.restore();
                        }
                    }
                }
            });
            Chart.defaults.LineWithDash = Chart.defaults.line;
            Chart.controllers.LineWithDash = Chart.controllers.line.extend({
                draw: function (ease) {
                    Chart.controllers.line.prototype.draw.call(this, ease);

                    if (this.chart.tooltip._active && this.chart.tooltip._active.length) {
                        var activePoint = this.chart.tooltip._active[0],
                            ctx = this.chart.ctx,
                            x = activePoint.tooltipPosition().x,
                            topY = this.chart.scales['y-axis-0'].top,
                            bottomY = this.chart.scales['y-axis-0'].bottom;

                        // draw line
                        ctx.save();
                        ctx.beginPath();
                        ctx.moveTo(x, topY);
                        ctx.lineTo(x, bottomY);
                        ctx.lineWidth = 2;
                        ctx.strokeStyle = 'rgba(0,0,0,0.1)';
                        ctx.stroke();
                        ctx.restore();
                    }
                }
            });
            let ctx = document.getElementById('faPersistencyGraph').getContext('2d');
            let dataChart = new Chart(ctx, {
                type: 'LineWithDash',
                data: data,
                options: {
                    // legendCallback: function (chart) {
                    //     let text = [];
                    //     text.push('<ul class="' + chart.id + '-legend customLegend">');
                    //     for (let i = 0; i < chart.data.datasets.length; i++) {
                    //         if (chart.data.length <= 0) {
                    //             break;
                    //         }

                    //         if (chart.data.datasets[i].data.length <= 0) {
                    //             continue;
                    //         }

                    //         let lineStyle = '<div style="background-color:' + chart.data.datasets[i].backgroundColor + ';" class="legend-solid-line"></div>';

                    //         text.push('<li class="legendTransition ">' + lineStyle + '<p>');
                    //         if (chart.data.datasets[i].label) {
                    //             text.push(chart.data.datasets[i].label);
                    //         }
                    //         text.push('</p></li>');
                    //     }
                    //     text.push('</ul>');
                    //     return text.join("");

                    // },
                    responsive: true,
                    maintainAspectRatio: false,
                    title: {
                        display: false,
                    },
                    tooltips: {
                        mode: 'index',
                        intersect: false,
                        enabled: false,
                        custom: function (tooltipModel) {
                            // Tooltip Element
                            let tooltipEl = document.getElementById('chartjs-tooltip-linegraph');
                            // Create element on first render
                            if (!tooltipEl) {
                                let graphContainer = document.getElementById('faPersistencyGraph-canvas')
                                tooltipEl = document.createElement('div');
                                tooltipEl.id = 'chartjs-tooltip-linegraph';
                                tooltipEl.innerHTML = '<table></table>';
                                tooltipEl.style.zIndex = 5001;
                                graphContainer.appendChild(tooltipEl);
                            }

                            // Hide if no tooltip
                            if (tooltipModel.opacity === 0) {
                                tooltipEl.style.opacity = 0;
                                return;
                            }

                            // Set caret Position
                            tooltipEl.classList.remove('above', 'below', 'no-transform');
                            if (tooltipModel.yAlign) {
                                tooltipEl.classList.add(tooltipModel.yAlign);
                            } else {
                                tooltipEl.classList.add('no-transform');
                            }

                            function getBody(bodyItem) {
                                return bodyItem.lines;
                            }

                            // Set Text
                            if (tooltipModel.body) {
                                let titleLines = tooltipModel.title || [];
                                let bodyLines = tooltipModel.body.map(getBody);

                                let innerHtml = '<thead>';

                                titleLines.forEach(function (title) {
                                    innerHtml += '<tr><th>' + title + '</th></tr>';
                                });
                                innerHtml += '</thead><tbody>';

                                let tooltipBodyLines = [];
                                bodyLines.forEach(function (body, i) {
                                    let isRP = body[0].label.toUpperCase().includes('RP');
                                    let colors = tooltipModel.labelColors[i];
                                    let style = 'background-color:' + colors.backgroundColor;
                                    style += '; border-color:' + colors.borderColor;
                                    style += '; padding: 0.25px 8px';
                                    style += '; margin-right: 2px';
                                    style += '; border-width: 1px;';
                                    style += isRP ? '; border-style: dashed;' : '; border-style: solid;';
                                    let span = '<span style="' + style + '"></span>';
                                    // innerHtml += '<tr><td>' + span + body + '</td></tr>';
                                    // innerHtml += '<tr><td>' + span + body[0].label + ': <b style="font-weight:800;color:' + body[0].color + '">' + body[0].value + '%</b></td></tr>';
                                    let bodyLine = '<tr><td>' + span + body[0].label + ': <b style="font-weight:800;color:' + body[0].color + '">' + body[0].value + '%</b></td></tr>';
                                    let sequenceNumber = PERSISTENCY_TOOLTIP_SEQUENCE.indexOf(body[0].label.toUpperCase());
                                    if(sequenceNumber !== -1){
                                        tooltipBodyLines[sequenceNumber] = bodyLine;
                                    }
                                });

                                tooltipBodyLines.forEach(function (body, i) {
                                    if(body !== null || body !== undefined){
                                        innerHtml += body;
                                    }
                                });

                                innerHtml += '</tbody>';

                                var tableRoot = tooltipEl.querySelector('table');
                                tableRoot.innerHTML = innerHtml;
                            }

                            let offsetX = 0, offsetY = 0;
                            // `this` will be the overall tooltip
                            var position = this._chart.canvas.getBoundingClientRect();

                            if (tooltipModel.caretX + tooltipModel.width > this._chart.width) {
                                offsetX = (tooltipModel.caretX + tooltipModel.width) - this._chart.width;
                            }

                            if (tooltipModel.caretY + tooltipModel.height > this._chart.height) {
                                offsetY = (tooltipModel.caretY + tooltipModel.height) - this._chart.height;
                            }

                            // Display, position, and set styles for font
                            tooltipEl.style.opacity = 'inherit';
                            tooltipEl.style.position = 'absolute';
                            // tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX - offsetX + 'px';
                            // tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY - offsetY + 'px';
                            tooltipEl.style.left = tooltipModel.caretX - offsetX + 'px';
                            tooltipEl.style.top = tooltipModel.caretY - offsetY + 'px';
                            tooltipEl.style.fontFamily = 'neo_regular'
                            tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
                            tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
                            tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
                            tooltipEl.style.borderRadius = '10px';
                            tooltipEl.style.pointerEvents = 'none';
                            tooltipEl.style.backgroundColor = 'rgba(0,0,0,0.8)';
                            tooltipEl.style.color = 'rgba(255,255,255,1)';
                            tooltipEl.style.textAlign = 'left';
                        },
                        callbacks: {
                            label: function (toolTipItem, data) {
                                let color = toolTipItem.yLabel >= 0 ? '#FFFFFF' : '#E4003A';
                                return {
                                    label: data.datasets[toolTipItem.datasetIndex].label,
                                    value: toolTipItem.yLabel,
                                    color: color
                                };
                            },
                        }
                    },
                    hover: {
                        mode: 'x-axis',
                        intersect: false
                    },
                    scales: {
                        yAxes: [{
                            ticks: {
                                fontFamily: 'neo_regular',
                                padding: 7,
                                callback: function (label, index, labels) {
                                    // return formatValues(label);
                                    return label;
                                },
                                min: data.minVal,
                                max: data.maxVal,
                                display: false,
                            },
                            scaleLabel: {
                                display: false
                            },
                            gridLines: {
                                display: false,
                                // drawTicks: false,
                                tickMarkLength: 0,
                                color: 'rgba(90,57,130,0.15)',
                            }

                        }],
                        xAxes: [{
                            gridLines: {
                                display: true,
                                color: 'rgba(90,57,130,0.15)',
                                // tickMarkLength: 0,
                            },
                            ticks: {
                                fontFamily: 'neo_regular',
                                autoSkip: t.props.isSm,
                                maxRotation: 0,
                                maxTicksLimit: 12,
                                callback: function(value, index, values){
                                    return value;
                                },
                            },
                            scaleLabel: {
                                padding: 8
                            }
                        }]
                    },
                    legend: {
                        display: false,
                        fontSize: '50px',
                        labels: {
                            filter: function (item, chart) {
                                if (chart.datasets[item.datasetIndex].data.length <= 0) {
                                    return !item.text.includes(item.text);
                                }
                                return item;
                            }
                        }
                    },
                    // onClick: function() {
                    //     togglePersistencyItem();
                    // }
                }
            });

            // let legendContainer = document.getElementById('line-legend-id');
            // legendContainer.innerHTML = dataChart.generateLegend();
            // let legendItems = legendContainer.querySelectorAll('li');

            // for (let i = 0; i < dataChart.legend.legendItems.length; i++) {
            //     legendItems[i].addEventListener("click", function (event) {
            //         event = event || window.event;

            //         let target = event.target || event.srcElement;
            //         while (target.nodeName !== 'LI') {
            //             target = target.parentElement;
            //         }

            //         dataChart.legend.options.onClick.call(dataChart, event, dataChart.legend.legendItems[i]);
            //         if (!dataChart.legend.legendItems[i].hidden) {
            //             target.classList.remove('grayscale');
            //         } else {
            //             target.classList.add('grayscale');
            //         }
            //     }, false);
            // }

            document.getElementById("faPersistencyGraph").onclick = (evt) => {
                let activePoint = dataChart.getElementAtEvent(evt);

                if (activePoint.length > 0) {
                    let clickedDatasetIndex = activePoint[0]._datasetIndex;
                    let clickedElementIndex = activePoint[0]._index;
                    let label = dataChart.data.labels[clickedElementIndex];
                    let value = dataChart.data.datasets[clickedDatasetIndex].data[clickedElementIndex];
                    togglePersistencyItem(clickedDatasetIndex, clickedElementIndex);
                    // Remove tooltip
                    const tooltipEl = document.getElementById('chartjs-tooltip-linegraph');
                    if(tooltipEl){
                        document.getElementById('chartjs-tooltip-linegraph').remove();
                    }
                }
            }

            this.setState({
                activeChart: dataChart
            });
        }
    }

    hasData(datasets){
        if(datasets instanceof Object){
            for(let i = 0; i < datasets.length; i++){
                if(datasets[i].data && datasets[i].data.length){
                    for(let obj = 0; obj < datasets[i].data.length; obj++){
                        if(datasets[i].data[obj].y !== null && datasets[i].data[obj].y !== 0){
                            return true;
                        }
                    }
                }
            }
        }

        return false;
    }

    render() {
        let { height, data, metricLabels } = this.state;
        let hasData = data ? data.datasets ? data.datasets.length !== 0 ? true : false : false : false;
        let hasDatasetValues = data && this.hasData(data.datasets);
        return (
            <div id="faPersistencyGraph-canvas" className="faPersistencyGraph-canvas col-md-12 col-xs-12 col-sm-12 col-col-lg-12">
                <div className="row">
                    {/* <div className="line-legend" id="line-legend-id" /> */}
                    {hasData === true && hasDatasetValues ? '' : <p className="faPersistencyGraph-no-data-show">NO DATA TO SHOW</p>}
                    {hasData === true && hasDatasetValues ? <div
                        className="col-md-10 col-xs-10 col-lg-10 col-sm-10"
                        style={{ padding: '0px', height: height ? height : '330px' }}>
                        <canvas
                            alt={this.props.alt}
                            id={"faPersistencyGraph"}
                            style={{ opacity: hasData === true && hasDatasetValues ? '1' : '0' }} />
                    </div> : <div
                        className="col-md-10 col-xs-10 col-lg-10 col-sm-10"
                        style={{ padding: '0px', height: height ? height : '330px' }}></div>}
                    {!this.props.isSm || window.innerWidth > this.props.sm ?
                        <div style={{ padding: '0px', position: 'relative', display: 'block', height: height ? height : '330px' }}
                            className="col-md-2 col-xs-2 col-lg-2 col-sm-2">
                            {metricLabels.map(function (label, index) {
                                return <label className="faPersistencyGraph-point-value"
                                    key={index}
                                    style={{
                                        marginTop: label.y + 'px'
                                    }}>{label.label} ({label.value}%)</label>
                            })}
                        </div>
                        :
                        <div style={{ padding: '0px', position: 'relative', display: 'block', }}
                            className="col-md-12 col-xs-2 col-lg-12 col-sm-12">
                            {metricLabels.map(function (label, index) {
                                return <label className="faPersistencyGraph-point-value-sm"
                                    key={index}>{label.label} ({label.value}%)</label>
                            })}
                        </div>
                    }
                </div>
            </div>
        )
    }
}


export default faPersistencyChart;
