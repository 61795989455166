import React, { Component } from "react";
import { apiModule } from "../../commons/apiCall";
import withWindowDimensions from "../../commons/withWindowDimensions";
import SalesIllustration from "./OtherInfoModals/salesIllustration";
import PolicyProvision from "./OtherInfoModals/policyProvision";

class OtherInfoPdfModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...this.props.modalData
        }
    }

    componentDidMount = () => {
        console.log("%cOtherInfoModal State Info:", "background: #255F8E; color: white; display: block;", { modalId: this.state.modalId, policyId: this.state.policyId });
    }

    render = () => {
        const displayTitle = {
            salesIllustration:
            {
                title: 'Sales Illustration'
            },
            policyProvision:
            {
                title: 'Policy Provision'
            }
        };
        return (
            <div className="other-info-modal-pdf" style={this.props.isSm ? {} : { paddingRight: "7rem" }}>
                <div className="other-info-modal-pdf__modal-content mt-n5">
                    <div id="other-info-modal-pdf-modal-header" className="other-info-modal-pdf__modal-header">
                        <h5 className="announcement-title">{displayTitle[this.state.modalId].title}</h5>
                        <img className="announcement-close-icon" src="/announcement/close.png" alt="close-icon" onClick={this.props.handleClose} />
                    </div>
                    <div className="other-info-modal-pdf__modal-main-content">
                        {
                            this.state.modalId === 'salesIllustration' &&
                            <SalesIllustration
                                modalData={{ ...this.state }}
                            />
                        }
                        {
                            this.state.modalId === 'policyProvision' &&
                            <PolicyProvision
                                modalData={{ ...this.state }}
                            />
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default withWindowDimensions(OtherInfoPdfModal);