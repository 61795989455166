import React, { Component } from 'react';
import './helpInfo.scss';

export default class HelpInfo extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.setState({
            showHelpInfo: this.props.showHelpInfo,
        });
    }

    requirementsList() {
        const checkmark = (<span className="icon-check text-success"></span>);
        const cross = (<span className="icon-close text-danger"></span>);
        const entry = this.props.entry;
        const confirmEntry = this.props.confirmEntry;

        let requirements = [
            { icon: cross, regex: /(?=.{14,}$)/, desc: 'At least 14 characters.' },
            { icon: cross, regex: /(?=.*[a-z])(?=.*[A-Z]).*/, desc: 'Upper and lowercase letters.' },
            { icon: cross, regex: /[!@#$%^&*(),.?:{}<>_]/, desc: 'At least 1 special character (for example, ., !, $, #, %).' },
            { icon: cross, regex: /[1-9]/, desc: 'At least 1 number.' },
            { icon: cross, regex: '', desc: 'New and Re-Type Password should match.' },
        ];

        requirements.forEach((element, index) => {
            if(index != 4) {
                if(element.regex.test(entry)) {
                    element.icon = checkmark;
                }
            } else if(index === 4) {
                if(entry === confirmEntry) {
                    element.icon = checkmark;
                }
            }
        });

        return requirements;
    }

    render() {
        const entry = this.props.entry;
        const requirements = this.requirementsList();
        const customStyles = this.props.customStyles;

        return (
            <div className="help__content">
                <div className="help__requirements-list">
                    <div className="mb-4">
                        { requirements.map((requirement, index) => (
                            <p class='help__requirements-entry' style={customStyles}> { requirement.icon } { requirement.desc }</p>
                        ))}
                    </div>
                    <div className="mb-4 help__requirements-entry">
                        Additional rules:
                        <ul>
                            <li>Password not same for the previous 24 passwords</li>
                            <li>Passwords will be expiring every 3 months</li>
                            <li>Not contain the user’s account name or parts of the user’s full name that exceeds two consecutive characters.</li>
                        </ul>

                    </div>
                </div>
            </div>);
    }
}
