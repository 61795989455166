import React, { Component } from 'react';
import $ from 'jquery';
import moment from 'moment';
import Chart from 'chart.js';

class TimeSeries extends Component {

    constructor(props) {
        super(props);
        this.state = ({
            title: this.props.title ? this.props.title : null,
            assetId: this.props.assetId ? this.props.assetId : "",
            ratingType: this.props.ratingType ? this.props.ratingType : "",
            yAxisTitle: this.props.yAxisTitle ? this.props.yAxisTitle : "",
            yAxisLabel: this.props.yAxisLabel ? this.props.yAxisLabel : "",
            maxCount: this.props.maxCount ? this.props.maxCount : 0,
            tooltipBackup: this.props.tooltipBackup ? this.props.tooltipBackup : '',
            chartType: 'line',
            data: this.props.data ? this.props.data : [],
            chartData: [],
            chartLabel: [],
            chart: null,
            chartPieColorScheme: [],
            graphBorderColorScheme: [
                'rgba(0, 0, 0, 0.1)',
                'rgba(0, 0, 0, 0.1)',
                'rgba(0, 0, 0, 0.1)',
                'rgba(0, 0, 0, 0.1)',
                'rgba(0, 0, 0, 0.1)',
                'rgba(0, 0, 0, 0.1)',
                'rgba(0, 0, 0, 0.1)',
                'rgba(0, 0, 0, 0.1)',
                'rgba(0, 0, 0, 0.1)',
                'rgba(0, 0, 0, 0.1)'
            ]
        });
        this.formatChartData = this.formatChartData.bind(this);
        this.renderChart = this.renderChart.bind(this);
    }

    formatChartData(nextProps) {
        let currentState = nextProps ? nextProps : this.state
        let datas = nextProps ? nextProps.data : this.state.data;
        let label = [];
        let data = [];

        let colorScheme = [];
        for (let i = 0; i < datas.length; i++) {
            label[i] = datas[i].name;
            data[i] = datas[i].count;

            switch (datas[i].id) {
                case 0:
                    colorScheme.push('rgba(150, 220, 250, 0.75)');
                    break;
                case 1:
                    colorScheme.push('rgba(204, 221, 97, 0.75)');
                    break;
                case 2:
                    colorScheme.push('rgba(225, 207, 235, 0.75)');
                    break;
                case 3:
                    colorScheme.push('rgba(247, 199, 195, 0.75)');
                    break;
                case 4:
                    colorScheme.push('rgba(255, 232, 176, 0.75)');
                    break;
                case 5:
                    colorScheme.push('rgba(255, 147, 79, 0.75)');
                    break;
                default:
                    colorScheme.push('rgba(195,216, 212, 0.75');
                    break;
            }
        }
        this.setState({
            chartLabel: label,
            chartData: data,
            chartPieColorScheme: colorScheme
        }, function () {
            this.renderChart(this.state.chartType, datas, currentState);
        });
    }

    renderChart(chartType, data, currentState) {
        let windowWidth = $(window).width();
        let fontSize = 10;
        let thisComponent = this
        if (windowWidth >= 420)
            fontSize = 12;
        if (windowWidth >= 900)
            fontSize = 14;
        let chart;
        let tickOptions = {
            padding: 10,
            fontFamily: 'Allianz Neo',
            fontSize: 15,
            beginAtZero: true,
            stepSize: currentState.maxCount / 5,
            max: currentState.maxCount,
            callback: function (value, index) {
                return (value !== 0 ? value + ' ' + currentState.yAxisLabel : '');
            }
        }

        let isOverallOnly = data ? (data.length === 1 ? (data[0].label.toUpperCase() === 'OVERALL' ? true : false) : false) : false;
        let chartOptions = {
            legend: {
                display: false,
                reverse: true,
                labels: {
                    fontColor: '#414141',
                    fontFamily: 'Allianz Neo',
                    fontSize: 12,
                    padding: 5
                },
            },
            legendCallback: function (chart) {
                var text = [];
                text.push('<ul class="' + chart.id + '-legend customLegend">');
                for (var i = chart.data.datasets.length - 1; i >= 0; i--) {
                    text.push('<li class="legendTransition "><div style="background-color:' + chart.data.datasets[i].pointBackgroundColor + ';"></div><span>');
                    if (chart.data.datasets[i].label) {
                        text.push(chart.data.datasets[i].label);
                    }
                    text.push('</span></li>');
                }
                text.push('</ul>');
                return isOverallOnly ? '' : text.join("");
            },
            tooltips: {
                mode: 'x',
                callbacks: {
                    title(datasets) {
                        var time = new Date(datasets[0].xLabel);
                        return moment(time).format('MMM YYYY');
                    },
                    label: function (tooltipItem, data) {
                        var label = data.datasets[tooltipItem.datasetIndex].label;
                        if (label) {
                            label += ' : ';
                        }
                        label += tooltipItem.yLabel + (thisComponent.state.yAxisLabel ? ' ' + thisComponent.state.yAxisLabel : ' ' + thisComponent.state.tooltipBackup);
                        return label
                    }
                }
            },
            scales: {
                yAxes: [{
                    display: true,
                    scaleLabel: {
                        display: true,
                        labelString: currentState.yAxisTitle,
                        fontSize: 16,
                        fontStyle: 'bold',
                        fontFamily: 'Allianz Neo',
                    },
                    gridLines: {
                        lineWidth: 2,
                        color: '#f0f0f0',
                        zeroLineWidth: 0,
                        zeroLineColor: '#f0f0f0',
                        drawTicks: false
                    },
                    ticks: tickOptions
                }],
                xAxes: [{
                    type: 'time',
                    time: {
                        unit: 'month'
                    },
                    scaleLabel: {
                        display: false,
                        labelString: 'Month',
                        fontSize: 16,
                        fontStyle: 'bold',
                        fontFamily: 'Allianz Neo'
                    },
                    gridLines: {
                        lineWidth: 2,
                        color: '#f0f0f0',
                        drawTicks: false
                    },
                    ticks: {
                        padding: 10,
                        fontFamily: 'Allianz Neo',
                        fontSize: 15,
                        callback: function (value, index, values) {
                            return moment(value).format('MMM-YY');
                        }
                    },
                }]
            }
        };
        let canvas = $("#lineChartCanvas-" + currentState.ratingType + "-" + currentState.assetId);


        if (canvas.length >= 1) {
            if (this.state.chart != null) {
                this.state.chart.destroy();
                this.setState({
                    chart: null
                })
            }
            let lineTension = 0;
            chart = new Chart(canvas, {
                type: chartType,
                data: {
                    datasets: data
                },
                options: chartOptions
            });

            let legendContainer = $("#LegendLineChartCanvas-" + this.state.ratingType + "-" + this.state.assetId).html(chart.generateLegend());
            let legendItems = legendContainer.find('li');
            for (var i = legendItems.length - 1; i >= 0; i--) {
                legendItems[i].addEventListener("click", function (event) {
                    event = event || window.event;

                    var target = event.target || event.srcElement;
                    while (target.nodeName !== 'LI') {
                        target = target.parentElement;
                    }
                    var parent = target.parentElement;
                    var index = Array.prototype.slice.call(parent.children).indexOf(target);
                    chart.legend.options.onClick.call(chart, event, chart.legend.legendItems[index]);
                    if (!chart.legend.legendItems[index].hidden) {
                        target.classList.remove('grayscale');
                    } else {
                        target.classList.add('grayscale');
                    }
                }, false);
            }

            this.setState({
                chart: chart
            });
            this.forceUpdate();
        }
    }

    componentDidMount() {
        this.formatChartData();
    }

    render() {
        return (
            <div> {
                this.state.title ? <label className="MetricLargeTitle" > { /* this.state.title */} </label> : ""}
                <div className="LegendLineChartCanvas"
                    id={"LegendLineChartCanvas-" + this.state.ratingType + "-" + this.state.assetId} > </div>
                <canvas id={"lineChartCanvas-" + this.state.ratingType + "-" + this.state.assetId} className="canvasChart"> </canvas>
            </div>
        );
    }
}

export default TimeSeries;