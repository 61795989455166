import React, { Component, Fragment } from 'react';
import ReactTable from 'react-table';
import { Link } from 'react-router-dom';
import { prettifyDate, calculateAge, writeData, readAllData } from '../../commons/utility.js';
import routeChange from '../../commons/routeChange';
import { apiModule } from '../../commons/apiCall';
import Auxi from '../auxi';
import LoaderGif from "../../commons/loaderGif";
import { myConfig } from '../../commons/config.js';
import UsersMobile from './Mobile/UsersMobile';
import TableLoader, { TableLoader2 } from '../../components/TableLoader/tableLoader';
import withWindowDimensions from '../../commons/withWindowDimensions';
import TablePagination from '../../components/tablePagination';

class Users extends Component {

    constructor(props) {
        super(props);
        this.state = {
            jwt: '',
            targetClicked: "",
            currentCaret: "",
            pageNumber: -1,
            showModal: false,
            selectedUser: null,
            modalText: "",
        }
    }


    changePage = (pageNumber) => {

        if (pageNumber.selected != null) {
            pageNumber = pageNumber.selected;
            if (this.props.currentPage != pageNumber) {
                this.props.search(pageNumber);
            }

        } else if (this.props.currentPage != pageNumber) {

            this.props.search(pageNumber);
        }
    }

    onClickAction = (selectedUser) => {
        this.setState({
            selectedUser,
        })

        if (!["active", "on-hold", "onhold"].includes((selectedUser.STATUS || "").toLowerCase())) {
            this.setState({
                modalText: `${selectedUser.name} is no longer connected to Allianz\nPress OK to proceed`
            })
            this.toggleModal();
        } else {
            this.triggerViewAs(selectedUser);
        }
    }

    triggerViewAs = (selectedUser = this.state.selectedUser) => {
        const { INTM_NO, name, email } = selectedUser;
        this.props.handleLoadViewAs(true);
        this.props.setViewAs(INTM_NO, name, email, this.props.history, "/dashboard/dashboard");
    }

    tableView = () => {
        let customNames = this.props.customNames;
        let headerColumns = [];
        for (let key in customNames) {
            headerColumns.push({ Header: customNames[key], accessor: key })
        }

        let desktopView = (
            <ReactTable
                className={`mt-4`}
                loading={this.props.loadTable}
                LoadingComponent={TableLoader}
                resizable={false}
                columns={headerColumns}
                // data={this.clientRows(100)}
                defaultPageSize={myConfig.PAGE_SIZE}
                minRows={0}
                totalSize={this.props.totalSize}
                data={this.props.users}
                manual
                getTheadThProps={(state, rowInfo, column, instance) => ({
                    onClick: () => {
                        this.props.headerClickedHandler(column.id);
                    },
                    sortClassName: (
                        this.props.sortByField == column.id ?
                            (this.props.sortOrder == "ASC" ?
                                "icon-chevron-small-up"
                                : "icon-chevron-dropdown")
                            : ""
                    ),
                    sortColor: (
                        this.props.sortByField == column.id ?
                            "text-black"
                            : ""
                    )
                })}
                onFetchData={(state, instance) => {
                    this.changePage(state.page);
                }}
                pages={this.props.pages}
                page={this.props.currentPage}
                getTrProps={(state, rowInfo, column, instance) => (
                    {
                        onClick: () => this.onClickAction(rowInfo.original)
                    }
                )}
            />
        );

        let mobileView = (
            <div className="d-block d-md-none position-relative">
                <UsersMobile
                    data={this.props.users}
                    onClickAction={this.onClickAction}
                    customNames={this.props.customNames}
                    headerClickedHandler={this.props.headerClickedHandler}
                />
                <TablePagination
                    css="mt-2"
                    data={this.props.users}
                    page={this.props.currentPage}
                    totalSize={this.props.totalSize}
                    onPageChange={this.changePage}
                    pageSize={myConfig.PAGE_SIZE}
                    pages={this.props.pages}
                />
                {this.props.loadTable ? <TableLoader loading={true} /> : ""}
            </div>
        );

        // if (this.props.loadTable === true) {
        //     desktopView = <LoaderGif css="d-md-block d-none" />;
        //     mobileView = <LoaderGif css="d-block d-md-none" />;
        // }
        return (
            <Auxi>
                {/* {desktopView}
                {mobileView} */}
                {this.props.isSm ? mobileView : desktopView}
                {this.props.isLoadingViewAs ? <TableLoader2 loading={true} /> : ""}
            </Auxi>
        );
    }

    toggleModal = (showModal = !this.state.showModal) => {
        this.setState({
            showModal,
        })
    }

    modal = () => (
        <div className="policy-dashboard__prompt-overlay policy-dashboard__prompt-overlay--fix-position">
            <article className="policy-services-prompt">
                <header className="p-4 d-flex flex-row"><span onClick={() => this.toggleModal()} className="policy-services-prompt__close icon-close mb-0 ml-auto"></span></header>
                <article className="policy-services-prompt__content py-0">
                    <article className="policy-services-submodule__user-area d-flex flex-column mt-0">
                        <section className="policy-services-submodule__child-container pt-0">
                            <button type="button" className="policy-modal__close" data-dismiss="modal">
                                <span>&#x2715;</span>
                            </button>
                            <h4 className="text-center ws-pre-wrap">{this.state.modalText}</h4>
                            <div className="policy-services-submodule__auth-actions policy-services-submodule__auth-actions--fta">
                                <button
                                    className="btn btn-headercolor px-4"
                                    onClick={() => { this.toggleModal(); this.triggerViewAs() }}
                                >
                                    OK
                                </button>
                            </div>
                        </section>
                    </article>
                </article>
            </article>
        </div>

    )
    render() {
        return (
            <Fragment>
                {this.tableView()}
                {this.state.showModal && this.modal()}
            </Fragment>
        );
    }
}
export default withWindowDimensions(Users);
