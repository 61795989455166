import React, { Component } from 'react';
import ReactTable from 'react-table';
//import Policy from './Policy/Policy';
import { prettifyDate, calculateAge, writeData, readAllData, encrypt, decrypt } from '../../commons/utility.js';


import routeChange from '../../commons/routeChange';
import { apiModule } from '../../commons/apiCall';
import Auxi from '../auxi';
import LoaderGif from "../../commons/loaderGif";
import { Link } from 'react-router-dom';
import Pagination from '../tablePagination';
import { myConfig } from '../../commons/config.js';

import TableLoader from '../TableLoader/tableLoader';
import withWindowDimensions from '../../commons/withWindowDimensions';
import ReactPaginate from 'react-paginate';
import TablePagination from '../../components/tablePagination';

class Policies extends Component {

    constructor(props) {
        super(props);
        this.state = {

            jwt: '',
            pageNumber: 0
        }
    }

    onClickAction = (item) => {
        this.props.setClientOrPolicy({ policyId: item });
        writeData('policy-client', { policyId: item, id: 1 });
        routeChange(this.props.history, "/dashboard/single-policy");
    }
    changePage = (pageNumber) => {
        if (pageNumber.selected != null) {
            pageNumber = pageNumber.selected;
        }
        if (this.props.currentPage != pageNumber) {
            // this.setState({
            //     pageNumber: pageNumber
            // }, () => {
            this.props.search(pageNumber);
            // });
        }

    }

    /*
     INS1_COMP_NAME:  `${result[i].contractHolder.lastName}, ${result[i].contractHolder.firstName}`,
                                        FACE_AMT : result[i].contractBalance,
                                        CREDIT_SO: result[i].branchName,*/
    mobileAccord = () => {
        const data = this.props.policies;
        const customName = {
            POL_DS: "Plan Name",
            DELIVERY_DT: "Policy Delivery Date", 
            INS1_COMP_NAME: "Policy Owner",
            FACE_AMT: "Face Amount",
            CREDIT_SO: "Contract Branch",
            TOT_MODAL_PREM: "Modal Premium",
            PMODE_CD: "Mode of Payment",
            insured: "Insured",
            EFF_DT: "Effective Date",
            POL_CURR_CD: "Currency",
            EXPIRY_DT: "Expiry Date",
            FA_NAME: "Intermediary Name",
        };

        let policies = data.map((item, index) => {
            let status_class = item.POL_STAT.toLowerCase().replace(/\s+/g, '-');
            let class_url = "btn " + status_class + " text-white rounded-pill data-accord__button pes-none";
            return (
                <div key={index} className="data-accord__card card border-0">
                    <div className="data-accord__toggle btn">
                        <div className="row data-accord__start">
                            <div className="col-5">
                                <div className="data-accord__name">{item.POL_NO}</div>
                            </div>
                            <div className="col-7 d-flex justify-content-between">
                                <button className={class_url}>{item.POL_STAT.toUpperCase()}</button>
                                <button className="btn data-accord__button"></button>
                                <button className="btn p-0" type="button" data-toggle="collapse" data-target={`#collapse-${index}`}>
                                    <div className="data-accord__caret">
                                        <div className="data-accord__caret-icon"></div>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div id={`collapse-${index}`} className="collapse" data-parent="#data-accord">
                        <div className="card-body data-accord__content">
                            <div className="row">
                                <div className="data-accord__column col-7">
                                    <div className="data-accord__label">{customName.POL_DS}</div>
                                    <div className="data-accord__value">{item.POL_DS}</div>
                                </div>
                                <div className="data-accord__column col-5">
                                    <div className="data-accord__label">{customName.DELIVERY_DT}</div>
                                    <div className="data-accord__value">{item.DELIVERY_DT}</div>
                                </div>
                                <div className="data-accord__column col-7">
                                    <div className="data-accord__label">{customName.TOT_MODAL_PREM}</div>
                                    <div className="data-accord__value">{item.TOT_MODAL_PREM}</div>
                                </div>
                                <div className="data-accord__column col-5">
                                    <div className="data-accord__label">{customName.PMODE_CD}</div>
                                    <div className="data-accord__value">{item.PMODE_CD}</div>
                                </div>
                                <div className="data-accord__column col-7">
                                    <div className="data-accord__label">{customName.INS1_COMP_NAME}</div>
                                    <div className="data-accord__value">{item.INS1_COMP_NAME}</div>
                                </div>
                                <div className="data-accord__column col-5">
                                    <div className="data-accord__label">{customName.EFF_DT}</div>
                                    <div className="data-accord__value">{item.EFF_DT}</div>
                                </div>
                                <div className="data-accord__column col-7">
                                    <div className="data-accord__label">{customName.POL_CURR_CD}</div>
                                    <div className="data-accord__value">{item.POL_CURR_CD}</div>
                                </div>
                                <div className="data-accord__column col-5">
                                    <div className="data-accord__label">{customName.FACE_AMT}</div>
                                    <div className="data-accord__value">{item.FACE_AMT}</div>
                                </div>
                                <div className="data-accord__column col-7">
                                    <div className="data-accord__label">{customName.CREDIT_SO}</div>
                                    <div className="data-accord__value">{item.CREDIT_SO}</div>
                                </div>
                                <div className="data-accord__column col-5">
                                    <div className="data-accord__label">{customName.EXPIRY_DT}</div>
                                    <div className="data-accord__value">{item.EXPIRY_DT}</div>
                                </div>
                                {/* leaving this here incase they require the intermediary name in the mobile, cause right now, nothing was mentioned in the ticket cpph-2353
                                {(!this.props.hasFa) ?
                                    <div className="data-accord__column col-7">
                                        <div className="data-accord__label">{customName.FA_NAME}</div>
                                        <div className="data-accord__value">{item.FA_NAME}</div>
                                    </div> : null
                                } */}
                                <div className="data-accord__column col-7 d-flex align-items-end justify-content-end">
                                    <button onClick={() => this.onClickAction(item.POL_NO)} className="btn btn-darkgreen rounded-pill data-accord__button">Summary</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            );
        });

        return (

            <div className="data-accord row flex-wrap mt-4" >
                <div className="data-accord__header">
                    <div className="col-5">
                        <div onClick={() => this.props.headerClickedHandler("contractNumber")} className="d-inline-flex align-items-center cs-pointer">
                            Policy Number <span className="icon-popout"></span>
                        </div>
                    </div>
                    <div className="col-7">
                        <div onClick={() => this.props.headerClickedHandler("POL_STAT")} className="d-inline-flex align-items-center cs-pointer">
                            Status <span className="icon-popout"></span>
                        </div>
                    </div>
                </div>
                <div className="col-12 data-accord__body mt-1">
                    <div className="data-accord__collapse" id="data-accord">
                        {policies}
                    </div>
                </div>
            </div>
        );
    }

    tableView = () => {
        let columns = [];
        if (this.props.isMgr && this.props.hasFa) {
            columns = [
                {
                    Header: 'Policy Number',
                    // id: 'policyNumber',
                    // accessor: d => Number(d.policyNumber),
                    accessor: 'contractNumber'
                },
                {
                    Header: "Policy Owner",
                    accessor: 'name',
                    //      maxWidth: 80,
                },
                {
                    Header: 'Policy Delivery Date',
                    accessor: 'DELIVERY_DT'
                },
                {
                    Header: 'Plan Name',
                    accessor: 'POL_DS',
                    //   minWidth: 160,
                },
                {
                    Header: "Modal Premium",
                    accessor: 'TOT_MODAL_PREM',
                    //accessor: d => parseFloat(d.TOT_MODAL_PREM).toFixed(2),
                },
                {
                    Header: "Mode of Payment",
                    accessor: 'PMODE_CD'
                },
                {
                    Header: "Face Amount",
                    accessor: 'FACE_AMT',
                    //  minWidth: 160,
                },
                // {
                //     Header: "Insured",
                //     accessor: 'insured',
                //     minWidth: 160,
                // },
                {
                    Header: "Effective Date",
                    accessor: 'EFF_DT',
                    //    maxWidth: 80,
                },
                {
                    Header: "Contract Branch",
                    accessor: 'CREDIT_SO',
                    //  maxWidth: 80,
                },
                {
                    Header: "Currency",
                    accessor: "POL_CURR_CD",
                    //   maxWidth: 60,
                },
                {
                    Header: "Expiry Date",
                    accessor: "EXPIRY_DT",
                    //   maxWidth: 80,
                },
                {
                    Header: "Financial Advisor",
                    accessor: "fa_name",

                },
                {
                    Header: 'Status',
                    accessor: 'POL_STAT',
                    maxWidth: 120,
                    Cell: props => <button className={"dashboard-datatable__button btn text-uppercase rounded-pill text-white " + props.value.toLowerCase().replace(/\s+/g, '-')} >{props.value.toLowerCase()}</button>
                }
            ];
        } else {
            columns = [
                {
                    Header: 'Intermediary Name',
                    accessor: 'FA_NAME',
                    width: 110
                },
                {
                    Header: "Contract Branch",
                    accessor: 'CREDIT_SO',
                    //  maxWidth: 80,
                },
                {
                    Header: 'Policy Number',
                    // id: 'policyNumber',
                    // accessor: d => Number(d.policyNumber),
                    accessor: 'POL_NO'
                },
                {
                    Header: 'Policy Delivery Date',
                    accessor: 'DELIVERY_DT'
                },
                {
                    Header: "Policy Owner",
                    accessor: 'INS1_COMP_NAME',
                    //      maxWidth: 80,
                },
                {
                    Header: 'Plan Name',
                    accessor: 'POL_DS',
                    //   minWidth: 160,
                },
                {
                    Header: "Modal Premium",
                    accessor: 'TOT_MODAL_PREM',
                    //accessor: d => parseFloat(d.TOT_MODAL_PREM).toFixed(2),
                },
                {
                    Header: "Mode of Payment",
                    accessor: 'PMODE_CD'
                },
                {
                    Header: "Face Amount",
                    accessor: 'FACE_AMT',
                    //  minWidth: 160,
                },
                // {
                //     Header: "Insured",
                //     accessor: 'insured',
                //     minWidth: 160,
                // },
                {
                    Header: "Effective Date",
                    accessor: 'EFF_DT',
                    //    maxWidth: 80,
                },
                {
                    Header: "Currency",
                    accessor: "POL_CURR_CD",
                    //   maxWidth: 60,
                },
                {
                    Header: "Expiry Date",
                    accessor: "EXPIRY_DT",
                    //   maxWidth: 80,
                },
                {
                    Header: 'Status',
                    accessor: 'POL_STAT',
                    maxWidth: 120,
                    Cell: props => <button className={"dashboard-datatable__button btn text-uppercase rounded-pill text-white " + props.value.toLowerCase().replace(/\s+/g, '-')} >{props.value.toLowerCase()}</button>
                }
            ];

            //removes the Intermediary Name column if the user is not a MANAGER
            if(!this.props.isMgr){
                columns.splice(0,1)
            }
        }
        let desktopView = (
            <ReactTable
                className={`mt-4`}
                loading={this.props.loadTable ? true : false}
                resizable={false}
                columns={columns}
                // data={this.policyRows(100)}
                data={this.props.policies}
                manual
                LoadingComponent={TableLoader}
                onFetchData={(state, instance) => {
                    this.changePage(state.page);
                }}
                getTheadThProps={(state, rowInfo, column, instance) => ({
                    onClick: () => {
                        this.props.headerClickedHandler(column.id);
                    },
                    sortClassName: (
                        this.props.sortByField == column.id ?
                            (this.props.sortOrder == "ASC" ?
                                "icon-chevron-small-up"
                                : "icon-chevron-dropdown")
                            : ""
                    ),
                    sortColor: (
                        this.props.sortByField == column.id ?
                            "text-black"
                            : ""
                    )
                })}
                pages={this.props.pages}
                page={this.props.currentPage}
                defaultPageSize={myConfig.PAGE_SIZE}
                totalSize={this.props.totalSize}
                minRows={0}
                getTrProps={(state, rowInfo, column, instance) => (
                    {
                        onClick: () => this.onClickAction(rowInfo.original.POL_NO)
                    }
                )}
            />
        );

        let mobileView = (
            <div className="d-block d-md-none position-relative">
                {this.mobileAccord()}
                {/* <ReactPaginate
                    pageCount={this.props.pages}
                    containerClassName="mobilePaginationContainer"
                    onPageChange={this.changePage}
                    activeClassName={"activeMobilePagination"}
                    initialPage={this.props.currentPage}
                /> */}
                <TablePagination
                    css="mt-2"
                    data={this.props.policies}
                    page={this.props.currentPage}
                    totalSize={this.props.totalSize}
                    onPageChange={this.changePage}
                    pageSize={myConfig.PAGE_SIZE}
                    pages={this.props.pages}
                />
                {this.props.loadTable ? <TableLoader loading={true} /> : ""}
            </div>
        );

        return (
            <Auxi>
                {this.props.isSm ? mobileView : desktopView}
            </Auxi>
        );
    }
    render() {
        return (
            this.tableView()
        );
    }
}
export default withWindowDimensions(Policies);
