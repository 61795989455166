import React, { Component } from 'react';
import ReactTable from 'react-table';
import { Link } from 'react-router-dom';
import { prettifyDate, calculateAge, writeData, readAllData, commaDelimit } from '../../commons/utility.js';
import routeChange from '../../commons/routeChange';
import { apiModule } from '../../commons/apiCall';
import Auxi from '../auxi';
import LoaderGif from "../../commons/loaderGif";
import { myConfig } from '../../commons/config.js';
import ApplicationsMobile from './Mobile/applicationsMobile';
import TableLoader from '../TableLoader/tableLoader';
import withWindowDimensions from '../../commons/withWindowDimensions';
import TablePagination from '../tablePagination';
import ReportsMobile from '../../components/Reports/Mobile/ReportsMobile';
import Status from "./status";
import Insured from "./insured";
import Modal from 'antd/lib/modal/Modal';
import { isManager } from '../../commons/security.js';
import moment from 'moment';


const Applications = (props) => {
    let mobileScreenLimit = 600;
    let dataTable = "";

    const columns = [
        {
            Header: "Application Number",
            accessor: "contractNumber"
        },
        {
            Header: "Plan Name",
            id: "productOfferingName",
            accessor: (data) => {
                let returnValue = "---";
                if (data.productOfferingName) {
                    returnValue = data.productOfferingName;
                }
                return returnValue;
            }
        },
        {
            Header: "Branch",
            id: "branchName",
            accessor: (data) => {
                let returnValue = "---";
                if (data.branchName) {
                    returnValue = data.branchName;
                }
                return returnValue;
            }
        },
        {
            id: "contractHolder",
            Header: "Applicant Owner",
            accessor: (data) => {
                let returnValue;
                if (data.contractHolder) {
                    returnValue = `${data.contractHolder.lastName}${data.contractHolder.suffix ? ' ' + data.contractHolder.suffix : '' }, ${data.contractHolder.firstName} ${data.contractHolder.middleName ? data.contractHolder.middleName : ''}`
                }
                else {
                    returnValue = "---";
                }
                return returnValue;
            }
        },
        {
            Header: "Proposed Insured",
            accessor: "insured",
            Cell: (props) => <Insured insured={props.value} />
        },
        {
            Header: "Application Receiving Status",
            id: "receivingStatus",
            accessor: (data) => {
                let returnValue = "---";
                if (data.statusHistory && data.statusHistory.contractAdditionalProcessState) {
                    returnValue = data.statusHistory.contractAdditionalProcessState;
                }
                return returnValue;
            }
        },
        {
            Header: "Receiving Status Date",
            id: "receivingStatusDate",
            accessor: (data) => {
                let returnValue = "---";
                if (data.receivedDate) {
                    returnValue = moment(data.receivedDate).format("YYYY-MM-DD");
                }
                return returnValue;
            }
        },
        {
            Header: "Original Document Due Date",
            id: "originalDueDate",
            accessor: (data) => {
                let returnValue = "---";
                if (data.statusHistory && data.statusHistory.additionalProcessStateDateTime) {
                    returnValue = moment(data.statusHistory.additionalProcessStateDateTime).format("YYYY-MM-DD");
                    if (data.pendingActions && data.pendingActions.length > 0 && data.pendingActions[0] === "ARM_DOC_PAST_DUE") {
                        returnValue = (<span className="text-danger">{returnValue}</span>)
                    }
                }
                return returnValue;
            }
        },
        {
            Header: "Expiration Date",
            id: "expirationDate",
            accessor: (data) => {
                let returnValue = "---";
                if (data.quotationAvailabilityEndDate) {
                    returnValue = moment(data.quotationAvailabilityEndDate).format("YYYY-MM-DD");
                }
                return returnValue;
            }
        },
        {
            Header: "Application Status",
            accessor: "status",
            Cell: (props) => <Status status={props.value} />
        },
        {
            id: "statusDate",
            Header: "Application Status Date",
            accessor: (data) => `${data.statusDate ? data.statusDate : "---"}`
        },
        {
            Header: "Encoded Date",
            id: "creationDate",
            accessor: (data) => {
                let returnValue = "---";
                if (data.creationDate) {
                    returnValue = data.creationDate;
                }
                return returnValue;
            }
        },
        {
            Header: "Beneficiary",
            accessor: "Beneficiary",
            Cell: (data) => {
                let returnValue = "";
                returnValue = (
                    <a className="dashboard-datatable__button btn rounded-pill btn-dropdowncolor text-white"
                        style={{ minWidth: "60px" }}
                        onClick={() => { props.handleShowBeneficiary(data) }}
                    >
                        View
                    </a>
                );
                return returnValue;
            }
        },
        {
            Header: "Requirements",
            accessor: "requirements",
            Cell: (data) => {
                let preassessment = "";
                let returnValue = "";

                if (data.row != null) {
                    preassessment = data.original.contractAddonDetail.preassessmentStatus;
                } else {
                    preassessment = data.contractAddonDetail.preassessmentStatus;
                }

                if (preassessment === "WITH_PENDING_REQUIREMENT") {
                    returnValue = (
                        <a className="dashboard-datatable__button btn rounded-pill btn-dropdowncolor text-white"
                            style={{ minWidth: "60px" }}
                            onClick={() => { props.handleShowRequirements(data) }}
                        >
                            View
                        </a>
                    );
                } else {
                    returnValue = "---";
                }

                return returnValue;
            }
        },
    ]

    if (isManager(props.role)) {
        const agentColumn = {
            Header: "Servicing Agent",
            id: "agent",
            accessor: (data) => data.agent && data.agent.name ? data.agent.name : "---"
        }
        columns.splice(columns.length - 2, 0, agentColumn);
    }

    if (!props.isMd) {
        dataTable = (
            <>
                <ReactTable
                    className={`application-table`}
                    columns={columns}
                    data={props.applications}
                    loading={props.isTableLoading}
                    minRows={1}
                    LoadingComponent={TableLoader}
                    defaultPageSize={myConfig.PAGE_SIZE}
                    pages={props.pages}
                    page={props.currentPage}
                    totalSize={props.totalSize}
                    onPageChange={(page) => props.handleChangePage(page)}
                    manual
                    getTheadThProps={(state, rowInfo, column, instance) => ({
                        sortClassName: "d-none",
                    })}
                >
                </ReactTable>
                {props.applications.length === 0 && !props.isTableLoading ?
                    (<div className="center-align dashboard-row-data font-neo-semibold mt-4">No records found </div>)
                    :
                    ""
                }
            </>
        );
    } else {
        dataTable = (
            <ApplicationsMobile
                title={[
                    {
                        Header: 'Application Number',
                        accessor: 'POL_NO',
                        hasCaret: true,
                        onClick: () => {
                            this.headerClickedHandler('POL_NO');
                        }
                    },
                    {
                        Header: 'Application Status',
                        accessor: 'POL_STAT',
                        Cell: (props) => <Status status={props.value.toLowerCase()} />
                    },
                    {
                        Header: "Plan Name",
                        accessor: "productOfferingName"
                    },
                ]
                }
                customName={columns}
                data={props.applications}
                isTableLoading={props.isTableLoading}
            >
                {!props.isTableLoading ? (
                    <TablePagination
                        css="mt-2"
                        data={props.applications}
                        page={props.currentPage}
                        totalSize={props.totalSize}
                        onPageChange={(page) => props.handleChangePage(page)}
                        pageSize={myConfig.PAGE_SIZE}
                        pages={props.pages}
                        isLoading={props.isTableLoading}
                    />
                ) : ""}
                {/* {props.isTableLoading ? <TableLoader loading={true} /> : ""} */}
            </ApplicationsMobile >
        )
    }

    return (
        dataTable
    );

}

export default withWindowDimensions(Applications);
