import React, { Component } from 'react'
import { rniLogoutF } from '../commons/service';

class LogoutModal extends Component {


    render() {
        const { clickedLogout } = this.props;
        return (
            <div id='LogoutModal' className="rni-logout-modal-container" onClick={()=> { clickedLogout(true) }}>
                <div className="rni-logout-modal-box" onClick={(e) => { e.stopPropagation() }}>
                    <div className="rni-logout-question">
                        <p>Are you sure you want to logout?</p>
                    </div>
                    <div className="rni-logout-buttons">
                        <button onClick={rniLogoutF} className="rni-logout-text">LOGOUT</button>
                        <button onClick={()=> { clickedLogout(true) }}>CANCEL</button>
                    </div>
                </div>
            </div>
        )
    }
}

export default LogoutModal;