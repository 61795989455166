import React from 'react';
import { components } from 'react-select';

const DropdownValue = ({ children, ...props }) => (
  <components.SingleValue className="policy-dropdown-option" {...props}>
    <div className="policy-dropdown-option__flag-container"><img src={`/img/flags/${children}.jpg`} className="policy-dropdown-option__flag" /></div>
  </components.SingleValue>
);

export default DropdownValue;
