import React, { Fragment } from 'react';
import Select from 'react-select';
import { Years } from "../Datepicker/customHeader";
import YearRange from './yearRange';
import DateRange from "./dateRange";
import withWindowDimensions from '../../commons/withWindowDimensions';
import "./documents.scss";

const Search = (props) => {
    const disabledPolicyNumber = props.disabledPolicyNumber.includes(props.searchFilter.value);
    let instructionMessage = null;

    switch(props.searchFilter.value) {
        case "POLICY_LIST":
            instructionMessage = 
                <Fragment>
                    <b>Note: The Generated PDF Report is password protected.</b>
                    <br/>
                    <i>One-Time Password will be sent to your registered email. Please check your email.</i>
                </Fragment>
            break
        default:
            instructionMessage = null;
    }

    return (
        <div className="datatable-search">
            <form>
                <div className={props.isSm? 'report-dropdown-mobile': 'report-dropdown'}>
                    <Select
                        styles={props.styles}
                        options={props.documentTypeOptions}
                        isSearchable={false}
                        className={`datatable-select mr-md-2 ${props.isSm? 'report-dropdown-inner-mobile': 'w-100'}`}
                        classNamePrefix="datatable-select"
                        value={props.searchFilter}
                        onChange={props.handleUpdateSearchFilter}
                        styles={{
                            option: (provided) => ({
                                ...provided,
                                fontFamily: "neo_semibold",
                                fontSize: ".6rem",
                                color: "#9A9B9C",
                                backgroundColor: "white",
                            }),
                            menu: () => ({
                                position: "absolute",
                                border: 0,
                                zIndex: "1090",
                                backgroundColor: "white",
                                borderRadius: "5px",
                                boxShadow: "2px 2px 2px #DDDEE0",
                            }),
                        }}
                    />
                </div>
                    <div className={`d-flex mt-2 ml-2 flex-wrap`}>
                        {!disabledPolicyNumber && (
                                <div className={`custom-searchbar datatable-search__container form-control rounded-pill mr-md-2 ${props.isSm? 'report-search-mobile': ''}`}>
                                    <input
                                        value={props.policyNumber}
                                        onChange={props.handleUpdatePolicyNumber}
                                        className="searchbar__input datatable-search__input h-100"
                                        type="text"
                                        onKeyPress={props.handlePressEnter}
                                        placeholder="Enter Policy Number"
                                    />
                                    <a className="btn searchbar__button searchbar__button--gray px-2">
                                        <span className="icon-search"></span>
                                    </a>
                                </div>
                        )}
                        {renderSearchParams(props, props.searchFilter.value)}
                        <a
                            className={`lh-1 d-flex flex-grow-0 mt-2 mt-md-0 justify-content-center align-items-center btn btn-dropdowncolor rounded-pill text-uppercase ${props.isSm ? "font-p75rem report-generate-btn-mobile" : "font-p65rem report-clear-btn"} font-neo-semibold text-white datatable-search__btn ${props.searchFilter.value === "statusDate" && props.isSm ? "w-100 ml-0" : "ml-1"}`}
                            onClick={props.handleClear}>
                                Clear
                        </a>
                    </div>
                {renderSpielMessage({...props, hideSpielMessage: disabledPolicyNumber, instructionMessage })}
                <div className="d-flex mt-2">
                    <a
                        className={`lh-1 d-flex flex-grow-0 mt-2 mt-md-0 justify-content-center align-items-center btn btn-dropdowncolor rounded-pill text-uppercase ${props.isSm ? "font-p75rem report-generate-btn-mobile" : "font-p65rem report-generate-btn"} font-neo-semibold text-white datatable-search__btn ${props.searchFilter.value === "statusDate" && props.isSm ? "w-100 ml-0" : "ml-2"}`}
                        onClick={props.handleSubmit}>
                        Generate
                    </a>
                </div>
            </form>
        </div>
    )
}

/**
 * Returns either a YearRange or a DateRange component
 * based on the searchFilter argument
 *
 * @param {object} props - Various handlers and values for the components to be rendered
 * @param {string} searchFilter - Determines which component will be rendered
 *
 * @returns {YearRange|DateRange} - Search filter components to render
 */
function renderSearchParams(props, searchFilter) {
    let searchParamComponent;
    switch (searchFilter) {
        case "CERT_TC_OR":
        case "SUMM_OR":
            searchParamComponent = (
                <DateRange
                    startDate={props.startDate}
                    endDate={props.endDate}
                    handleUpdateStartDate={props.handleUpdateStartDate}
                    handleUpdateEndDate={props.handleUpdateEndDate}
                />
            );
            break;
        case "POLICY_LIST":
            searchParamComponent = (
                    <Select
                        styles={props.styles}
                        options={props.statusListOptions}
                        isSearchable={false}
                        className={`report-dropdown mr-md-2 ${props.isSm? 'report-dropdown-inner-mobile': ''}`}
                        classNamePrefix="datatable-select"
                        placeholder="All Statuses"
                        value={props.status}
                        onChange={props.handleUpdateStatus}
                        styles={{
                            option: (provided) => ({
                                ...provided,
                                fontFamily: "neo_semibold",
                                fontSize: ".6rem",
                                color: "#9A9B9C",
                                backgroundColor: "white",
                            }),
                            menu: () => ({
                                position: "absolute",
                                border: 0,
                                zIndex: "1090",
                                backgroundColor: "white",
                                borderRadius: "5px",
                                boxShadow: "2px 2px 2px #DDDEE0",
                            }),
                        }}
                    />
            );
            break;
        default:
            break;
    }
    return searchParamComponent;
}

function renderSpielMessage(props) {
    let spielComponent;
    let spielMessage = (
        <span className='report-generate-spiel text-headercolor ml-2'>Please enter the <b>exact</b> policy number.</span>
    );
    let instructionMessage = (
        <div className='instruction-box border border-info p-3 m-1 bg-light'>
            <span className='report-generate-spiel text-headercolor'>
                { props.instructionMessage ? props.instructionMessage : (
                    <Fragment>
                        <span>IHP is not yet available for printing.</span>
                        <br/>
                        <b>Note: The Generated PDF Report is password protected.</b>
                        <br/>
                        <i>Password to open is the Policy Owner's date of birth  in this format: ddMonyyyy (e.g. 31May1990).</i>
                    </Fragment>
                ) }
            </span>
        </div>
    );
    spielComponent = props.showSpielMessage? (
        <div className='mt-2 ml-2'>
            {!props.hideSpielMessage && spielMessage}
            {instructionMessage}
        </div>
    ) : ('');

    return spielComponent;
}

export default withWindowDimensions(Search);
