import React, { Component } from 'react';
import { decrypt } from '../../commons/utility';
import { apiModule } from '../../commons/apiCall';
import { ReportLoader } from '../../commons/utilityViews.js';
import * as DOMPurify from 'dompurify'

class AuthenticationMethods extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedMethod: "",
      authMessage: "",
      isLoading: false,
      defaultAuthMessage: ""
    }

    let defaultAuthMessage = `If the current email address and mobile number are not accessible, please contact Allianz PNB Life Customer Service at <a href='mailto:customercare@allianzpnblife.ph'>customercare@allianzpnblife.ph</a> for assistance.`;

    this.state.authMessage = defaultAuthMessage;
    this.state.defaultAuthMessage = defaultAuthMessage;

    this.handleCancel = this.handleCancel.bind(this);
  }

  setAuthMethod(method) {
    let authMessage = "";

    this.state.selectedMethod === method
      ? this.setState({ selectedMethod: "" })
      : this.setState({ selectedMethod: method });

    if (method === "sms") {
      authMessage = `An SMS will be sent to the customer’s registered phone number +${this.props.baseCountryCode} ${this.props.baseMobileNum} with the link ${this.props.toSpiel || "to view the transaction details and confirm the update"}.`;

      this.state.authMessage === authMessage
        ? this.setState({ authMessage: this.state.defaultAuthMessage })
        : this.setState({ authMessage })
    } else if (method === "email") {
      authMessage = `An email will be sent to the customer’s registered email address ${this.props.baseEmail} with the link ${this.props.toSpiel || "to view the transaction details and confirm the update"}.`;

      this.state.authMessage === authMessage
        ? this.setState({ authMessage: this.state.defaultAuthMessage })
        : this.setState({ authMessage })
    }
  }

  sendChosenAuthType = async () => {
    const { agent, policyId, transactionId } = this.props;

    if (agent && policyId && transactionId) {
      this.setState({ isLoading: true });

      const apiData = {
        transactionId,
        "contractNumber": policyId,
        "verificationType": this.state.selectedMethod,
        "agent": {
          "agentNumber": agent
        },
        transactionType: "TTYP_PD",
        "status": "WAITING FOR APPROVAL",
      };

      const requestParams = {
        x_auth: decrypt(this.props.getSession().access_token),
        userTransactionId: transactionId
      }

      await apiModule("auth_transaction_id", requestParams, apiData, '', true
      ).then((result) => {
        this.props.setAuthMethod(this.state.selectedMethod);
      })
        .catch((err) => {
          console.error(err);
        });
      this.setState({ isLoading: false });
    }
  }

  handleCancel() {
    this.props.history.push(this.props.previousRoute || "/dashboard/policy-services");
  }

  render() {
    const isFta = this.props.isFta;

    if (this.state.isLoading) {
      return (
        <article className="policy-services-submodule policy-services-submodule--loading">
          <ReportLoader show={this.state.isLoading} />
        </article>
      );
    } else {
      return (
        <article className="policy-services-submodule">
          {!isFta &&
            <header className="d-flex flex-row">
              <h6 className="mr-auto">Select Policy 1/2: Select Authentication Method</h6>
              <h6>Next Step: Confirmation</h6>
            </header>
          }

          <article className="policy-services-submodule__user-area d-flex flex-column">
            <span className="policy-services-submodule__content-marker mb-3">
              {isFta ? "Select where you want to send the FTA form" : "Choose your authentication method"}
            </span>
            <section className="policy-services-submodule__child-container">
              <div className="policy-services-submodule__auth-types-container d-flex">
                <div
                  className={"policy-services-submodule__auth-methods d-flex flex-column" +
                    (this.props.baseEmail === "--"
                      ? " policy-services-submodule__auth-methods--disabled"
                      : ""
                    )}
                  onClick={() => this.props.baseEmail !== "--" ? this.setAuthMethod("email") : null}
                >
                  <span
                    className={
                      "policy-services-submodule__auth-type policy-services-submodule__auth-type--email icon-mail-circle-o" +
                      (this.state.selectedMethod === "email"
                        ? " policy-services-submodule__auth-type--active"
                        : "")
                    }
                  ></span>
                  <h5 className="policy-services-submodule__auth-type-info">Email</h5>
                </div>
                <div
                  className={"policy-services-submodule__auth-methods d-flex flex-column" +
                    (this.props.baseMobileNum === "--"
                      ? " policy-services-submodule__auth-methods--disabled"
                      : ""
                    )}
                  onClick={() => this.props.baseMobileNum !== "--" ? this.setAuthMethod("sms") : null}
                >
                  <span
                    className={
                      "policy-services-submodule__auth-type policy-services-submodule__auth-type--sms icon-chat-o" +
                      (this.state.selectedMethod === "sms"
                        ? " policy-services-submodule__auth-type--active"
                        : "")
                    }
                  ></span>
                  <h5 className="policy-services-submodule__auth-type-info">SMS</h5>
                </div>
              </div>
              {
                this.state.authMessage
                  ? <div className="policy-services-submodule__auth-message">
                    <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(this.state.authMessage) }} />
                  </div>
                  : null}
              <div className="policy-services-submodule__auth-actions">
                <button className="policy-services-submodule__auth-action policy-services-submodule__auth-action--cancel" onClick={this.handleCancel}>Cancel</button>
                <button
                  onClick={this.props.setPrompt ? () => this.props.setPrompt(this.state.selectedMethod) : this.sendChosenAuthType}
                  disabled={!this.state.selectedMethod}
                  className={"policy-services-submodule__auth-action policy-services-submodule__auth-action--send" +
                    (!this.state.selectedMethod
                      ? " policy-services-submodule__auth-action--disabled"
                      : ""
                    )
                  }
                >
                  Send
                </button>
              </div>
            </section>
          </article>
        </article>
      )
    }
  }
}


export default AuthenticationMethods;
