import React, { Component } from 'react';
import withWindowDimensions from '../../../commons/withWindowDimensions';
import {
    AccordionWithHeader,
    AccordionNode,
    AccordionHeader,
    AccordionPanel
} from 'react-accordion-with-header';
import ShowMoreText from 'react-show-more-text';
import { getContestDetails } from '../commons/restEndpoint';
import ContestInProgress from '../components/contestInProgress';
import FormatOne from '../custom/contestDetails_format_one';
import NotFound from '../module/pagenotfound';
import MiniLoading from '../components/miniLoading';
import { checkProperties } from '../commons/service';
import { NavLink } from 'react-router-dom';

class contestDetails extends Component {

    constructor(props) {
        super(props)

        this.state = {
            title: "",
            description: "",
            image: null,
            iconImage: null,
            rewards: [],
            mechanics: {
                exclusionTxt: "",
                qualificationTxt: "",
                qualifiedAgentsTxt: "",
                resultValidationTxt: "",
                rewardTxt: "",
            },
            contestId: null,
            isLegitContest: false,
            isLoading: true,
        }
        this.fetchContestDetails = this.fetchContestDetails.bind(this);
        this.contestDetailsFormat = this.contestDetailsFormat.bind(this);
    }

    contestDetailsFormat() {
        const hasContestDetails = checkProperties(this.state.mechanics)
        var screen = <NotFound />;
        var contestInProgress = <ContestInProgress />;
        if (this.state.isLegitContest === true) {
            screen = <FormatOne data={this.state} />;
        } else {

        }
        return hasContestDetails ? screen : contestInProgress;
    }

    async fetchContestDetails() {
        let x = await getContestDetails(this.props.contestId, this.props.session.access_token);
        if (x) {
            if (x.data) {
                this.setState({
                    title: x.data.contestTitle,
                    image: x.data.contestImg,
                    iconImage: x.data.contestIconImg,
                    description: x.data.contestDescription,
                    rewards: x.data.rewards,
                    mechanics: x.data.mechanics,
                    contestId: x.data.contestId,
                    isLegitContest: true,
                    isLoading: false,
                });
            } else {
                this.setState({
                    isLegitContest: false,
                    isLoading: false,
                });
            }
        } else {
            this.setState({
                isLegitContest: false,
                isLoading: false,
            });
        }
    }

    componentDidMount() {
        this.fetchContestDetails();
    }

    render() {
        var { isLoading } = this.state;
        return (
            <div>
                <div className="container">
                    <NavLink style={{ textDecorationLine: 'none' }} to={"/rewards-and-incentives/contests"}><img style={{ width: '24px', height: '24px' }} src="/rni/angle-left-solid.svg" /><label style={{ fontSize: '16px', cursor: 'pointer', fontFamily: 'neo_semibold', marginBottom: '0px', color: '#007ab3' }}>Back to Contests</label></NavLink>
                </div>
                {!isLoading ? this.contestDetailsFormat() : <div style={{ width: '50vw', margin: 'auto' }}><MiniLoading /></div>}
            </div>
        )
    }
}


export default withWindowDimensions(contestDetails);