import React, { Component } from 'react';
import parse from 'html-react-parser';
import './announcement.scss';
import { readAllData, writeData } from '../../commons/utility';
import { LoaderGif2, LoaderGif3 } from '../../commons/loaderGif';
import { getAnnouncement } from './service';

export default class announcement extends Component {

    constructor(props) {
        super(props)

        this.state = {
            isFetching: true,
            message: '',
            showAnnouncement: false
        }
        this.closeAnnouncement = this.closeAnnouncement.bind(this);
    }

    componentWillMount() {
        const { pageToCheck } = this.props;
        this.checkSession(pageToCheck);
    }

    async fetchAnnouncement() {
        let result = await getAnnouncement();
        let message;
        if (result.data && Array.isArray(result.data.message)) {
            let newMessage = ''
            for (let content of result.data.message) {
                message = message + content;
            }
        } else {
            message = result.data && result.data.message
        }

        this.setState({
            message,
            isFetching: false,
            showAnnouncement: result.data
        })
    }

    async checkSession(pageToCheck) {
        let that = this;

        if (pageToCheck === 'login_page') {
            that.fetchAnnouncement();
        } 
        /*** removed the covid announcement after logging in
        else {
            readAllData('session')
                .then(function (session_result) {
                    let new_session_result = session_result[0];
                    if (!new_session_result[pageToCheck]) {
                        that.fetchAnnouncement();
                    }
                })
        }
        ***/
    }

    async closeAnnouncement() {
        const { pageToCheck } = this.props;
        readAllData('session')
            .then(function (session_result) {
                let new_session_result = session_result[0];
                new_session_result[pageToCheck] = true;

                writeData('session', new_session_result);
            })

        this.setState({
            showAnnouncement: false
        })
    }

    render() {
        const { isFetching, message, showAnnouncement } = this.state;

        return showAnnouncement ? (
            <div className="announcement-body">
                <div className="announcement-box">
                    <div className="announcement-close-container">
                        <h5 className="announcement-title">Announcement</h5>
                        <img className="announcement-close-icon" src="/announcement/close.png" alt="close-icon" onClick={this.closeAnnouncement} />
                    </div>
                    <div className="announcement-content">
                        <div style={{ overflow: 'auto' }}>
                            {!isFetching ?
                                parse(message) :
                                <div className="announcement-content-loader"><LoaderGif3 /></div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        ) : ''
    }
}
