import React, { Component } from 'react';
import '../../css/mis.scss';
import { apiModule, createQueryStrings } from '../../commons/apiCall';
import { formatValues, formatValuesNoDecimals } from './formatter';
import LoaderGif, { LoaderGif2, LoaderGif3 } from '../../commons/loaderGif';
import { haveValue, charts } from '../../commons/misConfig';
import CommentIcon from './commentIcon';
// import moment from 'moment';

class AverageCaseSize extends Component {
    constructor(props) {
        super(props);
        this.state = {
            averageCaseSize: '',
            isLoading: true,
            totalComments: 0
        }

        this.getAverageCaseSize = this.getAverageCaseSize.bind(this);
    }

    componentDidMount() {
        this.getAverageCaseSize(this.props);
        this.props.getCommentCount(this, 'aveCaseSize', this.props.access_token);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.willUpdate) {
            this.getAverageCaseSize(nextProps);
        }

        if(nextProps.willUpdateCommentCount){
            nextProps.getCommentCount(this, 'aveCaseSize', nextProps.access_token);
        }
    }

    async getAverageCaseSize(nextProps) {
        this.setState({
            isLoading: true
        })
        
        let thisComponent = this;
        let headerParams = {
            x_auth: nextProps.access_token
        };
        let filter = [];
        filter['period'] = haveValue(nextProps.period.value) ? nextProps.period.value : '';
        filter['metric'] = haveValue(nextProps.metric.value) ? nextProps.metric.value : '';
        filter['productOfferingType'] = haveValue(nextProps.product.value) ? nextProps.product.value : '';
        filter['channel'] = haveValue(nextProps.channel.value) ? nextProps.channel.value : '';
        filter['startDate'] = haveValue(nextProps.startDate) ? nextProps.startDate : '';
        filter['endDate'] = haveValue(nextProps.endDate) ? nextProps.endDate : '';
        filter['contractStatus'] = haveValue(nextProps.status.value) ? nextProps.status.value : '';
        filter['targetType'] = haveValue(nextProps.target.value) ? nextProps.target.value : '';
        apiModule("get_average_case_size", headerParams, null, createQueryStrings(filter))
            .then(function (result) {
                let averageCaseSize = result.goalDetails[0].averagePlannedNewPremiumRate;
                // let latestUpdateTime = result.goalDetails[0].lastUpdateDateTime;
                thisComponent.setState({
                    averageCaseSize: averageCaseSize > 0 ? formatValues(averageCaseSize) : '--',
                    isLoading: false
                });
                // switch(latestUpdateTime){
                //     case null:
                //     case undefined: thisComponent.props.getLatestUpdateTime("No Datetime Available"); break;
                //     default: thisComponent.props.getLatestUpdateTime(moment(latestUpdateTime).format('MMM DD YYYY h:mm A'));
                // }
            })
            .catch(function (err) {
                console.log(err);
                // thisComponent.props.getLatestUpdateTime("No Datetime Available");
            });
    }

    render() {
        const { onClickCommentIcon } = this.props;
        const { averageCaseSize, isLoading, totalComments } = this.state;
        const loader = <LoaderGif3 css="ml-auto" style={{ width: "2.5rem" }} />
        return (
            <div className="container mis-container col-sm-12 col-md-12 col-lg-12 col-xl-12">
                {/* <div className="mis-comment-icon-container" onClick={() => onClickCommentIcon(charts.averageCaseSize)}>
                    <div className="mis-comment-icon">
                        <img src = "/mis/comments.svg" className="mis-comment-icon-img"/>
                        <span className="mis-comment-count">
                            {formatValuesNoDecimals(totalComments)}
                        </span>
                    </div>
                </div> */}
                <CommentIcon
                    chart={charts.averageCaseSize}
                    onClickCommentIcon={onClickCommentIcon} 
                    totalComments={totalComments} />
                <div className="card mis-card overflow-hidden" style={{ backgroundColor: "rgb(243, 116, 73)", height: "80px" }}>
                    <img className="mis-card-bg-img" src="/static/media/card-client.417f4330.png" />
                    <div className="dashboard-card__data card-body dashboard__card-body text-right d-flex justify-content-end align-items-center">
                        <div className="card-body-content h-100 d-flex flex-column justify-content-between" style={{ zIndex: 0, marginRight: '10px' }}>
                            <h1 className="card-title dashboard__card-title font-neo-bold mb-2">
                                {!isLoading ? (averageCaseSize ? averageCaseSize : '--') : loader}
                            </h1>
                            <h5 className="card-subtitle dashboard__card-subtitle font-neo-semibold">
                                Average Case Size
                        </h5>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default AverageCaseSize;


