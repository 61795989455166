
import { config } from './configurations/environment';

export const myConfig = {
    ...config,
    hardcodePolicyId: false,

    // Mock Data
    ENABLE_MOCK_DATA: false,

    APP_ID: 5,
    PRIVACY_URL: "https://allianzpnblife_salesportal.dice205.asia/data-privacy-policy/",
    PRIVACY_EMAIL_REF: "protectprivacy@allianzpnblife.ph",
    CACHE_STATIC: "version_static_aug20",
    CACHE_DYNAMIC: "version_dynamic_aug20",
    //Generate this using "npm run web-push generate-vapid-keys" in the backend node,
    WEBSITE_NAME: 'AllianzPNBLife Sales Portal',
    SAFARI_WEB_PUSH_ID: 'web.dice205.generic.push',
    WEB_PUSH_PUBLIC_KEY: 'BFV9vRSzfJ14HIoQSHEXUdBZahmR8fYuWHs-wNEG5fu8K0bK46kY1TQBvFeag4n-ue5uh76HfbVDI1qb7XoGXjs',
    GENERIC_ERROR_MESSAGE: "We have encountered problems completing your request, please try again later",
    //Types of push notifications
    sidebarContext: undefined,
    PAGE_SIZE: 10,
    PAGE_SIZE_MODAL: 5,
    SESSION_TIMEOUT: 10,
    CHECK_SESSION_TIMEOUT_EVERY: 10,
    REFRESH_TOKEN_EVERY: 90,
    ADOBE_PDF_CLIENT_ID: "ce3e622bd04b4b4a87347f133d03b190",

    // Production report
    // PROD_REPORT_DATE: "2021-05-31",
    // Below is the current date for PROD_REPORT_DATE
    PROD_REPORT_DATE: new Date().toISOString().slice(0, 10),
    EDELIVERY_EMAIL: 'E-delivery@allianzpnblife.ph',
    APP_VERSION: {
        HO: "4.18.1",
        BANCA: "4.18.1",
        AGENCY: "4.18.1",
        HSBC: "4.18.1"
    },
    
    // scenario : file has to be updated in the future
    // 1. if the file name and path is the same, you don't have to change this part.
    // 2. if the file name and path is not the same, update this part accordingly. ex.: file was moved to a different folder
    BANCA_EDM_FILE: "/docs/banca/Digital ID Authentication For Banca.pdf",
    AGENCY_EDM_FILE: "/docs/agency/Digital ID Authentication For Agency.pdf",

    UPLOAD_MAX_TOTAL_FILESIZE_MB: 20,
    UPLOAD_MAX_FILESIZE_MB: 10,
}
