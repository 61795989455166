import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import withWindowDimensions from '../commons/withWindowDimensions';
import { ReportLoader } from '../commons/utilityViews.js';
import ErrorMessage from "../components/errorMessage";
import { apiModule, createQueryStrings } from "../commons/apiCall";
import { decrypt, changeUsernameToAllianzpnblife } from "../commons/utility";
import { myConfig } from "../commons/config.js";
import { LoaderGif4 } from '../commons/loaderGif';
import { checkPermissions, getSessionInfo } from '../commons/security.js';
import Select from "react-select";
import DownloadReport from "../components/ProductionReport/downloadReport";

const noChannelDisplay = [{ label: "No available channel", value: 0 }]
const noReportDisplay = [{ label: "No available report", value: 0 }]

class ProductionReport extends Component {
    state = {
        // Loaders
        isLoading: true,
        isReportsLoading: false,
        isGenerating: false,

        isSubmitDisabled: false,
        isReportsDropdownHidden: false,

        reportOptions: [],
        selectedReport: {},

        channelOptions: [],
        selectedChannel: {},

        // Error message
        errorMessage: "",

        agentNumber: "",
        roles: [],
        accessToken: "",
        isMaster: false,
    }

    // Lifecycle
    async componentDidMount() {
        await this.initialMountCalls();
    }

    initialMountCalls = async () => {
        const agentNumber = decrypt(this.props.getSession().agentNumber);
        const jwt = this.props.getSession().access_token;
        this.setState({
            agentNumber,
            accessToken: jwt,
            isMaster: await this.isMaster(),
        }, async () => {
            // Retrieve document types
            try {
                await this.getRoles();
                await this.fetchDataLists();
                this.setState({ isLoading: false });
            }
            catch (error) {
                this.displayError(myConfig.GENERIC_ERROR_MESSAGE);
                this.setState({
                    isLoading: false,
                    isSubmitDisabled: true,
                    channelOptions: noChannelDisplay,
                    selectedChannel: noChannelDisplay[0],
                    reportOptions: noReportDisplay,
                    selectedReport: noReportDisplay[0],
                });
            }
        })
    }

    displayError = (errorMessage) => {
        if (errorMessage) {
            this.setState({ errorMessage: errorMessage });
        }
    }

    hideError() {
        this.setState({ errorMessage: "" });
    }

    getPermissionsList = async () => {
        const sessionInfo = await getSessionInfo();
        return sessionInfo[0].permissions ? sessionInfo[0].permissions : [];
    }

    getRoles = async () => {
        const sessionInfo = await getSessionInfo();
        const headerParams = { x_auth: this.state.accessToken, webUser: decrypt(sessionInfo[0].username) };
        const rolesData = await apiModule("get_webuser_roles", headerParams);
        let roles = [];
        if (rolesData && rolesData.data.length > 0) {
            rolesData.data.forEach(item => {
                roles = [...roles, item.name];
            })
        }
        this.setState({
            roles
        })

        console.log("GET ROLES", roles);
    }

    isMaster = async (permission = "VIEW_PRODUCTION_REPORT_MASTER") => {
        const permissionList = await this.getPermissionsList();
        return checkPermissions([permission], permissionList);
    }

    fetchDataLists = async () => {
        const headerParams = { x_auth: this.state.accessToken, query: { key: this.state.agentNumber } };
        if (this.state.isMaster || ["PNB_CHANNEL_HEAD", "RBM_PNB"].some(role => this.state.roles.includes(role))) {
            await this.setDoubleDropdown(headerParams);
        } else if (!this.state.isMaster || ["CAO", "HSBC_CHANNEL_HEAD"].some(role => this.state.roles.includes(role))) {
            await this.setSingleDropdown(headerParams);
        }

    }

    setDoubleDropdown = async (params) => {
        const hardCodedChannelList = { "PNB": "PNB", "PNB_WEALTH": "PNB Wealth" }
        let channelList = await apiModule("get_salesforce_channel", { x_auth: params.x_auth });
        if (params.query.key && !this.state.isMaster) {
            params.query = createQueryStrings(params.query);
            channelList = await apiModule("get_salesforce_channel", params);
            if (!channelList || channelList.length === 0) {
                channelList = hardCodedChannelList;
            }
        } else if (this.state.roles.includes("RBM_PNB") || this.state.roles.includes("PNB_CHANNEL_HEAD")) {
            channelList = hardCodedChannelList;
        }

        this.setChannelsDropdown(channelList);
    }

    setChannelsDropdown = async (channelList) => {
        let channelOptions = noChannelDisplay;
        let selectedChannel = noChannelDisplay[0];
        let isSubmitDisabled = true;
        let isReportsDropdownHidden = true;
        if (channelList && Object.keys(channelList).length > 0 && !channelList.error) {
            isSubmitDisabled = false;
            isReportsDropdownHidden = false;
            channelOptions = this.setDropdownData(channelList);
            selectedChannel = channelOptions[0];
            await this.updateReportsDropdown(selectedChannel);
        }
        this.setState({
            isSubmitDisabled,
            isReportsDropdownHidden,
            channelOptions,
            selectedChannel,
        })
    }

    setSingleDropdown = async (params) => {
        if (this.state.roles.includes("CAO")) {
            params.query.key = "AGENCY"
        } else if (this.state.roles.includes("HSBC_CHANNEL_HEAD")) {
            params.query.key = "HSBC"
        }
        params.query = createQueryStrings(params.query);
        let reportList = {};
        reportList = await apiModule("get_salesforce_report", params);
        this.setReportsDropdown(reportList);
    }

    setReportsDropdown = async (reportList) => {
        let reportOptions = noReportDisplay;
        let selectedReport = noReportDisplay[0];
        let isSubmitDisabled = true;
        if (reportList && Object.keys(reportList).length > 0 && !reportList.error) {
            isSubmitDisabled = false;
            reportOptions = this.setDropdownData(reportList);
            selectedReport = reportOptions[0];
        }
        this.setState({
            isSubmitDisabled,
            reportOptions,
            selectedReport,
        })
    }

    setDropdownData = (objectData) => {
        return Object.keys(objectData).map(item => {
            return { label: objectData[item], value: item };
        })
    }

    handleUpdateSearchFilter = (key, choice) => {
        this.setState({ [key]: choice });
    }

    updateReportsDropdown = async (channel) => {
        let channelValue = `${channel.value}`;
        if (channelValue !== this.state.selectedChannel.value) {
            this.setState({
                isReportsLoading: true,
            })
            if (["DSU"].some(role => this.state.roles.includes(role)) && channelValue === 'HSBC') {
                channelValue = `HSBC_ROLE_DSU`;
            }
            const query = createQueryStrings({ key: channelValue });
            const headerParams = { x_auth: this.state.accessToken, query };
            let reportOptions = noReportDisplay;
            let selectedReport = noReportDisplay[0];
            let isSubmitDisabled = true;
            let reportList = {};
            reportList = await apiModule("get_salesforce_report", headerParams);
            if (reportList && Object.keys(reportList).length > 0 && !reportList.error) {
                isSubmitDisabled = false;
                reportOptions = this.setDropdownData(reportList);
                selectedReport = reportOptions[0];
            }
            this.setState({
                reportOptions,
                selectedReport,
                isSubmitDisabled,
                isReportsLoading: false,
            })
        }
    }

    handleSubmit = () => {
        this.generateReport();
    }

    generateReport = async () => {
        console.log("GENERATING REPORT");
        this.setState({
            isGenerating: true,
        })
        // CSV DOWNLOADER HERE
        if (this.state.isMaster) {
            console.log("CHANNEL", this.state.selectedChannel)
            // console.log("REPORT", this.state.selectedReport)
        } else {
            // console.log("REPORT", this.state.selectedReport)
        }
        await this.downloadReportFromApi(
            this.state.selectedReport,
            this.state.agentNumber
        );
        this.setState({
            isGenerating: false,
        })
    }

    downloadReportFromApi = async (
        selectedReport,
        agentNum
    ) => {
        const requestParams = {
            x_auth: decrypt(this.props.getSession().access_token),
            reportType: selectedReport["value"],
            // agentNum: "6001088" // HARDCODED, real code below
            agentNum,
            dateTo: myConfig.PROD_REPORT_DATE,
        }
        await apiModule("get_report_csv_file", requestParams, null, '', true
        ).then((result) => {
            const { data, fileName } = result.data[0];
            DownloadReport(data, fileName);
        })
            .catch((err) => {
                console.error(err);
                this.displayError("Something went wrong. Please try again");
            });
    }

    render() {
        const loader = <LoaderGif4 />
        if (this.state.isLoading || this.state.isGenerating) {
            return (
                <ReportLoader show={this.state.isLoading} />
            )
        }
        else {
            return (
                <div className="dashboard-section overflow-hidden">
                    <ErrorMessage message={this.state.errorMessage} />
                    <div className="dashboard-container container-fluid p-3">
                        <div className="dashboard-header">
                            <h6 className="font-neo-bold">
                                <Link className="text-darkgray text-decoration-none" to="/dashboard/reports">Reports</Link>
                                <span className="dashboard-header__subheader text-headercolor"> | Production</span>
                            </h6>
                        </div>
                        <div className="dashboard-body">
                            <div className="dashboard-datatable mt-4 documents-report__search">
                                <div className="d-flex flex-wrap">
                                    {(this.state.isMaster || ["PNB_CHANNEL_HEAD", "RBM_PNB"].some(role => this.state.roles.includes(role))) &&
                                        <Select
                                            options={this.state.channelOptions}
                                            isSearchable={false}
                                            className={`datatable-select mr-md-2 ${this.props.isSm ? 'report-dropdown-inner-mobile w-100 mb-2' : 'w-25'}`}
                                            classNamePrefix="datatable-select"
                                            value={this.state.selectedChannel}
                                            onChange={(value) => {
                                                this.hideError();
                                                this.handleUpdateSearchFilter("selectedChannel", value);
                                                this.updateReportsDropdown(value);
                                            }}
                                            styles={{
                                                option: (provided) => ({
                                                    ...provided,
                                                    fontFamily: "neo_semibold",
                                                    fontSize: ".6rem",
                                                    color: "#9A9B9C",
                                                    backgroundColor: "white",
                                                }),
                                                menu: () => ({
                                                    position: "absolute",
                                                    border: 0,
                                                    zIndex: "1090",
                                                    backgroundColor: "white",
                                                    borderRadius: "5px",
                                                    boxShadow: "2px 2px 2px #DDDEE0",
                                                }),
                                            }}
                                        />
                                    }
                                    {this.state.isReportsLoading ? loader :
                                        <Select
                                            options={this.state.reportOptions}
                                            isSearchable={false}
                                            className={`${this.state.isReportsDropdownHidden && "d-none"} datatable-select mr-md-2 ${this.props.isSm ? 'report-dropdown-inner-mobile w-100' : 'w-25'}`}
                                            classNamePrefix="datatable-select"
                                            value={this.state.selectedReport}
                                            onChange={(value) => {
                                                this.hideError();
                                                this.handleUpdateSearchFilter("selectedReport", value);
                                            }}
                                            styles={{
                                                option: (provided) => ({
                                                    ...provided,
                                                    fontFamily: "neo_semibold",
                                                    fontSize: ".6rem",
                                                    color: "#9A9B9C",
                                                    backgroundColor: "white",
                                                }),
                                                menu: () => ({
                                                    position: "absolute",
                                                    border: 0,
                                                    zIndex: "1090",
                                                    backgroundColor: "white",
                                                    borderRadius: "5px",
                                                    boxShadow: "2px 2px 2px #DDDEE0",
                                                }),
                                            }}
                                        />
                                    }
                                    <div className={`datatable-select ${this.props.isSm && "w-100"}`}>
                                        <button
                                            className={`lh-1 d-flex flex-grow-0 mt-2 mt-md-0 justify-content-center align-items-center btn btn-dropdowncolor rounded-pill text-uppercase ${this.props.isSm ? "font-p75rem report-generate-btn-mobile" : "font-p65rem report-generate-btn"} font-neo-semibold text-white datatable-search__btn`}
                                            onClick={() => {
                                                this.hideError();
                                                this.handleSubmit();
                                            }}
                                            disabled={this.state.isSubmitDisabled}
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }
}

export default withRouter(withWindowDimensions(ProductionReport));
