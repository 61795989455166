import React, { Component } from 'react';
import { apiModule, createQueryStrings } from '../../commons/apiCall';
import { haveValue, charts } from '../../commons/misConfig';
import {  getSessionInfo } from '../../commons/security.js';
import { encrypt, decrypt } from '../../commons/utility.js';
import Lottie from 'lottie-react-web';
import radarLoading from '../../assets/animation/radar-loading.json'
import Select from 'react-select';
import moment from 'moment';

class CommentSection extends Component {
    constructor(props) {
        super(props);

        this.state = {
            username: '',
            userImage: '',
            comments: [],
            isLoading: true,
            isReplyLoading: false,
            errorMessage: '',
            commentLoader: <div style={{ height: '100%', margin: 'auto' }}> <Lottie speed={1} options={{ animationData: radarLoading, loop: true }} /></div>,
            replyLoader: <div style={{ height: '300px', margin: 'auto' }}> <Lottie speed={1} options={{ animationData: radarLoading, loop: true }} /></div>,
            pageSize: 5,
            pageNumber: 1,
            nextPageNumber: 0,
            prevPageNumber: 0,
            pageCount: 0,
            currentCommentId: -1
        }

        this.getUsername = this.getUsername.bind(this);
        this.getComments = this.getComments.bind(this);
        this.addComment = this.addComment.bind(this);
        this.onClickChangePage = this.onClickChangePage.bind(this);
        this.toggleOptions = this.toggleOptions.bind(this);
        this.updateComment = this.updateComment.bind(this);
        this.deleteComment = this.deleteComment.bind(this);
        this.getReplies = this.getReplies.bind(this);
        this.addReply = this.addReply.bind(this);
    }

    componentWillMount() {
        this.getUsername();
        this.getComments(this.props);
    }

    componentWillReceiveProps(nextProps) {
        this.getComments(nextProps);
    }

    async getUsername(){
        let sessionInfo = await getSessionInfo();
        this.setState({
            username: decrypt(sessionInfo[0].username),
            userImage: sessionInfo[0].image
        })
    }

    getComments(thisProps){
        this.setState({
            isLoading: true
        })

        let { pageNumber, pageSize } = this.state;
        let that = this;
        let headerParams = {
            chartId: thisProps.activeChartComments.pathParam,
            x_auth: thisProps.access_token
        };

        let filter = []
        filter['pageNumber'] = pageNumber;
        filter['pageSize'] = pageSize;

        apiModule("get_comments", headerParams, null, createQueryStrings(filter))
            .then(async function (result) {
                let activeComments = result.comments !== null && result.comments.length > 0 ? result.comments : {};
                for (let i = 0; i < activeComments.length; i++){
                    activeComments[i].replies = await that.getReplies(activeComments[i].id, 5);
                    activeComments[i].isEditing = false;
                    activeComments[i].isEditingReply = false;
                }
                that.setState({
                    comments: result.comments ? result.comments : {},
                    nextPageNumber: result.nextPageNumber,
                    prevPageNumber:  result.previousPageNumber,
                    pageCount: result.pageCount,
                    isLoading: false,
                    errorMessage: ''
                })
            })
            .catch(function (err) {
                console.log(err)
                that.setState({
                    isLoading: false,
                    errorMessage: 'Error Retrieving Comments'
                })
            });
    }

    async refreshReplies(commentId, index, pageSize){
        this.setState({
            currentCommentId: commentId,
            isReplyLoading: true
        })

        let { comments } = this.state;

        let comment = {
            ...comments[index],
            isEditingReply: false,
            replies: await this.getReplies(commentId, pageSize)
        };

        comments[index] = comment;
        this.setState({
            comments,
            isReplyLoading: false
        })
    }

    async getReplies(commentId, pageSize){
        let { activeChartComments, access_token } = this.props;
        let that = this;

        let headerParams = {
            chartId: activeChartComments.pathParam,
            commentId: commentId,
            x_auth: access_token
        }

        let filter = []
        filter['pageNumber'] = 1;
        filter['pageSize'] = pageSize ? pageSize : 5;

        return await apiModule("get_replies", headerParams, null, createQueryStrings(filter))
            .then(function (result) {
                result.isEditing = true;
                that.setState({
                    isReplyLoading: false
                });
                return result;
            })
            .catch(function (err) {
                console.log(err);
                that.setState({
                    isReplyLoading: false
                });
                return {};
            });
    }

    async addComment(){
        let { currPeriod, currMetric, currChannel, currProduct, startDate, endDate, activeChartComments, updateCommentCount } = this.props;
        let { username } = this.state;
        let sessionInfo = await getSessionInfo();
        let that = this;
        let commentContent = document.getElementById('mis-comment-content').value;
        commentContent = commentContent ? commentContent : "";

        //DB Limit
        if(commentContent.trim().length > 4000){
            alert('Comment Too Long');
            document.getElementById('mis-comment-content').value = "";
        }
        else if(commentContent.trim().length > 0 ){
            let headerParams = {
                chartId: activeChartComments.pathParam,
                x_auth: sessionInfo[0].access_token
            }

            let payloadParams = {
                "authorName": decrypt(sessionInfo[0].name),
                "authorEmail": username,
                "appliedFilter": {
                    "period": currPeriod.label,
                    "metric": currMetric.label,
                    "channel": currChannel.label,
                    "product": currProduct.label,
                    "contractStatus": "ISSUED",
                    "startDate": moment(startDate).format('YYYY-MM-DD'),
                    "endDate": moment(endDate).format('YYYY-MM-DD')
                },
                "content": commentContent
            }

            apiModule("post_comment", headerParams, payloadParams, '', true)
                .then(function (result) {
                    that.getComments(that.props);
                })
                .catch(function (err) {
                    console.log(err);
                });

                document.getElementById('mis-comment-content').value = "";

                this.onClickChangePage(1);
                updateCommentCount();
        }
    }

    async addReply(commentId, index, pageSize){
        let { currPeriod, currMetric, currChannel, currProduct, startDate, endDate, activeChartComments } = this.props;
        let { username } = this.state;
        let sessionInfo = await getSessionInfo();
        let that = this;
        let replyContent = document.getElementById('mis-reply-'+index+'-content').value;
        replyContent = replyContent ? replyContent : "";

        if(replyContent.trim().length > 4000){
            alert('Comment Too Long');
            document.getElementById('mis-reply-'+index+'-content').value = "";
        }
        else{
            let headerParams = {
                chartId: activeChartComments.pathParam,
                commentId: commentId,
                x_auth: sessionInfo[0].access_token
            }

            let payloadParams = {
                "authorName": decrypt(sessionInfo[0].name),
                "authorEmail": username,
                "appliedFilter": {
                    "period": currPeriod.label,
                    "metric": currMetric.label,
                    "channel": currChannel.label,
                    "product": currProduct.label,
                    "contractStatus": "ISSUED",
                    "startDate": moment(startDate).format('YYYY-MM-DD'),
                    "endDate": moment(endDate).format('YYYY-MM-DD')
                },
                "content": replyContent
            }

            apiModule("post_reply", headerParams, payloadParams, '', true)
                .then(function (result) {
                    that.refreshReplies(commentId, index, pageSize);
                })
                .catch(function (err) {
                    console.log(err);
                });

                document.getElementById('mis-reply-'+index+'-content').value = "";
        }
    }

    onKeyUpAddComment(e){
        if(e.target.value.trim().length > 0 && e.keyCode == 13){
            this.addComment();
        }
    }

    onKeyUpAddReply(e, commentId, index, pageSize){
        if(e.target.value.trim().length > 0 && e.keyCode == 13){
            this.addReply(commentId, index, pageSize);
        }
    }

    onClickChangePage(newPageNumber){
        let that = this;
        this.setState({
            pageNumber: newPageNumber
        }, function(){
            that.getComments(this.props);
        })
    }

    toggleOptions(index, isToggleButton, isReply, replyIndex){
        if(isReply){
            let replyDropdown = document.getElementById('comment-'+index+'-reply-'+replyIndex);
            let replyOptions = document.getElementById('options-'+index+'-reply-'+replyIndex);
            let replyOptionEllipsis = document.getElementById('ellipsis-'+index+'-reply-'+replyIndex);
            if(isToggleButton){
                replyDropdown.classList.toggle('dropdown-show');
                replyOptions.classList.toggle('reply-options-clicked');
                replyOptionEllipsis.classList.toggle('reply-ellipsis-clicked');
            }
            else if(replyDropdown.classList.contains('dropdown-show')){
                replyDropdown.classList.remove('dropdown-show');
            }
        }
        else{
            let commentDropdown = document.getElementById('comment-'+index);
            let commentOptions = document.getElementById('options-'+index);
            let commentOptionEllipsis = document.getElementById('ellipsis-'+index);
            if(isToggleButton){
                commentDropdown.classList.toggle('dropdown-show');
                commentOptions.classList.toggle('comment-options-clicked');
                commentOptionEllipsis.classList.toggle('comment-vertical-ellipsis-clicked');
            }
            else if(commentDropdown.classList.contains('dropdown-show')){
                commentDropdown.classList.remove('dropdown-show');
            }
            
        }
    }

    toggleReplies(commentId, index, pageSize){
        let divReplies = document.getElementById('replies-'+index); 
        divReplies.classList.toggle('dropdown-show');
        if(divReplies.classList.contains('dropdown-show')){
            this.refreshReplies(commentId, index, pageSize)
        }
    }

    async deleteComment(commentId, index){
        if(window.confirm("Are you sure you want to delete your comment?")){
            let { updateCommentCount, activeChartComments } = this.props;
            let { username } = this.state;
            let sessionInfo = await getSessionInfo();
            let that = this;

            let headerParams = {
                chartId: activeChartComments.pathParam,
                commentId: commentId,
                x_auth: sessionInfo[0].access_token
            }
            
            let payloadParams = {
                "authorEmail": username
            }

            apiModule("delete_comment", headerParams, payloadParams, '', true)
            .then(function (result) {
                that.getComments(that.props);
            })
            .catch(function (err) {
                console.log(err);
            });

            this.toggleOptions(index);
            updateCommentCount();
        }
    }

    async deleteReply(commentId, index, replyId, repliesLength){
        if(window.confirm("Are you sure you want to delete your reply?")){
            let { activeChartComments } = this.props;
            let { username } = this.state;
            let sessionInfo = await getSessionInfo();
            let that = this;

            let headerParams = {
                chartId: activeChartComments.pathParam,
                commentId: commentId,
                replyId: replyId,
                x_auth: sessionInfo[0].access_token
            }
            
            let payloadParams = {
                "authorEmail": username
            }

            apiModule("delete_reply", headerParams, payloadParams, '', true)
            .then(function (result) {
                that.refreshReplies(commentId, index, repliesLength);
            })
            .catch(function (err) {
                console.log(err);
            });
        }
    }

    showUpdateCommentBox(index){
        let { comments } = this.state;

        let comment = {
            ...comments[index],
            isEditing: !(comments[index].isEditing)
        };

        comments[index] = comment;
        this.setState({
            comments
        })
    }

    showUpdateReplyBox(commentIndex, replyIndex){
        let { comments } = this.state;

        let reply = {
            ...comments[commentIndex].replies.comments[replyIndex],
            isEditing: !(comments[commentIndex].replies.comments[replyIndex].isEditing)
        };

        let comment = {
            ...comments[commentIndex],
            isEditingReply: !(comments[commentIndex].isEditingReply)
        }

        comment.replies.comments[replyIndex] = reply;
        comments[commentIndex] = comment;

        // comments[commentIndex].replies.comments[replyIndex] = reply;
        this.setState({
            comments
        })
    }

    async updateComment(commentId, index){
        let { activeChartComments } = this.props;
        let { username } = this.state;
        let sessionInfo = await getSessionInfo();
        let that = this;
        let textarea = document.getElementById("comment-textarea-"+index)

        if(textarea.value && textarea.value.length > 0){
            let headerParams = {
                chartId: activeChartComments.pathParam,
                commentId: commentId,
                x_auth: sessionInfo[0].access_token
            }
            
            let payloadParams = {
                authorEmail: username,
                content: textarea.value
            }
    
            apiModule("update_comment", headerParams, payloadParams, '', true)
            .then(function (result) {
                that.getComments(that.props);
            })
            .catch(function (err) {
                console.log(err);
            });
        }
    }

    async updateReply(commentId, commentIndex, replyId, replyIndex, repliesLength){
        let { activeChartComments } = this.props;
        let { username } = this.state;
        let sessionInfo = await getSessionInfo();
        let that = this;
        let textarea = document.getElementById("reply-textarea-"+commentIndex+"-"+replyIndex);

        if(textarea && textarea.value.length > 0){
            let headerParams = {
                chartId: activeChartComments.pathParam,
                commentId: commentId,
                replyId: replyId,
                x_auth: sessionInfo[0].access_token
            }
            
            let payloadParams = {
                authorEmail: username,
                content: textarea.value
            }
    
            apiModule("update_reply", headerParams, payloadParams, '', true)
            .then(function (result) {
                that.refreshReplies(commentId, commentIndex, repliesLength);
            })
            .catch(function (err) {
                console.log(err);
            });
        }
        }

    render() {
        let that = this;
        let { closeCommentSection, activeChartComments } = this.props;
        let { comments, commentLoader, isLoading, nextPageNumber, prevPageNumber, pageCount, pageNumber, username, isReplyLoading, replyLoader, currentCommentId, userImage } = this.state;
        let activeComments = comments.length > 0 ? comments : [];
        let chartLabel = activeChartComments ? activeChartComments.label : "";
        return (
            <div className="col-sm-12 col-md-12 col-lg-5 col-xl-4 mis-comment-section">
                <div className="mis-comment-section-header">
                    <div className="mis-comment-section-close-button" onClick={closeCommentSection}>X</div>
                    <div className="mis-comment-section-chart-label">{chartLabel}</div>
                    <img src = '/mis/chevron-circle-right.svg' className="mis-comment-section-add-comment-button" aria-hidden="true" onClick={() => this.addComment()}/>
                    <input className="mis-comment-section-add-comment" id="mis-comment-content" type="text" name="txtComment" placeholder="Write an update.." onKeyUp={(e) => this.onKeyUpAddComment(e)} />
                </div>
                <div className="mis-comment-section-comments">
                        { isLoading ? commentLoader:
                            <div>
                                {activeComments && activeComments.map(function (comment, i) {
                                return (
                                    <div key={"mis-comment-" + i} className="mis-comment-section-container">
                                        <div className={"mis-comment-header " + (comment.isEditingReply ? "mis-comment-disabled" : "")}>
                                            <div className="mis-comment-header-right">
                                                <div className="mis-comment-header-right-date">
                                                    <img src='/mis/clock.svg' className="mis-comment-section-icon"/> {moment(comment.updateDateTime ? comment.updateDateTime : comment.creationDateTime).format('MMM DD YYYY h:mm A')}
                                                </div>
                                                {username == comment.authorEmail &&
                                                    <div className="mis-comment-options" id={"options-"+i} onClick={() => that.toggleOptions(i, true)}>
                                                        <div className="mis-vertical-ellipsis" id={"ellipsis-"+i}/>
                                                        <div className="mis-comment-dropdown" id={"comment-"+ i}>
                                                            <div className="mis-comment-dropdown-item" onClick={() => that.showUpdateCommentBox(i)}><img src='/mis/edit.svg' className="mis-comment-section-icon"/> Edit</div>
                                                            <div className="mis-comment-dropdown-item" onClick={() => that.deleteComment(comment.id, i)}><img src='/mis/trash-alt.svg' className="mis-comment-section-icon"/> Delete</div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            <div className="mis-comment-container-header">
                                                <img className="mis-comment-container-header-img" src={comment.image ? 'data:image/png;base64, ' + comment.image : "/mis/man.svg"} />
                                                <div className="mis-comment-container-header-info">
                                                    <div>{comment.authorName}</div>
                                                    <div className="mis-comment-filters">
                                                        <span className="mis-comment-filters-item mis-comment-filter-period mis-text-black ">{comment.appliedFilter.period}</span>
                                                        <span className="mis-comment-filters-item mis-comment-filter-metric mis-text-white ">{comment.appliedFilter.metric}</span>
                                                        <span className="mis-comment-filters-item mis-comment-filter-channel mis-text-black ">{comment.appliedFilter.channel}</span>
                                                        <span className="mis-comment-filters-item mis-comment-filter-product mis-text-white ">{comment.appliedFilter.product}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {comment.isEditing ?
                                            <div className="mis-comment-update-div"> 
                                                <textarea id={"comment-textarea-"+i} className="mis-comment-textarea" defaultValue={comment.content} maxLength={4000}/>
                                                <br/>
                                                <div style={{textAlign: "right"}}>
                                                    <button className="mis-comment-cancel-btn" onClick={() => that.showUpdateCommentBox(i)}>Cancel</button>
                                                    <button className="mis-comment-save-btn" onClick={() => that.updateComment(comment.id, i)}>Save</button>
                                                </div>
                                            </div>
                                            : 
                                            <div className={"mis-posted-comment" + (comment.isEditingReply ? " mis-comment-disabled" : "")}>
                                                {comment.content}
                                                <br/>
                                            </div>
                                        }
                                        {comment.updateDateTime && 
                                            <div className="mis-comment-stats">
                                                {/* <span className="mis-comment-likes-img-container">
                                                    <img className="mis-comment-likes-img" src= "/mis/man.svg"/>
                                                </span>
                                                <span className="mis-comment-likes-label">Liked</span> */}
                                                <span className="mis-comment-edited-label">Edited</span>
                                            </div>}
                                        <table className={"mis-comment-container-footer " + (comment.isEditing ? "mis-comment-disabled" : "")  + (comment.isEditingReply ? "mis-comment-disabled" : "")}>
                                            <tbody>
                                                <tr>
                                                    {/* <td className="mis-comment-section-like-button">
                                                        <img src = '/mis/thumbs-up.svg' className="mis-comment-section-icon"/> Like
                                                    </td> */}
                                                    <td className="mis-comment-section-reply-button" onClick={() => that.toggleReplies(comment.id, i, 5)}>
                                                        <img src = '/mis/share.svg' className="mis-comment-section-icon"/> Reply
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div className={"mis-comment-container-replies dropdown-show " + (comment.isEditing ? "mis-comment-disabled" : "")} id={"replies-"+i}>
                                            { isReplyLoading && currentCommentId === comment.id ? replyLoader : 
                                                <div>
                                                {comment.replies && comment.replies.comments && comment.replies.comments.map (function (reply, j) {
                                                        return (
                                                            <div className="mis-replies-container" key={"comment"+i+"-reply-"+j}>
                                                                {reply.isEditing ? 
                                                                    <div className="mis-comment-container-reply">
                                                                        <img className="mis-reply-author-img" src={comment.image ? 'data:image/png;base64, ' + comment.image : "/mis/man.svg"}/>
                                                                        <div className="mis-reply-content">
                                                                            <textarea id={"reply-textarea-"+i+"-"+j} className="mis-reply-textarea" defaultValue={reply.content}/>
                                                                        </div>
                                                                        <div style={{textAlign: "right"}}>
                                                                            <button className="mis-comment-cancel-btn" onClick={() => that.showUpdateReplyBox(i, j)}>Cancel</button>
                                                                            <button className="mis-comment-save-btn" onClick={() => that.updateReply(comment.id, i, reply.id, j, comment.replies.length)}>Save</button>
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    <div className={"mis-comment-container-reply " + (comment.isEditingReply ? "mis-comment-disabled" : "")}>
                                                                        <img className="mis-reply-author-img" src={comment.image ? 'data:image/png;base64, ' + comment.image : "/mis/man.svg"}/>
                                                                        <div className="mis-reply-content">
                                                                            <div className="mis-reply-content-body">
                                                                                <span className="mis-reply-author">{reply.authorName}</span> {reply.content}
                                                                                {username == comment.authorEmail &&
                                                                                    <div className="mis-reply-options" id={"options-"+i+"-reply-"+j} onClick={(e) => that.toggleOptions(i, true, true, j, e)}>
                                                                                        <div className="mis-ellipsis" id={"ellipsis-"+i+"-reply-"+j}/>
                                                                                        <div className="mis-reply-dropdown" id={"comment-"+i+"-reply-"+j}>
                                                                                            <div className="mis-comment-dropdown-item" onClick={() => that.showUpdateReplyBox(i, j)}><img src='/mis/edit.svg' className="mis-comment-section-icon"/> Edit</div>
                                                                                            <div className="mis-comment-dropdown-item" onClick={() => that.deleteReply(comment.id, i, reply.id, comment.replies.length)}><img src='/mis/trash-alt.svg' className="mis-comment-section-icon"/> Delete</div>
                                                                                        </div>
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                            <table className="mis-reply-content-footer">
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td className="mis-reply-content-footer-date"><img src='/mis/clock.svg' className="mis-comment-section-icon"/> {moment(reply.updateDateTime ? reply.updateDateTime : reply.creationDateTime).format('MMM DD YYYY h:mm A')}</td>
                                                                                        {reply.updateDateTime && <td className="mis-reply-content-edited">Edited</td>}
                                                                                        {/* <td className="mis-reply-content-footer-views">0 <img src='/mis/eye.svg' className="mis-comment-section-icon"/></td> */}
                                                                                        {/* <td className="mis-reply-content-footer-likes">{reply.upvoteCount} <img src='/mis/thumbs-up.svg' className="mis-comment-section-icon"/></td> */}
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        )
                                                    }
                                                )}
                                                { comment.replies && comment.replies && comment.replies.comments && comment.replies.comments.length < comment.replies.totalComments &&
                                                    <div className="mis-reply-view-more" onClick={() => that.refreshReplies(comment.id, i, comment.replies.comments.length + 5)}>View More</div>
                                                }
                                                <div className={"mis-comment-container-reply-footer" + (comment.isEditingReply ? " mis-comment-disabled" : "")}>
                                                    <img className="mis-reply-author-img" src={userImage ? userImage : "/mis/man.svg"} />
                                                    <img src = '/mis/chevron-circle-right.svg' className="mis-reply-content-add-reply-button" aria-hidden="true" onClick={() => that.addReply(comment.id, i, 5)}/>
                                                    <input className="mis-comment-container-add-reply" id={'mis-reply-'+i+'-content'} type="text" name="txtReply" placeholder="Write an update.." onKeyUp={(e) => that.onKeyUpAddReply(e, comment.id, i, 5)} />
                                                </div>
                                            </div>
                                            }
                                        </div>
                                    </div>
                                )
                            })}

                            {activeComments.length > 0 && 
                                <div className="mis-page-container">
                                    { prevPageNumber > 0 ? 
                                        <span style={{margin: 'auto'}}> 
                                            <span className="mis-page-button" onClick={() => this.onClickChangePage(1)}>{"<<"}</span> 
                                            <span className="mis-page-button" onClick={() => this.onClickChangePage(prevPageNumber)}>{"<"}</span> 
                                        </span>: "" 
                                    }
                                    <span className="mis-page-number">{pageNumber}</span>
                                    { nextPageNumber <= pageCount ?
                                        <span style={{margin: 'auto'}}> 
                                            <span className="mis-page-button" onClick={() => this.onClickChangePage(nextPageNumber)}>{">"}</span>
                                            <span className="mis-page-button" onClick={() => this.onClickChangePage(pageCount)}>{">>"}</span>
                                        </span> : "" 
                                    }
                                </div>
                            }
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export default CommentSection;