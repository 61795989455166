import React, { Component } from 'react';
import { Loader } from '../commons/utilityViews.js';
import { withRouter, Link } from 'react-router-dom';
import 'react-vertical-timeline-component/style.min.css';
import { renameByChannel, urlBase64ToUint8Array } from '../commons/utility.js';
import { isManager, checkLogin, checkPermissions, getSessionInfo } from '../commons/security.js';
import { KeyPlusIcon } from 'mdi-react';

class Reports extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            permissionsList: []
        };
    }

    async componentDidMount() {
        var permissionsList = await getSessionInfo();

        this.setState({
            permissionsList: permissionsList[0].permissions
        });
    }

    dataCards = (css, permissionsList) => {
        let channel = this.props.getSession().channel;
        let pageLabel = renameByChannel("List of FA", channel, this.props.getSession().view_as_channel);
        let pageLabelPolicyPerServicingFA = renameByChannel("Servicing FA", channel, this.props.getSession().view_as_channel);
        let pageLabelClientPerServicingFA = renameByChannel("Servicing FA", channel, this.props.getSession().view_as_channel);
        pageLabel = pageLabel + "s per status";
        pageLabelPolicyPerServicingFA = "Policy list per " + pageLabelPolicyPerServicingFA;
        pageLabelClientPerServicingFA = "Client list per " + pageLabelClientPerServicingFA;

        const cardData = {
            'Birthday List': {
                'id': 10,
                'bg': {
                    'color': '#8AD6F8',
                    'img': 'card-birthday.png'
                },
                'value': 120,
                'link': "/dashboard/birthday-list",
                'permission': ["VIEW_REP_CL_BD"]
            },
            'Policies Due': {
                'id': 20,
                'bg': {
                    'color': '#007D8B',
                    'img': 'card-client.png'
                },
                'value': 3000,
                'link': "/dashboard/policies-due",
                'permission': ["VIEW_REP_POL_DUE"]
            },
            'Client List per Location': {
                'id': 30,
                'bg': {
                    'color': '#F1985D',
                    'img': 'card-report-location.png'
                },
                'value': 10,
                'link': "/dashboard/client-list-per-location",
                'permission': ["VIEW_REP_CL_LOC"]
            },
            /*
            **removing policy status due to inconsistency with the data source
            'Policy List per Status': {
                'id': 40,
                'bg': {
                    'color': '#4E6489',
                    'img': 'card-report-status.png'
                },
                'value': 30,
                'link': "/dashboard/policy-list-per-status",
                'permission': ["VIEW_REP_POL_DUE"]
            },
            */
            'Matured and Paid up Policies': {
                'id': 50,
                'bg': {
                    'color': '#CFDB72',
                    'img': 'card-matured.png'
                },
                'value': 30,
                'link': "/dashboard/matured-and-paid-up-policies",
                'permission': ["VIEW_REP_POL_DUE"]
            },
            'Policy List per Servicing FA': {
                'id': 60,
                'bg': {
                    'color': '#4D796F',
                    'img': 'card-report-status.png'
                },
                'value': 30,
                'link': "/dashboard/policy-list-per-servicing-fa-with-status",
                'permission': ["VIEW_REP_POL_SERV"]
            },
            'Client List per Servicing FA': {
                'id': 70,
                'bg': {
                    'color': '#59535F',
                    'img': 'card-report-location.png'
                },
                'value': 30,
                'link': "/dashboard/client-list-per-servicing-fa",
                'permission': ["VIEW_REP_POL_SERV"]
            },
            // 'List of FA': {
            //     'id': 8,
            //     'bg': {
            //         'color': '#553C7E',
            //         'img': 'card-client.png'
            //     },
            //     'value': 30,
            //     'link': "/dashboard/list-of-fas-per-status",
            //     'permission': ["VIEW_REP_POL_SERV"]
            // }
            'Lead Reports': {
                'id': 80,
                'bg': {
                    'color': '#007AB3',
                    'img': 'card-papers.png'
                },
                'value': 30,
                'link': "/dashboard/lead-reports",
                'permission': ["VIEW_LEAD_REPORT_ALLIANZ_ENGAGE"]
            },
            'Generate Documents': {
                'id': 90,
                'bg': {
                    'color': '#595158',
                    'img': 'card-report-status.png'
                },
                'value': 120,
                'link': "/dashboard/generate-documents",
                'permission': ["VIEW_REP_CL_BD"]
            },
            'Production': {
                'id': 91,
                'bg': {
                    'color': '#007D8B',
                    'img': 'card-report-status.png'
                },
                'value': 120,
                'link': "/dashboard/production-report",
                'permission': ["VIEW_PRODUCTION_REPORT", "VIEW_PRODUCTION_REPORT_MASTER"],
            },
            'Persistency': {
                'id': 120,
                'bg': {
                    'color': '#007D8B',
                    'img': 'card-report-status.png'
                },
                'value': 120,
                'link': "/dashboard/company-persistency",
                'permission': ["VIEW_COMPANY_PERSISTENCY"],
            },
            /*'Policies Without Payment Beyond Due Date': {
                'id': 100,
                'bg': {
                    'color': '#EFBE25',
                    'img': 'card-client.png'
                },
                'value': 30,
                'link': "/dashboard/list-of-policies-without-payment-beyond-due-date",
                'permission': []
            },*/
            'Lapse List': {
                'id': 110,
                'bg': {
                    'color': '#007D8B',
                    'img': 'card-report-status.png'
                },
                'value': 150,
                'link': "/dashboard/lapse-list",
                'permission': ["VIEW_REP_POL_DUE"]
            },
        };
        let cards = [];
        for (let key in cardData) {
            if (cardData.hasOwnProperty(key)) {

                if (checkPermissions(cardData[key].permission, permissionsList)) {
                    let pageName = key;
                    if (pageName == "List of FA")
                        pageName = pageLabel;
                    else if (pageName == 'Client List per Servicing FA') {
                        pageName = pageLabelClientPerServicingFA;
                    } else if (pageName == 'Policy List per Servicing FA') {
                        pageName = pageLabelPolicyPerServicingFA;
                    }
                    cards.push(
                        // parent div has ROW class

                        <Link key={cardData[key].id} className={`text-decoration-none w-100 ${css} px-0`} to={cardData[key].link}>
                            <div className={`dashboard-card-container dashboard-card-container--height align-items-stretch pb-4`}>
                                <div
                                    className="card overflow-hidden shadow w-100 h-100 dashboard__card"
                                    style=
                                    {{
                                        backgroundColor: cardData[key].bg.color,
                                    }}>

                                    <img className="card-bg-img" src={require(`../assets/img/${cardData[key].bg.img}`)} />
                                    <div className="card-body dashboard__card-body pt-4 minh-7rem text-right d-flex justify-content-end align-items-end">
                                        <div className="card-body-content">
                                            <h4 className="card-report-text card-title font-neo-semibold">{pageName}</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    );
                }
            }
        }
        return (
            <div className="dashboard-cards row">
                {cards}
            </div>
        );
    }
    render() {
        if (this.state.isLoading) {
            return (
                <Loader handleClose={this.hideModal} show={this.state.isLoading} />
            );
        } else {
            return (
                <div className="dashboard-section">
                    <div className="dashboard-container container-fluid p-3">
                        <div className="dashboard-header">
                            <h6 className="font-neo-bold text-darkgray">
                                <Link className="text-darkgray text-decoration-none" to="/dashboard/reports">Reports</Link>
                            </h6>
                            {this.props.getViewAs}
                        </div>
                        <div className="dashboard-body mt-4">
                            {this.dataCards("col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3", this.state.permissionsList)}
                        </div>
                    </div>
                </div>
            );
        }
    }
}
export default withRouter(Reports);
