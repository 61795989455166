import React, { Component, Fragment } from 'react';
import { withRouter, Route, Switch, Link } from 'react-router-dom';
import { ReportLoader } from '../commons/utilityViews.js';
import { decrypt, fixName } from '../commons/utility';
import { apiModule } from '../commons/apiCall';

import withWindowDimensions from '../commons/withWindowDimensions';
import ContactInformation from '../components/PolicyServices/contactInformation';
import PolicyInformation from '../components/PolicyServices/policyInformation';
import Declarations from '../components/PolicyServices/declarations';
import Confirmation from '../components/PolicyServices/confirmation';
import ConfirmationPrompt from '../components/PolicyServices/confirmationPrompt';
import AuthenticationMethods from '../components/PolicyServices/authenticationMethods';
import AuthenticationComplete from '../components/PolicyServices/authenticationComplete';

class ChangePersonalData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      agent: "",
      policyId: "",
      transactionId: "",
      changeDataStep: 1,
      authStep: 0,
      ownerName: "",
      insuredName: "",
      baseUrl: "",
      nextUrl: "",
      isAppliedToAll: false,
      baseCountry: "",
      baseCountryCode: "",
      baseMobileNum: "",
      baseEmail: "",
      newCountry: "",
      newCountryCode: "",
      newMobileNum: "",
      newEmail: "",
      authMethod: "",
      isNextDisabled: true,
      isConfirmationPromptShown: false,
      isAtAuthenticationStage: false,
      isLoading: true,
      isSubmoduleLoading: true,
      isFormInvalid: false
    }
    const baseRoute = "/dashboard/policy-services/digital-id";
    if (typeof this.props.location.query !== "object") {
      this.props.history.push(baseRoute);
    } else if (Object.keys(this.props.location.query).includes("policyId")) {
      this.state.policyId = this.props.location.query.policyId;
    } else {
      this.props.history.push(baseRoute);
    }

    if (typeof this.props.location.state !== "object") {
      this.props.history.push(baseRoute);
    } else if (Object.keys(this.props.location.state.userData).includes("ownerName")) {
      const { ownerName, email, contactNum } = this.props.location.state.userData;
      this.state.ownerName = ownerName;
      this.state.baseEmail = email;
      this.state.baseMobileNum = contactNum;
    } else {
      this.props.history.push(baseRoute);
    }

    if (typeof this.props.location.query === "object" && typeof this.props.location.state === "object") {
      this.getContractIdInfo(this.state.policyId);
    }

    this.state.baseUrl = this.props.match.url;
    this.state.nextUrl = `${this.props.match.url}/confirmation`;

    this.handleSubmoduleDoneLoading = this.handleSubmoduleDoneLoading.bind(this);
    this.changeIsAppliedToAll = this.changeIsAppliedToAll.bind(this);
    this.setIsNextDisabled = this.setIsNextDisabled.bind(this);
    this.handleSetInvalidForms = this.handleSetInvalidForms.bind(this);
    this.handleGetBaseCountry = this.handleGetBaseCountry.bind(this);
    this.handleContactInfoUnmount = this.handleContactInfoUnmount.bind(this);
    this.handleSetAuthMethod = this.handleSetAuthMethod.bind(this);

    this.handleConfirmationYes = this.handleConfirmationYes.bind(this);
    this.handleConfirmationCancel = this.handleConfirmationCancel.bind(this);
  }

  async getContractIdInfo(policyId) {
    await apiModule(
      "get_policy",
      {
        x_auth: decrypt(this.props.getSession().access_token),
        policyId
      },
      null
    ).then((result) => {
      const agent = result.agent.agentNumber;
      const insuredName = fixName(result.insured[0]);
      const baseCountryCode = result.contractHolder.countryPrefix || "63";
      this.setState({ baseCountryCode, insuredName, agent, isLoading: false, baseMobileNum: this.removePrefix(baseCountryCode, this.state.baseMobileNum) });
    })
      .catch((err) => {
        console.error(err);
      });
  }

  handleSubmoduleDoneLoading() {
    this.setState({ isSubmoduleLoading: false });
  }

  async handleLinkChange(e) {
    if (e === "Next" && (!this.state.newEmail && !this.state.newMobileNum && !this.state.newCountryCode)) {
      this.setState({ isFormInvalid: true })
    } else {

      if (this.state.changeDataStep === 1 && e === "Next") {
        this.setState({ isNextDisabled: true, isLoading: true, nextUrl: `${this.props.match.url}/confirmation` });
        await this.sendUserUpdatedInfo();
      }

      let changeDataStep = this.state.changeDataStep;
      if (e === "Previous") {
        changeDataStep--;
        this.setState({ changeDataStep });
        this.props.history.goBack();
      } else if (e === "Next") {
        changeDataStep++;
        this.setState({ changeDataStep });
      }

      this.props.history.push(this.state.nextUrl);
    }
  }

  changeIsAppliedToAll(isAppliedToAll) {
    this.setState({ isAppliedToAll });
  }

  setIsNextDisabled(isNextDisabled) {
    this.setState({ isNextDisabled });
  }

  handleSetInvalidForms(isFormInvalid) {
    this.setState({ isFormInvalid });
  }

  handleGetBaseCountry(baseCountry) {
    if (!this.state.baseCountry) {
      this.setState({ baseCountry });
    }
  }

  /**
   * On change contact info unmount, get values from component input elements.
   * Compare with existing values as to not duplicate base data and new data.
   *
   * @param {string} newCountry full name of user's new country
   * @param {string} newCountryCode country code (+000) in <Select>
   * @param {string} newMobileNum
   * @param {string} newEmail
   */
  handleContactInfoUnmount({ newCountry, newCountryCode, newMobileNum, newEmail }) {
    if (newCountryCode !== this.state.baseCountryCode || newMobileNum !== this.state.baseMobileNum) {
      this.setState({ newCountryCode });
    }

    if (newMobileNum !== this.state.baseMobileNum || newCountryCode !== this.state.baseCountryCode) {
      this.setState({ newMobileNum });
    }

    if (newEmail !== this.state.baseEmail) {
      this.setState({ newEmail });
    }

    if (newCountry !== this.state.baseCountry || newCountryCode !== this.state.baseCountryCode) {
      this.setState({ newCountry });
    }
  }

  handleConfirmationYes() {
    this.setState({ isAtAuthenticationStage: true, authStep: 1 });
    this.props.history.push(this.state.baseUrl + "/authentication-methods");
  }

  handleConfirmationCancel() {
    this.setState({ isConfirmationPromptShown: false });
  }

  /**
   * Show confirmation prompt modal when user reaches Declarations screen
   * @param {SyntheticEvent} e standard react SyntheticEvent for HTML `<a>` element
   */
  handleSubmitData(e) {
    e.preventDefault();
    this.setState({ isConfirmationPromptShown: true });
    console.log(this.state);
  }

  handleSetAuthMethod(authMethod) {
    this.setState({ authMethod, authStep: 2 });
    this.props.history.push(`${this.state.baseUrl}/authentication-success`);
  }

  async sendUserUpdatedInfo() {
    const contactChannel = [];
    const {
      agent,
      policyId,
      baseCountryCode,
      newCountryCode,
      newMobileNum,
      newEmail,
      newCountry,
      baseCountry,
    } = this.state;

    if (newMobileNum) {
      contactChannel.push({
        "type": "MOBILE",
        "value": newMobileNum,
        "code": newCountryCode ? newCountryCode : baseCountryCode,
        "description": newCountry ? newCountry : baseCountry,
      });
    }

    if (newEmail) {
      contactChannel.push({
        "type": "EMAIL",
        "value": newEmail
      });
    }

    const apiData = {
      "contractNumber": policyId,
      "agent": {
        "agentNumber": agent
      },
      "initiatedBy": decrypt(this.props.getSession().username),
      contactChannel,
      "transactionType": "TTYP_PD",
      "isApplyToAllPolicies": this.state.isAppliedToAll ? "Y" : "N"
    };

    const requestParams = {
      x_auth: decrypt(this.props.getSession().access_token),
      "contractNumber": policyId
    }

    await apiModule("user_transactions_contract", requestParams, apiData, '', true
    ).then((result) => {
      const { transactionId } = result.data;
      this.setState({ transactionId });
    })
      .catch((err) => {
        console.error(err);
      });

    this.setState({ isNextDisabled: false, isLoading: false, isSubmoduleLoading: false });
  }

  removePrefix = (countryPrefix = "", contactNum = "") => {
    let newContactNum = contactNum;
    const countryPrefixLength = countryPrefix.length
    const mobilePrefix = contactNum.substr(0, countryPrefixLength);
    if (countryPrefix == mobilePrefix) {
      newContactNum = contactNum.slice(countryPrefixLength);
    }
    return newContactNum;
  }

  render() {
    if (this.state.isLoading) {
      return (
        <div className="policy-dashboard policy-dashboard--loading d-flex h-100 w-100 justify-content-center align-items-center">
          <ReportLoader show={this.state.isLoading} />
        </div>
      );
    } else {
      return (
        <div className="policy-dashboard d-flex h-100">
          <div className="policy-dashboard__container dashboard-container container-fluid p-0 d-flex flex-column">
            <header className="policy-dashboard__header d-flex">
              <span className="policy-dashboard__icon icon-user-o"></span>
              <h4 className="font-neo-bold text-darkgray">Change Personal Data</h4>
            </header>
            <main className="policy-dashboard__content flex-grow-1 position-relative">
              {
                this.state.ownerName
                  ? PolicyInformation(this.state.policyId, this.state.ownerName, this.state.insuredName, this.state.transactionId)
                  : null
              }
              <div className="policy-progress-line">
                <div
                  className={"policy-progress-line__fill-color bg-headercolor policy-progress-line__fill-color--" + (
                    !this.state.isAtAuthenticationStage
                      ? `change-step${this.state.changeDataStep}`
                      : `auth-step${this.state.authStep}`
                  )}
                ></div>
              </div>
              <Switch>
                <Route
                  exact
                  path={this.state.baseUrl + "/confirmation"}
                  render={() => <Confirmation {...this.props} {...this.state} />}
                />
                <Route
                  path={this.state.baseUrl + "/authentication-methods"}
                  render={() => <AuthenticationMethods setAuthMethod={this.handleSetAuthMethod} previousRoute={"/dashboard/policy-services/digital-id"} {...this.props} {...this.state} />}
                />
                <Route
                  path={this.state.baseUrl + "/authentication-success"}
                  render={() => <AuthenticationComplete {...this.props} {...this.state} />}
                />
                <Route
                  exact
                  path={this.state.baseUrl}
                  render={
                    () =>
                      <Fragment>
                        <ContactInformation
                          setIsNextDisabled={this.setIsNextDisabled}
                          onUnmount={this.handleContactInfoUnmount}
                          onDoneLoading={this.handleSubmoduleDoneLoading}
                          emitBaseCountry={this.handleGetBaseCountry}
                          setInvalidForms={this.handleSetInvalidForms}
                          {...this.props}
                          {...this.state}
                        />
                        <Declarations
                          isApplied={this.state.isAppliedToAll}
                          emitDeclarationData={this.changeIsAppliedToAll}
                          {...this.props}
                          {...this.state}
                        />
                      </Fragment>}
                />
              </Switch>
              {
                !this.state.isAtAuthenticationStage
                  ? <div className="policy-dashboard__step-links">
                    <button
                      onClick={() => this.handleLinkChange("Previous")}
                      className="policy-dashboard__step-link policy-dashboard__step-link--previous"
                    >Previous</button>
                    {
                      this.state.isNextDisabled
                        ? <button
                          disabled
                          onClick={(e) => e.preventDefault()}
                          className="policy-dashboard__step-link policy-dashboard__step-link--next policy-dashboard__step-link--disabled"
                        >Next</button>
                        : <button
                          onClick={
                            (e) => this.state.changeDataStep === 2
                              ? this.handleSubmitData(e)
                              : this.handleLinkChange("Next")
                          }
                          className="policy-dashboard__step-link policy-dashboard__step-link--next"
                        >{this.state.changeDataStep === 2 ? "Submit" : "Next"}</button>
                    }
                  </div>
                  : null
              }
              {
                this.state.isConfirmationPromptShown && this.state.changeDataStep === 2
                  ? <div
                    className="policy-dashboard__prompt-overlay"
                    onClick={() => this.setState({ isConfirmationPromptShown: false })}
                  >
                    <ConfirmationPrompt
                      headerIconClasses="icon-question mr-2"
                      headerText="Confirmation"
                      headerCloseClasses="icon-close mb-0"
                      content="Are the information correct?"
                      acceptText="Yes"
                      declineText="No"

                      handleAcceptTrigger={this.handleConfirmationYes}
                      handleDeclineTrigger={this.handleConfirmationCancel}
                      handleCancelTrigger={this.handleConfirmationCancel}
                    />
                  </div>
                  : null
              }
            </main>
          </div>
        </div>
      );
    }
  }
}


export default withRouter(withWindowDimensions(ChangePersonalData));
