import React, { Component } from 'react';
import moment from 'moment';

export default class Popup extends Component {
    constructor(props){
        super(props)
        this.state = {
            showPopup: true
        }

        this.closePopup = this.closePopup.bind(this);
        this.fetchMessage = this.fetchMessage.bind(this);
    }

    closePopup(){
        this.setState({
            showPopup: false
        })
    }

    fetchMessage(lastUpdate, currentDate){
        switch(lastUpdate){
            case "No Datetime Available": return (<p>Our system wasn't able to fetch any data. Please try again later.</p>) ; break;
            default: return (<p>Please be advised that the latest available data in the Digital MIS dashboard is {moment(lastUpdate).format('MMMM DD, YYYY')}.
                                <br/><br/>
                                Last successful batch run was {moment(currentDate).format('MMMM DD, YYYY')}.
                            </p>)
        }
    }

    render(){
        const {showPopup} = this.state;
        const {currentDate, lastUpdate} = this.props;
        let message = this.fetchMessage(lastUpdate, currentDate);
        return showPopup ? (
            <div className="mis-popup-body">
                <div className="mis-popup-background"/>
                <div className="mis-popup-box">
                    <div className="mis-popup-title-container">
                        <h5 className="mis-popup-title">Heads Up!</h5>
                    </div>
                    <div className="mis-popup-content">
                        {message}
                    </div>
                    <div className="mis-popup-close-container">
                        <button className="mis-popup-close-button" onClick={this.closePopup}>OK</button>
                    </div>
                </div>
            </div>
        ) : '';
    }
}