const mockPersistencyData = {
    13: {
        "goalDetails":
            [
                { "month": "July", "year": "2021", "achievedPersistencyPercentage": 50 }, { "month": "August", "year": "2021", "achievedPersistencyPercentage": 60 }, { "month": "September", "year": "2021", "achievedPersistencyPercentage": 70 }, { "month": "October", "year": "2021", "achievedPersistencyPercentage": 80 }, { "month": "November", "year": "2021", "achievedPersistencyPercentage": 90 }, { "month": "December", "year": "2021", "achievedPersistencyPercentage": 80 }, { "month": "January", "year": "2022", "achievedPersistencyPercentage": 70 }, { "month": "February", "year": "2022", "achievedPersistencyPercentage": 60 }, { "month": "March", "year": "2022", "achievedPersistencyPercentage": 50 }, { "month": "April", "year": "2022", "achievedPersistencyPercentage": 60 }, { "month": "May", "year": "2022", "achievedPersistencyPercentage": 70 }, { "month": "June", "year": "2022", "achievedPersistencyPercentage": 80 }
            ],
        "formulaName": "RP",
    },
    25: {
        "goalDetails":
            [
                { "month": "July", "year": "2021", "achievedPersistencyPercentage": 85 }, { "month": "August", "year": "2021", "achievedPersistencyPercentage": 75 }, { "month": "September", "year": "2021", "achievedPersistencyPercentage": 65 }, { "month": "October", "year": "2021", "achievedPersistencyPercentage": 55 }, { "month": "November", "year": "2021", "achievedPersistencyPercentage": 45 }, { "month": "December", "year": "2021", "achievedPersistencyPercentage": 35 }, { "month": "January", "year": "2022", "achievedPersistencyPercentage": 25 }, { "month": "February", "year": "2022", "achievedPersistencyPercentage": 35 }, { "month": "March", "year": "2022", "achievedPersistencyPercentage": 45 }, { "month": "April", "year": "2022", "achievedPersistencyPercentage": 55 }, { "month": "May", "year": "2022", "achievedPersistencyPercentage": 65 }, { "month": "June", "year": "2022", "achievedPersistencyPercentage": 75 }
            ],
    }
}

const mockPersistencyPolicyList = [
    { "contractNumber": "104289665", "currency": "PHP", "productOfferingName": "AZpire Growth Prime Peso", "status": "Lapsed", "contractHolder": { "birthName": "OCOY, BIENVENIDO  ALBEOS" }, "paidUntilDate": "2019-04-06T00:00:00.000", "cap": 36000.00, "aap": 0.00 },
    { "contractNumber": "104002621", "currency": "PHP", "productOfferingName": "STEP-UP 7", "status": "Inforce", "contractHolder": { "birthName": "APAS, MYRNA  ABRERA" }, "paidUntilDate": "2026-05-25T00:00:00.000", "cap": 15000.00, "aap": 15000.00 },
    { "contractNumber": "104739172", "currency": "PHP", "productOfferingName": "eAZy Protect", "status": "Lapsed", "contractHolder": { "birthName": "FLANDER, JACKSON  RABE" }, "paidUntilDate": "2019-08-14T00:00:00.000", "cap": 16302.00, "aap": 0.00 },
    { "contractNumber": "104739214", "currency": "PHP", "productOfferingName": "eAZy Protect", "status": "Inforce", "contractHolder": { "birthName": "ARRAS, HELEN  ANONUEVO" }, "paidUntilDate": "2021-05-27T00:00:00.000", "cap": 7513.00, "aap": 7513.00 },
    { "contractNumber": "104739248", "currency": "PHP", "productOfferingName": "eAZy Protect", "status": "Lapsed", "contractHolder": { "birthName": "LETABA, MARY ZENDY  DORINGUEZ" }, "paidUntilDate": "2019-11-19T00:00:00.000", "cap": 7425.00, "aap": 0.00 },
    { "contractNumber": "104659354", "currency": "PHP", "productOfferingName": "AZpire Growth Prime Peso", "status": "Lapsed", "contractHolder": { "birthName": "DECENILLA, ELVIE  CERNADA" }, "paidUntilDate": "2019-05-18T00:00:00.000", "cap": 60000.00, "aap": 0.00 },
    { "contractNumber": "104764287", "currency": "PHP", "productOfferingName": "Health Dynamics Peso", "status": "Lapsed", "contractHolder": { "birthName": "FUENTES, CRESLY  SIMBAJON" }, "paidUntilDate": "2019-12-15T00:00:00.000", "cap": 30000.00, "aap": 0.00 }
];

export {
    mockPersistencyData,
    mockPersistencyPolicyList,
}
