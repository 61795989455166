import { myConfig } from './config.js';

export let endpoints = (requestParams = {}) => {
    const user_mgnt_endpoint = myConfig.LOGIN_URL;
    const keycloak_endpoint = myConfig.KEYCLOAK_URL;
    
    return {
        ...agent(requestParams),
        ...productionReportEndpoints(requestParams),
        authentication_login: {
            description: 'Login user to the system',
            url: '/oauth/token',
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            sync: false,
            server_url: keycloak_endpoint,
        },
        authentication_validate_otp: {
            description: 'Validate the OTP',
            url: '/oauth/token',
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            sync: true,
            server_url: keycloak_endpoint,
        },
        authentication_resend_otp: {
            description: 'Resend the OTP',
            url: '/oauth/token',
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            sync: true,
            server_url: keycloak_endpoint,
        },
        authentication_forgot_password: {
            description: 'Send reset password email to the user',
            url: '/password/notify',
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            sync: false,
            server_url: keycloak_endpoint,
        },
        authentication_logout: {
            description: 'Invalidates access and refresh token of the user.',
            url: '/oauth/logout',
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            sync: false,
            server_url: keycloak_endpoint,
        },
        authentication_refresh_token: {
            description: 'Retrieves new access token.',
            url: '/oauth/token',
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            sync: false,
            server_url: keycloak_endpoint,
        },
        /*
         authentication_forgot_password: {
            description: 'Retrieve password through email',
            url: `/auth/password/reset`,
            method: 'POST',
            headers:  {  'Content-Type': 'application/json' } ,// { 'Content-Type': 'application/x-www-form-urlencoded' },
            sync: false,
            server_url: 'http://assist-salesportal-api.allianzpnblife.ph',
        },
        authentication_change_password: {
            description: 'Change the current password of the user',
            url: `/auth​/password​/change`,
            method: 'POST',
            headers:  {  'Content-Type': 'application/json' } ,//{ 'Content-Type': 'application/x-www-form-urlencoded' },
            sync: false,
            server_url: 'http://assist-salesportal-api.allianzpnblife.ph',
        },
        */
        authentication_change_password: {
            description: 'Change the current password of the user',
            url: '/password/reset',
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            sync: false,
            server_url: keycloak_endpoint,
        },
        user_change_password: {
            description: 'Change the current password of the user',
            url: '/password/change',
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            sync: false,
            server_url: keycloak_endpoint,
        },
        get_user: {
            description: 'Retrieve user information from keycloak',
            url: `/users/${requestParams.webUser}`,
            method: 'GET',
            headers: { 'Authorization': `${requestParams.x_auth}` },
            sync: false,
            server_url: keycloak_endpoint,

        },
        get_user_role: {
            description: 'Retrieve role of the user in RMS',
            url: `/users/${requestParams.webUser}/roles?clientId=AZ-ASSIST`,
            method: 'GET',
            headers: { 'Authorization': `${requestParams.x_auth}` },
            sync: false,
            server_url: keycloak_endpoint,

        },
        user_store_vapid: {
            description: 'Stores the VAPID of the user for push notifications',
            url: '/api/auth/register_device',
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'x-auth': `${requestParams.x_auth}` },
            sync: false,
            server_url: user_mgnt_endpoint,

        },
        user_get_vapid: {
            description: 'Retrieves the public key for push notifications',
            url: '/api/auth/getPublicKey',
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'x-auth': `${requestParams.x_auth}` },
            sync: false,
            server_url: user_mgnt_endpoint,

        },
        user_list_all: {
            description: 'List all the users in the system',
            url: '/api/user/all',
            method: 'GET',
            headers: { 'Authorization': `${requestParams.x_auth}` },
            sync: false,
            add_user_role: true
        },
        /**** NOTIFICATION RELATED  *****/
        send_notification: {
            description: 'Send notifications to the users within the given ID',
            url: '/api/notification/send_notification',
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Authorization': `${requestParams.x_auth}` },
            sync: true,
            add_user_role: true

        },
        profile_inbox: {
            description: 'Retrieves the logged in users inbox',
            url: '/api/profile/inbox',
            method: 'GET',
            headers: { 'Authorization': `${requestParams.x_auth}` },
            sync: false,
            add_user_role: true
        },
        /**** SEND EMAILS  *****/
        send_email: {
            description: 'Send Email',
            url: '/email',
            method: 'POST',
            headers: { 'Authorization': `${requestParams.x_auth}` },
            sync: false,
            add_user_role: true
        },
        /**** MANAGE USERS MODULE  *****/
        admin_get_all_users: {
            description: 'Get all users list for manage user module',
            url: '/api/user/all',
            method: 'GET',
            headers: { 'Authorization': `${requestParams.x_auth}` },
            sync: false,
            add_user_role: true
        },
        admin_update_user: {
            description: 'Update user in manage user module',
            url: `/api/user/${requestParams.user_id}`,
            method: 'PUT',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Authorization': `${requestParams.x_auth}` },
            sync: true,
            add_user_role: true
        },
        admin_create_user: {
            description: 'Create user in manage user module',
            url: `/api/user/`,
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Authorization': `${requestParams.x_auth}` },
            sync: true,
            add_user_role: true
        },
        admin_search_user: {
            description: 'Search user in manage user module',
            url: '/api/user/search',
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Authorization': `${requestParams.x_auth}` },
            sync: false,
            add_user_role: true
        },
        admin_delete_user: {
            description: 'Delete user in manage user module',
            url: `/api/user/${requestParams.user_id}`,
            method: 'DELETE',
            headers: { 'Authorization': `${requestParams.x_auth}` },
            sync: false,
            add_user_role: true
        },
        /**** NOTIFICATION INBOX ****/
        user_notification_inbox: {
            description: 'User notification inbox',
            url: `/api/notification/inbox/${requestParams.user_id}`,
            method: 'GET',
            headers: { 'Authorization': `${requestParams.x_auth}` },
            sync: false,
            add_user_role: true
        },
        /*******USER PROFILE**********/
        user_profile_picture: {
            description: 'Upload user profile picture',
            url: '/api/upload/',
            method: 'POST',
            headers: { 'Authorization': `${requestParams.x_auth}`, 'content-type': 'multipart/form-data' },
            sync: false,
            add_user_role: true
        },
        //NEW
        /** POLICIES **/
        policy_search: {
            description: 'Search all policies',
            // url: '/contracts?query=' + requestParams.query,
            url: '/contracts',
            method: 'GET',
            headers: { 'Authorization': `${requestParams.x_auth}` },
            sync: true,
            add_user_role: true
        },
        policy_all: {
            description: 'List all policies',
            url: `/contracts${requestParams.query || ""}`,
            method: 'GET',
            headers: { 'Authorization': `${requestParams.x_auth}` },
            sync: false,
            add_user_role: true
        },
        get_policy: {
            description: 'Get a policy by Id',
            url: `/contracts/${requestParams.policyId}`,
            method: 'GET',
            headers: { 'Authorization': `${requestParams.x_auth}` },
            sync: false,
            add_user_role: true
        },
        get_policy_dividends: {
            description: 'Get a policy by Id',
            url: `/contracts/${requestParams.policyId}/dividends`,
            method: 'GET',
            headers: { 'Authorization': `${requestParams.x_auth}` },
            sync: false,
            add_user_role: true
        },
        get_policy_coverage: {
            description: 'Get a policy coverages by Id',
            url: `/contracts/${requestParams.policyId}/coverages`,
            method: 'GET',
            headers: { 'Authorization': `${requestParams.x_auth}` },
            sync: false,
            add_user_role: true
        },
        get_policy_benefits_entitlement: {
            description: 'Get a policy benefits by Id',
            url: `/contracts/${requestParams.policyId}/benefitsentitlements`,
            method: 'GET',
            headers: { 'Authorization': `${requestParams.x_auth}` },
            sync: false,
            add_user_role: true
        },
        get_policy_paymenttransactions: {
            description: 'Get a policy payment transactions by Id',
            url: `/contracts/${requestParams.policyId}/paymenttransactions`,
            method: 'GET',
            headers: { 'Authorization': `${requestParams.x_auth}` },
            sync: false,
            add_user_role: true
        },
        get_policy_bills: {
            description: 'Get a policy bills by Id',
            url: `/contracts/${requestParams.policyId}/bills`,
            method: 'GET',
            headers: { 'Authorization': `${requestParams.x_auth}` },
            sync: false,
            add_user_role: true
        },
        get_policy_claims: {
            description: 'Get a policy claims by Id',
            url: `/contracts/${requestParams.policyId}/claims`,
            method: 'GET',
            headers: { 'Authorization': `${requestParams.x_auth}` },
            sync: false,
            add_user_role: true
        },
        get_policy_loans: {
            description: 'Get a policy loans by Id',
            url: `/contracts/${requestParams.policyId}/loans`,
            method: 'GET',
            headers: { 'Authorization': `${requestParams.x_auth}` },
            sync: false,
            add_user_role: true
        },
        get_policy_endowments: {
            description: 'Get a policy endowments by Id',
            url: `/contracts/${requestParams.policyId}/endowments`,
            method: 'GET',
            headers: { 'Authorization': `${requestParams.x_auth}` },
            sync: false,
            add_user_role: true
        },
        get_policy_funds: {
            description: 'Get a policy funds by Id',
            url: `/contracts/${requestParams.policyId}/funds`,
            method: 'GET',
            headers: { 'Authorization': `${requestParams.x_auth}` },
            sync: false,
            add_user_role: true
        },
        get_policy_funds_transaction: {
            description: 'Get a policy funds by Id',
            url: `/contracts/${requestParams.policyId}/funds/${requestParams.fundId}/fundTransactions`,
            method: 'GET',
            headers: { 'Authorization': `${requestParams.x_auth}` },
            sync: false,
            add_user_role: true
        },
        get_policy_funds_transaction2: {
            description: 'Get a policy funds by Id',
            url: `/contracts/${requestParams.policyId}/fundtransactions`,
            method: 'GET',
            headers: { 'Authorization': `${requestParams.x_auth}` },
            sync: false,
            add_user_role: true
        },
        get_policy_endorsements: {
            description: 'Get a policy endorsements by Id',
            url: `/contracts/${requestParams.policyId}/endorsements${requestParams.query || ""}`,
            method: 'GET',
            headers: { 'Authorization': `${requestParams.x_auth}` },
            sync: false,
            add_user_role: true
        },
        get_pending_endorsements_requirements: {
            description: 'Get a policy endorsements by Id with requirements',
            url: `/worklists?contractNumber=${requestParams.contractNum}&accountManager=${requestParams.agentNumber}&transactionId=${requestParams.transactionId}&worklistType=endorsements`,
            method: 'GET',
            headers: { 'Authorization': `${requestParams.x_auth}` },
            sync: false,
            add_user_role: true
        },
        get_policy_reinstatements: {
            description: 'Get a policy reinstatements by Id',
            url: `/contracts/${requestParams.policyId}/reinstatements`,
            method: 'GET',
            headers: { 'Authorization': `${requestParams.x_auth}` },
            sync: false,
            add_user_role: true
        },
        get_transaction_statement: {
            description: 'Get transaction statement by Id',
            url: `/contracts/${requestParams.policyId}?contractMode=INITIAL`,
            method: 'GET',
            headers: { 'Authorization': `${requestParams.x_auth}` },
            sync: false,
            add_user_role: true
        },
        get_document_other_info: {
            description: "Get document PDF for other information",
            url: `/documents?type=${requestParams.type}&contractId=${requestParams.contractId}`,
            method: 'GET',
            headers: { 'Authorization': `${requestParams.x_auth}` },
            sync: true,
            add_user_role: true
        },
        get_pending_reinstatement_requirements: {
            description: 'Get list of pending requirements for reinstatement transaction',
            url: `/worklists?contractNumber=${requestParams.contractNum}&accountManager=${requestParams.agentNumber}&transactionId=${requestParams.transactionId}&worklistType=reinstatement`,
            method: 'GET',
            headers: { 'Authorization': `${requestParams.x_auth}` },
            sync: false,
            add_user_role: true
        },
        /** CLIENTS **/
        client_all: {
            description: 'List all clients',
            url: '/parties',
            method: 'GET',
            headers: { 'Authorization': `${requestParams.x_auth}` },
            sync: false,
            add_user_role: true
        },
        get_client: {
            description: 'Get a client by Id',
            url: `/parties/${requestParams.clientId}`,
            method: 'GET',
            headers: { 'Authorization': `${requestParams.x_auth}` },
            sync: false,
            add_user_role: true
        },
        get_client_policies: {
            description: 'Get policy list of a client',
            url: `/parties/${requestParams.clientId}/contracts`,
            method: 'GET',
            headers: { 'Authorization': `${requestParams.x_auth}` },
            sync: false,
            add_user_role: true
        },
        /** DATALIST **/
        get_all_policy_status: {
            description: 'List all policy status',
            url: '/accountmanagers',
            method: 'GET',
            headers: { 'Authorization': `${requestParams.x_auth}` },
            sync: false,
            add_user_role: true
        },
        /** ACCOUNT MANAGERS **/
        accountmanagers_all: {
            description: 'List all accountmanagers',
            url: '/accountmanagers',
            method: 'GET',
            headers: { 'Authorization': `${requestParams.x_auth}` },
            sync: false,
            add_user_role: true
        },
        get_accountmanager: {
            description: 'Get a accountmanager by Id',
            url: `/accountmanagers/${requestParams.accountManagerId}`,
            method: 'GET',
            headers: { 'Authorization': `${requestParams.x_auth}` },
            sync: false,
            add_user_role: true
        },
        get_accountmanager_team: {
            description: 'Get Team member of accountmanagers',
            url: `/accountmanagers/${requestParams.accountManagerId}/team`,
            method: 'GET',
            headers: { 'Authorization': `${requestParams.x_auth}` },
            sync: false,
            add_user_role: true
        },
        get_accountmanager_permission: {
            description: 'Get permission of accountmanagers',
            url: `/accountmanagers/${requestParams.accountManagerId}/permission`,
            method: 'GET',
            headers: { 'Authorization': `${requestParams.x_auth}` },
            sync: false,
            add_user_role: true
        },
        get_accountmanager_hierarchy: {
            description: 'Get hierarchy of a accountmanagers',
            url: `/accountmanagers/${requestParams.accountManagerId}/hierarchy`,
            method: 'GET',
            headers: { 'Authorization': `${requestParams.x_auth}` },
            sync: false,
            add_user_role: true
        },
        get_dashboard_dataset: {
            description: 'Get datasets displayed on the dashboard',
            url: `/accountmanagers/${requestParams.accountManagerId}/datasets/${requestParams.datasetId}`,
            // URL WITH RANGE PARAMETER
            // url: `/accountmanagers/${requestParams.accountManagerId}/datasets/${requestParams.datasetId}/period/${requestParams.range}`,
            method: 'GET',
            headers: { 'Authorization': `${requestParams.x_auth}` },
            sync: false,
            add_user_role: true
            //server_url: dev_url
        },
        get_portfolio_dataset: {
            description: 'Get porfolio dataset displayed on the dashboard',
            url: `/accountmanagers/${requestParams.accountManagerId}/datasets/portfolio`,
            method: 'GET',
            headers: { 'Authorization': `${requestParams.x_auth}` },
            sync: false,
            add_user_role: true
        },
        get_inbox_dashboard: {
            description: 'Get datasets displayed on the dashboard',
            url: `/api/access/inbox/${requestParams.accountManagerId}`,
            method: 'GET',
            headers: { 'Authorization': `${requestParams.x_auth}` },
            sync: false,
            server_url: user_mgnt_endpoint
        },
        get_dashboard_funds: {
            description: 'Get a funds nav for dashboard',
            url: `/funds/`,
            method: 'GET',
            headers: { 'Authorization': `${requestParams.x_auth}` },
            sync: false,
            add_user_role: true
        },
        get_dashboard_fund_details: {
            description: 'Get a funds nav for dashboard',
            //url: `/funds/${requestParams.fundCode}`,
            url: `/funds/${requestParams.fundCode}/fundhistories`,
            method: 'GET',
            headers: { 'Authorization': `${requestParams.x_auth}` },
            sync: false,
            add_user_role: true
        },
        /**** SEND EMAILS  *****/
        send_email: {
            description: 'Send Email',
            url: '/email',
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `${requestParams.x_auth}` },
            sync: false,
            add_user_role: true
        },
        update_webuser: {
            description: 'Get a accountmanager by Id',
            url: `/webusers/${requestParams.accountManagerId}`,
            method: 'PUT',
            headers: { 'Content-Type': 'application/json', 'Authorization': `${requestParams.x_auth}` },
            sync: true,
            add_user_role: true
        },
        reports: {
            description: 'Get reports',
            url: `/reports`,
            method: 'GET',
            headers: { 'Authorization': `${requestParams.x_auth}` },
            sync: true,
            add_user_role: true
        },
        get_webuser_permissions: {
            description: 'Get permission of all types of user',
            url: `/webusers/${requestParams.webUser}/permission`,
            method: 'GET',
            headers: { 'Authorization': `${requestParams.x_auth}`  },
            sync: false,
            add_user_role: true
        },
        get_webuser_roles: {
            description: 'Get role of all types of user',
            url: `/webusers/${requestParams.webUser}/roles`,
            method: 'GET',
            headers: { 'Authorization': `${requestParams.x_auth}` },
            sync: false,
            add_user_role: true
        },
        get_webuser: {
            description: 'Get a webuser by Id',
            url: `/webusers/${requestParams.webUser}`,
            method: 'GET',
            headers: { 'Authorization': `${requestParams.x_auth}` },
            sync: true,
            add_user_role: true
        },
        get_datalist: {
            description: 'Get datalist based on datasetid',
            url: `/datalists/${requestParams.dataListId}`,
            method: 'GET',
            headers: { 'Authorization': `${requestParams.x_auth}` },
            sync: true,
            add_user_role: true
        },
        get_states: {
            description: 'Get states based on countryId',
            url: `/countries/${requestParams.countryId}/states`,
            method: 'GET',
            headers: { 'Authorization': `${requestParams.x_auth}` },
            sync: true,
            add_user_role: true
        },
        get_cities: {
            description: 'Get cities based on stateId',
            url: `/countries/${requestParams.countryId}/states/${requestParams.stateId}/cities`,
            method: 'GET',
            headers: { 'Authorization': `${requestParams.x_auth}` },
            sync: true,
            add_user_role: true
        },
        /**** MIS Calls  *****/

        get_mis_version: {
            description: 'Get MIS Version',
            url: `/health`,
            method: 'GET',
            headers: { 'Authorization': `${requestParams.x_auth}` },
            sync: true,
            add_user_role: true
        },
        // get_periods: {
        //     description: 'Get Period Options',
        //     url: `/datalists/period`,
        //     method: 'GET',
        //     headers: { 'Authorization': `${requestParams.x_auth}` },
        //     sync: true
        // },

        // get_metrics: {
        //     description: 'Get Metric Options',
        //     url: `/datalists/metric`,
        //     method: 'GET',
        //     headers: { 'Authorization': `${requestParams.x_auth}` },
        //     sync: true
        // },

        // get_product_types: {
        //     description: 'Get Product Type Options',
        //     url: `/datalists/product`,
        //     method: 'GET',
        //     headers: { 'Authorization': `${requestParams.x_auth}` },
        //     sync: true
        // },

        // get_channels: {
        //     description: 'Get Channel Options',
        //     url: `/datalists/channel`,
        //     method: 'GET',
        //     headers: { 'Authorization': `${requestParams.x_auth}` },
        //     sync: true
        // },

        get_average_case_size: {
            description: 'Get Average Case Size',
            url: `/salesgoals/aveCaseSize/dashboard`,
            method: 'GET',
            headers: { 'Authorization': `${requestParams.x_auth}` },
            sync: true,
            add_user_role: true
        },

        get_percent_achieved: {
            description: 'Get Percent Achieved',
            url: `/salesgoals/achievement/dashboard`,
            method: 'GET',
            headers: { 'Authorization': `${requestParams.x_auth}` },
            sync: true,
            add_user_role: true
        },

        get_issued_per_channel: {
            description: 'Get Issued Per Channel',
            url: `/salesgoals/channel/dashboard`,
            method: 'GET',
            headers: { 'Authorization': `${requestParams.x_auth}` },
            sync: true,
            add_user_role: true
        },

        get_metric_per_product: {
            description: 'Get Metric Per Product',
            url: `/salesgoals/product/dashboard`,
            method: 'GET',
            headers: { 'Authorization': `${requestParams.x_auth}` },
            sync: true,
            add_user_role: true
        },

        get_actual_line_graph: {
            description: 'Get Actual Line Graph',
            url: `/salesgoals/actual/dashboard`,
            method: 'GET',
            headers: { 'Authorization': `${requestParams.x_auth}` },
            sync: true,
            add_user_role: true
        },

        get_target_line_graph: {
            description: 'Get Target Line Graph',
            url: `/salesgoals/target/dashboard`,
            method: 'GET',
            headers: { 'Authorization': `${requestParams.x_auth}` },
            sync: true,
            add_user_role: true
        },

        get_metric_per_status: {
            description: 'Get Metric Per Status',
            url: `/salesgoals/contractStatus/dashboard`,
            method: 'GET',
            headers: { 'Authorization': `${requestParams.x_auth}` },
            sync: true,
            add_user_role: true
        },

        get_heatmap: {
            description: 'Get Heatmap Data',
            url: `/salesgoals/heatmap/dashboard`,
            method: 'GET',
            headers: { 'Authorization': `${requestParams.x_auth}` },
            sync: true,
            add_user_role: true
        },

        get_fund_allocation: {
            description: 'Get Fund Allocation',
            url: `/salesgoals/fund/dashboard`,
            method: 'GET',
            headers: { 'Authorization': `${requestParams.x_auth}` },
            sync: true,
            add_user_role: true
        },

        get_per_client_type: {
            description: 'Get Value per Client Type',
            url: `/salesgoals/channel/dashboard`,
            method: 'GET',
            headers: { 'Authorization': `${requestParams.x_auth}` },
            sync: true,
            add_user_role: true
        },
        
        /**** FA PERSISTENCY Calls  *****/
        get_fa_persistency: {
            description: 'Get FA Persistency Card Percentage',
            url: `/accountmanagers/${requestParams.accountManagerId}/salesgoals/persistency`,
            method: 'GET',
            headers: { 'Authorization': `${requestParams.x_auth}` },
            sync: true,
            add_user_role: true
        },

        get_comments: {
            description: 'Get Comments',
            url: `/salesgoals/${requestParams.chartId}/comments`,
            method: 'GET',
            headers: { 'Authorization': `${requestParams.x_auth}` },
            sync: true,
            add_user_role: true
        },

        post_comment: {
            description: 'Post Comment',
            url: `/salesgoals/${requestParams.chartId}/comments`,
            method: 'POST',
            headers: { 'Authorization': `${requestParams.x_auth}` },
            sync: true,
            add_user_role: true
        },

        get_replies: {
            description: 'Get Replies',
            url: `/salesgoals/${requestParams.chartId}/comments/${requestParams.commentId}/replies`,
            method: 'GET',
            headers: { 'Authorization': `${requestParams.x_auth}` },
            sync: true,
            add_user_role: true
        },

        post_reply: {
            description: 'Post Reply',
            url: `/salesgoals/${requestParams.chartId}/comments/${requestParams.commentId}/replies`,
            method: 'POST',
            headers: { 'Authorization': `${requestParams.x_auth}` },
            sync: true,
            add_user_role: true
        },

        delete_comment: {
            description: 'Delete Comment',
            url: `/salesgoals/${requestParams.chartId}/comments/${requestParams.commentId}`,
            method: 'PUT',
            headers: { 'Authorization': `${requestParams.x_auth}` },
            sync: true,
            add_user_role: true
        },

        delete_reply: {
            description: 'Delete Comment',
            url: `/salesgoals/${requestParams.chartId}/comments/${requestParams.commentId}/replies/${requestParams.replyId}`,
            method: 'PUT',
            headers: { 'Authorization': `${requestParams.x_auth}` },
            sync: true,
            add_user_role: true
        },

        update_comment: {
            description: 'Update Comment',
            url: `/salesgoals/${requestParams.chartId}/comments/${requestParams.commentId}`,
            method: 'PUT',
            headers: { 'Authorization': `${requestParams.x_auth}` },
            sync: true,
            add_user_role: true
        },

        update_reply: {
            description: 'Update Comment',
            url: `/salesgoals/${requestParams.chartId}/comments/${requestParams.commentId}/replies/${requestParams.replyId}`,
            method: 'PUT',
            headers: { 'Authorization': `${requestParams.x_auth}` },
            sync: true,
            add_user_role: true
        },
        
        /**** NBV SCENARIO *****/

        get_nbv_scenario: {
            description: 'Get NBV Scenario',
            url: `/datalists/nbvscenario`,
            method: 'GET',
            headers: { 'Authorization': `${requestParams.x_auth}` },
            sync: true,
            add_user_role: true
        },
        
        get_nbv_margin_list: {
            description: 'Get NBV Margin List',
            url: `/salesgoals/nbv/goalmargins`,
            method: 'GET',
            headers: { 'Authorization': `${requestParams.x_auth}` },
            sync: true,
            add_user_role: true
        },
        get_applications: {
            description: 'Get list of applications',
            url: `/contracts`,
            method: 'GET',
            headers: { 
                'Authorization': `${requestParams.x_auth}` 
            },
            sync: true,
            add_user_role: true
        },

        get_pending_requirements: {
            description: 'View pending applications',
            url: `/worklists`,
            method: 'GET',
            headers: {
                'Authorization': `${requestParams.x_auth}`
            },
            sync: true,
            add_user_role: true
        },

        get_status: {
            description: 'View list of all status',
            url: `/datalists/APPLICATIONSTATUS`,
            method: 'GET',
            headers: {
                'Authorization': `${requestParams.x_auth}`,
            },
            sync: true,
            add_user_role: true
        },

        get_receiving_status: {
            description: 'View list of all status',
            url: `/datalists/receivingStatus`,
            method: 'GET',
            headers: {
                'Authorization': `${requestParams.x_auth}`,
            },
            sync: true,
            add_user_role: true
        },

        /**** DOCUMENT GENERATION *****/
        get_document_types: {
            description: 'Get document type list',
            url: `/datalists/DOCUMENTTYPES`,
            method: 'GET',
            headers: { 'Authorization': `${requestParams.x_auth}` },
            sync: true,
            add_user_role: true
        },

        get_persistency_document_types: {
            description: 'Get persistency document type list',
            url: `/datalists/assistPersistencyDocuments`,
            method: 'GET',
            headers: { 'Authorization': `${requestParams.x_auth}` },
            sync: true,
            add_user_role: true
        },

        get_documents: {
            description: "Get document list",
            url: `/documents`,
            method: 'GET',
            headers: { 'Authorization': `${requestParams.x_auth}` },
            sync: true,
            add_user_role: true
        },

        get_policy_requirements: {
            description: 'Get NBV Margin List',
            url: `/contracts/${requestParams.contractId}/claims/${requestParams.claimId}/requirements`,
            method: 'GET',
            headers: { 'Authorization': `${requestParams.x_auth}` },
            sync: true,
            add_user_role: true
        },
        generate_otp: {
            description: 'Generate OTP',
            url: `/otp/generate`,
            method: 'POST',
            headers: { 'Authorization': `${requestParams.x_auth}`, 'Content-Type': 'application/json' },
            sync: false,
            add_user_role: true
        },

        validate_otp: {
            description: 'Validate OTP',
            url: `/otp/validate`,
            method: 'POST',
            headers: { 'Authorization': `${requestParams.x_auth}`, 'Content-Type': 'application/json' },
            sync: false,
            add_user_role: true
        },

        policies_beyond_due_date: {
            description: "View list of all policies beyond due date",
            url: "/reports",
            method: "GET",
            headers: {
                'Authorization': `${requestParams.x_auth}`,
            },
            sync: true,
            add_user_role: true
        },

        get_plan_names: {
            description: 'View list of all plan names',
            url: `/datalists/planNames?key=${requestParams.accountManager}`,
            method: 'GET',
            headers: {
                'Authorization': `${requestParams.x_auth}`,
            },
            sync: true,
            add_user_role: true
        },
        
        get_table_of_benefits: {
            description: "Get Table of Benefits for Health plans",
            url: `/contracts/${requestParams.policyId}/benefitcatalogues`,
            method: "GET",
            headers: {
                'Authorization': `${requestParams.x_auth}`
            },
            sync: true,
            add_user_role: true
        },

        get_health_policy: {
            description: "Get IHP Details",
            url: `/contracts/${requestParams.policyId}/healthdetails`,
            method: "GET",
            headers: {
                'Authorization': `${requestParams.x_auth}`
            },
            sync: true,
            add_user_role: true
        },

        /**** POLICY SERVICES *****/
        user_transactions_contract: {
          description: 'Send changed user email, country code, phone number',
          url: `/usertransactions?contractNumber=${requestParams.contractNumber}`,
          method: 'POST',
          headers: {
            'Authorization': `${requestParams.x_auth}`,
            'Content-Type': 'application/json'
          },
          sync: false,
          add_user_role: true
        },

        auth_transaction_id: {
          description: 'Send selected auth type: email/sms',
          url: `/usertransactions/${requestParams.userTransactionId}`,
          method: 'PUT',
          headers: {
            'Authorization': `${requestParams.x_auth}`,
            'Content-Type': 'application/json'
          },
          sync: false,
          add_user_role: true
        },
        
        /**** CLIENT APPROVAL *****/
        get_api_token_from_alias: {
          description: "Get the full token from aliased version of the token sent through SMS or email",
          url: `/usertransactions/token/${requestParams.tokenAlias}`,
          method: "GET",
          headers: {
            'Content-Type': 'application/json'
          },
          sync: false,
          server_url: myConfig.ACTIVATION_URL
        },

        user_transactions: {
          description: 'Standard user transactions',
          url: `/usertransactions`,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          sync: false,
          server_url: myConfig.ACTIVATION_URL
        },

        user_transactions_id: {
          description: 'User transaction endpoint with IDs',
          url: `/usertransactions/${requestParams.transactionId}`,
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json'
          },
          sync: false,
          server_url: myConfig.ACTIVATION_URL
        },

        review_transactions: {
            description: 'User transaction endpoint with IDs',
            url: `/usertransactions/${requestParams.transactionId}`,
            method: 'GET',
            headers: {
              'Content-Type': 'application/json'
            },
            sync: false,
            server_url: myConfig.ACTIVATION_URL
        },

        show_policies: {
            description: 'User transaction endpoint with IDs',
            url: `/usertransactions/${requestParams.transactionId}/policies`,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
            sync: false,
            server_url: myConfig.ACTIVATION_URL
        },

        user_contract: {
          description: 'Retrieve current contact details for the customer',
          url: `/contracts/${requestParams.contractNumber}`,
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          },
          sync: false,
          server_url: myConfig.ACTIVATION_URL
        },

        get_terms_and_conditions: {
            description: "Get the written messages based on the consentCode for FTA",
            url: `/termsandconditions?consentCode=${requestParams.consentCode}`,
            method: "GET",
            headers: {
            'Content-Type': 'application/json'
            },
            sync: true,
            server_url: myConfig.ACTIVATION_URL
        },

        get_act_datalist: {
            description: 'Get datalist based on datasetid',
            url: `/datalists/${requestParams.dataListId}`,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
            sync: true,
            server_url: myConfig.ACTIVATION_URL
        },

    }
}

const agent = (requestParams = {}) => {
    return {
        get_user_transactions: {
            description: "Get user transactions",
            url: `/usertransactions${requestParams.query || ""}`,
            method: "GET",
            headers: {
                'Authorization': `${requestParams.x_auth}`
            },
            sync: true,
            add_user_role: true
        },
        list_tran_status: {
            description: "Get user transaction statuses",
            url: `/datalists/userTranStatus`,
            method: "GET",
            headers: {
                'Authorization': `${requestParams.x_auth}`
            },
            sync: true,
            add_user_role: true
        }
    }
}

const productionReportEndpoints = (requestParams = {}) => {
    return {
        get_salesforce_channel: {
            description: "List of salesforce channel",
            url: `/datalists/salesforceChannel${requestParams.query || ""}`,
            method: "GET",
            headers: {
                'Authorization': `${requestParams.x_auth}`,
            },
            sync: true,
            add_user_role: true
        },
        get_salesforce_report: {
            description: "List of salesforce report",
            url: `/datalists/salesforceReport${requestParams.query || ""}`,
            method: "GET",
            headers: {
                'Authorization': `${requestParams.x_auth}`,
            },
            sync: true,
            add_user_role: true
        },
        get_report_csv_file: {
            description: "CSV getter for Production Reports",
            url: `/documents?agentNumber=${requestParams.agentNum}&type=${requestParams.reportType}&dateTo=${requestParams.dateTo}`,
            method: "GET",
            headers: {
                'Authorization': `${requestParams.x_auth}`,
            },
            sync: true,
            add_user_role: true
        },
    }
}