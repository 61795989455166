import React from 'react';

const Document = (props) => {
    if(props.downloadable) {
        return(
            <li>
                <a className="js-download-link" href={props.link} download={props.name} target="_blank">
                    {props.name}
                    <i className="icon-launch documents-report__icon"></i>
                </a>
            </li>
        )
    } else {
        return(
            <li>
                <a className="js-download-link" onClick={props.downloadMessage} style={{color: "#007bff"}}>
                    {props.name}
                    <i className="icon-launch documents-report__icon"></i>
                </a>
            </li>
        )
    }

}

export default Document;
