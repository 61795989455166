import React, { Component } from 'react';

import { myConfig } from '../../commons/config.js';
import moment from "moment";

import ReactTable from 'react-table';
import TableLoader from '../TableLoader/tableLoader';

import Status from './status.js';
import withWindowDimensions from '../../commons/withWindowDimensions';


const Policies = (props) => {
    return (
        <>
            <ReactTable
                columns={props.columns}
                data={props.policies}
                loading={props.isTableLoading}
                minRows={1}
                LoadingComponent={TableLoader}
                defaultPageSize={myConfig.PAGE_SIZE}
                pages={props.pages}
                page={props.page}
                totalSize={props.totalSize}
                onPageChange={(page) => props.handleChangePage(page)}
                manual
                getTheadThProps={(state, rowInfo, column, instance) => ({
                    onClick: () => {
                        if(column.hasOwnProperty("sortColumn")) {
                            props.handleClickHeader(column.sortColumn);
                        }
                    },
                    sortClassName: getSortClassName(props.sortByField, props.sortOrder, column),
                    sortColor: (
                        props.sortByField == column.id ?
                            "text-black"
                            : ""
                    )
                })}
            >
            </ReactTable>
            {props.policies.length === 0 && !props.isTableLoading ?
                (<div className="center-align dashboard-row-data font-neo-semibold mt-4">No records found </div>)
                :
                ""
            }
        </>
    )
}


function getSortClassName(sortByField, sortOrder, column) {
    let sortClassName = "";
    if(column.hasOwnProperty("sortColumn")) {
        if(sortByField === column.sortColumn) {
            sortClassName = sortOrder === "ASC" ? "icon-chevron-small-up" : "icon-chevron-dropdown";
        }
        else {
            sortClassName = ""
        }
    }
    else {
        sortClassName = "d-none"
    }
    return sortClassName;
}

export default withWindowDimensions(Policies);