import React, { Component, Fragment } from 'react';

class ReviewTransaction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOnPolicyInfoScreen: false,
      isOnChangesScreen: false,
      areButtonsDisabled: false,
      userFirstName: "",
    }

    if (Object.keys(this.props.policyInfo).length === 0) {
      this.props.history.push('/');
    }
  }

  handleClick(type) {
    this.setState({ areButtonsDisabled: true })
    this.props.handleClick(type);
  }

  goToPolicyInfoScreen = () => {
    this.props.setProgress(2);
    this.setState({ isOnPolicyInfoScreen: true });
  }

  goToChangesScreen = () => {
    this.setState({ isOnPolicyInfoScreen: false, isOnChangesScreen: true });
  }

  showAuthConfirmationHeader = () => (
    <Fragment>
      <p>Dear {this.props.policyInfo.policyOwner},</p>
      <p>Did you initiate the request to change your contact information?</p>
    </Fragment>
  );

  showPolicyInformationHeader = () => (
    <Fragment>
      <p>Dear {this.props.policyInfo.policyOwner},</p>
      <p>We at Allianz PNB Life are grateful that you have allowed us the opportunity to be part of securing your future and to give you confidence in a better tomorrow.</p>
      <p>We have received a request for a policy information update from your agent. Please review the following details provided:</p>
    </Fragment>
  );

  showPolicyInformation = () => (
    <Fragment>
      <h5 className="review-transaction__heading">Policy Information</h5>
      <div className="review-transaction__section">
        <div className="review-transaction__content">
          <h6>Policy Number</h6>
          <span>{this.props.policyInfo.policyNumber}</span>
        </div>
        
        <div className="review-transaction__content">
          <h6>Transaction No.</h6>
          <span>{this.props.policyInfo.transactionNumber}</span>
        </div>
      </div>
      <div className="review-transaction__section">
        <div className="review-transaction__content">
          <h6>Policy Owner Name</h6>
          <span>
            {
              this.props.policyInfo.policyOwner ? this.props.policyInfo.policyOwner : "--"
            }
          </span>
        </div>
        
        <div className="review-transaction__content">
          <h6>Policy Insured Name</h6>
          <span>
            {
              this.props.policyInfo.insuredName ? this.props.policyInfo.insuredName : "--"
            }
          </span>
        </div>
      </div>
    </Fragment>
  );

  showPolicyChanges = () => (
    <Fragment>
      <div className="review-transaction__row d-flex flex-column">
        <h5 className="review-transaction__heading review-transaction__heading--info">Current:</h5>
        <div className="review-transaction__section">
          <div className="review-transaction__content">
            <h6>Mobile No.</h6>
            <span className="review-transaction__info">
              {
                Object.keys(this.props.policyContactInfo["CURRENT"]).includes("Mobile")
                  ? <Fragment>
                      <div className="review-transaction__flag-container">
                        {
                          (this.props.policyContactInfo["CURRENT"]["Mobile"].description && !String(this.props.policyContactInfo["CURRENT"]["Mobile"].description).includes("/")) && 
                          <img src={`/img/flags/${this.props.policyContactInfo["CURRENT"]["Mobile"].description}.jpg`} className="review-transaction__flag"/>
                        }
                      </div>
                      <span className="review-transaction__code">{ this.props.policyContactInfo["CURRENT"]["Mobile"].code ? `+${this.props.policyContactInfo["CURRENT"]["Mobile"].code}`: null }</span>
                      { this.props.policyContactInfo["CURRENT"]["Mobile"].value }
                    </Fragment>
                  : "--"
              }
            </span>
          </div>
          <div className="review-transaction__content">
            <h6>Email</h6>
            <span className="review-transaction__info">
              {
                Object.keys(this.props.policyContactInfo["CURRENT"]).includes("Email") && this.props.policyContactInfo["CURRENT"]["Email"].value
                  ? this.props.policyContactInfo["CURRENT"]["Email"].value
                  : "--"
              }
            </span>
          </div>
        </div>
      </div>
      <div className="review-transaction__row d-flex flex-column">
        <h5 className="review-transaction__heading review-transaction__heading--info">Updated:</h5>
        <div className="review-transaction__section">
          <div className="review-transaction__content">
            <h6>Mobile No.</h6>
            <span className="review-transaction__info">
              {
                Object.keys(this.props.policyContactInfo["NEW"]).includes("Mobile")
                  ? <Fragment>
                      <div className="review-transaction__flag-container">
                      {
                          (this.props.policyContactInfo["NEW"]["Mobile"].description && !String(this.props.policyContactInfo["NEW"]["Mobile"].description).includes("/")) && 
                          <img src={`/img/flags/${this.props.policyContactInfo["NEW"]["Mobile"].description}.jpg`} className="review-transaction__flag"/>
                        }
                      </div>
                      <span className="review-transaction__code">{ this.props.policyContactInfo["NEW"]["Mobile"].code ? `+${this.props.policyContactInfo["NEW"]["Mobile"].code}`: null }</span>
                      { this.props.policyContactInfo["NEW"]["Mobile"].value }
                    </Fragment>
                  : "--"
              }
            </span>
          </div>
          
          <div className="review-transaction__content">
            <h6>Email</h6>
            <span className="review-transaction__info">
              {
                Object.keys(this.props.policyContactInfo["NEW"]).includes("Email") && this.props.policyContactInfo["NEW"]["Email"].value
                  ? this.props.policyContactInfo["NEW"]["Email"].value
                  : "--"
              }
            </span>

            <h6><br/>Apply changes to all policies?</h6>
            <span className="review-transaction__info">
              {
                this.props.policyInfo.isApplyToAllPolicies === "Y" ? "YES" : "NO"
              }
            </span>
          </div>
        </div>
      </div>
    </Fragment>
  );

  render() {
    return (
      <section className="review-transaction">
        <header className="review-transaction__header">
          {
            !this.state.isOnChangesScreen &&
            !this.state.isOnPolicyInfoScreen &&
            this.showAuthConfirmationHeader()
          }
          {
            this.state.isOnPolicyInfoScreen &&
            this.showPolicyInformationHeader()
          }
        </header>
        <article className="review-transaction__user-info">
          {
            this.state.isOnChangesScreen && this.showPolicyChanges()
          }
          {
            this.state.isOnPolicyInfoScreen &&
            this.showPolicyInformation()
          }
        </article>
        <div className="review-transaction__buttons d-flex justify-content-between">
          {
            !this.state.isOnChangesScreen &&
            !this.state.isOnPolicyInfoScreen &&
            <Fragment>
              <button
                disabled={this.state.areButtonsDisabled}
                onClick={() => !this.state.areButtonsDisabled && this.handleClick("uninitiated")}
                className={"review-transaction__button review-transaction__button--uninitiated" + (
                  this.state.areButtonsDisabled ? " review-transaction__button--disabled" : ""
                )}
              >No</button>
              <button
                disabled={this.state.areButtonsDisabled}
                onClick={this.goToPolicyInfoScreen}
                className={"review-transaction__button review-transaction__button--next-auth" + (
                  this.state.areButtonsDisabled ? " review-transaction__button--disabled" : ""
                )}
              >Yes</button>
            </Fragment>
          }
          {
            this.state.isOnPolicyInfoScreen &&
            <button
              className="review-transaction__button review-transaction__button--next"
              onClick={this.goToChangesScreen}
            >Next</button>
          }
          {
            this.state.isOnChangesScreen &&
            <Fragment>
              <div className="review-transaction__decline-accept w-100">
                <button
                  disabled={this.state.areButtonsDisabled}
                  onClick={() => !this.state.areButtonsDisabled && this.handleClick("decline")}
                  className={"review-transaction__button review-transaction__button--decline" + (
                    this.state.areButtonsDisabled ? " review-transaction__button--disabled" : ""
                  )}
                >Decline</button>
                <button
                    disabled={this.state.areButtonsDisabled}
                    onClick={() => !this.state.areButtonsDisabled && this.handleClick("accept")}
                    className={"review-transaction__button review-transaction__button--accept" + (
                    this.state.areButtonsDisabled ? " review-transaction__button--disabled" : ""
                  )}
                >Approve Update</button>
              </div>
            </Fragment>
          }
        </div>
      </section>
    );
  }
}

export default ReviewTransaction;
