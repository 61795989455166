
import React, { Fragment } from 'react';
import Select from 'react-select';
import withWindowsDimensions from "../../commons/withWindowDimensions";

const Search = (props) => {
    const options = [
        { label: "All", value: "all" },
        { label: "Policy Number", value: "polNo" },
        { label: "Owner First Name", value: "firstName" },
        { label: "Owner Last Name", value: "lastName" },
    ]

    const mobileCss = () => {
        let mobileFlex = "";
        let searchBar = "";
        let dropdown = "";
        let resetBtn = "";
        if (props.isSm) {
            mobileFlex = "flex-wrap";
            searchBar = "w-100 mt-2";
            dropdown = "ml-0 flex-grow-1 mt-2";
            resetBtn = "ml-auto ml-0 w-25 mt-2"
        }
        return (
            {
                mobileFlex: mobileFlex,
                searchBar: searchBar,
                dropdown: dropdown,
                resetBtn: resetBtn
            }
        );
    }

    return (
        <div className="datatable-search">
            <form className={`d-flex ${mobileCss().mobileFlex}`}>
                <Select
                    styles={props.styles}
                    options={options}
                    isSearchable={false}
                    className={`datatable-select mr-md-2 ${mobileCss().dropdown} ${props.isSm && props.searchFilter.value === "statusDate" ? "w-100" : ""}`}
                    classNamePrefix="datatable-select"
                    value={props.searchFilter}
                    onChange={(e) => props.handleStateChange("searchFilter", e)}
                    styles={{
                        option: (provided) => ({
                            ...provided,
                            fontFamily: "neo_semibold",
                            fontSize: ".6rem",
                            color: "#9A9B9C",
                            backgroundColor: "white"
                        }),
                        menu: () => ({
                            position: "absolute",
                            border: 0,
                            zIndex: "1090",
                            backgroundColor: "white",
                            borderRadius: "5px",
                            boxShadow: "2px 2px 2px #DDDEE0"
                        }),
                    }}
                />

                {props.searchFilter.value !== "all" ?
                    (
                        <Fragment>
                            <div className={`custom-searchbar datatable-search__container form-control rounded-pill ${mobileCss().searchBar} mr-md-2`}>
                                <input
                                    value={props.searchTerm}
                                    onChange={(e) => props.handleStateChange("searchTerm", e)}
                                    className="searchbar__input datatable-search__input h-100"
                                    type="text"
                                    onKeyPress={props.handlePressEnter}
                                    placeholder="Search for"
                                />
                                <a onClick={props.handleSubmit} className="btn searchbar__button searchbar__button--gray px-2 cs-pointer">
                                    <span className="icon-search"></span>
                                </a>
                            </div>
                            <a
                                className={`lh-1 d-flex mt-2 mt-md-0 justify-content-center align-items-center btn btn-dropdowncolor rounded-pill text-uppercase ${props.isSm ? "font-p75rem" : "font-p65rem"} font-neo-semibold text-white datatable-search__btn ${props.isSm ? "w-100 ml-0" : "ml-2"}`}
                                onClick={props.handleSubmit}>
                                Submit
                            </a>
                        </Fragment>
                    ) : ""
                }
            </form>
        </div>
    );
}

export default withWindowsDimensions(Search);
