import React, { Component } from 'react';
import { Loader } from '../commons/utilityViews.js';
import { withRouter, Link } from 'react-router-dom';
import {createOnlineSILink, createApplicationFormLink} from '../commons/utility.js';
import { checkPermissions, getSessionInfo } from '../commons/security.js';

class OtherLinks extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            permissionList: [],
        };
    }

    async componentDidMount() {
        let sessionInfo = await getSessionInfo();
        let permissionList = sessionInfo[0].permissions ? sessionInfo[0].permissions : [];
        this.setState({ permissionList });
    }

    OnlineSI = () => {
        let channel = this.props.getSession().channel;
        channel = channel.toLowerCase();
        window.location = createOnlineSILink(channel);
    }

    
    dataCards = (css) => {
        let channel = this.props.getSession().channel;
        channel = channel.toLowerCase();
        let onlineSI = createOnlineSILink(channel);
        let eForm = createApplicationFormLink(channel);

        let cardData = {
            'Allianz Propose (OSI)': {
                'id': 1,
                'bg': {
                    'color': '#89679C',
                    'img': 'card-report-status.png'
                },
                'value': '',
                'link': onlineSI
            },
            'Allianz Expert': {
                'id': 2,
                'bg': {
                    'color': '#43C1C0',
                    'img': 'card-policy.png'
                },
                'value': 3000,
                'link': "https://expert.allianzpnblife.ph/"
            },
            'Product Information': {
                'id': 3,
                'bg': {
                    'color': '#007D8B',
                    'img': 'card-client.png'
                },
                'value': 3000,
                'link': "https://www.allianzpnblife.ph/solutions.html"
            },
            /********************* removed based on cpph-2523
            'File a Request or Inquiry': {
                'id': 4,
                'bg': {
                    'color': '#F37449',
                    'img': 'card-report-location.png'
                },
                'value': 3000,
                'link': "https://forms.monday.com/forms/0c487b192134441b63d923a870a7a5c8"
            },
            *********************/
            'Distributor Starter Kit': {
                'id': 5,
                'bg': {
                    'color': '#E4003A',
                    'img': 'card-papers.png'
                },
                'value': 3000,
                'link': "https://www.allianzpnblife.ph/Allianz-Assist/distributor-starter-kit.html"
            },
            'Allianz Insight': {
                'id': 6,
                'bg': {
                    'color': '#CCDD61',
                    'img': 'card-birthday.png'
                },
                'value': 3000,
                'link': "https://insight.allianzpnblife.ph/fna"
            }
        };

        const protectedCardData = {
            'Agent Knowledgebase': {
                'id': 8,
                'bg': {
                    'color': '#89679C',
                    'img': 'card-report-status.png'
                },
                'value': '',
                'link': '/dashboard/agent-knowledgebase',
                'permission': 'VIEW_AGENT_KNOWLEDGEBASE'
            }
        }

        if(eForm) {
            cardData = {...cardData,
                'Application Form': {
                    'id': 7,
                    'bg': {
                        'color': '#43C1C0',
                        'img': 'card-policy.png'
                    },
                    'value': 3000,
                    'link': eForm
                }
            }
        }
        let cards = [];
        for (let key in cardData) {
            if (cardData.hasOwnProperty(key)) {
                cards.push(this.generateCard(cardData, key, css, true, false));
            }
        }

        for (let key in protectedCardData) {
            const permission = protectedCardData[key].permission;
            const permissionList = this.state.permissionList;

            if (
                checkPermissions(permission, permissionList) &&
                protectedCardData.hasOwnProperty(key)
            ) {
                cards.push(this.generateCard(protectedCardData, key, css, false, true)); 
            }
        }

        return (
            <div className="dashboard-cards row">
                {cards}
            </div>
        );
    }

    generateCard = (cardData, key, css, isNewTab, isInternal) => {
        const cardBody = (
            <div className={`dashboard-card-container align-items-stretch mb-4`}>
                <div
                    className="card overflow-hidden shadow w-100 h-100 dashboard__card"
                    style=
                    {{
                        backgroundColor: cardData[key].bg.color,
                    }}>
                    <img className="card-bg-img" src={require(`../assets/img/${cardData[key].bg.img}`)} />
                    <div className="card-body dashboard__card-body minh-7rem text-right d-flex justify-content-end align-items-center">
                        <div className="card-body-content">
                            <h4 className="card-title font-neo-semibold mb-0">{key}</h4>
                        </div>
                    </div>
                </div>
            </div>
        );

        return (
            !isInternal ?
            (
                <a key={cardData[key].id} className={`text-decoration-none w-100 ${css} px-0`} target={isNewTab ? '_blank' : '_self'} href={cardData[key].link}>
                    { cardBody }
                </a>
            ) :
            (
                <Link key={cardData[key].id} className={`text-decoration-none w-100 ${css} px-0`} to={cardData[key].link}>
                    { cardBody }
                </Link>
            )
        )
    }

    socialMediaCards = (css) => {
        let channel = this.props.getSession().channel;
        channel = channel.toLowerCase();
        let onlineSI = createOnlineSILink(channel);

        const cardData = {
            'Facebook': {
                'id': 1,
                'bg': {
                    'color': '#89679C',
                    'img': 'big-fb.png'
                },
                'value': '',
                'link': 'https://www.facebook.com/allianzpnblifeofficial/'
            },
            'Twitter': {
                'id': 2,
                'bg': {
                    'color': '#F37449',
                    'img': 'big-tw.png'
                },
                'value': 3000,
                'link': 'https://twitter.com/azphofficial'
            },
            'Instagram': {
                'id': 3,
                'bg': {
                    'color': '#F37449',
                    'img': 'big-ig.png'
                },
                'value': 3000,
                'link': 'https://www.instagram.com/allianzpnblifeofficial/'
            },
            'Youtube': {
                'id': 4,
                'bg': {
                    'color': '#007D8B',
                    'img': 'big-yt.png'
                },
                'value': 3000,
                'link': 'https://www.youtube.com/channel/UCeunCC9Crnn8aVTDfB1C8fA'
            },
        };
        let cards = [];
        for (let key in cardData) {
            if (cardData.hasOwnProperty(key)) {
                cards.push(
                    <a key={cardData[key].id} target="_blank" href={cardData[key].link}>
                        <img className="card-bg-img-icons" src={require(`../assets/icons/${cardData[key].bg.img}`)} />
                    </a>
                );
            }
        }
        return (
            <div className="dashboard-cards row">
                {cards}
            </div>
        );
    }
    render() {
        if (this.state.isLoading) {
            return (
                <Loader handleClose={this.hideModal} show={this.state.isLoading} />
            );
        } else {
            return (
                <div className="dashboard-section">
                    <div className="dashboard-container container-fluid p-3">
                        <div className="dashboard-header">
                            <h6 className="font-neo-bold text-darkgray">Other Links</h6>
                            {this.props.getViewAs}
                        </div>
                        <div className="dashboard-body mt-4">
                            {this.dataCards("col-12 col-md-6 col-lg-4 col-xl-3")}
                        </div>
                    </div>
                    <div className="dashboard-container container-fluid p-3">
                        <div className="dashboard-header">
                            <h6 className="font-neo-bold text-darkgray">Follow us</h6>
                        </div>
                        <div className="dashboard-body mt-4">
                            {this.socialMediaCards("col-12 col-md-6 col-lg-4 col-xl-3")}
                        </div>
                    </div>
                </div>
            );
        }
    }
}
export default withRouter(OtherLinks);
