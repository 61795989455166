import React, { Fragment, Component } from "react";
import ReinstatementModal from "./reinstatementModal";

class Reinstatement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      transactionId: "0",
      columns: ["Reinstatement Option", "Status", "Reinstatement Amount", "Premium Deposit", "Requirements"],
    }
  }

  claimsColumns = () => this.state.columns.map((item, index) => <th key={index}>{item}</th>);
  claimsRows = () => this.props.reinstatements.map((item, index) =>
    <tr key={index}>
      <td>{item.option ? item.option : '--'}</td>
      <td>{item.status ? item.status : '--'}</td>
      <td>{item.rsmAmt ? item.rsmAmt : '--'}</td>
      <td>{item.ablAmt ? item.ablAmt : '--'}</td>
      <td><button type="button" data-toggle="modal" data-target={`#${this.props.view}reinstatementModal`} className="btn btn-dropdowncolor reinstatement-requirements__button font-neo-semibold policy-info__button" onClick={() => this.setState({ ...this.state, transactionId: item.posNum })}>View</button></td>
    </tr>
  );

  conditionalRender = () => {
    if (this.props.insuranceType === 'health') {
      return (
        <span className="dashboard-row-data font-neo-semibold center-align">Reinstatement Not Yet Available</span>
      )
    } else if (this.props.reinstatements === null || this.props.reinstatements.length === 0) {
      return (
        <span className="dashboard-row-data font-neo-semibold center-align">No information to be displayed</span>
      )
    } else {
      return (
        <Fragment>
          <table className="table coverage-table-row coverage-table-bordered coverage-table-striped coverage-table-header table-borderless mb-0">
            <thead>
              <tr>
                {this.claimsColumns()}
              </tr>
            </thead>
            <tbody>
              {this.claimsRows()}
            </tbody>
          </table>
          <ReinstatementModal transactionId={this.state.transactionId} modalData={this.props.modalData} view={this.props.view}></ReinstatementModal>
        </Fragment>
      );
    }
  }

  render = () => (
    <div className={this.props.css}>
      <div className="policy__table-container">
        {this.conditionalRender()}
      </div>
    </div>
  )
}

export default Reinstatement;
