import React from 'react';
import * as DOMPurify from 'dompurify';
import { myConfig } from '../../commons/config.js';


const LoginModal = (props) => {
    let { close } = props;

    const text = (
        <>
            <p className='login-modal__paragraph'>We would like to announce that the old link to our website will be decommissioned in October 2024. 
            To ensure seamless access to our services, please update your records and begin using the new link, 
            which will be available for all access and updates starting from that date.</p><br/>

            Old Link: <a href={myConfig.SITE_URL} target="_blank">{myConfig.SITE_URL}</a> <br/>
            New Link: <a href={myConfig.NDBX_URL} target="_blank">{myConfig.NDBX_URL}</a> <br/><br/>

            <p className='login-modal__paragraph'>We appreciate your cooperation and understanding during this transition. If you have any questions
            or need further assistance, please do not hesitate to contact us.</p><br/>

            For Customer Related Concern: <a href='mailto:customercare@allianzpnblife.ph'>customercare@allianzpnblife.ph</a><br/>
            For Assist Related Concern: <a href='mailto:it.helpdesk@allianzpnblife.ph'>it.helpdesk@allianzpnblife.ph</a>
        </>
    );
    return (
        <div className="policy-dashboard__prompt-overlay policy-dashboard__prompt-overlay--fix-position login-modal">
            <article className="login-modal__container">
                <article className="login-modal__content">
                    <img className="login-modal__close" src='../img/icons/Close.svg' onClick={() => close()}></img>
                    <article className="login-modal__text-container d-flex flex-column">
                        <section className="login-modal__text-content">
                            {text}
                        </section>
                    </article>
                </article>
            </article>
        </div>
    )
}

export default LoginModal;