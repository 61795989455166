import React, { Component } from "react";
import moment from "moment";
import NavLink from "react-router-dom/NavLink";
import { checkExpirationToDate } from "../commons/service";

export default class contestListItem extends Component {
  constructor(props) {
    super(props);
    console.log(this.props)
    this.state = {
      data: this.props.data
        ? this.props.data
        : {
            header: "",
            description: "",
            expiration: "",
            joinedCount: 0,
            background: "white",
            color: "black",
            image: "",
            contestId: null
          },
      alternate: this.props.alternate ? this.props.alternate : false
    };
  }

  componentWillReceiveProps(props) {
    if (props.data) {
      this.setState({
        data: props.data
      });
    }
  }

  render() {
    const { data } = this.state;
    var expiration = this.state.data
      ? this.state.data.expiration
        ? this.state.data.expiration
        : 0
      : 0;
    expiration = moment(expiration * 1000);
    expiration = expiration.format("MMMM DD, YYYY");
    if (expiration === "Invalid date") {
      expiration = null;
    }

    let prefixExpiration = checkExpirationToDate(expiration);

    const stringExpiration = prefixExpiration
      ? "Ended last " + expiration
      : "Ends in " + expiration;

    return (
      <div className="rni-row">
      <NavLink
        style={{ textDecoration: "none" }}
        to={
          "/rewards-and-incentives/contests?contestDetails=" +
          this.state.data.contestId
        }
      >
        <div>
          {this.state.alternate === false ? (
            <div
              style={{ background: this.state.data.background, height:'100%' }}
              className="rni-contest-list-item"
            >
              <div
                style={{ background: "url(" + this.state.data.image + ")" }}
                className="rni-contest-list-item-image"
              ></div>
              <div className="rni-contest-list-item-labels">
                <h3>{this.state.data.header}</h3>
                <p className="rni-margin-top-bot-12">
                  {this.state.data.description}
                </p>
                <div>
                  <p>{stringExpiration}</p>
                  <p>
                    <img style={{ width: "20px", height: "20px", display:"inline-block" }} src="/rni/users-solid.svg"/> &nbsp;{data.joinedCount}&nbsp;qualified
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <div
              style={{ background: this.state.data.background }}
              className="rni-contest-list-item-alternate"
            >
              <div className="rni-contest-list-item-labels">
                <h3>{this.state.data.header}</h3>
                <p className="rni-margin-top-bot-12">
                  {this.state.data.description}
                </p>
                <div>
                  <p>{stringExpiration}</p>
                  <p>
                    <img style={{ width: "20px", height: "20px", display: "inline-block" }} src="/rni/users-solid.svg"/>&nbsp;{data.joinedCount}&nbsp;qualified
                  </p>
                </div>
              </div>
              <div
                style={{ background: "url(" + this.state.data.image + ")" }}
                className="rni-contest-list-item-image-alternate"
              ></div>
            </div>
          )}
        </div>
      </NavLink></div>
    );
  }
}
