import React, { Component } from 'react';
import { Loader } from '../commons/utilityViews.js';
import { withRouter, Link } from 'react-router-dom';
import Auxi from '../components/auxi';
import { myConfig } from '../commons/config.js';
import { appendFileType, removeNullProfilePic, urlBase64ToUint8Array, jsonToURI, prettifyDate, checkCharacters, calculateAge, numberWithCommas, writeData, readAllData, decrypt, encrypt } from '../commons/utility.js';
import { apiModule } from '../commons/apiCall';
import { isInternalUser, checkPermissions, getSessionInfo } from '../commons/security';
import  Modal from '../components/UserInfo/modal';

import LoaderGif from '../commons/loaderGif';

class UserInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: '',
            message: '',
            isLoading: false,
            isUpdate: false,
            okForUpdating: true,
            userProfile: {
                agreementValidity: {}
            },
            workAddress: {
                validityInterval: {}
            },
            homeAddress: {
                validityInterval: {}
            },
            userObject: {
                nickname: '',
                image: '',

                // Contact Info Params
                countryCode: '',
                area: '',
                contact: '',
                email: '',

                // Address Params
                lot: '',
                street: '',
                barangay: '',
                city: '',
                state: '',
                country: '',
                zip: '',
            },
            // hierarchy: [],
            hierarchy: null,
            imageFiles: [],
            allowPush: false,
            isInternal: false,

            // use hierarchy tree
            useTree: false, //true or false,

            originalNickname: "",
            isMISOnly: false,

            // OTP Modal States
            isOTPOpen: false,
            isModalLoading: true,
            isSuccessfulUpdating: false,
            code: '',

            // Contact Basis
            contactBasis: {
                countryCode: '',
                area: '',
                contact: '',
            },

            // New Input States
            inputs: {
                countryCode: '',
                area: '',
                contact: '',
                lot: '',
                street: '',
                barangay: '',
                city: '',
                state: '',
                country: '',
                zip: '',
            },
        };
    }

    async componentDidMount() {
        let that = this;
        let session = this.props.getSession();
        let permissionList = await getSessionInfo();

        let enableNotificationsButton = document.querySelectorAll('.enable-notifications');
        let isInternal = isInternalUser(this.props.getSession().channel);
        let isMISOnly = await checkPermissions(["VIEW_MIS"], permissionList[0].permissions) && permissionList[0].permissions.length === 1;

        this.setState({
            isInternal,
            isMISOnly
        });
        if ('Notification' in window) {
            for (var i = 0; i < enableNotificationsButton.length; i++) {
                enableNotificationsButton[i].style.display = 'inline-block';
                that.checkIfAllowedPush();
            }
        } else {
            //do nothing
        }
        if (session.access_token === undefined || session.access_token === null || session.access_token === "") {
            readAllData('session')
                .then(function (data) {
                    for (var dt of data) {
                        that.props.setUserSession(dt);
                        that.fetchUserDetails();
                    }
                });
        } else if (session.username === undefined || session.username === null || session.username === "") {

            readAllData('session')
                .then(function (data) {
                    for (var dt of data) {
                        that.props.setUserSession(dt);
                        that.fetchUserDetails();
                    }
                });
        } else {
            that.fetchUserDetails();
        }
    }

    refreshUserSession = (sessionObj) => {
        this.props.setUserSession(sessionObj);
    }

    errorMessage = (message) => {
        if (message) {
            this.setState({ error: message, message: '', okForUpdating: false })
        }
    }
    successMessage = (message) => {
        if (message) {
            this.setState({ message: message, error: '' })
        }
    }
    errorReset = () => {
        this.setState({ error: "", message: "", okForUpdating: true })

    }
    setUpdateProfile = () => {
        this.setState({
            userObject: {
                nickname: this.state.originalNickname,
                image: this.state.userObject.image
            },
            isUpdate: !this.state.isUpdate
        });
    }

    isOfLength = (string, lengthBasis) => {
        return (string.length == lengthBasis);
    }

    doesStartWith = (string, startBasis) => {
        return string.startsWith(startBasis);
    }

    sendEmail = () => {
        let access_token = this.props.getSession().access_token;
        let username = decrypt(this.props.getSession().username);

        let inputs =  this.state.inputs;

        console.log('INPUTS');
        console.log(inputs);

        let message = "Profile Update Requested for the ff \n\n";
        message += ("Country Code: " + (inputs.countryCode? inputs.countryCode: '--') + "\n");
        message += ("Area Code: " + (inputs.area? inputs.area: '--') + "\n");
        message += ("Contact Number: " + (inputs.contact? inputs.contact: '--') + "\n");
        message += ("Company/Building: " + (inputs.company? inputs.company: '--') + "\n");
        message += ("Lot: " + (inputs.lot? inputs.lot: '--') + "\n");
        message += ("Street: " + (inputs.street? inputs.street: '--') + "\n");
        message += ("Barangay: " + (inputs.barangay? inputs.barangay: '--') + "\n");
        message += ("City: " + (inputs.city? inputs.city: '--') + "\n");
        message += ("State: " + (inputs.state? inputs.state: '--') + "\n");
        message += ("Country: " + (inputs.country? inputs.country: '--') + "\n");
        message += ("Zip Code: " + (inputs.zip? inputs.zip: '--') + "\n");

        let that = this;

        let headerParams = {
            accountManagerId: username,
            x_auth: access_token
        };

        let toEmails = [ "distribution.licensing@allianzpnblife.ph" ];
        let payloadParams =
        {
            "from": username,
            "to": toEmails,
            "message": message,
            "subject": "Profile Update Request",
        };


        apiModule("send_email", headerParams, payloadParams, '', true)
            .then(function (result) {
                console.log('EMAIL SENT');
                that.setState({
                    message: "Profile Update Request Sent"
                });
            })
            .catch(function (err) {
                console.err('EMAIL SENDING FAILED');
                console.log(err);
                that.errorMessage(myConfig.GENERIC_ERROR_MESSAGE);
            });
    }

    handleProfileUpdate = () => {
        let that = this;
        let inputContact = that.state.inputs.contact;
        let basisContact = that.state.contactBasis.contact;
        let inputCountyCode = that.state.inputs.countryCode;
        let basisCountryCode = that.state.contactBasis.countryCode;

        const maxContactLength = 10;
        const contactPrefix = '9';

        if (inputContact !== basisContact || inputCountyCode !== basisCountryCode) {
            if(!/^[0-9]*$/.test(inputContact) || !/^[0-9]*$/.test(inputCountyCode)) {
                that.errorMessage('Contact Number and Country Code must be numeric');
                that.setState({ okForUpdating: true });
            } else if(!this.isOfLength(inputContact, maxContactLength)) {
                that.errorMessage('Contact Number must have a length of ' + maxContactLength + ' digits');
                that.setState({ okForUpdating: true });
            } else if(!this.doesStartWith(inputContact, contactPrefix)) {
                that.errorMessage('Contact Number must start with \"' + contactPrefix + '\"');
                that.setState({ okForUpdating: true });
            } else {
                that.handleShowOTP();
                that.errorReset();
            }
        } else {
            that.updateProfileHandler();
        }
    }

    updateProfileHandler = () => {
        let that = this;
        let continueApiCall = true;
        if (this.state.imageFiles != null) {
            let that = this;
            let headerParams = {
                accountManagerId: decrypt(that.props.getSession().username),
                x_auth: that.props.getSession().access_token
            };

            let updatedImage = null;

            if (this.state.imageFiles.base64 != null) {
                updatedImage = this.state.imageFiles.base64.split(/,(.+)/)[1];
            }

            let payloadParams = {
                alias: this.state.userObject.nickname,
                image: updatedImage,

                // Contact Info Params
                countryCode: this.state.userObject.country,
                area: this.state.userObject.area,
                contact: this.state.userObject.contact,
                email: this.state.userObject.email,

                // Address Params
                lot: this.state.userObject.lot,
                street: this.state.userObject.street,
                barangay: this.state.userObject.barangay,
                city: this.state.userObject.city,
                state: this.state.userObject.state,
                country: this.state.userObject.country,
                zip: this.state.userObject.zip,
            };

            if (this.state.userObject === "" || this.state.userObject === null || this.state.userObject.nickname === null || this.state.userObject.nickname === undefined) {
                // COMMENTED OUT CAUSE THIS MESSES WITH THE FLOW
                // that.errorMessage("Nickname cannot contain special characters");
                continueApiCall = false;

            } else {
                that.errorReset();
            }

            console.log("PAYLOAD PARAMETERS");
            console.log(payloadParams);

            if (continueApiCall) {
                apiModule("update_webuser", headerParams, payloadParams, '', true)
                    .then(function (result) {

                        let alias = '';
                        let image = '';

                        if (alias != null) {
                            alias = result.alias;
                        }

                        that.sendEmail();

                        that.setState({
                            loadTable: false,
                            isUpdate: false,
                            originalNickname: alias,
                            message: 'Successfully updated your profile'
                        });

                        that.setState({
                            isSuccessfulUpdating: true,
                        });
                        that.handleShowOTP();

                        let newSessionObject = that.props.getSession();

                        if (that.state.imageFiles.length == 0 || that.state.imageFiles.base64 === null) {
                            newSessionObject.image = that.state.userObject.image;
                        } else {
                            newSessionObject.image = that.state.imageFiles.base64;
                        }

                        newSessionObject.alias = that.state.userObject.nickname;

                        // PREVENT REFRESH OF PAGE
                        // that.props.setUserSession(newSessionObject);
                        // that.refreshUserSession(newSessionObject);

                    })
                    .catch(function (err) {
                        console.log(err);
                        that.setState({
                            loadTable: false,
                            isLoading: false,

                        });
                        that.errorMessage(myConfig.GENERIC_ERROR_MESSAGE);
                        //   resolve("Ok");

                    });
            }
        }
    }

    getFiles = (e) => {

        // get the files
        let files = e.target.files;

        // Process each file
        var allFiles = [];
        for (var i = 0; i < files.length; i++) {

            let file = files[i];

            // Make new FileReader
            let reader = new FileReader();

            // Convert the file to base64 text
            reader.readAsDataURL(file);

            // on reader load somthing...
            reader.onload = () => {

                // Make a fileInfo Object
                let fileInfo = {
                    name: file.name,
                    type: file.type,
                    size: Math.round(file.size / 1000) + ' kB',
                    base64: reader.result,
                    file: file,
                };

                // Push it to the state
                allFiles.push(fileInfo);

                // If all files have been proceed

                if (allFiles.length == files.length) {
                    // Apply Callback function
                    this.setState({
                        imageFiles: allFiles[0]
                    }, () => {

                    });
                }

            }
        }
    }

    handleShowOTP = () => {
        this.sendOTP();
        this.setState({
            isOTPOpen: true,
        });
    }

    handleCloseOTP = () => {
        this.setState({
            isOTPOpen: false,
            isSuccessfulUpdating: false,
            isUpdate: false,
        });
        this.refreshPage();
    }

    updateSuccessHandler = () => {
        this.setState({
            isSuccessfulUpdating: true,
        });
    }

    sendOTP = () => {
        let that = this;

        let headerParams = {
            x_auth: that.props.getSession().access_token,
        };

        let payloadParams = {
            "username": decrypt(that.props.getSession().username),
            "appId": 5,
        };

        if(!this.state.isSuccessfulUpdating) {
            console.log('PP');
            console.log(payloadParams);
            // JSON.stringify(payloadParams);

            console.log('PROPS');
            console.log(decrypt(that.props.getSession().username));

            apiModule("generate_otp", headerParams, payloadParams, '', true)
                .then(function (result) {
                    console.log('OTP SENT');
                    console.log(result.data);
                    that.setState({
                        code: result.data.code
                    });
                });
        }
    }

    fetchUserDetails = () => {
        let that = this;
        let headerParams = {
            accountManagerId: decrypt(this.props.getSession().agentNumber),
            x_auth: this.props.getSession().access_token,
            webUser: decrypt(that.props.getSession().username)
        };


        let payloadParams = {};

        apiModule("get_webuser", headerParams, null)
            .then(function (result) {
                let userProfile = result;
                let workAddress = {};
                let homeAddress = {};

                let alias = decrypt(that.props.getSession().name);
                let image = appendFileType(removeNullProfilePic(null));
                console.log("Default alias " + alias);
                if (result.alias != null && result.alias != undefined && result.alias != "" ) {
                    console.log("Fixed alias " + alias);

                    alias = result.alias;
                }

                if (result.image != null && result.image != undefined && result.image != "") {
                    image = appendFileType(result.image);
                }

                that.setState({
                    loadTable: false,
                    userObject: {
                        nickname: alias,
                        image: image
                    },
                    originalNickname: alias
                });
            })
            .catch(function (err) {
                console.log(err);
                that.setState({
                    loadTable: false,
                    isLoading: false,
                });
                that.errorMessage(myConfig.GENERIC_ERROR_MESSAGE);
                //   resolve("Ok");

            });

        console.log("is Internal User " + this.state.isInternal);
        if (isInternalUser(this.props.getSession().channel)) {

            let userProfile = [];
            let workAddress = {};
            let homeAddress = {};

            // firstName: this.state.userProfile.firstName,
            // lastName: this.state.userProfile.name,
            // email: this.state.userProfile.email,
            // branch: this.state.userProfile.branchList,

            that.setState({
                loadTable: false,
                isLoading: false,
                hierarchy: [],
                userProfile,
                workAddress,
                homeAddress,
                useTree: true
            });
            // console.log('RESULT');
            // console.log(result);
        } else {
            apiModule("get_accountmanager", headerParams, null)
                .then(function (result) {
                    let userProfile = result;
                    let workAddress = {};
                    let homeAddress = {};
                    let identityDocuments = [];

                    workAddress = userProfile.address;
                    identityDocuments = userProfile.identityDocuments;

                    console.log('RESULT MARKER');
                    console.log(result);
                    console.log(userProfile.phoneNumber);

                    that.setState({
                        identityDocuments: [
                            ...identityDocuments
                        ],

                        inputs: {
                            lot: workAddress.floorNumber ? workAddress.floorNumber : '',
                            street: workAddress.street ? workAddress.street : '',
                            barangay: workAddress.district ? workAddress.district : '',
                            city: workAddress.city ? workAddress.city : '',
                            state: workAddress.state ? workAddress.state : '',
                            country: workAddress.country ? workAddress.country : '',
                            zip: workAddress.zipCode ? workAddress.zipCode : '',
                            countryCode: userProfile.countryPrefix ? userProfile.countryPrefix : '',
                            area: userProfile.prefix ? userProfile.prefix : '',
                            contact: userProfile.phoneNumber ? userProfile.phoneNumber : '',
                        },
                        contactBasis: {
                            countryCode: userProfile.countryPrefix ? userProfile.countryPrefix : '',
                            area: userProfile.prefix ? userProfile.prefix : '',
                            contact: userProfile.phoneNumber ? userProfile.phoneNumber : '',
                        }
                    });

                    console.log('STATE MARKER');
                    console.log(that.state.inputs.contact);
                    console.log(userProfile.phoneNumber);
                    // console.log(that.state.contactBasis);
                    console.log(that.state.inputs);

                    apiModule("get_accountmanager_hierarchy", headerParams, null)
                        .then(function (result) {
                            let hierarchy = [];
                            let byBoss = [];
                            let fixedArrangement = [];
                            if (that.props.isMgr) {

                                hierarchy = result.content;
                                that.setState({
                                    loadTable: false,
                                    hierarchy,
                                    useTree: true
                                });
                            } else {

                                let tempH = result.content;

                                tempH.sort(function (a, b) {
                                    return a.level == b.level ? 0 : +(a.level < b.level) || -1;
                                });

                                let currentLevel = 100;
                                let currentBoss = "";
                                let currentArray = [];
                                let byBossArray = [];
                                for (let i = 0; i < tempH.length; i++) {
                                    let user = tempH[i];
                                    let user_level = parseInt(user.level);
                                    let boss = user.agentNumberOfAdvisorOfAccountManager;
                                    console.log("iterating on .." + i);

                                    if (i !== 0 && currentLevel != user_level) {
                                        currentLevel = parseInt(user.level);
                                        console.log("adding level .." + currentLevel);
                                        hierarchy.push(currentArray);
                                        currentArray = [];
                                    }

                                    if (i !== 0 && currentBoss != boss) {
                                        currentBoss = boss;
                                        byBoss.push(byBossArray);
                                        byBossArray = [];
                                    }

                                    byBossArray.push({
                                        agentNumber: user.agentNumber,
                                        agentNumberOfAdvisorOfAccountManager: user.agentNumberOfAdvisorOfAccountManager,
                                        email: user.email,
                                        image: user.image,
                                        level: user.level,
                                        name: user.name,
                                        phoneNumber: user.phoneNumber,
                                        roleNameOfAccountManager: user.roleNameOfAccountManager,
                                        childern: [],
                                    });

                                    console.log("user: adding level .." + user.level);
                                    currentArray.push({
                                        agentNumber: user.agentNumber,
                                        agentNumberOfAdvisorOfAccountManager: user.agentNumberOfAdvisorOfAccountManager,
                                        email: user.email,
                                        image: user.image,
                                        level: user.level,
                                        name: user.name,
                                        phoneNumber: user.phoneNumber,
                                        roleNameOfAccountManager: user.roleNameOfAccountManager,
                                        childern: [],
                                    });

                                    if (i === tempH.length - 1) {
                                        console.log("adding level .." + currentLevel + "   ... tempH.length -1");
                                        hierarchy.push(currentArray);
                                    }

                                    if (i === tempH.length - 1) {
                                        byBoss.push(byBossArray);
                                    }



                                }
                                console.log("positives..");
                                for (let i = 0; i < hierarchy.length; i++) {
                                    if (parseInt(hierarchy[i][0].level) >= 0) {
                                        console.log(hierarchy[i]);
                                        fixedArrangement.push(hierarchy[i]);
                                    } else {
                                        break;
                                    }
                                }
                                console.log("negatives..");
                                for (let i = hierarchy.length - 1; i > 0; i--) {
                                    if (parseInt(hierarchy[i][0].level) < 0) {
                                        console.log(hierarchy[i]);
                                        fixedArrangement.push(hierarchy[i]);
                                    } else {

                                    }
                                }

                                console.log(fixedArrangement);

                                that.setState({
                                    loadTable: false,
                                    isLoading: false,
                                    hierarchy: fixedArrangement,
                                    userProfile,
                                    workAddress,
                                    homeAddress,
                                    useTree: true
                                });
                            }
                        })
                        .catch(function (err) {
                            console.log(err);
                            that.setState({
                                loadTable: false,
                                isLoading: false,
                                userProfile,
                                workAddress,
                                homeAddress,
                                error: myConfig.GENERIC_ERROR_MESSAGE
                            });
                        });
                })
                .catch(function (err) {
                    console.log(err);
                    that.setState({
                        loadTable: false,
                        isLoading: false,

                    });
                    that.errorMessage(myConfig.GENERIC_ERROR_MESSAGE);
                    //   resolve("Ok");

                });

        }
    }

    userMainInfo = () => {
        let data = {
            // nickName: 'sheilazaro_12',
            firstName: this.state.userProfile.firstName,
            lastName: this.state.userProfile.name,
            email: decrypt(this.props.getSession().username),
            branch: this.state.userProfile.branchList,
        };

        const customName = {
            // nickName: 'Nickname',
            firstName: 'First Name',
            lastName: 'Last Name',
            email: 'Email Address',
            branch: 'Branch Assigned'
        };

        let mainInfo = [];
        for (let key in data) {
            if (data[key]) {
                if (key === "branch") {
                    let branchList = data[key].map(function (item, index) {
                        return (
                            <li>{item}</li>
                        );
                    })
                    mainInfo.push(
                        <div className="user-main__info mb-4">
                            <label className="main-info__label">{customName[key]}</label>
                            <h6 className="main-info__value font-neo-semibold text-dropdowncolor d-flex justify-content-center justify-content-md-start"><ul className="main-info__branches">{branchList}</ul></h6>
                        </div>
                    );
                } else {
                    mainInfo.push(
                        <div className="user-main__info mb-4">
                            <label className="main-info__label">{customName[key]}</label>
                            <h6 className="main-info__value font-neo-semibold text-dropdowncolor">{data[key]}</h6>
                        </div>
                    );
                }
            } else {
                mainInfo.push(
                    <div className="user-main__info mb-4">
                        <label className="main-info__label">{customName[key]}</label>
                        <h6 className="main-info__value font-neo-semibold text-dropdowncolor">--</h6>
                    </div>
                );
            }

        }
        return (
            <Auxi>
                <div className="user-main__info mb-4">
                    <label className="main-info__label">Nickname</label>
                    {this.editNickName()}
                </div>
                {mainInfo}
            </Auxi>
        );

    }

    basicInfo = () => {
        const header = "Basic Information";
        let data = {
            designation: "--",
            first: this.state.userProfile.firstName,
            middle: this.state.userProfile.middleName,
            last: this.state.userProfile.name,
            dob: prettifyDate(this.state.userProfile.birthDate),
            gender: this.state.userProfile.accountManagerGender,
            nationality: this.state.userProfile.nationality,
        };

        const customName = {
            designation: 'Designation',
            first: 'First Name',
            middle: 'Middle Name',
            last: 'Last Name',
            dob: 'Date of Birth',
            gender: "Gender",
            nationality: "Nationality"
        };

        let info = [];
        for (let key in data) {
            info.push(
                <tr>
                    <td>{customName[key]}</td>
                    <td>{data[key] ? data[key] : '--'}</td>
                </tr>
            );
        }
        if (this.state.isInternal) {
            return (
                <div>
                </div>
            );
        } else {
            return (
                <div className="user-details__group">
                    <h6 className="details-group__header px-4 py-2">{header}</h6>
                    <table className="details-group__table table table-sm table-borderless">
                        {info}
                    </table>
                </div>
            );
        }

    }

    contactInfo = () => {
        const header = "Contact Information";
        let session = this.props.getSession();

        let data = {
            countryCode: this.state.userProfile.countryPrefix,
            area: this.state.userProfile.prefix,
            contact: this.state.userProfile.phoneNumber,
            email: decrypt(session.username),
        };

        const customName = {
            countryCode: 'Country Code',
            area: "Area Code",
            contact: "Contact No.",
            email: "Email Address"
        };

        const nonEditables = [
            "email",
        ];

        const numericOnly = [
            'countryCode',
            'contact',
        ];

        let info = [];
        for (let key in data) {
            // if (data[key]) {
                if(this.state.isUpdate && !nonEditables.includes(key)) {
                    console.log('DATA');
                    console.log(customName);
                    console.log(key);
                    console.log(data);
                    info.push(
                        <tr>
                            <td>{customName[key]}</td>
                            <input className="info-value__input info-value__input--edit text-center text-md-left text-dropdowncolor" type={numericOnly.includes(key)? 'number': 'text'} name={key} onChange={(e) => this.handleInputChanges(e)} value={this.state.inputs[key]} />
                        </tr>
                    );
                } else {
                    info.push(
                        <tr>
                            <td>{customName[key]}</td>
                            <td>{data[key] ? data[key] : '--'}</td>
                        </tr>
                    );
                }
            // }
        }

        if (this.state.isInternal) {
            return (
                <div>
                </div>
            );
        } else {
            return (
                <div className="user-details__group">
                    <h6 className="details-group__header px-4 py-2">{header}</h6>
                    <table className="details-group__table table table-sm table-borderless">
                        {info}
                    </table>
                </div>
            );
        }
    }

    address = () => {
        const header = "Address";
        let workAddress = this.state.workAddress;
        let data = {};
        if (workAddress !== null && workAddress !== undefined) {
            if (workAddress.validityInterval !== null && workAddress.validityInterval !== undefined) {
                data = {
                    company: workAddress.buildingName,
                    lot: workAddress.floorNumber,
                    street: workAddress.street,
                    barangay: workAddress.district,
                    city: workAddress.city,
                    state: workAddress.state,
                    country: workAddress.country,
                    zip: workAddress.zipCode,
                };
            } else {
                data = {
                    company: workAddress.buildingName,
                    lot: workAddress.floorNumber,
                    street: workAddress.street,
                    barangay: workAddress.district,
                    city: workAddress.city,
                    state: workAddress.state,
                    country: workAddress.country,
                    zip: workAddress.zipCode,
                };
            }
        }
        const customName = {
            company: 'Company/Building Name',
            lot: "Lot/Block No.",
            street: "Street No./Street",
            barangay: "Barangay/Subdivision/Village",
            city: "City/Municipality",
            state: "Province/State",
            country: "Country",
            zip: "Zip Code",
        };

        console.log('ADDRESS');
        console.log(this.state.userObject);

        let info = [];
        for (let key in data) {
            // if (data[key]) {
                if(this.state.isUpdate) {
                    info.push(
                        <tr>
                            <td>{customName[key]}</td>
                            <input className="info-value__input info-value__input--edit text-center text-md-left text-dropdowncolor" type="text" name={key} onChange={(e) => this.handleInputChanges(e)} value={this.state.inputs[key] ? this.state.inputs[key] : ''} />
                        </tr>
                    );
                } else {
                    info.push(
                        <tr>
                            <td>{customName[key]}</td>
                            <td>{data[key] ? data[key] : '--'}</td>
                        </tr>
                    );
                }
            // }
        }

        if (this.state.isInternal) {
            return (
                <div>
                </div>
            );
        } else {
            return (
                <div className="user-details__group">
                    <h6 className="details-group__header px-4 py-2">{header}</h6>
                    <table className="details-group__table table table-sm table-borderless">
                        {info}
                    </table>
                </div>
            );
        }
    }

    intermediary = () => {
        const header = "Intermediary Details";
        let data = {
            num: this.state.userProfile.agentNumber,
            type: this.state.userProfile.roleNameOfAccountManager,
            status: this.state.userProfile.employmentStatus,
            date: prettifyDate(this.state.userProfile.employmentDate),
            office: this.state.userProfile.branchList,
        };

        const customName = {
            num: "Intermediary No.",
            type: "Intermediary Type",
            status: "Status",
            date: "Effectivity Date",
            office: "Service Office",
        };

        let info = [];
        for (let key in data) {
                if (key === "office") {
                    if(data[key]) {
                        let branchList = data[key].map(function (item, index) {
                            return (
                                <li>{item}</li>
                            );
                        })
                        info.push(
                            <tr>
                                <td>{customName[key]}</td>
                                <td>{data[key] ? branchList : '--'}</td>
                            </tr>
                        );
                    } else {
                        info.push(
                            <tr>
                                <td>{customName[key]}</td>
                                <td>--</td>
                            </tr>
                        );
                    }
                } else {
                    info.push(
                        <tr>
                            <td>{customName[key]}</td>
                            <td>{data[key] ? data[key] : '--'}</td>
                        </tr>
                    );
                }
        }

        if (this.state.isInternal) {
            return (
                <div>
                </div>
            );
        } else {
            return (
                <div className="user-details__group">
                    <h6 className="details-group__header px-4 py-2">{header}</h6>
                    <table className="details-group__table table table-sm table-borderless">
                        {info}
                    </table>
                </div>
            );
        }
    }
    showError = () => {
        let message = '';
        if (this.state.error !== '') {
            message =
                <div className="bg-error py-1 px-4">
                    <p className="text-white mb-0 font-neo-semibold">ERROR: {this.state.error}</p>
                </div>
        }
        else if (this.state.message !== '') {
            message =
                <div className="bg-success py-1 px-4">
                    <p className="text-white mb-0 font-neo-semibold">{this.state.message}</p>
                </div>
        }

        return message;
    }
    license = () => {
        const header = "License Details";
        let data = [];
        console.log("TEST", this.state.identityDocuments)
        if (this.state.identityDocuments && this.state.identityDocuments.length > 0) {
            const identityDocuments = this.state.identityDocuments
            if (identityDocuments && identityDocuments.length > 0) {
                data = identityDocuments.map(document => {
                    let interval = {
                        effectiveDate: "",
                        expirationDate: "",
                    }
                    if(document.validityInterval && Object.keys(document.validityInterval).length > 0){
                        interval.effectiveDate = document.validityInterval.startDateTime;
                        interval.expirationDate = document.validityInterval.endDateTime;
                    }
                    return {
                        category: document.category || "--",
                        number: document.number || "--",
                        status: document.status || "--",
                        effectiveDate: interval.effectiveDate ? prettifyDate(interval.effectiveDate) : "--",
                        expirationDate: interval.expirationDate ? prettifyDate(interval.expirationDate) : "--",
                    }
                });
            }
        } else {
            data = [
                {
                    number: "--",
                    category: "--",
                    status: "--",
                    effectiveDate: "--",
                    expirationDate: "--"
                }
            ]
        }

        const customName = {
            category: "Type",
            number: "Certificate of Authority No.",
            status: "CA Status",
            effectiveDate: "Issue Date",
            expirationDate: "Expiry Date"
        };

        let info = [];
        data.forEach((document, i) => {
            if (i > 0) {
                info.push(<br />);
            }
            Object.keys(document).forEach(documentKey => {
                info.push(
                    <tr>
                        <td>{customName[documentKey]}</td>
                        <td>{document[documentKey] ? document[documentKey] : '--'}</td>
                    </tr>
                );
            })
        })

        if (this.state.isInternal) {
            return (
                <div>
                </div>
            );
        } else {
            return (
                <div className="user-details__group">
                    <h6 className="details-group__header px-4 py-2">{header}</h6>
                    <table className="details-group__table table table-sm table-borderless">
                        {info}
                    </table>
                </div>
            );
        }
    }

    branch = () => {
        const header = "Other Branches";
        let data = {
            office: null,
            desc: this.state.userProfile.additionalInformation
        };

        const customName = {
            office: "Service Office",
            desc: "Description"
        };

        let info = [];
        for (let key in data) {
            info.push(
                <tr>
                    <td>{customName[key]}</td>
                    <td>{data[key] ? data[key] : '--'}</td>
                </tr>
            );
        }

        if (this.state.isInternal) {
            return (
                <div>
                </div>
            );
        } else {
            return (
                <div className="user-details__group">
                    <h6 className="details-group__header px-4 py-2">{header}</h6>
                    <table className="details-group__table table table-sm table-borderless">
                        {info}
                    </table>
                </div>
            );
        }
    }

    profilePicture = () => {
        let picture = this.state.imageFiles.base64;
        if (this.state.imageFiles.length == 0 || this.state.imageFiles.base64 === null) {
            picture = this.state.userObject.image;
        }
        else {
            picture = this.state.imageFiles.base64;
        }
        return <img className="info-picture" src={picture} alt={`User Image`} />;
    }
    /**********/

    displayConfirmNotification = () => {


        if ('serviceWorker' in navigator) {
            var options = {
                body: 'You successfully subscribed to our Notification service!',
                icon: 'newicons/icon-96x96.png',
                image: 'newicons/icon-96x96.png',
                lang: 'en-US', //BCP 47
                vibrate: [100, 50, 200], //vibration pause vibration pause..
                badge: 'newicons/icon-96x96.png',
                dir: 'ltr',
                tag: 'confirm-notification',
                renotify: true,
                actions: [
                    { action: 'confirm', title: 'Okay' },
                    { action: 'cancel', title: 'Cancel' }
                ]
            };
            navigator.serviceWorker.ready
                .then(function (swreg) {
                    swreg.showNotification('Successfully subscribed', options)
                });
        }
    }

    configurePushSub = () => {
        let that = this;
        console.log('configurePushSub ServiceWorker');
        if (!('serviceWorker' in navigator)) {
            console.log('No ServiceWorker');
            return;
        }

        if ('PushManager' in window) {
            console.log('We have a push manager');

            var reg;
            navigator.serviceWorker.ready
                .then(function (swreg) {
                    reg = swreg;
                    console.log('navigator.serviceWorker.ready');
                    console.log(swreg);
                    let pushManager = swreg.pushManager;
                    console.log(pushManager);

                    return pushManager.getSubscription();
                })
                .then(function (sub) {

                    return new Promise((resolve, reject) => {
                        if (sub === null) {
                            apiModule('user_get_vapid', {x_auth: ''}, {app_key : myConfig.APP_ID})
                            .then(response => {

                                if(response.result == 'success'){
                                    var vapidPublicKey = response.message;
                                    var convertedVapidPublicKey = urlBase64ToUint8Array(vapidPublicKey);
                                    console.log('Create a new subscription ' + vapidPublicKey);
                                    resolve(reg.pushManager.subscribe({
                                        userVisibleOnly: true,
                                        applicationServerKey: convertedVapidPublicKey
                                    }));
                                } else {
                                    resolve(sub);
                                }
                            });
                        } else {
                            // We have a subscription
                            console.log('We have a subscription');
                            console.log(sub);
                            return sub;
                        }
                    })
                })
                .then(function (newSub) {
                    console.log(newSub);
                    let username = decrypt(that.props.getSession().username);
                    let name = decrypt(that.props.getSession().name);

                    let webpushObject = {
                        'webpush': JSON.stringify(newSub),
                        'type': 'default_push_type',
                        'app_key': myConfig.APP_ID,
                        'user_id': username,
                        'username': username,
                        'email': username,
                    };

                    that.displayConfirmNotification();
                    console.log(newSub);
                    let request = { x_auth: that.props.getSession().jwt }
                    return apiModule('user_store_vapid', request, webpushObject);


                })
                .then(function (res) {
                    console.log(res);
                })
                .catch(function (err) {
                    console.log(err);
                });
        } else {


        }


    }

    callbackSidebar = (permissionData) => {
        let that = this;
        if (permissionData.permission === "granted") {

            let webpushObject = {
                'webpush': permissionData.deviceToken,
                'type': 'safari_push_type'
            };

            let data = jsonToURI(webpushObject);

            return apiModule('user_store_vapid', that.props.getSession().jwt, data);
        }
    }
    checkIfAllowedPush = () => {
        let that = this;

        if ('PushManager' in window) {
            if (Notification.permission !== "granted") {
                that.setState({ allowPush: false });
            } else {
                that.setState({ allowPush: true });
            }
        }
    }
    askForNotificationPermission = () => {
        let that = this;

        if ('PushManager' in window) {
            Notification.requestPermission(function (result) {
                console.log('User Choice', result);
                if (result !== 'granted') {
                    console.log('Not granted');
                } else {
                    that.setState({ allowPush: true });
                    that.configurePushSub();
                }
            });
        }
    }

    refreshPage = () =>  {
        window.location.reload();
    }
    editPicture = () => {
        let that = this;
        if (this.state.isUpdate) {
            return (

                <div className="user-info__picture">
                    {that.profilePicture()}
                    <input type="file" id="fileTest" accept="image/*"
                        onChange={(e) => { this.getFiles(e); }} />
                    <label for="fileTest"><div /></label>
                </div>
            );
        } else {
            return (<div className="user-info__picture">
                {that.profilePicture()}
            </div>
            );
        }
    }
    checkCheckbox(e) {
        let that = this;

        if (e.target.checked) {
            this.askForNotificationPermission();
        } else {
            try {
                if ('serviceWorker' in navigator) {
                    console.log(navigator.permissions);
                    navigator.permissions.revoke({ name: 'notifications' }).then(function (result) {
                        console.log(result);
                        this.setState({ allowPush: e.target.checked });
                    });

                }
            } catch (err) {
                console.log(err);
                that.errorMessage("Revoking notification permission is not supported by your browser");
            }

        }


    }
    handleChanges(e) {
        let userObject = this.state.userObject;
        let that = this;

        if (e.target.name === "nickname") {
            if (!checkCharacters(e.target.value)) {
                that.errorMessage("Nickname cannot contain special characters");
            } else {
                that.errorReset();
            }
        }
        console.log("TARGET");
        console.log(e.target.name + " " + e.target.value);
        userObject[e.target.name] = e.target.value;
        that.setState({ userObject });
    }

    handleInputChanges(e) {
        let inputs = this.state.inputs;
        let userObject = this.state.userObject;
        let that = this;

        console.log("TARGET");
        console.log(e.target.name + " : " + e.target.value);
        inputs[e.target.name] = e.target.value;
        userObject[e.target.name] = e.target.value;
        that.setState({ inputs });
        that.setState({ userObject });
        console.log('STATE');
        console.log(this.state.inputs);
    }

    saveButton = () => {
        let that = this;
        let initStyle = { height: "30px" }
        if (this.state.isUpdate && this.state.okForUpdating) {
            return (
                <div className="d-flex justify-content-center justify-content-md-end flex-grow-1 align-items-end">
                    <div style={initStyle}>
                        <button onClick={() => { this.handleProfileUpdate() }} className="btn btn-dropdowncolor py-1 px-4 font-p8rem font-neo-semibold">Save</button>
                        <button onClick={() => { this.setUpdateProfile() }} className="btn btn-danger py-1 px-4 font-p8rem font-neo-semibold ml-2">Cancel</button>
                    </div>

                </div>
            );
        } else {
            return (<div style={initStyle}></div>);
        }
    }

    editNickName = () => {
        let that = this;
        if (this.state.isUpdate) {
            return (<h6 className="main-info__value font-neo-semibold">
                <input className="info-value__input info-value__input--edit text-center text-md-left text-dropdowncolor" type="text" name="nickname" onChange={(e) => this.handleChanges(e)} value={this.state.userObject.nickname} />
            </h6>
            );
        } else {
            return (<h6 className="main-info__value font-neo-semibold">
                <input className="info-value__input text-center text-md-left text-dropdowncolor pes-none info-value__input--edit" style={{ borderBottomColor: "transparent" }} value={this.state.userObject.nickname} readonly />
            </h6>
            );
        }
    }
    render() {
        if (this.state.isLoading) {
            return (
                <Loader handleClose={this.hideModal} show={this.state.isLoading} />
            );
        } else {
            let { isMISOnly } = this.state;
            let name = decrypt(this.props.getSession().name);
            let role = this.props.getSession().role;

            let buttonsDiv = (<div></div>);


            if (this.state.isInternal) {
                buttonsDiv = (
                    <div className="user-info__actions mt-3">
                        {isMISOnly ? <Link className="btn btn-dropdowncolor py-1 info-action font-neo-semibold" to="/dashboard/mis">View MIS</Link> : ""}
                        <button className="btn btn-dropdowncolor py-1 info-action font-neo-semibold" onClick={() => this.setUpdateProfile()}>Update Profile</button>
                        <Link className="btn btn-dropdowncolor py-1 info-action font-neo-semibold" to="/dashboard/change-password">Change Password</Link>
                    </div>
                );
            } else {
                buttonsDiv = (
                    <div className="user-info__actions mt-3">
                        {isMISOnly ? <Link className="btn btn-dropdowncolor py-1 info-action font-neo-semibold" to="/dashboard/mis">View MIS</Link> : ""}
                        <button className="btn btn-dropdowncolor py-1 info-action font-neo-semibold" onClick={() => this.setUpdateProfile()}>Update Profile</button>
                        {/* <a className="btn btn-dropdowncolor py-1 info-action font-neo-semibold" href="/dashboard/change-password">Change Password</a> */}
                        <Link className="btn btn-dropdowncolor py-1 info-action font-neo-semibold" to="/dashboard/change-password">Change Password</Link>
                        {/* <button type="button" data-toggle="modal" data-target="#hierarchyModal" className="btn btn-dropdowncolor py-1 info-action font-neo-semibold" href="#">View Hierarchy</button> */}
                        {/* <Link className="btn btn-dropdowncolor py-1 info-action font-neo-semibold" to="/dashboard/hierarchy">View Hierarchy</Link> */}
                    </div>
                )
            }

            // console.log('SESSION');
            // console.log(props.session);

            return (
                <div className="dashboard-section">
                    <Modal
                        showModal = {this.state.isOTPOpen}
                        handleClose = {this.handleCloseOTP}
                        session = {this.props.getSession()}
                        isSuccessfulUpdating = {this.state.isSuccessfulUpdating}
                        updateSuccessHandler = {this.updateSuccessHandler}
                        sendEmail = {this.sendEmail}
                        code = {this.state.code}
                        sendOTP = {this.sendOTP}
                    />
                    {this.showError()}
                    <div className="dashboard-container container-fluid p-3">
                        <div className="dashboard-header">
                            <h6 className="font-neo-bold text-darkgray">User Profile</h6>
                            {this.props.getViewAs}
                        </div>
                        <div className="dashboard-body mt-4">
                            <div className="mt-3 bg-white p-4 shadow rounded">
                                <div className="row">
                                    <div className="col-md-6 text-center">
                                        <div className="user-info">
                                            {this.editPicture()}
                                            <div className="user-info__name mt-2">
                                                <h2 className="info-name font-neo-semibold mb-0">{name}</h2>
                                            </div>
                                            <div className="user-info__position">
                                                <h6 className="info-position text-headercolor">{role}</h6>
                                            </div>
                                            {buttonsDiv}
                                            <div className="user-info__enable-push mt-4 enable-notifications">
                                                <div className="info-enable-push">
                                                    {/* this.state.allowPush */}
                                                    <input onChange={(e) => this.checkCheckbox(e)} type="checkbox" checked={this.state.allowPush} name="toggle2" className="info-enable-push__input mobile-toggle" id="push-notif" />
                                                    <label className="info-enable-push__label" for="push-notif"></label>
                                                    <p className="info-enable-push__text ml-2">Allow Push Notifications</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="user-main">
                                            {this.userMainInfo()}
                                            {this.saveButton()}
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div className="user-details mt-4">
                                    <div className="user-details__container">
                                        {this.basicInfo()}
                                        {this.contactInfo()}
                                        {this.intermediary()}
                                        {this.address()}
                                        {this.license()}
                                        {this.branch()}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* {this.hierarchyModal("hierarchyModal")} */}
                </div>
            );
        }
    }
}

export default withRouter(UserInfo);