import { writeData } from "../../../commons/utility";
import moment from "moment";

export const SEARCH_TAB = {
  ALL: "",
  BULLETIN: "/bulletins",
  CONTEST: "/contests",
  USER: "/users"
};

export function getParams() {
  var str = window.location.search;
  var objURL = {};

  str.replace(new RegExp("([^?=&]+)(=([^&]*))?", "g"), function(
    $0,
    $1,
    $2,
    $3
  ) {
    objURL[$1] = $3;
  });
  return objURL;
}

export function isEmpty(obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
}

export function IndexColor(index) {
  var color = "white";
  var list = [
    "#ee8572",
    "#35495e",
    "#347474",
    "#63b7af",
    "#8cba51",
    "#ff5d6c",
    "#ee8972",
    "#7fcd91",
    "#af460f",
    "#fe8761",
    "#d5c455",
    "#5f6caf",
    "#f0134d",
    "#db3056",
    "#3282b8",
    "#0f4c75",
    "#ffba5a",
    "#be9fe1",
    "#916dd5",
    "#3e206d",
    "#12cad6",
    "#2c786c",
    "#004445",
    "#9dab86",
    "#ccda46",
    "#697c37",
    "#cfb495",
    "#f5cdaa",
    "#4a69bb",
    "#617be3",
    "#02a8a8",
    "#018383"
  ];

  if (index > list.length) {
    color = list[index % list.length];
  } else {
    color = list[index];
  }
  return color;
}

export function rniLogoutF() {
  let session = {
    alias: "",
    image: "",
    view_as: "",
    view_as_email: "",
    view_as_number: "",
    name: "",
    agentNumber: "",
    code: "",
    permissions: [],
    username: "",
    channel: "",
    id: 1,
    user_role: "",
    refresh_token: ""
  };
  writeData("session", session);
  //this.props.setUserSession(session);
  setTimeout(function() {
    window.location = "/";
  }, 500);
  // routeChange(this.props.history, "/");
}

export function checkProperties(obj) {
  for (var key in obj) {
    if (obj[key]) return true;
  }
  return false;
}

export function checkExpirationToDate(expirationDt) {
  return moment(new Date()).unix() > moment(expirationDt).unix();
}
