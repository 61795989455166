import React from "react";

const reactTableTh = (props) => {
    return (
        <div onClick={props.onClick} className={`rt-td ${props.className} ${props.isSortable !== false ? (props.sortColor ? props.sortColor : "") : "pes-none text-black"}`} style={props.style}>
            {props.children}{props.isSortable !== false ? <span className={`${props.sortClassName ? props.sortClassName : "icon-popout"}`} style={{ lineHeight: "initial", marginLeft: ".1rem" }}></span> : ""}
        </div>
    )

}
export default reactTableTh;
