import React, {Component} from 'react';
import Select from 'react-select';

const styles={
    option: (provided,{isDisabled}) => ({
        ...provided,
        fontFamily: "neo_bold",
        fontSize: ".6rem",
        // color: isDisabled ? "#9A9B9C" : "black",
        color: isDisabled ? "#c2c2c2" : "black",
        backgroundColor: 'white',
        cursor: isDisabled ? 'not-allowed' : 'default',
    }),
    menu: () => ({
        position: "absolute",
        border: 0,
        zIndex: "1090",
        backgroundColor: "white",
        borderRadius: "5px",
        boxShadow: "2px 2px 2px #DDDEE0",
    }),
    
}

class Filter extends Component{
    constructor(props){
        super(props);
    }

    
    render(){
        let options = this.props.options;
        let value = this.props.value;

        return(
            <div className="m-1">
                <Select
                className="datatable-select__control"
                classNamePrefix="datatable-select"
                value={value}
                styles={styles}
                options={options}
                isOptionDisabled={(option) => option.isDisabled}
                isSearchable={false}
                onChange={this.props.onChange}
                />
            </div>
        )
    }
}
export default Filter;