import React from 'react';
import '../css/mis.scss';

export const MAP_MODE = {
    PERFORMANCE: 'PERFORMANCE',
    TARGET: 'TARGET'
}

export const LINE_GRAPH_MODE = {
    ACTUALVSTARGET: 'ACTUALVSTARGET',
    ACTUALVSACTUAL: 'ACTUALVSACTUAL'
}

export const misAssetUrl = "assist.allianzpnblife.ph/dashboard/mis";

export const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

export const RESOLUTIONS = {
    xl: 1200.99,
    lg: 992.99,
    md: 768.99,
    sm: 576.99
}

export const MAP_CONFIG = {
    METRO_MANILA: {
        rotate: [-121.52, 24.069054, 0],
        scale: 22000
    },
    PHILIPPINES: {
        rotate: [-121, 25.879721, 0],
        scale: 900
    }
}

export function haveValue(value) {
    if(value){
        if(value.length != 0) {
            return true
        }
    }

    return false
}

export function labelXAxis(word) {
    let formattedLabel = [];

    if(word) {
        let splittedWord = word.split(" ");
        for(var i = 0; i < splittedWord.length; i++) {
            let wordContainer = "";
            if(splittedWord[i].length <= 6 && i != (splittedWord.length - 1)) {
                if(splittedWord[i+1].length > 10){
                    formattedLabel.push(splittedWord[i]);
                }
                else{
                    wordContainer = splittedWord[i] + " " + splittedWord[i+1];
                    formattedLabel.push(wordContainer);
                    i++;
                }
            }else{
                formattedLabel.push(splittedWord[i])
            }
        }

        

        
        // let splittedWord = word.split(" ");
        // for(var i = 0; i < splittedWord.length; i++) {
        //     // let wordContainer = "";
        //     if(splittedWord[i].length <= 6 && i != (splittedWord.length - 1)) {
        //         wordContainer += splittedWord[i];
        //     }else{
        //         formattedLabel.push(wordContainer);
        //         wordContainer = "";
        //     }
        // }
    }
    return formattedLabel;
}

export function showNoData(isFilterNotApplicable, filter){
    let responseText = isFilterNotApplicable ? 'FILTER NOT APPLICABLE' : 'NO DATA TO SHOW';
    if(isFilterNotApplicable && filter){
        responseText = filter.value ? filter.value + ' NOT APPLICABLE' : 'FILTER NOT APPLICABLE';
    }

    return (
        <p className="mis-no-data-show">
            {responseText}
        </p>
    )
}

export const charts = {
    averageCaseSize: {
        label: 'Average Case Size',
        pathParam: 'aveCaseSize'
    },
    achievement: {
        label: '% Achieved',
        pathParam: 'achievement'
    },
    contractStatus: {
        label: 'Value Per Status',
        pathParam: 'contractStatus'
    },
    pendingContractStatus: {
        label: 'Value Per Pending Status',
        pathParam: 'pendingContractStatus'
    },
    actualTarget: {
        label: 'Actual vs Target',
        pathParam: 'actualTarget'
    },
    actualActual: {
        label: 'Actual vs Actual',
        pathParam: 'actualActual'
    },
    heatmap: {
        label: 'Performance Heatmap',
        pathParam: 'heatmap'
    },
    channel: {
        label: 'Issued Per Channel',
        pathParam: 'channel'
    },
    region: {
        label: 'Issued Per Region',
        pathParam: 'region'
    },
    product: {
        label: 'Issued Per Product',
        pathParam: 'product'
    },
    plan: {
        label: 'Issued Per Plan',
        pathParam: 'plan'
    },
    fund: {
        label: 'Fund Allocation',
        pathParam: 'fund'
    },
    clientType: {
        label: 'Value Per Client Type',
        pathParam: 'clientType'
    }

}
export const PERSISTENCY_TOOLTIP_SEQUENCE = ["LIMRA13","RP13","LIMRA25","RP25"];
export function isEmpty(obj) {
    for(let key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
}

export const defaultFilters = {
    default :{
        period: { value: "MTD", label: "MTD" },
        metric: { value: "ANP", label: "ANP" },
        product: { value: "ALL", label: "All" },
        channel: { value: "ALL", label: "All" },
        status: { value: "ISSUED", label: "Issued" },
        target: { value: "OKR", label: "OKR" },
    },
    pnb :{
        period: { value: "MTD", label: "MTD" },
        metric: { value: "ANP", label: "ANP" },
        product: { value: "ALL", label: "All" },
        channel: { value: "BANCASSURANCE", label: "Bancassurance" },
        status: { value: "ISSUED", label: "Issued" },
        target: { value: "OKR", label: "OKR" },
    }
}
