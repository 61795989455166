import React, { Component } from 'react'
import ContestProgressItem from '../components/contestProgressItem';
import SectionHeader from '../components/sectionHeader';
import RatingLine from '../components/ratingLine';
import MiniLoading from '../components/miniLoading';
import { getUserProgress, getUserInformation } from '../commons/restEndpoint';
import { distanceDate, checkSearch, writeData, readAllData, encrypt, decrypt } from '../../../commons/utility.js';
import { formatValues } from '../../../components/Mis/formatter';
import moment from 'moment';

export default class profile extends Component {
    constructor(props) {
        super(props);

        this.state = {
            progresses: [],
            doneGettingContestProgress: false,
            doneGettingUserInformation: false,
            doneGettingUserGraph: false,
            email: this.props.session.username,
            props: this.props,
            userDetails: {
                birthday: "",
                contactNo: "",
                email: "",
                role: {
                    name: "",
                    description: "",
                },
                totalAnp: 0,
                totalPc: 0,
            },
        }

        this.fetchUserProgress = this.fetchUserProgress.bind(this);
        this.fetchUserInformation = this.fetchUserInformation.bind(this);
    }

    async fetchUserInformation() {
        let result = await getUserInformation(decrypt(this.props.session.username), decrypt(this.props.session.access_token));
        if (result) {
            if (result.data) {
                this.setState({
                    doneGettingUserInformation: true,
                    userDetails: result.data
                });
            } else {
                this.setState({
                    doneGettingUserInformation: true,
                });
            }
        } else {
            this.setState({
                doneGettingUserInformation: true,
            });
        }
        return null;
    }

    async fetchUserProgress() {
        let result = await getUserProgress(decrypt(this.props.session.username), decrypt(this.props.session.access_token));
        if (result) {
            if (result.data) {
                this.setState({
                    doneGettingContestProgress: true,
                    progresses: result.data
                });
            } else {
                this.setState({
                    doneGettingContestProgress: true,
                });
            }
        } else {
            this.setState({
                doneGettingContestProgress: true,
            });
        }
        return null;
    }

    async componentDidMount() {
        await this.fetchUserInformation();
        await this.fetchUserProgress();
    }

    render() {
        var { doneGettingContestProgress, doneGettingUserGraph, doneGettingUserInformation, progresses, userDetails, email, props } = this.state;
        var birthday = userDetails.birthday !== "" ? userDetails.birthday : 0;
        birthday = moment(birthday * 1000);
        birthday = birthday.format('MMMM DD, YYYY');
        if (birthday === "Invalid date") {
            birthday = null;
        }
        return (
            <div className="container" style={{ paddingBottom: '30px' }}>
                <div className="rni-profile-userpic-container-PROFILE">
                    <img src={this.props.session.image ? this.props.session.image !== "" ? decrypt(this.props.session.image) : "/rni/list-test-image-2.jpg" : "/rni/list-test-image-2.jpg"} className="rni-profile-userpic-image" />
                    <p style={{ marginBottom: '0px' }} className="rni-profile-username-p">{decrypt(this.props.session.name)}</p>
                    <p style={{ paddingTop: '0px', marginBottom: '0px' }} className="rni-profile-username-agency">{userDetails.role.description}</p>
                </div>
                {doneGettingContestProgress ? <div className="rni-profile-current-progress">
                    <SectionHeader label="Current Progress" src="/rni/gold-medal.png" />
                    <div className="row">
                        {progresses.length > 0 ? progresses.map(function (progress, index) {
                            let alt = false;
                            if (index % 2 !== 0) {
                                alt = true;
                            }
                            return (
                                <div key={index} className="col-md-12 col-xs-12 col-sm-12 col-lg-12 rni-leaderboard-list-profile-item" style={{ paddingTop: '5px', paddingBottom: '5px' }}>
                                    <ContestProgressItem data={progress} alternate={alt} email={email} props={props}/>
                                </div>
                            );
                        }) : <p style={{ fontSize: '2rem', fontFamily: 'neo_regular', textAlign: 'center', color: '#D7D3D9', width: '100%' }}>No contest progress yet.</p>}
                    </div>
                </div> : <div style={{ margin: 'auto' }}><MiniLoading /></div>}
            </div>
        )
    }
}
