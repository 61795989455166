import { writeData, readAllData, formatDateByNumber, jsonToURI, decrypt } from './utility.js';
import { myConfig } from './config.js';
import { endpoints } from './restEndpoints.js';
import { getSessionInfo } from './security';
import { getUserInformation } from '../pages/rni/commons/restEndpoint.js';
const axios = require('axios');
const idb = require('idb');

let createQueryStrings = (obj) => {
  let str = [];
  let returnString = "";
  for (var p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  
    if(str.length > 0) {
      returnString = "?" + str.join("&");
    }
  return returnString;
}


let apiModule = async (my_module, requestParams ,formData, queryStrings, special) => {
  let url = endpoints(requestParams)[my_module].url;
  
  let headers = endpoints(requestParams)[my_module].headers;
  let addUserRole = endpoints(requestParams)[my_module].add_user_role;

  let userInfo = await getSessionInfo();

  if(addUserRole) {
    headers = {...headers, 'X-User-Role': userInfo[0].user_role, 'X-User-Id': decrypt(userInfo[0].username)}
  }

  if(headers.hasOwnProperty('Authorization')) {
    headers = {...headers, 'Authorization': userInfo[0].access_token}
  }

  let method = endpoints(requestParams)[my_module].method;
  let server_url = myConfig.SERVER_URL;
  if(endpoints(requestParams)[my_module].server_url !== null &&
    endpoints(requestParams)[my_module].server_url !== undefined)
    {
      server_url = endpoints(requestParams)[my_module].server_url;
    }
  let result = {};

  writeData('actions', {last_action: Date.now(), id: 1});

  if(queryStrings === null || queryStrings === undefined) {
    queryStrings = "";
  }

  if(special) {
    return new Promise(function(resolve, reject) {
      let myUrl = server_url + url + queryStrings;
      axios(
        {
            method: method,
            url: myUrl,
            data: formData,
            headers: headers
            
        })
        .then(function(data){
          if(my_module !== "authentication_refresh_token"){
            writeData('actions', {last_action: Date.now(), id: 1});
          }
          
          resolve(data);
        })
        .catch(function(err) {
          console.log(err);
          //window.confirm(err);
          if(my_module !== "authentication_refresh_token"){
            writeData('actions', {last_action: Date.now(), id: 1});
          }
          reject(err);
        });
      });
  }else {
    let data = jsonToURI(formData);
    return new Promise(function(resolve, reject) {
      if(data === null || data === '') {
        fetch(server_url + url + queryStrings, {
          method: method,
          headers: headers
        })
        .then(function(response){
        // console.log(response);
        return response.json();
        })
        .then(function(data) {
          // console.log(data)
            writeData('actions', {last_action: Date.now(), id: 1});
            resolve(data);
        })
        .catch(function(err) {
           console.log(err);
           writeData('actions', {last_action: Date.now(), id: 1});
         //window.confirm(err);
          reject(err);
        });
      }else {
        fetch(server_url + url + queryStrings, {
          method: method,
          headers: headers,
          body:  data
        })
        .then(function(response){
        // console.log(response);
        return response.json();
        })
        .then(function(data) {
          // console.log(data)
            writeData('actions', {last_action: Date.now(), id: 1});
            resolve(data);
        })
        .catch(function(err) {
           console.log(err);
        // window.confirm(err);
          writeData('actions', {last_action: Date.now(), id: 1});
          reject(err);
        });

      }
    });
  }
  
}

let apiCall = (url, method, headers, formData) => {
    return new Promise(function(resolve, reject) {
      fetch(myConfig.SERVER_URL + url, {
          method: method,
          headers: headers,
          body:  formData
        })
        .then(function(response){
        // console.log(response);
        return response.json();
        })
        .then(function(data) {
          // console.log(data)
            resolve(data);
        })
        .catch(function(err) {
           console.log(err);
          //window.confirm(err);
          reject(err);
        });
    });
}

let offlineSync = (my_module, requestParams ,formData) => {
  let url = endpoints(requestParams)[my_module].url;
  let headers = endpoints(requestParams)[my_module].headers;
  let method = endpoints(requestParams)[my_module].method;
  let post = {};
  post.requestParams = {url : url, method : method, headers : headers};
  post.data = { formData };
  let id = 0;
  return new Promise(function(resolve, reject) {
    navigator.serviceWorker.ready
      .then(function(sw) {
        readAllData('sync-posts')
          .then(function(sync){
            id = sync.length + 1;
            return id;
          })
          .then(function(id){
            writeData('sync-posts', {post , id : id})
              .then(function() {
                return sw.sync.register('sync-new-posts');
              })

          resolve({message : 'Post is saved for syncing', result : 'success'});
          return({message : 'Post is saved for syncing', result : 'success'});

          });
    });
  });
}

export { apiCall, offlineSync, apiModule, createQueryStrings };
