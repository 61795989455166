import React, { Component } from 'react'
import TextEllipsis from 'react-text-ellipsis';
import BulletinSection from './bulletinSection';
import { getParams } from '../commons/service';
import BulletinDetails from './bulletinDetails';
import SectionHeader from '../components/sectionHeader';
import MiniLoading from '../components/miniLoading';
import moment from 'moment';
import { getBulletinList } from '../commons/restEndpoint';
import VisibilitySensor from 'react-visibility-sensor';
import ContestInProgress from '../components/contestInProgress';
export default class bulletin extends Component {

    constructor(props) {
        super(props)
        this.state = {
            bulletinList: [],
            doneGettingBulletinList: false,
            isGettingMore: false,
            page: 0,
            size: 25,
            maxPage: 5,
            bulletinId: -1,
            haveId: false
        }

        this.nextPage = this.nextPage.bind(this);
        this.fetchBulletinList = this.fetchBulletinList.bind(this);
    }

    componentDidMount() {
        let props = this.props;
        let params = getParams();
        let that = this;
        var haveId = params.hasOwnProperty("id");
        this.setState({
            ...props,
            haveId: haveId,
            bulletinId: params.id
        }, function () {
            if (!haveId)
                that.fetchBulletinList();
        });
    }

    componentWillReceiveProps(nextProps) {
        let params = getParams();
        this.setState({
            ...nextProps,
            haveId: params.hasOwnProperty("id"),
            bulletinId: params.id
        })
    }

    async fetchBulletinList() {
        this.setState({
            isGettingMore: true,
        });
        let { page, size, bulletinList } = this.state;
        let thisCom = this;
        var params = {
            page: page,
            size: size
        };
        let x = await getBulletinList(params, this.props.session.access_token);
        if (x) {
            if (x.data) {
                var y = x.data.content;
                // var list = [];
                var list = bulletinList;
                y.map(function (bulletin, index) {
                    let outputDate = "";
                    if (bulletin.expiryDt) {
                        let now = moment(new Date());
                        let convertedFetchedDate = moment(bulletin.expiryDt * 1000);
                        let fetchedDate = moment(convertedFetchedDate ? convertedFetchedDate : now);
                        let duration = moment.duration(now.diff(fetchedDate));
                        outputDate = "h";
                        if (duration.asSeconds() <= 59) {
                            outputDate = "now";
                        } else if (duration.asMinutes().toFixed(0) <= 59 && duration.asMinutes().toFixed(0) >= 1) {
                            outputDate = duration.asMinutes().toFixed(0) + "m";
                        } else if (duration.asHours().toFixed(0) <= 24 && duration.asHours().toFixed(0) >= 1) {
                            outputDate = duration.asHours().toFixed(0) + "h";
                        } else if (duration.asDays().toFixed(0) <= 2) {
                            outputDate = duration.asDays().toFixed(0) + "d";
                        } else {
                            outputDate = convertedFetchedDate.format("MMMM D, YYYY");
                        }
                        console.log(outputDate);
                    } else {
                        outputDate = "No Expiry Date Found";
                    }
                    list.push({
                        title: bulletin.title,
                        type: bulletin.type,
                        id: bulletin.id,
                        imgSrc: bulletin.imgUrl,
                        date: outputDate,
                    });
                    return null;
                });
                this.setState({
                    maxPage: x.data.totalPages,
                    bulletinList: list,
                    doneGettingBulletinList: true,
                    isGettingMore: false,
                });
            }
        }
    }

    nextPage() {
        if (this.state.isGettingMore === false) {
            var { page, size, maxPage } = this.state;
            var that = this;
            if (page + 1 < maxPage) {
                this.setState({
                    page: page + 1,
                }, function () {
                    that.fetchBulletinList();
                });
            }
        }
    }

    renderDisplay() {
        const { haveId, bulletinId, doneGettingBulletinList, bulletinList, isGettingMore, page, maxPage } = this.state;
        return haveId ?
            <BulletinDetails session={this.props.session} {...this.state} /> :
            <div>
                <SectionHeader label="Latest Updates" src="/rni/announcement.svg" />
                {doneGettingBulletinList ?
                    <BulletinSection session={this.props.session} {...this.state} bulletinList={bulletinList} />
                    :
                    <div style={{ width: '50vw', margin: 'auto' }}><MiniLoading /></div>
                }
                {doneGettingBulletinList && !isGettingMore && (page + 1 < maxPage) ? <VisibilitySensor delayedCall={true} onChange={(e) => {
                    if (e) { this.nextPage(); }
                }}>
                    <div>
                        <p style={{
                            textAlign: 'center',
                            fontSize: '2rem',
                            fontWeight: '800',
                            marginBottom: '0px'
                        }}>. . .</p>
                    </div>
                </VisibilitySensor> : ""}
            </div>
    }

    render() {
        return <ContestInProgress />
    }
}