import React, { Fragment } from 'react';
import './modal.scss';

const downloadSuccessModal = (props) => {
    let { text, subText, closeModal, showModal } = props;
    text = isEmpty(text) ? "" : "Your file was successfully downloaded!";
    subText = isEmpty(subText) ? "" : "Note: The extracted file is password protected.\nPlease check your email for the password.";
    return (
        <Fragment>
            {showModal &&
                (
                    <div className="reports-modal-body">
                        <div className="reports-modal-box">
                            <div className="reports-modal-content">
                                <div className="reports-modal-icon-container">
                                    <i className="reports-modal-icon far fa-check-circle text-success"></i>
                                </div>
                                <div className="reports-modal-text">
                                    {text}
                                    {
                                        subText &&
                                        <div className="reports-modal-subtext mt-2">
                                            {subText}
                                        </div>
                                    }
                                </div>
                                <div className="reports-modal-button reports-modal-button--width-1">
                                    <a
                                        onClick={() => closeModal()}
                                        className={`lh-1 d-flex flex-grow-0 mt-2 mt-md-0 justify-content-center align-items-center btn btn-dropdowncolor rounded-pill text-uppercase font-p80rem font-neo-semibold text-white`}>
                                        OK
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        </Fragment>
    )
}

const isEmpty = (text) => {
    const noValueList = [undefined, false, null];
    return !noValueList.includes(text);
}

export default downloadSuccessModal;
