import React, { Component } from "react";
import DatePicker from "react-datepicker";
import DpCustomHeader from "../components/Datepicker/customHeader";

class ReportDatepicker extends Component {
    constructor(props) {
        super(props);
        this.handleStartDateChange = this.handleStartDateChange.bind(this);
        this.handleEndDateChange = this.handleEndDateChange.bind(this);
    }

    componentDidMount() {
        //MAKE DATEPICKER INPUT FIELDS READONLY
        const datePicker = document.getElementsByClassName("react-datepicker__input-container");
        for (let i = 0; i < datePicker.length; i++) {
            datePicker[i].childNodes[0].setAttribute("readOnly", true);
        }
    }
    handleStartDateChange = (date) => {
        this.props.updateStartDate(date);
    }
    handleEndDateChange = (date) => {
        this.props.updateEndDate(date);
    }

    render() {
        const yearDropdown = this.props.yearDropdown == false ? this.props.yearDropdown : true;
        const dateFormat = this.props.dateFormat ? this.props.dateFormat : "MM-dd-yyyy";
        return (
            <div className="policy-datepicker">
                <div className="policy-datepicker__body flex-wrap justify-content-start align-items-stretch">
                    <div className="policy-datepicker__part d-flex align-items-center position-relative">
                        <div className="form-control policy-datepicker__container rounded-pill border-0 h-100">
                            <DatePicker
                                placeholderText={this.props.placeholderFrom}
                                className="policy-datepicker__input text-gray policy-datepicker__input--placeholder"
                                dateFormat={dateFormat}
                                selected={this.props.startDateValue}
                                onChange={this.handleStartDateChange}
                                minDate={this.props.minDateTo}
                                maxDate={this.props.maxDateFrom}
                                renderCustomHeader={({
                                    date,
                                    changeYear,
                                    decreaseMonth,
                                    increaseMonth,
                                    prevMonthButtonDisabled,
                                    nextMonthButtonDisabled
                                }) => (
                                        <DpCustomHeader
                                            dropdownStyle={{
                                                option: (provided) => ({
                                                    ...provided,
                                                    fontFamily: "neo_bold",
                                                    fontSize: ".65rem",
                                                    color: "#1e4a73",
                                                    backgroundColor: "white"
                                                }),
                                                menu: () => ({
                                                    position: "absolute",
                                                    border: 0,
                                                    zIndex: "1090",
                                                    backgroundColor: "white",
                                                    borderRadius: "5px",
                                                    width: "4rem",
                                                    boxShadow: "2px 2px 2px #DDDEE0"
                                                }),
                                            }}
                                            yearOptions={this.props.yearOptions}
                                            date={date}
                                            changeYear={yearDropdown ? changeYear : null}
                                            decreaseMonth={decreaseMonth}
                                            increaseMonth={increaseMonth}
                                            prevMonthButtonDisabled={prevMonthButtonDisabled}
                                            nextMonthButtonDisabled={nextMonthButtonDisabled}
                                        />
                                    )}
                            />
                            <span className="icon-calendar text-gray"></span>
                        </div>
                    </div>
                    <div className="policy-datepicker__part d-flex align-items-center position-relative ml-2 mx-md-2">
                        <div className="form-control policy-datepicker__container  policy-datepicker__mobile rounded-pill border-0 h-100">
                            <DatePicker
                                placeholderText={this.props.placeholderTo}
                                className="policy-datepicker__input text-gray policy-datepicker__input--placeholder"
                                dateFormat={dateFormat}
                                selected={this.props.endDateValue}
                                onChange={this.handleEndDateChange}
                                minDate={this.props.minDateTo}
                                maxDate={this.props.maxDateFrom}
                                renderCustomHeader={({
                                    date,
                                    changeYear,
                                    decreaseMonth,
                                    increaseMonth,
                                    prevMonthButtonDisabled,
                                    nextMonthButtonDisabled
                                }) => (
                                        <DpCustomHeader
                                            dropdownStyle={{
                                                option: (provided) => ({
                                                    ...provided,
                                                    fontFamily: "neo_bold",
                                                    fontSize: ".65rem",
                                                    color: "#1e4a73",
                                                    backgroundColor: "white"
                                                }),
                                                menu: () => ({
                                                    position: "absolute",
                                                    border: 0,
                                                    zIndex: "1090",
                                                    backgroundColor: "white",
                                                    borderRadius: "5px",
                                                    width: "4rem",
                                                    boxShadow: "2px 2px 2px #DDDEE0"
                                                }),
                                            }}
                                            yearOptions={this.props.yearOptions}
                                            date={date}
                                            changeYear={yearDropdown ? changeYear : null}
                                            decreaseMonth={decreaseMonth}
                                            increaseMonth={increaseMonth}
                                            prevMonthButtonDisabled={prevMonthButtonDisabled}
                                            nextMonthButtonDisabled={nextMonthButtonDisabled}
                                        />
                                    )}
                            />
                            <span className="icon-calendar text-gray"></span>
                        </div>
                    </div>
                    <div className="policy-datepicker__footer text-center">
                        {!this.props.hideButton &&
                        <button className="policy-datepicker__submit lh-1 btn btn-dropdowncolor font-neo-semibold py-1 rounded-pill text-uppercase" onClick={this.props.showTable}>View Report</button>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default ReportDatepicker;
