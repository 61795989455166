import React from "react";
import Auxi from '../../auxi';

const usersMobile = (props) => {
    const customName = props.customNames;
    const data = props.data;
    let users = data.map((item, index) => {
        return (
            <Auxi key={index}>
                <div className={`data-accord__card ${index > 0 ? "mt-3" : ""} card border-0`}>
                    <div className="data-accord__toggle btn">
                        <div className="row data-accord__start">
                            <div className="col-5">
                                <button onClick={() => props.onClickAction(item)} className="data-accord__name data-accord__name--clear">{item.name}</button>
                            </div>
                            <div className="col-7 d-flex justify-content-between">
                                <div className="font-neo-semibold">{item.INTM_NO}</div>
                                <button className="btn p-0" type="button" data-toggle="collapse" data-target={`#collapse-${index}`}>
                                    <div className="data-accord__caret">
                                        <div className="data-accord__caret-icon"></div>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div id={`collapse-${index}`} className="collapse" data-parent="#data-accord">
                        <div className="card-body data-accord__content">
                            <div className="row">
                                <div className="data-accord__column col-5">
                                    <div className="data-accord__label">{customName.BIRTHDAY}</div>
                                    <div className="data-accord__value">{item.BIRTHDAY}</div>
                                </div>
                                <div className="data-accord__column col-7">
                                    <div className="data-accord__label">{customName.ADDRESS}</div>
                                    <div className="data-accord__value">{item.ADDRESS}</div>
                                </div>
                                <div className="data-accord__column col-5">
                                    <div className="data-accord__label">{customName.phoneNumber}</div>
                                    <div className="data-accord__value">{item.phoneNumber}</div>
                                </div>
                                <div className="data-accord__column col-7">
                                    <div className="data-accord__label">{customName.STATUS}</div>
                                    <div className="data-accord__value">{item.STATUS}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Auxi >
        );
    });

    return (
        <div className="data-accord row flex-wrap mt-4" >
            <div className="data-accord__header">
                <div className="col-5">
                    <div onClick={() => props.headerClickedHandler("name")} className="d-inline-flex align-items-center cs-pointer">
                        {customName.name} <span className="icon-popout"></span>
                    </div>
                </div>
                <div className="col-7">
                    <div onClick={() => props.headerClickedHandler("INTM_NO")} className="d-inline-flex align-items-center cs-pointer">
                        {customName.INTM_NO} <span className="icon-popout"></span>
                    </div>
                </div>
            </div>
            <div className="col-12 data-accord__body mt-1">
                <div className="data-accord__collapse" id="data-accord">
                    {users}
                </div>
            </div>
        </div>
    );
}

export default usersMobile;
