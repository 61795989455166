import React from 'react';
import Select from 'react-select';
import withWindowDimensions from '../../commons/withWindowDimensions';
import "./documents.scss";

const YearRange = (props) => {

    let yearRangeCompoenent = (
        <>
        <Select
            styles={props.styles}
            options={props.years}
            isSearchable={false}
            className={`datatable-select mr-md-2 ${props.isSm? 'ml-0 w-50 mt-2': ''}`}
            classNamePrefix="datatable-select"
            value={props.yearFrom}
            onChange={props.handleUpdateYearFrom}
            placeholder="From"
            styles={{
                option: (provided) => ({
                    ...provided,
                    fontFamily: "neo_semibold",
                    fontSize: ".6rem",
                    color: "#9A9B9C",
                    backgroundColor: "white"
                }),
                menu: () => ({
                    position: "absolute",
                    border: 0,
                    zIndex: "1090",
                    backgroundColor: "white",
                    borderRadius: "5px",
                    boxShadow: "2px 2px 2px #DDDEE0"
                }),
            }}
        />

        <Select
            styles={props.styles}
            options={props.years}
            isSearchable={false}
            className={`datatable-select mr-md-2 ${props.isSm? 'w-50 mt-2': ''}`}
            classNamePrefix="datatable-select"
            value={props.yearTo}
            onChange={props.handleUpdateYearTo}
            placeholder="To"
            styles={{
                option: (provided) => ({
                    ...provided,
                    fontFamily: "neo_semibold",
                    fontSize: ".6rem",
                    color: "#9A9B9C",
                    backgroundColor: "white"
                }),
                menu: () => ({
                    position: "absolute",
                    border: 0,
                    zIndex: "1090",
                    backgroundColor: "white",
                    borderRadius: "5px",
                    boxShadow: "2px 2px 2px #DDDEE0"
                }),
            }}
        />
        </>
    );

    return (
        props.isSm? (
            <div className="d-flex w-100">
                {yearRangeCompoenent}
            </div>
        ): yearRangeCompoenent
    )
}

export default withWindowDimensions(YearRange);