import React, { Component } from 'react'
import { NavLink } from 'react-router-dom';
import $ from 'jquery';
import { Redirect } from 'react-router-dom';
import { searchRNI } from '../commons/restEndpoint';
import { createQueryStrings } from '../../../commons/apiCall';
import { clearAllData } from '../../../commons/utility';

export default class Sidebar extends Component {

    constructor(props) {
        super(props)

        this.state = {
            open: false,
        }

    }

    componentDidMount() {
        $('.wrapper').animate({ scrollTop: 0 }, 1);
    }

    render() {

        const { handleDrawerToggle, isOpen, module, openSidebar, clickedSearched, navList, activeTab, searchValue, initiateSearch, changeSearchValue, clickedTab, closeSidebar, clearAllData } = this.props;
        const isSearching = module.toLowerCase() === 'search';
        let that = this;
        let navWidth = navList ? 100 / navList.length : 0;

        return (
            <div className="wrapper">
                {this.state.redirect}
                <header className="main-header not-draggable">
                    <nav className="newStyle-navBar navbar navbar-static-top">
                        {isSearching ?
                            <img className="rni-search-back-image" src="/rni/back.png" onClick={() => { clearAllData(); this.props.history.goBack() }} /> :
                            <img onClick={handleDrawerToggle} className={"newStyle-sidebarToggle"} src={'/rni/burger-menu-alt.png'} alt='' />
                        }

                        {isSearching ?
                            <input onKeyDown={(e) => { if (e.key === 'Enter') { initiateSearch() } }} className="rni-search-input" placeholder="Type to search..." value={searchValue} onChange={(e) => { changeSearchValue(e) }} /> :
                            <p className="rni-top-bar-module">{module}</p>
                        }
                        {isSearching ?
                            <img className="rni-search-search-image" onClick={() => { initiateSearch() }} src="/rni/search-2.png" /> :
                            <div className="rni-navbar-search-container">
                                {/* <input onKeyDown={(e) => { if (e.key === 'Enter') { this.initiateSearch() } }} className="rni-navbar-search-input" placeholder="Search" value={this.state.searchValue} onChange={(e) => { this.changeSearch(e) }} /> */}
                                <NavLink to="/rewards-and-incentives/search"><img className="rni-navbar-search-image" src="/rni/search-2.png" onClick={closeSidebar} alt="" /></NavLink>
                            </div>}
                    </nav>
                </header>
                <aside style={{ maxHeight: '100vh'/*, overflow: 'auto'*/ }} className="newStyle-sidebar main-sidebar not-draggable" onClick={openSidebar}>
                    <section className="sidebar not-draggable">
                        {clickedSearched && isSearching ?
                            <div className="rni-search-nav-container">
                                <div className="rni-search-nav">
                                    {navList.map(function (itemObj, itemIndex) {
                                        let currStats = itemObj.value === activeTab ? 'rni-active-search' : '';
                                        return (
                                            <div key={itemIndex}
                                                className={`rni-search-nav-item ${currStats}`}
                                                style={{ width: navWidth + '%' }}
                                                onClick={(e) => { e.stopPropagation(); closeSidebar(); clickedTab(itemObj.value); }}>
                                                {itemObj.label}
                                            </div>);
                                    })}
                                </div>
                            </div> : null}
                        {this.props.children}
                    </section>
                </aside>
            </div>
        )
    }
}
