import React from 'react';
import DatePicker from 'react-datepicker';
import DpCustomHeader, {Years} from "../Datepicker/customHeader";
import * as moment from "moment";

const MonthField = (props) => {
    return (
        <div className={`policy-datepicker__part d-flex align-items-center position-relative`}>
            <div className="form-control policy-datepicker__month policy-datepicker__container rounded-pill border-0 h-100">
                <DatePicker
                    showMonthYearPicker
                    dateFormat="yyyy-MM"
                    placeholderText="Date of Measurement"
                    className="policy-datepicker__input text-gray policy-datepicker__input--placeholder"
                    selected={props.value}
                    onChange={props.handleUpdate}
                    maxDate={props.maxDate}
                    minDate={props.minDate}
                    renderCustomHeader={({
                        date,
                        changeYear,
                        decreaseYear,
                        increaseYear,
                        prevYearButtonDisabled,
                        nextYearButtonDisabled
                    }) => (
                        <DpCustomHeader
                            dropdownStyle={{
                                option: (provided) => ({
                                    ...provided,
                                    fontFamily: "neo_bold",
                                    fontSize: ".65rem",
                                    color: "#1e4a73",
                                    backgroundColor: "white"
                                }),
                                menu: () => ({
                                    position: "absolute",
                                    border: 0,
                                    zIndex: "1090",
                                    backgroundColor: "white",
                                    borderRadius: "5px",
                                    width: "4rem",
                                    boxShadow: "2px 2px 2px #DDDEE0"
                                }),
                            }}
                            date={date}
                            hideMonth
                            yearOptions={Years(parseInt(moment(props.minDate).format("YYYY"))).reverse()}
                            changeYear={changeYear}
                            decreaseMonth={decreaseYear}
                            increaseMonth={increaseYear}
                            prevMonthButtonDisabled={prevYearButtonDisabled}
                            nextMonthButtonDisabled={nextYearButtonDisabled}
                        />
                    )}
                />
                <span className="icon-calendar text-gray"></span>
            </div>
        </div>
    )
}

export default MonthField;
