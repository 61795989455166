import React, { Component, Fragment } from 'react'
import ContestListItem from '../components/contestListItem';
import SectionHeader from '../components/sectionHeader';
import { getContestUnderCategory, getContestList } from '../commons/restEndpoint';
import MiniLoading from '../components/miniLoading';
import { IndexColor } from '../commons/service';
import { Swipe } from 'react-swipe-component';
import Slider from "react-slick";
import { Carousel } from 'react-responsive-carousel';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";

export default class contestLanding extends Component {

    constructor(props) {
        super(props)

        this.state = {
            popularContests: [],
            doneGettingPopularContest: false,
        }
        this.getContest = this.getContest.bind(this);
    }

    async getContest() {
        let params = {
            status: this.props.archived ? 'inactive' : 'active'
        }
        let list = [];
        let contestsList = await getContestList(params, this.props.session.access_token);
        if (contestsList && contestsList.data) {
            
            contestsList.data.map(function (contest) {
                list.push({
                    header: contest.contestTitle,
                    description: contest.contestDescription,
                    expiration: contest.contestExpiryDt,
                    joinedCount: contest.contestTotalUsers,
                    background: IndexColor(contest.contestId ? contest.contestId : 0),
                    color: 'white',
                    image: contest.contestImg,
                    contestId: contest.contestId
                });
            });
        }

        return list;
    }

    async componentDidMount() {
        let popularContest = await this.getContest();

        this.setState({
            popularContests: popularContest,
            doneGettingPopularContest: true
        });
    }

    render() {
        const { popularContests, archivedContests, doneGettingPopularContest } = this.state;
        let headerLabel = this.props.archived ? 'Archived Contests':'Active Contests';
        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            autoplay: false,
            autoplaySpeed: 5000
        };

        return (<div className="container rni-contest-landing-container">
            <div className="col-md-12 col-xs-12 col-lg-12 col-sm-12">
                <SectionHeader label={headerLabel} src="/rni/gold-medal.png" />
                    {doneGettingPopularContest ? <div className="row">{
                        popularContests.map(function (contest, index) {
                            let alt = false;
                            if (index % 2 !== 0) {
                                alt = true;
                            }
                            return (<div key={index}
                                className="col-md-6 col-xs-12 col-sm-12 col-lg-6"
                                style={{ paddingBottom: '15px' }} >
                                <ContestListItem data={contest} alternate={alt} /> </div>
                            );
                        })
                    } </div>
                    : <div style={{ width: '50vw', margin: 'auto' }}><MiniLoading /></div>}
            </div> </div>
        )
    }
}