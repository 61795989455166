import React, { Component } from "react";
import moment from "moment";
import { IndexColor, checkExpirationToDate } from "../commons/service";
import NavLink from "react-router-dom/NavLink";
import { formatValues } from "../../../components/Mis/formatter";
import { getContestRank } from '../commons/restEndpoint';
import { decrypt } from '../../../commons/utility.js';
import Select, { components } from 'react-select';
// import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
// import ProgressProvider from '../commons/ProgressProvider';
// import VisibilitySensor from "react-visibility-sensor";

const customStyles = {
  menu: (provided, state) => ({ ...provided, zIndex: 100 }),
  option: (provided, state) => ({
      ...provided,
      fontWeight: state.isSelected ? "bold" : "normal",
      borderBottom: 'none',
      color: state.isSelected || state.isFocused ? '#006192' : '#414141',
      background: '#FFFFFF',
      fontSize: '16px',
      fontFamily: 'Allianz Neo',
      fontWeight: '400',
      padding: 15,
  }),
  indicatorSeparator: (provided, state) => ({
      opacity: 0,
  }),
  dropdownIndicator: (provided, state) => ({
      color: '#414141',
  })
}

export default class contestProgressItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: this.props.data ? this.props.data : {},
      activeFieldSort: {
        value: this.props.data.parameters[0].paramId,
        label: this.props.data.parameters[0].paramName
      },
      fieldSortType: [],
      rank: 0,
      alternate: this.props.alternate ? this.props.alternate : false,
      borderRadius: this.props.borderRadius,
      showDropdown: false
    };
    this.createLiners = this.createLiners.bind(this);
  }

  createLiners(width, amount) {
    var x = [];
    for (let ctr = 0; ctr < amount; ctr++) {
      x.push(width * ctr);
    }

    return x;
  }

  fetchActiveSort(){
    let sortType = [];
    let x = this.props.data.parameters.map((a) => {
      sortType.push({
        value: a.paramId,
        label: a.paramName
      })
    })

    let data = {
      activeFieldSort: {
        value: this.props.data.parameters[0].paramId,
        label: this.props.data.parameters[0].paramName
      },
      fieldSortType: sortType
    }

    return data;
  }

  async fetchRank(data){
    let params = {
      email: decrypt(this.props.email),
      paramId: data.activeFieldSort.value
    }
    let ranks = await getContestRank(this.props.data.contestId, params, decrypt(this.props.props.session.access_token));
    console.log(ranks)
    

    console.log(data)
    this.setState({
      rank: ranks.data.rank,
      fieldSortType: data.fieldSortType,
      activeFieldSort: data.activeFieldSort,
      showDropdown:false
    });
  }
  
  async changeFieldSort(value){
    let data = {
      activeFieldSort: value,
      fieldSortType: this.state.fieldSortType
    }

    let x = await this.fetchRank(data);
  }

  async componentWillMount(){
    let x = await this.fetchActiveSort();
    let y = await this.fetchRank(x)

  }

   

  render() {
    var thisCom = this;
    const { borderRadius, data, alternate, rank, showDropdown, activeFieldSort } = this.state;
    var expiration = this.state.data
      ? this.state.data.contestExpiryDt
        ? this.state.data.contestExpiryDt
        : 0
      : 0;
    expiration = moment(expiration * 1000);
    expiration = expiration.format("MMMM DD YYYY");
    if (expiration === "Invalid date") {
      expiration = null;
    }
    let currentAnp = data.totalAnp;
    let currentCC = data.totalCc;
    var widthPerTier = 0;

    let prefixExpiration = checkExpirationToDate(expiration);
    const stringExpiration = prefixExpiration
      ? "Ended last " + expiration
      : "Ends in " + expiration;

    return (
      <div>
          <div className="rni-leaderboard-list-profile-item-rank">
            <label className="rni-leaderboard-list-item-rank-profile-label" onClick={(e)=>{e.stopPropagation();this.setState({showDropdown: !showDropdown});}}>{rank}</label>
          </div>
        {
            showDropdown ?
            <Select
                        styles={customStyles}
                        value={this.state.activeFieldSort}
                        placeholder="Field Sort"
                        onChange={(e) => { this.changeFieldSort(e); }}
                        onClick={() => { }}
                        onBlur={() => { }}
                        //components= {{Option: CustomOption}}
                        options={this.state.fieldSortType}
                        className="rni-select-container-profile"
                    /> :''
          }
        <NavLink
          style={{ textDecoration: "none", color: "unset" }}
          to={"/rewards-and-incentives/contests?contestDetails=" + data.contestId}
        >
          <div>
          <div style={{ background: IndexColor(data.contestId), borderRadius: borderRadius ? borderRadius : "15px" }} className="rni-contest-list-item">
                <div style={{ background: "url(" + data.contestImg + ")", minHeight: "50px", borderRadius: borderRadius ? borderRadius : "" }} className="rni-contest-list-item-image"></div>
                <div style={{ width: "100%", marginBottom: "0px", position: "relative" }} className="rni-contest-list-item-labels">
                  <h3 style={{ textAlign: "left" }}>{data.contestTitle}</h3>
                  <div className="rni-contest-list-item-tier-list-container">
                    <div style={{ position: "relative" }}>
                      {data.tiersQualified > 0 ? <div className="rni-contest-tier-finished-status-collection">
                        <img src="/rni/gift.png" style={{ width: "35px", height: "35px" }} />
                        <div className="rni-contest-tier-finished-status-tier">
                        <label className="rni-contest-tier-finished-status-tier-label"> {data.tiersQualified} </label> 
                        <img src={"/rni/success.png"}
                          style={{
                            width: "15px",
                            height: "15px",
                            marginLeft: "3px",
                            marginRight: "3px"
                          }}
                        />
                        </div>
                      </div>: ''}
                      {data.tiersQualified > 0 ? <div className="rni-contest-tier-finished-status-collection">
                        <img src="/rni/gift.png" style={{ width: "35px", height: "35px" }} />
                        <div className="rni-contest-tier-finished-status-tier">
                        <label className="rni-contest-tier-finished-status-tier-label"> {data.tiersQualified} </label>  
                        <img src={"/rni/error.png"}
                          style={{
                            width: "15px",
                            height: "15px",
                            marginLeft: "3px",
                            marginRight: "3px"
                          }}
                        />
                        </div>
                      </div>:''}
                      {data.tiersQualified > 0 ? '':''}
                    </div>
                  </div>
                  { data.parameters.map(function(params,index) {
                    var formattedLabel = '';
                    var subProgressWidth = 0;
                    var liners = [];

                    formattedLabel = params.paramValue + " / " + params.goal + " " + params.paramName;
                    subProgressWidth = params.paramValue >= params.goal ? 100: (params.paramValue/params.goal) * 100;
                    liners = thisCom.createLiners(subProgressWidth, params.paramValue);
                      return(
                        <div key={index}>
                          <div className="rni-contest-progress-item-tier-progress-container">
                            <div className="rni-contest-progress-item-tier-subprogress-container">
                              <div className="rni-contest-progress-item-tier-subprogress-progress-container">
                              <div style={{ width: subProgressWidth + "%", overflow: "hidden" }} className="rni-contest-progress-item-tier-subprogress-progress"></div>
                            </div>
                            <label className="rni-contest-progress-formatted-label"> {formattedLabel}</label>
                          </div>
                        </div>
                        </div>
                      );
                    })
                  }
                  <div style={{ textAlign: "left" }}>
                    {
                      data.parameters.map(function(param,index) {
                        return(
                          <p style={{ fontSize: "14px" }}>Total {param.paramName}: <b>{param.paramValue}</b></p>
                        );
                      })
                    }
                  </div>
                  <p style={{ fontSize: "14px" }}>{stringExpiration}</p>
                </div>
              </div>
            
            {/* {alternate === false ? (
              <div style={{ background: IndexColor(data.contestId), borderRadius: borderRadius ? borderRadius : "15px" }} className="rni-contest-list-item">
                <div style={{ background: "url(" + data.contestImg + ")", minHeight: "50px", borderRadius: borderRadius ? borderRadius : "" }} className="rni-contest-list-item-image"></div>
                <div style={{ width: "100%", marginBottom: "0px", position: "relative" }} className="rni-contest-list-item-labels">
                  <h3 style={{ textAlign: "left" }}>{data.contestTitle}</h3>
                  { data.parameters.map(function(params,index) {
                    var formattedLabel = '';
                    var subProgressWidth = 0;
                    var liners = [];

                    formattedLabel = params.paramValue + " / " + params.goal + " " + params.paramName;
                    subProgressWidth = params.paramValue >= params.goal ? 100: (params.paramValue/params.goal) * 100;
                    liners = thisCom.createLiners(subProgressWidth, params.paramValue);
                      return(
                        <div key={index}>
                          <div className="rni-contest-progress-item-tier-progress-container">
                            <div className="rni-contest-progress-item-tier-subprogress-container">
                              <div className="rni-contest-progress-item-tier-subprogress-progress-container">
                              <div style={{ width: subProgressWidth + "%", overflow: "hidden" }} className="rni-contest-progress-item-tier-subprogress-progress"></div>
                            </div>
                            <label className="rni-contest-progress-formatted-label"> {formattedLabel}</label>
                          </div>
                        </div>
                        </div>
                      );
                    })
                  }
                  <div style={{ textAlign: "left" }}>
                    {
                      data.parameters.map(function(param,index) {
                        return(
                          <p style={{ fontSize: "14px" }}>Total {param.paramName}: <b>{param.paramValue}</b></p>
                        );
                      })
                    }
                  </div>
                  <p style={{ fontSize: "14px" }}>{stringExpiration}</p>
                </div>
              </div>
            ) : (
              <div
                style={{ background: IndexColor(this.state.data.contestId), borderRadius: borderRadius ? borderRadius : "15px" }} className="rni-contest-list-item-alternate">
                <div style={{ width: "100%", marginBottom: "0px", position: "relative" }} className="rni-contest-list-item-labels" >
                  <h3>{this.state.data.contestTitle}</h3>

                  
                  { data.parameters.map(function(params,index) {
                    var formattedLabel = '';
                    var subProgressWidth = 0;
                    var liners = [];

                    formattedLabel = params.paramValue + " / " + params.goal + " " + params.paramName;
                    subProgressWidth = params.paramValue >= params.goal ? 100: (params.paramValue/params.goal) * 100;
                    liners = thisCom.createLiners(subProgressWidth, params.paramValue);

                      return(
                        <div key={index}>
                          <div className="rni-contest-progress-item-tier-progress-container">
                            <div className="rni-contest-progress-item-tier-subprogress-container">
                              <div className="rni-contest-progress-item-tier-subprogress-progress-container">
                                <div style={{ width: subProgressWidth + "%", overflow: "hidden" }} className="rni-contest-progress-item-tier-subprogress-progress"></div>
                              </div>
                              <label className="rni-contest-progress-formatted-label">
                                {formattedLabel}
                              </label>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  }
                  <div style={{ textAlign: "right" }}>
                    {
                      data.parameters.map(function(param,index) {
                        return(
                          <p style={{ fontSize: "14px" }}>Total {param.paramName}: <b>{param.paramValue}</b></p>
                        );
                      })
                    }
                  </div>
                  <p style={{ fontSize: "14px" }}>{stringExpiration}</p>
                </div>
                <div style={{ background: "url(" + this.state.data.contestImg + ")", minHeight: "50px", borderRadius: borderRadius ? borderRadius : "" }} className="rni-contest-list-item-image-alternate" ></div>
                {/* <div className="rni-leaderboard-list-profile-item-rank-alternate">
                  <label className="rni-leaderboard-list-item-rank-profile-label">{rank}</label>
                </div> 
              </div>
            )} */}
          </div>
        </NavLink>
      </div>
    );
  }
}
