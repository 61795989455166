import React from 'react';
import withWindowDimensions from '../../../commons/withWindowDimensions';

const TextInput = (props) => {
    return (
        <div className={`custom-searchbar datatable-search__container form-control rounded-pill mr-md-2 ${props.isSm? 'mt-2': ''}`} style={{ flexBasis: window.innerWidth <= 600? '70%' : 'auto' }}>
            <input
                value={props.searchTerm}
                onChange={props.handleUpdateSearchTerm}
                className="searchbar__input datatable-search__input h-100"
                type="text"
                onKeyPress={props.handlePressEnter}
                placeholder="Search for"
            />
            <a className="btn searchbar__button searchbar__button--gray px-2">
                <span className="icon-search"></span>
            </a>
        </div>
    );
}

export default withWindowDimensions(TextInput);