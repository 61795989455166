import React, { Component } from 'react';
import './modal.scss';
import { apiModule } from "../../commons/apiCall";
import LoaderGif from '../../commons/loaderGif';

export default class Modal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false
        }
    }

    sendEmail = async () => {     
        this.setState({
            isLoading: true
        })   
        const headerParams = {
            x_auth: this.props.token
        }
        try {
            const email = await apiModule('send_email', headerParams, this.props.request, this.props.query, true);
            this.setState({
                isLoading: false
            })
            this.props.closeModal(email.status === 201 ? "success" : "failed");
        } catch(e) {
            this.setState({
                isLoading: false
            })
            this.props.closeModal("failed");
        }
        
        
    }

    render() {
        const { showModal, request } = this.props;

        return showModal ? (
            <div className="reports-modal-body">
                <div className="reports-modal-box">
                    <div className="reports-modal-content">
                        <div className="reports-modal-text">
                            Do you want to send the Billing Notice to <span className="email">{request.to[0]}</span>?
                        </div>
                        {
                            !this.state.isLoading &&
                            <div className="reports-modal-button">
                                <a
                                    className={`lh-1 d-flex flex-grow-0 mt-2 mt-md-0 justify-content-center align-items-center btn btn-dropdowncolor rounded-pill text-uppercase font-p80rem font-neo-semibold text-white`}
                                    onClick={this.sendEmail}>
                                    Yes
                                </a>
                                <a
                                    className={`lh-1 d-flex flex-grow-0 mt-2 mt-md-0 justify-content-center align-items-center btn btn-dropdowncolor rounded-pill text-uppercase font-p80rem font-neo-semibold text-white  ml-4`}
                                    onClick={() => this.props.closeModal("no")}>
                                    No
                                </a>
                            </div>
                        }                        
                        {
                            this.state.isLoading && <div><LoaderGif /></div>
                        }
                    </div>
                </div>
            </div>
        ) : ''
    }
}
