import React, { Component, Fragment } from 'react';
import Select from 'react-select';

class FormVerification extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    console.log(this.props)
  }

  render() {
    return (
      <Fragment>
        <h5 className="text-center mt-n5 mb-4">Review Transaction</h5>
        <div className="review-transaction__message">
          <p>
            Dear {this.props.policyOwner + ","}
          </p>
          <p>
            We at allianz PNB Life are grateful that you have allowed us the opportunity to be part of securing your future and to give you confidence in a better tomorrow.
          </p>
          <p>
            Please review the following details provided:
          </p>
          <div>
            <p className="mb-1">
              <b> Transaction Number:</b> {this.props.transactNo}
            </p>
            <p className="mb-1">
              <b> Policy Number:</b> {this.props.policyNumber}
            </p>
            <p className="mb-1">
              <b> Bank Name:</b> {this.props.bank.label}
            </p>
            <p className="mb-1">
              <b> Bank Branch/Address:</b> {this.props.bankAddress}
            </p>
            <p className="mb-1">
              <b> Bank Account Name:</b> {this.props.bankAccountLastName + ", " + this.props.bankAccountFirstName + " " + this.props.bankAccountMiddleInitial}
            </p>
            <p className="mb-1">
              <b> Bank Account Number:</b> {this.props.bankAccountNumber}
            </p>
            <p className="mb-1">
              <b> Bank Account Currency:</b> {this.props.bankCurrency.label}
            </p>
            <p className="mb-1">
              <b> Type of Bank Account:</b> {this.props.bankType.label}
            </p>
            {this.props.bankType.value == "JOINT_BANK_ACCT_OR" || this.props.bankType.value == "JOINT_BANK_ACCT_AND_OR" || this.props.bankType.value == "IN_TRUST_FOR"
              ? <p className="mb-1">
                <b> Co-Depositor Name:</b> {this.props.bankCoDepositorName}
              </p>
              : null}

            <p className="mb-1">
              <b> Apply all fund transfer details to all policies:</b> {this.props.applyToAllPolicies ? "Yes" : "No"}
            </p>
          </div>
        </div>
      </Fragment >
    )
  }
}

export default FormVerification;