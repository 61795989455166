import React, { Component } from 'react';
import { formatValues } from '../../../components/Mis/formatter';
import ProgressProvider from '../commons/ProgressProvider';

export default class leaderboardListItem extends Component {
    constructor(props) {
        super(props)

        this.state = {
            data: {
                //id: props.data.id ? props.data.id : 0,
                name: props.data.name, //&& `${props.data.user.firstName} ${props.data.user.middleName || ''} ${props.data.user.lastName}` || `${props.data.branch && props.data.branch.name || 'Unknown Name'}`,
                picture: '/rni/man.png', //props.data.user && props.data.user.imgUrl ? props.data.user.imgUrl : '/rni/man.png',
                paramTotal: this.props.data.params.filter((a) => { return a.paramName === this.props.mode })[0].paramValue,
                paramGoal: this.props.data.params.filter((a) => { return a.paramName === this.props.mode })[0].goal,
                //totalAnp: props.data.params.paramValue ? props.data.params.paramValue : 0,
                totalCc: props.data.params.paramValue ? props.data.params.paramValue : 0,
                goalAnp: props.data.goalAnp ? props.data.goalAnp : 0,
                goalCc: props.data.goalCc ? props.data.goalCc : 0,
                //max: props.data.max ? props.data.max : 0,
                rank: props.data.rank ? props.data.rank : 0,
                paramsText: ''
            },
            mode: props.mode ? props.mode : '' // ANP or CC
        }
    }

    componentWillMount() {
        let paramsText = '';
        let appendParams = this.props.data.params.filter((a) => { return a.paramName !== this.props.mode });
        appendParams = appendParams.map((a) => {
            return a.paramText = formatValues(a.paramValue) + ' ' + a.paramName
        })

        this.setState({
            paramsText: appendParams.join(' ')
        })
    }

    render() {
        var { data, mode } = this.state;
        var width = 0;
        width = data.paramTotal < data.paramGoal ? (data.paramTotal / data.paramGoal) * 100 : 100;

        return (
            <div className="rni-leaderboard-list-item-container">
                <div className="rni-leaderboard-list-item-rank">
                    <label>{data.rank}</label>
                </div>
                <img className="rni-leaderboard-list-item-picture" src={data.picture} />
                <p className="rni-leaderboard-list-item-p">
                    {data.name}
                </p>
                <div style={{ paddingLeft: '25px' }}>
                    <div className="rni-leaderboard-list-item-progress">
                        <label style={{ right: 'unset', left: '25px' }} className="rni-leaderboard-list-item-progress-labels">
                            {formatValues(data.paramTotal)}&nbsp;{mode}&nbsp;&#8226;&nbsp;{this.state.paramsText}
                        </label>
                        <ProgressProvider valueStart={0} valueEnd={width}>
                            {(value) => <div style={{ width: value + '%' }} className="rni-leaderboard-list-item-actual-progress">
                            </div>}
                        </ProgressProvider>
                    </div>
                </div>
            </div>
        )
    }
}
