import React, { Component } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import NavLink from 'react-router-dom/NavLink';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Redirect } from 'react-router-dom';

export default class contestBanner extends Component {

    constructor(props) {
        super(props)
        this.state = {
            contestId: -1,
            mouseDown: [0, 0],
            mouseUp: [1, 1]
        }

        this.clickedMouse = this.clickedMouse.bind(this);
    }

    clickedMouse(key, e, contestId) {
        this.setState({
            [key]: [e.screenX, e.screenY],
            contestId: contestId
        })
    }

    render() {
        const { bannerList } = this.props;
        const { contestId, mouseDown, mouseUp } = this.state;
        const imgClicked = (mouseDown[0] === mouseUp[0]) && (mouseDown[1] === mouseUp[1]);

        let that = this;
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            autoplay: true,
            autoplaySpeed: 5000
        };

        return Array.isArray(bannerList) && bannerList.length != 0 ? (
            <div className="rni-contest-banner">
                <Slider {...settings}>
                    {
                        bannerList.map(function (bannerObj, bannerIndex) {
                            return (
                                <NavLink key={'banner-' + bannerIndex} className="rni-contest-banner-body" to={imgClicked ? '/rewards-and-incentives/contests?contestDetails=' + contestId : '#'}>
                                    <img src={bannerObj.imgUrl} className="rni-contest-banner-img" onMouseDown={(e) => { that.clickedMouse('mouseDown', e, bannerObj.contest.id) }} onMouseUp={(e) => { that.clickedMouse('mouseUp', e, bannerObj.contest.id) }} />
                                </NavLink>
                            )
                        })}
                </Slider>
            </div>
        ) :
            <div className="rni-empty-contest">NO CONTESTS AVAILABLE</div>
    }
}
