import React, { Component } from 'react';
import { haveValue } from '../../../commons/misConfig';
import ContestListItem from './contestListItem';
import { IndexColor } from '../commons/service';
import MiniLoading from './miniLoading';
import VisibilitySensor from 'react-visibility-sensor';

class SearchContest extends Component {

    constructor(props) {
        super(props)

        this.formatData = this.formatData.bind(this);
    }

    formatData(result, index) {
        let data = {
            header: result.title,
            description: result.description,
            expiration: result.expDt,
            joinedCount: '',
            background: IndexColor(result.id),
            color: '#000',
            image: result.imgUrl,
            contestId: result.id,
        }

        return data;
    }

    render() {
        const { searchResult, isLoading, standalone } = this.props;
        let that = this;
        return !isLoading ? (
            <div className="row">
                {
                    haveValue(searchResult.content) ? searchResult.content.map(function (contest, index) {
                        let alt = false;
                        if (index % 2 !== 0) {
                            alt = true;
                        }

                        return (<div key={index}
                            className="col-md-6 col-xs-12 col-sm-12 col-lg-6"
                            style={{ paddingTop: '15px', paddingBottom: '15px' }} >
                            <ContestListItem data={that.formatData(contest, index)} alternate={alt} /> </div>
                        );
                    }) : <p style={{ width: '100%', textAlign: 'center', fontSize: '2rem', color: 'darkgray', fontFamily: 'neo_regular' }}>No Contest Found</p>
                }
                {standalone && haveValue(searchResult.content) ? <VisibilitySensor delayedCall={true} onChange={(e) => {
                    if (e) { this.props.nextPage(); }
                }}>
                    <div className="col-md-12 col-xs-12 col-sm-12 col-lg-12">
                        <p style={{
                            textAlign: 'center',
                            fontSize: '2rem',
                            fontWeight: '800',
                            marginBottom: '0px'
                        }}>. . .</p>
                    </div>
                </VisibilitySensor> : ""}
            </div>
        ) : <MiniLoading />
    }
}

export default SearchContest;