import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
// import Lottie from 'lottie-react-web';
// import rniLoader from '../../../assets/animation/blue-medal.json';

export default class ContestInProgress extends Component {
    render() {
        return (
            <div className="rni-contest-progress-icon">
                {/* <img src="/rni/contest-in-progress-2.png" alt='' />
                <p className="rni-under-construcion-header">Contest Under Construction!</p>
                <p className="rni-under-construcion-desc">We are working on something awesome! Stay tuned!</p> */}
                <img src="/rni/blue-medal.png" style={{ width: '100%', height: '350px', objectFit: 'contain' }} />
                {/* <div style={{ width: '100%', height: '50vh' }}>
                <Lottie speed={1} options={{ animationData: rniLoader }} />
                </div> */}
                <h5 style={{ color: "#414141", fontFamily: 'neo_regular' }}>Something exciting is coming your way!<br /><b>Stay tuned.</b></h5>
            </div>
        )
    }
}
