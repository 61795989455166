import { myConfig } from "../../../commons/config.js";
import { isEmpty } from '../commons/service';
import { distanceDate, checkSearch, writeData, readAllData, encrypt, decrypt } from '../../../commons/utility.js';
import axios from 'axios';

const urlRIA = myConfig.SERVER_URL.replace("/zuul/sp", "/fna");

function paramsToString(obj) {
    let str = [];
    let returnString = "";
    for (var p in obj)
        if (obj.hasOwnProperty(p)) {
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }

    if (str.length > 0) {
        returnString = "?" + str.join("&");
    }
    return returnString;
}

export async function getContestList(params, Authorization) {
    let call = axios({
        method: 'GET',
        url: urlRIA + '/contests/list' + paramsToString(params),
        headers: {
            "Accept": "application/json",
            "Authorization": Authorization
        },
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            return null;
        })
    const [response] = await Promise.all([call]);
    writeData('actions', { last_action: Date.now(), id: 1 });

    return response;
}

export async function getBulletinDetails(bulletinId, Authorization) {
    let call = axios({
        method: 'GET',
        url: urlRIA + '/bulletins/' + bulletinId,
        headers: {
            "Accept": "application/json",
            "Authorization": Authorization
        },
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            return null;
        })
    const [response] = await Promise.all([call]);
    writeData('actions', { last_action: Date.now(), id: 1 });

    return response;
}

export async function getBulletinList(params, Authorization) {
    let call = axios({
        method: 'GET',
        url: urlRIA + '/home/bulletin/list' + paramsToString(params),
        headers: {
            "Accept": "application/json",
            "Authorization": Authorization
        },
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            return null;
        })
    const [response] = await Promise.all([call]);
    writeData('actions', { last_action: Date.now(), id: 1 });

    return response;
}

export async function getContest(params, Authorization, body) {
    let call = axios({
        method: 'GET',
        url: urlRIA + '/contests' + paramsToString(params),
        headers: {
            "Accept": "application/json",
            "Authorization": Authorization
        },
        data: body
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            return null;
        })
    const [response] = await Promise.all([call]);
    writeData('actions', { last_action: Date.now(), id: 1 });

    return response;
}

export async function getLeaderboardListContestId(contestId, params, Authorization) {
    let call = axios({
        method: 'GET',
        url: urlRIA + '/leaderboards/' + contestId + paramsToString(params),
        headers: {
            "Accept": "application/json",
            "Authorization": Authorization
        },
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            return null;
        })
    const [response] = await Promise.all([call]);
    writeData('actions', { last_action: Date.now(), id: 1 });

    return response;
}

export async function getHierarchies(contestId, Authorization) {
    let call = axios({
        method: 'GET',
        url: urlRIA + '/leaderboards/'+ contestId + '/hierarchy/list',
        headers: {
            "Accept": "application/json",
            "Authorization": Authorization
        },
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            return null;
        })
    const [response] = await Promise.all([call]);
    writeData('actions', { last_action: Date.now(), id: 1 });

    return response;
}

export async function getContestDetails(contestId, Authorization) {
    let call = axios({
        method: 'GET',
        url: urlRIA + '/contests/' + contestId,
        headers: {
            "Accept": "application/json",
            "Authorization": Authorization
        },
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            return null;
        })
    const [response] = await Promise.all([call]);
    writeData('actions', { last_action: Date.now(), id: 1 });

    return response;
}

export async function getContestUnderCategory(categoryId, params, Authorization) {
    let call = axios({
        method: 'GET',
        url: urlRIA + '/contests/categories/' + categoryId + paramsToString(params),
        headers: {
            "Accept": "application/json",
            "Authorization": Authorization
        },
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            return null;
        })
    const [response] = await Promise.all([call]);
    writeData('actions', { last_action: Date.now(), id: 1 });

    return response;
}

export async function getContestBanners(Authorization) {
    let call = axios({
        method: 'GET',
        url: urlRIA + '/home/banners',
        headers: {
            "Accept": "application/json",
            "Authorization": Authorization
        },
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            return null;
        })
    const [response] = await Promise.all([call]);
    writeData('actions', { last_action: Date.now(), id: 1 });

    return response;
}

export async function getUserProgress(email, Authorization) {
    let call = axios({
        method: 'GET',
        url: urlRIA + '/contests/progress/user?email=' + email,
        headers: {
            "Accept": "application/json",
            "Authorization": Authorization
        },
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            return null;
        })
    const [response] = await Promise.all([call]);
    writeData('actions', { last_action: Date.now(), id: 1 });

    return response;
}

export async function getUserInformation(email, Authorization) {
    let call = axios({
        method: 'GET',
        url: urlRIA + '/profile?email=' + email,
        headers: {
            "Accept": "application/json",
            "Authorization": Authorization
        },
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            return null;
        })
    const [response] = await Promise.all([call]);
    writeData('actions', { last_action: Date.now(), id: 1 });

    return response;
}

export async function submitSupportTicket(message, subject, type, Authorization) {
    let call = axios({
        method: 'POST',
        url: urlRIA + '/support/',
        headers: {
            "Accept": "application/json",
            "Authorization": Authorization
        },
        data: {
            message: message,
            subject: subject,
            type: type,
        },
    })
        .then(function (response) {
            return true;
        })
        .catch(function (error) {
            return false;
        })
    const [response] = await Promise.all([call]);
    writeData('actions', { last_action: Date.now(), id: 1 });

    return response;
}

export async function searchRNI(params, auth, tab) {
    let call = axios({
        method: 'GET',
        url: urlRIA + '/search' + tab + params,
        headers: {
            "Accept": "application/json",
            "Authorization": auth
        },
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            return { data: null };
        })
    const [response] = await Promise.all([call]);
    writeData('actions', { last_action: Date.now(), id: 1 });

    return response;
}

export async function getRegionList(Authorization) {
    let call = axios({
        method: 'GET',
        url: urlRIA + '/hierarchies/regions',
        headers: {
            "Accept": "application/json",
            "Authorization": Authorization
        },
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            return null;
        })
    const [response] = await Promise.all([call]);
    writeData('actions', { last_action: Date.now(), id: 1 });

    return response;
}

export async function getBranchLists(regionId, Authorization) {
    let call = axios({
        method: 'GET',
        url: urlRIA + '/hierarchies/branches/' + regionId,
        headers: {
            "Accept": "application/json",
            "Authorization": Authorization
        },
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            return null;
        })
    const [response] = await Promise.all([call]);
    writeData('actions', { last_action: Date.now(), id: 1 });

    return response;
}

export async function getViewRoles(contestId, params, Authorization) {
    let call = axios({
        method: 'GET',
        url: urlRIA + '/leaderboards/' + contestId + '/views/list' + paramsToString(params),
        headers: {
            "Accept": "application/json",
            "Authorization": Authorization
        },
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            return null;
        })
    const [response] = await Promise.all([call]);
    writeData('actions', { last_action: Date.now(), id: 1 });

    return response;
}

export async function getViewRewards(params, Authorization) {
    let call = axios({
        method: 'GET',
        url: urlRIA + '/reward' + paramsToString(params),
        headers: {
            "Accept": "application/json",
            "Authorization": Authorization
        },
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            return null;
        })
    const [response] = await Promise.all([call]);
    writeData('actions', { last_action: Date.now(), id: 1 });

    return response;
}

export async function getSort(contestId, params, Authorization) {
    let call = axios({
        method: 'GET',
        url: urlRIA + '/leaderboards/'+ contestId + '/sort/list' + paramsToString(params),
        headers: {
            "Accept": "application/json",
            "Authorization": Authorization
        },
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            return null;
        })
    const [response] = await Promise.all([call]);
    writeData('actions', { last_action: Date.now(), id: 1 });

    return response;
}

export async function getSubcontest(contestId, Authorization) {
    let call = axios({
        method: 'GET',
        url: urlRIA + '/leaderboards/'+ contestId + '/subcontest/list',
        headers: {
            "Accept": "application/json",
            "Authorization": Authorization
        },
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            return null;
        })
    const [response] = await Promise.all([call]);
    writeData('actions', { last_action: Date.now(), id: 1 });

    return response;
}

export async function getContestRank(contestId, params, Authorization) {
    let call = axios({
        method: 'GET',
        url: urlRIA + '/contests/'+ contestId + '/user/rank' + paramsToString(params),
        headers: {
            "Accept": "application/json",
            "Authorization": Authorization
        },
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            return null;
        })
    const [response] = await Promise.all([call]);
    writeData('actions', { last_action: Date.now(), id: 1 });

    return response;
}