import React, { Component } from 'react'
import rniLoader from '../../../assets/animation/rni-loader.json';
import Lottie from 'lottie-react-web';

export default class loading extends Component {
    render() {
        return (
            <div style={{ position: 'fixed', left: '0', top: '0', width: '100vw', height: '100vh', zIndex: '5' }}>
                <Lottie speed={1.15} options={{ animationData: rniLoader, loop: true }} />
            </div>
        )
    }
}
