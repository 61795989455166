import React, { Component } from 'react';
import withWindowDimensions from '../../commons/withWindowDimensions';
import { withRouter, Redirect } from 'react-router-dom';
import './css/rnistyles.scss';
import { scaleDown as Menu } from 'react-burger-menu'
import Home from './module/home';
import Profile from './module/profile';
import Leaderboard from './module/leaderboard';
import Contest from './module/contest';
import Bulletin from './module/bulletin';
import NavLink from 'react-router-dom/NavLink';
import SidebarProfile from './components/sidebarProfile';
import Sidebar from './components/sidebar';
import { writeData } from '../../commons/utility';
import { getSessionInfo, checkPermissions } from '../../commons/security';
import PageNotFound from './module/pagenotfound';
import { rniLogoutF, SEARCH_TAB } from './commons/service';
import LogoutModal from './components/logoutModal';
import { Drawer } from '@material-ui/core';
import Support from './module/support';
import Search from './module/search';
import { searchRNI } from './commons/restEndpoint';
import { createQueryStrings } from '../../commons/apiCall';
import { isThisSecond } from 'date-fns';
import { RewardsCatalog } from './module/rewardsCatalog';
import ArchivedContest from './module/archivedContest';

class handler extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            openLeaderboard: false,
            isRIA: true,
            module: props.match.params.name,
            showLogout: false,
            isRIAOnly: true,
            riaPermissions: ["VIEW_UP", "UPDATE_UP", "ACCESS_REWARDS_AND_INCENTIVES"],
            searchValue: "",
            searchResult: {
                content: [],
                userResult: {
                    size: 0,
                    users: []
                },
                bulletinResult: {
                    size: 0,
                    bulletin: []
                },
                contestResult: {
                    size: 0,
                    contests: []
                }
            },
            currentPage: 0,
            size: 100,
            maxPage: 100,
            clickedSearched: false,
            navList: [{
                value: SEARCH_TAB.ALL,
                label: 'All'
            },
            // {
            //     value: SEARCH_TAB.BULLETIN,
            //     label: 'Bulletin'
            // },
            {
                value: SEARCH_TAB.CONTEST,
                label: 'Contests'
            },
            {
                value: SEARCH_TAB.USER,
                label: 'People'
            }],
            activeTab: SEARCH_TAB.ALL,
            ...props,
            isLoading: false,
            justLoadMore: false,
        }

        this.getActiveModule = this.getActiveModule.bind(this);
        this.closeSidebar = this.closeSidebar.bind(this);
        this.openSidebar = this.openSidebar.bind(this);
        this.checkIfRIA = this.checkIfRIA.bind(this);
        this.initiateSearch = this.initiateSearch.bind(this);
        this.changeSearchValue = this.changeSearchValue.bind(this);
        this.fetchResult = this.fetchResult.bind(this);
        this.clickedTab = this.clickedTab.bind(this);
        this.clearAllData = this.clearAllData.bind(this);
        this.nextPage = this.nextPage.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        this.checkIfRIA(nextProps)
    }

    componentDidMount() {
        this.checkIfRIA(this.props)
        // setInterval(function () {
        //     writeData('actions', { last_action: Date.now(), id: 1 });
        // }, 1000);
    }

    async checkIfRIA(props) {
        let permissionList = await getSessionInfo();
        if(!permissionList[0]){
            window.location = "/";
        }
        let isRIA = await checkPermissions(["ACCESS_REWARDS_AND_INCENTIVES"], permissionList[0].permissions);
        let isRIAOnly = permissionList[0].permissions ? true : false;

        for (let permission of permissionList[0].permissions) {
            if (!this.state.riaPermissions.includes(permission)) {
                isRIAOnly = false;
            }
        }
        this.setState({
            isRIA: isRIA,
            isRIAOnly: isRIAOnly,
            module: props.match.params.name,
            ...props
        })

        this.clickedLogout = this.clickedLogout.bind(this);
    }

    openSidebar() {
        this.setState({
            isOpen: true,
            openLeaderboard: false
        });
    }

    closeSidebar() {
        this.setState({
            isOpen: false,
            openLeaderboard: false
        });
    }

    getActiveModule(module, props, sessionState, state) {
        let activeComponent;
        switch (module) {
            case 'home':
                activeComponent = <Home {...props} session={sessionState} />;
                break;
            case 'contests':
                activeComponent = <Contest {...props} session={sessionState} archived= {false}/>;
                break;
            case 'archivedContests':
                activeComponent = <ArchivedContest {...props} session={sessionState} archived= {true}/>;
                break;  
            case 'leaderboard':
                activeComponent = <Leaderboard {...props} session={sessionState} />;
                break;
            case 'performance':
                activeComponent = <Profile {...props} closeSidebar={this.closeSidebar} session={sessionState} />;
                break;
            case 'bulletin':
                activeComponent = <Bulletin {...props} session={sessionState} />;
                break;
            case 'support':
                activeComponent = <Support {...props} session={sessionState} />;
                break;
            case 'search':
                activeComponent = <Search maxPage={this.state.maxPage} nextPage={this.nextPage} {...props} clickedTab={this.clickedTab} {...state} session={sessionState} closeSidebar={this.closeSidebar} />;
                break;
            case 'rewards':
                activeComponent = <RewardsCatalog {...props} session={sessionState} />;
                break;
            default:
                activeComponent = <PageNotFound {...props} session={sessionState} />
        }

        return activeComponent;
    }

    clickedLogout(value) {
        this.setState({
            showLogout: value ? false : true
        })
    }

    handleDrawerToggle = () => {
        this.setState(state => ({ isOpen: !state.isOpen }));
    };

    initiateSearch() {
        this.setState({
            clickedSearched: true,
            isLoading: true
        });

        this.fetchResult(this.state.activeTab);
    }

    changeSearchValue(e) {
        this.setState({
            searchValue: e.target.value,
        });
    }

    clickedTab(tabItem) {
        let { isLoading } = this.state;
        if (isLoading === false) {
            this.setState({
                activeTab: tabItem,
                isLoading: true,
                justLoadMore: false,
                currentPage: 0,
                maxPage: 100,
            })
            this.fetchResult(tabItem, false);
        }
    }

    nextPage() {
        let { isLoading, activeTab, size, currentPage, maxPage } = this.state;
        if (isLoading === false && ((currentPage + 1) < maxPage)) {
            this.setState({
                activeTab: activeTab,
                isLoading: true,
                currentPage: currentPage + 1,
                justLoadMore: true,
            })
            this.fetchResult(activeTab, true);
        }
    }

    async fetchResult(currentTab, justLoadMore) {
        let { searchValue, currentPage, size, activeTab, searchResult } = this.state;
        const session = this.props.getSession();
        let params = [];
        params['term'] = searchValue;
        params['page'] = justLoadMore ? currentPage : 0;
        params['size'] = size;
        let tabToSearch = currentTab !== undefined || currentTab !== null ? currentTab.toLowerCase() : activeTab.toLowerCase();
        let result = await searchRNI(createQueryStrings(params), session.access_token, tabToSearch);
        if (result) {
            if (result.data) {
                var x = result.data;
                var oldResult = searchResult;
                if (justLoadMore) {
                    if (x.content) {
                        x.content.map(function (item, index) {
                            oldResult.content.push(item);
                            return null;
                        });
                    }
                    x = oldResult;
                }
                this.setState({
                    searchResult: x,
                    isLoading: false,
                    justLoadMore: false,
                    maxPage: result.data ? result.data.totalPages ? result.data.totalPages : 0 : 0
                });
            } else {
                this.setState({
                    isLoading: false,
                    justLoadMore: false,
                });
            }
        } else {
            this.setState({
                isLoading: false,
                justLoadMore: false,
            });
        }
    }

    clearAllData() {

    }

    render() {
        const { module, isOpen, isRIA, showLogout, isRIAOnly } = this.state;
        writeData('actions', { last_action: Date.now(), id: 1 });
        const sidebarCollapse = (isOpen ? '' : 'sidebar-collapse')
        const sidebarOpen = ((window.innerWidth < 768) && isOpen) ? 'sidebar-open' : '';
        var session_result = this.props.getSession();
        const noSession = session_result !== null && session_result !== undefined && session_result.length > 0 && session_result[0].username !== "";
        if (noSession) {
            return (<Redirect to="/" />);
        }

        return isRIA ? (
            <div className={`RewardsAndIncentivesHandler sidebar-mini ${sidebarCollapse} ${sidebarOpen}`} id="outer-container">
                {showLogout ? <LogoutModal clickedLogout={this.clickedLogout} /> : null}
                <Sidebar
                    handleDrawerToggle={this.handleDrawerToggle}
                    isOpen={isOpen}
                    module={module}
                    openSidebar={this.openSidebar}
                    closeSidebar={this.closeSidebar}
                    changeSearchValue={this.changeSearchValue}
                    initiateSearch={this.initiateSearch}
                    clickedTab={this.clickedTab}
                    clearAllData={this.clearAllData}
                    {...this.props}
                    {...this.state}>
                    <SidebarProfile session={this.props.getSession()} closeSidebar={this.closeSidebar} isOpen={isOpen} />
                    <ul className="sidebar-menu not-draggable" data-widget="tree" onClick={(e) => { e.stopPropagation(); }}>
                        <li className={module === "home" ? "activePageLiBG" : " rni-sidebar-list"}><NavLink onClick={this.closeSidebar} id="home" className="menu-item" to="/rewards-and-incentives/home"><img src="/rni/homepage.png" className="rni-sidebar-item-icon" alt='' /><span>Home</span></NavLink></li>
                        <li className={module === "contests" ? "activePageLiBG" : " rni-sidebar-list"}><NavLink onClick={this.closeSidebar} id="contests" className="menu-item" to="/rewards-and-incentives/contests"><img src="/rni/contest-icon.png" className="rni-sidebar-item-icon" alt='' /><span>Contests</span></NavLink></li>
                        <li className={module === "archivedContests" ? "activePageLiBG" : " rni-sidebar-list"}><NavLink onClick={this.closeSidebar} id="archivedContests" className="menu-item" to="/rewards-and-incentives/archivedContests"><img src="/rni/archived.png" className="rni-sidebar-item-icon" alt='' /><span>Archived Contests</span></NavLink></li>
                        <li className={module === "bulletin" ? "activePageLiBG" : " rni-sidebar-list"}><NavLink onClick={this.closeSidebar} id="bulletin" className="menu-item" to="/rewards-and-incentives/bulletin"><img src="/rni/board.png" className="rni-sidebar-item-icon" alt='' /><span>Bulletin</span></NavLink></li>
                        <li className={module === "leaderboard" ? "activePageLiBG" : " rni-sidebar-list"}><NavLink onClick={this.closeSidebar} id="leaderboard" className="menu-item" to="/rewards-and-incentives/leaderboard"><img src="/rni/leaderboard-icon.png" className="rni-sidebar-item-icon" alt='' /><span>Leaderboard</span></NavLink></li>
                        <li className={module === "rewards" ? "activePageLiBG" : " rni-sidebar-list"}><NavLink onClick={this.closeSidebar} id="rewards" className="menu-item" to="/rewards-and-incentives/rewards"><img src="/rni/rewards-icon.png" className="rni-sidebar-item-icon" alt='' /><span>Rewards</span></NavLink></li>
                        <li className={module === "support" ? "activePageLiBG" : " rni-sidebar-list"}><NavLink onClick={this.closeSidebar} id="support" className="menu-item" to="/rewards-and-incentives/support"><img src="/rni/conversation.png" className="rni-sidebar-item-icon" alt='' /><span>Contact Us</span></NavLink></li>
                        {isRIAOnly ?
                            <li className={module === "logout" ? "activePageLiBG" : " rni-sidebar-list"}><NavLink onClick={this.closeSidebar} id="logout" className="menu-item" onClick={() => { this.clickedLogout(false) }} to="#"><img src="/rni/logout.png" className="rni-sidebar-item-icon" alt='' /><span>Logout</span></NavLink></li> :
                            <li className={module === "assist" ? "activePageLiBG" : " rni-sidebar-list"}><NavLink onClick={this.closeSidebar} id="assist" className="menu-item" to="/dashboard/dashboard"><img src="/rni/allianz-logo-white.png" className="rni-sidebar-item-icon" alt='' /><span>Back to Assist</span></NavLink></li>}
                    </ul>
                </Sidebar>
                <div id="page-wrap" className="content-wrapper content-wrapper-override" onClick={this.closeSidebar}>
                    <div className="rni-main-content">
                        {this.getActiveModule(module, this.props, this.props.getSession(), this.state)}
                    </div>
                </div>
            </div>
        ) : <Redirect to='/' />
    }
}

export default withRouter(withWindowDimensions(handler));