import React, { Component, Fragment } from 'react';
import { ReportLoader } from '../commons/utilityViews.js';
import { withRouter, Route, Router } from 'react-router-dom';
import 'react-vertical-timeline-component/style.min.css';
import { apiModule, createQueryStrings } from '../commons/apiCall';
import Search from '../components/AgentTransactionList/Search';
import Nav from '../components/AgentPolicyList/Nav';
import withWindowDimensions from '../commons/withWindowDimensions';
import { prettifyDate, decrypt } from '../commons/utility.js';
import Transactions from '../components/AgentTransactionList/Transactions';
import { myConfig } from '../commons/config.js';
import moment from "moment";

const defaultSelectedStatus = { label: "ALL", value: "all" };
class AgentTransactionHistory extends Component {
    state = {
        // Permissions and page loading
        isLoading: true,

        // Search
        searchTerm: "",
        searchFilter: { label: "All", value: "all" },
        selectedStatus: defaultSelectedStatus,
        statusList: [],

        // Table
        isTableLoading: true,
        showModal: false,
        tableData: [],

        // Pagination
        currentPage: 1,
        pages: 0,
        totalSize: 0,

        // Utility
        errorMessage: "",

        // Actions
        hasSearched: false,
    }

    constructor(props) {
        super(props);
    }

    // Component lifecycle methods
    async componentDidMount() {
        this.setState({
            isLoading: true,
            isTableLoading: true,
        });
        try {
            await this.fetchTransactions();
            this.setState({ isTableLoading: false, isLoading: false });
        } catch (e) {
            this.displayError(myConfig.GENERIC_ERROR_MESSAGE)
        }

        try {
            await this.fetchStatuses();
        } catch (e) {
            console.log(e);
            this.displayError(myConfig.GENERIC_ERROR_MESSAGE)
        }

        this.setState({ isLoading: false });
    }

    setSearchQuery = () => {
        const equivalentValue = {
            polNo: "contractNumber",
            firstName: "contractHolderFirstName",
            lastName: "contractHolderLastName",
            transNum: "transactionId",
            status: "status",
            all: "",
        }
        let selectedStatus = this.state.selectedStatus.value;
        const searchFilter = { ...this.state.searchFilter };
        const searchTerm = `${this.state.searchTerm}`;
        let query = {};
        if (searchFilter.value === "status" && selectedStatus !== "all") {
            query = {
                [equivalentValue[searchFilter.value]]: selectedStatus
            }
        }
        if (searchFilter && searchTerm) {
            query = {
                [equivalentValue[searchFilter.value]]: searchTerm
            }
        }

        return query;
    }

    fetchStatuses = async () => {
        let access_token = this.props.getSession().access_token;
        let headerParams = { x_auth: access_token };
        const res = await apiModule('list_tran_status', headerParams, null)
        console.log("check statuses", res);
        let statusList = [];
        if (res && Object.keys(res).length > 0) {
            statusList = Object.keys(res).map(statusKey => {
                return { label: res[statusKey], value: statusKey }
            })
        }

        this.setState({ statusList: [defaultSelectedStatus, ...statusList] });
    }

    fetchTransactions = async () => {
        this.setState({
            isTableLoading: true,
        });
        this.hideError();

        let searchQuery = this.setSearchQuery();
        let access_token = this.props.getSession().access_token;
        let headerParams = { x_auth: access_token };
        const paramsObject = {
            pageSize: myConfig.PAGE_SIZE,
            pageNumber: this.state.currentPage,
            agentNumber: decrypt(this.props.getSession().agentNumber),
            ...searchQuery
        }
        let data = [];
        const res = await apiModule('get_user_transactions', headerParams, null, createQueryStrings(paramsObject))
        const response = res;
        const contentResponse = this.modifiedValue(response.content, []);
        let status = 200;
        status = this.modifiedValue(response.status, status);
        if (status !== 200) {
            // Display error message if status is not 200
            this.displayError(response.message);
        } else if (response && Object.keys(response).length > 0) {
            this.setState({
                pages: response.pageCount,
                totalSize: response.totalSize,
            })
            data = contentResponse.map(trans => {
                const contractNumber = this.modifiedValue(trans.contractNumber, "--");
                const status = this.modifiedValue(trans.status, "--");
                const transactionId = this.modifiedValue(trans.transactionId, "--");
                const transactionDateTime = trans.transactionDateTime ? moment(trans.transactionDateTime).format("YYYY-MM-DD h:mmA") : "--";
                const ownerName = this.modifiedValue(trans.ownerName, "--");
                const insuredName = this.modifiedValue(trans.insuredName, "--");
                const createdBy = this.modifiedValue(trans.initiatedBy, "--");
                const transactionType = this.modifiedValue(trans.transactionType, "--");
                const verificationType = this.modifiedValue(trans.verificationType, "--");
                const isApplyToAllPolicies = trans.isApplyToAllPolicies === "Y" ? "YES" : "NO";
                const contactChannel = this.modifiedValue(trans.contactChannel, [{
                    code: "--",
                    type: "--",
                    remarks: "--",
                    value: "--",
                }])
                const emailChannel = contactChannel.filter((channel) => channel.type.toLowerCase() === "change in email address")
                const emails = this.sortRemarkValues(emailChannel);
                const emailRemarks = this.remarksValue(emails);
                const mobileChannel = contactChannel.filter((channel) => channel.type.toLowerCase() === "change in mobile")
                const mobileNums = this.sortRemarkValues(mobileChannel)
                const mobileRemarks = this.remarksValue(mobileNums);
                const remarks = transactionType === "FUND TRANSFER AGREEMENT" ? ["Fund transfer agreement request"] : [mobileRemarks, emailRemarks];
                return {
                    status,
                    transNum: transactionId,
                    transDateTime: transactionDateTime,
                    policyNum: contractNumber,
                    ownerName,
                    insuredName,
                    createdBy,
                    transType: transactionType,
                    apply: isApplyToAllPolicies,
                    remarks,
                    verificationType
                }
            })
        }

        this.setState({
            tableData: [...data],
            isTableLoading: false,
        })

    }

    remarksValue = (value) => {
        let modifiedValue = value.length > 1 ? `From ${value.join(" to ")}` : "";
        return modifiedValue;
    }

    modifiedValue = (value, backupValue) => {
        let modifiedValue = value || backupValue;
        return modifiedValue;
    }

    sortRemarkValues = (channels) => {
        let sortedRemarks = [];
        if (channels.length === 2) {
            const oldChannel = channels.find((channel) => channel.remarks.toLowerCase() === "old");
            sortedRemarks = [`${oldChannel.code || ""}${oldChannel.value || "--"}`, ...sortedRemarks];
            const newChannel = channels.find((channel) => channel.remarks.toLowerCase() === "new");
            sortedRemarks = [...sortedRemarks, `${newChannel.code || ""}${newChannel.value || "--"}`];
        }
        return sortedRemarks;
    }

    // Helper functions
    displayError = (message) => {
        if (message) {
            this.setState({ errorMessage: `${message}` });
        }
    }

    hideError() {
        this.setState({ errorMessage: "" });
    }


    handleStateChange = async (stateContext, value) => {
        switch (stateContext) {
            case "searchTerm":
                this.handleUpdateSearchTerm(value);
                break;
            case "searchFilter":
                this.handleUpdateSearchFilter(value);
                break;
            case "changePage":
                this.handleChangePage(value);
                break;
            default:
                this.setState({ [stateContext]: value });
        }
    }

    handleUpdateSearchTerm = async (e) => {
        this.setState({ searchTerm: e.target.value });
    }

    handleUpdateSearchFilter = async (choice) => {
        this.setState({
            searchFilter: choice,
            searchTerm: "",
            selectedStatus: defaultSelectedStatus,
        }, async () => {
            if (this.state.hasSearched && choice.value === "all") {
                this.resetTableState();
                await this.fetchTransactions();
            }
        })
    }

    resetTableState = () => {
        this.setState({
            hasSearched: false,
            currentPage: 1,
        })
    }

    handleSubmit = async () => {
        await this.doSearch();
    }

    handlePressEnter = async (e) => {
        if (e.key === "Enter") {
            await this.doSearch();
        }
    }

    doSearch = async () => {
        this.setState({ hasSearched: true, currentPage: 1 })
        await this.fetchTransactions();
    }

    handleChangePage = async (page) => {
        this.setState({ currentPage: page + 1 }, async () => {
            await this.fetchTransactions();
        });
    }

    render() {
        const { pages, currentPage, totalSize, tableData, isTableLoading } = this.state;
        if (this.state.isLoading) {
            return (
                <ReportLoader show={this.state.isLoading} />
            )
        }
        else {
            return (
                <Fragment>
                    <div className="dashboard-section">
                        <Route exact path={this.props.match.url} render={() =>
                            <Fragment>
                                {
                                    this.state.errorMessage.length > 0 ? (
                                        <div className="bg-error py-1 px-4">
                                            <p className="text-white mb-0 font-neo-semibold">ERROR: {this.state.errorMessage}</p>
                                        </div>) : ""
                                }
                                <div className="dashboard-container container-fluid p-3">
                                    <div className="dashboard-header">
                                        <h6 className="font-neo-bold text-darkgray">Transaction History</h6>
                                        {this.props.getViewAs}
                                    </div>
                                    <div className="dashboard-body">
                                        <Nav transactionHistory="active" />
                                        <div className="dashboard-datatable mt-4">
                                            <Search
                                                searchTerm={this.state.searchTerm}
                                                searchFilter={this.state.searchFilter}
                                                handleStateChange={this.handleStateChange}
                                                handleSubmit={this.handleSubmit}
                                                handlePressEnter={this.handlePressEnter}
                                                statusList={this.state.statusList}
                                                selectedStatus={this.state.selectedStatus}
                                            />
                                            <div className="mt-4">
                                                <Transactions
                                                    match={this.props.match}
                                                    data={tableData}
                                                    pages={pages}
                                                    currentPage={currentPage}
                                                    totalSize={totalSize}
                                                    isTableLoading={isTableLoading}
                                                    handleStateChange={this.handleStateChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Fragment>
                        } />
                    </div>
                </Fragment >
            );
        }

    }
}


export default withRouter(withWindowDimensions(AgentTransactionHistory));
