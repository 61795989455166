import React from 'react';

const ProgressIndicator = ({step}) => (
  <div className="progress-indicator d-flex mt-3 align-items-center justify-content-around">
    <div
      className={'progress-indicator__step' +
        (step === 1 ? ' progress-indicator__step--active' : '')
      }
    ><div className="progress-indicator__step-inner"></div></div>
    <div
      className={'progress-indicator__step' +
        (step === 2 ? ' progress-indicator__step--active' : '')
      }
    ><div className="progress-indicator__step-inner"></div></div>
    <div
      className={'progress-indicator__step' +
        (step === 3 ? ' progress-indicator__step--active' : '')
      }
    ><div className="progress-indicator__step-inner"></div></div>
  </div>
);

export default ProgressIndicator;
