import React, { Component } from 'react';
// import '../App.css';
// import DatePicker from 'react-datepicker';
import { emailFormat } from './utility.js';
const Modal = ({ handleClose , show, value, onChange, handleSubmit, seasonName, handleChanges }) => {
  const showHideClassName = show ? 'popup d-block' : 'popup d-none';
  return (
    <div className={showHideClassName}>
      <div className='popup_inner_season'>
      <div onClick={handleClose} className="icon-cards-batch">
          <i className="fa left-align fa-close"></i>
        </div>
      <form onSubmit={handleSubmit} >
        <div>
          <label className="batch-label">Create a Season</label>
          <div className="wrap-input-batch100 validate-input " data-validate = "Enter season name">
            <input value={seasonName} onChange={(event) => handleChanges(event)} className="input-batch100 " type="text" name="seasonName"/>
            <span className="focus-input-batch100" data-placeholder="Season Name"></span>
          </div>
        </div>
        <div>
          <label className="batch-label taget-batch">Select target hatch date</label>
           <div className="wrap-input-season validate-input " data-validate = "Enter target hatch date">
            {/* <DatePicker calendarClassName="datepicker" onChange={onChange} value={value} /> */}
            <span className="focus-input-batch100" data-placeholder="Target date"></span>
           </div>
        </div>
        <div className="batch-done"><button type="submit" className="btn-done">DONE</button></div>
      </form>
      </div>
    </div>
  );
};

const Loader = ({ handleClose , show }) => {
  const showHideClassName = show ? 'popup d-block' : 'popup d-none';
  return (
    <div className={showHideClassName}>
      <div onClick={handleClose} className="progress-bar-season">
         {/* <div className="loader load-size">Loading ...</div> */}
          <div className="loader load-size"></div>
        </div>
    </div>
  );
};

const DashboardLoader = () => {
  return (
    <div>
      <div className="progress-bar-season">
          <div className="loader loader_css"></div>
        </div>
    </div>
  );
}
const ReportLoader = ({ handleClose , show }) => {
  const showHideClassName = show ? 'popup d-block' : 'popup d-none';
  return (
    <div>
      <div onClick={handleClose} className="progress-bar-season">
          <div className="loader report-load-size"></div>
        </div>
    </div>
  );
};

const UserForm = ({ show, userDetails, handleChanges, handleClose, handleSubmit, handleRadioButtonChanges }) => {
  const showHideClassName = show ? 'popup d-block' : 'popup d-none';
  let emailDisclaimer = "";
  let passwordDisclaimer = "";
  if(!emailFormat(userDetails.email) && userDetails.email != ""){
    emailDisclaimer = "Email invalid"
  }
  return (
    <div className={showHideClassName}>
      <div className='pop-up-register'>
        <div onClick={handleClose} className="icon-cards-batch">
          <i  className="fa fa-close icon-wrapper"></i>
        </div>
        <div className="align-label">
          <div className="col full-field-label">Create new user</div>
        </div>
        <form className="form-user-details" onSubmit={handleSubmit}>
          <div className="row pad3">
            <div className="col form-title-wrap">First Name:</div>
            <div className="col">
              <input type="text" className="user-fields" name="first_name" onChange={(event) => handleChanges(event) } value={userDetails.first_name}/>
            </div>
          </div>
          <div className="row pad3">
            <div className="col form-title-wrap">Last Name:</div>
            <div className="col">
              <input type="text" className="user-fields" name="last_name" onChange={(event) => handleChanges(event) } value={userDetails.last_name}/>
            </div>
          </div>
          <div className="row pad3">
            <div className="col form-title-wrap">Email:</div>
             <div className="col">
              <input type="text" className="user-fields"  name="email" onChange={(event) => handleChanges(event) } value={userDetails.email}/>
              <div className="email-checker">{emailDisclaimer}</div>
            </div>
          </div>
          <div className="row pad3">
            <div className="col form-title-wrap">Password:</div>
             <div className="col">
              <input type="password" className="user-fields"  name="password" onChange={(event) => handleChanges(event) } value={userDetails.password}/>
              <div className="email-checker"></div>
            </div>
          </div>
          <div className="row pad3">
          <div className="col form-title-wrap">Contact Number:</div>
          <div className="col">
              <input type="number" className="user-fields"  name="contact_number" onChange={(event) => handleChanges(event) } value={userDetails.contact_number}/>
            </div>
          </div>
          <div className="row m-b-30 red-border">
            <div className="col"><label className="form-check-label" >Gender</label></div>
            <div className="row col">
            <div className="col-md-6 " >
                <input type="radio" checked={userDetails.male} onClick={(event) => handleRadioButtonChanges(event)} className="form-check-input" id="gender" name="gender"/>
                <label className="form-check-label" htmlFor="materialChecked">Male</label>
            </div>
             <div className="col-md-6">
                <input type="radio" checked={userDetails.female} onClick={(event) => handleRadioButtonChanges(event)} className="form-check-input" id="gender" name="gender"/>
                <label className="form-check-label" htmlFor="materialChecked2">Female</label>
            </div>
            </div>
          </div>
          <div className="row pad3">
            <div className="col form-title-wrap">Country:</div>
            <div className="col">
              <input type="text" className="user-fields"  name="country" onChange={(event) => handleChanges(event) } value={userDetails.country}/>
            </div>
          </div>
          <div className="row pad3">
            <div className="col form-title-wrap">City:</div>
            <div className="col">
              <input type="text" className="user-fields"  name="city" onChange={(event) => handleChanges(event) } value={userDetails.city}/>
            </div>
          </div>
            <div className="col-xs-12"><label className="form-check-label" >Role:</label></div>
            <div className="roleContainer col">
              <div className="col-xs-12 " >
                  <input type="radio" checked={userDetails.admin} onClick={(event) => handleRadioButtonChanges(event) }  className="form-check-input" id="admin" name="admin"/>
                  <label className="form-check-label" htmlFor="materialChecked">Super Admin</label>
              </div>
               <div className="col-xs-12">
                  <input type="radio" checked={userDetails.subscriber} onClick={(event) => handleRadioButtonChanges(event) }  className="form-check-input" id="subscriber" name="subscriber"/>
                  <label className="form-check-label" htmlFor="materialChecked2">Subscriber</label>
              </div>
               <div className="col-xs-12">
                  <input type="radio" checked={userDetails.report} onClick={(event) => handleRadioButtonChanges(event) } className="form-check-input" id="report" name="report"/>
                  <label className="form-check-label" htmlFor="materialChecked2">Report</label>
              </div>
            </div>
          <div className="button-wrap">
          <button type="submit" className="btn btn-primary btn-md btn-margin">SAVE</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export { Loader,DashboardLoader,  Modal, UserForm, ReportLoader};
