
import React, { Component } from 'react';
import Select from 'react-select';
import ReactTable from 'react-table';
import { ReportLoader } from '../commons/utilityViews.js';
import { withRouter, Link } from 'react-router-dom';
import { Years } from '../components/Datepicker/customHeader.js';
import ReportDropdownSearch from '../components/reportDropdownSearch.js';
import Auxi from '../components/auxi.js';
import { apiModule, createQueryStrings } from '../commons/apiCall.js';
import { myConfig } from '../commons/config.js';
import { numberWithCommas, fixName, removeNull, fixAddress, prettifyDate, calculateAge, writeData, readAllData, encrypt, decrypt, renameByChannel } from '../commons/utility.js';
import TableLoader from '../components/TableLoader/tableLoader';
import withWindowDimensions from '../commons/withWindowDimensions';
import ReportsMobile from '../components/Reports/Mobile/ReportsMobile';
import TablePagination from '../components/tablePagination';
import handleSortOrder from '../components/misc/sortOrder';
import { isManager, checkLogin, checkPermissions, getSessionInfo } from '../commons/security.js';
import routeChange from '../commons/routeChange';

class policyListPerFaReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            error: '',
            teamMateValue: null,
            statusValue: null,
            showTable: false,
            currentPage: 0,
            reports: [],
            teamMateList: [],
            statusList: [],
            loadTable: true,
            sortByField: null,
            sortOrder: null,
            isAllowed : false
        };
        this.pageHeader = "Policy List per Servicing FA"
    }
    //VIEW_REP_POL_SERV

    async componentDidMount() {
        
        let access_token = this.props.getSession().access_token;
        let session = await getSessionInfo();
        let permissionsList = session[0].permissions;
       
        if (checkPermissions(['VIEW_REP_POL_SERV'], permissionsList)) {
            
            this.setState({
                isLoading: true,
                isAllowed: true,
            }, () => {
                this.getTeamMateData();
            });
        }else {
            console.log("NOT Allowed");
            this.setState({ isAllowed: false, isLoading: false, error: "You are not allowed to access this page" });
        }
        
        
    }

    updateTeamMate = (teamMate) => {
        this.setState({
            teamMateValue: teamMate,
        }, () => {
            this.showTable();
        });
    }
    updateStatus = (status) => {
        this.setState({
            statusValue: status,
        });
    }
    errorMessage = (message) => {
        if (message) {
            this.setState({ error: message })
        }
    }
    resetMessage = () => {
        this.setState({ error: '' })

    }
    showTable = () => {
        if (this.state.teamMateValue) {
            this.setState({
                // isLoading: true
                loadTable: true,
            }, () => {
                this.getTableData();
            });
        }
    }

    async getTableData() {
        await this.fetchPolicyList(this.props.getSession().access_token, decrypt(this.props.getSession().agentNumber));
    }

    async getTeamMateData() {
        await this.fetchTeamMates(this.props.getSession().access_token, decrypt(this.props.getSession().agentNumber));
    }

    async getStatusData() {
        await this.fetchStatus(this.props.getSession().access_token, decrypt(this.props.getSession().agentNumber));
    }

    //SHOW ERROR IF ERROR STATE IS NOT NULL
    showError = () => {
        let message = '';
        if (this.state.error !== '') {
            message =
                <div className="bg-error py-1 px-4">
                    <p className="text-white mb-0 font-neo-semibold">ERROR: {this.state.error}</p>
                </div>
        }
        return message;
    }

    fetchStatus = (access_token, agentNumber) => {
        let session = this.props.getSession();
        let that = this;

        return new Promise(function (resolve, reject) {
            let headerParams = {
                x_auth: access_token,
                dataListId: 'policyStatus'
            };
            let filter = [];

            filter['key'] = agentNumber;

            apiModule("get_datalist", headerParams, null, createQueryStrings(filter))
                .then(function (response) {
                    let result = response;
                    let statusList = [];

                    Object.keys(result).forEach(function (key) {
                        let item = key;
                        statusList.push(
                            new Object({
                                value: item,
                                label: result[key]
                            })
                        );
                    });

                    that.setState({
                        statusList: statusList,
                        statusValue: statusList[0],
                    }, () => {
                        that.showTable();
                    });
                })
                .catch(function (err) {
                    console.log(err);
                    that.setState({
                        error: myConfig.GENERIC_ERROR_MESSAGE,
                        isLoading: false
                    }, () => {
                        // that.showError(err);
                        console.log(err)
                    });
                })

        });
    }

    fetchTeamMates = (access_token, agentNumber) => {
        let session = this.props.getSession();
        let that = this;
        let headerParams = {
            accountManagerId: agentNumber,
            x_auth: access_token,
        };
        console.log(session);
        let payloadParams = {};
        return new Promise(function (resolve, reject) {
            let filter = [];

            apiModule("get_accountmanager_team", headerParams, null, createQueryStrings(filter))
                .then(function (response) {
                    let result = response.content;
                    let teamMateList = [];
                    for (let i = 0; i < result.length; i++) {
                        let faResult = result[i];
                        teamMateList.push({ value: faResult.name, label: `${faResult.name}` })
                        //    teamMateList.push({ value: faResult.agentNumber, label: `${faResult.name}`})
                    }

                    that.setState({
                        teamMateList: teamMateList,
                        teamMateValue: teamMateList[0],
                    }, () => {
                        that.getStatusData();
                    });
                })
                .catch(function (err) {
                    console.log(err);
                    that.setState({
                        error: myConfig.GENERIC_ERROR_MESSAGE, //err.message,
                        isLoading: false
                    }, () => {
                        // that.showError(err);
                        console.log(err)
                    });
                })

        });
    }

    fetchPolicyList = (access_token, agentNumber) => {
        let that = this;

        return new Promise(function (resolve, reject) {

            let headerParams = { x_auth: access_token };
            let filter = [];
            // let continueProcess = true;
            filter['pageNumber'] = (that.state.currentPage + 1);
            filter['agentNumber'] = agentNumber;
            filter['reportCode'] = 'RPT_POL_PER_FA'
            filter['pageSize'] = myConfig.PAGE_SIZE;

            if (that.state.teamMateValue != null) {
                filter['name'] = that.state.teamMateValue.value;
            }

            // if (that.state.statusValue != null) {
            //     filter['status'] = that.state.statusValue.value;
            // }

            if (that.state.sortByField != null) {
                filter['sortBy'] = that.state.sortByField + ',' + that.state.sortOrder
            }

            if (true) {
                that.resetMessage();
                apiModule("reports", headerParams, null, createQueryStrings(filter))
                    .then(function (response) {


                        let result = [];
                        let totalSize = 0;
                        if (response != null) {
                            if (Array.isArray(response)) {
                                if (response.length > 0) {
                                    result = response[0].content;
                                    totalSize = response[0].totalSize;
                                } else {
                                    result = [];
                                    totalSize = 0;
                                }
                            } else {
                                result = response.content;
                                totalSize = response.totalSize;
                            }
                        }

                        let numOfPages = parseInt((totalSize / myConfig.PAGE_SIZE), 10);

                        if ((totalSize % myConfig.PAGE_SIZE) > 0) {
                            numOfPages++;
                        }

                        let reports = [];

                        if (result) {

                            for (let i = 0; i < result.length; i++) {

                                // poNum: "103000001",
                                // insured: "Crasco, James",
                                // plan: "Optimum Gold",
                                // effDate: "11/15/2018",
                                // currency: "Php",
                                // amt: "10,000",
                                // expDate: "11/15/2018",
                                // status: "Lapsed"

                                reports.push(
                                    {
                                        POL_NO: removeNull(result[i].contractNumber),
                                        I_COMP_NAME: fixName(result[i].insured),//.lastName + ", " + result[i].insured.lastName),
                                        POL_DS: removeNull(result[i].productOfferingName),
                                        EFF_DT: removeNull(prettifyDate(result[i].inceptionDate)),
                                        POL_CURR_CD: removeNull(result[i].currency),
                                        FACE_AMT: removeNull(numberWithCommas(result[i].contractBalance)),
                                        EXPIRY_DT: removeNull(prettifyDate(result[i].contractInterval.endTimeDate)),
                                        //POL_STAT: removeNull(result[i].status)
                                    }
                                );
                            }

                            that.setState({
                                reports: reports
                            });

                        } else {
                            that.showError("No Account Found");
                        }

                        that.setState({
                            reports: reports,
                            loadTable: false,
                            totalSize: totalSize,
                            pages: numOfPages,
                            showTable: true,
                            error: '',
                            isLoading: false
                        });
                    })
                    .catch(function (err) {
                        console.log(err);
                        that.setState({
                            isLoading: false
                        }, () => {
                            that.errorMessage(err);
                            console.log(err.message)
                        });
                    })
            } else {
                that.setState({
                    isLoading: false
                });
            }


        });
    }

    additionalDropDown = () => {

        let field = (
            <Select
                styles={{
                    option: (provided) => ({
                        ...provided,
                        fontFamily: "neo_semibold",
                        fontSize: ".6rem",
                        color: "#9A9B9C",
                        backgroundColor: "white",
                        marginRight: "100px"
                    }),
                    menu: () => ({
                        position: "absolute",
                        border: 0,
                        zIndex: "1090",
                        backgroundColor: "white",
                        borderRadius: "5px",
                        boxShadow: "2px 2px 2px #DDDEE0",
                        marginRight: "100px"
                    }),
                    marginRight: "100px"
                }}
                options={this.state.statusList}
                isSearchable={false}
                className={`datatable-select ml-0 ${this.props.isSm ? "w-100" : ""}`}
                classNamePrefix="datatable-select"
                placeholder={"Status"}
                value={this.state.statusValue}
                onChange={this.updateStatus}
            />
        );

        return field;
    }

    handleSortAction = (sortByField, sortOrder) => {
        this.setState({
            sortByField: sortByField,
            sortOrder: sortOrder,
            // isLoading: true
            loadTable: true,
        }, () => {
            this.getTableData();
        })
    }
    headerClickedHandler = (sortByField) => {
        let sortOrder = handleSortOrder({ sortByField: sortByField, state: this.state });
        this.handleSortAction(sortByField, sortOrder)
    }

    onClickAction = (item) => {
        this.props.setClientOrPolicy({ policyId: item });
        writeData('policy-client', { policyId: item, id: 1 });
        routeChange(this.props.history, "/dashboard/single-policy");
    }

    changePage = (pageNumber) => {
        let that = this;
        if (this.state.currentPage != pageNumber) {
            this.setState({
                currentPage: pageNumber,
                // isLoading: true,
                loadTable: true,
            }, () => {
                this.getTableData();
                // await that.fetchBirthdayList(that.props.getSession().access_token, decrypt(that.props.getSession().agentNumber));
            }
            );
        }
    }

    render() {
        const customNames = [
            {
                Header: 'Policy Number',
                accessor: 'POL_NO'
            },
            {
                Header: "Insured",
                accessor: 'I_COMP_NAME'
            },
            {
                Header: "Plan Name",
                accessor: 'POL_DS'
            },
            {
                Header: "Effective Date",
                accessor: 'EFF_DT',
            },
            {
                Header: "Currency",
                accessor: "POL_CURR_CD"
            },
            {
                Header: "Face Amount",
                accessor: "FACE_AMT"
            },
            {
                Header: "Expiry Date",
                accessor: "EXPIRY_DT"
            },
            /*
            **removing policy status due to inconsistency with the data source
            {
                Header: "Status",
                accessor: "POL_STAT",
                // Cell: props => <button className={"dashboard-datatable__button btn rounded-pill text-uppercase text-white pes-none " + props.value.toLowerCase()} >{props.value.toLowerCase()}</button>
            }
            */
        ];

        if (this.state.isLoading) {
            return (
                <ReportLoader handleClose={this.hideModal} show={this.state.isLoading} />
            );
        } else if (!this.state.isAllowed) {
            return (
                <div className="dashboard-section overflow-hidden">
                    {this.showError()}
                </div>
            );
        }else {

            let dataTable = "";

            if (this.state.reports.length > 0) {
                if (this.props.isSm) {
                    dataTable = (
                        <ReportsMobile
                            title={[
                                {
                                    Header: 'Policy Number',
                                    accessor: 'POL_NO',
                                    hasCaret: true,
                                    onClick: () => {
                                        this.headerClickedHandler('POL_NO');
                                    }
                                },
                                /*
                                **removing policy status due to inconsistency with the data source
                                {
                                    Header: 'Status',
                                    accessor: 'POL_STAT',
                                },
                                */
                            ]}
                            customNames={customNames}
                            data={this.state.reports}
                            hasOnClickBtn={true}
                            onClickAction={this.onClickAction}
                        >
                            <TablePagination
                                css="mt-2"
                                data={this.state.reports}
                                page={this.state.currentPage}
                                totalSize={this.state.totalSize}
                                onPageChange={this.changePage}
                                pageSize={myConfig.PAGE_SIZE}
                                pages={this.state.pages}
                            />
                            {this.state.loadTable ? <TableLoader loading={true} /> : ""}
                        </ReportsMobile>
                    );
                } else {
                    dataTable = (
                        <ReactTable
                            className={`mt-4 ${this.state.showTable ? "d-flex" : "d-none"} rt-no-hover`}
                            loading={this.state.loadTable}
                            LoadingComponent={TableLoader}
                            resizable={false}
                            columns={customNames}
                            defaultPageSize={myConfig.PAGE_SIZE}
                            minRows={0}
                            data={this.state.reports}
                            page={this.state.currentPage}
                            manual
                            onFetchData={(state, instance) => {
                                this.changePage(state.page);
                            }}
                            getTheadThProps={(state, rowInfo, column, instance) => ({
                                onClick: () => {
                                    this.headerClickedHandler(column.id);
                                },
                                sortClassName: (
                                    this.state.sortByField == column.id ?
                                        (this.state.sortOrder == "ASC" ?
                                            "icon-chevron-small-up"
                                            : "icon-chevron-dropdown")
                                        : ""
                                ),
                                sortColor: (
                                    this.state.sortByField == column.id ?
                                        "text-black"
                                        : ""
                                )
                            })}
                            getTrProps={(state, rowInfo, column, instance) => ({
                                onClick: () => {
                                    this.onClickAction(rowInfo.original.POL_NO);
                                },
                                style: {
                                    cursor: 'pointer'
                                }
                            })}
                            pages={this.state.pages}
                            totalSize={this.state.totalSize}
                        />
                    );
                }
            } else {
                dataTable = (
                    <div className="center-align dashboard-row-data font-neo-semibold">No records found </div>
                );
            }
            let channel = this.props.getSession().channel;
            let pageLabel = renameByChannel("List of FA", channel, this.props.getSession().view_as_channel);
            let pageLabelPolicyPerServicingFA = renameByChannel("Servicing FA", channel, this.props.getSession().view_as_channel);
            let pageLabelClientPerServicingFA = renameByChannel("Servicing FA", channel, this.props.getSession().view_as_channel);

            pageLabel = pageLabel + "s per status";
            pageLabelPolicyPerServicingFA = "Policy list per " + pageLabelPolicyPerServicingFA;
            pageLabelClientPerServicingFA = "Client list per " + pageLabelClientPerServicingFA;

            //this.pageHeader = "Policy List per Servicing FA with status"
            console.log("Title " + pageLabelClientPerServicingFA);
            console.log("Title " + pageLabelPolicyPerServicingFA);

            return (
                <div className="dashboard-section">
                    {this.showError()}
                    <div className="dashboard-container container-fluid p-3">
                        <div className="dashboard-header">
                            <h6 className="font-neo-bold">
                                <Link className="text-darkgray text-decoration-none" to="/dashboard/reports">Reports</Link>
                                <span className="dashboard-header__subheader text-headercolor"> | {pageLabelPolicyPerServicingFA}</span>
                            </h6>
                            {this.props.getViewAs}
                        </div>
                        <div className="dashboard-body mt-4">
                            <ReportDropdownSearch
                                showSearchBar={false}
                                dropdownValue={this.state.teamMateValue}
                                updateDropdown={this.updateTeamMate}
                                showTable={this.showTable}
                                dropdownPlaceholder="Team Mates"
                                styles={{
                                    option: (provided) => ({
                                        ...provided,
                                        fontFamily: "neo_semibold",
                                        fontSize: ".6rem",
                                        color: "#9A9B9C",
                                        backgroundColor: "white"
                                    }),
                                    menu: () => ({
                                        position: "absolute",
                                        border: 0,
                                        zIndex: "1090",
                                        backgroundColor: "white",
                                        borderRadius: "5px",
                                        boxShadow: "2px 2px 2px #DDDEE0"
                                    }),
                                }}
                                options={this.state.teamMateList}
                            // additionalDropDown={this.additionalDropDown()}
                            />
                            {dataTable}
                        </div>
                    </div>
                </div>
            );
        }
    }
}

export default withRouter(withWindowDimensions(policyListPerFaReport));
