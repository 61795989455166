import React, { Component } from 'react'
import { haveValue } from '../../../commons/misConfig';
import MiniLoading from './miniLoading';
import VisibilitySensor from 'react-visibility-sensor';

class SearchUser extends Component {
    render() {
        const { searchResult, isLoading, standalone, justLoadMore } = this.props;
        return !isLoading || justLoadMore ? (
            <div className="">
                {haveValue(searchResult.content) ? searchResult.content.map(function (userObj, userIndex) {
                    return <div className="rni-search-user-container" key={userIndex}>
                        <img src={userObj.imgUrl ? userObj.imgUrl : "/rni/man.png"} onError={(e) => { e.onerror = null; e.target.src = "/rni/man.png" }} />
                        <p>
                            <span>{`${userObj.firstName} ${userObj.lastName}`}</span><br />
                            <sup>{userObj.role ? userObj.role.description : null}</sup>
                        </p>
                    </div>
                }) : <p style={{ textAlign: 'center', fontSize: '2rem', color: 'darkgray', fontFamily: 'neo_regular' }}>No Users Found</p>}
                {standalone && haveValue(searchResult.content) ?
                    <VisibilitySensor delayedCall={true} onChange={(e) => {
                        if (e) { this.props.nextPage(); }
                    }}>
                        <div>
                            <p style={{
                                textAlign: 'center',
                                fontSize: '2rem',
                                fontWeight: '800',
                                marginBottom: '0px'
                            }}>. . .</p>
                        </div>
                    </VisibilitySensor>
                    :
                    ''
                }
            </div>
        ) : <MiniLoading />
    }
}

export default SearchUser;