import React, { Component } from "react";
import { apiModule } from "../../../commons/apiCall";
import LoaderGif from "../../../commons/loaderGif";
import { Loader } from '../../../commons/utilityViews.js';
import withWindowDimensions from "../../../commons/withWindowDimensions";
import { OtherInfoPdfViewer } from "./otherInfoPdfViewer";

class SalesIllustration extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...this.props.modalData,
            data64: '',
            isLoading: true,
            isError: false,
            errorStatus: '',
        }
    }

    componentDidMount = async () => {
        await this.fetchData();
    }

    fetchData = async () => {
        try {
            const reqParams = {
                type: 'SALES_ILLUSTRATION',
                contractId: this.state.policyId,
                x_auth: this.state.token,
            }
            console.log(`%cDownloading ${this.state.modalId} PDF...`, "background: green; color: white; display: block;");
            const response = await apiModule(`get_document_other_info`, reqParams)
                .then((response) => {
                    if (!response.error) {
                        console.log("%cPDF is done downloading,%c RESPONSE:", "background: green; color: white; display: block;", "background: #255F8E; color: white; display: block;", response);
                        this.setState({ isLoading: false, data64: response[0].data });
                        return response;
                    }
                    throw (response);
                });
        }
        catch (e) {
            console.log("%cPDF Error", "background: red; color: white; display: block;");
            console.error(e);
            if (e.message === 'No document found') {
                this.setState({ isLoading: false, isError: true, errorStatus: e.message });
            }
            else {
                this.setState({ isLoading: false, isError: true, errorStatus: `Something went wrong.` });
            }
        }
    }

    renderError = () => {
        if (this.state.isError && this.state.errorStatus === 'No document found') {
            return (
                <p className='other-info-modal-pdf__no-document'>No Sales Illustration available.</p>
            )
        }
        return (
            <div class="bg-error py-1 px-4">
                <p class="text-white mb-0 font-neo-semibold">ERROR: {`${this.state.errorStatus}`}</p>
            </div>
        )
    }

    render = () => {
        const contentStyle = ((this.state.isError || !this.state.data64) && !this.state.isLoading) ? null : (this.props.isSm ? { height: '75vh' } : { height: '85vh' });
        return (
            <div style={contentStyle}>
                {
                    this.state.isLoading ?
                        <div className="other-info-modal-pdf__modal-loading" style={contentStyle}><Loader show={this.state.isLoading} /></div> :
                        this.state.isError || !this.state.data64 ?
                            this.renderError() :
                            // <iframe onContextMenu={() => { document.body.addEventListener("contextmenu", function (evt) { evt.preventDefault(); return false; }); }} src={`data:application/pdf;base64,${this.state.data64}#toolbar=0&navpanes=0&view=FitH`} height="100%" width="100%" />
                            <OtherInfoPdfViewer isSm={this.props.isSm} linkPdf={`data:application/pdf;base64,${this.state.data64}`} />
                }
            </div>

        );
    }
}

export default withWindowDimensions(SalesIllustration);