import React, { Component, useEffect, useState } from 'react';
import withWindowDimensions from '../../commons/withWindowDimensions';
import Auxi from "../auxi";
import LoaderGif from '../../commons/loaderGif';
import Status from "./status";
import { TranslateIcon } from 'mdi-react';
import { Table } from 'antd';
import './modal.scss';

class Modal extends Component {

    constructor(props) {
        super(props);
        this.setState({
            show: this.props.showModal,
        });
    }

    componentDidMount() {
        this.setState({
            isModalLoading: this.props.isModalLoading,
        });
    }


    render() {
        const showModal = this.props.showModal;
        const data = this.props.data;
        const showHideClass = showModal? "": "none";
        const inlineStyle = {
            display: showHideClass,
            maxHeight: "95vh",
        };
        const customHeadingStyle = {
            verticalAlign: "bottom",
            fontSize: ".8rem"
        };

        let rows = "";
        let modal = "";
        let modalLoading = this.props.isModalLoading;

        console.log("MODAL");
        console.log([showModal, this.props.data, showHideClass]);

        if(this.props.isSm) {
            // MOBILE VIEW
            modal = (
                <div className={`claims-history-modal-body`} style={inlineStyle}>
                    <div className="claims-history-modal-box mt-n5">
                        <div className="claims-history-modal-close-container">
                            <h5 className="claims-history-modal-title"></h5>
                            <img className="claims-history-modal-close-icon" src="/announcement/close.png" alt="close-icon" onClick={this.props.handleClose} />
                        </div>
                        <div className="claims-history-modal-content" style={{ maxHeight: 'calc(100vh - 300px)' }}>
                            <div style={{ overflow: 'auto' }}>
                            {this.props.isModalLoading? (
                                <div style={{ minWidth: "10em", minHeight: "10em" }}>
                                    <LoaderGif css="mt-4 mb-4" />
                                </div>
                            ) : (
                                <div className="data-accord row flex-wrap mt-4" >
                                    <div className="data-accord__header">
                                        <div className="col-7">
                                            <div className="d-inline-flex align-items-center cs-pointer" style={{ fontSize: "1em" }}>
                                                Pending Requirements
                                            </div>
                                        </div>
                                        <div className="col-5">
                                            <div className="d-inline-flex align-items-center cs-pointer" style={{ fontSize: "1em" }}>
                                                Status
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 data-accord__body mt-1">
                                        <div className="data-accord__collapse" id="data-accord">
                                        {
                                            this.props.data.map((item, index) => {
                                                return (
                                                    <Auxi key={index}>
                                                        <div className={`data-accord__card ${index > 0 ? "mt-3" : ""} card border-0`}>
                                                            <div className="data-accord__toggle btn" data-toggle="collapse" data-target={`#collapse-${index}`}>
                                                                <div className="row data-accord__start">
                                                                    <div className="col-7">
                                                                        <button className="data-accord__name data-accord__name--clear">{item.documentDescription? item.documentDescription: "--"}</button>
                                                                    </div>
                                                                    <div className="col-5 d-flex justify-content-between">
                                                                        <div className="font-neo-semibold">{item.status? <Status status={item.status} />: "--"}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Auxi >
                                                );
                                            })
                                        }
                                        </div>
                                    </div>
                                </div>
                            )
                            }
                        </div>
                    </div>
                    </div>
                </div>
            );
        } else {
            modal = (
                // DESKTOP VIEW
                <div className={`claims-history-modal-body`} style={inlineStyle}>
                    <div className="claims-history-modal-box mt-n5">
                        <div className="claims-history-modal-close-container">
                            <h5 className="claims-history-modal-title"></h5>
                            <img className="claims-history-modal-close-icon" src="/announcement/close.png" alt="close-icon" onClick={this.props.handleClose} />
                        </div>
                        <div className="claims-history-modal-content">
                            <div style={{ overflow: 'auto' }}>
                                {this.props.isModalLoading? (
                                    <div style={{ minWidth: "10em", minHeight: "10em" }}>
                                        <LoaderGif css="mt-4 mb-4" />
                                    </div>
                                ):(
                                    <table className="table-striped" style={{ width: "100%" }}>
                                        <tbody>
                                            <tr className="" style={{ verticalAlign: "bottom", fontSize: ".8rem" }}>
                                                <th className="p-3" style={{ width: "40%" }} scope="col">Pending Requirements</th>
                                                <th className="p-3" style={{ width: "30%" }} scope="col">Requirements Status</th>
                                            </tr>
                                            {
                                                this.props.data.map((item, index) => {
                                                    return (
                                                        <tr style={{ fontSize: ".9em" }}>
                                                            <td className="p-3 text-left">{item.documentDescription? item.documentDescription: "--"}</td>
                                                            <td className="p-3 text-left">{item.status? <Status status={item.status} />: "--"}</td>
                                                        </tr>
                                                    );
                                                })
                                            }
                                        </tbody>
                                    </table>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return(
            modal
        );
    }

}
export default withWindowDimensions(Modal);