import React, { Component, Fragment } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

export class PdfPopupModal extends Component {

    state = {
        controlsHeight: 0,
        pdfHeight: 0,
        pdfWidth: 0,
        scale: 4
    }

    constructor(props) {
        super(props);
        pdfjs.GlobalWorkerOptions.workerSrc = `/js/pdf.worker.min.js`;
    }

    componentDidMount() {

        const pdfControlsHeight = document.getElementById("js-pdf-controls").getBoundingClientRect().height;
        this.setState({
            controlsHeight: pdfControlsHeight,
        })

        document.addEventListener("keydown", this.handleKeyDown, false);
        window.addEventListener("resize", this.updateDocumentCanvasSize);
    }


    componentWillUnmount() {
        document.removeEventListener("keydown", this.handleKeyDown, false);
        window.removeEventListener("resize", this.updateDocumentCanvasSize);
    }

    handleKeyDown = (e) => {
        if (e.key === "Escape") {
            this.handleCloseClick();
        }
    }

    updateDocumentCanvasSize = () => {
        const modalContent = document.getElementById("js-pdf-modal-content");
        if (modalContent) {
            const modalContentHeight = modalContent.getBoundingClientRect().height;
            const newHeight = (modalContentHeight) - this.state.controlsHeight;
            this.setState({
                pdfHeight: newHeight
            })
            if (this.props.file.scale) {
                const scale = this.props.isSm ? this.props.file.scale - 0.3 : this.props.file.scale;
                this.setState({
                    scale: scale * (4 * (2 / 3))
                })
            } else {
                this.setState({
                    scale: 4
                })
            }
        }
    }

    handleCloseClick = () => {
        this.props.onCloseClick();
    }

    handleRotation = () => {
        this.props.setPdfRotation();
    }

    handleNavigation = (type) => {
        console.log(type);
        if (type === "Back") {
            this.props.setPdfNavigation(-1);
        } else if (type === "Next") {
            this.props.setPdfNavigation(1);
        }
        this.updateDocumentCanvasSize();
    }

    onDocumentLoad = ({ numPages }) => {
        this.props.setPdfTotalPages(numPages);
    }

    pdfDocument = () => (
        <TransformWrapper>
            {({ zoomIn, zoomOut }) => (
                <Fragment>
                    <section className="agent-kb-pdf-modal__file-canvas">
                        <TransformComponent wrapperClass="agent-kb-pdf-modal__canvas-wrapper">
                            <Document
                                file={this.props.activePdfLink}
                                className="agent-kb-pdf-modal__file-canvas-document"
                                onLoadSuccess={(e) => {
                                    this.onDocumentLoad(e);
                                    setTimeout(() => {
                                        this.updateDocumentCanvasSize();
                                    }, 100)
                                }}
                                rotate={this.props.activePdfRotation}
                                height={100}
                            >
                                <Page
                                    pageNumber={this.props.activePageNum}
                                    renderAnnotationLayer={false}
                                    height={this.state.pdfHeight}
                                    width={this.state.pdfWidth}
                                    scale={this.state.scale}
                                />
                            </Document>
                        </TransformComponent>
                    </section>
                    <section id="js-pdf-controls" className="agent-kb-pdf-modal__file-controls">
                        <div className="agent-kb-pdf-modal__file-zoom-controls">
                            <button onClick={() => zoomIn()} className="agent-kb-pdf-modal__file-control">
                                <img src="/img/icons/PlusCircleO.svg" className="agent-kb-pdf-modal__file-control-icon" />
                                <span className="agent-kb-pdf-modal__file-control-text">Zoom In</span>
                            </button>
                            <button onClick={() => zoomOut()} className="agent-kb-pdf-modal__file-control">
                                <img src="/img/icons/MinusCircleO.svg" className="agent-kb-pdf-modal__file-control-icon" />
                                <span className="agent-kb-pdf-modal__file-control-text">Zoom Out</span>
                            </button>
                        </div>
                        <div className="agent-kb-pdf-modal__file-canvas-controls">
                            <button onClick={() => this.handleRotation()} className="agent-kb-pdf-modal__file-control">
                                <img src="/img/icons/Refresh.svg" className="agent-kb-pdf-modal__file-control-icon" />
                                <span className="agent-kb-pdf-modal__file-control-text">Rotate</span>
                            </button>
                            <div className="agent-kb-pdf-modal__file-page-controls">
                                <button
                                    disabled={this.props.activePageNum === 1}
                                    onClick={() => this.handleNavigation("Back")}
                                    className={
                                        "agent-kb-pdf-modal__file-control" +
                                        (this.props.activePageNum === 1 ? " agent-kb-pdf-modal__file-control--disabled" : "")
                                    }
                                >
                                    <img src="/img/icons/ArrowLeft.svg" className="agent-kb-pdf-modal__file-control-icon" />
                                    <span className="agent-kb-pdf-modal__file-control-text">Back</span>
                                </button>
                                <span className="agent-kb-pdf-modal__file-page-info">{this.props.activePageNum} of {this.props.totalPageNum}</span>
                                <button
                                    disabled={this.props.activePageNum === this.props.totalPageNum}
                                    onClick={() => this.handleNavigation("Next")}
                                    className={
                                        "agent-kb-pdf-modal__file-control" +
                                        (this.props.activePageNum === this.props.totalPageNum ? " agent-kb-pdf-modal__file-control--disabled" : "")
                                    }
                                >
                                    <span className="agent-kb-pdf-modal__file-control-text">Next</span>
                                    <img src="/img/icons/ArrowRight.svg" className="agent-kb-pdf-modal__file-control-icon agent-kb-pdf-modal__file-control-icon--next" />
                                </button>
                            </div>
                        </div>
                    </section>
                </Fragment>
            )}
        </TransformWrapper>
    );

    render() {

        return (
            <div
                className="agent-kb-pdf-modal__overlay"
                onClick={this.handleCloseClick}
            >
                <article className="agent-kb-pdf-modal" onClick={(e) => e.stopPropagation()}>
                    <header className="agent-kb-pdf-modal__header d-flex flex-row">
                        <h4 className=" d-inline-flex align-items-center mr-auto mb-0">
                            {this.props.headerText}
                        </h4>
                        <span
                            className={`agent-kb-pdf-modal__close icon-close mb-0`}
                            onClick={this.handleCloseClick}
                        ></span>
                    </header>
                    <section id="js-pdf-modal-content" className="agent-kb-pdf-modal__content">
                        {this.pdfDocument()}
                    </section>
                </article>
            </div>
        );
    }
}
