import React, { Component, useState } from 'react';
import '../../css/mis.scss';
import PhilippinesMap from './philippinesMap.js'
import DetailedMap from './detailedMap.js'
import { MAP_MODE, MAP_CONFIG } from '../../commons/misConfig';
import ReactTooltip from "react-tooltip";
import { apiModule, createQueryStrings } from '../../commons/apiCall';
import { haveValue, labelXAxis, showNoData, charts } from '../../commons/misConfig';
import { formatValues, changeCaseFirstLetter, changeToLowerCase, thousandSeparator, roundNumber, formatValuesNoDecimals } from './formatter';
import Lottie from 'lottie-react-web';
import Coins from '../../assets/animation/coins.json'
import moment from 'moment';
import CommentIcon from './commentIcon';
import withWindowDimensions from '../../commons/withWindowDimensions';
import VisibilitySensor from 'react-visibility-sensor';

class Heatmap extends Component {

    constructor(props) {
        super(props);
        this.state = {
            geoUrl: "/ph_data/ph_muni.topo.json",
            provinceUrl: '/ph_data/ph-provinces.json',
            regionUrl: '/ph_data/ph-region.json',
            countryUrl: '/ph_data/ph-country.json',
            metroManilaUrl: '/ph_data/ph-metromanila.json',
            properties: '',
            heatmapMode: MAP_MODE.PERFORMANCE,
            content: null,
            fillColors: ['#C1EBFB', '#91CFE9', '#61B3D7', '#3096C5', '#007AB3'],
            grayscaled: ['#D3D3D3', '#AFAFAF', '#8A8A8A', '#666666', '#414141'],
            activeFillColor: props.currChannel.value === 'ALL' ? ['#C1EBFB', '#91CFE9', '#61B3D7', '#3096C5', '#007AB3'] : ['#DC3149', '#E36376', '#EA96A3', '#F1C8D0', '#3DA556'],
            belowTargetColor: ['#DC3149', '#E36376', '#EA96A3', '#F1C8D0', '#3DA556'],
            activeFill: [false, false, false, false, false],
            coinLoaderBig: <div style={{ height: '200px', marginTop: '80px' }}> <Lottie speed={4} options={{ animationData: Coins, loop: true }} /></div>,
            data: {
                result: [],
                maxActual: 0,
                channelSelected: false
            },
            isLoading: true,
            activeRegion: ''
        }
        this.changeHeatmapMode = this.changeHeatmapMode.bind(this);
        this.setContent = this.setContent.bind(this);
        this.getHeatMap = this.getHeatMap.bind(this);
        this.setFillColors = this.setFillColors.bind(this);
        this.getPlaceInfo = this.getPlaceInfo.bind(this);
    }

    componentWillMount() {
        this.getHeatMap(this.props);
        this.props.getCommentCount(this, 'heatmap', this.props.access_token);
    }

    componentWillReceiveProps(nextProps) {
        const { fillColors, belowTargetColor } = this.state;
        if (nextProps.willUpdate) {
            this.setState({
                ...nextProps,
                // activeFillColor: nextProps.currChannel.value === 'ALL' ? fillColors: belowTargetColor,
                activeFillColor: fillColors,
                isLoading: true
            })

            this.getHeatMap(nextProps);
        } else {
            this.setState({
                willUpdate: nextProps.willUpdate
            })
        }

        if(nextProps.willUpdateCommentCount){
            nextProps.getCommentCount(this, 'heatmap', nextProps.access_token);
        }
    }

    changeHeatmapMode(e) {
        this.setState({
            heatmapMode: e.target.value
        })
    }

    getMaxActual(goalDetails) {
        return goalDetails.reduce((max, property) => property.achievedPremiumRate > max ? property.achievedPremiumRate : max, goalDetails[0].achievedPremiumRate);
    }

    groupByKey(xs, f) {
        if (xs !== undefined) {
            return xs.reduce((r, v, i, a, k = f(v)) => ((r[k] || (r[k] = [])).push(v), r), {});
        }
    }

    groupBy(result) {
        return [...result.reduce((r, o) => {
            const key = o.region;

            const item = r.get(key) || Object.assign({}, o, {
                achievedPremiumRate: 0,
                numberOfInsuranceCompanyContracts: 0,
                provinces: []
            });
            item.achievedPremiumRate += o.achievedPremiumRate;
            item.numberOfInsuranceCompanyContracts += o.numberOfInsuranceCompanyContracts;
            item.province = '';
            item.provinces.push(o.province)

            return r.set(key, item);
        }, new Map).values()];
    }

    groupByRegion(result) {
        if (result) {
            const formatResult = this.groupBy(result);
            return formatResult
        }

        return result
    }

    async getHeatMap(props) {
        let that = this;
        let headerParams = {
            x_auth: props.access_token
        };
        let filter = [];
        let provinceFilter = [];
        filter['metric'] = haveValue(props.metric) ? props.metric.value : '';
        filter['period'] = haveValue(props.period) ? props.period.value : '';
        filter['startDate'] = haveValue(props.startDate) ? props.startDate : '';
        filter['endDate'] = haveValue(props.endDate) ? props.endDate : '';
        filter['productOfferingType'] = haveValue(props.product) ? props.product.value : '';
        filter['channel'] = haveValue(props.channel) ? props.channel.value : '';
        filter['contractStatus'] = haveValue(props.status.value) ? props.status.value : '';
        filter['targetType'] = haveValue(props.target.value) ? props.target.value : '';
        provinceFilter['key'] = haveValue(props.channel) ? props.channel.value : '';

        let resultChannel = await apiModule("get_issued_per_channel", headerParams, null, createQueryStrings(filter))
            .then(function (result) {
                let data = {
                    result: [],
                    maxActual: 0,
                    channelSelected: false
                }

                return result.goalDetails;

            })
            .catch(function (err) {
                console.log(err);
            });
        let dataResult = that.groupByKey(resultChannel, (result) => result.region)
        // console.log(dataResult)

        await apiModule("get_heatmap", headerParams, null, createQueryStrings(filter))
            .then(function (result) {
                let data = {
                    result: [],
                    maxActual: 0,
                    channelSelected: false
                }
                if (result.goalDetails) {
                    // console.log(props.channel,result.goalDetails)
                    if (props.channel.value !== 'ALL') {
                        data.result = result.goalDetails.map((a) => {
                            a.achievedPremiumRate = dataResult[a.region][0].achievedPremiumRate;
                            a.numberOfInsuranceCompanyContracts = dataResult[a.region][0].numberOfInsuranceCompanyContracts;
                            return {
                                region: a.region,
                                province: a.province,
                                achievedPremiumRate: a.achievedPremiumRate,
                                numberOfInsuranceCompanyContracts: a.numberOfInsuranceCompanyContracts

                            }
                        });
                        // console.log(data.result);
                    } else {
                        data.result = result.goalDetails;
                    }

                    data.maxActual = that.getMaxActual(result.goalDetails);
                    data.channelSelected = props.channel.value === 'ALL' ? false : true;
                }

                that.setState({
                    data: data,
                    isLoading: false,
                });

            })
            .catch(function (err) {
                console.log(err);
            });

        // let commentHeaderParams = { chartId: 'heatmap', x_auth: props.access_token };
        // let commentFilter = [];
        // commentFilter['filter'] = 'count';

        // apiModule("get_comments", commentHeaderParams, null, createQueryStrings(commentFilter))
        //     .then(function (result){
        //         that.setState({
        //             totalComments: result.totalComments ? result.totalComments : 0
        //         })

        //     })
        //     .catch(function (err) {
        //         console.log(err)
        //     });
    }

    getSumInfo(result, label) {
        let achievedPremiumRateObj,
            plannedPremiumRateObj,
            numberOfInsuranceCompanyContractsObj;

        achievedPremiumRateObj = result.reduce((a, b) => +a + +b.achievedPremiumRate, 0);
        plannedPremiumRateObj = result.reduce((a, b) => +a + +b.plannedPremiumRate, 0);
        numberOfInsuranceCompanyContractsObj = result.reduce((a, b) => +a + +b.numberOfInsuranceCompanyContracts, 0);

        switch (label) {
            case "achievedPremiumRateObj":
                return <td>{thousandSeparator(roundNumber(achievedPremiumRateObj, 0))}</td>;
            case "plannedPremiumRateObj":
                return <td>{thousandSeparator(roundNumber(plannedPremiumRateObj, 0))}</td>;;
            case "numberOfInsuranceCompanyContractsObj":
                return <td>{thousandSeparator(numberOfInsuranceCompanyContractsObj, 0)}</td>;;
            default:
                return null
        }
    }


    getPlaceInfo(result, label) {
        let regionNameObj = [],
            achievedPremiumRateObj = [],
            plannedPremiumRateObj = [],
            numberOfInsuranceCompanyContractsObj = [];

        result.map(function (region, index) {
            if (region.hasOwnProperty("region")) {
                regionNameObj.push(<td>{region.region}</td>)
                achievedPremiumRateObj.push(<td key={index}>{thousandSeparator(roundNumber(region.achievedPremiumRate, 0))}</td>);
                plannedPremiumRateObj.push(<td key={index}>{thousandSeparator(roundNumber(region.plannedPremiumRate, 0))}</td>);
                numberOfInsuranceCompanyContractsObj.push(<td key={index}>{thousandSeparator(region.numberOfInsuranceCompanyContracts)}</td>);
            }
        })

        switch (label) {
            case "regionNameObj":
                return regionNameObj;
            case "achievedPremiumRateObj":
                return achievedPremiumRateObj;
            case "plannedPremiumRateObj":
                return plannedPremiumRateObj;
            case "numberOfInsuranceCompanyContractsObj":
                return numberOfInsuranceCompanyContractsObj;
            default:
                return null
        }
    }

    setContent(resultData, provinceName) {
        // console.log(provinceName, resultData)
        const { data } = this.state;
        // console.log(resultData)
        let obj = "";
        const { metric, startDate, endDate, channel } = this.props;
        let result = haveValue(resultData) ? resultData : []
        if ((haveValue(result) && channel.value !== 'ALL') || (provinceName && channel.value === 'ALL')) {
            const { numberOfInsuranceCompanyContracts, plannedPremiumRate } = result;
            let isChannel = data.channelSelected;
            // let isChannel = true;
            obj = <div>
                <table style={{ textAlign: 'left' }} className="mis-heatmap-tooltip">
                    <tbody>
                        <tr>
                            <td>{isChannel ? "Region" : "Province"}</td>
                            {isChannel ?
                                this.getPlaceInfo(result, "regionNameObj") :
                                <td style={{ textTransform: 'capitalize' }}>{provinceName}</td>}
                        </tr>
                        <tr>
                            <td>{metric.label}</td>
                            {isChannel ?
                                this.getPlaceInfo(result, 'achievedPremiumRateObj') :
                                this.getSumInfo(result, 'achievedPremiumRateObj')}
                        </tr>
                        {plannedPremiumRate ? <tr>
                            <td>Target</td>
                            {isChannel ?
                                this.getPlaceInfo(result, 'plannedPremiumRateObj') :
                                this.getSumInfo(result, 'plannedPremiumRateObj')}
                        </tr> : null}
                        <tr>
                            <td>Count</td>
                            {isChannel ?
                                this.getPlaceInfo(result, 'numberOfInsuranceCompanyContractsObj') :
                                this.getSumInfo(result, 'numberOfInsuranceCompanyContractsObj')}
                        </tr>
                        <tr>
                            <td>From</td>
                            <td colSpan="100">{moment(startDate).format('ll')}</td>
                        </tr>
                        <tr>
                            <td>To</td>
                            <td>{moment(endDate).format('ll')}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        }
        this.setState({
            content: obj,
            activeRegion: resultData ? resultData.region : ''
        })
    }

    setFillColors(index) {
        const { fillColors, grayscaled, activeFill, activeFillColor } = this.state;
        let newActiveFill = activeFill;
        let newActiveFillColor = [];
        let shouldUpdate = false;
        for (var i = 0; i < 5; i++) {
            if (index === i) {
                newActiveFill[i] = !newActiveFill[i]
                if (newActiveFill[i]) {
                    shouldUpdate = true;
                    newActiveFillColor[i] = fillColors[i];
                }
            } else {
                newActiveFill[i] = false;
                newActiveFillColor[i] = grayscaled[i];
            }
        }

        this.setState({
            activeFill: newActiveFill,
            activeFillColor: shouldUpdate ? newActiveFillColor : fillColors
        })
    }

    render() {
        const { provinceUrl, metroManilaUrl, properties, heatmapMode, content, isLoading, data, fillColors, grayscaled, activeFill, activeFillColor, coinLoaderBig, activeRegion, totalComments } = this.state;
        const { currChannel, currStatus, channel, onClickCommentIcon, currTarget } = this.props;
        let isPD = currTarget.value.toUpperCase().includes('PD');
        let that = this;
        return (
            <VisibilitySensor onChange={(e) => {
                if (e === false) {
                    let t = this;
                    this.setState({
                        content: null,
                    }, function () {
                        t.forceUpdate();
                    });
                }
            }}>
                <div className="container mis-container col-sm-12 col-md-12 col-lg-6 col-xl-6">
                {/* <div className="mis-comment-icon-container" onClick={() => onClickCommentIcon(charts.heatmap)}>
                    <div className="mis-comment-icon">
                        <img src = "/mis/comments.svg" className="mis-comment-icon-img"/>
                        <span className="mis-comment-count">
                            {formatValuesNoDecimals(totalComments)}
                        </span>
                    </div>
                </div> */}
                    <CommentIcon
                        chart={charts.heatmap}
                        onClickCommentIcon={onClickCommentIcon} 
                        totalComments={totalComments} />
                    <div className={"card mis-card-2 " + (!this.props.isSm ? "" : "heatmap-container-ovrd")}>
                        <div className="card-body mis-card-body">
                            <div className="card-title mis-card-title">
                                <span className="d-flex dashboard-data-header__text align-items-end font-neo-semibold text-headercolor">Performance Heatmap</span>
                            </div>
                            {isPD ? showNoData(true) :
                                isLoading ? coinLoaderBig :
                                <div style={{ position: "relative" }}>
                                    <VisibilitySensor partialVisibility={true} scrollCheck={true} resizeCheck={true} onChange={(e) => {
                                        if (e === false) {
                                            let t = this;
                                            this.setState({
                                                content: null,
                                            }, function () {
                                                t.forceUpdate();
                                            });
                                        }
                                        console.log('visibsensMM', e);
                                    }}><div className="mis-detailed-container">
                                            <DetailedMap
                                                data={data}
                                                topoUrl={metroManilaUrl}
                                                fillColors={activeFillColor}
                                                grayscaled={grayscaled}
                                                activeFill={activeFill}
                                                mode={heatmapMode}
                                                mapConfig={MAP_CONFIG.METRO_MANILA}
                                                // hoveredObj={(properties) => { this.setState({ properties: properties }) }} 
                                                setTooltipContent={this.setContent} // SLOWNESS TRACKING (TEMP DISABLE)
                                                activeRegion={activeRegion} />
                                        </div>
                                    </VisibilitySensor>
                                    <VisibilitySensor onChange={(e) => {
                                        if (e === false) {
                                            let t = this;
                                            this.setState({
                                                content: null,
                                            }, function () {
                                                t.forceUpdate();
                                            });
                                        }
                                        console.log('visibsensPH', e);
                                    }}><div className="mis-philippines-map">
                                            <DetailedMap
                                                data={data}
                                                topoUrl={provinceUrl}
                                                fillColors={activeFillColor}
                                                grayscaled={grayscaled}
                                                activeFill={activeFill}
                                                mode={heatmapMode}
                                                mapConfig={MAP_CONFIG.PHILIPPINES}
                                                // hoveredObj={(properties) => { this.setState({ properties: properties }) }} 
                                                setTooltipContent={this.setContent}  // SLOWNESS TRACKING (TEMP DISABLE)
                                                activeRegion={activeRegion} />
                                        </div></VisibilitySensor>
                                    {content !== null ? <ReactTooltip
                                        // className={!this.props.isSm ? "" : "ovrdpos-tooltip"} 
                                        style={{ width: !this.props.isSm ? 'auto' : '100%' }}
                                        overridePosition={
                                            (a, b, c, d, e, f, g, h) => {
                                                // console.log(a, b, c, d, e, f, g, h);
                                                if (a.left < 0 || this.props.isSm) {
                                                    a.left = 0;
                                                    // a.top = 128;
                                                }
                                                if (a.top < 0) {
                                                    a.top = 0;
                                                }
                                                return a;
                                            }} style={{ position: 'absolute' }} clickable>{content}</ReactTooltip> : ""}
                                    <div className="mis-legend-heatmap">
                                        {data.maxActual ? activeFillColor.map(function (colorObj, colorIndex) {
                                            return (
                                                <div key={'heatmap-legend-' + colorIndex} style={{ backgroundColor: colorObj }} className="mis-legend-heatmap-item" onClick={() => { that.setFillColors(colorIndex) }}>
                                                    {colorIndex === 0 ? <p className="mis-legend-heatmap-desc-0">0</p> : ''}
                                                    <p className="mis-legend-heatmap-desc">{formatValues((data.maxActual / 4 * (colorIndex + 1)))}</p>
                                                </div>
                                            )
                                        }) : null}
                                    </div>
                                </div>}
                        </div>
                    </div>
                </div>
            </VisibilitySensor>
        )
    }
}

export default withWindowDimensions(Heatmap);