import React, { Component } from 'react'
import ContestListItem from '../components/contestListItem';
import SectionHeader from '../components/sectionHeader';
import { getContestUnderCategory } from '../commons/restEndpoint';
import MiniLoading from '../components/miniLoading';
import { IndexColor } from '../commons/service';
import { haveValue } from '../../../commons/misConfig';

export default class contestCategory extends Component {

    constructor(props) {
        super(props)

        this.state = {
            contestsList: [
                /* {
                    header: "New Year, New Beginnings",
                    description: "Let us all welcome 2020 with a bang by selling products to win a vacation package for 2 to Netherlands",
                    expiration: "Ends on January 6, 2020",
                    joinedCount: 74,
                    background: '#FF934F',
                    color: 'white',
                    image: '/rni/list-test-image-1.jpg',
                }, */
            ],
            doneGettingListOfContests: false,
        }

        this.getContests = this.getContests.bind(this);
    }

    async getContests() {
        let params = {
            status: this.props.archived ? 'inactive':'active'
        }
        let contestsList = await getContestUnderCategory(this.props.categoryId, params, this.props.session.access_token);
        if (contestsList.data) {
            var list = [];

            contestsList.data.map(function (contest, index) {
                list.push({
                    header: contest.contestTitle,
                    description: contest.contestDescription,
                    expiration: contest.contestExpiryDt,
                    joinedCount: contest.contestTotalUsers,
                    background: IndexColor(contest.contestId ? contest.contestId : 0),
                    color: 'white',
                    image: contest.contestImg,
                    contestId: contest.contestId
                });
                return null;
            });

            this.setState({
                contestsList: list,
                doneGettingListOfContests: true,
            });
        }
    }

    componentDidMount() {
        this.getContests();
    }


    render() {
        const { doneGettingListOfContests, contestsList } = this.state;
        console.log(haveValue(contestsList))
        return (
            <div className="rni-contest-category-container container">
                <div className="col-md-12 col-xs-12 col-lg-12 col-sm-12">
                    <SectionHeader label={"Contests under " + this.props.name} src="/rni/gold-medal.png" />
                    {doneGettingListOfContests ? 
                        <div className="row">{
                            contestsList.map(function (contest, index) {
                                let alt = false;
                                if (index % 2 !== 0) {
                                    alt = true;
                                }
                                return (<div key={index}
                                    className="col-md-6 col-xs-12 col-sm-12 col-lg-6"
                                    style={{ paddingTop: '15px', paddingBottom: '15px' }} >
                                    <ContestListItem data={contest} alternate={alt} /> </div>
                                );
                            })
                        }</div>
                    :   <div style={{ width: '50vw', margin: 'auto' }}><MiniLoading /></div>} 
                </div> 
            </div>
        )
    }
}