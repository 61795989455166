import React from 'react';
import { Link } from 'react-router-dom';
import Auxi from './auxi';

const clientPolicyNav = (props) => {
    return (
        <div className="dashboard-nav d-none d-md-block">
            <ul className="dashboard-nav__list px-2">
                <li className={`dashboard-nav__list-item ${props.clientInfo}`}><Link id="clientInfo" className={`dashboard-nav__link text-decoration-none ${props.clientInfo}`} style={{ color: "#9A9B9C" }} to="/dashboard/client-information">Client Information</Link></li>
                <li className={`dashboard-nav__list-item ${props.policyList}`}><Link id="policyList" className={`dashboard-nav__link text-decoration-none ${props.policyList}`} style={{ color: "#9A9B9C" }} to="/dashboard/list-of-policies">List of Policies</Link></li>
                <li className={`dashboard-nav__list-item ${props.hasNewlyAssignedPolicies ? props.newlyAssignedPolicyList : "d-none"}`}><Link id="newlyAssignedPolicyList" className={`dashboard-nav__link text-decoration-none ${props.newlyAssignedPolicyList}`} style={{ color: "#9A9B9C" }} to="/dashboard/newly-assigned-policies">Newly Assigned Policies</Link></li>
            </ul>
            <div className="dashboard-nav__border border-bottom"></div>
        </div>
    );
}
export default clientPolicyNav;
