import React, { Component } from 'react';
// import Select from 'react-select';
import TextareaAutosize from 'react-textarea-autosize';
import SectionHeader from '../components/sectionHeader';
import { submitSupportTicket } from '../commons/restEndpoint';
// import MiniLoading from '../components/miniLoading';
import { NavLink } from 'react-router-dom';
import Menu from '../components/menu';
// import { ConsoleLineIcon } from 'mdi-react';

// const rniSupportTypes = [
//     {
//         value: "Inquiry",
//         label: "Inquiry"
//     },
//     {
//         value: "Request",
//         label: "Request"
//     },
//     {
//         value: "Comment/Suggestion",
//         label: "Comment/Suggestion"
//     },
// ];

const rniSupportTypes2 = [
    {
        categoryId: null,
        contestId: null,
        imgUrl: "/rni/question.png",
        link: null,
        name: "Question",
    },
    {
        categoryId: null,
        contestId: null,
        imgUrl: "/rni/request.png",
        link: null,
        name: "Request",
    },
    {
        categoryId: null,
        contestId: null,
        imgUrl: "/rni/feedback.png",
        link: null,
        name: "Comment/Suggestion",
    }
];

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        borderBottom: 'none',
        color: state.isSelected || state.isFocused ? '#006192' : '#414141',
        background: '#FFFFFF',
        fontSize: '16px',
        fontFamily: 'Allianz Neo',
        fontWeight: '400',
        padding: 15,
    }),
    indicatorSeparator: (provided, state) => ({
        opacity: 0,
    }),
    dropdownIndicator: (provided, state) => ({
        color: '#414141',
    })
}

export default class support extends Component {
    constructor(props) {
        super(props)

        this.state = {
            step: 0,
            subject: "",
            supportType: {
                value: null,
                label: null
            },
            message: "",
            isSubmitting: false,
            submitSuccess: false,
            allowSubmit: false,
            submitMessage: "",
        }

        this.setSubjectFormField = this.setSubjectFormField.bind(this);
        this.changeMessage = this.changeMessage.bind(this);
        this.changeSupportType = this.changeSupportType.bind(this);
        this.submitTicket = this.submitTicket.bind(this);
        this.validateForm = this.validateForm.bind(this);
    }

    validateForm() {
        var validSubject = this.state.subject.match(/.*\S.*/);
        var validMessage = this.state.message.match(/.*\S.*/);
        var validType = this.state.supportType.value !== null;
        this.setState({
            allowSubmit: ((validSubject ? true : false) === true) && (validType === true) && ((validMessage ? true : false) === true),
        });
    }

    async submitTicket() {
        this.setState({
            submitMessage: "",
            submitSuccess: false,
            isSubmitting: true,
        });
        let x = await submitSupportTicket(this.state.message, this.state.subject, this.state.supportType.value, this.props.session.access_token);
        this.setState({
            isSubmitting: false,
            submitSuccess: x,
            step: 1,
            submitMessage: x ? 'Thank you for getting in touch!\nWe will get back to you as soon as possible.\nCheck your email for details.' : 'There was a problem while submitting your concern',
            subject: x ? "" : this.state.subject,
            message: x ? "" : this.state.message,
            supportType: x ? { value: null, label: "Type" } : this.state.supportType,
        });

    }

    changeMessage(e) {
        var thisCom = this;
        this.setState({
            message: e.target.value,
        }, function () {
            thisCom.validateForm();
        });
    }

    changeSupportType(e) {
        let x = { value: null, label: "Type" };
        switch (e.name) {
            case 'Question': x = { value: 'QUESTION', label: "Question" }; break;
            case 'Request': x = { value: 'REQUEST', label: "Request" }; break;
            case 'Comment/Suggestion': x = { value: 'COMMENT/SUGGESTION', label: "Comment/Suggestion" }; break;
            default: x = { value: null, label: "Type" }; break;
        }
        var thisCom = this;
        this.setState({
            supportType: x,
        }, function () {
            thisCom.validateForm();
        });
    }

    setSubjectFormField(e) {
        var thisCom = this;
        this.setState({
            subject: e.target.value
        }, function () {
            thisCom.validateForm();
        });
    }


    render() {
        return (
            <div className="container rni-support-container">
                {this.state.step === 0 ?
                    <div>
                        <div className="rni-support-banner-container">
                            {/* <img src="/rni/conversation.png" className="rni-support-banner-image" />
                            <label className="rni-support-banner-text">Contact Us</label>
                            <div className="rni-default-horizontal-rule"></div> */}
                            <SectionHeader label="Contact Us" src="/rni/conversation.png" />
                        </div>
                        <div className="rni-support-form-container">
                            <p className="rni-support-form-main-message">
                                Please help us identify the issue you are experiencing
                    </p>
                            <div className="col-md-12 col-xs-12 col-sm-12 col-lg-12">
                                <div className="fieldForm">
                                    <input value={this.state.subject}
                                        onChange={(e) => { this.setSubjectFormField(e) }}
                                        onBlur={(event) => { }}
                                        type="text"
                                        id="subject"
                                        name="subject"
                                        placeholder=" "
                                        maxLength="50" />
                                    <label htmlFor="subject">Subject</label>
                                </div>
                            </div>
                            <div className="col-md-12 col-xs-12 col-sm-12 col-lg-12" style={{ marginTop: '25px' }}>
                                <label style={{ fontFamily: '"Allianz Neo"', fontWeight: '200', textAlign: 'left', paddingLeft: '5px', fontSize: 'calc(14px + 0.02vw)', color: '#414141', marginBottom: '0px' }}>Support Type</label>
                                <Menu noBorder supportType={this.state.supportType.label} menuList={rniSupportTypes2} callback={(menuSelected) => {
                                    this.changeSupportType(menuSelected);
                                }} />
                                {/* <Select
                                    styles={customStyles}
                                    value={this.state.supportType}
                                    placeholder="Type"
                                    onChange={(e) => { this.changeSupportType(e); }}
                                    onClick={() => { }}
                                    onBlur={() => { }}
                                    options={rniSupportTypes}
                                    className="rni-select-container"
                                    classNamePrefix="rni-select"
                                /> */}
                            </div>
                            <div className="col-md-12 col-xs-12 col-sm-12 col-lg-12">
                                <br />
                                {this.state.message !== null && this.state.message !== "" ?
                                    <label style={{ fontFamily: '"Allianz Neo"', fontWeight: '200', textAlign: 'left', paddingLeft: '5px', fontSize: 'calc(14px + 0.02vw)', color: '#414141', marginBottom: '0px' }}>Message</label> : ""}
                                <TextareaAutosize maxRows={5} minRows={1} onChange={(e) => { this.changeMessage(e); }} value={this.state.message} placeholder="Type your message here" className="rni-support-textarea" />
                            </div>
                            <div style={{ textAlign: 'center' }} className="col-md-12 col-xs-12 col-sm-12 col-lg-12">
                                <button onClick={() => { this.submitTicket() }} className="rni-support-submit-button" disabled={this.state.allowSubmit === false || this.state.isSubmitting === true}>{this.state.isSubmitting ? "SUBMITTING" : "SUBMIT"}</button>
                            </div>
                        </div>
                    </div>
                    :
                    <div>
                        <div className="rni-support-banner-container">
                            {/* <img src="/rni/conversation.png" className="rni-support-banner-image" />
                            <label className="rni-support-banner-text">Contact Us</label>
                            <div className="rni-default-horizontal-rule"></div> */}
                            <SectionHeader label="Contact Us" src="/rni/conversation.png" />
                        </div>
                        <div className="rni-support-form-container">
                            <div style={{ textAlign: 'center', paddingTop: '15px' }}>
                                <br />
                                <img style={{
                                    width: '128px',
                                    height: '128px',
                                    margin: 'auto',
                                    animation:'fade-in 0.25s ease-in'
                                }}
                                    src={this.state.submitSuccess ? "/rni/success-lg.png" : "/rni/error-lg.png"} />
                            </div>
                            <p className="rni-support-form-main-message">
                                {this.state.submitMessage}
                            </p>

                            <NavLink style={{ textDecorationLine: 'none' }}
                                to={"/rewards-and-incentives/"}>
                                {/* <img style={{
                                    width: '24px',
                                    height: '24px'
                                }}
                                    src="/rni/angle-left-solid.svg" /> */}
                                <label style={{
                                    fontSize: '16px',
                                    cursor: 'pointer',
                                    fontFamily: 'neo_semibold',
                                    marginBottom: '0px',
                                    color: '#007ab3',
                                    width: '100%',
                                    textAlign: 'center'
                                }}>
                                    Back to home
                                    </label>
                            </NavLink>
                        </div>
                    </div>
                }
            </div>
        )
    }
}
