import React, { Component } from 'react';
// import './App.css';
// import './css/Responsive.css';
import { distanceDate, checkSearch, writeData, readAllData, encrypt, decrypt } from './commons/utility.js';
import Login from './components/login.js';
import Routes from './commons/routes.js';
import { myConfig } from './commons/config.js';
import { BrowserRouter as Router } from 'react-router-dom';
import { apiModule, createQueryStrings } from './commons/apiCall';
import { write } from 'fs';

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      page: 'dashboard',
      isLoading: true,
      session: {
        jwt: '1234567890',
        view_as_number: '',
        view_as_role: '',
        view_as_channel: '',
        view_as: '',
        view_as_email: '',
        username: '',
        code: '',
        image: '',
        alias: '',
        name: '',
        resetPassword: false,
        access_token: '',
        user: null,
        role: "Financial Advisor",
        channel: '',
        agentNumber: '',
        permissions: [],
        csdPermissions: [],
        refresh_token: '',
        user_role: ''
      },
      loginMessage: '',
      search_loading: false,
      search_error: "",
      search_result: {
        content: []
      },
      search_query: '',
      policyorclient: {
        clientId: '-1',
        policyId: '-1',
        faId: '-1',
        PolicyType: 'TRAD'

      },
      clientId: '',
      Id: 2,
      clientName: '',
      logo: '',
      // toggleDrawer     : undefined
      toggleDrawer: undefined,

      timerid: -1,
      sessionEndsSoon: false,
      refreshTokenTimerId: -1

    };
    this.getSessionTimeout = this.getSessionTimeout.bind(this);
    this.setRefreshTokenTimer = this.setRefreshTokenTimer.bind(this);
    this.stopRefreshTokenTimer = this.stopRefreshTokenTimer.bind(this);
    this.handleRefreshToken = this.handleRefreshToken.bind(this);
  }
  componentWillUnmount() {
    this.setState({ isLoading: false });
    //console.log(" APP IS UNMOUNTING......");
    // clearTimeout(this.state.timerid);
  }
  async componentDidMount() {
    const parsedData = await readAllData('session');
    //console.log("App mount");
    if (parsedData != "") {
      let item = {
        jwt: parsedData[0].jwt,
        name: decrypt(parsedData[0].name),
        resetPassword: parsedData[0].resetPassword,
        view_as: decrypt(parsedData[0].view_as),
        view_as_number: decrypt(parsedData[0].view_as_number),
        view_as_email: decrypt(parsedData[0].view_as_email),
        view_as_channel: decrypt(parsedData[0].view_as_channel),
        user: parsedData[0].user,
        username: parsedData[0].username,
        role: parsedData[0].role,
        channel: parsedData[0].channel,
        access_token: parsedData[0].access_token,
        agentNumber: decrypt(parsedData[0].agentNumber),
        image: parsedData[0].image,
        alias: parsedData[0].alias,
        user_role: parsedData[0].user_role,
        refresh_token: parsedData[0].refresh_token,
        csdPermissions: parsedData[0].csdPermissions
      }



      this.setState({ session: item, isLoading: false });
      this.getSessionTimeout();

    }
    else {
      this.setState({ isLoading: false });
      this.getSessionTimeout();
    }

    this.setRefreshTokenTimer();
  }

  logoutF() {
    let that = this;
    readAllData('session')
      .then(function (session_result) {

        if (session_result!== null && session_result !== undefined && session_result.length > 0 && session_result[0].username !== "") {
          alert("Session has expired");
          let session = {resetPassword: false,  alias: '', image: '', view_as: '', view_as_email: '', view_as_number: '', name: '', agentNumber: '', code: '', permissions: [], csdPermissions: [], username: '', channel: '', id: 1, user_role: '', refresh_token: '' };
          that.setUserSession(session);
          writeData('actions', {last_action: Date.now(), id: 1});
          writeData('session', session).then(function (result) {
            console.log(result);
           // window.location = "/";
            setTimeout(function() { window.location = "/";} ,1000);
          })
        } else {
          console.log("User is logged out");
          // that.stopRefreshTokenTimer();
        }

      });



  }

  setRefreshTokenTimer() {
            /*console.log('starting refresh token...');
            let refreshTokenTimerId = setInterval(this.handleRefreshToken, (parseInt(myConfig.REFRESH_TOKEN_EVERY) * 1000));
            this.setState({
              refreshTokenTimerId
            })*/
  }

  stopRefreshTokenTimer() {
      console.log('stopping refresh token...');
      clearInterval(this.state.refreshTokenTimerId);
  }

  handleRefreshToken() {
    let that = this;
      readAllData('session')
        .then(function (session) {
          console.log('session', session);
            if(session.length > 0 && session[0].refresh_token) {
              console.log('calling refresh token...');
              const refreshToken = session[0].refresh_token;
              const payloadParams = {
                grant_type: 'refresh_token',
                refresh_token: refreshToken
              };
              apiModule("authentication_refresh_token", {}, payloadParams, '', true)
                .then(function (result) {
                    if(result.status === 200) {
                      readAllData('session')
                      .then(function (data) {
                          let userData = {};
                          for (var dt of data) {
                              userData = dt;
                              dt.access_token = result.data.access_token;
                              dt.refresh_token = result.data.refresh_token;
                          }
                          writeData('session', userData).then(function () {
                              console.log("Updating session done!");
                          });
                      });
                    }

                })
                .catch(function (err) {
                  console.log(err)
                })
            }else {
              that.stopRefreshTokenTimer();
            }
         });
  }



  getSessionTimeout() {
    let that = this;
    return new Promise(function (resolve, reject) {

      readAllData('actions')
        .then(function (action_result) {

          if(action_result.length > 0) {
            let inactiveTime = distanceDate(action_result[0].last_action, "seconds");
            let timeoutSession = (parseInt(myConfig.SESSION_TIMEOUT) * 60);
            //console.log("Checking the session - inactive for  " + inactiveTime + " seconds: (" + timeoutSession + ")");
  
            if (inactiveTime >= timeoutSession) {
              that.logoutF();
            }
  
            let timerid = setTimeout(() => {
              that.getSessionTimeout();
            }, (parseInt(myConfig.CHECK_SESSION_TIMEOUT_EVERY) * 1000));
            resolve(timerid);
            
          }else {
            writeData('actions', {last_action: Date.now(), id: 1})
            .then(function(){

              readAllData('actions')
              .then(function (action_result) {
                let inactiveTime = distanceDate(action_result[0].last_action, "seconds");
                let timeoutSession = (parseInt(myConfig.SESSION_TIMEOUT) * 60);
                console.log("Checking the session - inactive for  " + inactiveTime + " seconds: (" + timeoutSession + ")");
      
                if (inactiveTime >= timeoutSession) {
                  that.logoutF();
                }
      
                let timerid = setTimeout(() => {
                  that.getSessionTimeout();
                }, (parseInt(myConfig.CHECK_SESSION_TIMEOUT_EVERY) * 1000));
                  resolve(timerid);
              });
           });
          }
         
        })
    });



  }
  toggleSidebar() {
    let drawer = !this.state.toggleDrawer;
    console.log("drawer")
    console.log(drawer)
    this.setState({ toggleDrawer: drawer });
  }
  getLoginMessage() {
    return this.state.loginMessage;
  }

  setLoginMessage(loginMessage) {
    return this.setState({ loginMessage: loginMessage });
  }

  getSession() {
    return this.state.session;
  }

  setUserSession(user) {
    return this.setState({ session: user });
  }

  getClientOrPolicy() {
    return this.state.policyorclient;
  }

  setClientOrPolicy(item) {
    this.setState({ policyorclient: item });
  }
  errorMessage = (msg) => {
    console.log(msg);
    this.setState({ search_error: msg });
  }
  resetMessage = () => {
    this.setState({ search_error: '' });
  }
  doSearch(query, pageNumber) {

    let that = this;
    that.setState({
      search_loading: true
    })

    let jwt = this.getSession().access_token;
    let headerParams = {
      x_auth: jwt,
    };

    let filter = [];
    filter['query'] = query;
    filter['pageNumber'] = pageNumber;
    filter['agentNumber'] = decrypt(this.getSession().agentNumber);
    filter['pageSize'] = myConfig.PAGE_SIZE;

    let payloadParams = {};

    if (checkSearch(query, this.errorMessage, 2)) {
      this.resetMessage();
      apiModule("policy_search", headerParams, null, createQueryStrings(filter))
        .then(function (result) {

          if (Array.isArray(result)) {
            if (result.length > 0) {
              that.setState({
                search_result: result[0],
                search_query: query,
                search_loading: false
              })
            } else {
              that.setState({
                search_result: { content: [] },
                search_query: query,
                search_loading: false
              })
            }

          } else {
            that.setState({
              search_result: { content: [] },
              search_query: query,
              search_loading: false
            })
          }

        })
        .catch(function (err) {
          that.setState({
            search_result: { content: [] },
            search_query: query,
            search_loading: false
          })
        })
    } else {
      that.setState({
        search_query: query,
        search_loading: false
      })
    }

  }
  getSearchError = () => {
    return this.state.search_error;
  }
  getSearchResults = () => {

    if (this.state.search_result != null) {
      return this.state.search_result.content;
    } else {
      return null;
    }
  }
  getSearchQuery = () => {
    return this.state.search_query;
  }
  getSearchLoading = () => {
    return this.state.search_loading;
  }
  getSearchMessage = () => {
    return this.state.search_loading;
  }
  changePageHandler = (pageId) => {
    this.setState({ page: pageId });
  }

  checkSession = () => {
    return this.state.session;
  }

  doMimic = (view_as_number, view_as, view_as_email, view_as_role, view_as_channel, permissions, history, path) => {
    let userSession = this.state.session;
    let that = this;

    if(view_as_email !== undefined && view_as_email !== null) {
      view_as_email = encrypt(view_as_email);
    }else {
      view_as_email = "";
    }

    if(view_as !== undefined && view_as !== null) {
      view_as = encrypt(view_as);
    }else {
      view_as = "";
    }

    userSession.view_as = view_as;
    userSession.view_as_number = view_as_number;
    userSession.view_as_email = view_as_email;
    userSession.agentNumber = view_as_number;
    userSession.view_as_role = view_as_role;
    userSession.view_as_channel = view_as_channel;
    userSession.role = view_as_role;
    if(permissions !== null) {
      userSession.csdPermissions = userSession.permissions;
      userSession.permissions = permissions;
    }
    else{
      userSession.permissions = userSession.csdPermissions;
      userSession.csdPermissions = [];
    }

    readAllData('session')
    .then(function (session_result) {
      session_result[0].view_as = view_as;
      session_result[0].view_as_number = view_as_number;
      session_result[0].view_as_email = view_as_email;
      session_result[0].agentNumber = view_as_number;
      session_result[0].view_as_role = view_as_role;
      session_result[0].view_as_channel = view_as_channel;
      session_result[0].role = view_as_role;
      if(permissions !== null) {
        session_result[0].csdPermissions = session_result[0].permissions;
        session_result[0].permissions = permissions;
      }
      else{
        session_result[0].permissions = session_result[0].csdPermissions;
        session_result[0].csdPermissions = [];
      }
      writeData('session', session_result[0])
      .then(function () {
        that.setState({
          session: userSession
        }, ()=> {
          if(path === "/dashboard/list-of-users") {
           history.push(path);
          }else {
            window.location = path;
          }
         
        })
      })
      .catch(function (err) {
        console.log(err);
      });
    }).catch(function (err) {
            console.log(err);
    });
  }

  setViewAs = (view_as_number, view_as, view_as_email, history, path) => {
    let userSession = this.state.session;
    let that = this;
    let view_as_role = "";
    let view_as_channel = "";
    if(view_as_number === "") {
      //Clearing the setViewAs to give way to a new user
      that.doMimic(view_as_number, view_as, view_as_email,view_as_role, view_as_channel, null, history, path)
    }else {
      let headerParams = {
        accountManagerId : view_as_number,
        x_auth : this.state.session.access_token
      }
  
      let headerParams2 = {
        accountManagerId : view_as_number,
        x_auth : this.state.session.access_token
      }

      //view_as_role
     //userRole
      //roleNameOfAccountManager
      apiModule("get_accountmanager_permission", headerParams, null)
      // apiModule("get_webuser_permissions", headerParams, null)
      .then(function (permissions_result) {
          let permissions = [];
          console.log(permissions_result.data);
          for(let i = 0; i < permissions_result.data.length; i++) {
            permissions.push(permissions_result.data[i].name);
          }
          //adding permission for the internal user LIST OF USERS
          if(view_as_number !== 2011547 && view_as_number !== 2013745) {
            permissions.push("VIEW_CS");
          }
          
          apiModule("get_accountmanager", headerParams2, null)
          .then(function (account_manager_result) {
            if(account_manager_result !== null && account_manager_result !== undefined){
              if(account_manager_result.roleNameOfAccountManager !== null && account_manager_result.roleNameOfAccountManager !== undefined) {
                view_as_role =  account_manager_result.roleNameOfAccountManager;
                view_as_channel = account_manager_result.accountManagerCategory;
              } 
            }

            that.doMimic(view_as_number, view_as, view_as_email, view_as_role, view_as_channel, permissions, history, path);

          })
          .catch(function(err) {
              console.log(err);
              console.log("ERROR::: Error getting the role of this user")
              that.doMimic(view_as_number, view_as, view_as_email, view_as_role, view_as_channel, null, history, path);
          });
      })
      .catch(function(err) {
          console.log(err);
          console.log("ERROR::: Error getting the permission for this user! We will use permission of Internal User")
          that.doMimic(view_as_number, view_as, view_as_email, view_as_role, view_as_channel, null, history, path);
      });
    }
    
  }

  getViewAs = () => {
    let viewBlock = (
      <div className="viewing">
        <div className="viewing__as">
          <p className="viewing__as-text">Logged in As:</p>
          <button className="viewing__as-value btn btn-dropdowncolor font-semibold">{decrypt(this.state.session.name)}</button>
        </div>
        <div className="viewing__as">
          <p className="viewing__as-text">View As:</p>
          <button className="viewing__as-value btn btn-dropdowncolor font-semibold">{decrypt(this.state.session.view_as)}</button>
        </div>
      </div>
    );
    if (this.state.session.view_as === '') {
      viewBlock = "";
    }

    return viewBlock;
  }
  // <Timeline/>
  render() {
    if (this.state.isLoading) {
      return (<div>Loading...</div>)
    } else {
      return (
        <Routes
          dbSession={() => this.checkSession()}
          pageId={this.state.page}
          clientId={this.state.clientId}
          Id={this.state.Id}
          clientName={this.state.clientName}
          logo={this.state.logo}
          changePageHandler={(pageId) => this.changePageHandler(pageId)}
          changeClientId={(clientId, name, logo, Id) => this.changeClientId(clientId, name, logo, Id)}
          setUserSession={(user) => this.setUserSession(user)}
          getSession={() => this.getSession()}
          setClientOrPolicy={(item) => this.setClientOrPolicy(item)}

          getLoginMessage={() => this.getLoginMessage()}
          setLoginMessage={(item) => this.setLoginMessage(item)}

          getClientOrPolicy={() => this.getClientOrPolicy()}
          doSearch={(query, pageNumber) => this.doSearch(query, pageNumber)}
          getSearchLoading={() => this.getSearchLoading()}
          getSearchMessage={() => this.getSearchMessage()}
          getSearchResults={() => this.getSearchResults()}
          getSearchQuery={() => this.getSearchQuery()}
          getSearchError={() => this.getSearchError()}

          toggleSidebar={() => this.toggleSidebar()}
          toggleDrawer={this.state.toggleDrawer}
          setViewAs={(view_as_number, view_as, view_as_email, history, path) => this.setViewAs(view_as_number, view_as, view_as_email, history, path)}
          getViewAs={this.getViewAs()}
          setRefreshTokenTimer={() => this.setRefreshTokenTimer()}
        >
        </Routes>
      );
    }


  }
}

export default App;
