import React from "react";
import Auxi from "../../auxi";
import TableLoader from '../../TableLoader/tableLoader';

const PoliciesMobile = (props) => {
    const customName = props.customName;
    const data = props.data;
    const title = props.title;
    const excuded = [
        'Policy Number',
        'Owner',
    ];

    let fas = data.map((item, index) => {
        return (
            <Auxi key={index}>
                <div className={`data-accord__card ${index > 0 ? "mt-3" : ""} card border-0`}>
                    <div className="data-accord__toggle btn" data-toggle="collapse" data-target={`#collapse-${index}`}>
                        <div className="row data-accord__start">
                            <div className="col-3">
                                <button className="data-accord__name data-accord__name--clear">{item.contractNumber? item.contractNumber: "--"}</button>
                            </div>
                            <div className="col-9 d-flex">
                                <div className="font-neo-semibold w-100">{item.contractHolder? title[1].accessor(item): '--'}</div>
                                <button className="btn p-0" type="button">
                                    <div className="data-accord__caret ml-2">
                                        <div className="data-accord__caret-icon"></div>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div id={`collapse-${index}`} className="collapse" data-parent="#data-accord">
                        <div className="card-body data-accord__content">
                            <div className="row">
                                { 
                                    customName != null || customName != undefined? (
                                    customName.map((rowData, index) => {
                                        return(
                                            <>
                                            {
                                                !excuded.includes(rowData["Header"])? (
                                                    <>
                                                    <div className="data-accord__column col-12">
                                                        <div className="data-accord__label"><strong>{rowData["Header"]}</strong></div>
                                                        <div className="data-accord__value">
                                                            {
                                                                typeof rowData.accessor === 'string'? (
                                                                    item[rowData.accessor]? item[rowData.accessor]: "--"
                                                                ): (
                                                                    rowData.accessor(item)? rowData.accessor(item): "--"
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                    </>
                                                ) : ('')
                                            }
                                            </>
                                        );
                                    })
                                    ) : ('')
                             }
                            </div>
                        </div>
                    </div>
                </div>
            </Auxi >
        );
    });

    return (
        <>
        <div className="data-accord row flex-wrap mt-4" >
                {(
                    <div className="data-accord__header">
                        <div className="col-5">
                            <div className="d-inline-flex align-items-center cs-pointer">
                                {props.title[0]["Header"]}
                            </div>
                        </div>
                        <div className="col-7">
                            <div className="d-inline-flex align-items-center cs-pointer">
                                {props.title[1]["Header"]}
                            </div>
                        </div>
                    </div>
                )}
            <div className="col-12 data-accord__body mt-1">
                {props.isTableLoading? <div className="mt-5"><TableLoader loading={true} /></div>: ""}
                <div className="data-accord__collapse" id="data-accord">
                    {data.length === 0 && !props.isTableLoading? (<div className="center-align dashboard-row-data font-neo-semibold mt-4">No records found </div>): "" }
                    {!props.isTableLoading? fas: ""}
                </div>
            </div>
        </div>
        {props.children}
        </>
    );
}

export default PoliciesMobile;
