import React, { Component } from 'react';
import '../css/faPersistency.css';
import FAPersistencyChart from './Mis/faPersistencyChart';
import { apiModule, createQueryStrings } from '../commons/apiCall';
import { getSessionInfo } from '../commons/security';
import { myConfig } from '../commons/config';
import { mockPersistencyData } from '../commons/mockData';
import { decrypt, renameByChannel } from '../commons/utility';
import { months } from '../commons/misConfig';
import LoaderGif, { LoaderGif2, LoaderGif3 } from '../commons/loaderGif';
import withWindowDimensions from '../commons/withWindowDimensions';
import FaPersistencyPolicyList from './Mis/faPersistencyPolicyList';

class faPersistency extends Component {

    constructor(props) {
        super(props)

        this.state = {
            periods: ['13', '25'],
            // metrics: props.getSession.channel === 'BANCA' ?  ['LIMRA', 'RP'] : ['RP'],
            metrics: ['RP'],
            // color25th: 'rgba(90,57,130,0.75)',
            // color13th: 'rgba(248, 98, 0, 0.75)',
            color25th: '#007AB3',
            color13th: '#003781',
            persistencyData: null,
            isLoading: null,
            activeChart: null,
            active13thMetric: true,
            active25thMetric: true,
            showPersistencyList: false,

            persistencyPolicyList: {
                workflowId: "",
                classProductType: "",
                period: ""
            }
        }

        this.changeActive13thMetric = this.changeActive13thMetric.bind(this);
        this.changeActive25thMetric = this.changeActive25thMetric.bind(this);
        this.getPersistencyData = this.getPersistencyData.bind(this);
        this.togglePersistencyItem = this.togglePersistencyItem.bind(this);
        this.resetPersistencyListing = this.resetPersistencyListing.bind(this);
    }

    componentDidMount() {
        if (this.props.metric) {
            switch (this.props.metric) {
                case "13":
                    this.setState({ active13thMetric: true, active25thMetric: false })
                    break;
                default:
                    this.setState({ active13thMetric: false, active25thMetric: true })
            }
            this.getPersistencyData([this.props.metric]);
        } else {
            this.getPersistencyData();
        }
    }

    async getPersistencyData(passedMetric = this.state.periods) {
        let t = this;
        if (this.state.isLoading === null || this.state.isLoading === false) {
            this.setState({
                isLoading: true,
                persistencyData: null
            });
            // this.forceUpdate();
            let numberOfRemainingCalls = -100; // -100 means no call yet
            let { periods, metrics, color13th, color25th } = this.state;
            periods = passedMetric;
            let session = this.props.getSession;
            let persistencyData = {
                datasets: [],
                labels: [],
                minVal: 100,
                maxVal: 100
            };

            await periods.map(async function (period) {
                await metrics.map(async function (metric) {
                    let headerParams = {
                        accountManagerId: t.props.agentNumber || decrypt(session.agentNumber),
                        x_auth: session.access_token,
                    };

                    let filter = [];
                    filter['calculationFormulaName'] = metric;
                    filter['calculationMonth'] = period;

                    let dataset = {
                        backgroundColor: period.includes('13') ? color13th : color25th,
                        borderColor: period.includes('13') ? color13th : color25th,
                        borderDash: metric.includes('RP') ? [5, 5] : [],
                        data: [],
                        fill: false,
                        lineTension: 0,
                        label: metric + period,
                        pointRadius: 3,
                    };

                    if (numberOfRemainingCalls === -100) {
                        numberOfRemainingCalls = 1;
                    } else {
                        numberOfRemainingCalls += 1;
                    }

                    let data = await new Promise(function (resolve, reject) {
                        apiModule("get_fa_persistency", headerParams, null, createQueryStrings(filter))
                            .then(function (response) {
                                let result = response;

                                // Enable mock data
                                if (myConfig.ENABLE_MOCK_DATA) {
                                    if (period == 13 && metric == "RP") result = mockPersistencyData["13"];
                                    if (period == 25 && metric == "RP") result = mockPersistencyData["25"];
                                }

                                result = result.goalDetails ? result.goalDetails : null
                                numberOfRemainingCalls--;
                                resolve(result);
                            })
                            .catch(function (err) {
                                numberOfRemainingCalls--;
                                resolve(null);
                            })
                    });


                    if (data !== null || data !== undefined) {
                        data = data ? data.map(function (obj) {
                            persistencyData.minVal = persistencyData.minVal > (obj.achievedPersistencyPercentage - 5) ? (obj.achievedPersistencyPercentage - 5) : persistencyData.minVal;
                            persistencyData.minVal = (persistencyData.minVal < 0) ? 0 : persistencyData.minVal;
                            let month = obj.month ? obj.month.substring(0, 3) : "";
                            let year = obj.year ? " '" + obj.year.substring(2, 4) : "";
                            return { x: month + year, y: obj.achievedPersistencyPercentage }
                        }) : [];


                        if (persistencyData.labels.length === 0) {
                            for (let i = 0; i < data.length; i++) {
                                if (data[i].x) {
                                    persistencyData.labels.push(data[i].x);
                                }
                            }
                        }
                        dataset.data = data ? data : [];
                        persistencyData.datasets.push(dataset);
                    }

                    if (numberOfRemainingCalls <= 0 && numberOfRemainingCalls !== 100) {
                        t.setState({
                            persistencyData: persistencyData,
                            isLoading: false
                        });
                    }
                });
            });
        }
        this.resetPersistencyListing();
    }

    changeActive13thMetric() {
        if (this.state.isLoading === false) {
            let t = this;
            let periods = [];

            let active13thMetric = !this.state.active13thMetric;
            let active25thMetric = this.state.active25thMetric;

            if (active13thMetric === false && active25thMetric === true) {
                periods = ["25"];
            }
            if (active13thMetric === true && active25thMetric === false) {
                periods = ["13"];
            }
            if (active13thMetric === true && active25thMetric === true) {
                periods = ["13", "25"];
            }

            if (active25thMetric === false && active13thMetric === false) {
                active25thMetric = true;
                periods = ["25"];
            }

            this.setState({
                periods: periods,
                active13thMetric: active13thMetric,
                active25thMetric: active25thMetric,
                persistencyData: null,
            }, function () {
                t.getPersistencyData();
            });
        }
        this.resetPersistencyListing();
    }

    changeActive25thMetric() {
        if (this.state.isLoading === false) {
            let t = this;
            let periods = [];

            let active13thMetric = this.state.active13thMetric;
            let active25thMetric = !this.state.active25thMetric;

            if (active13thMetric === false && active25thMetric === true) {
                periods = ["25"];
            }
            if (active13thMetric === true && active25thMetric === false) {
                periods = ["13"];
            }
            if (active13thMetric === true && active25thMetric === true) {
                periods = ["13", "25"];
            }

            if (active25thMetric === false && active13thMetric === false) {
                active13thMetric = true;
                periods = ["13"];
            }
            this.setState({
                periods: periods,
                active13thMetric: active13thMetric,
                active25thMetric: active25thMetric,
                persistencyData: null,
            }, function () {
                t.getPersistencyData();
            });
        }
        this.resetPersistencyListing();
    }

    togglePersistencyItem(option = !this.state.showPersistencyList) {
        this.setState({ showPersistencyList: option })
    }

    setPersistencyListingParam(datasetIndex, elementIndex) {
        const label = this.state.persistencyData.datasets[datasetIndex].label;
        const date = this.state.persistencyData.labels[elementIndex];

        const metric = label.replace(/[^a-zA-Z]+/g, '');
        const period = label.replace(/[^\d]+/g, '');

        const selectedMonth = date.replace(/[^a-zA-Z]+/g, '');
        const monthIndex = months.findIndex(month => month.includes(selectedMonth));
        // For Testing purpose, input - 1
        const year = +(date.replace(/[^\d]+/g, ''));
        const lastDay = new Date(year, monthIndex + 1, 0).getDate();

        this.setState({
            persistencyPolicyList: {
                workflowId: metric,
                classProductType: `${period},${lastDay}-${selectedMonth.toUpperCase()}-20${year}`,
                period
            },
            dateAndYear: `${months[monthIndex] + " 20" + (date.replace(/[^\d]+/g, ''))}`
        })

        setTimeout(() => this.togglePersistencyItem(), 0)
    }

    resetPersistencyListing() {
        this.setState({
            persistencyPolicyList: {
                workflowId: "",
                classProductType: "",
                period: ""
            }
        })
    }

    render() {
        let { active13thMetric, active25thMetric, persistencyData, isLoading, periods, metrics, showPersistencyList, persistencyPolicyList } = this.state;
        let channel = this.props.getSession.channel;
        let servicingText = renameByChannel("Servicing FA", channel, this.props.getSession.view_as_channel);

        const loader = <div style={{ margin: 'auto' }}><LoaderGif2 css="ml-auto" /></div>
        return (
            <div className="faPersistency">
                <div className="faPersistency-container">
                    <div style={{ margin: '0px' }} className="row">
                        <div style={{ padding: '10px' }} className="col-md-12 col-xs-12 col-lg-12 col-sm-12">
                            <div className="faPersistency-metric">
                                <label className="faPersistency-metric-label">METRIC</label>
                                <div className={`faPersistency-metric-checkbox ${this.props.metric ? "pe-none" : ""}`}>
                                    <span onClick={() => { this.changeActive13thMetric(); }} className="faPersistency-metric-span">
                                        <input id="13th" type="checkbox" checked={active13thMetric} readOnly />
                                        <label>13<sup>th</sup></label>
                                        <span className="faPersistency-checkmark"></span>
                                    </span>
                                    <span onClick={() => { this.changeActive25thMetric(); }} className="faPersistency-metric-span">
                                        <input id="25th" type="checkbox" checked={active25thMetric} readOnly />
                                        <label>25<sup>th</sup></label>
                                        <span className="faPersistency-checkmark"></span>
                                    </span>
                                </div>
                            </div>
                            <div className="faPersistency-link">
                                {this.props.showLink && (<a className="faPersistency-anchor text-info cs-pointer" href="/dashboard/list-of-fa"><u>Persistency List per {servicingText}</u></a>)}
                            </div>
                            <label onClick={() => { this.props.hidePersistency(); }} className="faPersistency-close">x</label>
                        </div>
                        {isLoading === null || isLoading === true ? loader :
                            <FAPersistencyChart {...this.props} data={persistencyData} togglePersistencyItem={(datasetIndex, elementIndex) => {
                                this.setPersistencyListingParam(datasetIndex, elementIndex)
                            }} />}
                    </div>
                </div>

                <div onClick={() => { this.props.hidePersistency(); }} className="faPersistency-backdrop"></div>
                {showPersistencyList &&
                    <FaPersistencyPolicyList
                        setClientOrPolicy={this.props.setClientOrPolicy}
                        history={this.props.history}
                        agentNumber={this.props.agentNumber}
                        session={this.props.getSession}
                        persistencyPolicyList={persistencyPolicyList}
                        dateAndYear={this.state.dateAndYear}
                        togglePersistencyItem={() => this.togglePersistencyItem(false)}></FaPersistencyPolicyList>
                }
            </div>
        )
    }
}

export default withWindowDimensions(faPersistency)
