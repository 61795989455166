import React, { Component } from 'react';
import LoaderGif from '../../commons/loaderGif';

class TableLoader extends Component {
    constructor(props) {
        super(props);

    }

    render() {
        if (this.props.loading) {
            return <LoaderGif css="h-100 d-flex justify-content-center align-items-center position-absolute" style={{ background: "#F3F5F7", top: "0", left: "0" }} />;
        } else {
            return <div id="js-react-table" className="rt-table" role="grid">{this.props.children}</div>;
        }
    }
}


class TableLoader2 extends Component {
    constructor(props) {
        super(props);

    }

    render() {
        if (this.props.loading) {
            return <LoaderGif css="h-100 d-flex justify-content-center align-items-center position-absolute" style={{ top: "0", left: "0", zIndex: "1", background: "rgba(255,255,255,.5)" }} />;
        } else {
            return <div id="js-react-table" className="rt-table" role="grid">{this.props.children}</div>;
        }
    }
}
export default TableLoader;
export { TableLoader2 };
