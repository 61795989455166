import React, { Component } from 'react';
import PieChart from './pieChart';
import { haveValue, showNoData, charts } from '../../commons/misConfig';
import { apiModule, createQueryStrings } from '../../commons/apiCall';
import { formatValues, formatValuesNoDecimals } from './formatter';
import CommentIcon from './commentIcon';

class PerClientType extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            nonZero: true,
            isCommentVisible: false,
            totalComments: 0
        }
        this.getValuePerClientType = this.getValuePerClientType.bind(this);
    }

    componentWillMount() {
        this.getValuePerClientType(this.props);
        this.props.getCommentCount(this, 'clientType', this.props.access_token);
        this.setState({
            ...this.props
        })
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.willUpdate) {
            this.setState({
                ...nextProps,
                isLoading: true
            })

            this.getValuePerClientType(nextProps)
        }
        else {
            this.setState({
                willUpdate: nextProps.willUpdate
            })
        }

        if(nextProps.willUpdateCommentCount){
            nextProps.getCommentCount(this, 'clientType', nextProps.access_token);
        }
    }

    getValuePerClientType(props) {
        let that = this;
        let headerParams = {
            x_auth: props.access_token
        };
        let filter = []
        filter['metric'] = haveValue(props.metric) ? props.metric.value : 'ALL';
        filter['period'] = haveValue(props.period) ? props.period.value : 'ALL';
        filter['startDate'] = haveValue(props.startDate) ? props.startDate : '';
        filter['endDate'] = haveValue(props.endDate) ? props.endDate : '';
        filter['productOfferingType'] = haveValue(props.product) ? props.product.value : '';
        filter['workflowId'] = 'clientType'
        filter['contractStatus'] = haveValue(props.status) ? props.status.value : '';
        filter['channel'] = haveValue(props.channel)? props.channel.value: '';
        filter['targetType'] = haveValue(props.target.value) ? props.target.value : '';
        
        apiModule("get_per_client_type", headerParams, null, createQueryStrings(filter))
            .then(function (result) {

                if (result.goalDetails) {
                    let nonZero = false;
                    let dataObject = {
                        labels: [],
                        datasets: [{
                            data: [],
                            negativeData: [],
                            dataPct: [],
                            dataCount: [],
                            borderWidth: result.goalDetails.some((elem, index, arr) => { return elem && elem.achievedPremiumRate <= 0; }) ? 0 : 1,
                            borderColor: "#78DCFA",
                            backgroundColor: "#78DCFA"
                        }]
                    };

                    result.goalDetails.map(function (obj, index) {
                        dataObject.labels[index] = obj.clientType;
                        dataObject.datasets[0].backgroundColor = ['#007ab3', '#96DCFA']
                        dataObject.datasets[0].data[index] = obj.achievedPremiumRate;
                        dataObject.datasets[0].negativeData[index] = obj.achievedNegativePremiumRate;
                        dataObject.datasets[0].dataPct[index] = ((parseInt(haveValue(obj.achievedPremiumRate) ? obj.achievedPremiumRate : 1) / parseInt(haveValue(obj.plannedPremiumRate) ? obj.plannedPremiumRate : 1)) * 100).toFixed(2);
                        dataObject.metric = haveValue(props.metric) ? props.metric.value : ''
                        nonZero = nonZero || obj.achievedPremiumRate;
                    })

                    that.setState({
                        data: dataObject,
                        isLoading: false,
                        nonZero: nonZero
                    });
                }

            })
            .catch(function (err) {
                console.log(err);
            });
    }

    render() {
        const { isLoading, totalComments, currTarget } = this.state;
        const { currStatus, coinLoader, onClickCommentIcon } = this.props;
        let isPD = currTarget.value.toUpperCase().includes('PD');
        return (
            <div className="container mis-container col-sm-12 col-md-12 col-lg-6 col-xl-6">
                {/* <div className="mis-comment-icon-container" onClick={() => onClickCommentIcon(charts.clientType)}>
                    <div className="mis-comment-icon">
                        <img src = "/mis/comments.svg" className="mis-comment-icon-img"/>
                        <span className="mis-comment-count">
                            {formatValuesNoDecimals(totalComments)}
                        </span>
                    </div>
                </div> */}
                <CommentIcon
                    chart={charts.clientType}
                    onClickCommentIcon={onClickCommentIcon} 
                    totalComments={totalComments} />
                <div className="card mis-card-4 bg-white p-3 shadow-sm rounded">
                    <div className="card-body mis-card-body">
                        <div className="card-title mis-card-title">
                            <span className="d-flex dashboard-data-header__text align-items-end font-neo-semibold text-headercolor">
                                Value Per Client Type
                        </span>
                        </div>
                        {isPD ? showNoData(true)
                        :   isLoading ? coinLoader :
                                <PieChart chartId={'client-pie-chart'} {...this.props} {...this.state} />
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default PerClientType;