import React, { Component } from 'react';
import { myConfig } from '../commons/config.js';

class WhatsNew extends Component {
    constructor(props) {
        super(props);
        this.state = {
            HO: {
                date: 'April 20, 2023',
                changes: [
                    {
                        title: "Premium Due List Enhancement",
                        description: "Life Changers can now filter Due date in Premium Due List by simply going to Dashboard > Click View All in Premium Due List > Filter upcoming due date and past due date. Take advantage of this new feature to follow up your clients to pay their premium."
                    }
                ],
                message: [
                    {
                        title: "Generation of Billing of Notice",
                        description: "Simply go to <strong>Reports</strong> > <strong>Generate Documents</strong> > <strong>Select Billing Notice</strong>"
                    },
                    {
                        title: "Generation of Anniversary Statement",
                        description: "Simply go to <strong>Reports</strong> > <strong>Generate Documents</strong> > <strong>Select Anniversary Statement</strong>"
                    }
                ]
            },
            BANCA: {
                date: 'April 20, 2023',
                changes: [
                    {
                        title: "Premium Due List Enhancement",
                        description: "Life Changers can now filter Due date in Premium Due List by simply going to Dashboard > Click View All in Premium Due List > Filter upcoming due date and past due date. Take advantage of this new feature to follow up your clients to pay their premium."
                    }
                ],
                message: [
                    {
                        title: "Generation of Billing of Notice",
                        description: "Simply go to <strong>Reports</strong> > <strong>Generate Documents</strong> > <strong>Select Billing Notice</strong>"
                    },
                    {
                        title: "Generation of Anniversary Statement",
                        description: "Simply go to <strong>Reports</strong> > <strong>Generate Documents</strong> > <strong>Select Anniversary Statement</strong>"
                    }
                ]
            },
            AGENCY: {
                date: 'April 20, 2023',
                changes: [
                    {
                        title: "Premium Due List Enhancement",
                        description: "Life Changers can now filter Due date in Premium Due List by simply going to Dashboard > Click View All in Premium Due List > Filter upcoming due date and past due date. Take advantage of this new feature to follow up your clients to pay their premium."
                    }
                ],
                message: [
                    {
                        title: "Generation of Billing of Notice",
                        description: "Simply go to <strong>Reports</strong> > <strong>Generate Documents</strong> > <strong>Select Billing Notice</strong>"
                    },
                    {
                        title: "Generation of Anniversary Statement",
                        description: "Simply go to <strong>Reports</strong> > <strong>Generate Documents</strong> > <strong>Select Anniversary Statement</strong>"
                    }
                ]
            },
            HSBC: {
                date: 'April 20, 2023',
                changes: [
                    {
                        title: "Premium Due List Enhancement",
                        description: "Life Changers can now filter Due date in Premium Due List by simply going to Dashboard > Click View All in Premium Due List > Filter upcoming due date and past due date. Take advantage of this new feature to follow up your clients to pay their premium."
                    }
                ],
                message: [
                    {
                        title: "Generation of Billing of Notice",
                        description: "Simply go to <strong>Reports</strong> > <strong>Generate Documents</strong> > <strong>Select Billing Notice</strong>"
                    },
                    {
                        title: "Generation of Anniversary Statement",
                        description: "Simply go to <strong>Reports</strong> > <strong>Generate Documents</strong> > <strong>Select Anniversary Statement</strong>"
                    }
                ]
            }
        }
    }

    message(channel = "") {
        const channelChanges = this.state[(channel || "").toUpperCase()]
        return (
            <div>
                Hi Life Changer, <br/><br/>
                Let's familiarize ourselves with these latest features to help us better service our customers: <br />
                <br />
                <ol className = "whats-new__change-list">
                    
                    {channelChanges.changes.map((change, index) =>
                        <li key={index}>
                            <strong>{change.title}</strong>
                            {change.description && change.description.length > 0 && <div  dangerouslySetInnerHTML={{ __html: change.description }}></div>}
                        </li>
                    )}
                </ol>
            </div>
        );
    }

    otherNessages(channel = "") {
        const channelChanges = this.state[(channel || "").toUpperCase()];
        return (
            <div className='whats-new__other-messages'>
                You may also check the other features of Allianz Assist: <br /><br />
                <strong>Paperless Fund Transfer Agreement (FTA Form) via Allianz Assist</strong>
                <br />
                <br />
                <strong>Why help your Clients enroll an FTA account via Assist?</strong>
                <br />
                <br />
                {/* <ul className='whats-new__change-list'>
                    {channelChanges.message.map((change, index) =>
                        <li key={index}>
                            <strong>{change.title}</strong>
                            {change.description && change.description.length > 0 && <div  dangerouslySetInnerHTML={{ __html: change.description }}></div>}
                        </li>
                    )}
                </ul> */}
                <ul>
                    <li>Faster payouts via Fund Transfer for these transactions - RTs checks, Surrenders, Partial Withdrawals, Maturities, Endowments, and Dividends</li>
                    <li>Re-crediting for unsuccessful transactions for (Surrenders, Partial Withdrawals, Maturities, Endowments, and Dividends)</li>
                    <li>Zero Paper, minimized handoffs and wait time</li>
                    <li>Real-time Fund Transfer Enrollment - No need to wait 7 to 10 days to enroll/change a bank account</li>
                </ul>
                <strong>3 steps to trigger an FT account enrollment via Allianz Assist!</strong><br />
                Let your Client know that you're sending them a link for FT account enrollment.
                <ol>
                    <li>Check if the customer's registered email address and phone number are updated</li>
                    <li>Send FT account enrollment link</li>
                    <ul>
                        <li>From Allianz Assist, go to <strong>Policy Services {'>'} Select Type of Transaction "Fund Transfer Agreement Form"</strong> as a type of transaction {'>'} Click <strong>Submit</strong></li>
                        <li>Search for the Policy No. or Policyowner's Name and click <strong>New Transaction</strong></li>
                        <li>Select where you want to send the FTA form (<strong>to the client's email or mobile</strong>) then click <strong>Send</strong></li>
                    </ul>
                    <li>Client receives the link and input bank account details to enroll</li>
                    <strong>Note:</strong> FTA form link is valid until 48 hours
                </ol>
                Agents <strong>can monitor FT account enrollment status in Allianz Assist</strong> {'>'} List of Policies {'>'} then Search for the Policy No. or Policyowner's Name {'>'} Endorsement page
                
            </div>
        );
    }

    render() {
        const { toggleWhatsNewModal, channel } = this.props;
        const channelChanges = this.state[channel];
        return (
            <div className='whats-new'>
                <div className="whats-new__overlay" onClick={() => toggleWhatsNewModal()}></div>
                <div className='whats-new__modal'>
                    <img className='whats-new__close' src={require('../assets/img/close.png')} onClick={() => toggleWhatsNewModal()} />
                    <div className='whats-new__container'>
                        <h4 style={{ fontFamily: 'font-family: neo_semibold' }}>What's New?</h4>
                        <div className='whats-new__content-top'>
                            <img src={require('../assets/img/allianz_assist_bg-min.jpg')} />
                            <div className='whats-new__content-top__text'>
                                <div className='whats-new__date'>{channelChanges.date}</div>
                                <h4 className='whats-new__title'>[{channel}]<br/> Allianz Assist v{myConfig.APP_VERSION[channel]} is Now Live!</h4>
                            </div>
                        </div>
                        <div className='whats-new__message'>
                            {this.message(channel)}
                            {this.otherNessages(channel)}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default WhatsNew;