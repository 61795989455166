import React, { Component, Fragment } from 'react';
import withWindowDimensions from '../../../commons/withWindowDimensions';
import { haveValue } from '../../../commons/misConfig';
import {
    AccordionWithHeader,
    AccordionNode,
    AccordionHeader,
    AccordionPanel
} from 'react-accordion-with-header';
import ShowMoreText from 'react-show-more-text';
import NavLink from 'react-router-dom/NavLink';
import parse from 'html-react-parser';

class contestDetails_formatone extends Component {

    constructor(props) {
        super(props)
        this.state = {
            title: this.props.data.title ? this.props.data.title : "",
            description: this.props.data.description ? this.props.data.description : "",
            image: this.props.data.image ? this.props.data.image : null,
            detailsPanels: [
                { panel: 0, open: false },
                { panel: 1, open: false },
                { panel: 2, open: false },
                { panel: 3, open: false },
                { panel: 4, open: false },
            ],
            rewards: this.props.data.rewards ? this.props.data.rewards : [],
            mechanics: this.props.data.mechanics ? this.props.data.mechanics : {
                exclusionTxt: "",
                qualificationTxt: "",
                qualifiedAgentsTxt: "",
                resultValidationTxt: "",
                rewardTxt: "",
            },
            contestId: this.props.data.contestId ? this.props.data.contestId : null,
        }
    }

    render() {
        const { image, mechanics, detailsPanels, description, title, rewards } = this.state;
        const { isSm } = this.props;
        return (
            <div className="rni-contest-details-container">
                <div className="container">
                    <h3 className="rni-contest-details-h3">{title}</h3>
                </div>
                <div className={isSm ? "" : "container"}>
                    <img src={image ? image : '/rni/list-test-image-1.jpg'} className="rni-contest-details-img" alt="" />
                </div>
                <div className="container">
                    <p className="rni-contest-details-description">{description}</p>
                </div>
                <div className="container">
                    <AccordionWithHeader actionCallback={(panels, state) => {
                        this.setState({
                            detailsPanels: panels,
                        });
                    }}
                        style={{ boxShadow: 'none' }}
                    >
                        <AccordionNode key={0}>
                            <AccordionHeader
                                style={{ border: 'none', paddingTop: '15px', paddingBottom: '15px', borderBottom: '1.5px solid lightgray' }}
                                horizontalAlignment="centerSpaceBetween"
                                verticalAlignment="center"
                            >
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <img className="rni-details-accordion-header-icon" src="/rni/gift.png" />
                                    <p className="rni-contest-details-accordion-p">
                                        Rewards
                            </p>
                                </div>
                                <div>{detailsPanels[0] ? detailsPanels[0].open !== undefined ?
                                    <img src={detailsPanels[0].open === true ? "/rni/chevron-up-solid.svg" : "/rni/chevron-down-solid.svg"} className="rni-contest-details-chevron" />
                                    : '' : ''}</div>
                            </AccordionHeader>
                            <AccordionPanel style={{ overflow: 'auto' }}>
                                <div style={{ padding: '10px' }}>
                                    {haveValue(rewards) && rewards.length > 1 ? <div className="row" style={{ flexWrap: 'wrap', justifyContent: 'space-evenly' }}>
                                        {rewards.map(function (tier, index) {
                                            return (
                                                <div key={index} style={{ textAlign: 'center' }} className="">
                                                    <div className="rni-contest-details-tier">
                                                        <img src={tier.tierImg ? tier.tierImg : "/rni/gift.png"} className="rni-contest-details-tierImage" />
                                                        {tier.isAchieved ? <img src="/rni/success.png" className="rni-contest-details-tier-isachieved" /> : ""}
                                                    </div>
                                                    <p>{tier.tierTitle}</p>
                                                </div>);
                                        })}
                                    </div>
                                        : ""
                                    }
                                    <br />
                                    {haveValue(mechanics.rewardTxt) ? <Fragment>{parse(mechanics.rewardTxt.join(''))}</Fragment>
                                        : "No provided description for rewards"}
                                </div>
                            </AccordionPanel>
                        </AccordionNode>

                        <AccordionNode key={1}>
                            <AccordionHeader
                                style={{ border: 'none', paddingTop: '15px', paddingBottom: '15px', borderBottom: '1.5px solid lightgray' }}
                                horizontalAlignment="centerSpaceBetween"
                                verticalAlignment="center"
                            >
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <img className="rni-details-accordion-header-icon" src="/rni/mechanism.png" />
                                    <p className="rni-contest-details-accordion-p">
                                        Mechanics
                                    </p>
                                </div>
                                <div>{detailsPanels[1] ? detailsPanels[1].open !== undefined ?
                                    <img src={detailsPanels[1].open === true ? "/rni/chevron-up-solid.svg" : "/rni/chevron-down-solid.svg"} className="rni-contest-details-chevron" />
                                    : '' : ''}</div>

                            </AccordionHeader>
                            <AccordionPanel style={{ overflow: 'auto' }}>
                                <div style={{ padding: '10px' }}>
                                    {haveValue(mechanics.qualificationTxt) ? <Fragment>{parse(mechanics.qualificationTxt.join(''))}</Fragment>
                                        : "No qualifications defined"}
                                    {/* <ShowMoreText
                                        lines={3}
                                        more=''
                                        less=''
                                        anchorClass=''
                                        onClick={this.executeOnClick}
                                        expanded={true}
                                    >
                                        
                                    </ShowMoreText> */}
                                </div>
                            </AccordionPanel>
                        </AccordionNode>

                        <AccordionNode key={2}>
                            <AccordionHeader
                                style={{ border: 'none', paddingTop: '15px', paddingBottom: '15px', borderBottom: '1.5px solid lightgray' }}
                                horizontalAlignment="centerSpaceBetween"
                                verticalAlignment="center"
                            >
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <img className="rni-details-accordion-header-icon" src="/rni/results.png" />
                                    <p className="rni-contest-details-accordion-p">
                                        Details
                                    </p>
                                </div>
                                <div>{detailsPanels[2] ? detailsPanels[2].open !== undefined ?
                                    <img src={detailsPanels[2].open === true ? "/rni/chevron-up-solid.svg" : "/rni/chevron-down-solid.svg"} className="rni-contest-details-chevron" />
                                    : '' : ''}</div>
                            </AccordionHeader>
                            <AccordionPanel style={{ overflow: 'auto' }}>
                                <div style={{ padding: '10px' }}>
                                    {haveValue(mechanics.resultValidationTxt) ? <Fragment>{parse(mechanics.resultValidationTxt.join(''))}</Fragment>
                                        : "No qualifications defined"}
                                    {/* <ShowMoreText
                                        lines={3}
                                        more=''
                                        less=''
                                        anchorClass=''
                                        onClick={this.executeOnClick}
                                        expanded={true}
                                    >
                                    </ShowMoreText> */}
                                </div>
                            </AccordionPanel>
                        </AccordionNode>

                        <AccordionNode key={3}>
                            <AccordionHeader
                                style={{ border: 'none', paddingTop: '15px', paddingBottom: '15px', borderBottom: '1.5px solid lightgray' }}
                                horizontalAlignment="centerSpaceBetween"
                                verticalAlignment="center"
                            >
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <img className="rni-details-accordion-header-icon" src="/rni/ban.png" />
                                    <p className="rni-contest-details-accordion-p">
                                        Exclusions
                                    </p>
                                </div>
                                <div>{detailsPanels[3] ? detailsPanels[3].open !== undefined ?
                                    <img src={detailsPanels[3].open === true ? "/rni/chevron-up-solid.svg" : "/rni/chevron-down-solid.svg"} className="rni-contest-details-chevron" />
                                    : '' : ''}</div>
                            </AccordionHeader>
                            <AccordionPanel style={{ overflow: 'auto' }}>
                                <div style={{ padding: '10px' }}>
                                    {haveValue(mechanics.exclusionsTxt) ? <Fragment>{parse(mechanics.exclusionsTxt.join(''))}</Fragment>
                                        : "No qualifications defined"}
                                    {/* <ShowMoreText
                                        lines={3}
                                        more=''
                                        less=''
                                        anchorClass=''
                                        onClick={this.executeOnClick}
                                        expanded={true}
                                    >
                                    </ShowMoreText> */}
                                </div>
                            </AccordionPanel>
                        </AccordionNode>
                    </AccordionWithHeader>

                    <div style={{ paddingTop: '15px' }}>
                        <NavLink style={{ textDecoration: 'none' }} to={"/rewards-and-incentives/leaderboard?contest=" + this.state.contestId}>
                            <img src="/rni/leaderboard-icon.png" style={{ width: '20px', height: '20px', marginRight: '7px' }} />Check here for progress</NavLink>
                    </div>
                </div>
            </div >
        )
    }
}

export default withWindowDimensions(contestDetails_formatone);