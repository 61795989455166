import React, { Component, useState, forwardRef } from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { LoaderGif2 } from '../../commons/loaderGif';
import { apiModule } from '../../commons/apiCall';

class UserBirthdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: undefined,
      retries: 1,
      birthdate: "",
      rawBirthdate: undefined,
      isBirthdateValid: undefined,
      isSending: false,
      isSubmitDisabled: true,
      isDatePickerDisabled: false,
    }

    this.props.setProgress(1);
  }

  handleDateChange(rawBirthdate) {
    const birthdate = moment(rawBirthdate).format("MM/DD/YYYY");
    
    if (birthdate !== "Invalid date") {
      this.setState({ rawBirthdate, birthdate, isBirthdateValid: true });
    } else {
      this.setState({ isBirthdateValid: false })
    }
    
    if (this.state.isSubmitDisabled) {
      this.setState({ isSubmitDisabled: false });
    }
  }

  sendBirthdate = async () => {
    this.setState({ isSending: true, isSubmitDisabled: true, isDatePickerDisabled: true });
    
    const parsedBirthdate = moment(this.state.rawBirthdate).format("YYYY-MM-DD");
    const requestParams = {
      x_auth: this.props.token
    }
    const apiData = {
      "birthDate": parsedBirthdate,
      "token": this.props.token
    };
    await apiModule("user_transactions", requestParams, apiData, '', true
    ).then((result) => {
        if (result.data.meta.code === 200) {
          this.setState({ retries: 0 })
          this.props.processData(result.data.data);
        } else {
          this.hasApiError(result.data.meta.message);
          this.setState({ isSending: false });
        }
    })
    .catch((err) => {
      console.error(err);
    });
  }

  /**
   * Why do I have to compare using message strings?
   * Because the meta.code (400/403) is UNRELIABLE
   * AND CAN MEAN MORE THAN 1 ERROR
   *
   * Meaning THIS CAN FAIL if the BE adds or even
   * changes the wording
   * @param {string} message the response sent by the API after calling it
   */
  hasApiError(message) {
    let hasError = false;

    if (message.includes("Link expired")) {
      hasError = true;
      this.props.clearScreen();
      this.setState({ error: "Oops! The link has expired. You may contact your agent to request a new link.", retries: 0 });
    } else if (message.includes("Already Verified")) {
      hasError = true;
      this.setState({ error: "You are already verified!", retries: 0 });
    } else if (message.includes("date of birth provided")) {
      hasError = true;
      const error = String(message);
      const retries = Number(error.slice(error.length - 3).charAt(0));

      retries > 0
        ? this.setState({ error: message, isSubmitDisabled: false, isDatePickerDisabled: false })
        : this.setState({ error: message, isSubmitDisabled: true });
    } else if (message.includes("Invalid Token")) {
      hasError = true;
      this.setState({ error: "The token entered is invalid", retries: 0 });
    } else if (message.includes("already posted")) {
      hasError = true;
      this.setState({ error: "The transaction is already posted", retries: 0 });
    } else if (message.includes("already cancelled")) {
      hasError = true;
      this.setState({ error: "The transaction was already cancelled", retries: 0 });
    } else {
      this.setState({ error: "There was a problem with your transaction", retries: 0 });
    }

    return hasError;
  }

  render() {
    return(
      <section className="user-birthdate d-flex flex-column">
        <div className="user-birthdate__date-wrapper position-relative">
        <label className="user-birthdate__label">Birthdate</label>
          <DatePicker
            disabled={this.state.isDatePickerDisabled || this.state.isSending}
            style={{ width: '100%' }}
            placeholderText="MM/DD/YYYY"
            selected={this.state.rawBirthdate} 
            onChange={(date) => this.handleDateChange(date)}
            className="user-birthdate__date-picker"
            maxDate={new Date()}
            dateFormat="MM/dd/yyyy"
            showPopperArrow={false}
            showYearDropdown
            yearDropdownItemNumber={100}
            scrollableYearDropdown
            shouldCloseOnSelect
          />
          <i className="user-birthdate__calendar icon-calendar"></i>
          <span className="user-birthdate__format">MM/DD/YYYY</span>
        </div>
        { this.state.isBirthdateValid === false
            ? <div className="user-birthdate__error"><i className="icon-exclamation-triangle"></i>Oops! Invalid date format</div>
            : null
        }
        {
          this.state.isSending
            ? <div className="user-birthdate__loading">
                <div className="user-birthdate__loading-circle">
                  <LoaderGif2 />
                </div>
                Your information is still being sent. Please wait.
              </div>
            : null
        }
        {
          this.state.error && !this.state.isSending && 
            <div className="client-approval__error-message">
              <span className="client-approval__error-icon icon-close-circle"></span>
              <span className="client-approval__error-text">{this.state.error}</span>
            </div>
        }
        {
          /**
            * Render null as setting condition to "this.props.retries &&" renders
            * the number to the DOM
            */
          this.state.retries > 0
           ? <button
              className={"user-birthdate__submit text-uppercase" +
                (this.state.isSubmitDisabled || this.state.isSending ? " user-birthdate__submit--disabled" : "")
              }
              onClick={this.sendBirthdate}
              disabled={this.state.isSubmitDisabled || this.state.isSending}
            >Continue</button>
          : null
        }
      </section>
    );
  }
}

export default UserBirthdate;
