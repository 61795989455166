import React, { Component } from 'react'
import {
    ComposableMap,
    Geographies,
    Geography,
    ZoomableGroup
} from "react-simple-maps"
import phCodes from '../../data/ph_dist.json';
import { MAP_MODE } from '../../commons/misConfig.js';

class PhilippinesMap extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        const { topoUrl, hoveredObj, mode } = this.props;
        const modeTarget = mode === MAP_MODE.TARGET;
        const prov = {
            NMM: '0.1',
            CMM: '1',
            SMM: '0.738923391401907',
            LUZON: '0.75',
            VISAYAS: '1.6641740459499195',
            MINDANAO: '0.3336485433745545',
            OTHERS: '00'
        }

        return (
            <div>
                <ComposableMap  
                    onClick={() => { hoveredObj() }}
                    width={228}
                    height={250}
                    projection="geoAlbers"
                    projectionConfig={{
                        rotate: [-120, 25.879721, 0],
                        scale: 850
                    }}>

                    <Geographies geography={topoUrl}>
                        {({ geographies }) => geographies.map((geo, index) => {
                            console.log(geo.properties.HASC_1+': '+geo.properties.NAME_1)
                            return <Geography onMouseOver={()=>{ hoveredObj(geo.properties) }} onClick={()=> { hoveredObj(geo.properties) }} key={geo.rsmKey} geography={geo} style={{
                                    default: {
                                        fill: modeTarget? (prov[phCodes[geo.properties.HASC_1]] >= 0.75? '#1E8927': '#DC3149'):'#007ab3',
                                        opacity: modeTarget? '1': prov[phCodes[geo.properties.HASC_1]],
                                        outline: "none",
                                    },
                                    hover: {
                                        fill: modeTarget? (prov[phCodes[geo.properties.HASC_1]] >= 0.75? '#1E8927': '#DC3149'):'#007ab3',
                                        opacity: modeTarget? '1': prov[phCodes[geo.properties.HASC_1]],
                                    },
                                    pressed: {
                                        fill: modeTarget? (prov[phCodes[geo.properties.HASC_1]] >= 0.75? '#1E8927': '#DC3149'):'#007ab3',
                                        opacity: '1'
                                    }
                                    }}/>
                        }
                        )}
                    </Geographies>
                </ComposableMap>
            </div>
            
        )
    }
}

export default PhilippinesMap