import React, { Component } from 'react';
import { ReportLoader } from '../commons/utilityViews.js';
import { withRouter } from 'react-router-dom';
// import 'react-vertical-timeline-component/style.min.css';
import { fixName, prettifyDate, calculateAge, writeData, readAllData, encrypt, decrypt, getAddressDetails, renameByChannel } from '../commons/utility.js';
import routeChange from '../commons/routeChange';
import { apiModule, createQueryStrings } from '../commons/apiCall';
import Auxi from '../components/auxi';
import { myConfig } from '../commons/config';
import LoaderGif from '../commons/loaderGif';
import TablePagination from '../components/tablePagination';
import { isManager } from '../commons/security';

class SingleFa extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            faDashboardData: [],
            error: "",
            policies: [],
            basicInfo: [],
            address: [],
            faId: '',
            loadTable: true,

            pages: 10,
            currentPage: 0,
            totalSize: 0,
        };
    }

    async componentDidMount() {
        let access_token = this.props.getSession().access_token;
        let faId = this.props.getClientOrPolicy().faId;
        let that = this;
        if (faId === null || faId === '-1' || faId === -1) {
            console.log("faId == null");
            readAllData('policy-client')
                .then(function (data) {
                    for (var dt of data) {
                        faId = dt.faId;
                        that.setState({ access_token: access_token, faId: faId });

                        that.fetchThisFa(that.props.getSession().access_token, faId);
                    }
                });
        } else {
            console.log("faId == " + faId);
            //faId = '2007.0629.34270296.1';
            this.setState({ access_token: access_token, faId: faId });
            await this.fetchThisFa(this.props.getSession().access_token, faId);

        }


    }
    fetchThisFa = (access_token, faId) => {
        let that = this;
        return new Promise(function (resolve, reject) {

            let headerParams = { x_auth: access_token, accountManagerId: faId, clientId: faId };

            apiModule("get_accountmanager", headerParams, null)
                .then(function (result) {
                    let basicInfo = [];
                    let faDashboardData = [];
                    let address = [];
                    //IF RESULT IS NULL THEN ERROR MESSAGE
                    if (result) {
                        let item = null;
                        if (Array.isArray(result)) {
                            item = result[result.length - 1];
                        }
                        else {
                            item = result;
                        }

                        /* CLIENT DASHBOARD INFORMATION */
                        faDashboardData.push({
                            fieldName: "Last Name",
                            value: item.name,
                        });

                        faDashboardData.push({
                            fieldName: "First Name",
                            value: item.firstName,
                        });

                        faDashboardData.push({
                            fieldName: "Date of Birth",
                            value: prettifyDate(item.birthDate),
                        });

                        faDashboardData.push({
                            fieldName: "Gender",
                            value: item.accountManagerGender,
                        });

                        faDashboardData.push({
                            fieldName: "Age",
                            value: calculateAge(item.birthDate)
                        });

                        // faDashboardData.push({
                        //     fieldName: "Anniversary Date",
                        //     value:  prettifyDate(item.inceptionDate)
                        // });

                        /* BASIC INFORMATION */
                        basicInfo.push({
                            fieldName: "Designation",
                            value: item.accountManagerType,
                        });

                        basicInfo.push({
                            fieldName: "First Name",
                            value: item.firstName,
                        });

                        basicInfo.push({
                            fieldName: "Middle Name",
                            value: item.middleName,
                        });

                        basicInfo.push({
                            fieldName: "Last Name",
                            value: item.name,
                        });

                        basicInfo.push({
                            fieldName: "Date of Birth",
                            value: prettifyDate(item.birthDate),
                        });

                        basicInfo.push({
                            fieldName: "Gender",
                            value: item.accountManagerGender,
                        });

                        basicInfo.push({
                            fieldName: "Age",
                            value: calculateAge(item.birthDate)
                        });

                        basicInfo.push({
                            fieldName: "Company Name",
                            value: item.companyName,
                        });

                        basicInfo.push({
                            fieldName: "Other Name",
                            value: " -- ",
                        });

                        basicInfo.push({
                            fieldName: "Civil Status",
                            value: item.maritalStatus,
                        });

                        basicInfo.push({
                            fieldName: "Nationality",
                            value: item.nationality,
                        });

                        // basicInfo.push({
                        //     fieldName: "TIN No.",
                        //     value: " -- ",
                        // });

                        // basicInfo.push({
                        //     fieldName: "US TIN",
                        //     value: " -- ",
                        // });

                        // basicInfo.push({
                        //     fieldName: "SSS/GSIS No.",
                        //     value: " -- ",
                        // });

                        basicInfo.push({
                            fieldName: "Anniversary Date",
                            value: prettifyDate(item.employmentDate),
                        });

                        basicInfo.push({
                            fieldName: "Email Address",
                            value: item.email,
                        });
                        basicInfo.push({
                            fieldName: "Contact Number",
                            value: item.phoneNumber,
                        });

                        basicInfo.push({
                            fieldName: "Intermediary Number",
                            value: item.self,
                        });

                        basicInfo.push({
                            fieldName: "Intermediary Type",
                            value: item.roleNameOfAccountManager,
                        });

                        basicInfo.push({
                            fieldName: "Status",
                            value: item.employmentStatus,
                        });

                        basicInfo.push({
                            fieldName: "Effectivity Date",
                            value: prettifyDate(item.employmentDate),
                        });

                        // basicInfo.push({
                        //     fieldName: "Service Office",
                        //     value: item.employmentStatus,
                        // });

                        // basicInfo.push({
                        //     fieldName: "License Details",
                        //     value: item.employmentStatus,
                        // });

                        // basicInfo.push({
                        //     fieldName: "CA Status",
                        //     value: item.employmentStatus,
                        // });

                        // basicInfo.push({
                        //     fieldName: "Issue Date",
                        //     value: item.employmentStatus,
                        // });

                        // basicInfo.push({
                        //     fieldName: "Expiry Date",
                        //     value: item.employmentStatus,
                        // });

                        basicInfo.push({
                            fieldName: "Other Branches",
                            value: item.branchList,
                        });

                        // basicInfo.push({
                        //     fieldName: "Description",
                        //     value: item.employmentStatus,
                        // });


                        if (item.address !== null && item.address !== undefined) {
                            address = getAddressDetails(item.address);
                        }else {
                            address.push({
                                fieldName: "Company/ Building Name",
                                value: "--",
                              });
                          
                              address.push({
                                fieldName: "Lot/ Block No.",
                                value: "--",
                              });
                          
                              address.push({
                                fieldName: "Street No./ Street",
                                value: "--",
                              });
                          
                              address.push({
                                fieldName: "Barangay/ Subdivision/ Village",
                                value: "--",
                              });
                          
                              address.push({
                                fieldName: "City/ Municipality",
                                value: "--",
                              });
                          
                              address.push({
                                fieldName: "Country",
                                value: "--",
                              });
                          
                              address.push({
                                fieldName: "Province/ State",
                                value: "--",
                              });
                          
                              address.push({
                                fieldName: "Zip Code",
                                value: "--",
                              });
                        }



                        // /* ADDRESS INFORMATION */
                        // address.push({
                        //     fieldName: "Company/ Building Name",
                        //     value: itemAddress.buildingName,
                        // });

                        // address.push({
                        //     fieldName: "Lot/ Block No.",
                        //     value: itemAddress.floorNumber,
                        // });

                        // address.push({
                        //     fieldName: "Street No./ Street",
                        //     value: itemAddress.street,
                        // });

                        // address.push({
                        //     fieldName: "Barangay/ Subdivision/ Village",
                        //     value: itemAddress.district,
                        // });

                        // address.push({
                        //     fieldName: "City/ Municipality",
                        //     value: itemAddress.city,
                        // });

                        // address.push({
                        //     fieldName: "Country",
                        //     value: itemAddress.countryPrefix,
                        // });

                        // address.push({
                        //     fieldName: "Province/ State",
                        //     value: itemAddress.state,
                        // });

                        // address.push({
                        //     fieldName: "Zip Code",
                        //     value: itemAddress.zipCode,
                        // });



                    } else {
                        //   that.errorMessage("No Client Found");
                    }



                    let filter = [];
                    filter['agentNumber'] = faId; //decrypt(that.props.getSession().agentNumber);
                    filter['pageSize'] = myConfig.PAGE_SIZE;
                    // filter['accountManagerId'] = decrypt(that.props.getSession().agentNumber);
                    filter['pageNumber'] = that.state.currentPage + 1;

                    apiModule("policy_all", headerParams, null, createQueryStrings(filter))
                        .then(function (response) {
                            console.log(response);
                            let policies = [];
                            //IF RESULT IS NULL THEN ERROR MESSAGE
                            if (response.length > 0) {
                                let totalSize = response[0].totalSize;
                                let numOfPages = parseInt((totalSize / myConfig.PAGE_SIZE), 10);

                                if ((totalSize % myConfig.PAGE_SIZE) > 0) {
                                    numOfPages++;
                                }
                                that.setState({
                                    totalSize,
                                    pages: numOfPages,
                                });
                            } else {
                                that.setState({
                                    totalSize: 0,
                                    pages: 0,
                                });
                            }

                            if (response.length > 0) {
                                let result = response[0].content;

                                for (let i = 0; i < result.length; i++) {
                                    let item = result[i];
                                    console.log(item);
                                    if (item.insured === null || item.insured === undefined) {
                                        policies.push({

                                            policyNumber: item.contractNumber,
                                            planName: item.productOfferingName,
                                            status: item.status,
                                            insured: `---`,
                                            effectiveDate: prettifyDate(item.inceptionDate),
                                            currency: item.currency,
                                            expiryDate: prettifyDate(item.contractInterval.endTimeDate)
                                        });
                                    } else {
                                        policies.push({

                                            policyNumber: item.contractNumber,
                                            planName: item.productOfferingName,
                                            status: item.status,
                                            insured: `${fixName(item.insured)}`,//.lastName}, ${item.insured.firstName} ${item.insured.middleName}`,
                                            effectiveDate: prettifyDate(item.inceptionDate),
                                            currency: item.currency,
                                            expiryDate: prettifyDate(item.contractInterval.endTimeDate)
                                        });
                                    }
                                }

                            } else {
                                //  that.errorMessage("No Client Found");
                            }

                            that.setState({
                                faDashboardData,
                                loadTable: false,
                                // totalSize,
                                // pages: numOfPages,
                                policies,
                                basicInfo,
                                address,
                                isLoading: false,
                            });
                            resolve("Ok");
                        })
                        .catch(function (err) {
                            console.log(err);
                            that.setState({
                                faDashboardData,
                                loadTable: false,
                                // totalSize: 0,
                                basicInfo,
                                address,
                                isLoading: false,

                            });
                            // that.errorMessage(err.message);
                            that.errorMessage("No Policy List for this Client");
                            resolve("Ok");

                        })
                })
                .catch(function (err) {
                    console.log(err);
                    //that.errorMessage(err.message);
                    that.errorMessage("No Client Found");
                    that.setState({
                        isLoading: false,

                    });
                    resolve("Ok");
                })
            // let result = null;



        });
    }


    errorMessage = (message) => {
        if (message) {
            this.setState({ error: message })
        }
    }

    showError = () => {
        let message = '';
        if (this.state.error !== '') {
            message =
                <div className="bg-error py-1 px-4">
                    <p className="text-white mb-0 font-neo-semibold">ERROR: {this.state.error}</p>
                </div>
        }
        return message;
    }

    dashboardInfoFields = () => {

        let faDashboardData = this.state.faDashboardData;

        let infoFields = faDashboardData.map((item, index) => {
            return (
                <div key={index} className="dashboard-info__field">
                    <label className="dashboard-info__label">{item.fieldName}</label>
                    <div className="dashboard-info__value form-control">{item.value}</div>
                </div>
            )
        });
        return (
            <div className="dashboard-info__fields">
                {infoFields}
            </div >
        );
    }

    basicInfo = () => {
        let notForManagers = [
            'Intermediary Number',
            'Intermediary Type',
            'Effectivity Date',
            'Other Branches',
            'Status',
        ];
        let infoTableRows = this.state.basicInfo.map((item, index) => {
            if(!(notForManagers.includes(item.fieldName) && isManager(this.props.getSession().role))) {
                if (item.fieldName == "Other Branches") {
                    let eachBranches = "No other branches"
                    if (item.value) {
                        eachBranches = item.value.map((branch, index_branch) => {
                            return (
                                <li>{branch}</li>
                            );
                        });
                    }
                    // let eachBranches = item.value.map((branch, index_branch) => {
                    //     return (
                    //         <li>{branch}</li>
                    //     );
                    // });
                    return (
                        <tr key={index}>
                            <td>{item.fieldName ? item.fieldName : "-------"}</td>
                            <td><ul>{eachBranches}</ul></td>
                        </tr>
                    );
                } else {
                    return (
                        <tr key={index}>
                            <td>{item.fieldName ? item.fieldName : "-------"}</td>
                            <td>{item.value ? item.value : "-------"}</td>
                        </tr>
                    );
                }
            }
        });
        return (
            <div className="dashboard-info__table-body w-md-30">
                <p className="dashboard-info__table-header font-neo-semibold mb-1">Basic Information</p>
                <table className="dashboard-info__table table table-sm table-borderless">
                    <tbody>
                        {infoTableRows}
                    </tbody>
                </table>
            </div>
        );
    }

    address = () => {

        let addressRows = this.state.address.map((item, index) => {
            return (
                <tr key={index}>
                    <td>{item.fieldName ? item.fieldName : "-------"}</td>
                    <td>{item.value ? item.value : "-------"}</td>
                </tr>
            );
        });
        return (
            <div className="dashboard-info__table-body w-100">
                <p className="dashboard-info__table-header font-neo-semibold mb-1">Address</p>
                <table className="dashboard-info__table table table-sm table-borderless">
                    <tbody>
                        {addressRows}
                    </tbody>
                </table>
            </div>
        );
    }

    risk = () => {
        let data = [
            {
                name: "High Rish Client/FATCA",
                value: null,
            },
            {
                name: "Risk Class (If High Risk Client Only)",
                value: null,
            },
            {
                name: "High Rick Client Type/FATCA Type",
                value: null,
            },
        ];
        let riskRows = data.map((item, index) => {
            return (
                <tr key={index}>
                    <td>{item.name ? item.name : "-------"}</td>
                    <td>{item.value ? item.value : "-------"}</td>
                </tr>
            );
        });
        return (
            <div className="dashboard-info__table-body w-100">
                <p className="dashboard-info__table-header font-neo-semibold mb-1">Risk and FATCA</p>
                <table className="dashboard-info__table table table-sm table-borderless">
                    <tbody>
                        {riskRows}
                    </tbody>
                </table>
            </div>
        );
    }

    onClickAction = (item) => {
        console.log(item);

        this.props.setClientOrPolicy({ policyId: item });

        writeData('policy-client', { policyId: item, id: 1 });
        routeChange(this.props.history, "/dashboard/single-policy");
    }

    async getTableData() {
        await this.fetchThisFa(this.props.getSession().access_token, decrypt(this.state.faId));
    }

    changePage = (pageNumber) => {
        let that = this;
        if (this.state.currentPage != pageNumber) {
            this.setState({
                currentPage: pageNumber,
                // isLoading: true,
                loadTable: true,
            }, () => {
                this.getTableData();
                // await that.fetchBirthdayList(that.props.getSession().access_token, decrypt(that.props.getSession().agentNumber));
            }
            );
        }
    }

    policyTable = (css) => {
        const columns = [
            "Policy Number",
            "Plan Name",
            //"Status",
            "Proposed Insured",
            "Effective Date",
            "Currency",
            "Expiry Date"
        ];


        let policyColumns = columns.map((item, index) => {
            return (
                <th key={index} scope="col">{item}</th>
            );
        });
        console.log("Policies ");
        console.log(this.state.policies);
        let policyRows = this.state.policies.map((item, index) => {
            return (
                <tr className="policy-row cs-pointer" key={index} onClick={() => this.onClickAction(item.policyNumber)}>
                    <td scope="row">{item.policyNumber}</td>
                    <td>{item.planName}</td>
                    {
                    /*
                    **removing policy status due to inconsistency with the data source
                    <td><button className={"dashboard-datatable__button btn rounded-pill text-white " + item.status.toLowerCase()}>{item.status}</button></td>
                    */
                    }
                    <td>{item.insured}</td>
                    <td>{item.effectiveDate}</td>
                    <td>{item.currency}</td>
                    <td>{item.expiryDate}</td>
                </tr>
            )
        });
        return (
            <Auxi>
                <table className={`dashboard-policy__table table table-borderless ${css}`}>
                    <thead>
                        <tr>
                            {policyColumns}
                        </tr>
                    </thead>
                    <tbody>
                        {policyRows}
                    </tbody>
                </table>
            </Auxi>
        );
    }


    mobileAccord = (css) => {
        const data = this.state.policies;
        const customName = {
            planName: "Plan Name",
            modalPremium: "Modal Premium",
            paymentMode: "Mode of Payment",
            insured: "Insured",
            effectiveDate: "Effective Date",
            currency: "Currency",
            expiryDate: "Expiry Date"
        };

        let policies = data.map((item, index) => {
            let status_class = item.status.toLowerCase();
            let class_url = "btn " + status_class + " text-white rounded-pill data-accord__button pes-none";
            return (
                <div key={index} className={`data-accord__card card border-0`}>
                    <button className="data-accord__toggle btn">
                        <div className="row data-accord__start">
                            <div className="col-5">
                                <div className="data-accord__name">{item.policyNumber}</div>
                            </div>
                            {
                            /*
                            **removing policy status due to inconsistency with the data source
                            <div className="col-7 d-flex justify-content-between">
                                <button className={class_url}>{item.status}</button>
                                <button className="btn p-0" type="button" data-toggle="collapse" data-target={`#collapse-${index}`}>
                                    <div className="data-accord__caret">
                                        <div className="data-accord__caret-icon"></div>
                                    </div>
                                </button>
                            </div>
                            */
                            }
                        </div>
                    </button>
                    <div id={`collapse-${index}`} className="collapse" data-parent="#data-accord">
                        <div className="card-body data-accord__content">
                            <div className="row">
                                <div className="data-accord__column col-5">
                                    <div className="data-accord__label">{customName.planName}</div>
                                    <div className="data-accord__value">{item.planName}</div>
                                </div>

                                <div className="data-accord__column col-7">
                                    <div className="data-accord__label">{customName.insured}</div>
                                    <div className="data-accord__value">{item.insured}</div>
                                </div>
                                <div className="data-accord__column col-5">
                                    <div className="data-accord__label">{customName.effectiveDate}</div>
                                    <div className="data-accord__value">{item.effectiveDate}</div>
                                </div>
                                <div className="data-accord__column col-7">
                                    <div className="data-accord__label">{customName.currency}</div>
                                    <div className="data-accord__value">{item.currency}</div>
                                </div>
                                <div className="data-accord__column col-5">
                                    <div className="data-accord__label">{customName.expiryDate}</div>
                                    <div className="data-accord__value">{item.expiryDate}</div>
                                </div>
                                <div className="data-accord__column col-7 d-flex align-items-end justify-content-end">
                                    <button onClick={() => this.onClickAction(item.policyNumber)} className="btn btn-darkgreen rounded-pill data-accord__button">Summary</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            );
        });

        return (

            <div className={`data-accord row flex-wrap mt-4 ${css}`}>
                <div className="data-accord__header">
                    <div className="col-5 d-flex align-items-center">Policy Number</div>
                    {
                    /*
                    **removing policy status due to inconsistency with the data source
                    <div className="col-7 d-flex align-items-center">Status</div>
                    */
                    }
                </div>
                <div className="col-12 data-accord__body mt-1">
                    <div className="data-accord__collapse" id="data-accord">
                        {policies}
                    </div>
                </div>
            </div>
        );
    }

    tableView = () => {
        let desktopView = this.policyTable("d-md-table d-none");
        let mobileView = this.mobileAccord("d-block d-md-none");

        if (this.state.loadTable === true) {
            desktopView = <LoaderGif css="d-md-block d-none" />;
            mobileView = <LoaderGif css="d-block d-md-none" />;
        }
        return (
            <Auxi>
                {desktopView}
                {mobileView}
                {this.state.policies.length > 0 ?
                    <TablePagination
                        css={`mt-2 ${this.state.loadTable ? "d-none" : ""}`}
                        data={this.state.policies}
                        page={this.state.currentPage}
                        totalSize={this.state.totalSize}
                        onPageChange={this.changePage}
                        pageSize={myConfig.PAGE_SIZE}
                        pages={this.state.pages}
                    /> : ""
                }
            </Auxi>

        );
    }

    render() {
        let info = "";
        let tabName = renameByChannel("FA Information", this.props.getSession().channel, this.props.getSession().view_as_channel);
        if (this.state.isLoading) {
            return (
                <ReportLoader handleClose={this.hideModal} show={this.state.isLoading} />
            );
        } else {
            if (this.state.faDashboardData.length > 0) {
                info = (
                    <div className="dashboard-info">
                        {this.dashboardInfoFields()}
                        <ul className="dashboard-info__tabs nav nav-tabs mt-4 justify-content-center justify-content-md-start" role="tablist">
                            <li className="dashboard-info__tab nav-item">
                                <a className="dashboard-info__tab-link nav-link active" data-toggle="tab" href="#faInfo" role="tab">{tabName}</a>
                            </li>
                            <li className="dashboard-info__tab nav-item">
                                <a className="dashboard-info__tab-link nav-link" data-toggle="tab" href="#policyList" role="tab">List of Policies</a>
                            </li>
                        </ul>
                        <div className="tab-content">
                            <div className="tab-pane fade show active" id="faInfo" role="tabpanel">
                                <div className="dashboard-info__table-container mt-3 bg-white p-4 shadow rounded">
                                    {this.basicInfo()}
                                    <div className="d-flex flex-column w-md-60">
                                        {this.address()}
                                        {/* / {this.risk()} */}
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="policyList" role="tabpanel">
                                {this.tableView()}
                            </div>
                        </div>
                    </div>
                );
            } else {
                info = (
                    <div className="dashboard-policy center-align">
                        No Information Available
                    </div>
                );
            }

            let pageName = renameByChannel("Financial Advisor Information", this.props.getSession().channel, this.props.getSession().view_as_channel);

            return (
                <div className="dashboard-section">
                    <div className="dashboard-container container-fluid p-3">
                        <div className="dashboard-header">
                            <h6 className="font-neo-bold text-darkgray">{pageName}</h6>
                        </div>
                        {this.showError()}
                        <div className="dashboard-body mt-4">
                            {info}
                        </div>
                    </div>
                </div>
            );

        }
    }
}
export default withRouter(SingleFa);
