
import React, { Component } from 'react';
import ReactTable from 'react-table';
import { ReportLoader } from '../commons/utilityViews.js';
import { Loader } from '../commons/utilityViews.js';
import { withRouter, Link } from 'react-router-dom';
import ReportDatePicker from "../components/reportDatepicker";
import ReportDatepickerDropdown from "../components/reportDatepickerDropdown";
import { Years } from '../components/Datepicker/customHeader';
import Auxi from '../components/auxi';
import { apiModule, createQueryStrings } from '../commons/apiCall';
import { myConfig } from '../commons/config.js';
import { fixName, numberWithCommas, removeNull, checkDateRange, prettifyDate, calculateAge, writeData, readAllData, encrypt, decrypt } from '../commons/utility.js';
import TableLoader from '../components/TableLoader/tableLoader';
import withWindowDimensions from '../commons/withWindowDimensions';
import ReportsMobile from '../components/Reports/Mobile/ReportsMobile';
import TablePagination from '../components/tablePagination';
import handleSortOrder from '../components/misc/sortOrder';
import routeChange from '../commons/routeChange';

//default dates
var toDate = new Date();
toDate.setDate(new Date().getDate() + 30);
var formattedCurrentDate = new Date().getFullYear() + "-" + (new Date().getMonth() + 1) + "-" + new Date().getDate();
var formattedToDate = toDate.getFullYear() + "-" + (toDate.getMonth() + 1) + "-" + toDate.getDate();

class PolicyDueReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            error: '',
            startDate: Date.parse(formattedCurrentDate),
            endDate: Date.parse(formattedToDate),
            currentPage: 0,
            statusFilter: { value: "matured", label: "Matured" },
            dropdownOptions: [],
            reports: [],
            loadTable: false,
            sortByField: null,
            sortOrder: null
        };
        this.pageHeader = "Matured and Paid up Policies"
    }

    componentDidMount() {

        // INITIALIZAING DROPDOWN ARRAY
        let dropdownOptions = [
            { value: "matured", label: "Matured" },
            { value: "claimed", label: "Paid-Up" },
        ]

        this.setState({
            isLoading: true,
            dropdownOptions: dropdownOptions,
        }, () => {
            this.getTableData();
        }); 

    }

    showTable = () => {
        if (this.state.startDate && this.state.endDate) {
            this.setState({
                currentPage: 0,
                // isLoading: true
                loadTable: true,
            }, () => {
                this.getTableData();
            });
        }
    }

    async getTableData() {
        await this.fetchPolicyList(this.props.getSession().access_token, decrypt(this.props.getSession().agentNumber));
    }

    //SHOW ERROR IF ERROR STATE IS NOT NULL
    showError = () => {
        let message = '';
        if (this.state.error !== '') {
            message =
                <div className="bg-error py-1 px-4">
                    <p className="text-white mb-0 font-neo-semibold">ERROR: {this.state.error}</p>
                </div>
        }
        return message;
    }

    updateStartDate = (date) => {
        this.setState({
            startDate: date,
        }, () => {
            if (this.state.endDate != null) {
                this.showTable();
            }
        });
    }

    updateEndDate = (date) => {
        this.setState({
            endDate: date,
        }, () => {
            if (this.state.startDate != null) {
                this.showTable();
            }
        });
    }

    fetchPolicyList = (access_token, agentNumber) => {
        let that = this;
        
        return new Promise(function (resolve, reject) {
            let continueProcess = true;
            let headerParams = { x_auth: access_token };
            let filter = [];
            filter['pageNumber'] = (that.state.currentPage + 1);
            filter['agentNumber'] = agentNumber;
            filter['reportCode'] = 'RPT_POL_MATURED_PAID'
            filter['pageSize'] = myConfig.PAGE_SIZE;
            filter['status'] = that.state.statusFilter.value;

            // if (that.state.startDate != null) {
            //     filter['dueDateFrom'] = prettifyDate(that.state.startDate);
            //     continueProcess = (checkDateRange(filter['dueDateTo'], filter['dueDateFrom']) === "") ? true : false;

            // } else {
            //     filter['dueDateFrom'] = formattedCurrentDate;
            // }

            // if (that.state.endDate != null) {
            //     filter['dueDateTo'] = prettifyDate(that.state.endDate);
            //     continueProcess = (checkDateRange(filter['dueDateTo'], filter['dueDateFrom']) === "") ? true : false;

            // } else {
            //     filter['dueDateTo'] = formattedToDate;
            // }

            if (that.state.sortByField != null) {
                filter['sortBy'] = that.state.sortByField + ',' + that.state.sortOrder
            }
            // "reportCode=RPT_POL_MATURED_PAID, agentNumber, status, pageNumber, pageSize"


            if (continueProcess) {
                apiModule("reports", headerParams, null, createQueryStrings(filter))
                    .then(function (response) {

                        let result = [];
                        let totalSize = 0;
                        if (response != null) {

                            if (Array.isArray(response)) {
                                if (response.length > 0) {
                                    result = response[0].content;
                                    totalSize = response[0].totalSize;
                                } else {
                                    result = [];
                                    totalSize = 0;
                                }
                            } else {
                                result = response.content;
                                totalSize = response.totalSize;
                            }
                        }

                        let numOfPages = parseInt((totalSize / myConfig.PAGE_SIZE), 10);

                        if ((totalSize % myConfig.PAGE_SIZE) > 0) {
                            numOfPages++;
                        }

                        let reports = [];

                        if (result) {

                            for (let i = 0; i < result.length; i++) {

                                reports.push(
                                    {
                                        POL_NO: removeNull(result[i].contractNumber),
                                        I_COMP_NAME: fixName(result[i].insured),//.lastName + ", " + result[i].insured.lastName),
                                        POL_DS: removeNull(result[i].productOfferingName),
                                        EFF_DT: removeNull(prettifyDate(result[i].inceptionDate)),
                                        POL_CURR_CD: removeNull(result[i].currency),
                                        FACE_AMT: removeNull(numberWithCommas(result[i].contractBalance)),
                                        EXPIRY_DT: removeNull(prettifyDate(result[i].contractInterval.endTimeDate)),
                                        //POL_STAT: removeNull(result[i].status)
                                    }
                                );
                            }

                            that.setState({
                                reports: reports
                            });

                        } else {
                            that.showError("No Account Found");
                        }

                        that.setState({
                            reports: reports,
                            loadTable: false,
                            totalSize: totalSize,
                            pages: numOfPages,
                            showTable: true,
                            error: '',
                            isLoading: false
                        });
                    })
                    .catch(function (err) {
                        console.log(err);
                        that.setState({
                            isLoading: false,
                            error: myConfig.GENERIC_ERROR_MESSAGE
                        }, () => {

                            console.log(err.message)
                        });
                    })
            } else {
                that.setState({
                    isLoading: false,
                    error: checkDateRange(filter['dueDateTo'], filter['dueDateFrom'])
                }, () => {
                    that.showError();
                });
            }
            // let result = null;



        });
    }

    handleSortAction = (sortByField, sortOrder) => {
        this.setState({
            sortByField: sortByField,
            sortOrder: sortOrder,
            // isLoading: true
            loadTable: true,
        }, () => {
            this.getTableData();
        })
    }
    headerClickedHandler = (sortByField) => {
        let sortOrder = handleSortOrder({ sortByField: sortByField, state: this.state });
        this.handleSortAction(sortByField, sortOrder)
    }

    onClickAction = (item) => {
        this.props.setClientOrPolicy({ policyId: item });
        writeData('policy-client', { policyId: item, id: 1 });
        routeChange(this.props.history, "/dashboard/single-policy");
    }

    changePage = (pageNumber) => {
        let that = this;
        if (this.state.currentPage != pageNumber) {
            this.setState({
                currentPage: pageNumber,
                // isLoading: true,
                loadTable: true,
            }, () => {
                this.getTableData();
                // await that.fetchBirthdayList(that.props.getSession().access_token, decrypt(that.props.getSession().agentNumber));
            }
            );
        }
    }

    updateFilter = (filter) => {
        this.setState({
            statusFilter: filter,
        }, () => {
            this.showTable();
        });
    }

    render() {
        const customNames = [
            {
                Header: 'Policy Number',
                accessor: 'POL_NO'
            },
            {
                Header: "Insured",
                accessor: 'I_COMP_NAME'
            },
            {
                Header: "Plan Name",
                accessor: 'POL_DS'
            },
            {
                Header: "Effective Date",
                accessor: 'EFF_DT',
            },
            {
                Header: "Currency",
                accessor: "POL_CURR_CD",
            },
            {
                Header: "Face Amount",
                accessor: "FACE_AMT"
            },
            {
                Header: "Expiry Date",
                accessor: "EXPIRY_DT",
            },
            /*
            **removing policy status due to inconsistency with the data source
            {
                Header: "Status",
                accessor: "POL_STAT",
                Cell: props => <button className={"dashboard-datatable__button btn text-uppercase rounded-pill text-white " + props.value.toLowerCase()} >{props.value.toLowerCase()}</button>
            }
            */
        ];

        if (this.state.isLoading) {
            return (
                <ReportLoader handleClose={this.hideModal} show={this.state.isLoading} />
            );
        } else {

            let dataTable = "";

            if (this.state.reports.length > 0) {
                if (this.props.isSm) {
                    dataTable = (
                        <ReportsMobile
                            title={[
                                {
                                    Header: 'Policy Number',
                                    accessor: 'POL_NO',
                                    hasCaret: true,
                                    onClick: () => {
                                        this.headerClickedHandler('POL_NO');
                                    }
                                },
                                /*
                                **removing policy status due to inconsistency with the data source
                                {
                                    Header: 'Status',
                                    accessor: 'POL_STAT',
                                    Cell: props => <button className={"dashboard-datatable__button btn text-uppercase rounded-pill text-white " + props.value.toLowerCase()} >{props.value.toLowerCase()}</button>
                                },
                                */
                            ]
                            }
                            customNames={customNames}
                            data={this.state.reports}
                            hasOnClickBtn={true}
                            onClickAction={this.onClickAction}
                        >
                            <TablePagination
                                css="mt-2"
                                data={this.state.reports}
                                page={this.state.currentPage}
                                totalSize={this.state.totalSize}
                                onPageChange={this.changePage}
                                pageSize={myConfig.PAGE_SIZE}
                                pages={this.state.pages}
                            />
                            {this.state.loadTable ? <TableLoader loading={true} /> : ""}
                        </ReportsMobile >
                    );
                } else {
                    dataTable = (

                        <ReactTable
                            className={`mt-4 ${this.state.showTable ? "d-flex" : "d-none"} rt-no-hover`}
                            loading={this.state.loadTable}
                            LoadingComponent={TableLoader}
                            resizable={false}
                            columns={customNames}
                            defaultPageSize={myConfig.PAGE_SIZE}
                            minRows={0}
                            data={this.state.reports}
                            page={this.state.currentPage}
                            manual
                            onFetchData={(state, instance) => {
                                this.changePage(state.page);
                            }}
                            getTheadThProps={(state, rowInfo, column, instance) => ({
                                onClick: () => {
                                    this.headerClickedHandler(column.id);
                                },
                                sortClassName: (
                                    this.state.sortByField == column.id ?
                                        (this.state.sortOrder == "ASC" ?
                                            "icon-chevron-small-up"
                                            : "icon-chevron-dropdown")
                                        : ""
                                ),
                                sortColor: (
                                    this.state.sortByField == column.id ?
                                        "text-black"
                                        : ""
                                )
                            })}
                            getTrProps={(state, rowInfo, column, instance) => ({
                                onClick: () => {
                                    this.onClickAction(rowInfo.original.POL_NO);
                                },
                                style: {
                                    cursor: 'pointer'
                                }
                            })}
                            pages={this.state.pages}
                            totalSize={this.state.totalSize}
                        />
                    );
                }
            } else {
                dataTable = (
                    <div className="center-align dashboard-row-data font-neo-semibold">No records found </div>
                );
            }

            return (
                <div className="dashboard-section">
                    {this.showError()}
                    <div className="dashboard-container container-fluid p-3">
                        <div className="dashboard-header">
                            <h6 className="font-neo-bold">
                                <Link className="text-darkgray text-decoration-none" to="/dashboard/reports">Reports</Link>
                                <span className="dashboard-header__subheader text-headercolor"> | {this.pageHeader}</span>
                            </h6>
                            {this.props.getViewAs}
                        </div>
                        <div className="dashboard-body mt-4">
                            <ReportDatepickerDropdown
                                startDateValue={this.state.startDate}
                                endDateValue={this.state.endDate}
                                updateStartDate={this.updateStartDate}
                                updateEndDate={this.updateEndDate}
                                showTable={this.showTable}
                                placeholderFrom="Due Date From"
                                placeholderTo="Due Date To"
                                //CAN ADD MAX YEAR -> Years(minYear,maxYear)
                                yearOptions={Years(1990)}

                                //dropdownValue={this.state.dropdownOptions}
                                updateDropdown={this.updateFilter}
                                showTable={this.showTable}
                                dropdownPlaceholder="Report Filters"
                                styles={{
                                    option: (provided) => ({
                                        ...provided,
                                        fontFamily: "neo_semibold",
                                        fontSize: ".6rem",
                                        color: "#9A9B9C",
                                        backgroundColor: "white"
                                    }),
                                    menu: () => ({
                                        position: "absolute",
                                        border: 0,
                                        zIndex: "1090",
                                        backgroundColor: "white",
                                        borderRadius: "5px",
                                        boxShadow: "2px 2px 2px #DDDEE0"
                                    }),
                                }}
                                options={this.state.dropdownOptions}
                                defaultValue={this.state.statusFilter}
                            />
                            {dataTable}
                        </div>
                    </div>
                </div>
            );
        }
    }
}

export default withRouter(withWindowDimensions(PolicyDueReport));
