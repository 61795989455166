
import React, { Component } from 'react';
import ReactTable from 'react-table';
import { ReportLoader } from '../commons/utilityViews.js';
import { withRouter, Link } from 'react-router-dom';
import { Years } from '../components/Datepicker/customHeader';
import ReportDropdownSearch from '../components/reportDropdownSearch';
import Auxi from '../components/auxi';
import { apiModule, createQueryStrings } from '../commons/apiCall';
import { myConfig } from '../commons/config.js';
import { numberWithCommas, removeNull, fixName, prettifyDate, calculateAge, writeData, readAllData, encrypt, decrypt } from '../commons/utility.js';
import TableLoader from '../components/TableLoader/tableLoader';
import withWindowDimensions from '../commons/withWindowDimensions';
import ReportsMobile from '../components/Reports/Mobile/ReportsMobile';
import TablePagination from '../components/tablePagination';
import handleSortOrder from '../components/misc/sortOrder';
import routeChange from '../commons/routeChange';

class PolicyStatusReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            error: '',
            statusValue: null,
            showTable: false,
            currentPage: 0,
            reports: [],
            statusList: [
                // { value: "0", label: "Lapsed" },
                // { value: "1", label: "Matured" },
                // { value: "2", label: "Surrendered/ Cancelled" },
                // { value: "3", label: "Claimed" },
                // { value: "4", label: "Inforced" },
            ],
            sortByField: null,
            sortOrder: null
        };
        this.pageHeader = "Policy List per Status"

        this.updateStatus = this.updateStatus.bind(this);
    }

    componentDidMount() {
        // this.setState({
        //     isLoading: true
        // }, () => {
        //     this.getTableData();
        // });

        this.setState({
            isLoading: true
        }, () => {
            this.getStatusData();
        });
    }

    updateStatus = (status) => {
        this.setState({
            statusValue: status,
        }, () => {
            if (this.state.statusValue != null) {
                this.showTable();
            }
        });
    }

    showTable = () => {
        if (this.state.statusValue) {
            this.setState({
                // isLoading: true
                loadTable: true,
            }, () => {
                this.getTableData();
            });
        }
    }

    async getTableData() {
        await this.fetchPolicyList(this.props.getSession().access_token, decrypt(this.props.getSession().agentNumber));
    }

    async getStatusData() {
        await this.fetchStatus(this.props.getSession().access_token, decrypt(this.props.getSession().agentNumber));
    }

    fetchStatus = (access_token, agentNumber) => {
        let session = this.props.getSession();
        let that = this;

        return new Promise(function (resolve, reject) {
            let headerParams = {
                x_auth: access_token,
                dataListId: 'policyStatus'
            };
            let filter = [];

            filter['key'] = agentNumber;

            apiModule("get_datalist", headerParams, null, createQueryStrings(filter))
                .then(function (response) {
                    let result = response;
                    let statusList = [];

                    Object.keys(result).forEach(function (key) {
                        let item = key;
                        statusList.push(
                            new Object({
                                label: result[key],
                                value: item
                            })
                        );
                    });

                    that.setState({
                        statusList: statusList,
                        statusValue: statusList[0],
                    }, () => {
                        that.showTable();
                    });
                })
                .catch(function (err) {
                    console.log(err);
                    that.setState({
                        error: myConfig.GENERIC_ERROR_MESSAGE, //err.message,
                        isLoading: false
                    }, () => {
                        // that.showError(err);
                        console.log(err);
                    });
                })

        });
    }

    //SHOW ERROR IF ERROR STATE IS NOT NULL
    showError = () => {
        let message = '';
        if (this.state.error !== '') {
            message =
                <div className="bg-error py-1 px-4">
                    <p className="text-white mb-0 font-neo-semibold">ERROR: {this.state.error}</p>
                </div>
        }
        return message;
    }

    fetchPolicyList = (access_token, agentNumber) => {
        let that = this;

        return new Promise(function (resolve, reject) {

            let headerParams = { x_auth: access_token };
            let filter = [];
            filter['pageNumber'] = (that.state.currentPage + 1);
            filter['agentNumber'] = agentNumber;
            filter['reportCode'] = 'RPT_POL_PER_STATUS'
            filter['pageSize'] = myConfig.PAGE_SIZE;

            if (that.state.statusValue != null) {
                filter['status'] = that.state.statusValue.value;
            }

            if (that.state.sortByField != null) {
                filter['sortBy'] = that.state.sortByField + ',' + that.state.sortOrder
            }

            apiModule("reports", headerParams, null, createQueryStrings(filter))
                .then(function (response) {

                    let result = [];
                    let totalSize = 0;
                    if (response != null) {
                        if (Array.isArray(response)) {
                            if (response.length > 0) {
                                result = response[0].content;
                                totalSize = response[0].totalSize;
                            } else {
                                result = [];
                                totalSize = 0;
                            }
                        } else {
                            result = response.content;
                            totalSize = response.totalSize;
                        }
                    }

                    let numOfPages = parseInt((totalSize / myConfig.PAGE_SIZE), 10);

                    if ((totalSize % myConfig.PAGE_SIZE) > 0) {
                        numOfPages++;
                    }

                    let reports = [];

                    if (result) {

                        for (let i = 0; i < result.length; i++) {

                            // poNum: "103000001",
                            // insured: "Crasco, James",
                            // plan: "Optimum Gold",
                            // effDate: "11/15/2018",
                            // currency: "Php",
                            // amt: "10,000",
                            // expDate: "11/15/2018",

                            reports.push(
                                {
                                    POL_NO: removeNull(result[i].contractNumber),
                                    I_COMP_NAME: fixName(result[i].insured),//.lastName + ", " + result[i].insured.lastName),
                                    POL_DS: removeNull(result[i].productOfferingName),
                                    EFF_DT: removeNull(prettifyDate(result[i].inceptionDate)),
                                    POL_CURR_CD: removeNull(result[i].currency),
                                    FACE_AMT: removeNull(numberWithCommas(result[i].contractBalance)),
                                    EXPIRY_DT: removeNull(prettifyDate(result[i].contractInterval.endTimeDate)),
                                }
                            );
                        }

                        that.setState({
                            reports: reports
                        });

                    } else {
                        that.showError("No Account Found");
                    }

                    that.setState({
                        reports: reports,
                        loadTable: false,
                        totalSize: totalSize,
                        pages: numOfPages,
                        showTable: true,
                        error: '',
                        isLoading: false
                    });
                })
                .catch(function (err) {
                    console.log(err);
                    that.setState({
                        isLoading: false
                    }, () => {

                        that.showError(myConfig.GENERIC_ERROR_MESSAGE);
                        console.log(err.message)
                    });
                })
        });
    }

    handleSortAction = (sortByField, sortOrder) => {
        this.setState({
            sortByField: sortByField,
            sortOrder: sortOrder,
            // isLoading: true
            loadTable: true,
        }, () => {
            this.getTableData();
        })
    }
    headerClickedHandler = (sortByField) => {
        let sortOrder = handleSortOrder({ sortByField: sortByField, state: this.state });
        this.handleSortAction(sortByField, sortOrder)
    }

    onClickAction = (item) => {
        this.props.setClientOrPolicy({ policyId: item });
        writeData('policy-client', { policyId: item, id: 1 });
        routeChange(this.props.history, "/dashboard/single-policy");
    }

    changePage = (pageNumber) => {
        let that = this;
        if (this.state.currentPage != pageNumber) {
            this.setState({
                currentPage: pageNumber,
                // isLoading: true,
                loadTable: true,
            }, () => {
                this.getTableData();
                // await that.fetchBirthdayList(that.props.getSession().access_token, decrypt(that.props.getSession().agentNumber));
            }
            );
        }
    }

    render() {
        const customNames = [
            {
                Header: 'Policy Number',
                accessor: 'POL_NO'
            },
            {
                Header: "Insured",
                accessor: 'I_COMP_NAME'
            },
            {
                Header: "Plan Name",
                accessor: 'POL_DS'
            },
            {
                Header: "Effective Date",
                accessor: 'EFF_DT',
            },
            {
                Header: "Currency",
                accessor: "POL_CURR_CD"
            },
            {
                Header: "Face Amount",
                accessor: "FACE_AMT"
            },
            {
                Header: "Expiry Date",
                accessor: "EXPIRY_DT"
            },
        ];

        if (this.state.isLoading) {
            return (
                <ReportLoader handleClose={this.hideModal} show={this.state.isLoading} />
            );
        } else {

            let dataTable = "";

            if (this.state.reports.length > 0) {
                if (this.props.isSm) {
                    dataTable = (
                        <ReportsMobile
                            title={[
                                {
                                    Header: 'Policy Number',
                                    accessor: 'POL_NO',
                                    hasCaret: true,
                                    onClick: () => {
                                        this.headerClickedHandler('POL_NO');
                                    }
                                },
                                {
                                    Header: 'Insured',
                                    accessor: 'I_COMP_NAME',
                                    hasCaret: true,
                                    onClick: () => {
                                        this.headerClickedHandler('I_COMP_NAME');
                                    }
                                },
                            ]}
                            customNames={customNames}
                            data={this.state.reports}
                            hasOnClickBtn={true}
                            onClickAction={this.onClickAction}
                        >
                            <TablePagination
                                css="mt-2"
                                data={this.state.reports}
                                page={this.state.currentPage}
                                totalSize={this.state.totalSize}
                                onPageChange={this.changePage}
                                pageSize={myConfig.PAGE_SIZE}
                                pages={this.state.pages}
                            />
                            {this.state.loadTable ? <TableLoader loading={true} /> : ""}
                        </ReportsMobile>
                    );
                } else {
                    dataTable = (
                        <ReactTable
                            className={`mt-4 ${this.state.showTable ? "d-flex" : "d-none"} rt-no-hover`}
                            loading={this.state.loadTable}
                            LoadingComponent={TableLoader}
                            resizable={false}
                            columns={customNames}
                            defaultPageSize={myConfig.PAGE_SIZE}
                            minRows={0}
                            data={this.state.reports}
                            page={this.state.currentPage}
                            manual
                            onFetchData={(state, instance) => {
                                this.changePage(state.page);
                            }}
                            getTheadThProps={(state, rowInfo, column, instance) => ({
                                onClick: () => {
                                    this.headerClickedHandler(column.id);
                                },
                                sortClassName: (
                                    this.state.sortByField == column.id ?
                                        (this.state.sortOrder == "ASC" ?
                                            "icon-chevron-small-up"
                                            : "icon-chevron-dropdown")
                                        : ""
                                ),
                                sortColor: (
                                    this.state.sortByField == column.id ?
                                        "text-black"
                                        : ""
                                )
                            })}
                            getTrProps={(state, rowInfo, column, instance) => ({
                                onClick: () => {
                                    this.onClickAction(rowInfo.original.POL_NO);
                                },
                                style: {
                                    cursor: 'pointer'
                                }
                            })}
                            pages={this.state.pages}
                            totalSize={this.state.totalSize}
                        />
                    );
                }
            } else {
                dataTable = (
                    <div className="center-align dashboard-row-data font-neo-semibold">No records found </div>
                );
            }

            return (
                <div className="dashboard-section">
                    {this.showError()}
                    <div className="dashboard-container container-fluid p-3">
                        <div className="dashboard-header">
                            <h6 className="font-neo-bold">
                                <Link className="text-darkgray text-decoration-none" to="/dashboard/reports">Reports</Link>
                                <span className="dashboard-header__subheader text-headercolor"> | {this.pageHeader}</span>
                            </h6>
                            {this.props.getViewAs}
                        </div>
                        <div className="dashboard-body mt-4">
                            <ReportDropdownSearch
                                showSearchBar={false}
                                dropdownValue={this.state.statusValue}
                                updateDropdown={this.updateStatus}
                                showTable={this.showTable}
                                dropdownPlaceholder="Status"
                                styles={{
                                    option: (provided) => ({
                                        ...provided,
                                        fontFamily: "neo_semibold",
                                        fontSize: ".6rem",
                                        color: "#9A9B9C",
                                        backgroundColor: "white"
                                    }),
                                    menu: () => ({
                                        position: "absolute",
                                        border: 0,
                                        zIndex: "1090",
                                        backgroundColor: "white",
                                        borderRadius: "5px",
                                        boxShadow: "2px 2px 2px #DDDEE0"
                                    }),
                                }}
                                options={this.state.statusList}
                            />
                            {dataTable}
                        </div>
                    </div>
                </div>
            );
        }
    }
}

export default withRouter(withWindowDimensions(PolicyStatusReport));
