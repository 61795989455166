import React, { Component } from 'react';
import ReactTable from 'react-table';
import { Link } from 'react-router-dom';
import { prettifyDate, calculateAge, writeData, readAllData, commaDelimit } from '../../commons/utility.js';
import routeChange from '../../commons/routeChange';
import { apiModule } from '../../commons/apiCall';
import Auxi from '../auxi';
import LoaderGif from "../../commons/loaderGif";
import { myConfig } from '../../commons/config.js';
import FasMobile from './Mobile/FasMobile';
import TableLoader from '../TableLoader/tableLoader';
import withWindowDimensions from '../../commons/withWindowDimensions';
import TablePagination from '../../components/tablePagination';

class Fas extends Component {

    constructor(props) {
        super(props);
        this.state = {
            jwt: '',
            targetClicked: "",
            currentCaret: "",
            pageNumber: -1,
        }
    }

    onClickAction = (item) => {
        this.props.setClientOrPolicy({ faId: item });
        writeData('policy-client', { faId: item, id: 1 });
        routeChange(this.props.history, "/dashboard/single-fa");
    }

    changePage = (pageNumber) => {
        if (pageNumber.selected != null) {
            pageNumber = pageNumber.selected;
            if (this.props.currentPage != pageNumber) {
                this.props.beginLoading();
                this.props.search(pageNumber);
            }

        } else if (this.props.currentPage != pageNumber) {

            this.props.search(pageNumber);
        }
    }

    tableView = () => {
        let customNames = this.props.customNames;
        let headerColumns = [];
        for (let key in customNames) {
            if (key === "LDBRANCHCD") {
                headerColumns.push({
                    Header: customNames[key],
                    accessor: key,
                    Cell: props => <span>{commaDelimit(props.value)}</span>
                });
            } else if (key === "RP13" || key === "RP25") {
                headerColumns.push({
                    Header: customNames[key],
                    accessor: key,
                    Cell: props => props.value ? <a className="text-info cs-pointer" onClick={()=>this.props.setPersistencyModal(true, key.slice(2))}>{props.value}%</a> : "--",
                });
            } else {
                headerColumns.push({ Header: customNames[key], accessor: key })
            }

        }
        let desktopView = (
            <ReactTable
                className={`mt-4`}
                loading={this.props.loadTable ? true : false}
                resizable={false}
                columns={headerColumns}
                // data={this.clientRows(100)}
                defaultPageSize={myConfig.PAGE_SIZE}
                minRows={0}
                totalSize={this.props.totalSize}
                data={this.props.fas}
                manual
                LoadingComponent={TableLoader}
                onFetchData={(state, instance) => {
                    this.changePage(state.page);
                }}
                getTheadThProps={(state, rowInfo, column, instance) => ({
                    onClick: () => {
                        this.props.headerClickedHandler(column.id);
                    },
                    sortClassName: (
                        this.props.sortByField == column.id ?
                            (this.props.sortOrder == "ASC" ?
                                "icon-chevron-small-up"
                                : "icon-chevron-dropdown")
                            : ""
                    ),
                    sortColor: (
                        this.props.sortByField == column.id ?
                            "text-black"
                            : ""
                    )
                })}
                pages={this.props.pages}
                page={this.props.currentPage}
                getTrProps={(state, rowInfo, column, instance) => (
                    {
                        // onClick: () => this.onClickAction(rowInfo.original.faId)
                        onClick: (e) => {
                            if (e.target.parentElement === e.currentTarget) {
                                this.onClickAction(rowInfo.original.faId);
                            }
                            this.props.setSelectedAgentNumber(rowInfo.original.faId);
                        }
                    }
                )}
            />
        );

        let mobileView = (
            <div className="d-block d-md-none position-relative">
                <FasMobile
                    data={this.props.fas}
                    onClickAction={this.onClickAction}
                    customNames={this.props.customNames}
                    headerClickedHandler={this.props.headerClickedHandler}
                />
                {/* <ReactPaginate
                    pageCount={this.props.pages}
                    containerClassName="mobilePaginationContainer"
                    onPageChange={this.changePage}
                    activeClassName={"activeMobilePagination"}
                    initialPage={this.props.currentPage}
                /> */}

                <TablePagination
                    css="mt-2"
                    data={this.props.fas}
                    page={this.props.currentPage}
                    totalSize={this.props.totalSize}
                    onPageChange={this.changePage}
                    pageSize={myConfig.PAGE_SIZE}
                    pages={this.props.pages}
                />
                {this.props.loadTable ? <TableLoader loading={true} /> : ""}
            </div>
        );

        return (
            <Auxi>
                {this.props.isSm ? mobileView : desktopView}
            </Auxi>
        );
    }
    render() {
        return (
            this.tableView()
        );
    }
}
export default withWindowDimensions(Fas);
