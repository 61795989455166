import React, { Component } from 'react';
import '../css/mis.scss';
import AverageCaseSize from '../components/Mis/averageCaseSize';
import PercentAchieved from '../components/Mis/percentAchieved';
import ActualVsTarget from '../components/Mis/actualVsTarget';
import PerStatus from '../components/Mis/perStatus';
import PerPendingStatus from '../components/Mis/perPendingStatus';
import PerChannelRegion from '../components/Mis/perChannelRegion';
import PerProduct from '../components/Mis/perProduct';
import Heatmap from '../components/Mis/heatmap';
import PerClientType from '../components/Mis/perClientType';
import FundAllocation from '../components/Mis/fundAllocation';
import Filter from '../components/Mis/filter';
import { isManager, checkLogin, checkPermissions, getSessionInfo } from '../commons/security.js';
import MisDatePicker from "../components/Mis/misDatePicker";
import { Years } from '../components/Datepicker/customHeader';
import { apiModule, createQueryStrings } from '../commons/apiCall';
import { haveValue, misAssetUrl, isEmpty, months, defaultFilters } from '../commons/misConfig';
import Lottie from 'lottie-react-web';
import Coins from '../assets/animation/coins.json'
import moment from "moment";
import { dateStringify, getStringDate } from '../components/Mis/formatter';
import CommentSection from '../components/Mis/commentSection';
import { decrypt } from '../commons/utility.js';
import LoaderGif, { LoaderGif2, LoaderGif3 } from '../commons/loaderGif';
import TableList from '../components/Mis/tableList';
import MisMonthPicker from "../components/Mis/misMonthPicker";
import Popup from '../components/Mis/popup';

var day = dateStringify(new Date().getDate());
var month = dateStringify(new Date().getMonth() + 1);
var year = new Date().getFullYear();

// var formattedStartDate = year+"-"+month+"-01";
// var formattedFullYearStartDate = year+"-01-01";
// var formattedCurrentMonthDate = year+"-"+month+"-"+day;

var formattedStartDate = getStringDate(year, month, "01");
var formattedFullYearStartDate = getStringDate(year, "01", "01");
var formattedCurrentMonthDate = getStringDate(year, month, day);

// var formattedStartDate = getStringDate(yearToHardcode, monthToHardcode, "01");
// var formattedFullYearStartDate = getStringDate(yearToHardcode, "01", "01");
// var formattedCurrentMonthDate = getStringDate(yearToHardcode, monthToHardcode, DayToHardcode);

// var getCurrentMonthDays = new Date(yearToHardcode , monthToHardcode, 0);
var getCurrentMonthDays = new Date(new Date().getFullYear() , month, 0);

class DataReports extends Component {
    constructor(props) {
        super(props)
        this.state = {
            averageCaseSize: 0,
            percentAchieved: 0,
            percentAchievedCurrent: 0,
            percentAchievedTarget: 0,
            showSidebar: false,
            showCommentSection: false,
            startDate: new Date(formattedStartDate),
            endDate: new Date(formattedCurrentMonthDate),

            optionList: {
                period:{},
                metric: {},
                product: {},
                channel: {},
                status: {},
                target: {}
            },

            periodOptions: [],
            metricOptions: [],
            productOptions: [],
            channelOptions: [],
            statusOptions: [],
            targetOptions: [],
            coinLoader: <div style={{ height: 'calc(100% - 37px)', margin: 'auto' }}> <Lottie speed={4} options={{ animationData: Coins, loop: true }} /></div>,
            period: { value: "MTD", label: "MTD" },
            metric: { value: "ANP", label: "ANP" },
            product: { value: "ALL", label: "All" },
            channel: { value: "ALL", label: "All" },
            status: { value: "ISSUED", label: "Issued" },
            target: { value: "OKR", label: "OKR" },
            perMonthValue: {year: year, month: month},
            currPeriod: { value: "MTD", label: "MTD" },
            currMetric: { value: "ANP", label: "ANP" },
            currProduct: { value: "ALL", label: "All" },
            currChannel: { value: "ALL", label: "All" },
            currStatus: { value: "ISSUED", label: "Issued" },
            currTarget: { value: "OKR", label: "OKR" },
            currPerMonthValue: {year: year, month: month},
            willUpdate: true,
            isFetching: true,
            showHidden: true,
            isAllowed: true,
            activeChartComments: {},
            willUpdateCommentCount: true,
            version: "",
            isFilterLoading: null,
            showNBVInfo: false,
            nbvScenario: {
                code: "1",
                message: "All ANP Margins are updated"
            },
            isLoadingPeriod: true,
            isLoadingMetric: true,
            isLoadingChannel: true,
            isLoadingProduct: true,
            isLoadingStatus: true,
            isLoadingTarget: true,
            isLoadingFilters: true,
            isPNBChannelOnly: false,
            isDefaultSettingsLoaded: false,
            updateTime: '',
            hasUpdateTime: false,
            isCurrentDataUpdated: true,
            lastSuccessfulBatchDate: '',
        }
        this.onChangeFilter = this.onChangeFilter.bind(this);
        this.showError = this.showError.bind(this);
        this.onClickSidebarButton = this.onClickSidebarButton.bind(this);
        this.resetOptions = this.resetOptions.bind(this);
        this.clickedFilter = this.clickedFilter.bind(this);
        this.onClickDigitalText = this.onClickDigitalText.bind(this);
        this.updateStartDate = this.updateStartDate.bind(this);
        this.getOptions = this.getOptions.bind(this);
        this.showCommentIcon = this.showCommentIcon.bind(this);
        this.onClickCommentIcon = this.onClickCommentIcon.bind(this);
        this.closeCommentSection = this.closeCommentSection.bind(this);
        this.updateCommentCount = this.updateCommentCount.bind(this);
        this.callFSR = this.callFSR.bind(this);
        this.getVersion = this.getVersion.bind(this);
        this.getNBVScenario = this.getNBVScenario.bind(this);
        this.onChangePerMonth = this.onChangePerMonth.bind(this);
        this.changeDefaultFilters = this.changeDefaultFilters.bind(this);
        this.getLatestUpdateTime = this.getLatestUpdateTime.bind(this);
    }

    // componentDidMount() {
    //     let t = this;
    //     setTimeout(async function () {
    //         await t.callFSR();
    //     }, 180000);
    // }

    async callFSR() {
        // FSR LIBRARY
        let fsrCheck = window.fsr ? true : false;
        console.log(fsrCheck)
        if (fsrCheck) {
            var session = this.props.getSession();
            if (session) {
                // console.log(userData, googleSession);
                let email = session.username ? decrypt(session.username) : "";
                let name = session.name ? decrypt(session.name) : "";
                console.log(email, name, misAssetUrl);
                window.fsr.initSurvey({
                    assetURL: misAssetUrl,
                    username: email,
                    name: name,
                    // isSimulation: true, // DISABLE THIS FOR PROD
                });
                console.log('Survey Library is available');
            } else {
                console.log('User data not found!');
            }
        } else {
            console.log('Survey Library failed to load');
        }
    }

    resetOptions() {
        let { isPNBChannelOnly } = this.state;
        this.setState({
            willUpdate: true,
            period: { value: "MTD", label: "MTD" },
            metric: { value: "ANP", label: "ANP" },
            product: { value: "ALL", label: "All" },
            channel: isPNBChannelOnly ? defaultFilters.pnb.channel : defaultFilters.default.channel,
            status: { value: "ISSUED", label: "Issued" },
            target: { value: "OKR", label: "OKR" },
            perMonthValue: {year: year, month: month},
            currPeriod: { value: "MTD", label: "MTD" },
            currMetric: { value: "ANP", label: "ANP" },
            currProduct: { value: "ALL", label: "All" },
            currChannel: isPNBChannelOnly ? defaultFilters.pnb.channel : defaultFilters.default.channel,
            currStatus: { value: "ISSUED", label: "Issued" },
            currTarget: { value: "OKR", label: "OKR" },
            currPerMonthValue: {year: year, month: month},
            startDate: new Date(formattedStartDate),
            endDate: new Date(formattedCurrentMonthDate)
        })
    }

    formatOptions(rawOptions, hasSelectAll) {
        let options = [];
        if (hasSelectAll) {
            options = [{ value: "ALL", label: "All" }]
        }

        Object.keys(rawOptions).map(function (key) {
            let object = {
                value: key,
                label: rawOptions[key],
                isDisabled: false,
            }

            options.push(object);
        })
        return options;
    }

    async getVersion(access_token){
        let headerParams = {
            x_auth: access_token,
        };
        let version = await apiModule("get_mis_version", headerParams , null)
        .then(function (result) {
            return result.version;
        })
        .catch(function (err) {
            console.log(err);
            return "";
        });

        this.setState({
            version
        })
    }

    async changeDefaultFilters(){
        let session = await getSessionInfo();
        let isPNB = checkPermissions(['VIEW_MIS_PNB_ONLY'], session[0].permissions)
        if(isPNB){
            let pnbChannel = isPNB ? defaultFilters.pnb.channel : defaultFilters.default.channel;
            this.setState({
                channel: pnbChannel,
                currChannel: pnbChannel,
                isPNBChannelOnly: isPNB,
                willUpdate: true,
                willUpdateCommentCount: false,
                isDefaultSettingsLoaded: true
            })
        }
        else{
            this.setState({
                isPNBChannelOnly: false,
                willUpdate: true,
                willUpdateCommentCount: true,
                isDefaultSettingsLoaded: true
            })
        }
    }

    async getNBVScenario(access_token){
        let headerParams = {
            x_auth: access_token,
        };
        let that = this;
        await apiModule("get_nbv_scenario", headerParams , null)
        .then(function (result) {
            if(result !== null && result !== undefined && !(isEmpty(result))){
                that.setState({
                    nbvScenario: {
                        code: Object.keys(result)[0],
                        message: result[Object.keys(result)[0]]
                    }
                })
            }
        })
        .catch(function (err) {
            console.log(err);
        });
    }

    async getOptions(access_token) {
        let headerParams = {
            dataListId: '',
            x_auth: access_token
        };
        let thisComponent = this;
        let sessionInfo = await getSessionInfo();
        let viewPD = checkPermissions(['VIEW_PD_TARGET'], sessionInfo[0].permissions);
        let viewPNBChannelOnly = checkPermissions(['VIEW_MIS_PNB_ONLY'], sessionInfo[0].permissions);
        let optionList = this.state.optionList;

        headerParams.dataListId = 'period';
        optionList.period.default = await apiModule("get_datalist", headerParams, null)
            .then(function (result) {
                thisComponent.setState({
                    isLoadingPeriod: false,
                    periodOptions: thisComponent.formatOptions(result),
                    willUpdate: false,
                    willUpdateCommentCount: false
                })
                return thisComponent.formatOptions(result)
            })
            .catch(function (err) {
                console.log(err);
                thisComponent.setState({
                    isLoadingPeriod: false,
                    periodOptions: [],
                    willUpdate: false,
                    willUpdateCommentCount: false
                })
            });
        headerParams.dataListId = 'metric';
        optionList.metric.default = await apiModule("get_datalist", headerParams, null)
            .then(function (result) {
                thisComponent.setState({
                    isLoadingMetric: false,
                    metricOptions: thisComponent.formatOptions(result),
                    willUpdate: false,
                    willUpdateCommentCount: false
                })
                return thisComponent.formatOptions(result);
            })
            .catch(function (err) {
                console.log(err);
                thisComponent.setState({
                    metricOptions: [],
                    willUpdate: false,
                    willUpdateCommentCount: false
                })
            });
        headerParams.dataListId = 'channel';
        optionList.channel.default = await apiModule("get_datalist", headerParams, null)
            .then(function (result) {
                thisComponent.setState({
                    channelOptions: thisComponent.formatOptions(result, true),
                    willUpdate: false,
                    willUpdateCommentCount: false
                })
                return thisComponent.formatOptions(result, true);
            })
            .catch(function (err) {
                console.log(err);
                thisComponent.setState({
                    channelOptions: [],
                    willUpdate: false,
                    willUpdateCommentCount: false
                })
            });
        headerParams.dataListId = 'product';
        optionList.product.default = await apiModule("get_datalist", headerParams, null)
            .then(function (result) {
                thisComponent.setState({
                    isLoadingProduct: false,
                    productOptions: thisComponent.formatOptions(result, true),
                    willUpdate: false,
                    willUpdateCommentCount: false
                })
                return thisComponent.formatOptions(result, true);
            })
            .catch(function (err) {
                console.log(err);
                thisComponent.setState({
                    isLoadingProduct: false,
                    productOptions: [],
                    willUpdate: false,
                    willUpdateCommentCount: false
                })
            });
        headerParams.dataListId = 'achievedStatus';
        optionList.status.default = await apiModule("get_datalist", headerParams, null)
            .then(function (result) {
                thisComponent.setState({
                    statusOptions: thisComponent.formatOptions(result),
                    willUpdate: false,
                    willUpdateCommentCount: false
                })
                return thisComponent.formatOptions(result);
            })
            .catch(function (err) {
                console.log(err);
                thisComponent.setState({
                    statusOptions: [],
                    willUpdate: false,
                    willUpdateCommentCount: false
                })
            });
        headerParams.dataListId = 'targetType';
        optionList.target.default = await apiModule("get_datalist", headerParams, null)
            .then(function (result) {
                //No State Setting here due to conditions on whether the User has Access to PD or not
                return thisComponent.formatOptions(result);
            })
            .catch(function (err) {
                console.log(err);
            });

        //OPTIONS WITH KEYS
        let filter = { key: '' };
        filter.key = 'mancom';
        headerParams.dataListId = 'targetType';
        optionList.target.MANCOM = await apiModule("get_datalist", headerParams, null, createQueryStrings(filter))
            .then(function (result) {
                thisComponent.setState({
                    isLoadingTarget: false,
                    targetOptions: viewPD ? thisComponent.formatOptions(result) : optionList.target.default,
                    willUpdate: false,
                    willUpdateCommentCount: false
                })
                return thisComponent.formatOptions(result);
            })
            .catch(function (err) {
                console.log(err);
                thisComponent.setState({
                    isLoadingTarget: false,
                    targetOptions: optionList.target.default ? optionList.target.default : [],
                    willUpdate: false,
                    willUpdateCommentCount: false
                })
            });
        filter.key = 'pd';
        headerParams.dataListId = 'channel';
        optionList.channel.PD = await apiModule("get_datalist", headerParams, null, createQueryStrings(filter))
            .then(function (result) {
                thisComponent.setState({
                    isLoadingChannel: false,
                    willUpdate: false,
                    willUpdateCommentCount: false
                })
                return thisComponent.formatOptions(result, true);
            })
            .catch(function (err) {
                thisComponent.setState({
                    isLoadingChannel: false,
                    willUpdate: false,
                    willUpdateCommentCount: false
                })
                console.log(err);
            });
        filter.key = 'submitted';
        headerParams.dataListId = 'metric';
        optionList.metric.SUBMITTED = await apiModule("get_datalist", headerParams, null, createQueryStrings(filter))
            .then(function (result) {
                thisComponent.setState({
                    isLoadingMetric: false,
                    willUpdate: false,
                    willUpdateCommentCount: false
                })
                return thisComponent.formatOptions(result);
            })
            .catch(function (err) {
                thisComponent.setState({
                    isLoadingMetric: false,
                    willUpdate: false,
                    willUpdateCommentCount: false
                })
                console.log(err);
            });
        filter.key = 'nbv';
        headerParams.dataListId = 'achievedStatus';
        optionList.status.NBV = await apiModule("get_datalist", headerParams, null, createQueryStrings(filter))
            .then(function (result) {
                thisComponent.setState({
                    isLoadingStatus: false,
                    willUpdate: false,
                    willUpdateCommentCount: false
                })
                return thisComponent.formatOptions(result);
            })
            .catch(function (err) {
                thisComponent.setState({
                    isLoadingStatus: false,
                    willUpdate: false,
                    willUpdateCommentCount: false
                })
                console.log(err);
            });
        

        headerParams.dataListId = 'achievedStatus';
        let statusCall = await apiModule("get_datalist", headerParams, null)
            .then(function (result) {
                return thisComponent.formatOptions(result);
            })
            .catch(function (err) {
                console.log(err);
            });

        this.setState({
            isAllowed: true,
            access_token: access_token,
            isLoading: true,
            isFetching: false,
            willUpdate: false,
            optionList: optionList,
            isLoadingFilters: false
        })
    }

    onChangeFilter(key, e, forceUpdate, newStartDate, newEndDate) {
        const { currChannel, currMetric, currProduct, currStatus, currTarget, optionList} = this.state;
        let currFilters = {
            "PERIOD": 'currPeriod',
            "METRIC": 'currMetric',
            "PRODUCT": 'currProduct',
            "CHANNEL": 'currChannel',
            "STATUS": 'currStatus',
            "TARGET": 'currTarget'
        }

        let advancedFilter = e;
        let startDate = this.state.startDate;
        let endDate = this.state.endDate;
        let currPeriod = this.state.currPeriod;
        let channel = this.state.channel;
        let channelOptions = this.state.channelOptions;
        let metricOptions = this.state.metricOptions;
        let statusOptions = this.state.statusOptions;
        let perMonthValue = this.state.perMonthValue

        if (key.toUpperCase() == 'PERIOD') {
            if (advancedFilter.value == 'MTD') {
                startDate = new Date(formattedStartDate)
                endDate = new Date(formattedCurrentMonthDate)
                advancedFilter = {
                    value: 'MTD',
                    label: 'MTD'
                }
            }
            else if (advancedFilter.value == 'YTD') {
                startDate = new Date(formattedFullYearStartDate);
                endDate = new Date(formattedCurrentMonthDate);

                advancedFilter = {
                    value: 'YTD',
                    label: 'YTD'
                }
            } else if (advancedFilter.value === 'LAST MONTH') {
                let currentDate = new Date(formattedStartDate);
                // currentDate = new Date(currentDate.setFullYear(2019)); // DISABLED SINCE LAST MONTH SHOULD NOT BE LIMITED TO 2019 LOL
                startDate = currentDate.setDate(1);
                startDate = currentDate.setMonth(currentDate.getMonth() - 1);
                startDate = new Date(startDate);
                startDate = startDate.setHours(0, 0, 0, 0);
                startDate = new Date(startDate);
                endDate = new Date(new Date(startDate).getFullYear(), new Date(startDate).getMonth() + 1, 0);
                endDate = endDate.setHours(23, 59, 59, 0);
                endDate = new Date(endDate);
            } else if (advancedFilter.value === 'Q1') {
                let currentDate = new Date();
                currentDate = new Date(currentDate.setFullYear(year));
                startDate = new Date(currentDate.getFullYear(), 0, 1);
                startDate = startDate.setHours(0, 0, 0, 0);
                endDate = new Date(currentDate.getFullYear(), 3, 0);
                endDate = endDate.setHours(23, 59, 59, 0);
                advancedFilter = {
                    value: 'Q1',
                    label: 'Q1'
                }
            } else if (advancedFilter.value === 'Q2') {
                let currentDate = new Date();
                currentDate = new Date(currentDate.setFullYear(year));
                startDate = new Date(currentDate.getFullYear(), 3, 1);
                startDate = startDate.setHours(0, 0, 0, 0);
                endDate = new Date(currentDate.getFullYear(), 6, 0);
                endDate = endDate.setHours(23, 59, 59, 0);
                advancedFilter = {
                    value: 'Q2',
                    label: 'Q2'
                }
            } else if (advancedFilter.value === 'Q3') {
                let currentDate = new Date();
                currentDate = new Date(currentDate.setFullYear(year));
                startDate = new Date(currentDate.getFullYear(), 6, 1);
                startDate = startDate.setHours(0, 0, 0, 0);
                endDate = new Date(currentDate.getFullYear(), 9, 0);
                endDate = endDate.setHours(23, 59, 59, 0);
                advancedFilter = {
                    value: 'Q3',
                    label: 'Q3'
                }
            } else if (advancedFilter.value === 'Q4') {
                let currentDate = new Date();
                currentDate = new Date(currentDate.setFullYear(year));
                startDate = new Date(currentDate.getFullYear(), 9, 1);
                startDate = startDate.setHours(0, 0, 0, 0);
                endDate = new Date(currentDate.getFullYear(), 12, 0);
                endDate = endDate.setHours(23, 59, 59, 0);
                advancedFilter = {
                    value: 'Q4',
                    label: 'Q4'
                }
            } else if (advancedFilter.value === 'PER MONTH') {
                let currentDate = new Date();
                currentDate = new Date(currentDate.setFullYear(year));
                startDate = new Date(currentDate.getFullYear(), perMonthValue.month, 1);
                startDate = startDate.setHours(0, 0, 0, 0);
                endDate = new Date(currentDate.getFullYear(), perMonthValue.month, 0);
                endDate = endDate.setHours(23, 59, 59, 0);
                advancedFilter = {
                    value: 'MTD',
                    label: 'Per Month'
                }
            } else {
                startDate = newStartDate ? newStartDate : startDate;
                endDate = newEndDate ? newEndDate : endDate;
            }
        }

        if(key.toUpperCase().includes('METRIC')){
            statusOptions = advancedFilter.value.toUpperCase().includes('NBV') ? optionList.status.NBV : optionList.status.default;
        }

        if(key.toUpperCase().includes('STATUS')){
            metricOptions = advancedFilter.value.toUpperCase().includes('SUBMITTED') ? optionList.metric.SUBMITTED : optionList.metric.default;
        }

        if(key.toUpperCase().includes('TARGET')){
            channelOptions = advancedFilter.value.toUpperCase().includes('PD') ? optionList.channel.PD : optionList.channel.default;
            if(channelOptions.filter((obj) => obj.label === channel.label).length === 0){
                this.setState({
                    channel: channelOptions[0]
                })
            }
        }

        this.setState({
            [key]: advancedFilter,
            [currFilters[key.toUpperCase()]]: forceUpdate ? advancedFilter : currFilters[key.toUpperCase()],
            startDate: startDate,
            endDate: endDate,
            currPeriod: forceUpdate ? (key.toUpperCase() === 'PERIOD' ? e : currPeriod) : currPeriod,
            currMetric: currMetric,
            currChannel: forceUpdate ? (key.toUpperCase() === 'CHANNEL' ? e : currChannel) : currChannel,
            currProduct: forceUpdate ? (key.toUpperCase() === 'PRODUCT' ? e : currProduct) : currProduct,
            currStatus: currStatus,
            currTarget: currTarget,
            willUpdate: forceUpdate ? forceUpdate : false,
            channelOptions: channelOptions,
            metricOptions: metricOptions,
            statusOptions: statusOptions
        })

    }

    clickedFilter() {
        const { period, metric, product, channel, status, target, perMonthValue } = this.state;
        this.setState({
            willUpdate: true,
            currPeriod: period,
            currMetric: metric,
            currProduct: product,
            currChannel: channel,
            currStatus: status,
            currTarget: target,
            currPerMonthValue: perMonthValue,
            showSidebar: false
        })
    }

    async componentWillMount() {
        let access_token = this.props.getSession().access_token;
        let session = await getSessionInfo();
        if (session.length > 0 && checkPermissions(['VIEW_MIS'], session[0].permissions)) {
            await this.getOptions(access_token);            
            await this.getVersion(access_token);
            await this.getNBVScenario(access_token);
        } else {
            this.setState({ isAllowed: false, isLoading: false, error: "You are not allowed to access this page" });
        }

    }

    onClickSidebarButton(showSidebar) {
        this.setState({
            willUpdate: false,
            showSidebar: !showSidebar
        })
    }

    showError() {
        let message = '';
        if (this.state.error !== '') {
            message =
                <div className="bg-error py-1 px-4">
                    <p className="text-white mb-0 font-neo-semibold">ERROR: {this.state.error}</p>
                </div>
        }
        return message;
    }

    updateStartDate(date, forceUpdate) {
        this.setState({
            willUpdate: forceUpdate ? forceUpdate : false,
            startDate: date
        });
    }

    updateEndDate = (date, forceUpdate) => {
        this.setState({
            willUpdate: forceUpdate ? forceUpdate : false,
            endDate: date
        });
    }

    onClickDigitalText() {
        this.setState({
            showHidden: !this.state.showHidden,
            willUpdate: false
        })
    }

    showCommentIcon(that, showIcon){
        that.setState({
            isCommentVisible: showIcon,
            willUpdate: false
        })
    }

    onClickCommentIcon(chart){
        if(chart !== undefined || chart !== null){
            this.setState({
                activeChartComments: chart,
                showCommentSection: true,
                willUpdate: false,
                willUpdateCommentCount: false
            })
        }
    }

    closeCommentSection(){
        this.setState({
            showCommentSection: false,
            willUpdate: false,
        })
    }

    updateCommentCount(){
        this.setState({
            willUpdate: false,
            willUpdateCommentCount: true
        })
    }

    getCommentCount(thisComponent, chartId, accessToken){
        let commentHeaderParams = { chartId: chartId, x_auth: accessToken };
        let commentFilter = [];
        commentFilter['filter'] = 'count';

        apiModule("get_comments", commentHeaderParams, null, createQueryStrings(commentFilter))
            .then(function (result){
                thisComponent.setState({
                    totalComments: result.totalComments ? result.totalComments : 0
                })

            })
            .catch(function (err) {
                console.log(err)
            });
    }

    onClickNBVInfo(update){
        this.setState({
            showNBVInfo: update
        })
    }

    onChangePerMonth(year, month){
        let currentDate = new Date();
        currentDate = new Date(currentDate.setFullYear(year));
        let startDate = new Date(currentDate.getFullYear(), month - 1, 1);
        startDate = startDate.setHours(0, 0, 0, 0);
        let endDate = new Date(currentDate.getFullYear(), month, 0);
        endDate = endDate.setHours(23, 59, 59, 0);
        this.setState({
            perMonthValue: {
                year,
                month
            },
            willUpdate: false,
            willUpdateCommentCount: false,
            startDate,
            endDate
        })
    }

    

    async getLatestUpdateTime(dateTime){
        let previousDay = new Date(formattedCurrentMonthDate);
        previousDay = moment(previousDay).subtract(1, 'days');
        let lastSuccessfulBatchDate = 'No Datetime Available';

        let isDataUpdated = false;

        if(Date.parse(dateTime)){
            isDataUpdated = moment(dateTime).format('YYYY-MM-DD') == moment(previousDay).format('YYYY-MM-DD') 
            || moment(dateTime).format('YYYY-MM-DD') == moment(formattedCurrentMonthDate).format('YYYY-MM-DD');
            lastSuccessfulBatchDate = moment(dateTime).add(1, 'days');
        }

        this.setState({
            willUpdate: false,
            willUpdateCommentCount: false,
            updateTime: dateTime,
            hasUpdateTime: true,
            isCurrentDataUpdated: isDataUpdated,
            lastSuccessfulBatchDate: lastSuccessfulBatchDate
        })
    }

    render() {
        const { showSidebar, isFetching } = this.state;
        let that = this;
        if (!this.state.isAllowed) {
            return (
                <div className="dashboard-section overflow-hidden">
                    {this.showError()}
                </div>
            );
        } else {
            const { period, metric, product, channel, status, target, perMonthValue, willUpdate, periodOptions, metricOptions, productOptions, channelOptions, statusOptions, targetOptions, currPeriod, currMetric, currProduct, currChannel, currStatus, currTarget, currPerMonthValue, showCommentSection } = this.state;
            const { isLoadingPeriod, isLoadingMetric, isLoadingChannel, isLoadingProduct, isLoadingStatus, isLoadingTarget, isLoadingFilters, isPNBChannelOnly, isDefaultSettingsLoaded } = this.state;
            const access_token = this.props.getSession().access_token;
            let startDate = moment(this.state.startDate).format('YYYY-MM-DD');
            let endDate = moment(this.state.endDate).format('YYYY-MM-DD');
            let displayDate = Date.parse(this.state.updateTime) ? moment(this.state.updateTime).format("MMM. DD, YYYY") : "No Datetime Available";
            let that = this;
            const loader = <div style={{ margin: 'auto', padding: '200px 0' }}><LoaderGif2 css="ml-auto" /></div>
            if(!isDefaultSettingsLoaded){
                //Loading Settings
                this.changeDefaultFilters();
                return(
                    <div className="row" style={{margin: '0px'}}>{loader}</div>
                )
            }
            else{
            return (
                <div>
                    <div className="mis-section">
                        {this.state.hasUpdateTime && !this.state.isCurrentDataUpdated ? <Popup currentDate={this.state.lastSuccessfulBatchDate} lastUpdate={this.state.updateTime}/> : ''}
                        <div className="mis-dashboard-header font_neo_semibold">
                            <span className="mis-header-title">
                                Reports | <span style={{ color: "#255f8e", marginLeft: "3px" }}>Digital MIS </span> <span className="mis-last-update-time"><span>Last Updated:</span><span>{displayDate}</span> </span>
                            </span>
                            <div style={{ position: "fixed", display: "flex", right: "1rem" }}>
                                <span className="mis-sidebar-legend">
                                    LEGEND
                                    <span className="green-box" /><label>Above Target</label>
                                    <span className="red-box" /><label style={{ marginRight: '15px' }}>Below Target</label>
                                </span>
                                <div className="mis-legend-list">
                                    <span style={{ verticalAlign: "top" }}>ACTIVE FILTERS</span>
                                    <span className="mis-legend">
                                        <div>{currPeriod.label.toUpperCase() === 'PER MONTH' ? months[currPerMonthValue.month - 1].toUpperCase() : currPeriod.label.toUpperCase()}</div>
                                        <div className="mis-legend-label">Period</div>
                                    </span>
                                    <span className="mis-legend">
                                        <div>{currMetric.label.toUpperCase()}</div>
                                        <div className="mis-legend-label">Metric</div>
                                    </span>
                                    { !isPNBChannelOnly ?
                                    <span className="mis-legend">
                                        <div>{currChannel.label.toUpperCase()}</div>
                                        <div className="mis-legend-label">Channel</div>
                                    </span> : "" }
                                    <span className="mis-legend">
                                        <div>{currProduct.label.toUpperCase()}</div>
                                        <div className="mis-legend-label">Product</div>
                                    </span>
                                    <span className="mis-legend">
                                        <div>{currStatus.label.toUpperCase()}</div>
                                        <div className="mis-legend-label">Status</div>
                                    </span>
                                    <span className="mis-legend">
                                        <div>{currTarget.label.toUpperCase()}</div>
                                        <div className="mis-legend-label">Target</div>
                                    </span>
                                </div>
                                <label className="pl-2 mis-filter-container" onClick={() => { this.onClickSidebarButton(showSidebar) }}><span className={(showSidebar ? 'sidebar-shown' : 'sidebar-hidden')}>←</span> {showSidebar ? "Hide" : "Display"} Filters</label>
                            </div>
                        </div>
                        <div className="mis-dashboard-container container-fluid p-3" style={{ paddingBottom: '5rem' }}>
                            <div className="row" style={{ margin: "2rem 0rem 0rem .1rem", height: "100%", width: "100%" }}>
                                <div className="container col-sm-12 col-md-12 col-lg-12" style={{ textAlign: "center" }} >
                                    <div className="row">
                                        <div className="container mis-container col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                            <div className="row">
                                                <div className="container mis-container col-sm-5 col-md-5 col-lg-3 col-xl-3">
                                                    <AverageCaseSize 
                                                        access_token={access_token} 
                                                        {...this.state} 
                                                        startDate={startDate} 
                                                        endDate={endDate} 
                                                        showCommentIcon={this.showCommentIcon}
                                                        onClickCommentIcon={this.onClickCommentIcon}
                                                        getCommentCount={this.getCommentCount}
                                                        // getLatestUpdateTime={this.getLatestUpdateTime}
                                                        />
                                                    <PercentAchieved 
                                                        access_token={access_token} 
                                                        {...this.state} 
                                                        startDate={startDate} 
                                                        endDate={endDate}
                                                        showCommentIcon={this.showCommentIcon} 
                                                        onClickCommentIcon={this.onClickCommentIcon}
                                                        getCommentCount={this.getCommentCount}/>
                                                </div>
                                                <PerStatus
                                                    access_token={access_token}
                                                    {...this.state}
                                                    startDate={startDate}
                                                    endDate={endDate} 
                                                    showCommentIcon={this.showCommentIcon}
                                                    onClickCommentIcon={this.onClickCommentIcon}
                                                    getCommentCount={this.getCommentCount}/>
                                                <PerPendingStatus
                                                    access_token={access_token}
                                                    {...this.state}
                                                    startDate={startDate}
                                                    endDate={endDate}
                                                    showCommentIcon={this.showCommentIcon} 
                                                    onClickCommentIcon={this.onClickCommentIcon}
                                                    getCommentCount={this.getCommentCount}/>
                                            </div>
                                        </div>
                                        <div className="container mis-container col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                            <div className="row">
                                                <ActualVsTarget 
                                                    access_token={access_token} 
                                                    {...this.state} 
                                                    startDate={startDate} 
                                                    endDate={endDate}
                                                    maxDays={getCurrentMonthDays.getDate()} 
                                                    onChangeFilter={this.onChangeFilter}
                                                    showCommentIcon={this.showCommentIcon} 
                                                    onClickCommentIcon={this.onClickCommentIcon}
                                                    getCommentCount={this.getCommentCount}
                                                    getLatestUpdateTime={this.getLatestUpdateTime}
                                                    hasUpdateTime={this.state.hasUpdateTime}/>
                                                <Heatmap
                                                    access_token={access_token}
                                                    {...this.state}
                                                    startDate={startDate}
                                                    endDate={endDate}
                                                    showCommentIcon={this.showCommentIcon}
                                                    onClickCommentIcon={this.onClickCommentIcon}
                                                    getCommentCount={this.getCommentCount} />
                                            </div>
                                        </div>
                                        <div className="container mis-container col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                            <div className="row">
                                                <PerChannelRegion
                                                    access_token={access_token}
                                                    {...this.state}
                                                    startDate={startDate}
                                                    endDate={endDate}
                                                    onChangeFilter={this.onChangeFilter}
                                                    showCommentIcon={this.showCommentIcon} 
                                                    onClickCommentIcon={this.onClickCommentIcon}
                                                    getCommentCount={this.getCommentCount}
                                                    isPNBChannelOnly={isPNBChannelOnly}/>
                                                <PerProduct
                                                    access_token={access_token}
                                                    {...this.state}
                                                    startDate={startDate}
                                                    endDate={endDate}
                                                    onChangeFilter={this.onChangeFilter}
                                                    showCommentIcon={this.showCommentIcon} 
                                                    onClickCommentIcon={this.onClickCommentIcon}
                                                    getCommentCount={this.getCommentCount}/>
                                                <FundAllocation 
                                                    access_token={access_token} 
                                                    {...this.state} 
                                                    startDate={startDate} 
                                                    endDate={endDate}
                                                    showCommentIcon={this.showCommentIcon} 
                                                    onClickCommentIcon={this.onClickCommentIcon}
                                                    getCommentCount={this.getCommentCount}/>
                                                <PerClientType 
                                                    access_token={access_token} 
                                                    {...this.state} 
                                                    startDate={startDate} 
                                                    endDate={endDate}
                                                    showCommentIcon={this.showCommentIcon} 
                                                    onClickCommentIcon={this.onClickCommentIcon}
                                                    getCommentCount={this.getCommentCount}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {showSidebar ? <div className="mis-overlay-bg" onClick={() => { this.onClickSidebarButton(showSidebar) }}></div> : ''}
                                <div className="mis-bottombar-container">
                                    {/* {showSidebar ?
                                        <div className="mis-bottombar-header-container">
                                            <label>FILTER</label>
                                            <button type="button" className="btn mis-bottombar-button" onClick={this.resetOptions}>Reset All Filters</button>
                                            <button type="button" className="btn mis-bottombar-button" onClick={this.clickedFilter}>Filter</button>
                                        </div> : ''}
                                    <div className={showSidebar ? 'mis-bottombar-filter-container-show' : 'mis-bottombar-filter-container-hidden'}>
                                        <div className="mis-bottombar-opt-container">
                                            {periodOptions ? periodOptions.map(function (obj, index) {
                                                return (
                                                    <span className="mis-radio-btn-container " key={'period-' + index} onClick={(e) => that.onChangeFilter('period', obj, false)}><div className={period.label.toUpperCase() == obj.value.toUpperCase() ? "mis-radio-selected" : 'mis-radio-unselected'}><div></div></div><label>{obj.label}</label></span>
                                                )
                                            }) : ''}
                                            <div style={{ display: 'grid' }}>
                                                {period.label.toUpperCase().includes('DATE') &&
                                                    <MisDatePicker
                                                        className="mis-bottombar-date-container"
                                                        startDateValue={new Date(startDate)}
                                                        endDateValue={new Date(endDate)}
                                                        updateStartDate={(date) => { this.updateStartDate(date, false) }}
                                                        updateEndDate={(date) => { this.updateEndDate(date, false) }}
                                                        placeholderFrom="Due Date From"
                                                        placeholderTo="Due Date To"
                                                        dateFormat="yyyy-MM-dd"
                                                        minDate={new Date(formattedFullYearStartDate)}
                                                        maxDate={new Date(formattedCurrentMonthDate)} //uncomment if dev
                                                    // maxDate={new Date("2019-11-16")}
                                                    //CAN ADD MAX YEAR -> Years(minYear,maxYear)
                                                    // yearOptions={Years(yearToday)}
                                                    />
                                                }
                                            </div>
                                        </div>
                                        <div className="mis-bottombar-opt-container">
                                            {metricOptions ? metricOptions.map(function (obj, index) {
                                                return (
                                                    obj.isDisabled ? '' : <span className="mis-radio-btn-container" key={'metric-' + index} onClick={(e) => that.onChangeFilter('metric', obj, false)}><div className={metric.value === obj.value ? "mis-radio-selected" : 'mis-radio-unselected'}><div></div></div><label>{obj.label}</label></span>
                                                )
                                            }) : ''}
                                        </div>
                                        <div className="mis-bottombar-opt-container">
                                            {channelOptions ? channelOptions.map(function (obj, index) {
                                                return (
                                                    obj.isDisabled ? '' : <span className="mis-radio-btn-container" key={'channel-' + index} onClick={(e) => that.onChangeFilter('channel', obj, false)}><div className={channel.value === obj.value ? "mis-radio-selected" : 'mis-radio-unselected'}><div></div></div><label>{obj.label}</label></span>
                                                )
                                            }) : ''}
                                        </div>
                                        <div className="mis-bottombar-opt-container">
                                            {productOptions ? productOptions.map(function (obj, index) {
                                                return (
                                                    obj.isDisabled ? '' : <span className="mis-radio-btn-container" key={'product-' + index} onClick={(e) => that.onChangeFilter('product', obj, false)}><div className={product.value === obj.value ? "mis-radio-selected" : 'mis-radio-unselected'}><div></div></div><label>{obj.label}</label></span>
                                                )
                                            }) : ''}
                                        </div>
                                        <div className="mis-bottombar-opt-container">
                                            {statusOptions ? statusOptions.map(function (obj, index) {
                                                return (
                                                    obj.isDisabled ? '' : <span className="mis-radio-btn-container" key={'status-' + index} onClick={(e) => that.onChangeFilter('status', obj, false)}><div className={status.value === obj.value ? "mis-radio-selected" : 'mis-radio-unselected'}><div></div></div><label>{obj.label}</label></span>
                                                )
                                            }) : ''}
                                        </div>
                                        <div className="mis-bottombar-opt-container">
                                            {targetOptions ? targetOptions.map(function (obj, index) {
                                                return (
                                                    <span className="mis-radio-btn-container" key={'target-' + index} onClick={(e) => that.onChangeFilter('target', obj, false)}><div className={target.value === obj.value ? "mis-radio-selected" : 'mis-radio-unselected'}><div></div></div><label>{obj.label}</label></span>
                                                )
                                            }) : ''}
                                        </div>
                                    </div>
                                    <div className="mis-bottombar-body" onClick={() => { this.onClickSidebarButton(showSidebar) }}>
                                        <div>
                                            <span className="mis-bottombar-value">{currPeriod.label}</span>
                                            <span>PERIOD</span>
                                        </div>
                                        <div>
                                            <span className="mis-bottombar-value">{currMetric.label}</span>
                                            <span>METRIC</span>
                                        </div>
                                        <div>
                                            <span className="mis-bottombar-value">{currChannel.label}</span>
                                            <span>CHANNEL</span>
                                        </div>
                                        <div>
                                            <span className="mis-bottombar-value">{currProduct.label}</span>
                                            <span>PRODUCT</span>
                                        </div>
                                    </div> */}
                                    <div className="mis-bottombar-body" onClick={() => { this.onClickSidebarButton(showSidebar) }}>
                                        <div>
                                            <span className="mis-bottombar-value">
                                                <img className="mis-sidebar-icon" src="/mis/calendar-alt.svg"/> 
                                                <label>{currPeriod.label.toUpperCase() === 'PER MONTH' ? months[currPerMonthValue.month - 1] : currPeriod.label}</label>
                                            </span>
                                            <span className="mis-bottombar-value">
                                                <img className="mis-sidebar-icon" src="/mis/th.svg"/> 
                                                <label>{currProduct.label}</label>
                                            </span>
                                        </div>
                                        <div>
                                            <span className="mis-bottombar-value">
                                                <img className="mis-sidebar-icon" src="/mis/chart-bar.svg"/> 
                                                <label>{currMetric.label}</label>
                                            </span>
                                            <span className="mis-bottombar-value">
                                                <img className="mis-sidebar-icon" src="/mis/file-medical-alt.svg"/> 
                                                <label>{currStatus.label}</label>
                                            </span>
                                        </div>  
                                        <div>
                                            <span className="mis-bottombar-value">
                                                <img className="mis-sidebar-icon" src="/mis/landmark.svg"/>
                                                <label>{currChannel.label}</label>
                                            </span>
                                            <span className="mis-bottombar-value">
                                                <img className="mis-sidebar-icon" src="/mis/flag.svg"/>
                                                <label>{currTarget.label}</label>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="container mis-sidebar-container col-sm-10 col-md-6 col-lg-4 col-xl-3 " id="mis-sidebar-container"
                                    style={{ width: showSidebar ? "85%" : "0px", padding: 'unset', transition: 'all 0.2s ease-in-out' }}>
                                    <div className="mis-sidebar">
                                        <div>
                                            <div className="card" style={{ border: "none", background: "transparent" }}>
                                                <label className="card-header mis-sidebar-header">
                                                    FILTERS
                                                </label>
                                                <div className="card-body mis-sidebar-filters">
                                                    { isLoadingPeriod ? <img className="mis-icon-loading-spin " src="/mis/spinner.svg"/> : 
                                                        <span>
                                                        <label className="mis-sidebar-label"><img className="mis-sidebar-icon" src="/mis/calendar-alt.svg"/> Period</label>
                                                        <Filter options={periodOptions} value={period} onChange={(e) => this.onChangeFilter('period', e)} />
                                                        {period.label.toUpperCase().includes('DATE') &&
                                                            <MisDatePicker
                                                                className="ml-3"
                                                                startDateValue={new Date(startDate)}
                                                                endDateValue={new Date(endDate)}
                                                                updateStartDate={this.updateStartDate}
                                                                updateEndDate={this.updateEndDate}
                                                                placeholderFrom="Due Date From"
                                                                placeholderTo="Due Date To"
                                                                dateFormat="yyyy-MM-dd"
                                                                minDate={new Date(formattedFullYearStartDate)}
                                                                maxDate={new Date(formattedCurrentMonthDate)}
                                                            // maxDate={new Date()} //uncomment if dev
                                                            //CAN ADD MAX YEAR -> Years(minYear,maxYear)
                                                            // yearOptions={Years(yearToday-5, yearToday)}
                                                            />
                                                        }
                                                        {period.label.toUpperCase().includes("PER MONTH") && 
                                                            <MisMonthPicker 
                                                                year={perMonthValue.year} 
                                                                month={perMonthValue.month}
                                                                maxMonth={month}
                                                                onChangeMonth={this.onChangePerMonth}/>
                                                        }
                                                        </span>
                                                    }
                                                    { isLoadingMetric ? <img className="mis-icon-loading-spin " src="/mis/spinner.svg"/> : 
                                                        <span>
                                                            <label className="mis-sidebar-label"><img className="mis-sidebar-icon" src="/mis/chart-bar.svg"/> Metric</label>
                                                            <Filter options={metricOptions} value={metric} onChange={(e) => this.onChangeFilter('metric', e)} />
                                                            { metric.value.toUpperCase() === 'NBV' ? 
                                                                <div className="nbv-warning-container">
                                                                    <span className="nbv-icon">&#9432;</span>
                                                                    {/* <span className="nbv-warning-statement">Some NBV calculations are still based on previous ANP Margin.  */}
                                                                    <span className="nbv-warning-statement">{this.state.nbvScenario.message}
                                                                        { this.state.nbvScenario.code == 3 ? <span className="nbv-statement-more-info" onClick={() => this.onClickNBVInfo(true)}> More Info</span> : ""}
                                                                    </span>
                                                                </div>
                                                                : ""
                                                            }
                                                        </span>
                                                    }
                                                    { !isPNBChannelOnly ? isLoadingChannel ? <img className="mis-icon-loading-spin " src="/mis/spinner.svg"/> : 
                                                        <span>
                                                            <label className="mis-sidebar-label"><img className="mis-sidebar-icon" src="/mis/landmark.svg"/> Channel</label>
                                                            <Filter options={channelOptions} value={channel} onChange={(e) => this.onChangeFilter('channel', e)} />
                                                        </span>
                                                     : ""}
                                                    { isLoadingProduct ? <img className="mis-icon-loading-spin " src="/mis/spinner.svg"/> : 
                                                        <span>
                                                            <label className="mis-sidebar-label"><img className="mis-sidebar-icon" src="/mis/th.svg"/> Product Type</label>
                                                            <Filter options={productOptions} value={product} onChange={(e) => this.onChangeFilter('product', e)} />
                                                        </span>
                                                    }
                                                    { isLoadingStatus ? <img className="mis-icon-loading-spin " src="/mis/spinner.svg"/> : 
                                                        <span>
                                                            <label className="mis-sidebar-label"><img className="mis-sidebar-icon" src="/mis/file-medical-alt.svg"/> Status</label>
                                                            <Filter options={statusOptions} value={status} onChange={(e) => this.onChangeFilter('status', e)} />
                                                        </span>
                                                    }
                                                    { isLoadingTarget ? <img className="mis-icon-loading-spin " src="/mis/spinner.svg"/> : 
                                                        <span>
                                                            <label className="mis-sidebar-label"><img className="mis-sidebar-icon" src="/mis/flag.svg"/> Target</label>
                                                            <Filter options={targetOptions} value={target} onChange={(e) => this.onChangeFilter('target', e)} />
                                                        </span>
                                                    }
                                                </div>
                                            </div>
                                            {isLoadingFilters ? "" : 
                                                <span>
                                                    <button type="button" className="btn mis-sidebar-button btn-filter" onClick={this.clickedFilter}>FILTER</button>
                                                    <button type="button" className="btn mis-sidebar-button btn-reset-filters" onClick={this.resetOptions}>RESET FILTERS</button>
                                                </span>
                                            }
                                            <button type="button" className="btn mis-sidebar-button btn-cancel" onClick={() => this.onClickSidebarButton(showSidebar)}>CANCEL</button>
                                            {this.state.version && <div className="mis-sidebar-version">v{this.state.version}</div>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>                                
                    { showCommentSection ? <div className="mis-overlay-bg" style={{zIndex: 1300}} onClick={() => { this.closeCommentSection() }}></div> : ''}
                    { showCommentSection && <CommentSection {...this.state} closeCommentSection={this.closeCommentSection} updateCommentCount={this.updateCommentCount}/>}
                    <div id="fsrContainer"></div>
                    {this.state.showNBVInfo ? <TableList access_token={access_token} onClickOverlay={() => this.onClickNBVInfo(false)}/> : ""}
                </div>
            )
        }
        }
    }
}

export default DataReports;