import React, { Component } from 'react';
import { myConfig } from '../commons/config.js';
import { PdfPopupModal } from '../components/AgentKnowledgebase/pdfPopupModal';
import { AdobePdfModal } from '../components/AgentKnowledgebase/adobePdfModal';
class DigitalIdEdm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            headerText: "",
            activePdfLink: "",
            activePdfRotation: 0,
            activePageNum: 1,
            totalPageNum: 1,
        }
    }

    componentDidMount() {
        if (this.props.channel == "BANCA") {
            this.setState({
                activePdfLink: myConfig.BANCA_EDM_FILE,
                file: {
                    "fileUrl": myConfig.BANCA_EDM_FILE
                }
            })
        } else {
            this.setState({
                activePdfLink: myConfig.AGENCY_EDM_FILE,
                file: {
                    "fileUrl": myConfig.AGENCY_EDM_FILE
                }
            })
        }
    }

    setPdfTotalPages = (totalPageNum) => {
        this.setState({ totalPageNum });
    }

    setPdfNavigation = (number) => {
        const activePageNum = this.state.activePageNum + number;
        this.setState({ activePageNum });
    }

    setPdfRotation = () => {
        const currentRotation = this.state.activePdfRotation;
        if (currentRotation === 360) {
            this.setState({ activePdfRotation: 0 });
        } else {
            this.setState({ activePdfRotation: currentRotation + 90 });
        }
    }

    render() {
        const { toggleDigitalIdEdmModal, channel } = this.props;
        return (
            <PdfPopupModal
                headerText={"Announcement!"}
                activePdfLink={this.state.activePdfLink}
                activePdfRotation={this.state.activePdfRotation}
                activePageNum={this.state.activePageNum}
                totalPageNum={this.state.totalPageNum}

                onCloseClick={toggleDigitalIdEdmModal}
                setPdfTotalPages={this.setPdfTotalPages}
                setPdfNavigation={this.setPdfNavigation}
                setPdfRotation={this.setPdfRotation}
                file={this.state.file}
                isSm={this.props.isSm}
            />
            // <AdobePdfModal
            //     headerText={"Announcement!"}
            //     activePdfLink={this.state.activePdfLink}
            //     onCloseClick={toggleDigitalIdEdmModal}
            //     isSm={this.props.isSm}
            // />
        )
    }
}

export default DigitalIdEdm;