import React from 'react';

const PolicyInformation = (policyId, owner, insured, transactionNumber) => {
  return(
      <section className="policy-services-module">
        <header className="policy-services-module__parent-container">
          <div className="policy-services-module__child-container--padded">
            <h6 className="policy-services-module__regular-header">Selected Policy Information</h6>
          </div>
          <div className="policy-horizontal-line"></div>
        </header>
        <article className="policy-services-module__parent-container d-flex flex-column">
          <div className="policy-services-module__parent-row d-flex">
            <div className="policy-services-module__child-container--padded w-50">
              <h5 className="policy-services-module__bolded-header">Policy No.</h5>
              <span>{ policyId }</span>
            </div>
            {
              transactionNumber
                ? <div className="policy-services-module__child-container--padded w-50">
                    <h5 className="policy-services-module__bolded-header">Transaction Number</h5>
                    <span>{ transactionNumber }</span>
                  </div>
                : null
            }
          </div>
          <div className="policy-horizontal-line"></div>
        </article>
        <div className="policy-services-module__parent-container d-flex flex-column">
          <div className="policy-services-module__parent-row d-flex">
            <article className="policy-services-module__child-container--padded w-50">
              <h5 className="policy-services-module__bolded-header">Policy Owner Name</h5>
              <span>{ owner }</span>
            </article>
            <article className="policy-services-module__child-container--padded w-50">
              <h5 className="policy-services-module__bolded-header">Policy Insured Name</h5>
              <span>{ insured }</span>
            </article>
          </div>
        </div>
      </section>
  )
}

export default PolicyInformation;