import React, { Component } from 'react';
import { Loader } from '../commons/utilityViews.js';
import { BrowserRouter as Router, Route, Link, Redirect, withRouter } from 'react-router-dom'
import { apiModule, createQueryStrings } from '../commons/apiCall.js';
import { appendFileType, removeNullProfilePic, writeData, jsonToURI, readAllData, encrypt, decrypt, changeUsernameToAllianzpnblife } from '../commons/utility.js';
import routeChange from '../commons/routeChange';
import AuthLoader from '../commons/authLoader';
import { head, indexOf } from 'lodash';
import Otp from './otp.js';
import { myConfig } from '../commons/config.js';

class Verify extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            error: '',
            code: '',
        };
        this.authenticateOTP = this.authenticateOTP.bind(this);
        this.getUserRole = this.getUserRole.bind(this);
        this.getWebUser = this.getWebUser.bind(this);
        this.getAccountManager = this.getAccountManager.bind(this);
        this.getUser = this.getUser.bind(this);
    }

    otpInputs = ["first", "second", "third", "fourth", "fifth", "sixth"];

    async componentDidMount() {
        let that = this;
        let session = this.props.getSession();

        if (session.code === undefined || session.code === null || session.code === "") {
            readAllData('session')
                .then(function (data) {
                    for (var dt of data) {
                        /*dt.agentNumber = decrypt(dt.agentNumber);
                        dt.name = decrypt(dt.name);*/
                        that.props.setUserSession(dt);
                    }
                });
        }
    }

    resendOTP = () => {
        const that = this;
        let headerParams = {};

        this.setState({
            isLoading: true
        })

        let payloadParams = {
            code: that.props.getSession().code,
            grant_type: "otp_resend"
        };

        apiModule("authentication_resend_otp", headerParams, payloadParams, '', true)
            .then(function (result) {
                if (result.status === 200) {
                    console.log('result', result);
                    let userData = {};
                    readAllData('session')
                        .then(function (data) {
                            for (var dt of data) {
                                userData = dt;
                                dt.code = result.data.code;
                            }
                            writeData('session', userData).then(function () {
                                console.log("Updating session done!");
                            });

                            that.setState({
                                isLoading: false,
                                code: result.data.code,
                                error: "OTP resent."
                            });
                        });

                } else {
                    that.setState({
                        isLoading: false,
                        error: "Oops something went wrong!",
                    });
                }
            })

            .catch(function (err) {
                console.log('err', err)
                const response = JSON.stringify(err);

                
                console.log('response', response);
                const { response: { data: { error } } = { data: {error: ''}} } = JSON.parse(response);


                const errorCodes = {
                    710: "OTP resend limit (5) has been met. OTP resending will be locked for 15 minutes."
                }
                if(errorCodes.hasOwnProperty(error)) {
                    that.setState({
                      isLoading: false,
                      error: errorCodes[error]
                    });
                  } else {
                    that.setState({
                      isLoading: false,
                      error: myConfig.GENERIC_ERROR_MESSAGE
                    });
                  }
            })
    }

    fetchUserPermissions = (headerParams) => {
        return new Promise(function (resolve, reject) {
            apiModule("get_webuser_permissions", headerParams, null)
                .then(function (permissions_result) {
                    let permissions = [];
                    for (let i = 0; i < permissions_result.data.length; i++) {
                        permissions.push(permissions_result.data[i].name);
                    }

                    resolve(permissions);
                })
                .catch(function (err) {
                    reject(err);
                });

        });
    }
    
    doOTP = async (otpInputs) => {

        const that = this;
        that.setState({ isLoading: true });


        let headerParams = {};
        let code = that.state.code;
        
        if (that.state.code === '' || that.state.code === undefined || that.state.code === null) {
            code = that.props.getSession().code;
        }

        let otpString = '';
        Object.keys(otpInputs).forEach(otpObjectKey => {
            otpString = otpString.concat(otpInputs[otpObjectKey].value)
        });

        // let otpString = that.state.payloadParams.first + that.state.payloadParams.second + that.state.payloadParams.third + that.state.payloadParams.fourth + that.state.payloadParams.fifth + that.state.payloadParams.sixth
        
        if(otpString === '') {
            this.setState({
                isLoading: false,
                error: 'Please provide your OTP first.'
            });
            return;
        }
        
        let payloadParams = {
            grant_type: 'otp',
            code: code,
            otp: otpString
        };

        const otp = await this.authenticateOTP(headerParams, payloadParams, that);
        try{
            if(otp) {

                let session = { 
                    resetPassword: false, 
                    channel: "", 
                    alias: "", 
                    image: "", 
                    view_as: "", 
                    view_as_email: "", 
                    view_as_number: "", 
                    name: "", 
                    agentNumber: "", 
                    role: "", 
                    access_token: otp.x_auth, 
                    code: '', 
                    permissions: "", 
                    username: this.props.getSession().username, 
                    id: 1,
                    user_role: "",
                    refresh_token: otp.refresh_token
                };
                writeData('session', session);

                headerParams = {...headerParams, x_auth: otp.x_auth, webUser: decrypt(this.props.getSession().username)};

                const user = await this.getUser(headerParams, that);
                const username = user.email ? encrypt(user.email) : this.props.getSession().username;
                
                headerParams = {...headerParams, x_auth: otp.x_auth, webUser: decrypt(username)};

                const userRole = await this.getUserRole(headerParams, that);
                if(userRole) {
                    headerParams = {...headerParams, userRole: userRole.userRole};

                    let session = { 
                        resetPassword: false, 
                        channel: "", 
                        alias: "", 
                        image: "", 
                        view_as: "", 
                        view_as_email: "", 
                        view_as_number: "", 
                        name: "", 
                        agentNumber: "", 
                        role: "", 
                        access_token: otp.x_auth, 
                        code: '', 
                        permissions: "", 
                        username: username, 
                        id: 1,
                        user_role: userRole.userRole,
                        refresh_token: otp.refresh_token
                    };
                    writeData('session', session);

                    let role = 'HO';
                    let channel = 'HO';

                    const webUser = await this.getWebUser(headerParams, that);
                    if(webUser) {
                        if(webUser.accountManagerId) {
                            headerParams = {...headerParams, accountManagerId: webUser.accountManagerId};
                            const accountManager = await this.getAccountManager(headerParams, that);
                            if(accountManager) {
                                role = accountManager.role;
                                channel = accountManager.channel;
                            }
                        }
                        

                        const permissions = await this.fetchUserPermissions(headerParams);
                        if(permissions) {

                            let session = { 
                                resetPassword: false, 
                                channel: channel, 
                                alias: webUser.alias, 
                                image: webUser.image, 
                                view_as: "", 
                                view_as_email: "", 
                                view_as_number: "", 
                                name: encrypt(user.name), 
                                agentNumber: encrypt(webUser.accountManagerId), 
                                role: role, 
                                access_token: otp.x_auth, 
                                code: '', 
                                permissions: permissions, 
                                username: username, 
                                id: 1,
                                user_role: userRole.userRole,
                                refresh_token: otp.refresh_token
                            };
                            writeData('session', session);
                            that.props.setUserSession(session);
                            that.props.setRefreshTokenTimer();

                            if (session.resetPassword === true || session.resetPassword === "true") {
                                routeChange(that.props.history, "/change_password");
                            } else {
                                let permissionArray = permissions.map(function (e) { return e });
                                let foundOne = false;
                                if (permissionArray.indexOf('VIEW_IS') !== -1 && foundOne === false) { // MIMIC HSBC HOS
                                    that.props.setViewAs(2013745, '', 'raechelgrace.yadao@allianzpnblife.ph', that.props.history, "/dashboard/dashboard");
                                    foundOne = true;
                                }
                                
                                if (permissionArray.indexOf('VIEW_HSBC') !== -1 && foundOne === false) { // MIMIC HSBC RBM
                                    that.props.setViewAs(2011547, '', 'margrette.ang@allianzpnblife.ph', that.props.history, "/dashboard/dashboard");
                                    foundOne = true;
                                }
                                
                                if (permissionArray.indexOf('VIEW_ASSIST') !== -1 && foundOne === false) { // ASSIST
                                    routeChange(that.props.history, "/dashboard/dashboard");
                                    foundOne = true;
                                }

                                if (permissionArray.indexOf('VIEW_CS') !== -1 && foundOne === false) { // CS
                                    routeChange(that.props.history, "/dashboard/list-of-users");
                                    foundOne = true;
                                }

                                if (permissionArray.indexOf('VIEW_MIS') !== -1 && foundOne === false) { // MIS
                                    routeChange(that.props.history, "/dashboard/mis");
                                    foundOne = true;
                                }

                                if (((permissionArray.indexOf("VIEW_PRODUCTION_REPORT_MASTER") !== -1) || (permissionArray.indexOf("VIEW_PRODUCTION_REPORT") !== -1)) && foundOne === false) { // REPORTS
                                    routeChange(that.props.history, "/dashboard/reports");
                                    foundOne = true;
                                }

                                if (foundOne === false) { //
                                    routeChange(that.props.history, "/dashboard/list-of-users");
                                }
                            }
                        }
                    }
                }
            }      
        } catch(error) {
            that.setState({
                isLoading: false,
            });
            that.errorMessage(myConfig.GENERIC_ERROR_MESSAGE);
        }
    }

    authenticateOTP = (headerParams, payloadParams, that) => {
        return new Promise(function (resolve, reject) {
            apiModule("authentication_validate_otp", headerParams, payloadParams, '', true)
                .then(function (result) {
                    if (result.status === 200) {
                        resolve({
                            x_auth: result.data.access_token,
                            refresh_token: result.data.refresh_token
                        });
                    } else {
                        that.errorMessage("Invalid OTP");
                        resolve(false);
                    }
                })
                .catch(function (err) {
                    console.log('err', err)
                    const response = JSON.stringify(err);
                    const { response: { data: { error } } = { data: {error: ''}} } = JSON.parse(response);

                    const errorCodes = {
                        701: "Invalid OTP.",
                        702: "Expired OTP.",
                        703: "OTP locked.",
                        710: "OTP resend locked."
                    }
                    if(errorCodes.hasOwnProperty(error)) {
                        that.setState({
                          isLoading: false,
                          error: errorCodes[error]
                        });
                      } else {
                        that.setState({
                          isLoading: false,
                          error: myConfig.GENERIC_ERROR_MESSAGE
                        });
                      }
                    resolve(false);
                })
        });
    }

    getUserRole =  (headerParams, that) => {
        return new Promise(function (resolve, reject) {
            //TODO role
            apiModule("get_user_role", headerParams, null)
                .then(function (result) {
                    console.log('result get user role', result);

                    const roleString = r => `"${r}"`;
                    const roles = result.data.map(roleString);
                    const userRole = `[${roles.join(',')}]`;

                    resolve({
                        userRole
                    });
                })
                .catch(function (err) {
                    console.log(err);
                    that.setState({
                        isLoading: false,
                    });
                    that.errorMessage(myConfig.GENERIC_ERROR_MESSAGE);
                    resolve(false);
                });
        });
    }

    getWebUser = (headerParams, that) => {
        return new Promise(function (resolve, reject) {
            //TODO /webuser
            apiModule("get_webuser", headerParams, null, createQueryStrings({ platform: 'react' }))
                .then(function (result) {
                    console.log('result webuser', result);
                    const accountManagerId = result.userId;

                    let image = '';

                    if (image != null) {
                        image = appendFileType(removeNullProfilePic(result.image));
                    }

                    resolve({
                        accountManagerId,
                        alias: result.alias,
                        image: image
                    })
                })
                .catch(function (err) {
                    console.log(err);
                    that.setState({
                        isLoading: false,
                    });
                    that.errorMessage(myConfig.GENERIC_ERROR_MESSAGE);
                    resolve(false);
                });  
        });
    }

    getAccountManager = (headerParams, that) => {

        return new Promise(function (resolve, reject) {

        //TODO /accountManager
        apiModule("get_accountmanager", headerParams, null)
            .then(function (result) {
                console.log('result account manager', result);
                resolve({
                    role: result.roleNameOfAccountManager || 'HO',
                    channel: result.accountManagerCategory || 'HO'
                })                                                              
            })
            .catch(function (err) {
                resolve(false);
            });
        });
    }

    getUser = (headerParams, that) => {
        return new Promise(function (resolve, reject) {
            
            //TODO /webuser
            let user = headerParams.webUser.replace('@allianzpnblife.ph', '');
            const headers = {...headerParams, webUser: user};
            console.log('headers 2', headers);
            apiModule("get_user", headers, null)
                .then(function (result) {
                    console.log('result user', result);
                    resolve({
                        name: `${result.data.firstName} ${result.data.lastName}`,
                        email: result.data.email
                    });
                })
                .catch(function (err) {
                    console.log(err);
                    that.setState({
                        isLoading: false,
                    });
                    that.errorMessage(myConfig.GENERIC_ERROR_MESSAGE);
                    resolve({
                        name: '',
                        email: ''
                    });
                });  
        });
    }


    errorMessage = (message) => {
        if (message) {
            this.setState({ error: message })
        }
    }



    render() {
        return (
            <div className="auth-section">
                <div className="auth__picture">
                    <img className="auth__img" src={require('../assets/img/bg-login.jpg')} alt="Authentication background" />
                </div>
                <div className="custom-skewed-block background--gradient-blue">
                    <div className="auth-block">
                        {/* VERIFICATION CONTAINER */}
                        <Otp
                        isLoading={this.state.isLoading}
                        error={this.state.error}
                        handleSubmit={this.doOTP}
                        handleResend={this.resendOTP}
                        />
                        {/* END VERIFICATION CONTAINER */}
                    </div>
                    <div className="auth-logo-section">
                        <div className="auth-logo-container">
                            <img className="auth-logo" src={require("../assets/img/logo.png")} alt="Allianz Logo" />
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default withRouter(Verify);
