export const styles = {
  templateSkin: {
    backgroundColor : '#F0F0F0',
    color: '#000000',
  },
  templateTiles: {
    // backgroundColor : '#F8F8F8',
    color: '#255F8E'
  },
 };
