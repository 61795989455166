import React, { Component } from 'react'
import TextEllipsis from 'react-text-ellipsis';

class BulletinItem extends Component {

    render() {
        const { bulletinList } = this.props;
        return bulletinList.map(function (bulletinObj, bulletinIndex) {
            return (
                <div key={`bulletin-item-${bulletinIndex}`} className={`rni-bulletin-item rni-bulletin-item-bg-${bulletinObj.type}`}>
                    <img src={bulletinObj.imgSrc} />
                    <div className="rni-bulletin-item-description">
                        <TextEllipsis
                            lines={3}
                            tag={'p'}
                            ellipsisChars={'...'}
                            tagClass={'className'}
                            debounceTimeoutOnResize={200}
                            useJsOnly={true}>
                            {bulletinObj.title}
                        </TextEllipsis>
                        <label><span className="rni-bulletin-type">{bulletinObj.type}</span></label>
                    </div>
                </div>
            )
        })
    }
}


export default BulletinItem