import React, { Component } from 'react';
// import '../App.css';
import { writeData, passwordFormat, clearAllData, decrypt } from '../commons/utility.js';
import { isManager, isInternalUser, checkLogin, checkPermissions, checkChannels, getSessionInfo } from '../commons/security.js';

import { apiModule } from '../commons/apiCall.js';
import { menuConfig } from '../commons/menu.js';
import { user } from '../apiFactory/user.js';
import Auxi from './auxi.js';
import { styles } from '../styles/styles.js';
import { BrowserRouter as Router, Route, Link, withRouter, Redirect } from 'react-router-dom';
import { myConfig } from '../commons/config.js';
import { renameByChannel, urlBase64ToUint8Array, jsonToURI } from '../commons/utility.js';
import Topbar from './topbar';
import routeChange from '../commons/routeChange';
import withWindowDimensions from '../commons/withWindowDimensions';
import { haveValue } from '../commons/misConfig.js';
import WhatsNew from './whatsNew';
import DigitalIdEdm from './digitalIdEdm.js';

class Sidebar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            sidebarToggled: null,
            permissionsList: [],
            channel: '',
            showPrompt: false,
            isInternalUser: null,
            hasViewAs: null,
            showDigitalIdEdmModal: false,
            showWhatsNewModal: false,
        }
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.toggleDigitalIdEdmModal = this.toggleDigitalIdEdmModal.bind(this);
        this.toggleWhatsNewModal = this.toggleWhatsNewModal.bind(this);
    }

    async componentDidMount() {
        var permissionsList = await getSessionInfo();
        this.checkAppVersion();

        if (permissionsList !== null && permissionsList.length > 0) {
            this.setState({
                permissionsList: permissionsList[0].permissions,
                channel: permissionsList[0].channel
            });
        }
        this.hasViewAs();
        this.isInternalUser();
        if (this.props.isSm) {
            if (this.state.isInternalUser === true && this.state.hasViewAs === false && document.getElementById("js-update-profile-button")) {
                document.getElementById("js-update-profile-button").disabled = true;
            }
        }
    }

    onClickAction = () => {
        this.props.setViewAs('', '', '', this.props.history, "/dashboard/list-of-users");
        this.setState({ showPrompt: false });
        //    routeChange(this.props.history, "/dashboard/list-of-users");
        setTimeout(function () { window.location = "/dashboard/list-of-users"; }, 500);
    }
    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            // alert('You clicked outside of me!');
            this.setState({ showPrompt: false });
        }
    }
    logoutF = async() => {
        if (window.confirm("Are you sure you want to logout?")) {

            let payloadParams = {
                access_token: this.props.getSession().access_token,
                refresh_token: this.props.getSession().refresh_token
            };

            await this.logout(payloadParams);

            let session = { alias: '', image: '', view_as: '', view_as_email: '', view_as_number: '', name: '', agentNumber: '', code: '', permissions: [], username: '', channel: '', id: 1, user_role: '', refresh_token: '' };
            writeData('session', session);
            //this.props.setUserSession(session);
            // routeChange(this.props.history, "/");
            setTimeout(function () { window.location = "/"; }, 500);
        }
    }

    logout = (payloadParams) => {
        return new Promise(function (resolve, reject) {
            apiModule("authentication_logout", {}, payloadParams, '', true)
                .then(function (result) {
                console.log('result', result);
                resolve('Ok')
                })
                .catch(function (err) {
                console.log('err', err)
                resolve('Ok')
                });
        });
    }


    sortByPageID(content) {
        content.sort((a, b) => {
            return a.pageId < b.pageId ? -1 : a.pageId > b.pageId ? 1 : 0;
        });
    }

    mobileSidebarCategory = (content, permissionsList) => {
        this.sortByPageID(content);
        let channel = this.state.channel;
        const activeMenuItem = "active-menu-item";
        if (this.state.isInternalUser) {

            const isViewingAs = this.state.hasViewAs;
            return content.map((item, i) => {
                let index = i;
                let url = item.pageLabel.toLowerCase().replace(/\s/g, "-");
                let pageLabel = renameByChannel(item.pageLabel, channel);
                if (checkPermissions(item.permission, permissionsList) && checkChannels(item.channels, this.state.channel)) {
                    let linkState = isViewingAs || item.pageModule == "userList" ? true : false;
                    let userMenuState = isViewingAs && item.pageModule == "userList" ? true : false;
                    if (item.pageModule == "contact") {
                        linkState = false;
                        userMenuState = false;
                    } else if (item.pageModule == 'dashboard' && checkPermissions(["VIEW_MIS"], permissionsList) && !linkState && permissionsList.length === 1) {
                        linkState = false;
                        url = 'mis'
                    }

                    // console.log(item.overrideLink)

                    return (
                        <Link
                            key={`${item.pageId}-mobile`}
                            style={{
                                color: linkState ? "#255F8E" : "#8EABC3",
                                width: "13rem"
                            }}
                            className={`text-decoration-none ${item.css}`}
                            to={item.overrideLink ? `/${url}` : `/dashboard/${url}`}
                            onClick={userMenuState ? (e) => { e.preventDefault(); this.setState({ showPrompt: true }); } : () => { }}
                        >
                            <div
                                className="sidebar-menu-item px-2 py-1"
                                onClick={linkState ? this.pageClickToggleHandler : () => { }}
                            >
                                <div className={`d-flex align-items-center px-2 ${item.icon !== '' ? 'sidebar-menu-icons' : ""} ${item.isActive == "active" ? activeMenuItem : ''}`} style={{ height: "3rem", borderRadius: "5px" }}>
                                    <span
                                        style={{ fontSize: "35px" }}
                                        className={"sidebar-menu-icon " + (item.isActive == 'active' ? (item.mobileActiveIcon !== "" ? item.mobileActiveIcon : (item.activeIcon !== '' ? item.activeIcon : item.icon)) : item.icon)}></span>
                                    <label
                                        key={i}
                                        style={{ fontSize: "1rem" }}
                                        className={"sidebar-label mb-0 ml-1 " + item.isActive}>
                                        {pageLabel}
                                    </label>
                                </div>
                            </div>
                        </Link>
                    );


                } else {
                    return (<div key={`${item.pageId}-mobile`} ></div >);
                }

            });
        } else {
            return content.map((item, i) => {
                let index = i;
                let url = item.pageLabel.toLowerCase().replace(/\s/g, "-");
                let pageLabel = renameByChannel(item.pageLabel, channel);
                if (checkPermissions(item.permission, permissionsList) && checkChannels(item.channels, this.state.channel)) {


                    // console.log(item.overrideLink)
                    return (
                        <Link
                            key={`${item.pageId}-mobile`}
                            onClick={this.pageClickToggleHandler}
                            style={{
                                color: "#255F8E",
                                width: "13rem"
                            }}
                            className={`text-decoration-none ${item.css}`}
                            to={item.overrideLink ? `/${url}` : `/dashboard/${url}`}
                        >
                            <div className="sidebar-menu-item px-2 py-1">
                                <div className={`d-flex align-items-center px-2 ${item.icon !== '' ? 'sidebar-menu-icons' : ""} ${item.isActive == "active" ? activeMenuItem : ''}`} style={{ height: "3rem", borderRadius: "5px" }}>
                                    <span
                                        style={{ fontSize: "35px" }}
                                        className={"sidebar-menu-icon " + (item.isActive == 'active' ? (item.mobileActiveIcon !== "" ? item.mobileActiveIcon : (item.activeIcon !== '' ? item.activeIcon : item.icon)) : item.icon)}></span>
                                    <label
                                        key={i}
                                        style={{ fontSize: "1rem" }}
                                        className={"sidebar-label mb-0 ml-1 " + item.isActive}>
                                        {pageLabel}
                                    </label>
                                </div>
                            </div>
                        </Link>
                    );
                } else {
                    return (<div key={`${item.pageId}-mobile`} ></div >);
                }
            });
        }
    }
    sidebarCategory = (content, permissionsList) => {
        this.sortByPageID(content);
        let channel = this.state.channel;
        if (this.state.isInternalUser) {

            const isViewingAs = this.state.hasViewAs;
            return content.map((item, i) => {

                // if (item.isActive) {
                //     console.log(item);
                // }

                let index = i;
                let url = item.pageLabel.toLowerCase().replace(/\s/g, "-");
                let pageLabel = renameByChannel(item.pageLabel, channel, this.props.getSession().view_as_channel);
                if (checkPermissions(item.permission, permissionsList) && checkChannels(item.channels, this.state.channel)) {
                    let linkState = isViewingAs || item.pageModule == "userList" ? true : false;
                    let userMenuState = isViewingAs && item.pageModule == "userList" ? true : false;
                    // console.log(linkState);
                    if (item.pageModule == "contact") {
                        linkState = false;
                        userMenuState = false;
                    }
                    else if (item.pageModule == 'dashboard' && checkPermissions(["VIEW_MIS"], permissionsList) && !linkState && permissionsList.length === 1) {
                        linkState = true;
                        url = 'mis'
                    }

                    return (
                        <Link
                            key={item.pageId}
                            // style={{ color: linkState ? "#255F8E" : "#8EABC3" }}
                            style={{ color: '#255F8E' }}
                            className={`text-decoration-none ${item.css}`}
                            to={item.overrideLink ? `/${url}` : `/dashboard/${url}`}
                            onClick={userMenuState ? (e) => { e.preventDefault(); this.setState({ showPrompt: true }); } : () => { }}
                        >
                            <div className="sidebar-menu-item px-2">
                                <div className={"text-center " + (item.icon !== '' ? 'sidebar-menu-icons' : null)}>
                                    <span className={"sidebar-menu-icon " + (item.isActive == 'active' ? (item.activeIcon !== '' ? item.activeIcon : item.icon) : item.icon)}></span>
                                    <div className={item.isActive == 'active' ? 'sidebar-menu-indicator' : null}></div>
                                </div>
                                <label
                                    key={i}
                                    className={"text-center sidebar-label label-align " + item.isActive}>
                                    {pageLabel}
                                </label>
                            </div>
                        </Link>
                    );
                } else {
                    return (<div key={item.pageId}></div>);
                }
            });
        } else {
            return content.map((item, i) => {
                let index = i;
                let url = item.pageLabel.toLowerCase().replace(/\s/g, "-");
                let pageLabel = renameByChannel(item.pageLabel, channel);
                if (checkPermissions(item.permission, permissionsList) && checkChannels(item.channels, this.state.channel)) {
                    return (
                        <Link
                            key={item.pageId}
                            style={{ color: "#255F8E" }}
                            className={`text-decoration-none ${item.css}`}
                            to={item.overrideLink ? `/${url}` : `/dashboard/${url}`}
                        >
                            <div className="sidebar-menu-item px-2">
                                <div className={"text-center " + (item.icon !== '' ? 'sidebar-menu-icons' : null)}>
                                    <span className={"sidebar-menu-icon " + (item.isActive == 'active' ? (item.activeIcon !== '' ? item.activeIcon : item.icon) : item.icon)}></span>
                                    <div className={item.isActive == 'active' ? 'sidebar-menu-indicator' : null}></div>
                                </div>
                                <label
                                    key={i}
                                    className={"text-center sidebar-label label-align " + item.isActive}>
                                    {pageLabel}
                                </label>
                            </div>
                        </Link>
                    );
                } else {
                    return (<div key={item.pageId}></div>);
                }
            });
        }
    }


    userPrompt = (css) => {
        document.addEventListener('mousedown', this.handleClickOutside);
        return (
            <div className={`user-prompt modal fade ${css}`} tabIndex="-1" role="dialog">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div
                        className="modal-content user-prompt__content"
                        ref={this.setWrapperRef}
                    >
                        <div className="modal-body user-prompt__body py-5">
                            <h5 className="user-prompt__body-header font-neo-bold">Do you want to exit as {decrypt(this.props.getSession().view_as)}?</h5>
                            <div className="user-prompt__btns mt-6">
                                <button onClick={() => this.setState({ showPrompt: false })} className="btn user-prompt__btn btn-headercolor font-neo-semibold mx-2">Cancel</button>
                                <button onClick={() => this.onClickAction()} className="btn user-prompt__btn btn-headercolor font-neo-semibold mx-2">OK</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    infoModal = (id) => {
        return (
            <div className="modal fade" id={id} tabIndex="-1" role="dialog">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header border-0">
                            <h5 className="modal-title font-neo-bold text-center w-100">Modal title</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eget lectus ultricies, mollis lorem quis, pharetra mauris. Curabitur vitae hendrerit risus. Morbi nec mi erat. Aliquam turpis mauris, tempus et condimentum nec, imperdiet ac metus. Nulla nec tortor non diam malesuada blandit. Praesent sagittis in elit ut tincidunt. Donec tincidunt tellus ut volutpat porttitor.</div>
                        {/* <div className="modal-footer border-0">

                        </div> */}
                    </div>
                </div>
            </div>
        );
    }

    mobileSidebar = () => {
        const userProfile = (
            <div className="mobile-user p-4">
                <div className="mobile-user__picture">
                    <img className="mobile-user__img" alt="User picture" src={this.props.getSession().image} />
                </div>
                <div className="mobile-user__text mt-2">
                    <p className="mobile-user__name mb-0 font-neo-semibold">{decrypt(this.props.getSession().name)}</p>
                    <small className="mobile-user__position">{this.props.getSession().role}</small>
                </div>
                <div className="mobile-user__update mt-2">
                    <button
                        id="js-update-profile-button"
                        className="mobile-user__update-btn btn btn-dropdowncolor font-neo-semibold"
                        onClick={() => {
                            this.pageClickToggleHandler();
                            routeChange(this.props.history, "/dashboard/user-info");
                        }}
                    >
                        Update Profile
                    </button>
                </div>
            </div >
        );
        let that = this;
        return (
            <div className="sidebar-container" style={styles.templateTiles}>
                <div className="sidebar-body d-flex flex-column align-items-center">
                    {userProfile}
                    {this.mobileSidebarCategory(menuConfig, this.state.permissionsList)}
                </div>
                <div className="sidebar-footer w-100 text-center px-2">
                    <div className="d-flex w-100 justify-content-end">
                        {/* <button type="button" className="sidebar-footer__button btn text-headercolor mb-1 d-flex" data-toggle="modal" data-target="#infoModal">
                            <span className="sidebar-footer__icon icon-info-circle-o"></span>
                        </button> */}

                        <button onClick={() => { that.logoutF(); }} type="button" className="sidebar-footer__button btn text-headercolor mb-1 d-flex align-items-start font-p9rem">
                            <span className="sidebar-footer__icon icon-yox font-1p25rem"></span><span className="ml-1">Logout</span>
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    toggleSidebar = (isClicked) => {
        if (isClicked && (this.state.sidebarToggled === null)) {
            this.setState({ sidebarToggled: true });
        } else if (isClicked && (this.state.sidebarToggled === true)) {
            this.setState({ sidebarToggled: false })
        } else if (isClicked && (this.state.sidebarToggled === false)) {
            this.setState({ sidebarToggled: true })
        }
    }

    moveToRight = () => {
        if (this.state.sidebarToggled === true) {
            return "animation-move-topbar";
        } else if (this.state.sidebarToggled === false) {
            return "animation-unmove-topbar";
        } else {
            return "";
        }
    }

    showSidebar = () => {
        if (this.state.sidebarToggled === true) {
            return "animation-sidebar-open"
        } else if (this.state.sidebarToggled === false) {
            return "animation-sidebar-close";
        } else {
            return "";
        }
    }
    isInternalUser = () => {
        if (isInternalUser(this.props.getSession().channel)) {
            // return true;
            this.setState({
                isInternalUser: true,
            })
        } else {
            // return false;
            this.setState({
                isInternalUser: false,
            })
        }
    }
    hasViewAs = () => {
        let emptyArray = ["", '', null, undefined];
        // if (this.props.getSession().view_as !== "" && this.props.getSession().view_as !== null && this.props.getSession().view_as !== undefined) {
        if (emptyArray.includes(this.props.getSession().view_as)) {
            // return false;
            this.setState({
                hasViewAs: false,
            })
        } else {
            // return true;
            this.setState({
                hasViewAs: true,
            })
        }
    }

    managerAko = () => {
        if (isManager(this.props.getSession().role)) {
            return "Manager";
        } else {
            return "Not Manger";
        }
    }
    pageClickToggleHandler = () => {
        if (this.state.sidebarToggled === true) {
            this.setState({ sidebarToggled: false });
        }
    }

    toggleWhatsNewModal = () => {
        const { showWhatsNewModal } = this.state;
        this.setState({
            showWhatsNewModal: !showWhatsNewModal
        })
    }
    toggleDigitalIdEdmModal = () => {
        const { showDigitalIdEdmModal } = this.state;
        if(this.props.getSession().channel == "AGENCY" || this.props.getSession().channel == "BANCA"){
            this.setState({
                showDigitalIdEdmModal: !showDigitalIdEdmModal
            })
        }
    }

    checkAppVersion() {
        const channel = this.props.getSession().channel;

        const localAppVersion = localStorage.getItem(`assist_app_version_${channel}`);

        if (localAppVersion !== myConfig.APP_VERSION[channel]) {
            localStorage.setItem(`assist_app_version_${channel}`, myConfig.APP_VERSION[channel]);
            this.toggleDigitalIdEdmModal();
        }
    }

    render() {
        // console.log("Internal User: " + this.state.isInternalUser);
        // console.log("isViewing: " + this.state.hasViewAs);
        const { permissionsList, showWhatsNewModal, showDigitalIdEdmModal } = this.state;
        const isMisOnly = haveValue(permissionsList) ? permissionsList.includes('VIEW_MIS') && permissionsList.length === 1 : false;
        let mainContainer = "main-container";
        let sidebarWrapper = `sidebar-opened d-none d-md-block`;
        let mobileSidebarWrapper = `sidebar-opened d-block d-md-none`;
        if (this.props.toggle == undefined) {
            mainContainer = "main-container";

        } else if (this.props.toggle) {
            mainContainer = "main-container";

        } else {
            mainContainer = "main-container";

        }
        let mobileSidebar = "";
        if (this.props.isSm) {
            mobileSidebar = (
                <div className={`${mobileSidebarWrapper} ${this.showSidebar()}`} style={{ backgroundColor: "#F8F8F8" }}>
                    {this.mobileSidebar()}
                </div>
            );
        }

        let currentSidebar = "";
        if (this.props.isSm) {
            currentSidebar = mobileSidebar;
        } else {
            if (!isMisOnly) {
                currentSidebar = (
                    <div className={`${sidebarWrapper}`}>
                        <div className="sidebar-container" style={styles.templateTiles}>
                            <div className="sidebar-body">

                                {this.sidebarCategory(menuConfig, this.state.permissionsList)}
                            </div>
                            {/* <div className="sidebar-footer w-100 text-center">
                                <button type="button" className="sidebar-footer__button btn text-headercolor mb-4" data-toggle="modal" data-target="#infoModal">
                                    <span className="sidebar-footer__icon icon-info-circle-o"></span>
                                </button>
                            </div> */}
                        </div>
                    </div>
                )
            }
            else {
                currentSidebar = '';
            }
        }
        return (
            <div className="overflow-hidden">
                <Topbar {...this.props} getSession={() => this.props.getSession()} onClick={this.pageClickToggleHandler} toggleSidebar={this.toggleSidebar} moveToRight={this.moveToRight()} isInternalUser={this.state.isInternalUser} hasViewAs={this.state.hasViewAs} isMisOnly={isMisOnly} toggleWhatsNewModal={this.toggleWhatsNewModal}/>
                <div className={mainContainer}>
                    {/* {!isMis ? currentSidebar : ''} */}
                    {currentSidebar}
                    <div className={`dashboard ${this.moveToRight()} ${isMisOnly ? `dashboard-mis-container` : ``}`}>
                        <div className="h-100" onClick={this.pageClickToggleHandler}>
                            {this.props.children}
                        </div>
                    </div>
                </div>
                {this.infoModal("infoModal")}
                {this.state.showPrompt ? this.userPrompt("d-block animation-fadein") : this.userPrompt("d-none")}
                {showDigitalIdEdmModal ? <DigitalIdEdm channel={this.props.getSession().channel} toggleDigitalIdEdmModal={this.toggleDigitalIdEdmModal} isSm={this.props.isSm}/> : ""}
            </div >

        );
    }
}

export default withRouter(withWindowDimensions(Sidebar));
