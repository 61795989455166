import React, { Component } from 'react';
import ReactTable from 'react-table';
import { Loader, ReportLoader } from '../commons/utilityViews.js';
import { withRouter } from 'react-router-dom';
import withWindowDimensions from '../commons/withWindowDimensions';
import Auxi from '../components/auxi';
import PremiumDueMobile from '../components/PremiumDue/Mobile/PremiumDueMobile';
import { myConfig } from '../commons/config.js';
import { checkDateInput, checkDateRange, prettifyDate, calculateAge, writeData, readAllData, encrypt, decrypt, numberWithCommas, removeNull, fixName} from '../commons/utility.js';
import { apiModule, createQueryStrings } from '../commons/apiCall';
import TableLoader from '../components/TableLoader/tableLoader';
import TablePagination from '../components/tablePagination';
import PremiumDueListMobile from '../components/PremiumDue/Mobile/PremiumDueListMobile';
import handleSortOrder from '../components/misc/sortOrder';
import routeChange from '../commons/routeChange';
import { isManager } from '../commons/security.js';
import DatatableSearch from '../components/datatableSearch';
import moment from 'moment';
import Insured from '../components/Applications/insured.js';
import ReportDatePicker from "../components/reportDatepicker";
import { Years } from '../components/Datepicker/customHeader';

class PremiumDueListView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            error: '',
            dues: [],
            rows: -1,
            pages: myConfig.PAGE_SIZE,
            currentPage: 0,
            totalSize: 0,
            loadTable: false,
            sortByField: null,
            sortOrder: null,
            isMgr: false,
            startDate: null,
            endDate: null,
            optionSelected: { value: "", label: "All" },
        };
        this.pageHeader = "Premium Due List"
    }

    componentDidMount() {
        this.setState({
            isLoading: true,
            isMgr: isManager(this.props.getSession().role)
        }, () => {
            this.getTableData();
        });
    }

    getTableData() {
        this.setState({
            currentPage: 0
        }, async () => {
            await this.fetchPolicyDueList(this.props.getSession().access_token, decrypt(this.props.getSession().agentNumber));
        });
    }

    showTable = (pageNumber) => {
        pageNumber = 0;
        this.setState({ 
            loadTable: true,
            currentPage: 0
        }, () => {
            this.search(pageNumber)
        });
    }

    updateStartDate = (date) => {
        this.setState({
            startDate: date,
        }, () => {
            this.showTable();
        });
    }

    updateEndDate = (date) => {    
        this.setState({
            endDate: date,
        }, () => {
            this.showTable();
            
        });
    }

    search = (pageNumber) => {
        let that = this;
        let session = that.props.getSession();
        let headerParams = {
            accountManagerId: decrypt(session.agentNumber),
            x_auth: session.access_token,
            datasetId: 'duepremiums'
        };
        let filter = [];
        let continueProcess = true;
        filter['pageNumber'] = pageNumber + 1;
        filter['pageSize'] = myConfig.PAGE_SIZE;

        if (that.state.optionSelected.value != "") {
            filter['classProductSign'] = this.state.optionSelected.value;
        }
        
        if ((this.state.startDate === null || Number.isNaN(this.state.startDate) || this.state.startDate === undefined || this.state.startDate === '--') &&
        (this.state.endDate === null || Number.isNaN(this.state.endDate) || this.state.endDate === undefined || this.state.endDate === '--')) {
            filter['dueDateFrom'] = "";
            filter['dueDateTo'] = "";
            continueProcess = true;
        } else if ((this.state.startDate === null || Number.isNaN(this.state.startDate) || this.state.startDate === undefined || this.state.startDate === '--') ||
        (this.state.endDate === null || Number.isNaN(this.state.endDate) || this.state.endDate === undefined || this.state.endDate === '--')){
            continueProcess = false;
            this.setState({
                loadTable: false,
                error: "Please provide Due Dates From and Due Date To."
            });
        } else {
            filter['dueDateFrom'] = prettifyDate(this.state.startDate);
            filter['dueDateTo'] = prettifyDate(this.state.endDate);
            continueProcess = true;
            continueProcess = (checkDateRange(filter['dueDateTo'], filter['dueDateFrom']) === "") ? true : false;
            if (!continueProcess) {
                this.setState({
                    isLoading: false,
                    error: checkDateRange(filter['dueDateTo'], filter['dueDateFrom'])
                });
                continueProcess = false;
            }
        }

        if (that.state.sortByField != null) {
            filter['sortBy'] = that.state.sortByField + ',' + that.state.sortOrder;
        }
        if(continueProcess) {
            let payloadParams = {};
            apiModule("get_dashboard_dataset", headerParams, payloadParams, createQueryStrings(filter))
                .then(function (response) {

                    let result = [];
                    let totalSize = 0;
                    if (response != null) {
                            if (Array.isArray(response)) {
                                if (response.length > 0) {
                                    if (response.length > 0) {
                                        if (response.length > 0) {
                                            result = response[0].data.paginatedDuePremiums.content;
                                            totalSize = response[0].data.paginatedDuePremiums.totalSize;
                                        } else {
                                            result = [];
                                            totalSize = 0;
                                        }
                                    } else {
                                        result = [];
                                        totalSize = 0;
                                    }
                                } else {
                                    result = [];
                                    totalSize = 0;
                                }
                            } else if (response.data.paginatedDuePremiums) {
                                result = response.data.paginatedDuePremiums.content;
                                totalSize = response.data.paginatedDuePremiums.totalSize;
                        }
                    }
                    let numOfPages = parseInt((totalSize / myConfig.PAGE_SIZE), 10);

                    if ((totalSize % myConfig.PAGE_SIZE) > 0) {
                        numOfPages++;
                    }

                    let dues = [];

                    if (result) {

                        for (let i = 0; i < result.length; i++) {

                            var location = "";
                            var accountManager = "";

                            if (result[i].accountManager != null && result[i].accountManager.length > 0) {
                                accountManager = result[i].accountManager.name;
                            }

                        dues.push(
                            {
                                // id: result[i].insured.globalId,
                                NAME: result[i].contractHolder,
                                DUE_DATE: prettifyDate(result[i].paidUntilDate),
                                FA_NAME: result[i].agent.name,
                                POL_NO: result[i].contractNumber,
                                INS1_COMP_NAME: <Insured insured = {result[i].insured} />,
                                POL_STAT: removeNull(result[i].status).toUpperCase(),
                                PMODE: result[i].paymentFrequency,
                                POL_CURR_CD: removeNull(result[i].currency),
                                TOT_MODAL_PREM: numberWithCommas(result[i].netPremium),
                                BUNDLE_ID: result[i].typeOfBill
                                // contact: result[i].insured.phoneNumber,
                                // email: result[i].insured.email
                            }
                        );
                    }

                    that.setState({
                        dues: dues
                    });

                } else {
                    that.showError("No Account Found");
                }

                that.setState({
                    dues: dues,
                    loadTable: false,
                    totalSize: totalSize,
                    pages: numOfPages,
                    showTable: true,
                    error: '',
                    isLoading: false
                });
            })
            .catch(function (err) {
                console.log(err);
                that.setState({
                    isLoading: false,
                    loadTable: false
                }, () => {
                    that.showError(myConfig.GENERIC_ERROR_MESSAGE);
                    console.log(err.message)
                });
            })
        }
        // let result = null;

    }

    fetchPolicyDueList = (access_token, agentNumber) => {
        let that = this;
        return new Promise(function (resolve, reject) {

            let session = that.props.getSession();
            let headerParams = {
                accountManagerId: decrypt(session.agentNumber),
                // accountManagerId: username,
                x_auth: session.access_token,
                datasetId: 'duepremiums'
            };
            let filter = [];
            filter['pageNumber'] = (that.state.currentPage + 1);
            filter['pageSize'] = myConfig.PAGE_SIZE;
            if (that.state.optionSelected.value != "") {
                filter['classProductSign'] = "";
            }

            let payloadParams = {};
            apiModule("get_dashboard_dataset", headerParams, payloadParams, createQueryStrings(filter))
                .then(function (response) {

                    let result = [];
                    let totalSize = 0;
                    if (Array.isArray(response)) {
                        result = response[0].data.paginatedDuePremiums.content;
                        totalSize = response[0].data.paginatedDuePremiums.totalSize;
                    } else if (response.data.paginatedDuePremiums) {
                        result = response.data.paginatedDuePremiums.content;
                        totalSize = response.data.paginatedDuePremiums.totalSize;
                    }

                    let numOfPages = parseInt((totalSize / myConfig.PAGE_SIZE), 10);

                    if ((totalSize % myConfig.PAGE_SIZE) > 0) {
                        numOfPages++;
                    }

                    let dues = [];

                    if (result) {

                        for (let i = 0; i < result.length; i++) {

                            var location = "";
                            var accountManager = "";

                            if (result[i].accountManager != null && result[i].accountManager.length > 0) {
                                accountManager = result[i].accountManager.name;
                            }

                            // dues: [{ id: 0, name: "David, James E.", dob: "1983-12-01", contact: "+63 782 124312", email: "name@example.com" }]
                            // dues: [{ id: 0, name: "David, James E.", due: "1983-12-01", poNum: "103000001", contact: "+63 782 124312", email: "name@example.com" }]
                            dues.push(
                                {
                                    // id: result[i].contractHolder.self,
                                    NAME: result[i].contractHolder,
                                    DUE_DATE: prettifyDate(result[i].paidUntilDate),
                                    FA_NAME: result[i].agent.name,
                                    POL_NO: result[i].contractNumber,
                                    INS1_COMP_NAME: <Insured insured = {result[i].insured} />,
                                    POL_STAT: removeNull(result[i].status).toUpperCase(),
                                    PMODE: result[i].paymentFrequency,
                                    POL_CURR_CD: removeNull(result[i].currency),
                                    TOT_MODAL_PREM: numberWithCommas(result[i].netPremium),
                                    BUNDLE_ID: result[i].typeOfBill
                                    // contact: result[i].insured.phoneNumber,
                                    // email: result[i].insured.email
                                }
                            );
                        }

                        that.setState({
                            dues: dues
                        });

                    } else {
                        that.showError("No Account Found");
                    }

                    that.setState({
                        dues: dues,
                        loadTable: false,
                        totalSize: totalSize,
                        pages: numOfPages,
                        showTable: true,
                        error: '',
                        isLoading: false
                    });
                })
                .catch(function (err) {
                    console.log(err);
                    that.setState({
                        isLoading: false,
                        loadTable: false,
                    }, () => {
                        that.showError(myConfig.GENERIC_ERROR_MESSAGE);
                        console.log(err);
                    });
                })
            // let result = null;



        });
    }

    //SHOW ERROR IF ERROR STATE IS NOT NULL
    showError = () => {
        let message = '';
        if (this.state.error !== '') {
            message =
                <div className="bg-error py-1 px-4">
                    <p className="text-white mb-0 font-neo-semibold">ERROR: {this.state.error}</p>
                </div>
        }
        return message;
    }

    handleSortAction = (sortByField, sortOrder) => {
        this.setState({
            sortByField: sortByField,
            sortOrder: sortOrder,
            // isLoading: true
            loadTable: true,
        }, () => {
            this.search(this.state.currentPage);
        })
    }

    headerClickedHandler = (sortByField) => {
        let sortOrder = handleSortOrder({ sortByField: sortByField, state: this.state });
        this.handleSortAction(sortByField, sortOrder)
    }

    changePage = (pageNumber) => {
        if (pageNumber.selected != null) {
            pageNumber = pageNumber.selected;
            if (this.state.currentPage != pageNumber) {
                this.beginLoading(pageNumber);
                this.search(pageNumber);
            }

        } else if (this.state.currentPage != pageNumber) {

            this.beginLoading(pageNumber);
            this.search(pageNumber);
        }
    }

    beginLoading = (pageNumber) => {
        this.setState({
            // isLoading: true,
            loadTable: true,
            currentPage: pageNumber
        })
    }

    onClickPolicyOwner = (item) => {
        this.props.setClientOrPolicy({ clientId: item });
        writeData('policy-client', { clientId: item, id: 1 });
        routeChange(this.props.history, "/dashboard/single-client");
    }

    onClickPolicyNumber = (item) => {
        this.props.setClientOrPolicy({ policyId: item });
        writeData('policy-client', { policyId: item, id: 1 });
        routeChange(this.props.history, "/dashboard/single-policy");
    }

    updateOptionSelected = (option) => {
        this.setState({ optionSelected: option });
    }

    searchByOption = () => {
        let dropDownValue = this.state.optionSelected.value;
        let startDate = this.state.startDate;
        let endDate = this.state.endDate;
        let pastDateFrom = this.state.pastDateFrom;
        let pastDateTo = this.state.pastDateTo;

        let field = null;
        if (dropDownValue === "DUE") {
            field = (
                <ReportDatePicker
                    startDateValue={startDate}
                    endDateValue={endDate}
                    updateStartDate={this.updateStartDate}
                    updateEndDate={this.updateEndDate}
                    placeholderFrom="Due Date From"
                    placeholderTo="Due Date To"
                    minDateTo={new Date()}
                    //CAN ADD MAX YEAR -> Years(minYear,maxYear)
                    yearOptions={Years(1990)}
                    hideButton={true}
                />
            );
        } else if (dropDownValue === "PAST"){
            field = (
                <ReportDatePicker
                    startDateValue={startDate}
                    endDateValue={endDate}
                    updateStartDate={this.updateStartDate}
                    updateEndDate={this.updateEndDate}
                    placeholderFrom="Due Date From"
                    placeholderTo="Due Date To"
                    maxDateFrom={new Date()}
                    //CAN ADD MAX YEAR -> Years(minYear,maxYear)
                    yearOptions={Years(1990)}
                    hideButton={true}
                />
            );
        } else {
            field = (
                <ReportDatePicker
                    startDateValue={startDate}
                    endDateValue={endDate}
                    updateStartDate={this.updateStartDate}
                    updateEndDate={this.updateEndDate}
                    placeholderFrom="Due Date From"
                    placeholderTo="Due Date To"
                    //CAN ADD MAX YEAR -> Years(minYear,maxYear)
                    yearOptions={Years(1990)}
                    hideButton={true}
                />
            );
        }
        return field
    }

    render() {
        const customNames = [
            {
                Header: 'Due Date',
                accessor: 'DUE_DATE',
            },
            {
                Header: 'Policy Number',
                accessor: 'POL_NO',
                Cell: props =>
                    <a className={( props.original ? moment(props.original.DUE_DATE) < moment().subtract(1, 'days')
                        ? "text-danger"
                        : "text-info" : null)}
                        onClick={() => this.onClickPolicyNumber(props.value)} style={{ cursor: "pointer" }}>{props.value}</a>
            },
            {
                Header: 'Policy Owner',
                accessor: 'NAME',
                Cell: (props) =>
                    <a className={(moment(props.original.DUE_DATE) < moment().subtract(1, 'days')
                        ? "text-danger"
                        : "text-info")}
                        onClick={() => this.onClickPolicyOwner(props.value.self)} style={{ cursor: "pointer" }}>{props.value.name}</a>
            },
            {
                Header: "Insured Name",
                accessor: "INS1_COMP_NAME"
            },
            {
                Header: 'Policy Status',
                accessor: 'POL_STAT',
            },
            {
                Header: 'Mode of Payment',
                accessor: 'PMODE'
            },
            {
                Header: 'Currency',
                accessor: 'POL_CURR_CD',
            },
            {
                Header: 'Modal Premium',
                accessor: 'TOT_MODAL_PREM',
            },
            {
                Header: 'Payment Option',
                accessor: 'BUNDLE_ID',
            },
        ];

        if (this.state.isMgr) {
            customNames.splice(0, 0,
                {
                    Header: 'Intermediary Name',
                    accessor: 'FA_NAME',
                })
        }

        if (this.state.isLoading) {
            return (
                <ReportLoader handleClose={this.hideModal} show={this.state.isLoading} />

            );
        } else {
            let options = [
                { value: "DUE", label: "Premium Due" },
                { value: "PAST", label: "Past Due" },
                { value: "", label: "All" }
            ]

            let dataView = (
                <ReactTable
                    className={`mt-5 rt-no-hover`}
                    loading={this.state.loadTable}
                    LoadingComponent={TableLoader}
                    resizable={false}
                    columns={customNames}
                    defaultPageSize={myConfig.PAGE_SIZE}
                    minRows={0}
                    data={this.state.dues}
                    page={this.state.currentPage}
                    totalSize={this.state.totalSize}
                    onPageChange={this.changePage}
                    pageSize={myConfig.PAGE_SIZE}
                    pages={this.state.pages}
                    manual
                    getTheadThProps={(state, rowInfo, column, instance) => ({
                        onClick: () => {
                            this.headerClickedHandler(column.id);
                        },
                        sortClassName: (
                            this.state.sortByField == column.id ?
                                (this.state.sortOrder == "ASC" ?
                                    "icon-chevron-small-up"
                                    : "icon-chevron-dropdown")
                                : ""
                        ),
                        sortColor: (
                            this.state.sortByField == column.id ?
                                "text-black"
                                : ""
                        )
                    })}
                    getTrProps={(state, rowInfo, column, instance) => ({
                        style: {
                            color: (moment(rowInfo.original.DUE_DATE) < moment().subtract(1, 'days')
                                ? '#CC0000'
                                : "")
                        }
                    })}
                />
            );
            if (this.props.isSm) {
                dataView = (
                    <PremiumDueListMobile
                        title={[
                            {
                                Header: 'Policy Owner',
                                accessor: 'NAME',
                                hasCaret: true,
                                onClick: () => {
                                    this.headerClickedHandler('NAME');
                                },
                                Cell: props => <a className="text-info" onClick={() => this.onClickPolicyOwner(props.value.self)} style={{cursor: "pointer"}}>{props.value.name}</a>
                            },
                            {
                                Header: 'Due Date',
                                accessor: 'DUE_DATE',
                                hasCaret: true,
                                onClick: () => {
                                    this.headerClickedHandler('DUE_DATE');
                                }
                            }
                        ]}

                        customNames={customNames}
                        data={this.state.dues}
                        sortByField={this.state.sortByField}
                        sortOrder={this.state.sortOrder}
                    >
                        <TablePagination
                            css="mt-2"
                            data={this.state.dues}
                            page={this.state.currentPage}
                            totalSize={this.state.totalSize}
                            onPageChange={this.changePage}
                            pageSize={myConfig.PAGE_SIZE}
                            pages={this.state.pages}
                        />
                        {this.state.loadTable ? <TableLoader loading={true} /> : ""}
                    </PremiumDueListMobile>
                );
            }
            return (
                <div className="dashboard-section">
                    {this.showError()}
                    <div className="dashboard-container container-fluid p-3">
                        <div className="dashboard-header">
                            <h6 className="font-neo-bold text-darkgray">{this.pageHeader}</h6>
                            {this.props.getViewAs}
                        </div>
                        <div className="dashboard-body mt-4">

                            <DatatableSearch
                                //DROPDOWN OPTION
                                optionSelectedValue={this.state.optionSelected}
                                updateOptionSelected={this.updateOptionSelected}
                                //END DROPDOWN OPTION

                                searchByOption={this.state.optionSelected.value !== null ? this.searchByOption() : <></>}
                                
                                updateSearchValue = {(pageNumber) => {
                                        this.setState({ 
                                            loadTable: true, 
                                            currentPage: 0,
                                            startDate: null,
                                            endDate: null
                                        }, () => {
                                            this.search(pageNumber)
                                        });
                                }}

                                search={(pageNumber) => {
                                        this.setState({ 
                                            loadTable: true, 
                                            currentPage: 0,
                                        }, () => {
                                            this.search(pageNumber)
                                        });
                                }}

                                styles={{
                                    option: (provided) => ({
                                        ...provided,
                                        fontFamily: "neo_semibold",
                                        fontSize: ".6rem",
                                        color: "#9A9B9C",
                                        backgroundColor: "white"
                                    }),
                                    menu: () => ({
                                        position: "absolute",
                                        border: 0,
                                        zIndex: "1090",
                                        backgroundColor: "white",
                                        borderRadius: "5px",
                                        boxShadow: "2px 2px 2px #DDDEE0"
                                    }),
                                }}
                                options={options}
                            />
                            <p className="m-3 font-italic" style={{fontSize: ".9em",color: "#255f8e"}}>Note: Please be advised that those in red font are past due policies <br />
                            Data reflected here are the policies with current due. To check if paid, please go to the Billing and Payment page.</p>
                            {this.state.dues.length != 0 ? dataView : <div className="center-align dashboard-row-data font-neo-semibold">No records found </div>}
                        </div>
                    </div>
                </div>
            );
        }
    }
}

export default withRouter(withWindowDimensions(PremiumDueListView));
