import React, { Component } from 'react';
import '../../css/mis.scss';
import { apiModule, createQueryStrings } from '../../commons/apiCall';
import BarChart from './barChart';
import { haveValue, labelXAxis, showNoData, charts } from '../../commons/misConfig';
import { changeCaseFirstLetter, getMinVal, getMaxVal, formatValues, formatValuesNoDecimals } from './formatter';
import CommentIcon from './commentIcon';

class PerChannelRegion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isChannel: false,
            channelData: [],
            data: {
                labels: [],
                datasets: []
            },
            isLoading: true,
            totalComments: 0
        }

        this.clickChart = this.clickChart.bind(this);
        this.returnToAll = this.returnToAll.bind(this);
        this.getValuePerChannel = this.getValuePerChannel.bind(this);
    }

    componentWillMount() {
        this.getValuePerChannel(this.props);
        this.props.getCommentCount(this, this.props.channel.value === 'ALL' ? 'channel' : 'region', this.props.access_token);
        this.setState({
            ...this.props
        })
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.willUpdate) {
            this.setState({
                ...nextProps,
                isLoading: true
            })

            this.getValuePerChannel(nextProps);
        } else {
            this.setState({
                willUpdate: nextProps.willUpdate
            })
        }

        if(nextProps.willUpdateCommentCount){
            nextProps.getCommentCount(this, nextProps.channel.value === 'ALL' ? 'channel' : 'region', nextProps.access_token);
        }
    }

    getValuePerChannel(props) {
        let that = this;
        let headerParams = {
            x_auth: props.access_token
        };
        let filter = []
        filter['metric'] = haveValue(props.metric) ? props.metric.value : 'ALL';
        filter['period'] = haveValue(props.period) ? props.period.value : 'ALL';
        filter['startDate'] = haveValue(props.startDate) ? props.startDate : '';
        filter['endDate'] = haveValue(props.endDate) ? props.endDate : '';
        filter['productOfferingType'] = haveValue(props.product) ? props.product.value : '';
        filter['channel'] = haveValue(props.channel) ? props.channel.value : '';
        filter['contractStatus'] = haveValue(props.status.value) ? props.status.value : '';
        filter['targetType'] = haveValue(props.target.value) ? props.target.value : '';
        apiModule("get_issued_per_channel", headerParams, null, createQueryStrings(filter))
            .then(function (result) {
                let dataObject = {
                    labels: [],
                    datasets: [{
                        data: [],
                        targetData: [],
                        dataPct: [],
                        dataCount: [],
                        targetDataCount: [],
                        dataCountPct: [],
                        borderWidth: 2,
                        borderColor: "#78DCFA",
                        backgroundColor: "#78DCFA"
                    }]
                };

                if (result.goalDetails)
                    result.goalDetails.map(function (obj, index) {
                        dataObject.labels[index] = obj.channel ? labelXAxis(obj.channel) : labelXAxis(obj.region);
                        dataObject.datasets[0].data[index] = obj.achievedPremiumRate;
                        dataObject.datasets[0].targetData[index] = obj.plannedPremiumRate;
                        dataObject.datasets[0].dataPct[index] = obj.achievedPercentage;
                        dataObject.datasets[0].dataCount[index] = obj.numberOfInsuranceCompanyContracts;
                        dataObject.datasets[0].targetDataCount[index] = obj.plannedNumberOfInsuranceCompanyContracts;
                        dataObject.datasets[0].dataCountPct[index] = obj.plannedPercentage;
                        dataObject.metric = haveValue(props.metric) ? props.metric.value : ''
                        dataObject.minVal = getMinVal(result.goalDetails);
                        dataObject.maxVal = getMaxVal(result.goalDetails);
                    })

                that.setState({
                    data: dataObject,
                    isLoading: false
                });

            })
            .catch(function (err) {
                console.log(err);
            });
    }

    clickChart(value) {
        const { channel } = this.state;
        const { channelOptions, onChangeFilter, currTarget, optionList } = this.props;
        if (channel.value == 'ALL') {
            let channels = currTarget.value.includes('PD') ? optionList.channel.PD : optionList.channel.default;
            Object.keys(channels).map(function (key) {
                if (value.toUpperCase() == channels[key].label.toUpperCase()) {
                    onChangeFilter('channel', channels[key], true)
                }
            })
            // Object.keys(channelOptions).map(function (key) {
            //     if (value.toUpperCase() == channelOptions[key].label.toUpperCase()) {
            //         onChangeFilter('channel', channelOptions[key], true)
            //     }
            // })
        }
    }

    returnToAll(value) {
        const { channelOptions, onChangeFilter, currTarget, optionList } = this.props;
        let channels = currTarget.value.includes('PD') ? optionList.channel.PD : optionList.channel.default;
            Object.keys(channels).map(function (key) {
                if (value.toUpperCase() == channels[key].label.toUpperCase()) {
                    onChangeFilter('channel', channels[key], true)
                }
            })
            // Object.keys(channelOptions).map(function (key) {
            //     if (value.toUpperCase() == channelOptions[key].label.toUpperCase()) {
            //         onChangeFilter('channel', channelOptions[key], true)
            //     }
            // })

        this.setState({
            isLoading: true,
            isUpdating: true
        })
    }

    render() {
        const { data, channel, status, willUpdate, isLoading, totalComments, currTarget } = this.state;
        const { coinLoader, onClickCommentIcon, isPNBChannelOnly } = this.props;
        let isSubmitted = status.value.toUpperCase().includes('SUBMITTED');
        let header = '';
        header += (haveValue(status)) ? (isSubmitted ? 'Submitted' : 'Issued') + ' per ' : '';
        header += (haveValue(channel) ? (channel.value === 'ALL' ? ' Channel' : ' Region') : '');
        header += haveValue(channel) ? (channel.value === 'ALL' ? '' : ' ( ' + channel.label + ' )') : '';
        return (
            <div className="container mis-container col-sm-12 col-md-12 col-lg-6 col-xl-6">
                {/* <div className="mis-comment-icon-container" onClick={() => onClickCommentIcon(channel.value === 'ALL' ? charts.channel : charts.region)}>
                    <div className="mis-comment-icon">
                        <img src = "/mis/comments.svg" className="mis-comment-icon-img"/>
                        <span className="mis-comment-count">
                            {formatValuesNoDecimals(totalComments)}
                        </span>
                    </div>
                </div> */}
                <CommentIcon
                    chart={channel.value === 'ALL' ? charts.channel : charts.region}
                    onClickCommentIcon={onClickCommentIcon} 
                    totalComments={totalComments} />
                <div className="card mis-card-3 bg-white p-3 shadow-sm rounded">
                    <div className="card-body mis-card-body">
                        <div style={{
                            display: 'flex',
                            width: '100%',
                            justifyContent: 'space-between'
                        }} className="card-title mis-card-title">
                            <label className="d-flex dashboard-data-header__text align-items-end font-neo-semibold text-headercolor mis-per-channel-region-header-label" style={{ width: '70%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', marginBottom: '0px', position: 'relative' }}>
                                {header}
                            </label>
                            { !isPNBChannelOnly && channel.value !== 'ALL' && !isLoading ? <span style={{ position: 'relative', top: 'unset', right: 'unset' }} className='dashboard-return-text' onClick={() => { this.returnToAll('All') }}>
                                <span className="mis-accumulative-desc">Clear Filter</span>
                            </span> : ''}
                        </div>
                        {isLoading ? this.props.coinLoader :
                            <BarChart {...this.props} chartId="PerChannelChart" data={data} willUpdate={willUpdate}
                                clickChart={this.clickChart}
                            />
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default PerChannelRegion;