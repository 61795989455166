import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import FtaModal from '../ftaModal';
import * as UploadProofFuncs from './UploadProofFuncs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons';
import { myConfig } from '../../../commons/config';

class UploadProofForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            base64: [],
            rawFiles: [],
            isConverting: false,
            errorFileMessage: '',
        }
    }

    maxFileSizeMB = myConfig.UPLOAD_MAX_FILESIZE_MB;
    maxTotalSizeMB = myConfig.UPLOAD_MAX_TOTAL_FILESIZE_MB;

    componentDidMount = () => {
        this.setState({
            rawFiles: this.props.proofFiles ? [...this.props.proofFiles] : [],
            base64: this.props.proofBase64Files ? [...this.props.proofBase64Files] : []
        });
    }

    handleBase64 = (file) => {
        const fileObject = {
            base64: (file.base64Array && Array.isArray(file.base64Array)) ? [...file.base64Array] : [],
            rawFiles: (file.allowedFiles && Array.isArray(file.allowedFiles)) ? [...file.allowedFiles] : []
        }
        this.setState({ ...fileObject }, () => {
            console.log('Updated file list:', { rawFiles: this.state.rawFiles, base64: this.state.base64 });
        });
        this.props.handleFileData({ ...fileObject });
    }

    handleFileUploadChange = (files, rejectedFiles) => {
        console.log('rejected files', rejectedFiles);
        if (rejectedFiles.length > 0) {
            this.handleRejectedFiles(rejectedFiles);
            return;
        }
        if (files.length > 0 && rejectedFiles.length === 0) {
            this.handleFileMultipleUpload(files);
        }
    }

    rejectedFilesList = (files) => {
        let string = ""
        files.forEach(file => {
            string += `<li>${this.fileNameDisplay(file.file.name)}</li>`;
        })
        return string;
    }

    handleRejectedFiles = (files) => {
        let errorFileMessage = "";
        const fileSizeErrorList = files.filter((file) => [...file.errors.map((o)=> o.code)].includes('file-too-large'));
        const fileInvalidType = files.filter((file) => [...file.errors.map((o)=> o.code)].includes('file-invalid-type'));
        if (fileSizeErrorList.length > 0) {
            errorFileMessage+= `<p>One or many file(s) size exceeded. Each file size must be less than ${this.maxFileSizeMB}MB.<br/>Rejected Files:<ul>${this.rejectedFilesList(fileSizeErrorList)}</ul></p>`;
        }
        if (fileInvalidType.length > 0) {
            errorFileMessage+= `<p>Invalid file type. Only accepts .jpg, .jpeg, .png and .pdf files.<br/>Rejected Files:<ul>${this.rejectedFilesList(fileInvalidType)}</ul></p>`;
        }
        this.setState({ errorFileMessage });
    }

    removeAll = (index) => {
        this.handleBase64({ base64Array: [], allowedFiles: [] });
    }

    removeSingle = (index) => {
        let [newFiles, newBase64Array] = [[...this.state.rawFiles], [...this.state.base64]];
        newFiles.splice(index, 1);
        newBase64Array.splice(index, 1);
        this.handleBase64({ allowedFiles: newFiles, base64Array: newBase64Array });
    }

    base64DataBuilder = (base64, fileFormat) => {
        return {
            documentClass: this.props.documentClass,
            fileFormat,
            data: base64
        };
    }

    /**handle convert (multiple) file into Base 64*/
    handleFileMultipleUpload = async (fileList) => {
        this.setState({ isConverting: true });
        let base64Array = [];
        let fileTotalSize = this.validateFileTotalSize([...this.state.rawFiles, ...fileList]);
        if (fileTotalSize.isRange) {
            this.setState({
                isConverting: false,
                errorFileMessage: `Total file size exceeded, Total file size must be less than ${this.maxTotalSizeMB}MB.<br>Total size: ${fileTotalSize.totalSize}MB`
            });
            return;
        }
        base64Array = await Promise.all(fileList.map(async (file) => {
            const [base64, fileType] = await this.fileConvert(file);
            return this.base64DataBuilder(base64, fileType);
        }));
        this.handleBase64({ base64Array: [...this.state.base64, ...base64Array], allowedFiles: [...this.state.rawFiles, ...fileList] });
        this.setState({ isConverting: false });
    }

    validateFileTotalSize = (fileList) => {
        let fileSizeTotal = 0;
        fileList.forEach((file) => {
            fileSizeTotal += file.size;
        });
        console.log(`Total size: ${(fileSizeTotal / 1024 / 1024).toFixed(2)}MB`);
        return {
            isRange: (fileSizeTotal / 1024 / 1024) > this.maxTotalSizeMB,
            totalSize: (fileSizeTotal / 1024 / 1024).toFixed(2),
        };
    }

    fileConvert = async (file) => {
        console.log(file ? file.type : 'no file');
        if (file) {
            const [fileMimeType, fileMimeSubtype] = [...file.type.split("/")];
            if (fileMimeType === 'image') {
                const pdfRes = await UploadProofFuncs.generatePdfFromImages(file);
                return [pdfRes.split(",")[1], 'pdf'];
            }
            if (['pdf'].includes(fileMimeSubtype)) {
                const pdfRes = await UploadProofFuncs.directFileToBase64(file);
                return [pdfRes.split(",")[1], file.name.split('.').pop()];
            }
            console.warn('File not supported.');
            return [null, null];
        }
        return [null, null];
    }

    fileNameDisplay = (fullFileName = "", strLen = 32) => {
        if (fullFileName.length <= strLen) {
            return fullFileName;
        }
        return fullFileName.substring(0, strLen - 12) + "..." + fullFileName.slice(-8);
    }

    render = () => {
        return (
            <>
                <Dropzone
                    onDrop={(acceptedFiles, rejectedFiles) => this.handleFileUploadChange(acceptedFiles, rejectedFiles)}
                    accept={{
                        'image/png': ['.png', '.jpg', '.jpeg'],
                        'application/pdf': ['.pdf'],
                    }}
                    maxSize={this.maxFileSizeMB * 1024 * 1024}
                >
                    {({ getRootProps, getInputProps }) => (
                        <section>
                            <div {...getRootProps({ className: `file-zone ${this.props.moduleHasError ? 'file-zone--error' : ''}` })}>
                                <input {...getInputProps()} />
                                <FontAwesomeIcon icon={faCloudUploadAlt} size='7x' className="icon" />
                                <p className='file-button'>Choose a file to upload</p>
                                <span className='note'>
                                    Please make sure that every detail of the document is clearly visible. <br />
                                    Only accepts .jpg, .jpeg, .png and .pdf files with maximum size of {this.maxFileSizeMB}MB per file.
                                </span>
                            </div>
                            <aside>
                                {this.state.isConverting ?
                                    <span>Processing Files. Please wait...</span>
                                    :
                                    <>
                                        {this.state.errorFileMessage &&
                                            <FtaModal
                                                text={'Failed to add file(s).'}
                                                subText={this.state.errorFileMessage}
                                                close={() => this.setState({ errorFileMessage: '' })}
                                            />
                                        }
                                        {this.state.rawFiles.length > 0 && <span>Files:</span>}
                                        <ul className='file-list'>
                                            {
                                                this.state.rawFiles.map((file, i) => (
                                                    <li key={i} className='file-info'>{this.fileNameDisplay(file.name)} - {Number.parseFloat(file.size / 1024 / 1024).toFixed(2)} MB
                                                        <button type='button' key={i} className='file-button-remove' onClick={() => this.removeSingle(i)}>&#10005;</button>
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                        {/* {this.state.rawFiles.length > 0 && <button className='file-button' onClick={() => this.removeAll()}>Remove All Files</button>} */}
                                    </>
                                }
                            </aside>
                        </section>
                    )}
                </Dropzone>
            </>
        );
    }
}

export default UploadProofForm;