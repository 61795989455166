import React, { Component } from 'react';
import TimeSeriesChart from './timeSeriesChart';
import withWindowDimensions from '../../../commons/withWindowDimensions';

class RatingLine extends Component {

    constructor(props) {
        super(props);
        this.state = ({
            title: this.props.title ? this.props.title : "",
            data: this.props.data ? this.props.data : [],
            assetId: this.props.assetId ? this.props.assetId : "",
            ratingType: this.props.ratingType ? this.props.ratingType : "",
            yAxisTitle: this.props.yAxisTitle ? this.props.yAxisTitle : "",
            yAxisLabel: this.props.yAxisLabel ? this.props.yAxisLabel : "",
            maxCount: this.props.maxCount ? this.props.maxCount : ""
        })
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            ...nextProps
        })
    }

    render() {
        if (this.props.disabled) {
            return (
                <div style={{ maxWidth: (this.props.isSm ? '0px' : '560px'), width: '50vw', margin: 'auto' }} className="MetricLarge not-draggable">
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-lg-12 col-xs-12">
                            <label className="MetricLargeTitle">{/* this.state.title */}</label>
                        </div>
                        <div style={{ "position": "relative" }} className="col-md-12 col-sm-12 col-lg-12 col-xs-12">
                            <div style={{
                                'position': 'absolute',
                                'textAlign': 'center',
                                'width': '100%',
                                'left': '0'
                            }}>
                                <i style={{ 'color': 'rgb(150, 150, 150)' }} className="fas fa-exclamation-triangle fa-5x"></i>
                                <br />
                                <label style={{ 'color': 'rgb(150, 150, 150)' }}>Under construction</label>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div style={{ maxWidth: (this.props.isSm ? '0px' : '560px'), width: '50vw', margin: 'auto' }} className="MetricLarge not-draggable">
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-lg-12 col-xs-12">
                            <label className="MetricLargeTitle">{/* this.state.title */}</label>
                        </div>
                        <div className="col-md-12 col-sm-12 col-lg-12 col-xs-12">
                            <TimeSeriesChart assetId={this.state.assetId}
                                data={this.state.data}
                                ratingType={this.state.ratingType}
                                yAxisTitle={this.state.yAxisTitle}
                                yAxisLabel={this.state.yAxisLabel}
                                maxCount={this.state.maxCount} />
                        </div>
                    </div>
                </div>
            );
        }
    }
}
export default withWindowDimensions(RatingLine);