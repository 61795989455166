import React, { Component } from 'react';
import { prettifyDate, calculateAge, numberWithCommas, writeData, readAllData } from '../commons/utility.js';
import { Link } from "react-router-dom";
import { DashboardLoader } from '../commons/utilityViews.js';

class dashboardLapsedReport extends Component {

    render() {
        const props = this.props;
        let rowsData = (<tr><td className="dashboard-row-data font-neo-semibold center-align" colSpan={3}>No records found</td></tr>)
        if (props.list !== null && props.list !== undefined && props.list.length > 0) {
            rowsData = props.list.map((item, index) => {
                if (index < 10) {
                    let isLapsedCss = "";
                    if (item.daysSinceLapsed && item.daysSinceLapsed > 1096) {
                        isLapsedCss = "text-danger"
                    }
                    return (
                        <tr key={index} className={`dashboard-row-data font-neo-semibold ${isLapsedCss}`}>
                            {props.isMgr ? <td><label className='mr-2'>{item.agent && item.agent.name || "--"}</label></td> : null}
                            <td>
                                <a className={`text-info mr-2 ${isLapsedCss}`} style={{ cursor: 'pointer' }} onClick={() => props.onClickPolicyNumber(item.contractNumber)}>
                                    {`${item.contractNumber}`}
                                </a>
                            </td>
                            <td><label className='mr-2'>{item.contractHolder.name || "--"}</label></td>
                            <td><label className='mr-2'>{prettifyDate(item.lapsedStartDate)}</label></td>
                            <td><label className='mr-2'>{item.daysSinceLapsed || "--"}</label></td>
                            <td><label className='mr-2'>{item.currency || "--"}</label></td>
                        </tr>
                    )
                } else {
                    return (
                        <span></span>
                    )
                }

            });
        }

        let headerData = (<tr><td colSpan={3}></td></tr>)
        if (props.list !== null && props.list !== undefined && props.list.length > 0) {
            headerData = (<tr className="font-neo-light dashboard-table-headers text-darkgray">
                {props.isMgr ? <th className="pr-2" scope="col">Intermediary Name</th>: null}
                <th className="pr-2" scope="col">Policy No.</th>
                <th className="pr-2" scope="col">Policy Owner</th>
                <th className="pr-2" scope="col">Lapsed Date</th>
                <th className="pr-2" scope="col">Days Lapsed</th>
                <th className="pr-2" scope="col">Currency</th>
            </tr>);
        }

        let viewAll = (<span></span>);
        if (props.list !== null && props.list !== undefined && props.list.length > 0) {

            viewAll = (<Link to="/dashboard/lapse-list" className="btn btn-headercolor dashboard-data-button">View All</Link>);
        }


        if (props.list === null) {
            return (

                <div className={`${props.css} mb-4`}>
                    <div className="dashboard-data-content bg-white p-3 shadow-sm rounded">
                        <DashboardLoader />

                    </div>
                </div>
            );
        } else {
            return (

                <div className={`${props.css} mb-4`}>
                    <div className="dashboard-data-content bg-white p-3 shadow-sm rounded">
                        <div className="dashboard-upper-body">
                            <div className="dashboard-data-header">
                                <h6 className="d-flex dashboard-data-header__text align-items-end font-neo-semibold text-headercolor">List of Lapsed Policies
                                    {/* <span className="dashboard-data-icon icon-chevron-dropdown"></span> */}
                                </h6>
                                <span className='report-generate-spiel text-headercolor'>
                                    <small><i>Note: Please be advised that those lapse policies in red are not reinstate-able</i></small>
                                </span>
                            </div>
                            <div className="dashboard-data-body overflow-auto">
                                <table className="table table-borderless dashboard-data-table">
                                    <thead>
                                        {headerData}
                                    </thead>
                                    <tbody>
                                        {rowsData}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="dashboard-data-footer">
                            {viewAll}
                        </div>
                    </div>
                </div>

            );
        }
    }
}

export default dashboardLapsedReport;
