import React, { Component } from 'react';
import '../../css/mis.scss';
import BarChart from './barChart';
import { apiModule, createQueryStrings } from '../../commons/apiCall';
import { haveValue, labelXAxis, showNoData, charts } from '../../commons/misConfig';
import { getMinVal, getMaxVal, formatValues, formatValuesNoDecimals } from './formatter';
import CommentIcon from './commentIcon';

class PerProduct extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isProduct: true,
            data: {
                labels: [],
                datasets: [{
                    data: [],
                    borderWidth: 2,
                    borderColor: "#78DCFA",
                    backgroundColor: "#78DCFA"
                }]
            },
            isLoading: true,
            isCommentVisible: false,
            totalComments: 0
        }
        this.getPerProduct = this.getPerProduct.bind(this);
        this.clickChart = this.clickChart.bind(this);
        this.returnToAll = this.returnToAll.bind(this);
    }

    componentWillMount() {
        this.getPerProduct(this.props);
        this.props.getCommentCount(this, this.props.product.value == 'ALL' ? 'product' : 'plan', this.props.access_token);
        this.setState({
            ...this.props
        })
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.willUpdate) {
            this.setState({
                ...nextProps,
                isLoading: true
            })

            this.getPerProduct(nextProps);
        } else {
            this.setState({
                willUpdate: nextProps.willUpdate
            })
        }

        if(nextProps.willUpdateCommentCount){
            nextProps.getCommentCount(this, nextProps.product.value == 'ALL' ? 'product' : 'plan', nextProps.access_token);
        }
    }

    async getPerProduct(props) {
        let that = this;
        let headerParams = {
            x_auth: props.access_token
        };
        let filter = [];
        filter['metric'] = haveValue(props.metric) ? props.metric.value : '';
        filter['period'] = haveValue(props.period) ? props.period.value : '';
        filter['startDate'] = haveValue(props.startDate) ? props.startDate : '';
        filter['endDate'] = haveValue(props.endDate) ? props.endDate : '';
        filter['productOfferingType'] = haveValue(props.product) ? props.product.value : '';
        filter['channel'] = haveValue(props.channel) ? props.channel.value : '';
        filter['contractStatus'] = haveValue(props.status.value) ? props.status.value : '';
        filter['targetType'] = haveValue(props.target.value) ? props.target.value : '';
        apiModule("get_metric_per_product", headerParams, null, createQueryStrings(filter))
            .then(function (result) {
                let dataObject = {
                    labels: [],
                    datasets: [{
                        data: [],
                        targetData: [],
                        dataPct: [],
                        dataCount: [],
                        targetDataCount: [],
                        dataCountPct: [],
                        borderWidth: 2,
                        borderColor: "#78DCFA",
                        backgroundColor: "#78DCFA"
                    }]
                };

                if (result.goalDetails) {
                    result.goalDetails.map(function (obj, index) {
                        dataObject.labels[index] = haveValue(props.product) ? obj.productOfferingType ? labelXAxis(obj.productOfferingType) : labelXAxis(obj.plan) : '';
                        dataObject.datasets[0].data[index] = obj.achievedPremiumRate;
                        dataObject.datasets[0].targetData[index] = obj.plannedPremiumRate;
                        dataObject.datasets[0].dataPct[index] = obj.achievedPercentage;
                        dataObject.datasets[0].dataCount[index] = obj.numberOfInsuranceCompanyContracts;
                        dataObject.datasets[0].targetDataCount[index] = obj.plannedNumberOfInsuranceCompanyContracts;
                        dataObject.datasets[0].dataCountPct[index] = obj.plannedPercentage;
                        dataObject.metric = haveValue(props.metric) ? props.metric.value : ''
                        dataObject.minVal = getMinVal(result.goalDetails);
                        dataObject.maxVal = getMaxVal(result.goalDetails);
                    })
                }

                that.setState({
                    data: dataObject,
                    isLoading: false
                });

            })
            .catch(function (err) {
                console.log(err);
            });

            
        // let commentHeaderParams = { chartId: (props.product.value === 'ALL' ? 'product' : 'plan'), x_auth: props.access_token };
        // let commentFilter = [];
        // commentFilter['filter'] = 'count';

        // apiModule("get_comments", commentHeaderParams, null, createQueryStrings(commentFilter))
        //     .then(function (result){
        //         that.setState({
        //             totalComments: result.totalComments ? result.totalComments : 0
        //         })

        //     })
        //     .catch(function (err) {
        //         console.log(err)
        //     });
    }

    clickChart(value) {
        const { product } = this.state;
        const { productOptions, onChangeFilter } = this.props;
        if (product.value == 'ALL') {
            Object.keys(productOptions).map(function (key) {
                if (value.toUpperCase() == productOptions[key].label.toUpperCase()) {
                    onChangeFilter('product', productOptions[key], true)
                }
            })
        }
    }

    returnToAll(value) {
        const { productOptions, onChangeFilter } = this.props;
        Object.keys(productOptions).map(function (key) {
            if (value.toUpperCase() == productOptions[key].label.toUpperCase()) {
                onChangeFilter('product', productOptions[key], true)
            }
        })

        this.setState({
            isLoading: true,
            isUpdating: true
        })
    }

    render() {
        const { data, product, status, willUpdate, isLoading, totalComments, currTarget } = this.state;
        const { coinLoader, onClickCommentIcon } = this.props;
        let isSubmitted = status.value.toUpperCase().includes('SUBMITTED');
        let header = '';
        header += (haveValue(status) ? (isSubmitted ? 'Submitted' : 'Issued') + ' per ' : '');
        header += (haveValue(product) ? (product.value === 'ALL' ? ' Product' : ' Plan') : '');
        header += haveValue(product) ? (product.value === 'ALL' ? '' : ' ( ' + product.label + ' )') : '';
        let isPD = currTarget.value.toUpperCase().includes('PD');
        return (
            <div className="container mis-container col-sm-12 col-md-12 col-lg-6 col-xl-6">
                {/* <div className="mis-comment-icon-container" onClick={() => onClickCommentIcon(product.value === 'ALL' ? charts.product : charts.plan)}>
                    <div className="mis-comment-icon">
                        <img src = "/mis/comments.svg" className="mis-comment-icon-img"/>
                        <span className="mis-comment-count">
                            {formatValuesNoDecimals(totalComments)}
                        </span>
                    </div>
                </div> */}
                <CommentIcon
                    chart={product.value === 'ALL' ? charts.product : charts.plan}
                    onClickCommentIcon={onClickCommentIcon} 
                    totalComments={totalComments} />
                <div className="card mis-card-3 bg-white p-3 shadow-sm rounded">
                    <div className="card-body mis-card-body">
                        <div className="card-title mis-card-title">
                            <span className="d-flex dashboard-data-header__text align-items-end font-neo-semibold text-headercolor">
                                {header}
                            </span>
                            {product.value !== 'ALL' && !isLoading ? <span className='dashboard-return-text' onClick={() => { this.returnToAll('All') }}>
                                <span className="mis-accumulative-desc">Clear Filter</span>
                            </span> : ''}
                        </div>
                        {isPD && product.value !== "ALL" ? showNoData(true) :
                            isLoading ? this.props.coinLoader :
                            <BarChart chartId="PerProduct" data={data} {...this.props} willUpdate={willUpdate}
                                clickChart={this.clickChart}
                                style={{ marginTop: '8%' }} />
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default PerProduct;