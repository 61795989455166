import React, {Component} from 'react';
import '../../css/misMonthPicker.scss';
import Picker from 'react-month-picker';
import MonthBox from './monthBox.js';
import {months} from '../../commons/misConfig.js';

class MisMonthList extends Component {
    constructor(props, context) {
        super(props, context)

        this.state = {
            mvalue: {year: props.year, month: props.month},
            shortMonths: months.map(month => month.substring(0,3))
        }

        this.handleClickMonthBox = this.handleClickMonthBox.bind(this)
        this.handleAMonthChange = this.handleAMonthChange.bind(this)
        this.handleAMonthDissmis = this.handleAMonthDissmis.bind(this)
    }

    componentWillReceiveProps(nextProps){
        this.setState({
            mvalue: {
                year: nextProps.year,
                month: nextProps.month
            }
        })
    }

    render() {
        let { year, month, maxMonth } = this.props;
        let { mvalue, shortMonths } = this.state;

        const makeText = m => {
            if (m && m.year && m.month) return (shortMonths[m.month-1] + ' ' + m.year)
            return '?'
        }

        return (
            <ul>
                <li>
                    <label>Pick A Month</label>
                    <div className="edit">
                        <Picker
                            ref="pickAMonth"
                            years={{min: {year: year, month: 1}, max: {year: year, month: maxMonth}}}
                            value={mvalue}
                            lang={shortMonths}
                            onChange={this.handleAMonthChange}
                            onDismiss={this.handleAMonthDissmis}
                        >
                            <MonthBox value={makeText(mvalue)} onClick={this.handleClickMonthBox} />
                        </Picker>
                    </div>
                </li>

            </ul>
        )
    }

    handleClickMonthBox(e) {
        this.refs.pickAMonth.show();
    }

    handleAMonthChange(year, month, idx) {
        this.props.onChangeMonth(year, month)
        this.refs.pickAMonth.dismiss();
    }

    handleAMonthDissmis(value) {
        this.setState({
            mvalue: value
        })
    }
}

export default MisMonthList;