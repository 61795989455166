import React, { Component } from 'react';

import { getSessionInfo } from "../commons/security";
import { getPrivacyContent } from "../components/PrivacyNotice/privacyContent";

class PrivacyNotice extends Component {

    async componentDidMount() {
        const permissions = await getSessionInfo();
        console.log('permissions')
        console.log(permissions)
    }

    render() {
        const user = this.props.getSession();
        const headerText = determineTitle(user.channel);

        return (
            <div className="dashboard-section overflow-hidden">
                <div className="dashboard-container container-fluid p-3">
                    <div className="dashboard-body">
                        <div className="PrivacyNotice">
                            <h1 className="PrivacyNotice__header font-neo-bold text-darkgray m-3 mb-4">{`Privacy Notice`}</h1>

                            <div className="PrivacyNotice__body font-neo-semibold p-4">
                                { getPrivacyContent(headerText) }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

function determineTitle(channel) {
    let title = "";
    switch (channel) {
        case "BANCA":
        case "PNB":
            title = "Banca"
            break;
        case "AGENCY":
            title = "Agency";
            break;
        default:
            title = "Default"
    }
    return title;
}

export default PrivacyNotice;