import React, { Component } from 'react';
import ReactPlayer from 'react-player';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import VisibilitySensor from 'react-visibility-sensor';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import parse from 'html-react-parser';
import { getBulletinDetails } from '../commons/restEndpoint';
import moment from 'moment';
import MiniLoading from '../components/miniLoading';

export default class bulletinDetails extends Component {

    constructor(props) {
        super(props)

        this.state = {
            doneGettingBulletinDetails: false,
            copied: false,
            bulletin: {
                id: 0,
                title: '',
                type: '',
                date: '',
                summary: '',
                media: [],
                content: []

            },
            videoStyle: {
                width: '100% !important'
            },
            settings: {
                dots: true,
                infinite: true,
                speed: 500,
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: true
            }
        }

        this.getMedia = this.getMedia.bind(this);
        this.fetchBulletinDetails = this.fetchBulletinDetails.bind(this);
    }

    async fetchBulletinDetails() {
        let x = await getBulletinDetails(this.props.bulletinId, this.props.session.access_token);
        if (x) {
            if (x.data) {

                var effectiveDt = x.data.effectiveDt ? x.data.effectiveDt : 0;
                effectiveDt = moment(effectiveDt * 1000);
                effectiveDt = effectiveDt.format('MMMM DD, YYYY');
                if (effectiveDt === "Invalid date") {
                    effectiveDt = null;
                }

                this.setState({
                    doneGettingBulletinDetails: true,
                    bulletin: {
                        title: x.data.title,
                        type: x.data.type,
                        date: effectiveDt,
                        media: x.data.media,
                        summary: '',
                        content: x.data.html,
                        id: x.data.id,
                    }
                });
            }
        }
    }

    componentDidMount() {
        this.fetchBulletinDetails();
    }

    getMedia(media, index) {
        let mediaResult = <div key={index}>No Media Attached.</div>
        if (media) {
            switch (media.type) {
                case "IMAGE":
                    mediaResult = <div key={index} className="rni-bulletin-media-container">
                        <img src={media.imgUrl} alt="" className="rni-bulletin-media-img" />
                    </div>;
                    break;
                case "VIDEO":
                    mediaResult = <div>
                        <VisibilitySensor>
                            {({ isVisible }) => {
                                return <ReactPlayer
                                    playing={isVisible ? true : false}
                                    url={media.imgUrl}
                                    width="100%"
                                    height="233px"
                                    controls={true}
                                    volume={1} />
                            }}
                        </VisibilitySensor>
                    </div>;
                    break;
                case "YOUTUBE":
                    mediaResult = <iframe src={media.imgUrl}>
                    </iframe>
                default: mediaResult = mediaResult;
            }
        }

        return mediaResult;
    }

    render() {
        const { videoStyle, settings, bulletin, copied, doneGettingBulletinDetails } = this.state;
        let that = this;
        return (
            <div id="BulletinPage" className={`container-fluid rni-bulletin-page rni-bulletin-item-bg-${bulletin.type}`}>
                {doneGettingBulletinDetails ? <div>
                    {copied ? <div className="rni-clipboard-copied" onAnimationEnd={() => this.setState({ copied: false })}>
                        <p>✓ Successfully copied link to clipboard. </p>
                    </div> : null}
                    <div className="col-lg-12 col-md-12 rni-bulletin-page-header">
                        <h4 className="rni-bulletin-page-title">
                            {bulletin.title}
                            <CopyToClipboard text={window.location.href}
                                onCopy={() => this.setState({ copied: true })}>
                                <img src="/rni/content.png" alt="" className="rni-clipboard-img" />
                            </CopyToClipboard>
                        </h4>
                        <label>
                            <span className="rni-bulletin-type">{bulletin.type}</span>
                            <span className="rni-bulletin-date">&nbsp;&nbsp;{bulletin.date}</span>
                        </label>
                    </div>
                    <div className="col-lg-12 col-md-12 rni-bulletin-page-media">
                        <Slider {...settings}>
                            {
                                bulletin.media.map(function (mediaObj, bannerIndex) {
                                    return that.getMedia(mediaObj, bannerIndex)
                                })}
                        </Slider>
                    </div>
                    <div className="col-lg-12 col-md-12 rni-bulletin-page-content">
                        <div className="rni-bulletin-page-content-container">
                            {
                                bulletin.content.map(function (contentObj, contentIndex) {
                                    return parse(contentObj)
                                })
                            }
                        </div>
                    </div>
                </div>
                    :
                    <div style={{ width: '35vw', margin: 'auto' }}><MiniLoading /></div>
                }
            </div>
        )
    }
}
