import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { checkPermissions, getSessionInfo } from '../../commons/security';

class Nav extends Component {
    state = {
        hidePolicies: false
    }

    constructor(props) {
        super(props);
    }

    async componentDidMount() {
        let session = await getSessionInfo();
        let permissionsList = session[0].permissions;
        if (!checkPermissions(['VIEW_AGENT_UPDATING_OF_CLIENT'], permissionsList)) {
            this.setState({ hidePolicies: true })
        }
    }

    render() {
        const { policyList, transactionHistory } = this.props;
        const { hidePolicies } = this.state;
        const hidePoliciesClass = hidePolicies ? "d-none" : "";
        return (
            <div className="dashboard-nav d-none d-md-block">
                <ul className="dashboard-nav__list px-2">
                    <li className={`dashboard-nav__list-item ${hidePoliciesClass} ${policyList || ""}`}><Link id="policyList" className={`dashboard-nav__link text-decoration-none ${policyList}`} style={{ color: "#9A9B9C" }} to="/dashboard/policy-services">List of Policies</Link></li>
                    <li className={`dashboard-nav__list-item ${transactionHistory || ""}`}><Link id="transactionHistory" className={`dashboard-nav__link text-decoration-none ${transactionHistory}`} style={{ color: "#9A9B9C" }} to="/dashboard/agent-transaction-history">Transaction History</Link></li>
                </ul>
                <div className="dashboard-nav__border border-bottom"></div>
            </div>
        );
    }
}
export default Nav;
