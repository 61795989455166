import React, { Component } from 'react';
import { prettifyDate, calculateAge, numberWithCommas, writeData, readAllData, removeNullNumber, removeNull, fixName } from '../../commons/utility.js';
import { myConfig } from '../../commons/config';
import { apiModule } from '../../commons/apiCall';

class Surrender extends Component {

    constructor(props) {
        super(props);
        this.state = {
            surrenderInfo: []
        }
    }

    componentDidMount() {
        const access_token = this.props.access_token;
        const policyId = this.props.policyId;
        const headerParams = {
            x_auth: access_token, policyId: policyId,
            query: "?amendmentType=SURRENDER"
        };
        console.log("CHECK", { access_token, policyId });
        const that = this;
        apiModule("get_policy_endorsements", headerParams, null)
            .then(function (result) {
                let surrenderInfo = [];
                console.log("get_policy_endorsements");
                console.log(result);

                if (result)
                    for (let i = 0; i < result.length; i++) {
                        let item = result[i];

                        surrenderInfo.push({
                            inceptionDate: prettifyDate(item.inceptionDate),
                            status: removeNull(item.status),
                            currency: removeNull(item.currency),
                            accountType: removeNull(item.accountType),
                            payoutOption: removeNull(item.payoutOption),
                            checkNo: removeNull(item.checkNo),
                            transactionDate: prettifyDate(item.transactionDate),
                            transactionAmount: numberWithCommas(item.transactionAmount),
                            transactionStatus: removeNull(item.transactionStatus),
                            disbursingBank: removeNull(item.receivingBank),
                        });
                    }

                that.setState({
                    surrenderInfo,
                });
            })
            .catch(function (err) {
                console.log(err);
                that.errorMessage(myConfig.GENERIC_ERROR_MESSAGE);
                //   resolve("Ok");

            });
    }


    render() {
        const css = this.props.css;
        const noteMessage = "For Cheque availability, kindly give us an additional 5 to 7 business days from the displayed date below. For Fund Transfer, kindly give us an additional 1 to 2 business days for the actual posting in the client's account.";
        const noteStyle = {
            fontSize: ".9em",
            color: "#255f8e",
        };
        let columns = [
            "Bank Name",
            "Account Currency",
            "Type of Account",
            "Surrender Status",
            "Surrender Value",
            "Surrender Date",
            "Payout Option",
            "Cheque Status/\nFund Transfer",
            "Cheque Number/\nFund Transfer Date"
        ];

        let surrenderColumns = columns.map((item, index) => {
            return (
                <th key={index}>{item}</th>
            );
        });
        let surrenderRows = this.state.surrenderInfo.map((item, index) => {
            let conditionalValue = "";
            const payoutOption = item.payoutOption ? item.payoutOption.toLowerCase() : "";
            switch (payoutOption) {
                case "fund transfer":
                    conditionalValue = item.transactionDate;
                    break;
                case "cheque":
                    conditionalValue = item.checkNo;
                    break;
                default:
                    conditionalValue = "--";
            }
            return (
                <tr key={index}>
                    <td>{item.disbursingBank}</td>
                    <td>{item.currency}</td>
                    <td>{item.accountType}</td>
                    <td>{item.status}</td>
                    <td>{item.transactionAmount}</td>
                    <td>{item.inceptionDate}</td>
                    <td>{item.payoutOption}</td>
                    <td>{item.transactionStatus}</td>
                    <td>{conditionalValue}</td>
                </tr>
            );
        });

        if (this.state.surrenderInfo === null || this.state.surrenderInfo.length === 0) {
            return (
                <div className={`${css}`}>
                    <div className="policy__table-container">
                        <span className="dashboard-row-data font-neo-semibold center-align">No information to be displayed</span>
                    </div>
                </div>
            )
        } else {
            return (
                <div className={`${css}`}>
                    {/* <h6 className="coverage-header text-headercolor font-neo-bold">For Unit-Linked Plans</h6> */}
                    <p className="font-italic" style={noteStyle}>Note: {noteMessage}</p>
                    <div className="policy__table-container">
                        <table className="table coverage-table-row coverage-table-bordered coverage-table-striped coverage-table-header table-borderless mb-0">
                            <thead>
                                <tr>
                                    {surrenderColumns}
                                </tr>
                            </thead>
                            <tbody>
                                {surrenderRows}
                            </tbody>
                        </table>
                    </div>
                </div>

            );
        }
    }

}
export default Surrender;
