import React from 'react';
import * as DOMPurify from 'dompurify';

const FtaModal = (props) => {
    let { close, text, subText } = props;
    text = text || "Please fill out all of the required fields.";
    return (
        <div className="policy-dashboard__prompt-overlay policy-dashboard__prompt-overlay--fix-position">
            <article className="policy-services-prompt">
                <article className="policy-services-prompt__content">
                    <article className="policy-services-submodule__user-area d-flex flex-column">
                        <section className="policy-services-submodule__child-container">
                            <h4 className="text-center">{text}</h4>
                            {subText && <div className="mt-4" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(subText) }} />}
                            <div className="policy-services-submodule__auth-actions policy-services-submodule__auth-actions--fta">
                                <button
                                    className="btn btn-info px-4"
                                    onClick={() => close()}
                                >
                                    OK
                                </button>
                            </div>
                        </section>
                    </article>
                </article>
            </article>
        </div>
    )
}

export default FtaModal;