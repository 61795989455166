import React, { Component } from 'react';
import { prettifyDate, calculateAge, numberWithCommas, writeData, readAllData } from '../commons/utility.js';
import { apiModule } from '../commons/apiCall';
import { Link } from "react-router-dom";
import { DashboardLoader } from '../commons/utilityViews.js';
import moment from 'moment';

class premiumDue extends Component {

    render() {
        const props = this.props;
        let rowsData = (<tr><td className="dashboard-row-data font-neo-semibold center-align" colSpan={3}>No records found</td></tr>)
        if (props.list !== null && props.list !== undefined && props.list.length > 0) {
            rowsData = props.list.map((item, index) => {
                if(index < 10) {
                    let isPastDue = moment(item.paidUntilDate) < moment().subtract(1, 'days')  ? "text-danger"  : null;

                    return (
                        <tr key={index} className={`dashboard-row-data font-neo-semibold ${isPastDue}`}>
                            {props.isMgr ?
                                <td>
                                    <a className="mr-2" style={{ cursor: 'pointer' }} onClick={() => props.onClickPolicyNumber(item.contractNumber)}>
                                        {`${item.agent.name}`}
                                    </a>
                                </td>
                                : null}
                            <td>
                                <a className="mr-2" style={{ cursor: 'pointer' }} onClick={() => props.onClickPolicyOwner(item.contractHolder.self)}>
                                    {prettifyDate(item.paidUntilDate)}
                                </a>
                            </td>
                            <td>
                                <a className={`text-info mr-2 ${isPastDue}`} style={{ cursor: 'pointer' }} onClick={() => props.onClickPolicyOwner(item.contractHolder.self)}>
                                    {`${item.contractHolder.name}`}
                                </a>
                            </td>
                            <td>
                                <a className={`text-info mr-2 ${isPastDue}`} style={{ cursor: 'pointer' }} onClick={() => props.onClickPolicyNumber(item.contractNumber)}>
                                    {`${item.contractNumber}`}
                                </a>
                            </td>
                            <td>
                                <a className="mr-2" style={{ cursor: 'pointer' }} onClick={() => props.onClickPolicyNumber(item.contractNumber)}>
                                    {`${item.typeOfBill}`}
                                </a>
                            </td>
                            <td>
                                <a className="mr-2" style={{ cursor: 'pointer' }} onClick={() => props.onClickPolicyNumber(item.contractNumber)}>
                                    {`${item.paymentFrequency ? item.paymentFrequency : "--"}`}
                                </a>
                            </td>
                        </tr>
                    )
                }else {
                    return (
                        <span></span>
                    )
                }

            });
        }

        let headerData = (<tr><td colSpan={3}></td></tr>)
        if (props.list !== null && props.list !== undefined && props.list.length > 0) {
            headerData = (<tr className="font-neo-light dashboard-table-headers text-darkgray">
                {props.isMgr ? <th className='pr-2' scope="col">Intermediary Name</th> : null}
                <th className='pr-4' scope="col">Due Date</th>
                <th className='pr-2' scope="col">Policy Owner</th>
                <th className='pr-2' scope="col">Policy No.</th>
                <th className='pr-2' scope="col">Payment Option</th>
                <th className='pr-2' scope="col">Mode of Payment</th>
            </tr>);
        }

        let viewAll = (<span></span>);
        if (props.list !== null && props.list !== undefined && props.list.length > 0) {

            viewAll = (<Link to="/dashboard/premium-due-list-view" className="btn btn-headercolor dashboard-data-button">View All</Link>);
        }


        if(props.list === null) {
            return (

                <div className={`${props.css} mb-4`}>
                    <div className="dashboard-data-content bg-white p-3 shadow-sm rounded">
                        <DashboardLoader />

                    </div>
                </div>
            );
        }else {
            return (

                <div className={`${props.css} mb-4`}>
                    <div className="dashboard-data-content bg-white p-3 shadow-sm rounded">
                        <div className="dashboard-upper-body">
                            <div className="dashboard-data-header">
                                <h6 className="d-flex dashboard-data-header__text align-items-end font-neo-semibold text-headercolor">Premium Due List
                            {/* <span className="dashboard-data-icon icon-chevron-dropdown"></span> */}
                                </h6>
                                <span className='report-generate-spiel text-headercolor'>
                                    <div className='dashboard-data-header__note'><i>Note: Please be advised that those in red font are past due policies</i> <br />
                                    <i>Data reflected here are the policies with current due. To check if paid, please go to the Billing and Payment page.</i></div>
                                </span>
                            </div>
                            <div className="dashboard-data-body overflow-auto">
                                <table className="table table-borderless dashboard-data-table">
                                    <thead>
                                        {headerData}
                                    </thead>
                                    <tbody>
                                        {rowsData}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="dashboard-data-footer">
                            {viewAll}
                        </div>
                    </div>
                </div>

            );
        }
    }
}

export default premiumDue;