import React, { Component } from 'react'
import { getParams } from '../commons/service'
import NotFound from './pagenotfound'
import MiniLoading from '../components/miniLoading'
import LeaderboardList from './leaderboardList'
import LeaderboardSection from './leaderboardSection'

export default class leaderboard extends Component {

    constructor(props) {
        super(props)
        this.state = {
            contest: null
        }

        this.getContestLeaderboard = this.getContestLeaderboard.bind(this);
    }

    componentWillMount() {
        let params = getParams();
        this.getContestLeaderboard(params);
    }

    componentWillReceiveProps(nextProps) {
        let params = getParams();
        this.getContestLeaderboard(params);
    }

    async getContestLeaderboard(params) {
        let result = ''
        setTimeout(() => {
            result = 0;
        }, 2000);
        this.setState({
            contest: params.contest,
            result: result
        })
    }

    render() {
        const { contest } = this.state;
        return (
            <div style={{ textAlign: 'center' }}>
                {!contest ?
                    <LeaderboardSection contestId={contest} session={this.props.session} /> :
                    <LeaderboardList contestId={contest} session={this.props.session} />}
            </div>
        )
    }
}
