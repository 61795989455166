import React, { Component } from 'react';
import parse from 'html-react-parser';
import './modal.scss';
import { readAllData, writeData, decrypt } from '../../commons/utility';
import { apiModule } from '../../commons/apiCall.js';
import { LoaderGif2, LoaderGif3 } from '../../commons/loaderGif';
import AuthLoader from '../../commons/authLoader';
import Otp from '../otp';
import { myConfig } from '../../commons/config';

export default class Modal extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.setState({
            isOTPReady: false,
            isLoading: false,
            error: 'Please enter the OTP that we sent to your old contact number',
            code: '',
        });
    }


    errorMessage = (message) => {
        if (message) {
            this.setState({ error: message })
        }
    }

    doOTP = (otpInputs) => {

        const that = this;
        let code = that.props.code;
        that.setState({ isLoading: true });

        console.log('CODE');
        console.log(code);

        return new Promise(function (resolve, reject) {

            let headerParams = {
                x_auth: that.props.session.access_token
            };
            //let code = that.props.code;
            // if (that.state.code === '' || that.state.code === undefined || that.state.code === null) {
            //     code = that.props.session.code;
            // }
            console.log('Promised CODE');
            console.log(code);

            let otpString = '';
            Object.keys(otpInputs).forEach(otpObjectKey => {
                otpString = otpString.concat(otpInputs[otpObjectKey].value)
            });

            // let otpString = that.state.payloadParams.first + that.state.payloadParams.second + that.state.payloadParams.third + that.state.payloadParams.fourth + that.state.payloadParams.fifth + that.state.payloadParams.sixth
            let payloadParams = {
                username: decrypt(that.props.session.username),
                code: code,
                otp: otpString,
                appId: 5
            };

            apiModule("validate_otp", headerParams, payloadParams, '', true)
                .then(function (result) {
                    if (result.status === 202) {
                        console.log('OTP SUCCESS');
                        that.props.sendEmail();
                        that.props.updateSuccessHandler();
                    } else if (result.status === 604) {
                        console.log('OTP FAILED');
                        console.log(result);
                        that.errorMessage('The OTP has expired. Please try again.');
                    } else if (result.status === 605) {
                        console.log('OTP FAILED');
                        console.log(result);
                        that.errorMessage('Update Request for this account has been locked.');
                    } else {
                        console.log('OTP FAILED');
                        that.errorMessage(myConfig.GENERIC_ERROR_MESSAGE);
                    }
                    that.setState({
                        isLoading: false,
                    });
                    resolve("Ok");
                })

                .catch(function (err) {
                    console.log(err);

                    that.errorMessage("Invalid OTP");
                    that.setState({
                        isLoading: false,

                    });
                    resolve("Ok");
                })
            // let result = null;

        });
    }

    resendOTP = () => {
        console.log('OTP RESEND');
        this.props.sendOTP();
        this.errorMessage('OTP Resent');
    }

    render() {
        const that = this;
        let modalMessage = 'Please enter the OTP that we sent to your old contact number';
        let resendMessage = "Didn't get a One-Time Password?";

        let returnValue = this.props.showModal? (
            <div className="user-info-modal-body">
                <div className="user-info-modal-box background--gradient-blue">
                    <div className="user-info-modal-close-container">
                        <h5 className="user-info-modal-title"></h5>
                        {/* <img className="modal-close-icon" src="/announcement/close.png" alt="close-icon" onClick={this.props.handleClose} /> */}
                        <span className="icon-close user-info-modal--close-icon" onClick={this.props.handleClose}></span>
                    </div>
                    <div className="user-info-modal-content background--gradient-blue">
                        <div className="m-4" style={{ overflow: 'auto' }}>
                            {/* <div className="text-center font-weight-bold">{modalMessage}</div> */}
                            {!this.props.isSuccessfulUpdating? (
                                <Otp
                                    handleSubmit={this.doOTP}
                                    isLoading={that.state.isLoading}
                                    handleResend= {this.resendOTP}
                                    error={that.state.error}
                                />
                                ) : (
                                <div className="text-center">
                                    <h4 className="text-white">Profile Update Sent!</h4>
                                    <p className="text-white mb-4">Please wait 1-2 business days for updates to be reflected.</p>
                                    <span className="icon-check-circle text-success modal--successful-update-icon"></span>
                                    <a onClick={this.props.handleClose} className={`auth__button btn btn-navyblue text-uppercase btn-block text-white mt-4`}>
                                        Proceed
                                    </a>
                                </div>
                            )}
                            {/* <div className="text-center mt-2">{resendMessage}</div>
                            <div className="text-center">
                                <a onClick={() => this.resendOTP(this)} className="text-center font-weight-bold otp__resend-txt">Resend</a>
                            </div> */}
                            {/* {!Moda ?
                                parse(message) :
                                <div className="modal-content-loader"><LoaderGif3 /></div>
                            } */}
                        </div>
                    </div>
                </div>
            </div>
        ) : '';

        return(
            returnValue
        );
    }
}