import React, { Component } from 'react';

import { Link } from "react-router-dom";

import { apiModule, createQueryStrings } from '../commons/apiCall';
import { myConfig } from '../commons/config';
import { checkSearch, decrypt, checkDateRange } from "../commons/utility.js";
import { isManager } from "../commons/security";

import { ReportLoader } from '../commons/utilityViews';
import ReportsMobile from '../components/Reports/Mobile/ReportsMobile';
import withWindowDimensions from '../commons/withWindowDimensions';
import ErrorMessage from '../components/errorMessage';
import Search from "../components/PaymentsBeyondDue/search/search";
import Policies from '../components/PaymentsBeyondDue/policies';
import moment from "moment";
import handleSortOrder from '../components/misc/sortOrder';
import PoliciesMobile from '../components/PaymentsBeyondDue/Mobile/policiesMobile';
import TablePagination from '../components/tablePagination';
import Insured from '../components/Applications/insured';

class PaymentsBeyondDueReport extends Component {
    state = {
        isLoading: true,
        errorMessage: "",

        // Table Properties
        isTableLoading: false,

        // Search
        searchFilter: { label: "Policy Owner", value: "contractHolderName" },
        searchTerm: "",
        startDate: "",
        endDate: "",
        status: {},

        query: {},

        // Sort
        sortByField: null,
        sortOrder: null,

        // Pagination
        pages: 0,
        page: 0,
        totalSize: 0,

        // Mobile Sort Dropdown
        sortFilter: { label: "Sort by...", value: "", order: "" },

        policies: [],
        statuses: []
    }

    // Lifecycle
    async componentDidMount() {
        this.setState({ isLoading: true });
        const jwt = this.props.getSession().access_token;
        const agentNumber = decrypt(this.props.getSession().agentNumber);

        // Fetch the policies
        let policies = [];
        let totalSize = 0;
        let pages = 0;

        try {
            ({ policies =[], totalSize = 0, pages = 0 } = await this.fetchPolicies(agentNumber, this.state.page, jwt));
        }
        catch (_e) {
            this.displayError(myConfig.GENERIC_ERROR_MESSAGE);
        }

        // Fetch the statuses
        let statuses = [];
        try {
            const statusResponse = await this.fetchStatuses(agentNumber, jwt);
            Object.keys(statusResponse).map((statusKey) => {
                const statusItem = statusResponse[statusKey];
                statuses.push({ label: statusItem, value: statusKey })
            });
        }
        catch (_e) {
            this.displayError(myConfig.GENERIC_ERROR_MESSAGE);
        }

        this.setState({ isLoading: false, policies, totalSize, pages, statuses });
    }

    // Data fetch
    /**
     * Retrieves a llst of policies according to the search parameters
     * 
     * @param {string | number} agentNumber 
     * @param {numberj} pageNumber 
     * @param {string} jwt 
     * @param {object} searchItem 
     */
    async fetchPolicies(agentNumber, pageNumber, jwt, searchItem = {}) {
        const query = {
            agentNumber,
            reportCode: "RPT_POL_BYD_DD",
            pageNumber: pageNumber + 1,
            pageSize: myConfig.PAGE_SIZE,
            ...searchItem
        };
        const headerParams = { x_auth: jwt };

        const response = await apiModule('policies_beyond_due_date', headerParams, null, createQueryStrings(query));
        const result = {
            totalSize: 0,
            policies: [],
            pages: 0
        };
        if (response.length > 0) {
            const responseData = response[0];
            result.totalSize = responseData.totalSize;
            result.policies = responseData.content;
            result.pages = responseData.pageCount
        };
        return result;
    }

    /**
     * Retrieves a list of application statuses 
     * 
     * @param {string} jwt 
     */
    async fetchStatuses(agentNumber, jwt) {
        const headerParams = {
            x_auth: jwt,
            dataListId: 'policyStatus'
        };
        const query = {
            agentNumber,
            accountManagerId: agentNumber,
        };

        return await apiModule('get_datalist', headerParams, null, createQueryStrings(query));
    }

    // Handlers
    /**
     * Callback for search filter dropdown change event
     * 
     * @event onChange
     * @param {Object.<String, String>} choice 
     */
    handleUpdateSearchFilter = (choice) => {
        this.setState({ searchFilter: choice }, () => this.clearSearch());
    }

    handleSortDropdownFilter = (choice) => {
        this.setState({ sortFilter: choice, sortByField: choice.value, sortOrder: choice.order }, () => this.triggerSearch(this.state.page));
    }

    /**
     * Callback for search term input field change event
     * 
     * @event onChange
     * @param {object} e - DOM event
     */
    handleUpdateSearchTerm = (e) => {
        this.setState({ searchTerm: e.target.value })
    }


    handleChangePage = (page) => {
        this.setState(
            { page },
            async () => await this.doSearch(page)
        );
    }

    handleUpdateStartDate = (date) => {
        this.setState({ startDate: date });
    }

    handleUpdateEndDate = (date) => {
        this.setState({ endDate: date });
    }

    handleUpdateStatus = (status) => {
        this.setState({ status });
    }

    handleClickHeader = async (sortByField) => {
        let sortOrder = handleSortOrder({ sortByField: sortByField, state: this.state });
        this.setState({
            sortByField: sortByField,
            sortOrder: sortOrder,
        }, () => {
            this.triggerSearch(this.state.page);
        });
    }

    /**
     * Callback for clicking the submit button on the search component
     * 
     * @event onClick
     */
    handleSubmit = () => {
        this.triggerSearch();
    }

    /**
     * Callback for pressing Enter on the search field
     * 
     * @event onKeyPress
     * @param {object} e - DOM event
     */
    handlePressEnter = (e) => {
        if (e.key === "Enter") {
            this.triggerSearch();
        }
    }

    // Helpers
    /**
     * Sets this.state.errorMessage with an error message
     * 
     * @param {string} errorMessage 
     */
    displayError = (errorMessage) => {
        this.setState({ errorMessage })
    }

    /**
     * Sets the this.state.errorMessave state variable to an empty string
     */
    hideError = () => {
        this.setState({ errorMessage: "" })
    }

    /**
     * Clears all the input fields except for the searchFilter
     */
    clearSearch = () => {
        this.setState({
            searchTerm: "",
            startDate: "",
            endDate: "",
            status: this.state.statuses[0],
            query: {}
        });
    }

    triggerSearch(page = 0) {
        this.setState(
            { query: this.formQuery(), page, searchTerm: this.state.searchTerm.trim() },
            async () => await this.doSearch(page)
        );
    }

    async doSearch(page = 0) {
        this.hideError();
        const query = this.state.query;
        const { isValid, isDate, message } = this.validateQuery(query);
        // console.log("IN SEARCH FUNCTION")
        // console.log(`Page ${page}`)
        // console.table(query);
        // console.table(this.validateQuery(query))

        this.setState({ isTableLoading: true })
        if (isValid) {
            try {
                const agentNumber = decrypt(this.props.getSession().agentNumber);
                const jwt = this.props.getSession().access_token;
                const { policies, totalSize, pages } = await this.fetchPolicies(agentNumber, page, jwt, query);
                this.setState({ policies, totalSize, pages });
            }
            catch (_e) {
                this.displayError(myConfig.GENERIC_ERROR_MESSAGE)
            }
        }
        else {
            if(!isValid) {
                if(isDate) {
                    this.displayError(message)
                }
            }
        }
        this.setState({ isTableLoading: false })
    }

    formQuery() {
        const searchFilter = this.state.searchFilter.value;
        let query = {};
        if (["contractNumber", "contractHolderName", "status"].includes(searchFilter)) {
            if (searchFilter === 'status') {
                query[searchFilter] = this.state.status.value;
            }
            else {
                if(this.state.searchTerm !== "") {
                    query[searchFilter] = this.state.searchTerm.trim();
                }
            }
        }
        else {
            const formattedStartDate = moment(this.state.startDate).format("YYYY-MM-DD");
            const formattedEndDate = moment(this.state.endDate).format("YYYY-MM-DD");
            if (formattedStartDate !== 'Invalid date') {
                query["missedPremiumDateFrom"] = formattedStartDate;
            }
            if (formattedEndDate !== 'Invalid date') {
                query["missedPremiumDateTo"] = formattedEndDate;
            }
        }

        if (this.state.sortByField !== null && this.state.sortOrder !== null) {
            query['sortBy'] = `${this.state.sortByField},${this.state.sortOrder}`;
        }

        return query;
    }

    validateQuery(query) {
        const searchFilter = this.state.searchFilter.value;
        let result = {
            isValid: false,
            isDate: false,
            message: ""
        };

        if (searchFilter === "contractHolderName") {
            if(query.hasOwnProperty('contractHolderName')) {
                result.isValid = checkSearch(query.contractHolderName, this.displayError, 2);
            }
            else {
                result.isValid = true;
            }
        }
        else if (searchFilter === "contractNumber") {
            if(query.hasOwnProperty('contractNumber')) {
                result.isValid = checkSearch(query.contractNumber, this.displayError, 2);
            }
            else {
                result.isValid = true;
            }
        }
        else if (searchFilter === "paidUntilDate") {
            result.isDate = true;
            const hasFromDate = query.hasOwnProperty('missedPremiumDateFrom');
            const hasToDate = query.hasOwnProperty('missedPremiumDateTo');

            const startDate = query.missedPremiumDateFrom;
            const endDate = query.missedPremiumDateTo;

            const bothAreNone = !hasFromDate && !hasToDate;
            const oneIsNone = !hasFromDate || !hasToDate;
            const bothAreFilled = hasFromDate && hasToDate;

            if(bothAreNone) {
                result.isValid = true;
            }
            else if (bothAreFilled) {
                const stringResult = checkDateRange(endDate, startDate);
                if(stringResult.length === 0) {
                    result.isValid = true;
                }
                else {
                    result.message = stringResult;
                }
            }
            else if(oneIsNone) {
                result.message = "Invalid input. From cannot be greater than To";
            }
        }
        else if (searchFilter === "status") {
            result.isValid = true;
        }
        return result;
    }

    render() {

        const YMDFormat = "YYYY-MM-DD";
        const columns = [
            {
                Header: "Policy Number",
                accessor: "contractNumber"
            },
            {
                id: "contractHolder",
                Header: "Owner",
                accessor: (data) => data.contractHolder ? `${data.contractHolder.lastName}, ${data.contractHolder.firstName} ${data.contractHolder.middleName}` : "--"
            },
            {
                Header: "Insured",
                accessor: "insured",
                Cell: (props) => <Insured insured={props.value} />
            },
            /*
            **removing policy status due to inconsistency with the data source
            {
                id: "status",
                Header: "Status",
                accessor: (data) => <button className={"dashboard-datatable__button btn text-uppercase rounded-pill text-white " + data.status.toLowerCase()} >{data.status.toLowerCase()}</button>
            },
            */
            {
                Header: "Payment Scheme",
                accessor: "paymentScheme"
            },
            {
                id: "netPremium",
                Header: "Modal Premium",
                accessor: (data) => `${data.currency} ${data.netPremium}`
            },
            {
                Header: "Outstanding Premium",
                accessor: "contractBalance",
                sortColumn: "PAYABLE_PREMIUM",
            },
            {
                id: "paidUntilDate",
                Header: "Missed Premium Date",
                accessor: (data) => moment(data.paidUntilDate).format(YMDFormat),
                sortColumn: "MISSED_PREMIUM_DATE",
            },
            {
                Header: "Referring Branch",
                accessor: "branchName"
            },
        ];

        if (isManager(this.props.getSession().role)) {
          columns.splice(
            columns.length - 1,
            0, 
            {
                id: "servicingAgent",
                Header: "Servicing Agent",
                accessor: (data) => data.agent ? `${data.agent.name}` : "--"
            });
        }

        if (this.state.isLoading) {
            return (
                <ReportLoader show={this.state.isLoading} />
            )
        }
        else {
            const noteMessage = "Outstanding premium for (1) Traditional Policies are subject to interest charges (2) Unit Linked Policies are inclusive of contact debt";
            const noteStyle = {
                fontSize: ".9em",
                color: "#255f8e",
                marginTop: "25px"
            };
            return (
                <div className="dashboard-section overflow-hidden">
                    <ErrorMessage message={this.state.errorMessage} />
                    <div className="dashboard-container container-fluid p-3">
                        <div className="dashboard-header">
                            <h6 className="font-neo-bold">
                                <Link className="text-darkgray text-decoration-none" to="/dashboard/auto-reinstatement">Reports</Link>
                                <span className="dashboard-header__subheader text-headercolor"> | Policies without Payment Beyond Due Date</span>
                            </h6>
                        </div>
                        <div className="dashboard-body">
                            <div className="dashboard-datatable mt-4">
                                <Search
                                    searchFilter={this.state.searchFilter}
                                    searchTerm={this.state.searchTerm}
                                    startDate={this.state.startDate}
                                    endDate={this.state.endDate}
                                    status={this.state.status}
                                    statuses={this.state.statuses}
                                    sortFilter={this.state.sortFilter}
                                    handleUpdateSearchFilter={this.handleUpdateSearchFilter}
                                    handleUpdateSearchTerm={this.handleUpdateSearchTerm}
                                    handleUpdateStartDate={this.handleUpdateStartDate}
                                    handleUpdateEndDate={this.handleUpdateEndDate}
                                    handleUpdateStatus={this.handleUpdateStatus}
                                    handleSubmit={this.handleSubmit}
                                    handlePressEnter={this.handlePressEnter}
                                    handleSortDropdownFilter={this.handleSortDropdownFilter}
                                />
                                <p className="font-italic" style={noteStyle}>Note: {noteMessage}</p>
                                <div className="mt-4">
                                    {this.props.isSm ? (
                                        <PoliciesMobile
                                            title={[
                                                {
                                                    Header: 'Policy Number',
                                                    accessor: 'POL_NO',
                                                    hasCaret: true,
                                                    onClick: () => {
                                                        this.headerClickedHandler('POL_NO');
                                                    }
                                                },
                                                {
                                                    id: "contractHolder",
                                                    Header: 'Policy Owner',
                                                    accessor: (data) => data.contractHolder ? `${data.contractHolder.lastName}, ${data.contractHolder.firstName} ${data.contractHolder.middleName}` : "--"
                                                },
                                            ]
                                            }
                                            data={this.state.policies}
                                            customName={columns}
                                            isTableLoading={this.state.isTableLoading}
                                        >
                                            <TablePagination
                                                css="mt-2"
                                                data={this.state.policies}
                                                page={this.state.page}
                                                totalSize={this.state.totalSize}
                                                onPageChange={this.handleChangePage}
                                                pageSize={myConfig.PAGE_SIZE}
                                                pages={this.state.pages}
                                            />
                                            {/* {this.state.loadTable ? <TableLoader loading={true} /> : ""} */}
                                        </PoliciesMobile>
                                    ) : (
                                            <Policies
                                                policies={this.state.policies}
                                                pages={this.state.pages}
                                                page={this.state.page}
                                                columns={columns}
                                                totalSize={this.state.totalSize}
                                                isTableLoading={this.state.isTableLoading}
                                                role={this.props.getSession().channel}
                                                sortByField={this.state.sortByField}
                                                sortOrder={this.state.sortOrder}
                                                handleChangePage={this.handleChangePage}
                                                handleClickHeader={this.handleClickHeader}
                                            />
                                        )}


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }
}

export default withWindowDimensions(PaymentsBeyondDueReport);