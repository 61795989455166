import React, { Component } from 'react';
import Auxi from '../components/auxi';
import { Link, Route, BrowserRouter as Router } from 'react-router-dom';
import { createLogo, removeNullProfilePic, appendFileType, urlBase64ToUint8Array, jsonToURI, prettifyDate, calculateAge, numberWithCommas, writeData, readAllData, encrypt, decrypt, truncateString } from '../commons/utility.js';

import { myConfig } from '../commons/config.js';
import routeChange from '../commons/routeChange';
import { apiModule, createQueryStrings } from '../commons/apiCall';
import moment from "moment";
import withWindowDimensions from '../commons/withWindowDimensions';

class Topbar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            searchbar: '',
            logout: false,
            notification: [],
            notification_count: 0,
        }
    }

    async componentDidMount() {
        this.checkIfAllowedPush();
        this.fetchAlerts();
    }

    fetchAlerts = () => {
        let session = this.props.getSession();
        let username = decrypt(this.props.getSession().username)
        let that = this;
        let headerParams = {
            accountManagerId: username,
            x_auth: session.access_token,
        };

        let payloadParams = {};
        let filter = [];
        filter['page_size'] = 3;

        let notification = [];
        let notification_count = 0;
        return new Promise(function (resolve, reject) {
            apiModule("get_inbox_dashboard", headerParams, payloadParams, createQueryStrings(filter))
                .then(function (reply) {

                    if (reply.message !== null && reply.message !== undefined) {
                        let result = reply.message;

                        for (let i = 0; i < result.data.length; i++) {
                            let date_item = result.data[i];
                            notification.push({
                                text: date_item.message,
                                time: moment(date_item.created_at).fromNow()
                            })
                        }



                        if (result.total !== null && result.total !== undefined) {
                            notification_count = parseInt(result.total);
                        }
                    }


                    that.setState({ notification_count, notification, isLoading: false })

                })
                .catch(function (err) {
                    that.setState({ notification: [], isLoading: false, notification_count: notification_count })


                })
        });
    }


    notification = () => {

        if (this.state.notification !== null && this.state.notification !== undefined) {


            let notifItems = this.state.notification.map((item, index) => {
                let divider = '';
                if (index < this.state.notification.length - 1) {
                    divider = <div className="dropdown-divider m-0"></div>;
                }
                // console.log(data.length);
                return (
                    <Auxi key={index}>
                        <div className="dropdown-item notif-nav-dropdown__item">
                            <div className="notif-item">
                                <p className="notif-item__text mb-1">{truncateString(item.text, 50)}</p>
                                <small className="notif-item__time">{item.time}</small>
                            </div>
                        </div>
                        {divider}
                    </Auxi>
                );
            });

            return notifItems;
        } else {
            return (
                <Auxi key={0}>
                    <div className="dropdown-item notif-nav-dropdown__item">
                        <div className="notif-item">

                        </div>
                    </div>

                </Auxi>
            );
        }
    }
    onChangeItem = (e) => {
        let searchbar = this.state.searchbar;
        searchbar = e.target.value;
        this.setState({ searchbar });
    }

    onKeyPress = (e) => {
        if (e.key === 'Enter') {
            this.onClickAction(this.state.searchbar, this)
        } else {

        }

    }

    /**********/

    configurePushSub = () => {
        let that = this;
        console.log('configurePushSub ServiceWorker');
        if (!('serviceWorker' in navigator)) {
            console.log('No ServiceWorker');
            return;
        }

        if ('PushManager' in window) {
            console.log('We have a push manager');

            var reg;
            navigator.serviceWorker.ready
                .then(function (swreg) {
                    reg = swreg;
                    console.log('navigator.serviceWorker.ready');
                    console.log(swreg);
                    let pushManager = swreg.pushManager;
                    console.log(pushManager);

                    return pushManager.getSubscription();
                })
                .then(function (sub) {

                    // Create a new subscription
                    console.log('user_get_vapid');
                    return new Promise((resolve, reject) => {

                        if (sub === null) {

                            apiModule('user_get_vapid', { x_auth: '' }, { app_key: myConfig.APP_ID }).then(function (response) {
                                if (response.result == 'success') {
                                    var vapidPublicKey = response.message;
                                    var convertedVapidPublicKey = urlBase64ToUint8Array(vapidPublicKey);
                                    console.log('Create a new subscription ' + vapidPublicKey);
                                    resolve(
                                        reg.pushManager.subscribe({
                                            userVisibleOnly: true,
                                            applicationServerKey: convertedVapidPublicKey
                                        })
                                    );
                                }
                            });
                        } else {
                            // We have a subscription
                            resolve(sub);
                        }
                    })

                    // var vapidPublicKey = myConfig.WEB_PUSH_PUBLIC_KEY;
                    // var convertedVapidPublicKey = urlBase64ToUint8Array(vapidPublicKey);
                    // console.log('Create a new subscription ' + myConfig.WEB_PUSH_PUBLIC_KEY);
                    // return reg.pushManager.subscribe({
                    //     userVisibleOnly: true,
                    //     applicationServerKey: convertedVapidPublicKey
                    // });

                })
                .then(function (newSub) {
                    let username = decrypt(that.props.getSession().username);
                    let name = decrypt(that.props.getSession().name);

                    let webpushObject = {
                        'webpush': JSON.stringify(newSub),
                        'type': 'default_push_type',
                        'app_key': myConfig.APP_ID,
                        'user_id': username,
                        'username': username,
                        'email': username,
                    };

                    console.log(newSub);
                    let request = { x_auth: that.props.getSession().jwt }
                    return apiModule('user_store_vapid', request, webpushObject);


                })
                .then(function (res) {
                    console.log(res);
                })
                .catch(function (err) {
                    console.log(err);
                });
        } else {


        }


    }

    checkIfAllowedPush = () => {
        let that = this;

        if ('PushManager' in window) {
            if (Notification.permission === "granted") {
                that.configurePushSub();
            }
        }
    }

    onClickAction = (item, that) => {
        let pathName = window.location.pathname;

        writeData('search', { search: item, id: 1 });

        if (pathName === "/dashboard/lead-reports" || pathName === "/dashboard/lead-reports/search") {
            if (item.trim().length !== 0) {
                if (item.trim().length >= 2)
                    routeChange(that.props.history, "/dashboard/lead-reports?search=" + encodeURI(item));
            }
            else {
                routeChange(that.props.history, "/dashboard/lead-reports");
            }
        } else {
            that.props.doSearch(item, 1);
            routeChange(that.props.history, "/dashboard/search-results");
        }
    }
    async logoutF() {
        if (window.confirm("Are you sure you want to logout?")) {
            let payloadParams = {
                access_token: this.props.getSession().access_token,
                refresh_token: this.props.getSession().refresh_token
            };

            console.log('here logout')

            let logout = await this.logout(payloadParams);

            let session = { alias: '', image: '', view_as: '', view_as_email: '', view_as_number: '', name: '', agentNumber: '', code: '', permissions: [], username: '', channel: '', id: 1, user_role: '', refresh_token: '' };
            writeData('session', session);
            localStorage.clear();
            //this.props.setUserSession(session);
            setTimeout(function () { window.location = "/"; }, 500);
            // routeChange(this.props.history, "/");

        }

    }

    logout = (payloadParams) => {
        return new Promise(function (resolve, reject) {
            apiModule("authentication_logout", {}, payloadParams, '', true)
                .then(function (result) {
                console.log('result', result);
                resolve('Ok')
                })
                .catch(function (err) {
                console.log('err', err)
                resolve('Ok')
                });
        });
    }

    globalSearchBar = () => {
        return (
            <Auxi>
                <input className="searchbar__input h-100" name="searchbar" id="searchbar" onKeyPress={(e) => this.onKeyPress(e)} onChange={(e) => this.onChangeItem(e)} value={this.state.searchbar} type="text" placeholder="Search for" />
                {/* <button className="btn searchbar__button"><img className="searchbar__button-img" src={require('../assets/icons/search-white.svg')} alt="Search icon" /></button> */}
                <a onClick={() => this.onClickAction(this.state.searchbar, this)} className="btn searchbar__button"><img className="searchbar__button-img" src={require('../assets/icons/search-white.svg')} alt="Search icon" /></a>
            </Auxi>
        );
    }

    mobileView = (logoLink, isMisOnly) => {
        // let channel = this.props.getSession().channel;
        // let logo_div = (<img className="w-100" src={createLogo(channel)} alt="Allianz Logo" />);
        return (
            <nav onClick={this.props.onClick} className={`navbar-light navbar-expand bg-headercolor custom-navbar ${this.props.moveToRight} py-p75rem`}>
                <div className="custom-navbar-collapse collapse navbar-collapse justify-content-between" id="navbarSupportedContent">
                    {/* {!isMisOnly? <div className="toggle-sidebar">
                        <button className="toggle-sidebar__btn pr-0 pl-0 cs-pointer" onClick={this.props.toggleSidebar}>
                            <span className="toggle-sidebar__icon icon-bars"></span>
                        </button>
                    </div>: ''} */}
                    <div className="toggle-sidebar">
                        <button className="toggle-sidebar__btn pr-0 pl-0 cs-pointer" onClick={this.props.toggleSidebar}>
                            <span className="toggle-sidebar__icon icon-bars"></span>
                        </button>
                    </div>
                    {logoLink}
                    <div className="header-navbar-nav-container">
                        <ul className="navbar-nav header-navbar-nav">
                            <li className="ml-2 position-relative">
                                <button className="btn btn-dropdowncolor dropdown-toggle h-100 notif-nav__dropdown-toggle" type="button" data-toggle="dropdown">
                                    {/* <img className="h-100" src={require('../assets/icons/bell-o-white.svg')} alt="Bell icon" /> */}
                                    <div className="notif-nav__badge badge badge-pill badge-green text-white position-absolute font-60pc">{this.state.notification_count}</div>
                                    <span className="notif-nav__icon icon-bell-o"></span>
                                </button>
                                <div className="notif-nav-dropdown dropdown-menu shadow-sm">
                                    {this.notification()}
                                    <div className="d-flex justify-content-end notif-nav-dropdown__item mt-2">
                                        <Link className="notif-nav-dropdown__button btn btn-dropdowncolor" to="/dashboard/alerts-and-updates">View All</Link>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="custom-searchbar-container form-inline mt-3">
                        <div className="custom-searchbar form-control w-100">
                            {this.globalSearchBar()}
                        </div>
                    </div>

                </div>
            </nav>
        )
    }

    desktopView = (logoLink, role, name) => {
        const { toggleWhatsNewModal } = this.props;
        return (
            <nav className="navbar navbar-expand navbar-light bg-headercolor custom-navbar">
                {/* <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent">
                    <span className="navbar-toggler-icon"></span>
                </button> */}
                {logoLink}
                <div className="custom-navbar-collapse collapse navbar-collapse" id="navbarSupportedContent">
                    <div className="custom-searchbar-container form-inline">
                        <div className="custom-searchbar form-control w-100 my-2">
                            {this.globalSearchBar()}
                        </div>
                    </div>

                    <div className="header-navbar-nav-container ml-auto">
                        <ul className="navbar-nav header-navbar-nav">
                            <li className="w-100">
                                <div className="dropdown user-nav-container h-100">
                                    <div className="user-nav__body">
                                        <button className="btn btn-dropdowncolor dropdown-toggle user-nav__dropdown-toggle h-100" type="button" data-toggle="dropdown">

                                            <img className="user-picture rounded-circle" src={this.props.getSession().image}></img>
                                            <div className="user-title">

                                                <p className="mb-0 font-neo-semibold text-white">{name}</p>
                                                <small className="text-white">{role}</small>
                                            </div>
                                            <div className="user-dropdown-icon-container">
                                                <span className="user-dropdown__icon icon-chevron-dropdown"></span>
                                            </div>
                                        </button>
                                        <div className="user-nav-dropdown shadow-sm dropdown-menu w-100">
                                            {/* <a className="dropdown-item user-nav-dropdown__item" href="/dashboard/user-info"><span className="user-nav-dropdown__icon icon-user-o mx-1"></span>View User Information</a> */}
                                            <Link className="dropdown-item user-nav-dropdown__item" to="/dashboard/user-info"><span className="user-nav-dropdown__icon icon-user-o mx-1"></span>View User Information</Link>
                                            <div className="dropdown-divider"></div>
                                            <a className="dropdown-item user-nav-dropdown__item text-dropdowncolor" onClick={() => { this.logoutF(); }}><span className="user-nav-dropdown__icon icon-setting-o mx-1"></span>Logout</a>
                                        </div>
                                    </div>
                                </div>

                            </li>
                            <li className="ml-2">
                                <button className="btn btn-dropdowncolor dropdown-toggle h-100 notif-nav__dropdown-toggle" type="button" data-toggle="dropdown">
                                    {/* <img className="h-100" src={require('../assets/icons/bell-o-white.svg')} alt="Bell icon" /> */}
                                    <div className="notif-nav__badge badge badge-pill badge-green text-white position-absolute font-60pc">{this.state.notification_count}</div>
                                    <span className="notif-nav__icon icon-bell-o"></span>
                                </button>
                                <div className="notif-nav-dropdown dropdown-menu shadow-sm">
                                    {this.notification()}
                                    <div className="d-flex justify-content-end notif-nav-dropdown__item mt-2">
                                        <Link className="notif-nav-dropdown__button btn btn-dropdowncolor" to="/dashboard/alerts-and-updates">View All</Link>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        );
    }

    render() {
        const { isMisOnly } = this.props;
        let name = decrypt(this.props.getSession().name);
        let role = this.props.getSession().role;
        let channel = this.props.getSession().channel;
        let logo_div = (<img className="w-100" src={createLogo(channel)} alt="Allianz Logo" />);
        let logoLink = (
            <Link to="/dashboard/dashboard" className={`navbar-brand navbar-brand--custom ${this.props.isSm ? "mr-0" : "mr-4"}`}>
                {logo_div}
            </Link >
        );
        if (this.props.isInternalUser === true && this.props.hasViewAs === false) {
            logoLink = (
                <div className={`navbar-brand navbar-brand--custom ${this.props.isSm ? "mr-0" : "mr-4"}`}>
                    {logo_div}
                </div>
            );
        }
        return (
            <Auxi>
                {this.props.isSm ? this.mobileView(logoLink, isMisOnly) : this.desktopView(logoLink, role, name)}
            </Auxi>

        );
    }
}

export default withWindowDimensions(Topbar);
