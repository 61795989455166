import React from 'react';

const loadingGif = (props) => {
    return (
        <div className={`w-100 text-center ${props.css ? props.css : ""}`} style={props.style}>
            <img width="75" height="75" src={require("../assets/img/loader.gif")} alt="Loading Gif" />
        </div>
    );
}

const loadingGif2 = (props) => {
    return (
        <div className={`loader ${props.css ? props.css : ""}`} style={props.style}></div>
    )
}

const loadingGif3 = (props) => {
    return (
        <div className={props.css ? props.css : ""} style={props.style}>
            <img className="h-100 w-100" src={require("../assets/img/auth-loader.gif")} alt="Loading Gif" />
        </div>
    );
}

const loadingGif4 = () => {
    return (
        <div className="text-center">
            <img width="25" height="25" src={require("../assets/img/loader.gif")} alt="Loading Gif" />
        </div>
    )
}

export default loadingGif;
export {
    loadingGif2 as LoaderGif2,
    loadingGif3 as LoaderGif3,
    loadingGif4 as LoaderGif4,
};
