import React, { Component, } from 'react';
import withWindowDimensions from '../../commons/withWindowDimensions';
import Auxi from "../auxi";
import LoaderGif from '../../commons/loaderGif';
import { apiModule } from "../../commons/apiCall";
import { prettifyDate, fixName, replaceRemark } from '../../commons/utility';

class EndorsementModal extends Component {
    state = {
        isLoading: true,
        data: []
    }

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.fetchData();
    }

    async fetchData() {
        await apiModule(
            "get_pending_endorsements_requirements",
            {
                x_auth: this.props.modalData.token,
                contractNum: this.props.modalData.policyId,
                agentNumber: this.props.modalData.agent,
                transactionId: this.props.modalData.transactionId,
            },
            null
        ).then((result) => {
            let data = [
                {
                    "responsibleUnit": "",
                    "insured": [],
                    "jobType": "",
                    "worklistStatus": "",
                    "unitComment": "",
                    "name": "",
                    "receivedDate": "",
                }
            ]
            if (result.length > 0) {
                data = result.map(item => {
                    let name = "";
                    let insured = [];
                    if (item.contractLink) {
                        if (item.contractLink.contractHolder) {
                            const contractHolder = item.contractLink.contractHolder;
                            contractHolder.lastName = contractHolder.lastName ? contractHolder.lastName.trim() : "";
                            name = fixName(contractHolder);
                        }

                        if (item.contractLink.insured && item.contractLink.insured.length > 0) {
                            insured = item.contractLink.insured.map(insuredItem => {
                                insuredItem.lastName = insuredItem.lastName ? insuredItem.lastName.trim() : "";
                                return fixName(insuredItem);
                            })
                        }
                    }
                    return {
                        responsibleUnit: item.responsibleUnit,
                        jobType: item.jobType,
                        worklistStatus: item.worklistStatus,
                        unitComment: replaceRemark(item.unitComment),
                        receivedDate: item.receivedDate ? prettifyDate(item.receivedDate) : "",
                        insured,
                        name,
                    }
                });
            }
            this.setState({ transactionId: this.props.modalData.transactionId, isLoading: false, data });
        }).catch((err) => {
            console.error(err);
            this.setState({ transactionId: this.props.modalData.transactionId, isLoading: false });
        });
    }


    render() {
        const data = this.state.data;
        const showHideClass = "";
        const policyNumber = this.props.modalData.policyId || "";
        const inlineStyle = {
            display: showHideClass,
            maxHeight: "95vh",
        };
        const applicationNumberStyle = {
            fontSize: "14px",
            marginLeft: "2px"
        }
        const closeContainerStyle = {
            transition: "0.2s linear",
            float: "right"
        }

        let modal = "";

        if (this.props.isSm) {
            // MOBILE VIEW
            modal = (
                <div className={`announcement-body`} style={inlineStyle}>
                    <div className="announcement-box mt-n5">
                        <span style={applicationNumberStyle}><strong>Policy Number</strong>: {policyNumber}</span>
                        <div style={closeContainerStyle}>
                            <h5 className="announcement-title"></h5>
                            <img className="announcement-close-icon" src="/announcement/close.png" alt="close-icon" onClick={this.props.handleClose} />
                        </div>
                        <div className="announcement-content" style={{ maxHeight: 'calc(100vh - 300px)' }}>
                            <div style={{ overflow: 'auto' }}>
                                {this.state.isLoading ? (
                                    <div style={{ minWidth: "10em", minHeight: "10em" }}>
                                        <LoaderGif css="mt-4 mb-4" />
                                    </div>
                                ) : (
                                    <div className="data-accord row flex-wrap mt-4" >
                                        <div className="data-accord__header">
                                            <div className="col-6">
                                                <div className="d-inline-flex align-items-center cs-pointer" style={{ fontSize: "1em" }}>
                                                    Policy Owner Name
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="d-inline-flex align-items-center cs-pointer" style={{ fontSize: "1em" }}>
                                                    Requirements
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 data-accord__body mt-1">
                                            <div className="data-accord__collapse" id="data-accord">
                                                {
                                                    data.map((item, index) => {
                                                        return (
                                                            <Auxi key={index}>
                                                                <div className={`data-accord__card ${index > 0 ? "mt-3" : ""} card border-0`}>
                                                                    <div className="data-accord__toggle btn" data-toggle="collapse" data-target={`#collapse-${index}`}>
                                                                        <div className="row data-accord__start">
                                                                            <div className="col-6">
                                                                                <button className="data-accord__name data-accord__name--clear">{item.name ? item.name : "--"}</button>
                                                                            </div>
                                                                            <div className="col-6 d-flex justify-content-between">
                                                                                <div className="font-neo-semibold">{item.jobType ? item.jobType : "--"}</div>
                                                                                <button className="btn p-0" type="button">
                                                                                    <div className="data-accord__caret">
                                                                                        <div className="data-accord__caret-icon"></div>
                                                                                    </div>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div id={`collapse-${index}`} className="collapse" data-parent="#data-accord">
                                                                        <div className="card-body data-accord__content">
                                                                            <div className="row">
                                                                                <div className="data-accord__column col-11">
                                                                                    <div className="data-accord__label">Insured Name</div>
                                                                                    <div className="data-accord__value">{item.insured ? item.insured.join("\n") : "--"}</div>
                                                                                </div>
                                                                                <div className="data-accord__column col-11">
                                                                                    <div className="data-accord__label">For</div>
                                                                                    <div className="data-accord__value">{item.responsibleUnit ? item.responsibleUnit : "--"}</div>
                                                                                </div>
                                                                                <div className="data-accord__column col-1">
                                                                                </div>
                                                                                <div className="data-accord__column col-11">
                                                                                    <div className="data-accord__label">Requirements Status</div>
                                                                                    <div className="data-accord__value">{item.worklistStatus ? item.worklistStatus : "--"}</div>
                                                                                </div>
                                                                                <div className="data-accord__column col-1">
                                                                                </div>
                                                                                <div className="data-accord__column col-11">
                                                                                    <div className="data-accord__label">Date Received</div>
                                                                                    <div className="data-accord__value">{item.receivedDate ? item.receivedDate : "--"}</div>
                                                                                </div>
                                                                                <div className="data-accord__column col-1">
                                                                                </div>
                                                                                <div className="data-accord__column col-11">
                                                                                    <div className="data-accord__label">Remarks</div>
                                                                                    <div className="data-accord__value">{item.unitComment ? item.unitComment : "--"}</div>
                                                                                </div>
                                                                                <div className="data-accord__column col-1">
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Auxi >
                                                        );
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            modal = (
                // DESKTOP VIEW
                <div className={`announcement-body`} style={inlineStyle}>
                    <div className="announcement-box announcement-box--max-width-70p mt-n5">
                        <span style={applicationNumberStyle}><strong>Policy Number</strong>: {policyNumber}</span>
                        <div style={closeContainerStyle}>
                            <h5 className="announcement-title"></h5>
                            <img className="announcement-close-icon" src="/announcement/close.png" alt="close-icon" onClick={this.props.handleClose} />
                        </div>
                        <div className="announcement-content">
                            <div style={{ overflow: 'auto' }}>
                                {this.state.isLoading ? (
                                    <div style={{ minWidth: "10em", minHeight: "10em" }}>
                                        <LoaderGif css="mt-4 mb-4" />
                                    </div>
                                ) : (
                                    <table className="table-striped" style={{ width: "100%" }}>
                                        <tbody>
                                            <tr className="" style={{ verticalAlign: "bottom", fontSize: ".8rem" }}>
                                                <th className="p-3" scope="col">Policy Owner Name</th>
                                                <th className="p-3" scope="col">Insured Name</th>
                                                <th className="p-3" scope="col">For</th>
                                                <th className="p-3" scope="col">Requirements</th>
                                                <th className="p-3" scope="col">Requirements Status</th>
                                                <th className="p-3" scope="col">Date Received</th>
                                                <th className="p-3" scope="col">Remark</th>
                                            </tr>
                                            {
                                                data.map((item, index) => {
                                                    return (
                                                        <tr key={index} style={{ fontSize: ".9em" }}>
                                                            <td className="p-3 text-left">{item.name ? item.name : "--"}</td>
                                                            <td className="p-3 text-left">{item.insured ? item.insured.join("\n") : "--"}</td>
                                                            <td className="p-3 text-left">{item.responsibleUnit ? item.responsibleUnit : "--"}</td>
                                                            <td className="p-3 text-left">{item.jobType ? item.jobType : "--"}</td>
                                                            <td className="p-3 text-left">{item.worklistStatus ? item.worklistStatus : "--"}</td>
                                                            <td className="p-3 text-left">{item.receivedDate ? item.receivedDate : "--"}</td>
                                                            <td className="p-3 text-left">{item.unitComment ? item.unitComment : "--"}</td>
                                                        </tr>
                                                    );
                                                })
                                            }
                                        </tbody>
                                    </table>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            modal
        );
    }

}
export default withWindowDimensions(EndorsementModal);
