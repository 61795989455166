export const AgentKnowledgeBaseFiles = [
    {
        folderName: 'GrabTab',
        files: [
            {
                fileName: "AJ Installation - How to file SDP ticket to IT",
                fileDate: "",
                fileUrl: "/docs/agency/GrabTab/AJ Installation - How to file SDP Ticket to IT.pdf",
                scale: 1
            },
            {
                fileName: "Application Form: GrabTab Program",
                fileDate: "October 8, 2021",
                fileUrl: null,
                alternateFileView: "/docs/agency/GrabTab/Application Form_GrabTab Loan_v2.2 2021-10-08.pdf",
                alternateFileDownload: "/docs/agency/GrabTab/Application Form_GrabTab Loan_v2.2 2021-10-08.docx",
            },
            {
                fileName: "GrabTab V3.2 Agency Quick Guide",
                fileDate: "December 2021",
                fileUrl: "/docs/agency/GrabTab/GrabTabV3.2_Agency_Quick Guide - 122021 - as of Dec 2021.pdf",
            }
        ]
    },
    {
        folderName: 'Legal Guidelines',
        files: [
            {
                folderName: 'Disciplinary Guidelines',
                files: [
                    {
                        fileName: "Allianz PNB Life Disciplinary Guidelines for Intermediaries",
                        fileDate: "January 25, 2022",
                        fileUrl: "/docs/agency/Legal Guidelines/Disciplinary Guidelines/AZPNBL Disciplinary Guidelines and Table of Penalties and Sanctions_vFINAL2022.pdf",
                    },
                    {
                        fileName: "Table of Violations and Sanctions",
                        fileDate: "February 1, 2022",
                        fileUrl: "/docs/agency/Legal Guidelines/Disciplinary Guidelines/Table of Violations and Sanctions_vFINAL2022.xls"
                    },
                ]
            },
            {
                fileName: "Allianz PNB Life Code of Sales and Business Conduct",
                fileDate: "June 16, 2020",
                fileUrl: "/docs/agency/Legal Guidelines/AZ PNB Life - Code of Sales and Business Conduct - Final.pdf",
            },
            {
                fileName: "Allianz Standard for Customer Protection",
                fileDate: "August 29, 2024",
                fileUrl: "/docs/agency/Legal Guidelines/Allianz Standard for Customer Protection.pdf",
            },
        ]
    },
    {
        folderName: 'Licensing Guidelines and Requirements',
        files: [
            {
                folderName: '1 - New Application',
                files: [
                    {
                        fileName: "AIL Form",
                        fileDate: "",
                        fileUrl: "/docs/agency/Licensing Guidelines and Requirements/1 - New Application/AIL FORM.pdf",
                    },
                    {
                        fileName: "Allianz Licensing Onboarding Process",
                        fileDate: "",
                        fileUrl: "/docs/agency/Licensing Guidelines and Requirements/1 - New Application/Allianz Licensing Onboarding process.pdf",
                    },
                    {
                        fileName: "Allianz Licensing Requirements",
                        fileDate: "",
                        fileUrl: "/docs/agency/Licensing Guidelines and Requirements/1 - New Application/Allianz Licensing Requirements.pdf",
                    },
                ]
            },
            {
                folderName: '2 - Renewal',
                files: [
                    {
                        fileName: "Allianz Licensing Renewal Form - Agency",
                        fileDate: "",
                        fileUrl: "/docs/agency/Licensing Guidelines and Requirements/2 - Renewal/ALLIANZ LICENSE RENEWAL FORM - AGENCY.pdf",
                    },
                    {
                        fileName: "IC Circular Letter (CL2022_03)",
                        fileDate: "",
                        fileUrl: "/docs/agency/Licensing Guidelines and Requirements/2 - Renewal/IC Circular Letter (CL2022_03).pdf",
                    },
                ]
            },
            {
                folderName: '3 - Movement or Offboarding',
                files: [
                    {
                        fileName: "Guidelines and Form (Transfer of Business)",
                        fileDate: "",
                        fileUrl: "/docs/agency/Licensing Guidelines and Requirements/3 - Movement or Offboarding/Guidelines and Form (Transfer of Business).pdf",
                        scale: 1
                    },
                    {
                        fileName: "Guidelines and Requirements for Transfer of Agents",
                        fileDate: "April 1, 2022",
                        fileUrl: "/docs/agency/Licensing Guidelines and Requirements/3 - Movement or Offboarding/Guidelines and Requirements for Transfer of Agents (TOA).pdf",
                        scale: 1
                    },
                    {
                        fileName: "Intermediary Movement Form (Agency)",
                        fileDate: "",
                        fileUrl: "/docs/agency/Licensing Guidelines and Requirements/3 - Movement or Offboarding/Intermediary Movement Form (Agency).xls",
                    },
                ]
            },
            {
                fileName: "Licensing FAQs",
                fileDate: "April 1, 2022",
                fileUrl: "/docs/agency/Licensing Guidelines and Requirements/Licensing FAQs.pdf",
                scale: 1
            },
            {
                fileName: "Licensing Handbook (Agency Version)",
                fileDate: "July 30, 2021",
                fileUrl: "/docs/agency/Licensing Guidelines and Requirements/Licensing Handbook (Agency Version).pdf",
            }
        ]
    },
    {
        folderName: 'Training',
        files: [
            {
                fileName: "First 90 Days Onboarding Training of a Life Changer Agency Partner's Guide - AZ Eagles Academy",
                fileDate: "November 1, 2021",
                fileUrl: "/docs/agency/Training/First 90 Days Onboarding Training of a Life Changer_Agency Partner's Guide_AZ Eagles Academy_Nov2021.pdf",
                scale: 1
            },
            {
                fileName: "First 90 Days Onboarding Training of a Life Changer Leader's Guide - AZ Eagles Academy",
                fileDate: "November 1, 2021",
                fileUrl: "/docs/agency/Training/First 90 Days Onboarding Training of a Life Changer_Leader's Guide_AZ Eagles Academy_Nov2021.pdf",
                scale: 1
            }
        ]
    },
    {
        folderName: 'NBUW Guidelines',
        files: [
            {
                folderName: 'Addresses',
                files: [
                    {
                        fileName: "EDM4_NBUW_03232022",
                        fileDate: "",
                        fileUrl: "/docs/agency/NBUW Guidelines/Addresses/EDM4_NBUW_03232022.pdf",
                    },
                ],
            },
            {
                folderName: 'Annual Income',
                files: [
                    {
                        fileName: "EDM2_NBUW_03232022_v1.2",
                        fileDate: "",
                        fileUrl: "/docs/agency/NBUW Guidelines/Annual Income/EDM2_NBUW_03232022_v1.2.pdf",
                    },
                ],
            },
            {
                folderName: 'Beneficiary',
                files: [
                    {
                        fileName: "EDM 8_Relationship of Bene to Proposed Insured",
                        fileDate: "",
                        fileUrl: "/docs/agency/NBUW Guidelines/Beneficiary/EDM 8_Relationship of Bene to Proposed Insured.pdf",
                    },
                    {
                        fileName: "QRG 4 - Bene",
                        fileDate: "",
                        fileUrl: "/docs/agency/NBUW Guidelines/Beneficiary/QRG 4 - Bene.pdf",
                    },
                ],
            },
            {
                folderName: 'Build',
                files: [
                    {
                        fileName: "QRG 3 - Build",
                        fileDate: "",
                        fileUrl: "/docs/agency/NBUW Guidelines/Build/QRG 3 - Build.pdf",
                    },
                ],
            },
            {
                folderName: 'Nationality',
                files: [
                    {
                        fileName: "EDM3_NBUW_03232022",
                        fileDate: "",
                        fileUrl: "/docs/agency/NBUW Guidelines/Nationality/EDM3_NBUW_03232022.pdf",
                    },
                ],
            },
            {
                folderName: 'NMA Table',
                files: [
                    {
                        fileName: "NMA 1M (2017)",
                        fileDate: "",
                        fileUrl: "/docs/agency/NBUW Guidelines/NMA Table/NMA 1M (2017).pdf",
                    },
                    {
                        fileName: "NMA 8M (2017)",
                        fileDate: "",
                        fileUrl: "/docs/agency/NBUW Guidelines/NMA Table/NMA 8M (2017).pdf",
                    },
                ],
            },
            {
                folderName: 'Occupation',
                files: [
                    {
                        fileName: "EDM#1_NBUW_03212022_v1.2",
                        fileDate: "",
                        fileUrl: "/docs/agency/NBUW Guidelines/Occupation/EDMno1_NBUW_03212022_v1.2.pdf",
                    },
                ],
            },
            {
                folderName: 'Payment',
                files: [
                    {
                        fileName: "EDM5_NBUW_05022022",
                        fileDate: "",
                        fileUrl: "/docs/agency/NBUW Guidelines/Payment/EDM5_NBUW_05022022.pdf",
                    },
                    {
                        fileName: "EDM7_NBUW_05062022",
                        fileDate: "",
                        fileUrl: "/docs/agency/NBUW Guidelines/Payment/EDM7_NBUW_05062022.pdf",
                    },
                ],
            },
            {
                fileName: "NB Checklist Form",
                fileDate: "",
                fileUrl: "/docs/agency/NBUW Guidelines/NB Checklist Form.pdf",
            }
        ],
    },
    {
        folderName: "2024 Rewards and Incentives",
        fileDate: "",
        files: [
            {
                folderName: '2024 Agency RNI',
                files: [
                    {
                        fileName: "[Mechanics] Hanoi",
                        fileDate: "May 1, 2024",
                        fileUrl: "/docs/agency/AZ Assist Knowledgebase - 2024 AG RNI - 06042024/[Mechanics] Hanoi.pdf"
                    },
                    {
                        fileName: "[Mechanics] LIMITLESS: The 2025 Allianz Agency Annual Awards",
                        fileDate: "January 1, 2024",
                        fileUrl: "/docs/agency/AZ Assist Knowledgebase - 2024 AG RNI - 06042024/[Mechanics] LIMITLESS - The 2025 Allianz Agency Annual Awards.pdf"
                    }
                ]
            },
            {
                fileName: "[Mechanics] 2024 GAMA",
                fileDate: "January 1, 2024",
                fileUrl: "/docs/agency/2024 Rewards and Incentives/[Mechanics] 2024 GAMA.pdf",
                scale: 1
            },
            {
                fileName: "[Mechanics] LifeCon 2024",
                fileDate: "January 1, 2024",
                fileUrl: "/docs/agency/2024 Rewards and Incentives/[Mechanics] LifeCon 2024.pdf",
                scale: 1
            },
            {
                fileName: "[Mechanics] 2025 MDRT Membership Requirements",
                fileDate: "January 1, 2024",
                fileUrl: "/docs/agency/2024 Rewards and Incentives/[Mechanics] 2025 MDRT Membership Requirements.pdf",
                scale: 1
            }
        ]
    },
    {
        fileName: "Allianz Compensation & Benefits Handbook",
        fileDate: "July 1, 2023",
        fileUrl: "/docs/agency/Agency Compensation & Benefits Handbook - 2023 Edition - v1.0 - 06272023.pdf",
    },
    {
        fileName: "Agency 2024 Payout Schedule",
        fileDate: "",
        fileUrl: "/docs/agency/Agency Payout Schedule 2024.pdf"
    },
    {
        fileName: "Allianz Onboarding Handbook 2021 Final",
        fileDate: "July 21, 2021",
        fileUrl: "/docs/agency/Allianz Onboarding Handbook - 2021 - Final 07012021.pdf",
    },
    {
        fileName: "AZPNB Policy Replacement Guidelines",
        fileDate: "May 11, 2021",
        fileUrl: "/docs/agency/AZ PNB Policy Replacement Guidelines (Revised) 2021-05-11.pdf",
    },
    {
        fileName: "BDS Directory",
        fileDate: "",
        fileUrl: "/docs/agency/BDS Directory.xlsx",
    },
    {
        fileName: "Underwriting Manual for Distribution ver 7.0",
        fileDate: "",
        fileUrl: "/docs/agency/Underwriting Manual for Distribution Ver 7.0.pdf",
    },
    {
        fileName: "Digital ID Authentication EDM and FAQs For Agency",
        fileDate: "",
        fileUrl: "/docs/agency/Digital ID Authentication For Agency.pdf",
    }
];

export const BancaFiles = [
    {
        folderName: 'Banca Bonus Scheme - Sales Managers only',
        roles: ["SM"],
        files: [
            {
                fileName: "2022 PNB Branch List and Branch Classification Targets_FINAL",
                fileDate: "",
                fileUrl: "/docs/banca/Banca Bonus Scheme - Sales Managers only/2022 PNB Branch List and Branch Classification Targets_FINAL.xlsx",
            },
            {
                fileName: "Bonus Scheme Calculator_Sales Managers",
                fileDate: "",
                fileUrl: "/docs/banca/Banca Bonus Scheme - Sales Managers only/Bonus Scheme Calculator_Sales Managers.xlsx",
            },
            {
                fileName: "Memo - Banca New Bonus Scheme - SM Level_Final",
                fileDate: "January 21, 2022",
                fileUrl: "/docs/banca/Banca Bonus Scheme - Sales Managers only/Memo - Banca New Bonus Scheme - SM Level_Final.pdf",
            }
        ]
    },
    {
        folderName: 'Banca Bonus Scheme - SMs and FAs',
        roles: ["SM", "FA"],
        files: [
            {
                fileName: "Bonus Scheme Calculator_FA",
                fileDate: "",
                fileUrl: "/docs/banca/Banca Bonus Scheme - SMs and FAs/Bonus Scheme Calculator_FA.xlsx",
            },
            {
                fileName: "Memo - Banca New Bonus Scheme - FA Level_Final",
                fileDate: "January 13, 2022",
                fileUrl: "/docs/banca/Banca Bonus Scheme - SMs and FAs/Memo - Banca New Bonus Scheme - FA Level_Final.pdf",
            }
        ]
    },
    {
        folderName: 'GrabTab',
        files: [
            {
                fileName: "(BA) AJ Installation - How to file SDP Ticket to IT",
                fileDate: "",
                fileUrl: "/docs/banca/GrabTab/(BA) AJ Installation - How to file SDP Ticket to IT.pdf",
                scale: 1
            },
            {
                fileName: "Application Form_GrabTab Loan_v2.2 2021-10-08",
                fileDate: "October 8, 2021",
                fileUrl: null,
                alternateFileView: "/docs/banca/GrabTab/Application Form_GrabTab Loan_v2.2 2021-10-08.pdf",
                alternateFileDownload: "/docs/banca/GrabTab/Application Form_GrabTab Loan_v2.2 2021-10-08.docx",
            },
            {
                fileName: "GrabTabV3.2_Bancassurance_Quick Guide - 122021 - as of Dec 2021",
                fileDate: "December 2021",
                fileUrl: "/docs/banca/GrabTab/GrabTabV3.2_Bancassurance_Quick Guide - 122021 - as of Dec 2021.pdf",
            }
        ]
    },
    {
        folderName: 'Legal Guidelines',
        files: [
            {
                folderName: 'Disciplinary Guidelines',
                files: [
                    {
                        fileName: "AZPNBL Disciplinary Guidelines and Table of Penalties and Sanctions_vFINAL2022",
                        fileDate: "January 25, 2022",
                        fileUrl: "/docs/banca/Legal Guidelines/Disciplinary Guidelines/AZPNBL Disciplinary Guidelines and Table of Penalties and Sanctions_vFINAL2022.pdf",
                    },
                    {
                        fileName: "Table of Violations and Sanctions_vFINAL2022",
                        fileDate: "2022",
                        fileUrl: "/docs/banca/Legal Guidelines/Disciplinary Guidelines/Table of Violations and Sanctions_vFINAL2022.xls",
                    },
                ]
            },
            {
                fileName: "AZPNBLIfe Code of Sales and Business Conduct",
                fileDate: "June 16, 2020",
                fileUrl: "/docs/banca/Legal Guidelines/AZPNBLIfe Code of Sales and Business Conduct.pdf",
            },
            {
                fileName: "Allianz Standard for Customer Protection",
                fileDate: "August 29, 2024",
                fileUrl: "/docs/banca/Legal Guidelines/Allianz Standard for Customer Protection.pdf",
            },
        ]
    },
    {
        folderName: 'Licensing Requirements',
        files: [
            {
                folderName: '1 - New Application',
                files: [
                    {
                        fileName: "AIL Form",
                        fileDate: "",
                        fileUrl: "/docs/banca/Licensing Requirements/1 - New Application/AIL FORM.pdf",
                    },
                    {
                        fileName: "Allianz Licensing Onboarding Process",
                        fileDate: "",
                        fileUrl: "/docs/banca/Licensing Requirements/1 - New Application/Allianz Licensing Onboarding process.pdf",
                    },
                    {
                        fileName: "Allianz Licensing Requirements",
                        fileDate: "",
                        fileUrl: "/docs/banca/Licensing Requirements/1 - New Application/Allianz Licensing Requirements.pdf",
                    },
                ]
            },
            {
                folderName: '2 - Renewal',
                files: [
                    {
                        fileName: "Allianz Licensing Renewal Form - Bancassurance",
                        fileDate: "",
                        fileUrl: "/docs/banca/Licensing Requirements/2 - Renewal/Allianz Licensing Renewal Form - Bancassurance.pdf",
                    },
                    {
                        fileName: "IC Circular Letter (CL2022_03)",
                        fileDate: "March 2022",
                        fileUrl: "/docs/banca/Licensing Requirements/2 - Renewal/IC Circular Letter (CL2022_03).pdf",
                    },
                ]
            },
            {
                folderName: '3 - Movement or Offboarding',
                files: [
                    {
                        fileName: "Banca Offboarding Process as of Feb 22, 2022",
                        fileDate: "",
                        fileUrl: "/docs/banca/Licensing Requirements/3 - Movement or Offboarding/Banca Offboarding Process as of Feb 22, 2022.pdf",
                        scale: 1
                    },
                    {
                        fileName: "Guidelines and Requirements for Transfer of Agents",
                        fileDate: "April 1, 2022",
                        fileUrl: "/docs/banca/Licensing Requirements/3 - Movement or Offboarding/Guidelines and Requirements for Transfer of Agents (TOA).pdf",
                        scale: 1
                    },
                    {
                        fileName: "Intermediary Movement Form (Banca)",
                        fileDate: "",
                        fileUrl: "/docs/banca/Licensing Requirements/3 - Movement or Offboarding/Intermediary Movement Form (Banca).xls",
                    },
                ]
            },
            {
                fileName: "LICENSING FAQs (per pillar) as of August 6, 2021",
                fileDate: "August 6, 2021",
                fileUrl: "/docs/banca/Licensing Requirements/Licensing FAQs.pdf",
                scale: 1
            },
            {
                fileName: "Licensing Handbook (Banca Version)",
                fileDate: "February 2022",
                fileUrl: "/docs/banca/Licensing Requirements/Licensing Handbook (Banca Version).pdf",
            }
        ]
    },
    {
        folderName: 'NBUW Guidelines',
        files: [
            {
                folderName: 'Addresses',
                files: [
                    {
                        fileName: "EDM4_NBUW_03232022",
                        fileDate: "",
                        fileUrl: "/docs/banca/NBUW Guidelines/Addresses/EDM4_NBUW_03232022.pdf",
                    },
                ],
            },
            {
                folderName: 'Annual Income',
                files: [
                    {
                        fileName: "EDM2_NBUW_03232022_v1.2",
                        fileDate: "",
                        fileUrl: "/docs/banca/NBUW Guidelines/Annual Income/EDM2_NBUW_03232022_v1.2.pdf",
                    },
                ],
            },
            {
                folderName: 'Beneficiary',
                files: [
                    {
                        fileName: "EDM 8_Relationship of Bene to Proposed Insured",
                        fileDate: "",
                        fileUrl: "/docs/banca/NBUW Guidelines/Beneficiary/EDM 8_Relationship of Bene to Proposed Insured.pdf",
                    },
                    {
                        fileName: "QRG 4 - Bene",
                        fileDate: "",
                        fileUrl: "/docs/banca/NBUW Guidelines/Beneficiary/QRG 4 - Bene.pdf",
                    },
                ],
            },
            {
                folderName: 'Build',
                files: [
                    {
                        fileName: "QRG 3 - Build",
                        fileDate: "",
                        fileUrl: "/docs/banca/NBUW Guidelines/Build/QRG 3 - Build.pdf",
                    },
                ],
            },
            {
                folderName: 'Nationality',
                files: [
                    {
                        fileName: "EDM3_NBUW_03232022",
                        fileDate: "",
                        fileUrl: "/docs/banca/NBUW Guidelines/Nationality/EDM3_NBUW_03232022.pdf",
                    },
                ],
            },
            {
                folderName: 'NMA Table',
                files: [
                    {
                        fileName: "NMA 1M (2017)",
                        fileDate: "",
                        fileUrl: "/docs/banca/NBUW Guidelines/NMA Table/NMA 1M (2017).pdf",
                    },
                    {
                        fileName: "NMA 8M (2017)",
                        fileDate: "",
                        fileUrl: "/docs/banca/NBUW Guidelines/NMA Table/NMA 8M (2017).pdf",
                    },
                ],
            },
            {
                folderName: 'Occupation',
                files: [
                    {
                        fileName: "EDM#1_NBUW_03212022_v1.2",
                        fileDate: "",
                        fileUrl: "/docs/banca/NBUW Guidelines/Occupation/EDMno1_NBUW_03212022_v1.2.pdf",
                    },
                ],
            },
            {
                folderName: 'Payment',
                files: [
                    {
                        fileName: "EDM5_NBUW_05022022",
                        fileDate: "",
                        fileUrl: "/docs/banca/NBUW Guidelines/Payment/EDM5_NBUW_05022022.pdf",
                    },
                    {
                        fileName: "EDM7_NBUW_05062022",
                        fileDate: "",
                        fileUrl: "/docs/banca/NBUW Guidelines/Payment/EDM7_NBUW_05062022.pdf",
                    },
                ],
            },
            {
                fileName: "Banca Sales Checklist Form",
                fileDate: "",
                fileUrl: "/docs/banca/NBUW Guidelines/Banca Sales Checklist Form_final.pdf",
            },
            {
                fileName: "NB Checklist Form_03062023",
                fileDate: "",
                fileUrl: "/docs/banca/NBUW Guidelines/NB Checklist Form_03062023.pdf",
            },
            {
                fileName: "Roadshow New Guidelines for Banca",
                fileDate: "",
                fileUrl: "/docs/banca/NBUW Guidelines/Roadshow_New Guidelines for Banca_Final for dissemination.pdf",
            },
            {
                fileName: "SPA to receive e-Policy contract and Other Comms",
                fileDate: "",
                fileUrl: "/docs/banca/NBUW Guidelines/SPA to receive epolicy contract and other comms v03062023.pdf",
            }
        ],
    },
    {
        folderName: '2024 Rewards and Incentives',
        files: [
            {
                folderName: 'Bellissimo Milano',
                files: [
                    {
                        fileName: "[Contest Mechanics] Bellissimo Milano - FA version",
                        fileDate: "January 1, 2024",
                        fileUrl: "/docs/banca/2024 Rewards and Incentives/Bellissimo Milano/[Contest Mechanics] Bellissimo Milano - FA version.pdf",
                    },
                    {
                        fileName: "[Contest Mechanics] Bellissimo Milano - PNB Bank Partner version",
                        fileDate: "January 1, 2024",
                        fileUrl: "/docs/banca/2024 Rewards and Incentives/Bellissimo Milano/[Contest Mechanics] Bellissimo Milano - PNB Bank Partner version.pdf",
                    }
                ]
            },
            {
                folderName: 'Q1 Super Sprint',
                files: [
                    {
                        fileName: "[Contest Mechanics] Q1 Super Sprint - FA version",
                        fileDate: "January 1, 2024",
                        fileUrl: "/docs/banca/2024 Rewards and Incentives/Q1 Super Sprint/[Contest Mechanics] Q1 Super Sprint - FA version.pdf",
                    },
                    {
                        fileName: "[Contest Mechanics] Q1 Super Sprint - PNB RBS version",
                        fileDate: "January 1, 2024",
                        fileUrl: "/docs/banca/2024 Rewards and Incentives/Q1 Super Sprint/[Contest Mechanics] Q1 Super Sprint - PNB RBS version.pdf",
                    },
                    {
                        fileName: "[Contest Mechanics] Q1 Super Sprint - PNB Wealth version",
                        fileDate: "January 1, 2024",
                        fileUrl: "/docs/banca/2024 Rewards and Incentives/Q1 Super Sprint/[Contest Mechanics] Q1 Super Sprint - PNB Wealth version.pdf",
                    }
                ],
            },
            {
                folderName: '2024 BA RNI',
                files: [
                    {
                        fileName: "[Mechanics] Q2 Challenge",
                        fileDate: "May 1, 2024",
                        fileUrl: "/docs/banca/AZ Assist Knowledgebase - 2024 BA RNI - 06052024/[Mechanics] Q2 Challenge.pdf"
                    },
                    {
                        fileName: "[Mechanics] Q2 Intensified",
                        fileDate: "May 6, 2024",
                        fileUrl: "/docs/banca/AZ Assist Knowledgebase - 2024 BA RNI - 06052024/[Mechanics] Q2 Intensified.pdf"
                    }
                ]
            },
            {
                fileName: "[Contest Mechanics] 2025 MDRT Membership - BA",
                fileDate: "January 1, 2024",
                fileUrl: "/docs/banca/2024 Rewards and Incentives/[Contest Mechanics] 2025 MDRT Membership - BA.pdf",
            },
            {
                fileName: "[Contest Mechanics] Allianz Champion Convention",
                fileDate: "January 1, 2024",
                fileUrl: "/docs/banca/2024 Rewards and Incentives/[Contest Mechanics] Allianz Champion Convention.pdf",
            },
        ]
    },
    {
        fileName: "RAR Comms for AZ PNB Banca release_22Mar2022",
        fileDate: "March 22, 2022",
        fileUrl: "/docs/banca/AZ PNB Banca Rider Attachment Ratio Series - as of March 22 2022.pdf",
    },
    {
        fileName: "2024 Payout Schedule - Bancassurance",
        fileDate: "",
        fileUrl: "/docs/banca/Bancassurance Payout Schedule 2024.pdf",
    },
    {
        fileName: "SSS Directory",
        fileDate: "",
        fileUrl: "/docs/banca/SSS Directory.xlsx",
    },
    {
        fileName: "Underwriting Manual for Distribution Ver 7.0",
        fileDate: "February 12, 2022",
        fileUrl: "/docs/banca/Underwriting Manual for Distribution Ver 7.0.pdf",
    },
    {
        fileName: "2024 Payout Schedule - Bank Referrers",
        fileDate: "",
        fileUrl: "/docs/banca/Bank Referrer Fee Schedule 2024.pdf",
    },
    {
        fileName: "Digital ID Authentication EDM and FAQs For Banca",
        fileDate: "",
        fileUrl: "/docs/banca/Digital ID Authentication For Banca.pdf",
    }
];
